import React, { useEffect, useState } from "react";
import "../styles/propertyDetails.css";
import { getPropertyDetailsById } from "../services/propertyDetailsService";
import { Drawer, message } from "antd";
import Header from "../../../../layout/views/Header";
import PropertyDetailsForm from "./PropertyDetailsForm";
import "../../../customerTabDetails/style/customerStyle.css";
import { useParams } from "react-router-dom";
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import HistoryTable from "../../../history/views/HistoryTable";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import { useSelector } from "react-redux";
import "../../../../commonCss/commonStyle.css";
import EditButton from "../../../../../utils/editButton/EditButton";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import pdf_image from "../assets/pdf.png";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";

const PropertyDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [propertyData, setPropertyData] = useState(null);
  const [open, setOpen] = useState(false);
  const [createdById, setCreatedById] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [loading, setLoading] = useState(true);
  const { user_data } = useSelector((state) => state.user);
  const [imageData, setImagedata] = useState(null);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPropertyDetailsById(loan_id);
        setPropertyData(data.data);
        setImagedata(data.data.property_document_details)
        setCreatedById(data.data?.loan_detail);
        setUpdateId(data.data?.id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
  }, [loan_id, refreshData]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const fieldNameMap = {
    area_category: "Area Category",
    number_of_floors: "Number Of Floors",
    property_age: "Property Age   (In Year)",
    construction_stage: "Construction Stage",
    ownership: "Ownership",
    land_type: "Land Type",
    address_line_1: "Address Line 1",
    address_line_2: "Address Line 2",
    landmark: "Landmark",
    city: "City",
    taluka: "Taluka",
    district: "District",
    pincode: "Pincode",
    state: "State",
    country: "Country",
    property_type: "Property Type",
    green_building:"Greeen Building",
    developer_name: "Developer Name",
    project_name: "Name Of Project",
    bd_modified_at: "Business Modified Date",



  };
  return (
    <>
      <div className="main_property_details_container">
        <div className="fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : propertyData ? (
            <>
              <div className="property_edit_container">
                {propertyData?.created_at &&
                  propertyData?.modified_at &&
                  isModifiedAfterCreated(
                    propertyData?.created_at,
                    propertyData?.modified_at
                  ) && (
                    <HistoryTable
                      id={propertyData?.id}
                      bench_id={"252859e17edf1d55"}
                      fieldNameMap={fieldNameMap}
                    />
                  )}
                <ConditionalRender
                  userPermissions={user_data?.permissions}
                  requiredPermissions={["EDITLOAN"]}
                >
                  <div
                    className="tab_form_sub_edit edit_button"
                    onClick={showDrawer}
                  >
                    <EditButton />
                  </div>
                </ConditionalRender>
              </div>
              <div className="proprty_details_container">
                <Drawer
                  title={
                    <Header
                      title="Edit"
                      onClose={onClose}
                      name="Property Details"
                    />
                  }
                  width={970}
                  onClose={onClose}
                  open={open}

                  closable={false}
                  refreshData={toggleRefreshData}
                >
                  <PropertyDetailsForm
                    open={open}
                    closeForm={onClose}
                    id={createdById}
                    updateId={updateId}
                    refreshData={toggleRefreshData}
                    images={imageData}
                  />
                </Drawer>


                <div className="Basic_basic_card">
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Area Category</div>
                    <div className="basic_basic_details_data">
                      {propertyData?.area_category || '-'}
                    </div>
                  </div>
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Ownership</div>
                    <div className="basic_basic_details_data">
                      {propertyData?.ownership || '-'}
                    </div>
                  </div>
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Number Of Floors</div>
                    <div className="basic_basic_details_data">{propertyData?.number_of_floors || '-'}</div>
                  </div>
                  <div className="basic_basic_basic_card_content">
                    <div className="basic_basic_details_label">Construction Stage</div>
                    <div className="basic_basic_details_data">{propertyData.construction_stage || '-'}</div>
                  </div>

                </div>

                <div className="Basic_basic_card">
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Property Age (In Year)</div>
                    <div className="basic_basic_details_data">
                      {propertyData?.property_age || '-'}
                    </div>
                  </div>
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Land Type</div>
                    <div className="basic_basic_details_data">
                      {propertyData?.land_type || '-'}
                    </div>
                  </div>
                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Property Type</div>
                    <div className="basic_basic_details_data">{propertyData?.property_type?.display_name || '-'}</div>
                  </div>
                  <div className="basic_basic_basic_card_content">
                    <div className="basic_basic_details_label">Green Building</div>
                    <div className="basic_basic_details_data">{propertyData.green_building ? "Yes" : "No"}</div>
                  </div>
                  <div className="basic_basic_basic_card_content">
                    <div className="basic_basic_details_label">Name Of Project</div>
                    <div className="basic_basic_details_data">{propertyData.project_name || '-'}</div>
                  </div>

                  <div className="basic_basic_card_content">
                    <div className="basic_basic_details_label">Developer Name
                    </div>
                    <div className="basic_basic_details_data">
                      {propertyData?.developer_name || '-'}
                    </div>
                  </div>


                </div>

          
              </div>
             
              <div className="property_remark_bolck prop_remark">Address :</div>



              <div className="Basic_basic_card">
                <div className="basic_basic_card_content">
                  <div className="basic_basic_details_label">Address Line 1</div>
                  <div className="basic_basic_details_data">
                    {propertyData?.address_line_1 || '-'}
                  </div>
                </div>
                <div className="basic_basic_card_content">
                  <div className="basic_basic_details_label">Address Line 2</div>
                  <div className="basic_basic_details_data">
                    {propertyData?.address_line_2 || '-'}
                  </div>
                </div>
                <div className="basic_basic_card_content">
                  <div className="basic_basic_details_label">Landmark</div>
                  <div className="basic_basic_details_data">{propertyData?.landmark || '-'}</div>
                </div>
                <div className="basic_basic_basic_card_content">
                  <div className="basic_basic_details_label">Pincode</div>
                  <div className="basic_basic_details_data">{propertyData.pincode?.display_name || '-'}</div>
                </div>

              </div>
              <div className="Basic_basic_card">
                <div className="basic_basic_card_content">
                  <div className="basic_basic_details_label">City</div>
                  <div className="basic_basic_details_data">
                    {propertyData?.city?.display_name || '-'}
                  </div>
                </div>
                <div className="basic_basic_card_content">
                  <div className="basic_basic_details_label">Taluka</div>
                  <div className="basic_basic_details_data">
                    {propertyData?.taluka || '-'}
                  </div>
                </div>
                <div className="basic_basic_card_content">
                  <div className="basic_basic_details_label">District</div>
                  <div className="basic_basic_details_data">{propertyData?.district?.display_name || '-'}</div>
                </div>
                <div className="basic_basic_basic_card_content">
                  <div className="basic_basic_details_label">State</div>
                  <div className="basic_basic_details_data">{propertyData?.state?.display_name || '-'}</div>
                </div>

              </div>
              <div className="Basic_basic_card">
                <div className="basic_basic_card_content">
                  <div className="basic_basic_details_label">Country</div>
                  <div className="basic_basic_details_data">
                    {propertyData?.country?.display_name || '-'}
                  </div>
                </div>
             
          

              </div>
              <div>
                <div className='property_images'>
                  {imageData.map((image, index) => (
                    <div className="property_div_img" key={index} >
                      {image.file_type === "application/pdf" ? (
                        <a href={image.document_path} target="_blank" rel="noreferrer noopener">
                          <img
                            src={pdf_image}
                            className="property_details_img"

                            alt="PDF Preview"
                          />
                        </a>

                      ) : (
                        <RViewerJS
                          options={{
                            url: (img) => img.getAttribute("data-original"),
                          }}
                        >
                          <img
                            src={image.thumb_document}
                            data-original={image.document_path}
                            alt="Preview"
                            className="property_details_img"

                          />
                        </RViewerJS>
                      )}
                      <div className="property_thumb_img">
                        {image.document_type.display_name}
                      </div>
                    </div>
                  ))}
                </div>


              </div>
            </>
          ) : (
            <div className="no_data_found_message">
              <CustomNoData />
            </div>
          )}
        </div>
      </div >
    </>
  );
};

export default PropertyDetails;
