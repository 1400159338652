import React, { useEffect, useState } from "react";
import { Card, Radio, Typography, Input, Button } from "antd";
import { PlusOutlined, CheckOutlined } from "@ant-design/icons";
import "../style/dashboardLiteToDoList.css";
import { getTodoList, postTodo, updateTodo } from "../service/todoService";

const DashboardLiteToDoList = ({ selectedDate }) => {
  const { Text, Link } = Typography;
  const [toDoList, setToDoList] = useState([]);
  const [newToDo, setNewToDo] = useState("");
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    const fetchTodos = async () => {
      const date = new Date(selectedDate);
      const formattedDate = date.toISOString().split("T")[0];
      const response = await getTodoList(formattedDate);
      setToDoList(response.data);
    };

    fetchTodos();
  }, [selectedDate]);

  const handleAddToDo = async () => {
    if (newToDo.trim()) {
      const date = new Date(selectedDate);
      const formattedDate = date.toISOString().split("T")[0];
      const data = {
        title: newToDo,
        due_date: formattedDate,
      };

      await postTodo(data);

      const response = await getTodoList(formattedDate);
      setToDoList(response.data);

      setNewToDo("");
      setShowInput(false);
    }
  };

  const handleRadioChange = async(id) => {
    const checkStatus = toDoList?.find((item) => item.id === id);
    await updateTodo(id, { is_active: !checkStatus.is_active });
    setToDoList((prevList) => {
      const updatedList = prevList.map((item) =>
        item.id === id ? { ...item, is_active: !item.is_active } : item
      );

      const sortedList = updatedList.sort(
        (a, b) => (b.completed ? 1 : 0) - (a.completed ? 1 : 0)
      );

      return sortedList;
    });
  };

  return (
    <Card
      bordered={false}
      style={{ backgroundColor: "#fff", borderRadius: 8 }}
      bodyStyle={{ padding: 0 }}
    >
      <div
        style={{
          padding: 10,

          borderRadius: 8,
          maxHeight: 300, // Set a fixed height
          overflowY: "auto", // Make it scrollable
          paddingRight: 10, // To avoid content overlap
        }}
        className="custom-scrollbar" // Add custom class for styling
      >
        <Radio.Group style={{ width: "100%" }}>
          {toDoList.map((item) => (
            <div
              key={item.id}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              {!item.is_active ? (
                <CheckOutlined
                  style={{
                    color: "white",
                    backgroundColor: "lightgreen",
                    borderRadius: "50%",
                    padding: 4,
                    fontSize: 8,
                  }}
                  onClick={() => handleRadioChange(item.id)}
                />
              ) : (
                <Radio
                  value={item.id}
                  checked={item.completed}
                  onClick={() => handleRadioChange(item.id)}
                />
              )}
              <Text delete={!item.is_active} style={{ marginLeft: 8 }}>
                {item.title}
              </Text>
            </div>
          ))}
        </Radio.Group>

        {showInput && (
          <div className="dashboard_lite_todo_div_two">
            <Input
              value={newToDo}
              onChange={(e) => setNewToDo(e.target.value)}
              placeholder="Enter new to-do"
              style={{ marginRight: 8 }}
            />
            <Button type="primary" onClick={handleAddToDo}>
              Add
            </Button>
          </div>
        )}

        {!showInput && (
          <div className="dashboard_lite_todo_div_two">
            <PlusOutlined style={{ color: "#8c8c8c" }} />
            <Link
              style={{ color: "#1890ff", marginLeft: 8 }}
              onClick={() => setShowInput(true)}
            >
              Add To-Do
            </Link>
          </div>
        )}
      </div>
    </Card>
  );
};

export default DashboardLiteToDoList;
