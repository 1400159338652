import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import { createSchemeConfigrationService, getSchemeConfigrationByIdService, listAllLoanTypeService, updatecSchemeConfigrationByIdService, listAllTypeSchemeService } from "../services/schemeConfigrationService";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
const { Option } = Select;

const SchemeConfigrationForm = ({ refreshData, id, open, closeForm }) => {
    const [form] = Form.useForm();
    const [allLoanTypes, setAllLoanTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allScheme, setAllScheme] = useState([]); 
   

    const getSchemeConfigrationData = async () => {
        if (id) {
            try {
                const response = await getSchemeConfigrationByIdService(id);
                const {
                    loan_scheme_detail,
                    min_loan_amt,
                    max_loan_amt,
                    max_tenor,
                    max_age,
                    max_ltv,
                    min_roi,
                    max_roi,
                    foir_applicability,
                    max_foir
                } = response?.data;
                const loanTypeId = allLoanTypes.find(type => type.name === loan_scheme_detail?.loan_type)?.id;
                const schemeTypeId = allScheme.find(type => type.name === loan_scheme_detail?.scheme)?.id;
                form.setFieldsValue({
                    loan_type: loanTypeId,
                    scheme_detail: schemeTypeId,
                    min_loan_amt,
                    max_loan_amt,
                    max_tenor,
                    max_age,
                    max_ltv,
                    min_roi,
                    max_roi,
                    foir_applicability,
                    max_foir
                });
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        }
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
          if (id) {
            const response = await updatecSchemeConfigrationByIdService(id, values);
            if ((response.status = 200 && response.success)) {
              message.success("Scheme Configration successfully updated");
              refreshData();
              closeForm();
            }
          } else {
            const response = await createSchemeConfigrationService(values);
            if ((response.status = 200 && response.success)) {
              message.success("Scheme Configration successfully created");
              refreshData();
              closeForm();
            }
          }
    
        }
        catch (error) {
          message.error(
            ErrorMessage(error?.response?.status, error?.response?.data?.message)
          );
        }finally{  
          setLoading(false)
        }
      };
    
      useEffect(() => {
        if (open) {
            getSchemeConfigrationData();
        } else {
          form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [open, id]);
    

    useEffect(() => {
        const getAllLoanTypes = async () => {
            try {
                const response = await listAllLoanTypeService();
                setAllLoanTypes(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                  );
            }
        };
        getAllLoanTypes();
    }, []);

    useEffect(() => {
        const getAllScheme = async () => {
            try {
                const response = await listAllTypeSchemeService();
                setAllScheme(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                  );
            }
        };
        getAllScheme();
    }, []);

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
                min_loan_amt: "",
                max_loan_amt: "",
                max_tenor: "",
                max_age: "",
                max_ltv: "",
                min_roi: "",
                max_roi: "",
                foir_applicability: "",
                max_foir: "",
                is_active: true,
                is_verified: true,
            }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="loan_type"
                        label="Loan Type"
                        required>
                        <Select
                            mode="single"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {allLoanTypes?.map((loan) => {
                                return (
                                    <Select.Option key={loan.id} value={loan.id}>
                                        {loan.display_name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="scheme_detail"
                        label="Scheme"
                        required>
                        <Select
                            mode="single"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {allScheme?.map((scheme_detail) => {
                                return (
                                    <Select.Option key={scheme_detail.id} value={scheme_detail.id}>
                                        {scheme_detail.display_name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="min_loan_amt" label="Min Loan Amt" rules={[{ required: true, message: "Please enter minimum loan amount" }]}>
                        <Input placeholder="Please Enter Min Loan Amt" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="max_loan_amt" label="Max Loan Amt" rules={[{ required: true, message: "Please enter maximum loan amount" }]}>
                        <Input placeholder="Please Enter Max Loan Amt" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="max_tenor" label="Max Tenor" rules={[{ required: true, message: "Please enter max tenor" }]}>
                        <Input placeholder="Please Enter Max Tenor" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="max_age" label="Max Age" rules={[{ required: true, message: "Please enter max age" }]}>
                        <Input placeholder="Please Enter Max Age" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="max_ltv" label="Max LTV" rules={[{ required: true, message: "Please enter max LTV" }]}>
                        <Input placeholder="Please Enter Max LTV" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="min_roi" label="Min ROI" rules={[{ required: true, message: "Please enter min ROI" }]}>
                        <Input placeholder="Please Enter Min ROI" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="max_roi" label="Max ROI" rules={[{ required: true, message: "Please enter max ROI" }]}>
                        <Input placeholder="Please Enter Max ROI" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="foir_applicability" label="FOIR Applicability" rules={[{ required: true, message: "Please enter FOIR applicability" }]}>
                        <Input placeholder="Please Enter FOIR Applicability" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="max_foir" label="Max FOIR" rules={[{ required: true, message: "Please enter max FOIR" }]}>
                        <Input placeholder="Please Enter Max FOIR" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="is_active" label="Is Active" rules={[{ required: true }]}>
                        <Select placeholder="Please Select">
                            <Option value={true}>True</Option>
                            <Option value={false}>False</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="is_verified" label="Is Verified" rules={[{ required: true }]}>
                        <Select placeholder="Please Select">
                            <Option value={true}>True</Option>
                            <Option value={false}>False</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Space direction="horizontal" align="center" style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default SchemeConfigrationForm;
