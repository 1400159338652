/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  Row,
  Select,
  Upload,
  Button,
  Space,
  message,
  Radio,
  Tabs,
} from "antd";
import pdf_image from "../assets/pdf.png";
import {
  postHDFCErgo,
  getAllStateService,
  getAllCityService,
  getallpincode,
  getAllPropertyTypeService,
  getAllOccuptationTypeService,
  getAllDocumentTypeService,
  getPersonalDetailsByIdService,
  getRelationByIdService,
  getInsuranceDetailsByIdService,
  EditHDFCErgo,
  deleteHDFCDocumentDetailsByIdService,
  getAdditionalLoanDetailIdService,
  getInsuranceAddressTypeList,
  getAddressDetailByAddresType,
  insurancePincodeCodeService,
} from "../services/loanInsuranceService";

import * as Yup from "yup";
import moment from "moment";
import dayjs from "dayjs";
import "viewerjs-react/dist/index.css";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import HDFCInsuredCustomerForm from "./HDFCInsuredCustomerForm";
import HDFCCoverage from "./HDFCCoverage";
import TabPane from "antd/es/tabs/TabPane";
import "../styles/hdfcinsuranceform.css";

const { Option } = Select;

const getValidationSchema = (count) => {
  return Yup.object().shape({
    [`insured_customer_name_${count}`]: Yup.string().required(
      `Insured Customer Name ${count} is required`
    ),
    [`branch_address_${count}`]: Yup.string().required(
      `Branch Address ${count} is required`
    ),
    [`property_type_${count}`]: Yup.string().required(
      `Property Type ${count} is required`
    ),
    [`occupation_${count}`]: Yup.string().required(
      `Occupation ${count} is required`
    ),
    [`city_${count}`]: Yup.string().required(`City ${count} is required`),
    [`state_${count}`]: Yup.string().required(`State ${count} is required`),
    [`pincode_${count}`]: Yup.string().required(`Pincode ${count} is required`),
    [`salutation_${count}`]: Yup.string().required(
      `Salutation ${count} is required`
    ),
    [`mobile_${count}`]: Yup.string().required(`Mobile  is required`),
    [`gender_${count}`]: Yup.string().required(`Gender ${count} is required`),
    [`email_${count}`]: Yup.string().required(`Email ${count} is required`),
    [`customer_address_type_${count}`]: Yup.string().required(
      `Address Type ${count} is required`
    ),
    [`address_line_1_${count}`]: Yup.string()
      .required(`Address Line 1 ${count} is required`)
      .max(225, `Address Line 1 ${count} cannot exceed 225 characters`),
    [`address_line_2_${count}`]: Yup.string()
      .required(`Address Line 2 ${count} is required`)
      .max(225, `Address Line 2 ${count} cannot exceed 225 characters`),
    [`nationality_${count}`]: Yup.string().required(
      `Nationality ${count} is required`
    ),
    [`date_of_birth_${count}`]: Yup.date().required(
      `Date Of Birth ${count} is required`
    ),
    [`pan_no_${count}`]: Yup.string().required(
      `PAN Number ${count} is required`
    ),
    [`height_${count}`]: Yup.string()
      .required(`Height ${count} is required`)
      .matches(/^\d+$/, `Height ${count} must contain only numbers`),
    [`weight_${count}`]: Yup.string()
      .required(`Weight ${count} is required`)
      .matches(/^\d+$/, `Weight ${count} must contain only numbers`),
    [`loan_amount_${count}`]: Yup.number().required(
      `Loan Amount ${count} is required`
    ),
    [`loan_tenure_${count}`]: Yup.number().required(
      `Tenure of loan ${count} is required`
    ),
    [`Details Of The Coverage HDFC_${count}`]: Yup.string()
      .required(`Policy Term ${count} is required`)
      .test(
        "max-value",
        `Policy Term ${count} cannot be more than 360`,
        (value) => parseFloat(value) <= 360
      ), // Ensures the number is <= 360

    [`sum_assured_${count}`]: Yup.string().required(
      `Sum Assured ${count} is required`
    ),
    [`total_premium_incl_gst_${count}`]: Yup.string().required(
      `Total Premium Inclusive Of GST ${count} is required`
    ),
    [`emi_amount_${count}`]: Yup.string().required(
      `EMI Amount ${count} is required`
    ),
    [`monthly_income_${count}`]: Yup.string().required(
      `Monthly income ${count} is required`
    ),
    [`age_proof_${count}`]: Yup.string().required(
      `Age Proof ${count} is required`
    ),

    [`nominee_insured_customer_name_${count}`]: Yup.string().required(
      `Nominee Insured Customer Name ${count} is required`
    ),
    [`nominee_nationality_${count}`]: Yup.string().required(
      `Nominee Nationality ${count} is required`
    ),
    [`nominee_email_${count}`]: Yup.string().required(
      `Nominee Email ${count} is required`
    ),
    [`nominee_mobile_${count}`]: Yup.string()
      .required(`Nominee Mobile Number ${count} is required`)
      .matches(
        /^\d{10}$/,
        `Nominee Contact Number ${count} must be exactly 10 digits`
      ),
    [`nominee_date_of_birth_${count}`]: Yup.date()
      .max(new Date(), `Nominee Date of Birth ${count} cannot be a future date`)
      .required(`Nominee Date Of Birth ${count} is required`),
    [`nominee_gender_${count}`]: Yup.string().required(
      `Nominee Gender ${count} is required`
    ),
    [`nominee_address_line_1_${count}`]: Yup.string()
      .required(`Nominee Address Line 1 ${count} is required`)
      .max(225, `Nominee Address Line 1 ${count} cannot exceed 225 characters`),
    [`nominee_address_line_2_${count}`]: Yup.string()
      .required(`Nominee Address Line 2 ${count} is required`)
      .max(225, `Nominee Address Line 2 ${count} cannot exceed 225 characters`),
    [`relation_with_assured_person_${count}`]: Yup.string().required(
      `Relation with Assured Person ${count} is required`
    ),
    [`address_type_${count}`]: Yup.string().required(
      `Nominee Address Type ${count} is required`
    ),
    [`nominee_city_${count}`]: Yup.string().required(
      `Nominee City ${count} is required`
    ),
    [`nominee_state_${count}`]: Yup.string().required(
      `Nominee State ${count} is required`
    ),
    [`nominee_pincode_${count}`]: Yup.string().required(
      `Nominee Pincode ${count} is required`
    ),
    [`nominee_relation_${count}`]: Yup.string().required(
      `Nominee Relation ${count} is required`
    ),
    [`pan_number_${count}`]: Yup.string().required(
      `PAN Number ${count} is required`
    ),
    [`premium_amount_${count}`]: Yup.string().required(
      `Premium Amount ${count} is required`
    ),
    [`applicant_type_${count}`]: Yup.string().required(
      `Applicant Type ${count} is required`
    ),
    [`nominee_applicant_type_${count}`]: Yup.string().required(
      `Nominee Applicant Type ${count} is required`
    ),
    [`nominee_occupation_${count}`]: Yup.string().required(
      `Nominee Occupation ${count} is required`
    ),
    [`appointee_name_${count}`]: Yup.string().required(
      `Appointee Name ${count} is required`
    ),
    [`appointee_date_of_birth_${count}`]: Yup.date()
      .max(
        new Date(),
        `Appointee Date of Birth ${count} cannot be today's or a future date`
      )
      .required(`Appointee Date Of Birth ${count} is required`),
    [`apointee_gender_${count}`]: Yup.string().required(
      `Appointee Gender ${count} is required`
    ),
    [`appointee_contact_number_${count}`]: Yup.string()
      .required(`Appointee Contact Number ${count} is required`)
      .matches(
        /^\d{10}$/,
        `Appointee Contact Number ${count} must be exactly 10 digits`
      ),
    [`apointee_relationship_with_life_to_be_assured_${count}`]:
      Yup.string().required(`Appointee Relationship ${count} is required`),
  });
};

const yupSync = (index) => {
  const validationSchema = getValidationSchema(index);

  return {
    async validator({ field }, value) {
      // Append the index to the field name
      const indexedField = `${field}`;

      // Validate the specific field with the index
      await validationSchema.validateSyncAt(indexedField, {
        [indexedField]: value,
      });
    },
  };
};

const HDFCInsuranceForm = ({
  id,
  customerId,
  closeForm,
  insurance_id,
  loan_id,
  customer_id,
  toggleRefreshInsurance,
  setSelectedCustomer,
  setSelectedInsuranceType,
  open,
  refreshDelete,
}) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [city, setCity] = useState([]);
  const [states, setStates] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [activeKey, setActiveKey] = React.useState("0"); // Initial activeKey
  const [propertyType, setPropertyType] = useState([]);
  const [occupationType, setOccupationTypes] = useState([]);
  const [personalData, setPersonalData] = useState([]);
  const [loanData, setLoanData] = useState([]);
  const [matchedCustomer, setMatchedCustomer] = useState(null);
  const [nominee, setSelectedNominee] = useState();
  const [isUnder18, setIsUnder18] = useState([]);
  const [relation, setRelation] = useState([]);
  const [count, setCount] = useState(1);
  const [formData, setFormData] = useState([{}]);
  const [addCustomer, setAddCustomer] = useState([]);
  const [addMoreCustomer, setAddMoreCustomer] = useState(false);
  const [addressType, setAddressType] = useState([]);
  const [ids, setIds] = useState({
    customerIds:[],
    insuredCustomerIds: [],
    nomineeId: [],
    documentIds: [],
    coverageId: null,
    appointeeIds:[],
    customer_detail: null,
  });
  const [oldCustomerId, setOldCustomerId] = useState();
  const [image, setImage] = useState([]);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [deletePicture, setDeletePicture] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [existingImages, setExistingImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [nomineeIndex, setNomineeIndex] = useState();
  const [nomineeAddressType, setNomineeAddressType] = useState([]);
  const [monthlyIncome, setMonthlyIncome] = useState("");

  const updateCustomerId = insurance_id
    ? ids.insuredCustomerIds[count - 1]
    : customerId;

    useEffect(() => {
      if (customerId !== addCustomer[activeKey]) {
        setAddCustomer([customerId]);
      }
    }, [customerId]);

  useEffect(() => {
    if (updateCustomerId && addressType.length > 1 && count === 1) {
      form.resetFields();
      setAddressType([]);
    }
  }, [form, updateCustomerId]);
  const getUserData = async () => {
    if (insurance_id) {
      try {
        const response = await getInsuranceDetailsByIdService(insurance_id);
        const {
          document,
          insured_customer_detail,
          insurance_coverage_data,
          nominee_detail,
        } = response?.data || {};

        const getCustomerDetailId = insured_customer_detail?.map(
          (item) => item.customer_detail?.id
        );
        const getCustomerId = insured_customer_detail?.map(
          (item) => item.id
        );
        const getNomineeIds = nominee_detail?.map(
          (item) => item?.id
        );

        const getApointeeId = nominee_detail?.map((item) => item?.appointee?.id)
        setIds({ insuredCustomerIds: getCustomerDetailId,nomineeId:getNomineeIds ,customerIds:getCustomerId,appointeeIds:getApointeeId});
        setExistingImages(document);
        setFormData(insured_customer_detail);
        // Loop through each nominee and set form values
        const mergeData = (insuredCustomers, nominees) => {
          return insuredCustomers?.map((customer) => {
            const matchingNominees = nominees.filter(
              (nominee) => nominee.insurance_customer_detail === customer.id
            );
            return {
              ...customer,
              nominees: matchingNominees[0],
            };
          });
        };
        // Merged result
        const mergedResult = mergeData(insured_customer_detail, nominee_detail);
        const updatedIsUnder18 = nominee_detail?.map((insuredCustomerNominee) => {
          if (insuredCustomerNominee?.date_of_birth) {
            const [nomineeDay, nomineeMonth, nomineeYear] = insuredCustomerNominee.date_of_birth.split("-") || [];
            const nomineeFormattedDate = moment(`${nomineeYear}-${nomineeMonth}-${nomineeDay}`, "YYYY-MM-DD");
        
            if (nomineeFormattedDate.isValid()) {
              return moment().diff(nomineeFormattedDate, "years") < 18;
            } else {
              console.warn("Invalid date format for nominee's date of birth.");
              return false; // Default to false if the date is invalid
            }
          } else {
            console.warn("Nominee's date of birth is missing.");
            return false; // Default to false if date is missing
          }
        });
        setIsUnder18(updatedIsUnder18);

        form.setFieldsValue({});
        // Loop through each insured customer detail and set form values
        mergedResult?.forEach((insuredCustomer, index) => {
          const [insuredDay, insuredMonth, insuredYear] = insuredCustomer?.date_of_birth.split("-") || [];
          const [nomineeDay, nomineeMonth, nomineeYear] = insuredCustomer?.nominees?.date_of_birth.split("-") || [];
          const formattedDate = moment(`${insuredYear}-${insuredMonth}-${insuredDay}`, "YYYY-MM-DD");
          const nomineeformattedDateofBirth = moment(`${nomineeYear}-${nomineeMonth}-${nomineeDay}`, "YYYY-MM-DD");

          
          let appointeeFormattedDate = null;
          if (insuredCustomer?.nominees?.appointee?.appointee_name && insuredCustomer?.nominees?.appointee?.date_of_birth) {
            const [appointeeDay, appointeeMonth, appointeeYear] =
              insuredCustomer.nominees?.appointee?.date_of_birth.split("-") || [];
            appointeeFormattedDate = moment(
              `${appointeeYear}-${appointeeMonth}-${appointeeDay}`,
              "YYYY-MM-DD"
            );
          }

          form.setFieldsValue({
            [`id_${index}`]:ids.customerIds[index],
            [`insured_customer_name_${index}`]: `${
              insuredCustomer?.first_name || ""
            } ${insuredCustomer?.middle_name || ""} ${
              insuredCustomer?.last_name || ""
            }`.trim(),
            [`gender_${index}`]: insuredCustomer?.gender,
            [`date_of_birth_${index}`]: formattedDate,
            [`ocupation_${index}`]: insuredCustomer?.ocupation?.id,
            [`nationality_${index}`]: insuredCustomer?.nationality,
            [`mobile_${index}`]: insuredCustomer?.mobile,
            [`email_${index}`]: insuredCustomer?.email,
            [`customer_address_type_${index}`]:
              insuredCustomer?.customer_address_type?.id,
            [`address_type_${index}`]: insuredCustomer?.address_type,
            [`address_line_1_${index}`]: insuredCustomer?.address_line_1,
            [`address_line_2_${index}`]: insuredCustomer?.address_line_2,
            [`city_${index}`]: insuredCustomer?.city?.id,
            [`state_${index}`]: insuredCustomer?.state?.id,
            [`pincode_${index}`]: insuredCustomer?.pincode?.id,
            monthly_income: insurance_coverage_data?.monthly_income,
            emi_amount: insurance_coverage_data?.emi_amount,
            policy_term: insurance_coverage_data?.policy_term,
            sum_assured: insurance_coverage_data?.sum_assured,
            premium_amount: insurance_coverage_data?.total_premium_incl_gst,
            loan_tenure: insurance_coverage_data?.tenure,
            loan_amount: insurance_coverage_data?.loan_amount,
            pan_number: insurance_coverage_data?.pan_no,
            property_type: insurance_coverage_data?.property_type,
            [`applicant_type_${index}`]:
              insuredCustomer?.customer_type?.name,
            customer_detail: insuredCustomer?.customer_detail,
            [`nominee_id_${index}`]: insuredCustomer?.nominees?.id,

            [`nominee_customer_detail_${index}`]: insuredCustomer?.nominees
              ?.customer_detail?.id
              ? insuredCustomer?.nominees?.customer_detail?.id
              : null,
              [`select_nominee_${index}`]: insuredCustomer?.nominees?.name,
            [`nominee_insured_customer_name_${index}`]:
              insuredCustomer?.nominees?.name,
            [`nominee_gender_${index}`]: insuredCustomer?.nominees?.gender,
            [`nominee_mobile_${index}`]: insuredCustomer?.nominees?.mobile,
            [`nominee_relation_with_assured_person_${index}`]:
              insuredCustomer?.nominees?.relation?.id,
            [`nominee_date_of_birth_${index}`]: nomineeformattedDateofBirth,
            [`address_type_${index}`]:
              insuredCustomer?.nominees?.address_type?.id,
            [`nominee_address_line_1_${index}`]:
              insuredCustomer?.nominees?.address_line_1,
            [`nominee_address_line_2_${index}`]:
              insuredCustomer?.nominees?.address_line_2,
            [`nominee_city_${index}`]: insuredCustomer?.nominees?.city?.id,
            [`nominee_state_${index}`]:
              insuredCustomer?.nominees?.state?.id,
            [`nominee_pincode_${index}`]:
              insuredCustomer?.nominees?.pincode?.id,
            [`appointee_name_${index}`]: insuredCustomer?.nominees?.appointee?.appointee_name,
            [`appointee_date_of_birth_${index}`]: appointeeFormattedDate,
            [`apointee_gender_${index}`]:
              insuredCustomer?.nominees?.appointee?.gender,
            [`apointee_relationship_with_life_to_be_assured_${index}`]:
              insuredCustomer?.nominees?.appointee?.relation?.id,
            [`appointee_contact_number_${index}`]:
              insuredCustomer?.nominees?.appointee?.mobile,
          });
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  const getFormData = form.getFieldValue();

  useEffect(() => {
    if (open) {
      getUserData();
    } else {
      form.resetFields();
      setFileList([]);
      setImage([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, insurance_id, refreshDelete]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPersonalDetailsByIdService(loan_id);
        setPersonalData(response?.data.customers);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const fetchLoanData = async () => {
      try {
        const response = await getAdditionalLoanDetailIdService(loan_id);
        setLoanData(response?.data);
        setMonthlyIncome(response?.data?.appraised_monthly_income);
      } catch (error) {
        message.error(
          `${error?.response?.status}: ${error?.response?.data?.message}`
        );
      }
    };

    if (loan_id) {
      fetchData();
      fetchLoanData();
    }
  }, [loan_id]);

  const handlePincodeChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.id === value);

    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await insurancePincodeCodeService(pincodeName);
      if (response?.data) {
        const { city, state } = response.data;
        form.setFieldsValue({
          [`nominee_city_${nomineeIndex}`]: city.id || null,
          [`nominee_state_${nomineeIndex}`]: state.id || null,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleNomineePincodeChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.id === value);

    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await insurancePincodeCodeService(pincodeName);
      if (response?.data) {
        const { city, state } = response.data;
        form.setFieldsValue({
          // nominee_pincode: pincode?.id || undefined,
          [`nominee_city_${nomineeIndex}`]: city.name || null,
          [`nominee_state_${nomineeIndex}`]: state.name || null,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (updateCustomerId) {
      const foundCustomer = personalData.find(
        (person) => person.id === customerId
      );
      if (foundCustomer && addressType) {

        let dateOfBirthValue = null;
        if (
          foundCustomer.date_of_birth &&
          moment(foundCustomer.date_of_birth, "DD-MM-YYYY", true).isValid()
        ) {
          dateOfBirthValue = dayjs(foundCustomer.date_of_birth, "DD-MM-YYYY");
        } else {
          console.error("Invalid date format or value");
        }

        form.setFieldsValue({
          [`insured_customer_name_${activeKey}`]: `${
            foundCustomer?.first_name || ""
          } ${foundCustomer?.middle_name || ""} ${
            foundCustomer?.last_name || ""
          }`.trim(),
          [`date_of_birth_${activeKey}`]: dateOfBirthValue,
          [`gender_${activeKey}`]: foundCustomer?.gender || "",
          [`nationality_${activeKey}`]: foundCustomer?.nationality || "",
          [`mobile_${activeKey}`]: foundCustomer?.mobile_number || "",
          [`email_${activeKey}`]: foundCustomer?.email || "",
          [`applicant_type_${activeKey}`]:
            foundCustomer?.customer_type?.display_name || "",
          [`address_type_${activeKey}`]: "" || undefined,
          // [`ocupation_${count}`]: "" || undefined,
          [`address_line_1_${activeKey}`]: "" || undefined,
          [`address_line_2_${activeKey}`]: "" || undefined,
          [`city_${activeKey}`]: null,
          [`state_${activeKey}`]: null,
          [`pincode_${activeKey}`]: null,
          policy_term: "",
          sum_assured: "",
          premium_amount: "",
          pan_number: "",
          property_type: undefined,
          monthly_income: "",
          emi_amount: "",
        });
        setMatchedCustomer(foundCustomer);
      }
    }
  }, [customerId]);

  useEffect(() => {
    const updateAddressDetail = async () => {
      try {
        const response = await getAddressDetailByAddresType(
          addCustomer[activeKey],
          addressType[activeKey]
        );
        if (response?.data) {
          const { address_line_1, address_line_2, city, state, pincode } =
            response.data;
          form.setFieldsValue({
            [`address_line_1_${activeKey}`]: address_line_1 || undefined,
            [`address_line_2_${activeKey}`]: address_line_2 || undefined,
            [`city_${activeKey}`]: city.id || null,
            [`state_${activeKey}`]: state.id || null,
            [`pincode_${activeKey}`]: pincode.id || null,
          });
        }
      } catch (error) {
        console.error("Failed to fetch address details:", error);
      }
    };
    if (updateCustomerId && addressType?.length > 0) {
      updateAddressDetail();
    } else {
      form.resetFields([
        `address_line_1_${activeKey}`,
        `address_line_2_${activeKey}`,
        `city_${activeKey}`,
        `state_${activeKey}`,
        `pincode_${activeKey}`,
      ]);
    }
  }, [addressType]);

  useEffect(() => {
    if (nominee) {
      const foundCustomer = personalData.find(
        (person) => person.id === nominee
      );
      if (nominee === "other" && !nomineeAddressType) {
        form.setFieldsValue({
          [`nominee_id_${nomineeIndex}`]: null,
          [`nominee_insured_customer_name_${nomineeIndex}`]: "",
          [`nominee_gender_${nomineeIndex}`]: undefined,
          [`nominee_mobile_${nomineeIndex}`]: "",
          [`nominee_date_of_birth_${nomineeIndex}`]: "",
          [`nominee_relation_with_assured_person_${nomineeIndex}`]: undefined,
          // [`address_type_${nomineeIndex}`]: undefined,
          [`nominee_address_line_1_${nomineeIndex}`]: "",
          [`nominee_address_line_2_${nomineeIndex}`]: "",
          [`nominee_city_${nomineeIndex}`]: undefined,
          [`nominee_state_${nomineeIndex}`]: undefined,
          [`nominee_pincode_${nomineeIndex}`]: undefined,
          // [`relation_with_assured_person_${nomineeIndex}`]: "",
          // [`nominee_address_line_1_${nomineeIndex}`]: "",
          // [`nominee_address_line_2_${nomineeIndex}`]: "",
          // [`nominee_city_${nomineeIndex}`]: "",
          // [`nominee_state_${nomineeIndex}`]: "",
          // [`nominee_pincode_${nomineeIndex}`]: "",
          // [`appointee_name_${nomineeIndex}`]: "",
          // [`appointee_date_of_birth_${nomineeIndex}`]: "",
          // [`apointee_gender_${nomineeIndex}`]: "",
          // [`appointee_contact_number_${nomineeIndex}`]: "",
          // [`apointee_relationship_with_life_to_be_assured_${nomineeIndex}`]: "",
        });
        return
      }
      if (foundCustomer) {
        const [day, month, year] = foundCustomer?.date_of_birth.split("-") || [];
        const formattedDate = dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD"); // Create a moment object
        const parsedDate = moment(formattedDate, "DD-MM-YYYY");

        // Calculate age
        const age = moment().diff(parsedDate, "years");
        setIsUnder18((prevState) => {
          const updatedArray = Array.isArray(prevState) ? [...prevState] : [];
          updatedArray[Number(activeKey)] = age < 18; // Set true or false for the index
          return updatedArray;
        });
        form.setFieldsValue({
          [`nominee_id_${activeKey}`]: nominee,
          [`nominee_insured_customer_name_${activeKey}`]: `${
            foundCustomer?.first_name || ""
          } ${foundCustomer?.middle_name || ""} ${
            foundCustomer?.last_name || ""
          }`.trim(),
          [`nominee_gender_${activeKey}`]: foundCustomer.gender || undefined,
          [`nominee_mobile_${activeKey}`]:
            foundCustomer?.mobile_number || "",
          [`nominee_date_of_birth_${activeKey}`]: formattedDate || "",
          [`nominee_relation_with_assured_person_${activeKey}`]: undefined,
          [`nominee_address_type_${nomineeIndex}`]: undefined,
          [`nominee_address_line_1_${activeKey}`]: "",
          [`nominee_address_line_2_${activeKey}`]: "",
          [`nominee_city_${nomineeIndex}`]: undefined,
          [`nominee_state_${nomineeIndex}`]: undefined,
          [`nominee_pincode_${nomineeIndex}`]: undefined,
          // [`nominee_address_type_${nomineeIndex}`]: undefined,

          [`appointee_name_${nomineeIndex}`]: "",
          [`appointee_date_of_birth_${nomineeIndex}`]: "",
          [`apointee_gender_${nomineeIndex}`]: "",
          [`appointee_contact_number_${nomineeIndex}`]: "",
          [`apointee_relationship_with_life_to_be_assured_${nomineeIndex}`]:
            undefined,
        });
        // setNomineeAddressType([])
      }
      else{
        form.setFieldsValue({
          [`address_type_${nomineeIndex}`]: undefined
        })
      }
    }
  }, [nominee]);

  useEffect(() => {
    const updateAddressDetail = async () => {
      try {
        const response = await getAddressDetailByAddresType(
          nominee,
          nomineeAddressType[activeKey]
        );
        if (response?.data) {
          const { address_line_1, address_line_2, city, state, pincode } =
            response.data;
          form.setFieldsValue({
            [`nominee_address_line_1_${activeKey}`]: address_line_1 || undefined,
            [`nominee_address_line_2_${activeKey}`]: address_line_2 || undefined,
            [`nominee_city_${activeKey}`]: city.id || null,
            [`nominee_state_${activeKey}`]: state.id || null,
            [`nominee_pincode_${activeKey}`]: pincode.id || null,
          });
        }
      } catch (error) {
        console.error("Failed to fetch address details:", error);
      }
    };
    if (nominee !== "other" && nomineeAddressType?.length) {
      updateAddressDetail();
    } else {
      form.resetFields([
        `nominee_address_line_1_${activeKey}`,
        `nominee_address_line_2_${activeKey}`,
        `nominee_city_${activeKey}`,
        `nominee_state_${activeKey}`,
        `nominee_pincode_${activeKey}`,
        // `address_type_${activeKey}`,
      ]);
    }
  }, [nomineeAddressType]);


  const handleNomineeChange = (value, index) => {
    setSelectedNominee(value);
    if (value === "other") {
      form.setFieldsValue({
        [`nominee_id_${index}`]: null,
        [`nominee_insured_customer_name_${index}`]: null,
        [`nominee_date_of_birth_${index}`]: null,
        [`nominee_gender_${index}`]: null,
        [`nominee_mobile_${index}`]: null,
        [`nominee_nationality_${index}`]: null,
        [`nominee_relation_with_assured_person_${index}`]: undefined,
        [`nominee_address_line_1_${index}`]: "",
        [`nominee_address_line_2_${index}`]: "",
        [`nominee_city_${index}`]: undefined,
        [`nominee_state_${index}`]: undefined,
        [`nominee_pincode_${index}`]: undefined,
        [`address_type_${index}`]: undefined,
        [`appointee_name_${index}`]: "",
        [`appointee_date_of_birth_${index}`]: "",
        [`apointee_gender_${index}`]:undefined,
        [`apointee_relationship_with_life_to_be_assured_${index}`]: undefined,
        [`appointee_contact_number_${index}`]: "",
      });
      isUnder18[index] = null;
    } 
    // else {
    //   form.setFieldsValue({
    //     [`nominee_relation_with_assured_person_${index}`]: undefined,
    //     [`address_type_${index}`]: undefined,
    //     [`nominee_address_line_1_${index}`]: "",
    //     [`nominee_address_line_2_${index}`]: "",
    //     [`nominee_city_${index}`]: undefined,
    //     [`nominee_state_${index}`]: undefined,
    //     [`nominee_pincode_${index}`]: undefined,
    //     [`address_type_${index}`]: undefined,
    //     [`apointee_name_${index}`]: "",
    //     [`apointee_date_of_birth_${index}`]: "",
    //     [`apointee_relationship_with_life_to_be_assured_${index}`]: undefined,
    //     [`apointee_contact_number_${index}`]: "",
    //   });
    // }
    setNomineeIndex(index);
  };

  const handleDateChange = (index, date, dateString) => {
    if (date) {
      const parsedDate = moment(dateString, "DD-MM-YYYY");
      const age = moment().diff(parsedDate, "years");

      setIsUnder18((prevState) => {
        const updatedArray = Array.isArray(prevState) ? [...prevState] : [];
        updatedArray[index] = age < 18; // Set true or false for the index
        return updatedArray;
      });
    } else {
      setIsUnder18((prevState) => {
        const updatedArray = Array.isArray(prevState) ? [...prevState] : [];
        updatedArray[index] = false;
        return updatedArray;
      });
    }
  };
  const getAllCitylist = async () => {
    try {
      const response = await getAllCityService();
      setCity(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllState = async () => {
    try {
      const response = await getAllStateService();
      setStates(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllPincodelist = async () => {
    try {
      const response = await getallpincode();
      setPincode(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllPropertylist = async () => {
    try {
      const response = await getAllPropertyTypeService();
      setPropertyType(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllOccupationlist = async () => {
    try {
      const response = await getAllOccuptationTypeService();
      setOccupationTypes(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllDocumentlist = async () => {
    try {
      const response = await getAllOccuptationTypeService();
      getAllDocumentTypeService(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllRelationlist = async () => {
    try {
      const response = await getRelationByIdService();
      setRelation(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAddressTypeList = async () => {
    try {
      const response = await getInsuranceAddressTypeList();
      setAddressTypeList(response?.data);
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllCitylist();
    getAllState();
    getAllPincodelist();
    getAllPropertylist();
    getAllOccupationlist();
    getAllDocumentlist();
    getAllRelationlist();
    getAddressTypeList();
  }, []);

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUploadChange = (info) => {
    let updatedFileList = [...info.fileList];

    // Assuming 'updatedFileList' is your array and 'form' is your form reference
    updatedFileList = updatedFileList.map((file, index) => {
      if (index === updatedFileList.length - 1) {
        return {
          ...file,
          documents: file.originFileObj,
        };
      }
      return file;
    });
    setFileList(updatedFileList);
    form.setFieldsValue({
      documents: undefined,
    });
  };

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const newImage = {
      file_path: isJpgOrPng ? URL.createObjectURL(file) : pdf_image,
      uid: file.uid,
    };

    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false; // Prevent automatic upload
  };

  const handleSubmit = async (values) => {
    const updateValuesData = count === 2 ? {...values,...formData[0]} : values
    setLoading(true);
    const propertyDocumentDetail = await Promise.all(
      fileList.map(async (file) => {
        const base64 = await fileToBase64(file.originFileObj);
        return base64; // Only return the base64 URL
      })
    );
    const newFormData = formData.filter((item) => item !== undefined);
    try {
      if (insurance_id) {
        const insured_customers = [];
        const nominnes = [];
        const numberOfInsured = formData.length; // Assuming the length represents the number of insured customers
        //     // Helper to extract dynamic data
        const extractDynamicData = (prefix, index, fallback = {}) => {
          const isNomineePrefix = prefix === "nominee_";
          return {
            ocupation:
            updateValuesData[`${prefix}ocupation_${index}`] ||
              fallback.ocupation ||
              null,
            city: updateValuesData[`${prefix}city_${index}`] || fallback.city?.id || null,
            state:
            updateValuesData[`${prefix}state_${index}`] || fallback.state?.id || null,
            pincode:
            updateValuesData[`${prefix}pincode_${index}`] ||
              fallback.pincode?.id ||
              null,
            address_line_1:
            updateValuesData[`${prefix}address_line_1_${index}`] ||
              fallback.address_line_1 ||
              null,
            address_line_2:
            updateValuesData[`${prefix}address_line_2_${index}`] ||
              fallback.address_line_2 ||
              null,
            [isNomineePrefix ? "address_type" : "customer_address_type"]:
              (isNomineePrefix
                ? updateValuesData[`address_type_${index}`]
                : updateValuesData[`customer_address_type_${index}`]) ||
              fallback.address_type ||
              null,
          };
        };

        // Populate insured customers and nominees
        for (let i = 0; i < numberOfInsured; i++) {
          insured_customers.push({
            // id: insuredCustomerId,
            id: newFormData[i]?.id || getFormData.customer_detail?.id || null,
            ...extractDynamicData("", i, newFormData[i] || formData[i]),
            relationship_with_insured_customer:
            updateValuesData[`relation_with_assured_person_${i}`] ||
              formData[i]?.relationship_with_insured_customer?.id ||
              null,
          });

          nominnes.push({
            id: ids.nomineeId[i],
            insurance_customer_detail: newFormData[i]?.id,
            name: updateValuesData[`nominee_insured_customer_name_${i}`] || null,
            mobile: updateValuesData[`nominee_mobile_${i}`] || null,
            mobile_country_code: "91",
            date_of_birth:
            updateValuesData[`nominee_date_of_birth_${i}`]?.format("YYYY-MM-DD") ||
              null,
            gender: updateValuesData[`nominee_gender_${i}`] || null,
            ...extractDynamicData("nominee_", i),
            customer_detail: updateValuesData[`nominee_customer_detail_${i}`] || null,
            relation:
            updateValuesData[`nominee_relation_with_assured_person_${i}`] || null,
            appointee_detail: isUnder18[i] && {
              id:ids?.appointeeIds[i],
              nominee: ids?.nomineeId[i],
              appointee_name: updateValuesData[`appointee_name_${i}`],
              mobile: updateValuesData[`appointee_contact_number_${i}`],
              mobile_country_code: "91",
              date_of_birth:
              updateValuesData[`appointee_date_of_birth_${i}`]?.format(
                  "YYYY-MM-DD"
                ),
              gender: updateValuesData[`apointee_gender_${i}`],
              relation:
              updateValuesData[
                  `apointee_relationship_with_life_to_be_assured_${i}`
                ],
            },
          });
        }

        try {
          const formattedValues = {
            insurance_detail: insurance_id,
            loan_detail: loan_id || id,
            customer_detail: customerId,
            insured_customer_detail: insured_customers,
            nominne: nominnes,
            insurance_coverage: {
              ids: ids.coverageId,
              monthly_income: monthlyIncome,
              emi_amount: values.emi_amount,
              policy_term: values.policy_term,
              sum_assured: values.sum_assured,
              pan_no: values.pan_number,
              property_type: values.property_type,
              total_premium_incl_gst: values.premium_amount,
              // emi_amount: loanData.requested_amount,
            },

            document: propertyDocumentDetail,
          };
          const response = await EditHDFCErgo(formattedValues);

          if ((response.status = 200 && response.success)) {
            message.success("HDFC insurance updated successfully");
            closeForm();
            form.resetFields();
            toggleRefreshInsurance();
            refreshDelete();
          } else {
            message.error("Failed to update details");
          }
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        }
      } else {
        try {
          const insured_customers = [];
          const nominnes = [];
          const numberOfInsured = newFormData.length; // Assuming the length represents the number of insured customers
          for (let i = 0; i < count; i++) {
            insured_customers.push({
              customer_detail: i === 1 ? oldCustomerId : customerId, // Correct index access
              property_type: updateValuesData[`property_type_${i}`],
              customer_address_type: updateValuesData[`customer_address_type_${i}`],
              ocupation: updateValuesData[`ocupation_${i}`],
              city: updateValuesData[`city_${i}`],
              state: updateValuesData[`state_${i}`],
              pincode: updateValuesData[`pincode_${i}`],
              address_line_1: updateValuesData[`address_line_1_${i}`],
              address_line_2: updateValuesData[`address_line_2_${i}`],
            });
          }

          for (let i = 0; i < numberOfInsured; i++) {
            nominnes.push({
              name: updateValuesData[`nominee_insured_customer_name_${i}`],
              mobile: updateValuesData[`nominee_mobile_${i}`],
              mobile_country_code: "91", // Fixed value
              date_of_birth:
              updateValuesData[`nominee_date_of_birth_${i}`]?.format("YYYY-MM-DD"), // Date formatting
              gender: updateValuesData[`nominee_gender_${i}`],
              address_type: updateValuesData[`address_type_${i}`],
              address_line_1: updateValuesData[`nominee_address_line_1_${i}`],
              address_line_2: updateValuesData[`nominee_address_line_2_${i}`],
              relation: values[`nominee_relation_with_assured_person_${i}`],
              city: updateValuesData[`nominee_city_${i}`],
              state: updateValuesData[`nominee_state_${i}`],
              pincode: updateValuesData[`nominee_pincode_${i}`],
              customer_detail: updateValuesData[`nominee_id_${i}`],
              appointee_detail: isUnder18 && {
                appointee_name: updateValuesData[`appointee_name_${i}`],
                mobile: updateValuesData[`appointee_contact_number_${i}`],
                mobile_country_code: "91",  
                date_of_birth:
                updateValuesData[`appointee_date_of_birth_${i}`]?.format(
                    "YYYY-MM-DD"
                  ),
                gender: updateValuesData[`apointee_gender_${i}`],
                relation:
                updateValuesData[
                    `apointee_relationship_with_life_to_be_assured_${i}`
                  ],
              },
            });
          }

          const formattedValues = {
            loan_detail: loan_id || id,
            insured_customer_detail: insured_customers.slice().reverse(),
            nominne: nominnes,
            insurance_coverage: {
              monthly_income: monthlyIncome,
              emi_amount: values.emi_amount,
              policy_term: values.policy_term,
              sum_assured: values.sum_assured,
              total_premium_incl_gst: values.premium_amount,
              property_type: values.property_type,
              pan_no: values.pan_number,
              // emi_amount: loanData.requested_amount,
            },

            document: propertyDocumentDetail,
          };

          const response = await postHDFCErgo(formattedValues);

          if ((response.status = 200 && response.success)) {
            message.success("HDFC insurance successfully created");
            closeForm();
            form.resetFields();
            setSelectedCustomer("");
            setSelectedInsuranceType("");
            toggleRefreshInsurance();
          } else {
            message.error(response.message);
          }
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        }
      }
    } catch (error) {
      console.error("Error occurred while submitting:", error);
      message.error("An error occurred while processing the request");
    } finally {
      setLoading(false); // Stop loading after the process completes
    }
  };

  const confirmDelete = () => {
    setImage(image.filter((image) => image !== deleteUploadPicture));
    const updatedFileList = fileList.filter(
      (item) => item.uid !== deleteUploadPicture.uid
    );
    setFileList(updatedFileList);
    setOpenUploadModal(false); // Close the modal
    setDeleteUploadPicture(null); // Reset the selected picture
  };

  const deletePropertyPictures = async () => {
    try {
      const response = await deleteHDFCDocumentDetailsByIdService(
        deletePicture.id
      );

      if (response?.status === 200 && response?.success === true) {
        message?.success("Document deleted successfully");
        setOpenModal(false);
        setOpenUploadModal(false);
        refreshDelete();
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/[^0-9.]/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  const handleInput = (e, field) => {
    // Allow letters, numbers, comma, forward slash, and empty spaces
    const newValue = e.target.value.replace(/[^A-Za-z0-9,/\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleCoverAnotherCustomer = (e) => {
    const coverAnother = e.target.value === "yes";
    const filteredPersonalData = personalData?.filter(
      (item) => item.id !== customerId
    );
    setPersonalData(filteredPersonalData);

    if (coverAnother) {
      setShow(true);
      // Covering another customer (two customers involved)
      setCount(2);
    } else {
      // Reverting to one customer
      setShow(false);
      setCount(1);

      // Remove the second object only if formData has two items
      setFormData((prevData) =>
        prevData.length > 1
          ? prevData.filter((_, index) => index !== 1)
          : prevData
      );
    }
  };

  const handleCustomerChange = (value) => {
    setOldCustomerId(customerId);
    setActiveKey("1")
    setAddMoreCustomer(true);
    setAddCustomer(prev => (prev ? [...prev, value] : [value]));
    setFormData((prevData) => {
      const currentFormValues = form.getFieldsValue(); // Get current form values

      // Ensure formData contains no more than two objects
      if (prevData.length >= 1) {
        // Replace the second object
        return [currentFormValues,{}];
      } else {
        // Add new data if there are less than two objects
        form.setFieldsValue({
          [`nominee_insured_customer_name_${count}`]: "",
          [`nominee_gender_${count}`]: undefined,
          [`nominee_mobile_${count}`]: "",
          [`nominee_date_of_birth_${count}`]: "",
          // [`nominee_address_line_1_${count}`]: "" ,
          // [`nominee_address_line_2_${count}`]: "",
          // [`nominee_city_${count}`]: undefined,
          // [`nominee_state_${count}`]: undefined,
          // [`nominee_pincode_${count}`]: undefined,
        });
        return [prevData, currentFormValues];
      }
    });
    setSelectedCustomer(value);
    // setAddressType(null)
    // setNomineeAddressType(null)
  };

  const onFormValuesChange = (changedValues, allValues) => {
    setFormData((prevFormValues) => {
      if (Array.isArray(prevFormValues)) {
        // If formValues is an array, update only the first index
        const updatedFormValues = [...prevFormValues]; // Make a copy of the existing array
        updatedFormValues[0] = allValues; // Update the first index with the new form values
        return updatedFormValues; // Return the updated array
      } else {
        // If formValues is not an array, set it directly
        return allValues;
      }
    });
  };
  
  const handleTabChange = (key) => {
    setActiveKey(key);
  };
  return (
    <div>
      <Tabs activeKey={activeKey} className="hdfc-insured-customer-data" onChange={handleTabChange}>
        {formData.length > 0 &&
          formData.map((formValue, index) => (
            <TabPane
              tab={`Insured Customer ${index + 1}`}
              key={index} // Ensure this key is unique
              closable={count > 1}
              forceRender={true} // Ensures the component re-renders on tab switch
            >
              <HDFCInsuredCustomerForm
                form={form}
                formValue={formValue} // This should reflect the current tab's data
                index={index}
                getFormData={getFormData}
                formData={formData} // Ensure this is the latest formData
                setFormData={setFormData} // Function to update formData
                yupSync={yupSync}
                filterOption={filterOption}
                occupationType={occupationType}
                handleInput={handleInput}
                city={city}
                states={states}
                pincode={pincode}
                insurance_id={insurance_id}
                handleNomineeChange={handleNomineeChange}
                personalData={personalData}
                nominee={nominee}
                handleDateChange={handleDateChange}
                isUnder18={isUnder18}
                nomineeIndex={nomineeIndex}
                relation={relation}
                count={count}
                addressTypeList={addressTypeList}
                setAddressType={setAddressType}
                customerId={customer_id}
                handleSubmit={handleSubmit}
                matchedCustomer={matchedCustomer}
                handlePincodeChange={handlePincodeChange}
                setNomineeAddressType={setNomineeAddressType}
                handleNomineePincodeChange={handleNomineePincodeChange}
                onFormValuesChange={onFormValuesChange} // Ensure this is used to sync changes
                activeKey={activeKey}
              />
            </TabPane>
          ))}
      </Tabs>

      {!insurance_id && customerId && !addMoreCustomer && (
        <Row gutter={8} align="middle" className="cover-another-customer">
          {" "}
          {/* Adjust gutter for spacing */}
          <Col span={12}>
            <Form.Item label="Do you want to Cover Another Customer?">
              <Radio.Group
                onChange={handleCoverAnotherCustomer}
                // value={addMoreCustomer ? "yes" : "no"}
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {show && (
            <Col span={12}>
              <div>
                <label className="hdfc-select-insured-customer">
                  <span className="hdfc-select-insured-customer-astrick">
                    *
                  </span>
                  Select Insured Customer{" "}
                </label>
                <br />
                <Select
                  placeholder="Please Select Customer"
                  onChange={handleCustomerChange}
                  allowClear
                  className="hdfc-select-insured-customer"
                >
                  {personalData.map((person) => (
                    <Option key={person.id} value={person.id}>
                      {person.first_name} {person.middle_name}{" "}
                      {person.last_name} ({person.customer_type.display_name})
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          )}
        </Row>
      )}
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          gender: matchedCustomer?.gender,
        }}
        onFinish={(values) =>
          handleSubmit(values)
        }
      >
        <div className="details_coverage_hdfc">
          Details Of The Coverage HDFC
        </div>

        <HDFCCoverage
          loanData={loanData}
          handleNumberFields={handleNumberFields}
          filterOption={filterOption}
          propertyType={propertyType}
          fileList={fileList}
          handleUploadChange={handleUploadChange}
          handleBeforeUpload={handleBeforeUpload}
          image={image}
          existingImages={existingImages}
          setDeleteUploadPicture={setDeleteUploadPicture}
          setOpenUploadModal={setOpenUploadModal}
          pdf_image={pdf_image}
          setDeletePicture={setDeletePicture}
          openUploadModal={openUploadModal}
          confirmDelete={confirmDelete}
          openModal={openModal}
          deletePropertyPictures={deletePropertyPictures}
          setOpenModal={setOpenModal}
          loan_id={loan_id}
        />

        <Space
          direction="horizontal"
          align="center"
          className="hdfc-cancel-save-button"
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default HDFCInsuranceForm;
