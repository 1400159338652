import React, { useEffect, useState, useRef, useCallback } from "react";
import { Space, Input, Tooltip, message, Typography, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getTableDataService } from "./dataTableService";

const DataTable = ({
  tableData,
  setTableData,
  apiPath,
  tableColumns,
  refreshData,
  searchPlaceholder,
  filters,
}) => {
  const [loading, setLoading] = useState(false);
  const latestRequest = useRef(null);
  const searchTimeout = useRef(null);
  const initialRender = useRef(true); // Track initial render

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sort: {
      field: null,
      order: null,
    },
    searchQuery: "",
  });

  const { Text } = Typography;

  const handleTableChange = (pagination, filter, sorter) => {
    setTableParams((prev) => ({
      ...prev,
      pagination,
      sort: {
        field: sorter.field,
        order: sorter.order,
      },
    }));
  };

  const fetchTableData = useCallback(async () => {
    try {
      setLoading(true);
      const requestId = Date.now();
      latestRequest.current = requestId;

      const { current, pageSize } = tableParams.pagination;
      const { field, order } = tableParams.sort;
      const searchQuery = tableParams.searchQuery.trim();
      const page = tableParams.pagination.current;
      const limit = tableParams.pagination.pageSize;

      const response = await getTableDataService(
        apiPath,
        current,
        pageSize,
        searchQuery,
        field,
        order,
        filters
      );

      if (latestRequest.current !== requestId) return; // Ignore outdated responses



      const totalPages = Math.ceil(response.count / limit);
      const validPage = Math.min(page, totalPages) || 1;

      setTableData(response?.data);
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: response?.count || 0,
          current: validPage
        },
      }));
    } catch (error) {
      message.error(error?.response?.data?.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  }, [
    apiPath,
    tableParams.pagination,
    tableParams.sort,
    tableParams.searchQuery,
    filters,
    setTableData,
  ]);

  // Fetch data on pagination/sorting/filter change but NOT searchQuery
  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiPath,
    tableParams.pagination.current,
    tableParams.pagination.pageSize,
    tableParams.sort.field,
    tableParams.sort.order,
    filters,
    refreshData,
  ]);

  // Debounced search, but prevent it on the initial render
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return; // Skip the first render to avoid the duplicate API call
    }

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      fetchTableData();
    }, 600); // 600ms debounce

    return () => clearTimeout(searchTimeout.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableParams.searchQuery]);

  return (
    <>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Input
          placeholder={searchPlaceholder || "Search"}
          suffix={
            <Tooltip title="Search in table.">
              <SearchOutlined className="site-form-item-icon" />
            </Tooltip>
          }
          value={tableParams.searchQuery}
          onChange={(e) =>
            setTableParams((prev) => ({
              ...prev,
              searchQuery: e.target.value,
              pagination: { ...prev.pagination, current: 1 }, // Reset to page 1
            }))
          }
        />
        <Text>
          Showing{" "}
          {tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
            1}{" "}
          to{" "}
          {Math.min(
            tableParams.pagination.pageSize * tableParams.pagination.current,
            tableParams.pagination.total
          )}{" "}
          out of {tableParams.pagination.total}
        </Text>
      </Space>

      <div style={{ marginTop: "16px" }}>
        <Table
          style={{ marginTop: "0.5rem" }}
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={tableData}
          pagination={{
            ...tableParams.pagination,
            showSizeChanger: true,
            position: ["bottomRight"],
          }}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
        />
      </div>
    </>
  );
};

export default DataTable;
