import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getCustomersByLoanId = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/customer-detail/personal-detail-list/${loanId}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getIncomeCalculationsByLoanId = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/loan/cam-income-detail/income-cal-scheme-wise/${loanId}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};



export const listMonthYear = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/list-of-month-year`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listFinancialYear = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/list-of-financial-year`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const createIncomeFormCam = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/income-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createBankingIncomeDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/income-detail/cam-banking-detail-save/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createCam = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/loan/additional-loan-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const LTVCalculationsByLoanId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/additional-loan-detail/get-all-data/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const LTVCalculationsById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/additional-loan-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateLTVCalculations = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/additional-loan-detail/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const updateIncomeFormByCustomerId = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/income-detail/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const loanbycustomerId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/income-detail/customer-income-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const IncomeMutiplierDataByLoanId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/year-income-detail/year-customer-income-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const updateIncomeMultiplierByCustomerId = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/year-income-detail/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const IncomeCalculationPost = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(`/api/v1/customer/income-detail/check-income-eligibility-customer/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createIncomeMultiplierFormCam = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/year-income-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createEMIEquliserFormCam = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/income-detail/cam-existing-loan-save/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getIncomeMultiplierCustomerDataByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/year-income-detail/year-customer-wise-income-detail/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getIncomeCustomerDataByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/income-detail/customer-wise-income-detail/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getEMIEqualiserDataByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/existing-loan-detail/existing-loan-detail-by-customer/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getRentalDataByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/rental/rental-detail-by-customer/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createRentalFormCam = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/income-detail/cam-rental-detail-save/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const deleteIncomeDetailsByIdService = (id, scheme_detail, income_considered) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/income-detail/?id=${id}&scheme_detail=${scheme_detail}&income_considered=${income_considered}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteEMIEqualiserDetailsByIdService = (loan_id, customer_id, scheme_detail, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/income-detail/exist-loan?loan_id=${loan_id}&customer_id=${customer_id}&scheme_detail=${scheme_detail}&id=${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}



export const deleteIncomeMultipierAndEstimateByIdService = (id, scheme_detail, income_considered) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/year-income-detail/?id=${id}&scheme_detail=${scheme_detail}&income_considered=${income_considered}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getRentalDetailsByIdLoanId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/employment-detail/employment-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getCAMDetailsCustomerId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/customer/customer-with-scheme-income-calculation/customer-income-with-scheme-by-loan-id/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteRentalDetailsByIdLoanId = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/employment-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listCustomerNameService = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-detail/personal-detail-list/${loanId}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listSchemeService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/scheme/get-scheme-by-loantype/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listLoanPurposeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/loan-purpose/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listLoanStatusService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/loan-status/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listBankService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/banks/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listSourceOfLiability = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/source-of-liabilty`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const checkEmploymentDetailsByCustomerId = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/income-detail/check-customer-employment/?customer_detail=${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getBankingDetailsCreditByCustomerId = (customer_id, loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/account-aggregator/get-month-wise-for-cam?customer_id=${customer_id}&loan_detail_id=${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getBankingDetailsBankBalanceByCustomerId = (customer_id, loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/account-aggregator/get-day-wise-for-cam?customer_id=${customer_id}&loan_detail_id=${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listPropertyType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/property-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllMunicipalTaxPaidByService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/munciple-tax-paid-by`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getAllMaintencePaidByService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/maintence-paid-by`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getAllGSTPaidByService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/gst-paid-by`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllRentalModeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/rental-mode`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getSchemeCheckMatchService = (loan_id, scheme_detail) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/income-detail/check-scheme-configuration-match-or-not/?loan_detail=${loan_id}&scheme_detail=${scheme_detail}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getLoanDetailsByLoanIdForScheme = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/loan/loan-detail/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};