const masterConfig = [
    'Asset',
    'Address Type',
    'Agency',
    'Agency Type',
    'Agent',
    'Bank',
    'Bank Branch',
    'Caste Category',
    'Charge',
    'Charge Status',
    'Charge Type',
    'City',
    'Country',
    'Customer Type',
    'Department',
    'Designation',
    'Deviation',
    'Deviation Category',
    'District',
    'Document Category',
    'Document Type',
    'Employment Nature',
    'Employment Type',
    'End Use',
    'Finance Institution',
    'Household Expense',
    'Insurance Plan Magma',
    'Industry Type',
    'Investment Type',
    'Insurance company',
    'ICICI Questionnaire',
    'Land Authority',
    'Lead Status',
    'Line Of Work',
    'Loan Purpose',
    'Loan Status',
    'Loan Type',
    'Location',
    'Negative Profile',
    'Media Category',
    'Media Type',
    'Occupation',
    'Organization Nature',
    'Part Disbursement',
    'Permissions',
    'Pincode',
    'Product',
    'Property Type',
    'Property Structure',
    'Questionnaire',
    'Questionnaire Answer',
    'Questionnaire Category',
    'RTO',
    'Relation',
    'Role',
    'Sanction Condition Category',
    'Spoc Location',
    'Sanction Condition',
    'Scheme',
    'Scheme Configration',
    'Source',
    'Stage',
    'Stage Flow',
    'Stage Status',
    'State',
    'Status',
    'Sub Section',
    'Technical Document',
    'Test Program',
    'Time Sheet Event Type',
    'Vastu Account',
    'Vehicle Body',
    'Vehicle Manufacturer',
    'Vehicle Model',
    'Vehicle Variant',
    'Vehicle Type',
    'Zones'
]
export default masterConfig;