import React, { useState, useEffect } from "react";
import "../styles/addressDetails.css";
import location from "../assets/Location.png";
import add from "../assets/Add.png";
import "../../style/customerStyle.css";
import { Drawer, Modal, message, Tooltip } from "antd";
import Header from "../../../../layout/views/Header";
import OfficeForm from "./OfficeForm";
import {getAddresslDetailsByIdService } from "../services/addressDetailsServices";
import { useParams } from "react-router-dom";
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import HistoryTable from "../../../history/views/HistoryTable";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import "../../../../commonCss/commonStyle.css";
import AddressType from "./AddressType";
import EditButton from "../../../../../utils/editButton/EditButton";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import GoogleMapLocation from "../../../../../utils/googleMapLocation/GoogleMapLocation";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FaExclamationCircle } from "react-icons/fa";

const AddressDetails = ({ loanDetails }) => {
  const { encrypted_loan_id } = useParams();
  const [currentOpen, setCurrentOpen] = useState(false);
  const [addressData, setAddressData] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [round, setRound] = useState();
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [addressType, setAddressType] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedCustomerAdd, setSelectedCustomerAdd] = useState(null);
  const [openAddressType, setOpenAddressType] = useState(false);
  const { user_data } = useSelector((state) => state.user);
  const [isMapModalVisible, setIsMapModalVisible] = useState(false);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [branchLatitude, setBranchLatitude] = useState();
  const [branchLongitude, setBranchLongitude] = useState();
  const [, setSelectedAddress] = useState(null);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = () => {
    setCurrentOpen(true);
  };
  const onClose = () => {
    setCurrentOpen(false);
    setSelectedCustomerId(null);
    setSelectedAddress(null);
  };

  const showDrawerAddressType = () => {
    setOpenAddressType(true);
  };

  const onCloseAddressType = () => {
    setOpenAddressType(false);
  };

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  const renderEditButton = (showDrawer, id, person, address) =>
    id ? (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
          setSelectedCustomerId(id);
          setAddressType(address);
          setSelectedPerson(person);
        }}
      >
        {/* <img src={location} alt="Location Button" /> */}
        <EditButton />
      </div>
    ) : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAddresslDetailsByIdService(loan_id);
        setAddressData(response.data.results);
        setRound(response.data); // Update officeCount from API response
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    if (loanDetails) {
      setBranchLatitude(loanDetails?.location?.latitude)
      setBranchLongitude(loanDetails?.location?.longitude)
    }


    fetchData();
    // eslint-disable-next-line
  }, [refreshData, loan_id]);



  const isEmpty = (obj) => Object.keys(obj).length === 0;

  const hasAddressData = (customerAddress) => {
    return (
      !isEmpty(customerAddress.current_address) ||
      !isEmpty(customerAddress.permanent_address) ||
      customerAddress.office_address?.length > 0
    );
  };

  const office_Headings = [
    "Key Parameter's",
    "Office Address",
    "Address Line 1",
    "Address Line 2",
    "Landmark",
    "City",
    "Taluka",
    "District",
    "State",
    "Pincode",
    "Country",
  ];

  const Permanent_address = [
    "Key Parameter's",
    "Permanent Address",
    "Address Line 1",
    "Address Line 2",
    "Landmark",
    "City",
    "Taluka",
    "District",
    "State",
    "Pincode",
    "Country",
  ];

  const Current_address = [
    "Key Parameter's",
    "Current Address",
    "Address Line 1",
    "Address Line 2",
    "Landmark",
    "City",
    "Taluka",
    "District",
    "State",
    "Pincode",
    "Country",
  ];

  const fieldNameMap = {
    address_line_1: "Address Line 1",
    address_line_2: "Address Line 2",
    landmark: "Landmark",
    city: "City",
    taluka: "Taluka",
    district: "District",
    state: "State",
    pincode: "Pincode",
    country: "Country",
    bd_modified_at: "Business Modified Date",
    bd_create_date_at: "Business Created Date"
  };

  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return "tab_form_main_data_two_entries";
      case 3:
        return "tab_form_main_data_three_entries";
      // case 4:
      //   return 'tab_form_main_data_four_entries';
      default:
        return "";
    }
  };

  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header
              title="Edit"
              onClose={onClose}
              name={`${selectedPerson}'s ${addressType === "Permanent" ? "Permanent Address" : addressType} Details`}
            />
          }
          width={970}
          open={currentOpen}
          onClose={onClose}
          closable={false}
          id={selectedCustomerId}
        >
          <OfficeForm
            refreshData={toggleRefreshData}
            closeForm={onClose}
            id={selectedCustomerId}
            customer_id={selectedCustomerAdd}
            open={currentOpen}
          />
        </Drawer>

        <Drawer
          title={
            <Header
              title="Add"
              onClose={onCloseAddressType}
              name={`${selectedPerson?.customer_name}'s Address Details`}
            />
          }
          width={970}
          onClose={onCloseAddressType}
          open={openAddressType}
          closable={false}
        >
          <AddressType
            refreshData={toggleRefreshData}
            open={openAddressType}
            customer_id={selectedCustomerAdd}
            closeForm={onCloseAddressType}
          // id={selectedCustomer}
          />
        </Drawer>

        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : addressData?.some((person) =>
            hasAddressData(person?.customer_address)
          ) ? (
            //  addressData?.length > 0 ? (
            <>
              <table className="tab_form_main_table">
                <thead>
                  <tr>
                    <th className="tab_form_key_parameters">
                      {Current_address[0]}
                      {Current_address[0] === "Current Address" && (
                        <>{showDrawer()}</>
                      )}
                    </th>

                    {addressData?.map((person, index) => (
                      <th key={index} className="tab_form_top_heading">
                        <div className="add_button_alignment">
                          <div>
                            <span>{`${person?.customer_name}`} </span>
                            <span>
                              <span>{`(${person?.customer_type})`} </span>
                              {/* <img
                                src={verify}
                                alt="group"
                               
                                className="verify_img"
                              /> */}
                            </span>
                          </div>
                          <ConditionalRender
                            userPermissions={user_data?.permissions}
                            requiredPermissions={["POSTCUST"]}
                          >
                            <div
                              className="tab_form_sub_edit"
                              onClick={() => {
                                showDrawerAddressType();
                                setSelectedCustomerAdd(person?.customer_id);
                                setSelectedPerson(person)
                              }}
                            >
                              <img src={add} alt="Add Button" />
                            </div>
                          </ConditionalRender>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {round &&
                    [...Array(round?.max_count)]?.map((_, rowIndex) =>
                      Current_address?.slice(1)?.map((header, colIndex) => (
                        <tr
                          key={`row-${rowIndex}`}
                          className={
                            header === "Current Address" ||
                              header === "Current Address"
                              ? "address-row"
                              : header === "PD Entries"
                                ? "address-pd-row "
                                : header === "Staying for Years"
                                  ? "tab_form_highlighted_row_orange"
                                  : ""
                          }
                        >
                          <td
                            key={`col-${colIndex}`}
                            className={`tab_form_left_heading${header === "Current Address"
                              ? " tab_form_highlighted_row_blue"
                              : header === "PD Entries"
                                ? " dependent_gray_highlighted-row"
                                : header === "Staying for Years"
                                  ? " tab_form_highlighted_row_orange"
                                  : ""
                              }`}
                          >
                            {header}
                          </td>
                          {addressData?.map((person, colIndex) => (
                            <td
                              key={`col-${colIndex}`}
                              className={`tab_form_main_data_table ${getTableColumnClass(
                                addressData.length
                              )}`}
                            >
                              {person.customer_address.current_address ? (
                                <>
                                  {header === "Address Line 1"
                                    ? person?.customer_address?.current_address
                                      ?.address_line_1 || "-"
                                    : header === "Address Line 2"
                                      ? person?.customer_address?.current_address
                                        ?.address_line_2 || "-"
                                      : header === "Landmark"
                                        ? person?.customer_address?.current_address
                                          ?.landmark || "-"
                                        : header === "City"
                                          ? person?.customer_address?.current_address
                                            ?.city?.display_name || "-"
                                          : header === "Taluka"
                                            ? person?.customer_address?.current_address
                                              ?.taluka || "-"
                                            : header === "District"
                                              ? person?.customer_address?.current_address
                                                ?.district?.display_name || "-"
                                              : header === "State"
                                                ? person.customer_address.current_address
                                                  .state?.display_name || "-"

                                                : header === "Pincode" ? (
                                                  <>
                                                    {`${person.customer_address.current_address.pincode?.display_name || "-"}`}
                                                    {person.customer_address.current_address.is_negative_profile === "yes" && (
                                                      <Tooltip title="Negative Area">
                                                      <span className="status_false_flage">
                                                        <FaExclamationCircle />
                                                      </span>
                                                    </Tooltip>
                                                    )}
                                                  </>
                                                )

                                                  : header === "Country"
                                                    ? person.customer_address.current_address
                                                      .country?.display_name || "-"
                                                    : ""}

                                  {header === "Current Address" && (
                                    <div className="current_adddres_div">

                                      {person?.customer_address?.current_address
                                        ?.created_at &&
                                        person?.customer_address
                                          ?.current_address?.modified_at &&
                                        isModifiedAfterCreated(
                                          person.customer_address
                                            .current_address.created_at,
                                          person.customer_address
                                            .current_address.modified_at
                                        ) && (
                                          <div className="tab_form_sub_edit">
                                            <HistoryTable
                                              id={
                                                person?.customer_address
                                                  ?.current_address?.id
                                              }
                                              bench_id={"a047ebe19441dc89"}
                                              fieldNameMap={fieldNameMap}
                                            />
                                          </div>
                                        )}
                                      <ConditionalRender
                                        userPermissions={user_data?.permissions}
                                        requiredPermissions={["EDITCUST"]}
                                      >

                                        {renderEditButton(
                                          showDrawer,
                                          person.customer_address
                                            ?.current_address?.id,
                                          person?.customer_name,
                                          person.customer_address
                                            ?.current_address?.address_type
                                            ?.display_name
                                        )}

                                        <div
                                          className="tab_form_sub_edit"

                                          onClick={() => {
                                            const currentAddress = person.customer_address?.current_address;
                                            if (currentAddress?.latitude && currentAddress?.longitude) {
                                              setLatitude(currentAddress.latitude);
                                              setLongitude(currentAddress.longitude);
                                              setIsMapModalVisible(true);
                                            }
                                          }}
                                        >
                                          {person.customer_address?.current_address?.latitude &&
                                            person.customer_address?.current_address?.longitude ? (
                                            <img src={location} alt="Location Button" />
                                          ) : ""}
                                        </div>

                                      </ConditionalRender>
                                    </div>
                                  )}
                                </>

                              ) : header === "Current Address" ? (
                                ""
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))
                    )}
                </tbody>
              </table>


              <table className="tab_form_main_table">
                <tbody>
                  {round &&
                    [...Array(round.max_count)]?.map((_, rowIndex) =>
                      Permanent_address?.slice(1)?.map((header, colIndex) => (
                        <tr
                          key={`row-${rowIndex}`}
                          className={
                            header === "Permanent Address" ||
                              header === "Permanent Address"
                              ? " address-row"
                              : header === "PD Entries"
                                ? "address-pd-row "
                                : header === "Staying for Years"
                                  ? " tab_form_highlighted_row_orange"
                                  : ""
                          }
                        >
                          <td
                            key={`col-${colIndex}`}
                            className={`tab_form_left_heading${header === "Permanent Address"
                              ? " tab_form_highlighted_row_blue"
                              : header === "PD Entries"
                                ? " dependent_gray_highlighted-row"
                                : header === "Staying for Years"
                                  ? " tab_form_highlighted_row_orange"
                                  : ""
                              }`}
                          >
                            {header}
                          </td>
                          {addressData?.map((person, colIndex) => (
                            <td
                              key={`col-${colIndex}`}
                              className={`tab_form_main_data_table ${getTableColumnClass(
                                addressData.length
                              )}`}
                            >

                              {person.customer_address.permanent_address ? (
                                <>
                                  {header === "Address Line 1"
                                    ? person.customer_address.permanent_address
                                      .address_line_1 || "-"
                                    : header === "Address Line 2"
                                      ? person.customer_address.permanent_address
                                        .address_line_2 || "-"
                                      : header === "Landmark"
                                        ? person.customer_address.permanent_address
                                          .landmark || "-"
                                        : header === "City"
                                          ? person.customer_address.permanent_address
                                            .city?.display_name || "-"
                                          : header === "Taluka"
                                            ? person.customer_address.permanent_address
                                              .taluka || "-"
                                            : header === "District"
                                              ? person.customer_address.permanent_address
                                                .district?.display_name || "-"
                                              : header === "State"
                                                ? person.customer_address.permanent_address
                                                  .state?.display_name || "-"

                                                : header === "Pincode" ? (
                                                  <>
                                                    {`${person.customer_address.permanent_address
                                                      .pincode?.display_name || "-"}`}
                                                    {person.customer_address.permanent_address.is_negative_profile === "yes" && (
                                                       <Tooltip title="Negative Area">
                                                       <span className="status_false_flage">
                                                         <FaExclamationCircle />
                                                       </span>
                                                     </Tooltip>
                                                    )}
                                                  </>
                                                )
                                                  : header === "Country"
                                                    ? person.customer_address.permanent_address
                                                      .country?.display_name || "-"
                                                    : ""}

                                  {header === `Permanent Address` && (
                                    <div
                                      className="current_adddres_div"
                                    >
                                      <ConditionalRender
                                        userPermissions={user_data?.permissions}
                                        requiredPermissions={["DELTCUST"]}
                                      >
                                        {Array.isArray(person.customer_address?.permanent_address?.address_check_status) &&
                                          person.customer_address?.permanent_address?.address_check_status.map((statusObj, index) => (
                                            statusObj.status === "True" ? (
                                              <div key={index} className="status_true_flage">
                                                <Tooltip title={
                                                  <>
                                                    {statusObj.message}
                                                  </>
                                                }>
                                                  <RiVerifiedBadgeFill />
                                                </Tooltip>
                                              </div>
                                            ) : statusObj.status === "False" ? (
                                              <div key={index} className="status_false_flage">
                                                <Tooltip
                                                  title={
                                                    <>
                                                      {statusObj.message}
                                                    </>
                                                  }
                                                >
                                                  <FaExclamationCircle />
                                                </Tooltip>
                                              </div>
                                            ) : null
                                          ))}
                                      </ConditionalRender>

                                      {person?.customer_address
                                        ?.permanent_address?.created_at &&
                                        person?.customer_address
                                          ?.permanent_address?.modified_at &&
                                        isModifiedAfterCreated(
                                          person.customer_address
                                            .permanent_address.created_at,
                                          person.customer_address
                                            .permanent_address.modified_at
                                        ) && (
                                          <HistoryTable
                                            id={
                                              person?.customer_address
                                                ?.permanent_address?.id
                                            }
                                            bench_id={"a047ebe19441dc89"}
                                            fieldNameMap={fieldNameMap}
                                          />
                                        )}
                                      <ConditionalRender
                                        userPermissions={user_data?.permissions}
                                        requiredPermissions={["EDITCUST"]}
                                      >
                                        {renderEditButton(
                                          showDrawer,
                                          person?.customer_address
                                            ?.permanent_address?.id,
                                          person?.customer_name,
                                          person.customer_address
                                            ?.permanent_address?.address_type
                                            ?.display_name
                                        )}
                                        <div className="tab_form_sub_edit"
                                          onClick={() => {
                                            const permantAddress = person.customer_address?.permanent_address;
                                            if (permantAddress?.latitude && permantAddress?.longitude) {
                                              setLatitude(person.customer_address.permanent_address.latitude)
                                              setLongitude(person.customer_address.permanent_address.longitude)
                                              setIsMapModalVisible(true)
                                            }
                                          }
                                          }>
                                          {person.customer_address?.permanent_address?.latitude &&
                                            person.customer_address?.permanent_address?.longitude ? (
                                            <img src={location} alt="Location Button" />
                                          ) : ""}
                                        </div>
                                      </ConditionalRender >
                                    </div>
                                  )}
                                </>
                              ) : header === "Permanent Address" ? (
                                ""
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))
                    )}
                </tbody>
              </table>

              <table className="tab_form_main_table">
                <tbody>
                  {round &&
                    [...Array(round.office_address_count)]?.map((_, rowIndex) =>
                      office_Headings?.slice(1)?.map((header, colIndex) => (
                        <tr
                          key={`row-${rowIndex}`}
                          className={
                            header === "Office Address" ||
                              header === "Office Address"
                              ? " address-row"
                              : header === "PD Entries"
                                ? "address-pd-row "
                                : header === "Staying for Years"
                                  ? " tab_form_highlighted_row_orange"
                                  : ""
                          }
                        >
                          <td
                            key={`col-${colIndex}`}
                            className={`tab_form_left_heading${header === "Office Address"
                              ? " tab_form_highlighted_row_blue"
                              : header === "PD Entries"
                                ? " dependent_gray_highlighted-row"
                                : header === "Staying for Years"
                                  ? " tab_form_highlighted_row_orange"
                                  : ""
                              }`}

                          >
                            {header === "Office Address"
                              ? `Office Address ${rowIndex + 1}`
                              : header}
                          </td>
                          {addressData?.map((person) => (
                            <td
                              key={`col-${colIndex}`}
                              className={`tab_form_main_data_table ${getTableColumnClass(
                                addressData.length
                              )}`}
                            >
                              {person?.customer_address ? (
                                <>
                                  {header === "Address Line 1"
                                    ? person?.customer_address?.office_address[
                                      rowIndex
                                    ]?.address_line_1 || "-"
                                    : header === "Address Line 2"
                                      ? person?.customer_address?.office_address[
                                        rowIndex
                                      ]?.address_line_2 || "-"
                                      : header === "Landmark"
                                        ? person?.customer_address?.office_address[
                                          rowIndex
                                        ]?.landmark || "-"
                                        : header === "City"
                                          ? person?.customer_address?.office_address[
                                            rowIndex
                                          ]?.city?.display_name || "-"
                                          : header === "Taluka"
                                            ? person?.customer_address?.office_address[
                                              rowIndex
                                            ]?.taluka || "-"
                                            : header === "District"
                                              ? person?.customer_address?.office_address[
                                                rowIndex
                                              ]?.district?.display_name || "-"
                                              : header === "State"
                                                ? person?.customer_address?.office_address[
                                                  rowIndex
                                                ]?.state?.display_name || "-"

                                                : header === "Pincode" ? (
                                                  <>
                                                    {`${person?.customer_address?.office_address[
                                                      rowIndex
                                                    ]?.pincode?.display_name || "-"}`}
                                                    {person?.customer_address?.office_address[
                                                      rowIndex
                                                    ]?.is_negative_profile === "yes" && (
                                                      <Tooltip title="Negative Area">
                                                      <span className="status_false_flage">
                                                        <FaExclamationCircle />
                                                      </span>
                                                    </Tooltip>
                                                      )}
                                                  </>
                                                )
                                                  : header === "Country"
                                                    ? person?.customer_address?.office_address[
                                                      rowIndex
                                                    ]?.country?.display_name || "-"
                                                    : ""}
                                  {header === "Office Address" && (
                                    <div
                                      className="current_adddres_div"
                                    >

                                      {person?.customer_address?.office_address[
                                        rowIndex
                                      ]?.created_at &&
                                        person?.customer_address
                                          ?.office_address[rowIndex]
                                          ?.modified_at &&
                                        isModifiedAfterCreated(
                                          person.customer_address
                                            .office_address[rowIndex]
                                            .created_at,
                                          person.customer_address
                                            .office_address[rowIndex]
                                            .modified_at
                                        ) && (
                                          <HistoryTable
                                            id={
                                              person?.customer_address
                                                ?.office_address[rowIndex]?.id
                                            }
                                            bench_id={"a047ebe19441dc89"}
                                            fieldNameMap={fieldNameMap}
                                          />


                                        )}

                                      <ConditionalRender
                                        userPermissions={user_data?.permissions}
                                        requiredPermissions={["EDITCUST"]}
                                      >
                                        {renderEditButton(
                                          showDrawer,
                                          person.customer_address
                                            ?.office_address[rowIndex]?.id,
                                          person?.customer_name,
                                          person?.customer_address
                                            ?.office_address[rowIndex]
                                            ?.address_type?.display_name
                                        )}

                                        <div className="tab_form_sub_edit"
                                          onClick={() => {
                                            const currentAddress = person.customer_address?.office_address[rowIndex];
                                            if (currentAddress?.latitude && currentAddress?.longitude) {
                                              setLatitude(currentAddress.latitude);
                                              setLongitude(currentAddress.longitude);
                                              setIsMapModalVisible(true);
                                            }
                                          }}
                                        >
                                          {person.customer_address?.office_address[rowIndex]?.latitude &&
                                            person.customer_address?.office_address[rowIndex]?.longitude ? (
                                            <img src={location} alt="Location Button" />
                                          ) : null}
                                        </div>

                                      </ConditionalRender>
                                    </div>
                                  )}
                                </>
                              ) : (
                                person?.customer_address?.office_address[
                                  rowIndex
                                ]?.address_type?.display_name(
                                  header === "Office Address" ? (
                                    ""
                                  ) : (
                                    <span>-</span>
                                  )
                                )
                              )}
                            </td>
                          ))}
                        </tr>
                      ))
                    )}
                </tbody>
              </table>
            </>
          ) : (
            <div className='fade-in'>
              <div>
                <table className='tab_form_main_table no_data_table_height'>
                  <thead>
                    <tr>
                      <th className="tab_form_key_parameters">
                        {Current_address[0]}
                        {Current_address[0] === "Current Address" && (
                          <>{showDrawer()}</>
                        )}
                      </th>
                      {addressData?.map((person, index) => (
                        <th
                          key={index}
                          className="tab_form_top_heading no_data_heading_bottom_border"
                        >
                          <span className='no_data_heading_content'>
                            <div>
                              <span>{`${person?.customer_name}`} </span>
                              <span>
                                <span>{`(${person?.customer_type})`} </span>
                              </span>
                            </div>
                            <ConditionalRender
                              userPermissions={user_data?.permissions}
                              requiredPermissions={["POSTCUST"]}
                            >
                              <div
                                className="tab_form_sub_edit"
                                onClick={() => {
                                  showDrawerAddressType();
                                  setSelectedCustomerAdd(person?.customer_id);
                                  setSelectedPerson(person)
                                }}
                              >
                                <img src={add} alt="Add Button" />
                              </div>
                            </ConditionalRender>
                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Placeholder to ensure table body aligns */}
                    {addressData?.length ? (
                      <tr>
                        <td colSpan={addressData?.length + 1}>
                          {/* Your data rows go here */}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          colSpan={addressData?.length + 1}
                        ></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div
                className='no_data_icon'
              >
                <CustomNoData />
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Google Map Location"
        visible={isMapModalVisible}
        onCancel={() => setIsMapModalVisible(false)}
        footer={null}
        width={800}
      >
        <GoogleMapLocation
          latitude={latitude} longitude={longitude} branchLatitude={branchLatitude} branchLongitude={branchLongitude}
        />
      </Modal>
    </>
  );
};

export default AddressDetails;
