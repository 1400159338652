import { DownOutlined, UpOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import '../styles/sendbacklegal.css';
import { Button, Col, Form, message, Select, Space } from 'antd';
import ErrorMessage from '../../../utils/errorHandling/ErrorMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { decrypt } from '../../../utils/cryptoUtils/cryptoUtils';
import { getLegalQueryStatus, getLegalSendBackData, updateLegalSendBackData } from '../services/propertyDescriptionService';
import { stageToCompleteService } from '../../loanApplication/loanSummary/services/loanapplicationService';
import { useSelector } from 'react-redux';

const LegalSendBack = () => {
    const [collapsed1, setCollapsed1] = useState(true);
    const { encrypted_stage_id, encrypted_loan_id } = useParams();
    const [legalSendBackData, setLegalSendBackData] = useState([]);
    const [legalStatusData, setLegalStatusData] = useState([]);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isVendorCompleteEnabled, setIsVendorCompleteEnabled] = useState(false);
    const { user_data } = useSelector((state) => state.user);
    const toggleCollapse1 = () => {
        setCollapsed1(!collapsed1);
    };

    let stage_id = null;
    try {
        stage_id = decrypt(encrypted_stage_id);
    } catch (error) {
        message.error("Invalid stage ID");
    }

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await getLegalSendBackData(stage_id);
    //             setLegalSendBackData(response.data);

    //             const StatusResponse = await getLegalQueryStatus();
    //             setLegalStatusData(StatusResponse.data);

    //             // Set initial form values dynamically
    //             const initialValues = {};
    //             response.data.forEach((item) => {
    //                 initialValues[`query_${item.id}`] = item.query_status; // Set query_status as initial value
    //             });
    //             form.setFieldsValue(initialValues);
    //             console.log(initialValues,"initialValues");
    //             checkVendorComplete(initialValues); 
    //         } catch (error) {
    //             message.error(
    //                 ErrorMessage(error?.response?.status, error?.response?.data?.message)
    //             );
    //         }
    //     };

    //     fetchData();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [stage_id]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getLegalSendBackData(stage_id);
                const legalData = response.data; // Store fetched data in a variable
                setLegalSendBackData(legalData); // Update state first

                const StatusResponse = await getLegalQueryStatus();
                setLegalStatusData(StatusResponse.data);

                // Set initial form values dynamically
                const initialValues = {};
                legalData.forEach((item) => {
                    initialValues[`query_${item.id}`] = item.query_status;
                });
                form.setFieldsValue(initialValues);

                // Run after React updates the state
                setTimeout(() => checkVendorComplete(initialValues), 0);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };

        if (stage_id) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stage_id]);

    const checkVendorComplete = (values) => {

        const allCompletedOrCancelled = Object.values(values).every(
            (status) => status === "Completed" || status === "Cancel"
        );

        setIsVendorCompleteEnabled(allCompletedOrCancelled);
    };

    const handleCompleteStage = async (stage_id) => {
        try {
            const payload = {
                stage: "LEGAL605",
                stage_status: "VENDORCO",
                user: user_data?.id
            };
            const response = await stageToCompleteService(stage_id, payload);
            if ((response.status = 200 && response.success)) {
                message.success(`Legal stage completed successfully`);
                navigate(`/loandetails/${encrypted_loan_id}`);
            }
        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };

    const handleSubmit = async (values) => {
        try {
            const queries = legalSendBackData.map((item) => ({
                id: item.id,
                query_status: values[`query_${item.id}`],
            }));

            const payload = { queries };
            const response = await updateLegalSendBackData(payload);
            if (response.status === 200 && response.success) {
                message.success("Send back status updated successfully");
                checkVendorComplete(values); // Check button status after submission
            }
        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };

    return (
        <div style={{ marginTop: "20px" }}>
            <div className="legal_views_document_container">
                <div className="legal_miscellaneous_details_blue_heading">
                    <div className="legal_input_heading_name">Send Back</div>
                    <div className="legal_input_button_space">
                        <div className="legal_input_edit_button">
                            <Button type="primary" disabled={!isVendorCompleteEnabled}
                                onClick={() => handleCompleteStage(stage_id)}>
                                Vendor Complete
                            </Button>
                        </div>
                        <div className="legal_input_collapse_icon" onClick={toggleCollapse1}>
                            {collapsed1 ? <DownOutlined /> : <UpOutlined />}
                        </div>
                    </div>
                </div>
                {collapsed1 && (
                    <>
                        <Form
                            form={form}
                            onFinish={handleSubmit}
                            onValuesChange={(_, allValues) => checkVendorComplete(allValues)}
                        >
                            <div>
                                {legalSendBackData?.map((item) => (
                                    <div key={item.id} style={{ display: "flex" }}>
                                        <div className="send_back_query_label">{item.query}</div>
                                        <div className="send_back_value">
                                            <Col span={8}>
                                                <Form.Item
                                                    name={`query_${item.id}`} // Unique field name
                                                    label=""
                                                >
                                                    <Select placeholder="Please Select" showSearch allowClear>
                                                        {legalStatusData.map((type) => (
                                                            <Select.Option key={type} value={type}>
                                                                {type}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Space
                                direction="horizontal"
                                align="center"
                                style={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    margin: "0px 10px 10px 0px",
                                }}
                            >
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Space>
                        </Form>
                    </>
                )}
            </div>
        </div>
    );
};

export default LegalSendBack;
