import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  DatePicker,
} from "antd";
import * as Yup from "yup";
import {
  getSelfemplyedByIdService,
  listEmploymentNatureService,
  listIndustryervice,
  listLineOfWorkService,
  listOrganizationNatureService,
  updateSelfemployedByIdService,
  listSourceOfFund,
  listBusinessLegalStructure,
  listBusinessSeasonality,
  createSelfEmployedService,
  getAllBusinessProofChoiceService,
  getAllOwnershipPrimisesChoiceService,
  getAllLocationTypeService,
  getAllStructureTypeService,
  listFamilyBusiness,
  listOccupationService,
} from "../services/employmentDetailsServices";
import "moment/locale/en-gb";
import moment from "moment";
import { useSelector } from "react-redux";

import dayjs from "dayjs";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import { useDispatch } from "react-redux";
import { addNewCustomerEmployment } from "../../../../../redux/salesFormSlice";
import { listAddressTypeService, pincodeCodeService } from "../../dependentDetails/services/dependentDetailsServices";
import { listCity, listCountry, listDistrict, listPincode, listState } from "../../../../LegalValuation/services/propertyDescriptionService";
import GetDropdown from "../../../../../utils/getDropdown/GetDropdown";
const { Option } = Select;


const validationSchema = Yup.object().shape({
  organization_name: Yup.string()
    .required("Organization name is required")
    .min(2, "Organization name must be between 2 and 128 characters")
    .max(128, "Organization name must be between 2 and 128 characters"),
  industry_type: Yup.string()
    .required("Industry type is required"),
  type_of_business: Yup.string()
    .required("Type of business is required"),
  year_of_start: Yup.string()
    .required("Year of start is required"),
  total_business_experience: Yup.string()
    .required("Total experience is required"),
  managed_by: Yup.string()
    .required("Managed by is required")
    .min(2, "Managed by must be between 2 and 128 characters")
    .max(128, "Managed by must be between 2 and 128 characters"),
  number_of_employees: Yup.string()
    .required("Number of employees is required")
    .test(
      "is-positive-integer",
      "Number of employees can not be 0",
      (value) => {
        if (!value) return false; // Ensuring value is not empty
        const intValue = parseInt(value);
        return intValue > 0 && Number.isInteger(intValue);
      }
    )
    .test(
      "is-less-than-1000000",
      "Number of employees must be less than 1000000",
      (value) => {
        if (!value) return false; // Ensuring value is not empty
        const intValue = parseInt(value);
        return intValue < 1000000;
      }
    ),
  no_of_branches: Yup.string()
    .required("Number of branches is required")
    .test(
      "is-less-than-100000",
      "Number of branches must be less than 100000",
      (value) => {
        if (!value) return false; // Ensuring value is not empty
        const intValue = parseInt(value);
        return intValue < 100000;
      }
    ),
  gross_income: Yup.string()
    .required("Gross income is required")
    .test(
      "not-zero",
      "Gross income should not be 0",
      (value) => value && parseFloat(value) !== 0
    ),
  net_income: Yup.string()
    .required("Net income is required"),
  business_legal_structure: Yup.string()
    .required("Business legal structure is required"),
  nature_of_employment: Yup.string()
    .required("Employment nature is required"),
  experience_in_current_organization: Yup.number()
    .required("Experience is required"),
  source_of_fund: Yup.string()
    .required("Source of fund is required"),
  business_seasonality: Yup.string()
    .required("Business seasonality  is required"),
  organization_nature: Yup.string()
    .required("Organization nature is required"),
  line_of_work: Yup.string()
    .required("Line of work is required"),
  business_registration_proof: Yup.string()
    .required("Business registration proof is required"),
  ownership_of_business_premises: Yup.string()
    .required("Ownership of business premises is required"),
  years_of_business_in_current_premises: Yup.string()
    .required("Years of business in current premises is required"),

  years_in_current_business: Yup.string()
    .required("Years in current business is required"),
  business_area: Yup.string()
    .required("Business area is required")
    .notOneOf(["0"], "Business area can not be 0"),
  business_location_type: Yup.string()
    .required("Business location type is required"),
  type_of_structure: Yup.string()
    .required("Type of structure is required"),
  amount_salary_paid_to_self: Yup.string()
    .required("Amount salary paid to self is required"),
    manual_scavenger: Yup.string().required("Manual Scavenger is required")   
    .min(2, "Manual Scavenger must be between 2 and 128 characters")  
    .max(128, "Manual Scavenger must be between 2 and 128 characters"),
    udyam_registration_number: Yup.string()
    .required("Udyam Registration Number is required"),
    address1: Yup.string()
    .trim()
    .required("Address Line 1 is required")
    .label("Address Line 1"),
  address2: Yup.string()
    .trim()
    .required("Address Line 2 is required")
    .label("Address Line 2"),
  landmark: Yup.string()
    .required("Landmark is required")
    .min(2, "Landmark must be at least 2 characters long.")
    .max(225, "Landmark must be at most 225 characters long.")
    .label("Landmark"),
  city: Yup.string().required("City is required").label("City"),
  taluka: Yup.string()
  .matches(/^[A-Za-z ]+$/, "Taluka can only contain letters")
  .required("Taluka is required")
  .label("Taluka"),
  district: Yup.string().required("District is required").label("District"),
  pincode: Yup.string().required("Pincode is required").label("Pincode"),
  state: Yup.string().required("State is required").label("State"),
  country: Yup.string().required("Country is required").label("Country"),
  address_type: Yup.string().required("Address type is required"),

  // average_daily_sale: Yup.string()
  //   .required("Average daily sale is required"),
  // estimated_gross_margin: Yup.string()
  //   .required("Estimated gross margin is required"),
  // net_margin_earned: Yup.string()
  //   .required("Net margin earned is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const SelfEmployedForm = ({ refreshData, id, open, closeForm, customer_id, selectedEmploymentType, loan_detail, onNext, activeCustomerKey, activeEmploymentKey,isSalesForm,isEdit }) => {
  const [form] = Form.useForm();
  const [industry, setIndustry] = useState([]);
  const [line, setLine] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [allEmploymentNature, setAllEmploymentNature] = useState([]);
  const [sourceOfFund, setSourceOfFund] = useState([]);
  const [businessSeasonality, setBusinessSeasonality] = useState([]);
  const [businessLegalStructure, setBusinessLegalStructure] = useState([]);
  const [businessProof, setBusinessProof] = useState([])
  const [ownership, setOwnerShip] = useState([]);
  const [locationType, setLocationType] = useState([]);
  const [familyBusiness, setFamilyBusiness] = useState([]);
  const [typeStructure, setTypeStructure] = useState([]);
  const [industryType, setIndustryType] = useState(null);
  const { encrypted_loan_id } = useParams();
  const [loading, setLoading] = useState(false);
  const latitude = useSelector((state) => state?.user?.user_location?.latitude);
  const longitude = useSelector((state) => state?.user?.user_location?.longitude);
  const [alladdress, setAddressType] = useState([]);
  const [city, setCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [state, setState] = useState([]);
  const [country, setCountry] = useState([]);
  const [allOccupation, setAllOccupation] = useState([]);
  const dispatch = useDispatch();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const getEmploymentData = async () => {
    if (id) {
      try {
        const response = await getSelfemplyedByIdService(id);
        let {
          organization_name,
          industry_type,
          line_of_work,
          year_of_start,
          total_business_experience,
          managed_by,
          number_of_employees,
          no_of_branches,
          gross_income,
          net_income,
          nature_of_employment,
          business_legal_structure,
          business_seasonality,
          source_of_fund,
          organization_nature,
          business_registration_proof,
          ownership_of_business_premises,
          years_of_business_in_current_premises,
          years_in_current_business,
          business_area,
          type_of_structure,
          type_of_business,
          amount_salary_paid_to_self,
          average_daily_sale,
          estimated_gross_margin,
          net_margin_earned,
          registration,
          business_location_type,
          manual_scavenger,
          occupation,
        } = response?.data;
          setIndustryType(industry_type.id);
          year_of_start = dayjs().year(year_of_start).startOf('year');  
          // manual_scavenger =
          // manual_scavenger === true
          //   ? "Yes"
          //   : manual_scavenger === false
          //   ? "No"
          //   : null;
          form.setFieldsValue({
          organization_name,
          industry_type: industry_type.id,
          line_of_work: line_of_work?.id,
          year_of_start,
          total_business_experience,
          managed_by,
          number_of_employees,
          no_of_branches,
          gross_income,
          net_income,
          nature_of_employment: nature_of_employment.id,
          business_legal_structure,
          business_seasonality,
          source_of_fund,
          organization_nature: organization_nature.id,
          business_registration_proof,
          ownership_of_business_premises,
          years_of_business_in_current_premises,
          years_in_current_business,
          business_area,
          type_of_structure,
          type_of_business,
          amount_salary_paid_to_self,
          average_daily_sale,
          estimated_gross_margin,
          net_margin_earned,
          registration,
          business_location_type,
          manual_scavenger,
          occupation: occupation?.id,
        });


        if(industry_type){
          const resp_industry_type = await listIndustryervice();
          const IndustryTypeList = GetDropdown(resp_industry_type.data,industry_type,"id", false)
          setIndustry(IndustryTypeList);
        }

        if(nature_of_employment){
          const resp_emp_nature = await listEmploymentNatureService();
          const EmpNatureList = GetDropdown(resp_emp_nature.data,nature_of_employment,"id", false)
          setAllEmploymentNature(EmpNatureList);
        }

        if(business_registration_proof){
          const resp_business_registration_proof = await getAllBusinessProofChoiceService();
          const BusinessRegistrationProofList = GetDropdown(resp_business_registration_proof.data,business_registration_proof,"id", false)
          setBusinessProof(BusinessRegistrationProofList);
        }

        if(ownership_of_business_premises){
          const resp_ownership_of_business_premises = await getAllOwnershipPrimisesChoiceService();
          const OwnershipofBusinessPremisesList = GetDropdown(resp_ownership_of_business_premises.data,ownership_of_business_premises,"id", false)
          setOwnerShip(OwnershipofBusinessPremisesList);
        }

        if(business_location_type){
          const resp_business_location_type = await getAllLocationTypeService();
          const BusinessLocationTypeList = GetDropdown(resp_business_location_type.data,business_location_type,"id", false)
          setLocationType(BusinessLocationTypeList);
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
    else
    {
      const response_industry_type = await listIndustryervice();
      setIndustry(response_industry_type.data);

      const response_emp_nature = await listEmploymentNatureService();
      setAllEmploymentNature(response_emp_nature.data);

      const responseBusinessProof = await getAllBusinessProofChoiceService();
      setBusinessProof(responseBusinessProof.data);

      const responsesetOwnerShip = await getAllOwnershipPrimisesChoiceService();
      setOwnerShip(responsesetOwnerShip.data);

      const responseLocationType = await getAllLocationTypeService();
      setLocationType(responseLocationType.data);
    }
  };

  const handleInput = (e, field) => {
    let newValue;
    if (field !== "year_of_start") {
      newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    }
    form.setFieldsValue({ [field]: newValue });
  };

  useEffect(() => {
    const getAllCity = async () => {
      try {
        const response = await listCity();
        setCity(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    
    const getAllDistrict = async () => {
      try {
        const response = await listDistrict();
        setDistrict(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllPincode = async () => {
      try {
        const response = await listPincode();
        setPincode(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllState = async () => {
      try {
        const response = await listState();
        setState(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCountry = async () => {
      try {
        const response = await listCountry();
        setCountry(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllAddress = async () => {
      try {
        const response = await listAddressTypeService();
        setAddressType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllOccupation = async () => {
      try {
        const response = await listOccupationService();
        setAllOccupation(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllCity();
    getAllDistrict();
    getAllPincode();
    getAllState();
    getAllCountry();
    getAllAddress();
    getAllOccupation();
  }, [form]);

  useEffect(() => {
    
    const getAllSourceOfFund = async () => {
      try {
        const response = await listSourceOfFund();
        setSourceOfFund(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllBusinessLegalStructure = async () => {
      try {
        const response = await listBusinessLegalStructure();
        setBusinessLegalStructure(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllBusinessSeasonality = async () => {
      try {
        const response = await listBusinessSeasonality();
        setBusinessSeasonality(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    // getAllIndustry();
    getAllSourceOfFund();
    getAllBusinessLegalStructure();
    getAllBusinessSeasonality();
  }, [form]);

  useEffect(() => {
    const getAllOrganization = async () => {
      try {
        const response = await listOrganizationNatureService();
        setOrganization(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    // const getAllEmploymentNature = async () => {
    //   try {
    //     const response = await listEmploymentNatureService();
    //     setAllEmploymentNature(response.data);
    //   } catch (error) {
    //     message.error(
    //       ErrorMessage(error?.response?.status, error?.response?.data?.message)
    //     );
    //   }
    // };
    getAllOrganization();
    // getAllEmploymentNature();
  }, [form]);

  useEffect(() => {
    const getAllLines = async () => {
      try {
        const response = await listLineOfWorkService(industryType);
        setLine(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    if (industryType && typeof(industryType) === "number") {
    getAllLines();
    }
  }, [form, industryType]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const selectedPincode = pincode.find((pin) => pin.name === values.pincode);
    const pincodeId = selectedPincode ? selectedPincode.id : null;
    values.number_of_employees = parseInt(values.number_of_employees);
    values.net_income = parseFloat(values.net_income);
    values.gross_income = parseInt(values.gross_income, 10);
    values.no_of_branches = parseInt(values.no_of_branches, 10);
    values.year_of_start = values.year_of_start.format("YYYY");

    const customerAddress = {
      address_type: values.address_type,
      address_line_1: values.address1,
      address_line_2: values.address2,
      landmark: values.landmark,
      taluka:values.taluka,
      pincode: pincodeId,
      latitude:latitude,
      longitude:longitude
    };
    try {
      if (id) {
        const response = await updateSelfemployedByIdService(id, {
          ...values,
        });
        if ((response.status = 200 && response.success)) {
          message.success("Employment Details successfully updated"); 
          if (typeof isSalesForm === "undefined") {
            refreshData();
            closeForm();
          }
        }
      } else {
        const formattedValues = [{
          "employment_details": {
            customer_detail: customer_id,
            employment_type: selectedEmploymentType,
            loan_detail: parseInt(loan_id),
          },
          "self_employed": {
            ...values,
            business_area: Number(values.business_area), // Add comma here
          },
          "customer_address": customerAddress,
        }];
        
        const response = await createSelfEmployedService(formattedValues);
        if ((response.status = 200 && response.success)) {
          message.success("Employment details successfully created"); 
          if (typeof isSalesForm === "undefined") {
            refreshData();
            closeForm();
          }
          if (
            typeof activeEmploymentKey !== "undefined" &&
            typeof activeCustomerKey !== "undefined"
          ) {
            dispatch(
              addNewCustomerEmployment({
                activeCustomerKey: activeCustomerKey,
                activeEmploymentKey: activeEmploymentKey,
                id: response?.data[0]?.id,
                type:response?.data[0]?.employment_detail?.employement_type_id
              })
            );
          }
       
        }
      }
    } catch (error) {
      closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
    finally{  
      setLoading(false)
    }

  };

  // const AllBusinessProof = async () => {
  //   try {
  //     // Fetch Units from the API
  //     const response = await getAllBusinessProofChoiceService();
  //     setBusinessProof(response.data);
  //   } catch (error) {
  //     console.error("Error fetching Units:", error);
  //     message.error("Failed to fetch Units");
  //   }
  // };

  // const AllOwnershipPremises = async () => {
  //   try {
  //     // Fetch Units from the API
  //     const response = await getAllOwnershipPrimisesChoiceService();
  //     setOwnerShip(response.data);
  //   } catch (error) {
  //     console.error("Error fetching Units:", error);
  //     message.error("Failed to fetch Units");
  //   }
  // };
  // const AllLocationbTypeService = async () => {
  //   try {
  //     // Fetch Units from the API
  //     const response = await getAllLocationTypeService();
  //     setLocationType(response.data);
  //   } catch (error) {
  //     console.error("Error fetching Units:", error);
  //     message.error("Failed to fetch Units");
  //   }
  // };

  const AllStrutureTypeService = async () => {
    try {
      // Fetch Units from the API
      const response = await getAllStructureTypeService();
      setTypeStructure(response.data);
    } catch (error) {
      console.error("Error fetching Units:", error);
      message.error("Failed to fetch Units");
    }
  };
  
  const AllFamilyBusinessTypeService = async () => {
    try {
      // Fetch Units from the API
      const response = await listFamilyBusiness();
      setFamilyBusiness(response.data);
    } catch (error) {
      console.error("Error fetching Units:", error);
      message.error("Failed to fetch Units");
    }
  };

  useEffect(() => {
    if (open) {
      getEmploymentData();
      // AllBusinessProof();
      // AllOwnershipPremises();
      // AllLocationbTypeService();
      AllStrutureTypeService();
      AllFamilyBusinessTypeService();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const handleIndustryChange = async (industryTypeId) => {
    try {
      // Check if industryTypeId is a number
      if (typeof industryTypeId === 'number') {
        const response = await listLineOfWorkService(industryTypeId); // Ensure your service accepts industryTypeId as a parameter
        setLine(response.data);
        
        // Reset the "line_of_work" field value
        form.setFieldsValue({
          line_of_work: undefined  // Ensure you'recalling setFieldsValue with the correct format
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };  

  const handletextInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s,./()0-9/-]/g, ""); // Allow numbers
    form.setFieldsValue({ [field]: newValue });
  };

  const handlePincodeChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.name === value);

    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, district, state, country } = response.data;
        form.setFieldsValue({
          city: city?.name || undefined,
          district: district?.name || undefined,
          state: state?.name || undefined,
          country: country?.name || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleSelectedInvestment = (value, field) => {
    form.setFieldsValue({ [field]: value });
  };

  const handleNumberFields = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };
  // const handleNumberFieldsWithoutFloating = (e, field) => {
  //   const newValue = e?.target?.value?.replace(/[^\d]/g, "");
  //   form.setFieldsValue({ [field]: newValue });
  // };
                                              
  const disabledDate = current => {
    // Disable dates after today
    return current && current > moment().endOf('day');
  };

  const customNetIncomeValidator = (_, value) => {
    const grossIncome = form.getFieldValue("gross_income");
    if (parseFloat(value) === 0) {
      return Promise.reject("Net income should not be 0");
    }

    if (parseFloat(value) > parseFloat(grossIncome)) {
      return Promise.reject("Net Income must be less than or equal to Gross Income");
    }
    return Promise.resolve();
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        organization_name: "",
        // industry_type: "",
        year_of_start: "",
        total_business_experience: "",
        managed_by: "",
        number_of_employees: "",
        no_of_branches: "",
        gross_income: "",
        net_income: "",
        // nature_of_employment: "",
        // line_of_work: "",
        // business_legal_structure: "",
        // business_seasonality: "",
        // organization_nature: "",
        // source_of_fund: "",

        // business_registration_proof: "",
        // ownership_of_business_premises: "",
        years_of_business_in_current_premises: "",
        years_in_current_business: "",
        business_area: "",
        // type_of_structure:"",
        // type_of_business: "",
        amount_salary_paid_to_self: "",
        average_daily_sale: "",
        estimated_gross_margin: "",
        net_margin_earned: "",
        registration: "",
        occupation:null,
        // business_location_type:""

      }}
    >
      <Row gutter={16} className="employement_row_form">
        <Col span={8}>
          <Form.Item
            name="organization_name"
            label="Organization Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Your Organization Name"
              onChange={(e) => handleInput(e, "organization_name")}
            />
          </Form.Item>
        </Col>
      
        <Col span={8}>
        <Form.Item
          name="industry_type"
          rules={[yupSync]}
          label="Industry Type"
          required
        >
          <Select
            placeholder="Please Select Industry Type"
            onChange={handleIndustryChange}
            allowClear
         
            className="select_filed"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {industry?.map((indus) => (
              <Select.Option key={indus.id} value={indus.id}>
                {indus.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name="line_of_work"
          rules={[yupSync]}
          label="Line Of Work"
          required
        >
          <Select
            placeholder="Please Select Line Of Work"
            allowClear

            className="select_filed"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {line?.map((indus) => (
              <Select.Option key={indus.id} value={indus.id}>
                {indus.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      </Row>

      <Row gutter={16} className="employement_row_form">
       

        <Col span={8}>
          <Form.Item
            name="total_business_experience"
            label="Total Experience (In Year)"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Total Experience (In Year)"
              onChange={(e) => handleNumberFields(e, "total_business_experience")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="managed_by" label="Managed By" rules={[yupSync]} required>
            <Input placeholder="Please Select Managed By"
              onChange={(e) => handleInput(e, "managed_by")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="number_of_employees" label="Number Of Employees" rules={[yupSync]} required>
            <Input placeholder="Please Enter No. Of Employees"
              onChange={(e) => handleNumberFields(e, "number_of_employees")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="employement_row_form">
        <Col span={8}>
          <Form.Item name="no_of_branches" 
          label="No Of Branches"
           rules={[yupSync]} 
           required>
            <Input placeholder="Please Enter No. Of Branches"
              onChange={(e) => handleNumberFields(e, "no_of_branches")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="gross_income"
            label=" Gross Income"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Your Gross Income"
              onChange={(e) => handleNumberFields(e, "gross_income")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="net_income"
            label=" Net Income"
            rules={[
              { required: true, message: "Net income is required" },
              { validator: customNetIncomeValidator }
            ]}
            required
          >
            <Input
              placeholder="Please Enter Your Net Income"
              onChange={(e) => handleNumberFields(e, "net_income")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="employement_row_form">
        <Col span={8}>
          <Form.Item
            name="nature_of_employment"
            label="Employment Nature"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select Employment Nature"
              // onChange={handleSelectedInvestment}
              required={true}
              allowClear
            
              className="select_filed"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allEmploymentNature?.map((employement) => {
                return (
                  <Select.Option key={employement.id} value={employement.id}>
                    {employement.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="business_legal_structure"
            label="Business Legal Structure"
            rules={[yupSync]}
            required
          >
            <Select placeholder="Please Select" showSearch allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {businessLegalStructure.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="type_of_business"
            label="Family Business"
            rules={[yupSync]}
            required
          >
            <Select placeholder="Please Select" showSearch allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {familyBusiness.map((fam) => (
                <Select.Option key={fam} value={fam}>
                  {fam}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

      </Row>



      <Row gutter={16} className="employement_row_form">
        <Col span={8}>
          <Form.Item
            name="business_seasonality"
            label=" Business Seasonality"
            rules={[yupSync]}
            required
          >
            <Select placeholder="Please Select" showSearch allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {businessSeasonality.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="organization_nature"
            rules={[yupSync]}
            label="Organization Nature"
            required
          >
            <Select
              mode="single"
              placeholder="Please Select Organization Nature"
              onChange={(value) => handleSelectedInvestment(value, "organization_nature")}

              required={true}
              allowClear
            
              className="select_filed"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {organization?.map((relation) => {
                return (
                  <Select.Option key={relation.id} value={relation.id}>
                    {relation.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="year_of_start" label="Year Of Start  (In Year)" rules={[yupSync]} required>
            <DatePicker
              picker="year"
              disabledDate={disabledDate}
              // format="DD-MM-YYYY"
           
              className="select_filed"
              placeholder="Select Date"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="employement_row_form">
        <Col span={8}>
          <Form.Item
            name="source_of_fund"
            label="Source Of Fund"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Enter Source Of Fund"
              showSearch allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {sourceOfFund.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item
            name="business_registration_proof"
            label="Business Registration Proof"
            rules={[yupSync]}
            required
          >
            <Select placeholder="Search To Select" 
              className="select_filed" showSearch allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {businessProof?.map((business) => (
                <Option key={business} value={business}>
                  {business}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="ownership_of_business_premises"
            label="Ownership Of Business Premises"
            rules={[yupSync]}
            required
          >
            <Select placeholder="Please Select" showSearch allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {ownership.map((owner) => (
                <Select.Option key={owner} value={owner}>
                  {owner}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>



      <Row gutter={16} className="employement_row_form">

        <Col span={8}>
          <Form.Item
            name="years_of_business_in_current_premises"
            label="Years Of Business In Current Premise"
            required
            rules={[yupSync]}
          >
            <Input
              placeholder="Please Enter Years Of Business In Current Premise"
              onChange={(e) => handleNumberFields(e, "years_of_business_in_current_premises")}
            />
          </Form.Item>
        </Col>



        <Col span={8}>
          <Form.Item
            name="years_in_current_business"
            label="Years In Current Business"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Years In Current Business"
              onChange={(e) => handleNumberFields(e, "years_in_current_business")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="business_area"
            label="Business Area"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Business Area"
              onChange={(e) => handleNumberFields(e, "business_area")}
            />
          </Form.Item>
        </Col>
      </Row>


      <Row gutter={16} className="employement_row_form">

        <Col span={8}>
          <Form.Item
            name="business_location_type"
            label="Business Location Type"
            rules={[yupSync]}
            required
          >
            <Select placeholder="Please Select" showSearch allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {locationType.map((locationtype) => (
                <Select.Option key={locationtype} value={locationtype}>
                  {locationtype}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item
            name="type_of_structure"
            label="Type Of Structure"
            rules={[yupSync]}
            required
          >
            <Select placeholder="Please Select" showSearch allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {typeStructure.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="amount_salary_paid_to_self"
            label="Amount Salary Paid to Self"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Amount Salary Paid To Self"
              onChange={(e) => handleNumberFields(e, "amount_salary_paid_to_self")}
            />
          </Form.Item>
        </Col>
      </Row>


      <Row gutter={16} className="employement_row_form">
        <Col span={8}>
          <Form.Item
            name="average_daily_sale"
            label="Average Daily Sale"
          // rules={[yupSync]}
          // required
          >
            <Input
              placeholder="Please Enter Average Daily Sale"
              onChange={(e) => handleNumberFields(e, "average_daily_sale")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="estimated_gross_margin"
            label="Estimated Gross Margin (%)"
          // rules={[yupSync]}
          // required
          >
            <Input
              placeholder="Please Enter Estimated Gross Margin (%)"
              onChange={(e) => handleNumberFields(e, "estimated_gross_margin")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="net_margin_earned"
            label="Net Margin Earned (%)"
          // rules={[yupSync]}
          // required
          >
            <Input
              placeholder="Please Enter Net Margin Earned (%)"
              onChange={(e) => handleNumberFields(e, "net_margin_earned")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="manual_scavenger"
            label="Manual Scavenger"
            rules={[yupSync]}
            required
          >
            <Select placeholder="Please Select">
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="udyam_registration_number"
            label="Udyam Registration Number"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Udyam Registration Number"
              onChange={(e) => handleNumberFields(e, "udyam_registration_number")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="occupation"
            label="Occupation"
            required
          >
            <Select
              placeholder="Please Select Occupation Type"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allOccupation?.map((allocptn) => (
                <Select.Option key={allocptn.id} value={allocptn.id}>
                  {allocptn.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {!isEdit && (
        <div>
          <Row gutter={16}>
        <Col span={8}>
        <Form.Item
          name="address_type"
          label="Address Type"
          rules={[yupSync]}
          required
        >
          <Select
            mode="single"
            placeholder="Please Select"
            required={true}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {alladdress
        ?.filter((add) => add.name === "Employment Address") // Filtering only "Office Address"
        .map((add) => (
          <Select.Option key={add.id} value={add.id}>
            {add.display_name}
          </Select.Option>
        ))}
          </Select>
        </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="address1"
            label="Address Line 1"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 1"
              onChange={(e) => handletextInput(e, "address1")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="address2"
            label="Address Line 2"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 2"
              onChange={(e) => handletextInput(e, "address2")}
            />
          </Form.Item>
        </Col>
        </Row>

      <Row gutter={12}>
      <Col span={8}>
          <Form.Item
            name="landmark"
            label="Landmark"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Landmark"
              onChange={(e) => handletextInput(e, "landmark")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="pincode" label="Pincode" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select Pincode"
              required={true}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handlePincodeChange}
            >
              {pincode?.map((pincode) => {
                return (
                  <Select.Option key={pincode.id} value={pincode.name}>
                    {pincode.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="district"
            label="District"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select District"
              required={true}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled
            >
              {district?.map((district) => {
                return (
                  <Select.Option key={district.id} value={district.name}>
                    {district.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        
      </Row>

      <Row gutter={12}>
      <Col span={8}>
          <Form.Item name="state" label="State" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select State"
              required={true}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled
            >
              {state?.map((state) => {
                return (
                  <Select.Option key={state.id} value={state.id}>
                    {state.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="city" label="City" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select City"
              required={true}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled
            >
              {city?.map((city) => {
                return (
                  <Select.Option key={city.id} value={city.name}>
                    {city.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="taluka" label="Taluka" rules={[yupSync]} required>
            <Input placeholder="Please enter taluka" />
          </Form.Item>
        </Col>
       
      </Row>

      <Row gutter={12}>
      <Col span={8}>
          <Form.Item name="country" label="Country" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please Select Country"
              required={true}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled
            >
              {country?.map((country) => {
                return (
                  <Select.Option key={country.id} value={country.name}>
                    {country.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      </div>
      )}
      <Space
        direction="horizontal"
        align="center"
        className="employement_button"
      >
        {
        isSalesForm ? (
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit 
          </Button>
        ) : (
          <>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
            <Button onClick={closeForm}>Cancel</Button>
          </>
        )
      }
      </Space>
    </Form>
  );
};

export default SelfEmployedForm;
