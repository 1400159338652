
import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getAddresslDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/customer/customer-address-detail/by-loan-id/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}



export const getAddressByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-address-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const createAddressService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/customer-address-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const updateAddressDetailsByIdService = (loan_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/customer-address-detail/${loan_id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const viewAddressDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/customer/customer-address-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}



export const getallpincode = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/pincode/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getAllCityService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/city/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllDistrictService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/district/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllStateService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/state/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllCountriesService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/country/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAddressTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/address-type/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const pincodeCodeService = (pincode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/pincode/get-data-by-pincode/?name=${pincode}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listAddressType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/address-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getSalesAddressDetails = (customerid) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const response = await axiosRequest.get(`/api/v1/customer/customer-address-detail/${customerid}?get_inactive=True`);
      const response = await axiosRequest.get(`/api/v1/customer/customer-address-detail/by-loan-id/${customerid}?get_inactive=True`);
     
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getSalesAddressForms = (customerid) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-address-detail/by-loan-id/${customerid}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const createSalesAddressService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("api/v1/customer/customer-address-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
};

export const deleteAddressByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/customer-address-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
};
