import { axiosRequest } from "../../../../../utils/api/axiosRequest"

  export const getLoanDetailsByLoanId = (loanid) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/loan-detail/loan-id/${loanid}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getVerificationTypes = (loanid) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/choice-api/verification-type`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const verifyExistingVehicleByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/existing-vehicle`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyCommercialVehicleByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/commercial-vehicle`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyPanCardByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/pan`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  export const verifyOtpByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/commercial-vehicle/`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  export const verifyVoterIdByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/voter`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  export const verifyBankAccountByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/bank-account`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyIFSCCodeByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/ifsc`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyDrivingLincenseByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/driving-license`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyelectricitybillByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/electricity-bill`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyGSTAuthenticationByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/gst`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyGSTByPANByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/gst`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyEPFUANLookupByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/epf-pan-lookup`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyUdyogAadharNumberByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/epf-pan-lookup`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyFssaiLinceseAuthenticationByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/fssai`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyShopAndEstablishmentByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/shop`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyPassportVerificationByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/passport-verification`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyForm16ByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/form-16`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyFDALicenseAuthenticationIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/form-16`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyTANAuthenticationIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/tan`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyMCASignatoriesByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/tan`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyLPGIDAuthenticationByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/lpg`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyEmploymentVerificationAdvancedByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/lpg`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyIECDetailedProfileByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/iec`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyCompanyIdentificationNumberByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/iec`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyPNGAuthenticationByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/png`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyIncomeTaxChallanAuthenticationByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/itr-challan`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyAadharAuthenticationByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/itr`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyEPFVerificationByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/epf-pan-lookup`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyITRVerificationByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/itr`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyEmployementVerificationByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/employment-verification`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyAadharOtpByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/get-aadhaar-otp`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyAadharByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/aadhaar-verification`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyMobileOTPByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/mobile-otp-send`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const verifyMobileByIdService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/karza/karza-api/mobile-otp-verified`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getElectricityPProviderService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/choice-api/electricity-service-provider`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getAreaCodeService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/choice-api/shop-area-code`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getPNGService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/choice-api/png-service-provider`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getKarzaDetailsService = (loanid, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/karza/get-karza-api/${loanid}`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getKarzaDetailService = (loanid) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/karza/get-karza-api/${loanid}`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getBackgroundCheckByKarza = (type,id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(
          `/api/v1/customer/bank-account-detail/bank-detail-karza/?karza_type=${type}&customer_detail=${id}`
        );
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getKarzaDocumentNumberVerify = (type,id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(
          `/api/v1/karza/karza-api/get-document-number?verification_type=${type}&customer_id=${id}
          `
        );
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };


  