import axios from "axios";
import baseConfig from "../../config/baseConfig";


const serverUrl = baseConfig.serverurl;

export const axiosRequest = axios.create({
  baseURL: serverUrl,
});

// Add a request interceptor
axiosRequest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
axiosRequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem("refresh");
        const response = await axios.post(
          `${serverUrl}/api/v1/userauth/refresh`,
          { refresh: refreshToken }
        );
        const token = response.data.access;
        

        localStorage.setItem("access", token);

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axios(originalRequest);
      } catch (error) {
        localStorage.clear();
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);
