import React, { useEffect, useState, useCallback, useRef } from "react";
import "../styles/notificationFeed.css";
import NotificationCard from "./NotificationCard";
import { clearAllNotifications } from "../services/notificationServices";
import { useDispatch } from "react-redux";
import { clearUnseenNotifications } from "../../../redux/notificationSlice";
import { Empty } from "antd";
import Loading from "../../../utils/loading/Loading";
import { getLoanAllNotification } from "../services/notificationServices";

const NotificationFeed = ({ showDrawer }) => {
  const [seenNotifications, setSeenNotifications] = useState([]);
  const [unseenNotifications, setUnseenNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFirstFetch, setLoadingFirstFetch] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const pageRef = useRef(1);
  const dispatch = useDispatch();
  const isFetchingRef = useRef(false);
  const notificationContainerRef = useRef(null);
  const [maxPage, setMaxPage] = useState(null);

  const fetchNotifications = useCallback(async () => {
    if (
      !hasMore ||
      isFetchingRef.current ||
      (maxPage !== null && pageRef.current > maxPage)
    )
      return;

    isFetchingRef.current = true;
    setLoading(true);
    if (pageRef.current === 1) setLoadingFirstFetch(true);

    try {
      const response = await getLoanAllNotification(pageRef.current, 200);

      if (response.data.length === 0) {
        setHasMore(false);
        if (pageRef.current === 1) {
          setSeenNotifications([]);
          setUnseenNotifications([]);
          setIsEmpty(true);
        }
      } else {
        setSeenNotifications((prev) => [
          ...prev,
          ...response.data.filter(
            (notification) => notification?.notification?.is_seen
          ),
        ]);
        setUnseenNotifications((prev) => [
          ...prev,
          ...response.data.filter(
            (notification) => !notification?.notification?.is_seen
          ),
        ]);
        pageRef.current += 1;
        setIsEmpty(false);
      }

      if (response.count && maxPage === null) {
        setMaxPage(Math.ceil(response.count / 5));
      }
    } catch (error) {
      console.error("❌ Error fetching notifications:", error);
    } finally {
      setLoading(false);
      setLoadingFirstFetch(false);
      isFetchingRef.current = false;
    }
  }, [hasMore, maxPage]);

  const handleScroll = useCallback(() => {
    const container = notificationContainerRef.current;
    if (
      container &&
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 50
    ) {
      fetchNotifications();
    }
  }, [fetchNotifications]);

  const clearNotifications = async () => {
    const clearResponse = await clearAllNotifications();
    if (clearResponse.status === 200) {
      dispatch(clearUnseenNotifications());
    }
  };

  useEffect(() => {
    if (showDrawer) {
      setSeenNotifications([]);
      setUnseenNotifications([]);
      setIsEmpty(false); // Reset empty state
      pageRef.current = 1;
      setHasMore(true);

      (async () => {
        await fetchNotifications();
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDrawer]);

  useEffect(() => {
    if (unseenNotifications.length > 0) {
      clearNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unseenNotifications]);

  useEffect(() => {
    const container = notificationContainerRef.current;
    if (showDrawer && container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [showDrawer, handleScroll]);

  return (
    <div
      ref={notificationContainerRef}
      style={{ maxHeight: "100%", overflowY: "auto" }}
    >
      {loadingFirstFetch ? (
        <Loading />
      ) : isEmpty ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Empty description="No Notifications Available" />
        </div>
      ) : (
        <div>
          {unseenNotifications.length > 0 && (
            <div>
              <h3 className="notification_div_heading">Unseen Notifications</h3>
              <div style={{ marginBottom: 32, width: "100%" }}>
                {unseenNotifications.map((notification, index) => (
                  <NotificationCard
                    key={`${notification?.loan_detail_id}-${index}`}
                    notification={notification}
                  />
                ))}
              </div>
            </div>
          )}
          {seenNotifications.length > 0 && (
            <div>
              <h3 className="notification_div_heading">Seen Notifications</h3>
              <div style={{ marginBottom: 32, width: "100%" }}>
                {seenNotifications.map((notification, index) => (
                  <NotificationCard
                    key={`${notification?.loan_detail_id}-${index}`}
                    notification={notification}
                  />
                ))}
              </div>
            </div>
          )}
          {loading && pageRef.current > 1 && <Loading />}
        </div>
      )}
    </div>
  );
};

export default NotificationFeed;
