import { useEffect, useRef, useState, useCallback } from "react";

const useWebSocket = (url, onConnectionChange) => {
  const ws = useRef(null);
  const messagesRef = useRef([]);
  const isConnected = useRef(false);
  const reconnectAttempts = useRef(0);
  const [, forceRender] = useState(0);
  const [lastMessage, setLastMessage] = useState(null);

  const connectWebSocket = useCallback(() => {
    if (ws.current && ws.current.readyState !== WebSocket.CLOSED) return;

    ws.current = new WebSocket(url);

    ws.current.onopen = () => {
      console.log("WebSocket Connected");
      isConnected.current = true;
      onConnectionChange?.(true);
      reconnectAttempts.current = 0;
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      messagesRef.current = [...messagesRef.current, data];
      setLastMessage(data);
      forceRender((prev) => prev + 1);
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    ws.current.onclose = () => {
      console.log("WebSocket Disconnected");
      isConnected.current = false;
      onConnectionChange?.(false);
      retryConnection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, onConnectionChange]);

  const retryConnection = useCallback(() => {
    if (reconnectAttempts.current < 5) {
      const timeout = Math.min(5000, (reconnectAttempts.current + 1) * 1000);
      setTimeout(() => {
        console.log(`Reconnecting... Attempt ${reconnectAttempts.current + 1}`);
        reconnectAttempts.current += 1;
        connectWebSocket();
      }, timeout);
    } else {
      console.log("Max reconnect attempts reached.");
    }
  }, [connectWebSocket]);

  useEffect(() => {
    connectWebSocket();
    return () => ws.current?.close();
  }, [connectWebSocket]);

  const sendMessage = (message) => {
    if (ws.current?.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify({ ...message }));
    }
  };

  return {  sendMessage, isConnected: isConnected.current, lastMessage };
};

export default useWebSocket;

// add at first in return if needed
// messages: messagesRef.current,