import React, { useEffect, useState } from "react";
import "../styles/overAllStatus.css";
import add from "../assets/Add.png";
import OverallStatusForm from "./OverallStatusForm";
import { Drawer, message } from "antd";
import Header from "../../layout/views/Header";
import { getOverallStatusByLoanStageId } from "../services/dedupeService";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import Loading from "../../../utils/loading/Loading";

const OverAllStatus = () => {
  const [open, setOpen] = useState(false);
  const [overallData, setOverallData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const { encrypted_loan_id, encrypted_stage_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getOverallStatusByLoanStageId(loan_id, stage_id);
        setOverallData(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData]);

  return (
    <>
      <Drawer
        title={
          <Header
            title={"Add"}
            // title={selectedCustomer ? "Edit" : "Add"}
            onClose={onClose}
            // name={
            //   selectedPerson
            //     ? `${selectedPerson?.customer_name}'s  Bank Account Details`
            //     : ` ${selectedPerson?.customer_name}'s Bank Account Details`
            // }
          />
        }
        width={970}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <OverallStatusForm
          refreshData={toggleRefreshTableData}
          open={open}
          closeForm={onClose}
        />
      </Drawer>
      <div className="overall_main_container">
        {loading ? (
          <Loading loading={loading} />
        ) : (
          <>
            {overallData?.loan_stage?.stage?.code === "DEDUPE50" &&
              overallData?.loan_stage?.stage_status?.code === "INTITIAT" && (
                <div
                  className="tab_form_sub_edit_dedupe"
                  onClick={() => {
                    showDrawer();
                    // handleAddButtonClick();
                  }}
                >
                  <img
                    src={add}
                    alt="Add Button"
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  />
                </div>
              )}

            <div className="overall_status">
              <div className="overall">Overall Status :</div>
              <div
                className={`dedupe_overall_status ${
                  overallData?.status === "Positive"
                    ? "positive_status"
                    : overallData?.status === "Negative"
                    ? "negative_status"
                    : ""
                }`}
              >
                {overallData?.status || "-"}
              </div>
            </div>
            <div className="remark_div">
              <div className="dedupe_remark">Remark :</div>
              <div className="no_match_found">{overallData?.remark || "-"}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OverAllStatus;
