import React, { useEffect, useState } from "react";
import "../styles/customerMatching.css";
import {
  getCustomerDedupe,
  getLoanIdForHyperlink,
} from "../services/dedupeService";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { message, Modal } from "antd";
import Loading from "../../../utils/loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { decrypt, encrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import { dedupeCustomerLinkService } from "../services/dedupeService";
import correctImage from "../assets/correct.png";
import linkImage from "../assets/link.png";

import CustomNoData from "../../../utils/noDataIcon/CustomNoData";
import { v4 } from "uuid";

const specialKeys = [
  "Pan Card",
  "Aadhar Card",
  "Voter Id",
  "Driving Licence",
  "Passport",
];

const CustomerMatching = ({ customer_id }) => {
  const [showNoData, setShowNoData] = useState(false);
  const [customersData, setCustomersData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { encrypted_loan_id } = useParams();
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (match) => {
    setSelectedRecord(match);
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    await handleLinkClick(selectedRecord);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchCustomerDedupeData = async () => {
      try {
        setLoading(true);
        const response = await getCustomerDedupe(loan_id, customer_id);
        const isEmpty = response?.every(
          (obj) => obj?.response_code !== 200 || obj?.status === "I"
        );

        if (isEmpty) {
          setShowNoData(true);
          setLoading(false);
        } else {
          const filteredData = response.filter(
            (item) => item.riskmatch !== null
          );

          const data = filteredData.map((customer) => {
            const dedupeRecords = [];

            if (customer?.riskmatch) {
              customer.riskmatch.forEach((match) => {
                dedupeRecords.push({
                  loan_account_no: match?.loan_account_no || "",
                  application_number: match?.application_number || "",
                  loan_stage: match?.loan_stage,
                  source: match?.source_name || "Direct",
                  customer_name:
                    `${match?.f_name} ${match?.m_name} ${match.l_name}` || "",
                  matching_data: match?.matching_data || [],
                  un_matching_data: match?.un_matching_data || [],
                  customer_type: match?.customer_type || "",
                  input_customer_id: customer?.input_customer_id || "", // Ensure input_customer_id is mapped from customer
                  customer_id: match?.customer_id || "", // Use customer_id from match
                  is_linked: match?.is_linked,
                  uuid: v4(),
                });
              });
            }

            if (customer?.householdmatch) {
              customer.householdmatch.forEach((match) => {
                if (match.matched_loan_account_no === null) {
                }

                const hasValidData =
                  match?.matched_application_no != null &&
                  match?.matched_percentage != null &&
                  match?.matched_loan_account_no != null &&
                  match?.matched_application_no?.trim() !== "" &&
                  match?.matched_application_no !== "None" &&
                  match?.matched_percentage !== "None";

                if (hasValidData) {
                  dedupeRecords.push({
                    loan_account_no: `(Household) ${match?.matched_loan_account_no}`,
                    application_number: match?.matched_application_no || "",
                    source: match?.source_name || "Direct",
                    customer_name: `${match?.f_name} ${match?.m_name} ${match.l_name}`,
                    matching_data: [
                      {
                        "Matched Current Address":
                          match?.matched_current_address || "",
                      },
                      {
                        "Current Address Match Percentage":
                          match?.current_fulladdress_match_percentage?.toString() ||
                          "",
                      },
                      {
                        "Matched Permanent Address":
                          match?.matched_permanent_address || "",
                      },
                      {
                        "Permanent Address Match Percentage":
                          match?.permanent_fulladdress_match_percentage?.toString() ||
                          "",
                      },
                    ],
                    un_matching_data: [],
                    customer_type: match?.customer_type,
                    loan_stage: match?.loan_stage,
                    is_linked: match?.is_linked,
                    input_customer_id: customer?.input_customer_id || "",
                    customer_id: match?.matched_customer_id || "",
                    uuid: v4(),
                  });
                }
              });
            }

            return { dedupe_records: dedupeRecords };
          });

          setCustomersData(data);
          setLoading(false);
        }
      } catch (error) {
        setShowNoData(true);
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchCustomerDedupeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id]);

  if (showNoData) {
    return (
      <div className="no_data_found_message">
        <CustomNoData />
      </div>
    );
  }

  const handleAppNoClick = async (appNumber) => {
    try {
      const response = await getLoanIdForHyperlink(appNumber);
      if (response.data.id) {
        const path = `/loandetails/${encrypt(response?.data?.id.toString())}`;
        window.open(path, "_blank");
      } else {
        navigate("/not-found");
      }
    } catch (error) {
      navigate("/not-found");
    }
  };

  const handleLinkClick = async (match) => {
    try {
      console.log(customersData);
      const data = {
        linked_type: "Customer",
        linked_source_code: match.input_customer_id,
        linked_destination_code: match.customer_id,
      };

      const response = await dedupeCustomerLinkService(data);
      if (response && response.status === 200) {
        message.success("Customer linked successfully!");
        const newData = customersData.map((record) => {
          const newDedupeRecords = record?.dedupe_records.map((r) => {
            // Preserve previous `is_linked` value unless a new match is found
            const wasPreviouslyLinked = r.is_linked ?? false;

            const isCurrentlyLinked = response?.data?.linked_lan_details.some(
              (m) =>
                m.linked_destination_code === r.customer_id &&
                m.linked_source_code === r.input_customer_id
            );

            return {
              ...r,
              is_linked: wasPreviouslyLinked || isCurrentlyLinked, // Keep previous matches
            };
          });

          return {
            ...record,
            dedupe_records: newDedupeRecords,
          };
        });

        setIsModalOpen(false);
        setSelectedRecord(null);
        setCustomersData(newData);
      } else {
        message.error("Failed to link customer. Please try again.");
      }
    } catch (error) {
      setIsModalOpen(false);
      setSelectedRecord(null);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <div className="customer-matching-container">
      {loading ? (
        <div style={{ border: "1px solid #ddd" }}>
          <Loading style={{ width: "100%" }} loading={loading} />
        </div>
      ) : showNoData ? (
        <div className="no_data_found_message">
          <CustomNoData />
        </div>
      ) : (
        <div className="custom-table">
          <div className="table_heading">
            <div className="source-column-dedupe">Source</div>
            <div className="source-column-dedupe">Customer Name</div>
            <div className="source-column-dedupe">Customer Type</div>
            <div className="source-column-dedupe">Matched Records</div>
            <div className="source-column-dedupe">Unmatched Records</div>
          </div>
          {customersData &&
            customersData.map((data, index) => (
              <div key={index} className="dedupe_grid_container">
                {data.dedupe_records.map((match, index) => (
                  <div key={index}>
                    <div
                      className="dedupe_first_row"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h6
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleAppNoClick(match?.application_number)
                          }
                          className="dedupe_loan_number"
                        >
                          {match?.loan_account_no &&
                          `${match?.loan_account_no}` !== "None"
                            ? match?.loan_account_no
                            : ""}
                          &nbsp;
                          {match?.application_number &&
                          `match.application_number` !== "None"
                            ? `(${match?.application_number})`
                            : ""}
                          {match?.loan_stage ? ` (${match.loan_stage})` : ""}
                        </h6>
                      </div>

                      {match?.is_linked ? (
                        <button className="dedup_link_lan_button" disabled>
                          <img src={correctImage} alt="" />
                          <span>Linked</span>
                        </button>
                      ) : (
                        <button
                          className="dedup_link_lan_button link_lan_cursor_pointer"
                          onClick={() => showModal(match)}
                        >
                          <img src={linkImage} alt="" />
                          <span>Link</span>
                        </button>
                      )}
                    </div>

                    <div className="dedupe_grid_row">
                      <div
                        className="dedupe_table_data_body"
                        style={{ marginLeft: "10px" }}
                      >
                        {data.source || "Direct"}
                      </div>
                      <div className="dedupe_table_data_body">
                        {match.customer_name}
                      </div>
                      <div className="dedupe_table_data_body">
                        {match.customer_type}
                      </div>
                      <div className="dedupe_table_data_body">
                        {match.matching_data.map((record, i) => (
                          <div key={i} style={{ marginBottom: "10px" }}>
                            {Object.entries(record)
                              .filter(
                                ([key, value]) =>
                                  value !== null &&
                                  value !== "None" &&
                                  value.trim() !== ""
                              )
                              .map(([key, value]) => (
                                <div
                                  className={`dedupe_match ${
                                    specialKeys.includes(key)
                                      ? "highlight_property"
                                      : ""
                                  }`}
                                  key={key}
                                >
                                  <div style={{ flex: "0 0 30%", textAlign: "left" }}>
                                    <strong>{`${key}:`}</strong>
                                  </div>
                                  <div
                                    style={{
                                      flex: "0 0 70%",
                                      textAlign: "left",
                                      wordWrap: "break-word",
                                      overflow: "auto"
                                    }}
                                  >
                                    {`${value}`}
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                      <div className="dedupe_table_data_body">
                        {match.un_matching_data.map((record, i) => (
                          <div key={i} style={{ marginBottom: "10px", marginLeft:'10px' }}>
                            {Object.entries(record)
                              .filter(
                                ([key, value]) =>
                                  value !== null &&
                                  value !== "None" &&
                                  value.trim() !== ""
                              )
                              .map(([key, value]) => (
                                <div key={key} className="dedupe_match">
                                  <div
                                    style={{ flex: "0 0 40%", textAlign: "left" }}
                                  >
                                    <strong>{`${key}:`}</strong>
                                  </div>
                                  <div
                                    style={{
                                      flex: "0 0 60%",
                                      textAlign: "left",
                                      wordWrap: "break-word",
                                      overflow: "auto"
                                    }}
                                  >
                                    {`${value}`}
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      )}
      {selectedRecord && (
        <Modal
          title="Link Lan"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Are you sure you want to link lan?</p>
        </Modal>
      )}
    </div>
  );
};

export default CustomerMatching;
