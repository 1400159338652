import {
    Col,
    Form,
    Row,
    Input,
    Space,
    Button,
    message,
    Select
} from "antd";
import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import { createReferenceCallService, getApplicantListByIdService, getCustomerMobileNumberByIdService } from "../services/callInitiateService";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";


const validationSchema = Yup.object().shape({
    assign_to: Yup.string()
        .required("Assign to is required.")
        .min(1, "Assign to is required"),
    customer_name: Yup.string()
        .required("Customer name is required.")
        .min(1, "Customer name is required"),

    to_mobile: Yup.string().required("Mobile number is required."),
    from_mobile: Yup.string()
        .required("Agent mobile number is required.")
        .matches(/^\d{10}$/, "Agent mobile number must be exactly 10 digits."),

});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};

const CallInitiate = ({ refreshData, reference_id, open, closeForm, customer_data, selectedReference, tab }) => {
    const [form] = Form.useForm();
    const { user_data } = useSelector((state) => state.user);
    const { encrypted_loan_id } = useParams();
    const [customerName, setCustomerName] = useState(null);
    const [customerId, setCustomerId] = useState(null);

     

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    useEffect(() => {
        if (user_data) {
            form.setFieldsValue({
                from_mobile: user_data?.mobile,
                assign_to: user_data?.name,
            });
        }
        if (customer_data) {
            if (tab === "reference") {
                form.setFieldsValue({
                    customer_name: customer_data?.customer_name,
                });
            }
            if (tab === "personal") {
                form.setFieldsValue({
                    customer_name: customer_data?.first_name,
                    to_mobile: customer_data?.mobile_number,
                });
            }
        }
        if (selectedReference) {
            form.setFieldsValue({
                to_mobile: selectedReference?.mobile_number,
            });
        }

       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_data, form, open]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const customerResponse = await getApplicantListByIdService(loan_id); 
                const customerNames = customerResponse.data.customer_list.map(
                    (customer) => ({
                        id: customer.id,
                        name: customer.name,
                    })
                );
                setCustomerName(customerNames);
                if (customerNames.length > 0) {
                    setCustomerId(customerNames[0].id);
                }
            } catch (error) {
                console.error("Error fetching customer data:", error);
            }
        };
    
        fetchData();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCustomerChange = async (value) => {
        setCustomerId(value); 
        if (!value) { 
          form.setFieldsValue({
            from_mobile: undefined,
            other_mobile_number: undefined,
          });
          return;
        }
        try {
          const customermobileresponse = await getCustomerMobileNumberByIdService(
            value
          );
          const numbers = customermobileresponse.data; 
          form.setFieldsValue({
            from_mobile: numbers[0],
            other_mobile_number: undefined,
          });
        } catch (error) {
          message.error("Failed to fetch mobile numbers");
        }
      };

    const handleSubmit = async (values) => {
        try {
            if (tab === "reference") {
                values.loan_detail = parseInt(loan_id);
                values.customer_detail = customer_data?.customer_id;
                values.reference_detail = reference_id;
                values.assign_to = customerId;
            }
            if (tab === "personal") {
                values.loan_detail = parseInt(loan_id);
                values.customer_detail = customer_data?.id;
                values.assign_to = customerId;
            }
           
            const response = await createReferenceCallService(values);
            if ((response.status = 200 && response.success)) {
                message.success("Call Successfully initiated");
                refreshData();
                closeForm();
            }
        }
        catch (error) {
            // closeForm();
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };
    return (
        <>
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical">
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="assign_to"
                            label="Agent"
                            rules={[yupSync]}
                            required
                        >
                            {/* <Input
                                disabled
                                placeholder="Please Enter Agent Name"
                            /> */}

                <Select
                  mode="single"
                  placeholder="Please select"
                  allowClear
                  className="select_field"
                  showSearch
                  onChange={handleCustomerChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {customerName?.map((cust) => (
                    <Select.Option key={cust.id} value={cust.id}>
                      {cust.name}
                    </Select.Option>
                  ))}
                </Select>

                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="from_mobile"
                            label="Agent Mobile Number"
                            rules={[yupSync]}
                            required
                        >
                            <Input
                                disabled
                                placeholder="Enter agent mobile number"
                                className="input_field"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="customer_name"
                            label="Customer Name"
                            rules={[yupSync]}
                            required
                        >
                            <Input
                                disabled
                                placeholder="Please Enter Customer Name"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="to_mobile"
                            label="Mobile Number"
                            // rules={[yupSync]}
                            required
                        >
                            <Input
                                placeholder="Please Enter Mobile Number"
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Space
                    direction="horizontal"
                    align="center"
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button onClick={closeForm}>Cancel</Button>
                </Space>
            </Form>
        </>

    )
}

export default CallInitiate