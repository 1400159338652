import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  message,
  Checkbox,
  Image,
} from "antd";
import group from "../../assets/Group.png";
import "../../styles/SalesForm.css";
import { postDocumentCheck } from "../../services/salesformservices";
import { useDispatch } from "react-redux";
import {
  changeStepperActiveKey,
  setCustomerApplicantType,
  setCustomerdocumentData,
  setbase64,
} from "../../../../redux/salesFormSlice";
import Loading from "../../../../utils/loading/Loading";
import getDocumentTypeList from "../../../fi/services/getDocumentTypeList";

const DocumentCheck = ({ activeCustomer, activeKey, customers }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const fileInputRef = useRef(null);
  const [, setLoanid] = useState();
  const [ocrData, setOcrData] = useState([]);
  // const [disable] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [checkboxStates, setCheckboxStates] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState([]);
  const [applicantType, setApplicantType] = useState('');

  let customer_id = null;

  useEffect(() => {
    const fetchDocumentTypeList = async () => {
      const response = await getDocumentTypeList();
      setDocumentType(response.data);
    };
    fetchDocumentTypeList();
  }, []);

  const handleApplicantTypeChange = (value) => {
    setApplicantType(value);
  };

  const handleFileChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const documentTypeValue = form.getFieldValue("document");
    const documentName = documentType.find(
      (item) => item.code === documentTypeValue
    );

    if (!file) {
      message.error("No file selected");
      return;
    }

    if (file.size === 0) {
      message.error("File is empty");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64Url = reader.result;

      if (!base64Url) {
        message.error("Failed to read the file");
        return;
      }

      setSelectedDocument(file.name);
      setSelectedImage(base64Url);

      const payload = {
        customer_detail: customer_id || null,
        document_type: documentTypeValue,
        document_path: base64Url,
      };

      const checkInstance = customer_id ? false : true;

      try {
        const response = await postDocumentCheck(payload, checkInstance);
        const { ocr_data } = response.data;

        const { name, date_of_birth, gender, ...updatedOcrData } = {
          ...ocr_data,
          documentName: documentName.name,
          request_file: base64Url,
        };

        setUserData({ name, date_of_birth, gender });
        setLoading(false);
        setOcrData(updatedOcrData);

        dispatch(
          setCustomerdocumentData({
            index: activeCustomer,
            value: updatedOcrData,
          })
        );

        form.resetFields();
        setSelectedDocument(null);
        setLoanid(null);
      } catch (error) {
        message.error(error?.response?.data.message);
        setSelectedDocument(null);
        setUserData("");
        setLoading(false);
        console.error("Upload Error:", error);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleCheckboxChange = (field, checked, value) => {
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [field]: checked ? value : undefined,
    }));
    setOcrData((prev) => ({ ...prev, [field]: checked ? value : undefined }));
  };

  const generatePayload = () => {
    // Check which fields are available in userData
    const availableFields = Object.keys(userData).filter(key => userData[key]);
    
    // Check if all available fields are selected
    const notSelectedFields = availableFields.filter(field => !checkboxStates[field]);
    
    if (notSelectedFields.length > 0) {
      // Create a readable list of missing fields
      const fieldNames = {
        name: "Name",
        gender: "Gender",
        date_of_birth: "Date of Birth"
      };
      
      const missingFieldsText = notSelectedFields
        .map(field => fieldNames[field])
        .join(", ");
      
      message.error(`Please select all available fields: ${missingFieldsText}`);
      return;
    }

    // Continue with form submission since all available fields are selected
    dispatch(
      setCustomerApplicantType({
        index: activeCustomer,
        value: applicantType,
      })
    );
    
    dispatch(
      setCustomerdocumentData({
        index: activeCustomer,
        value: ocrData,
      })
    );
    
    dispatch(
      changeStepperActiveKey({
        index: parseInt(activeCustomer),
        value: 1,
      })
    );
    
    dispatch(
      setbase64({
        index: activeCustomer,
        base64Url: selectedImage,
      })
    );
  };


  return (
    <div>
      <Form
        form={form}
        initialValues={{
          document_type: null,
        }}
      >
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item
              name="document"
              label="Document"
              required
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                mode="single"
                placeholder="Please select"
                allowClear
                style={{ width: "100%" }}
                showSearch
              >
                {documentType
                  ?.filter((document) =>
                    [
                      "AADHAARB",
                      "AADHAAR5",
                      "AADHAARF",
                      "PANCARD0",
                      "VOTINGCP",
                      "VOTINGCA",
                    ].includes(document.code)
                  )
                  .map((document) => (
                    <Select.Option key={document.id} value={document.code}>
                      {document.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              name="applicant_type"
              label="Applicant Type"
              // rules={[yupSync]}
              required
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                showSearch
                mode="single"
                placeholder="Please Select Applicant Type"
                required={true}
                allowClear
                onChange={handleApplicantTypeChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Select.Option key="individual" value="Individual">
                  Individual
                </Select.Option>
                <Select.Option key="company" value="Company">
                  Company
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <div>
              <h4 style={{ marginLeft: -2, marginBottom: "12px" }}>
                Enter Document Name <span style={{ color: "red" }}>*</span>
              </h4>
              <Row gutter={16}>
                <Col span={10}>
                  <Form.Item name="document">
                    <Input
                      placeholder="Selected Document"
                      value={selectedDocument}
                      onChange={(e) => setSelectedDocument(e.target.value)}
                      disabled={true}
                    />
                    <div style={{ color: "gray", fontSize: "12px" }}>
                      Only .jpg, .jpeg, .png
                    </div>
                  </Form.Item>
                </Col>

                <Form.Item style={{ marginLeft: -9 }}>
                  <div>
                    <Button
                      type="primary"
                      onClick={() => {
                        // Trigger the file input click
                        fileInputRef.current?.click();
                      }}
                      icon={<img src={group} width="15" alt="Group Icon" />}
                      // disabled={checkedDocument}
                    >
                      Select File
                    </Button>

                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={(e) => {
                        handleFileChange(e);
                        // Reset the input value to trigger 'onChange' on next selection
                        e.target.value = null;
                      }}
                    />
                  </div>
                </Form.Item>
              </Row>
            </div>
          </Col>
        </Row>

        {/* {loading ? (
          <div
            className="loader-wrapper"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : ( */}
        {/* {customer?.documentData && (

       )}  */}

        <div>
          {loading ? (
            <Loading loading={loading} />
          ) : (
            Object.keys(userData).length > 0 && (
              <div>
                <div className="document_check_files">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "20px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "330px",
                        height: "170px",
                        borderRadius: "8px",
                        marginRight: "30px",
                      }}
                    >
                      <Image
                        style={{
                          width: "310px",
                          height: "163px",
                          margin: "2px 8px",
                        }}
                        src={ocrData?.url || selectedImage}
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          width: "150px",
                          marginRight: "20px",
                        }}
                      >
                        {userData?.name && (
                          <Checkbox
                            checked={checkboxStates.name === userData?.name} // Check if the stored name matches the current one
                            onChange={(e) =>
                              handleCheckboxChange(
                                "name",
                                e.target.checked,
                                userData?.name
                              )
                            }
                          >
                            {userData?.name}
                          </Checkbox>
                        )}

                        {userData?.gender && (
                          <Checkbox
                            checked={checkboxStates.gender === userData?.gender}
                            onChange={(e) =>
                              handleCheckboxChange(
                                "gender",
                                e.target.checked,
                                userData?.gender
                              )
                            }
                          >
                            {userData?.gender}
                          </Checkbox>
                        )}

                        {userData?.date_of_birth && (
                          <Checkbox
                            checked={
                              checkboxStates.date_of_birth ===
                              userData?.date_of_birth
                            }
                            onChange={(e) =>
                              handleCheckboxChange(
                                "date_of_birth",
                                e.target.checked,
                                userData?.date_of_birth
                              )
                            }
                          >
                            {userData?.date_of_birth}
                          </Checkbox>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    marginTop: 20,
                  }}
                >
                  <Button type="primary" onClick={generatePayload}>
                    Submit
                  </Button>
                </div>
              </div>
            )
          )}
        </div>
      </Form>
    </div>
  );
};

export default DocumentCheck;
