/* eslint-disable react-hooks/exhaustive-deps */
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import { useEffect } from "react";
import "../styles/magmainsuredcustomerform.css";
import { getAddressDetailByAddresType } from "../services/loanInsuranceService";

const { Option } = Select;

const MagmaInsuredCustomerForm = ({
  customerId,
  occupationType,
  filterOption,
  states,
  city,
  pincode,
  personalData,
  setMatchedCustomer,
  setFormValues,
  insurance_id,
  datas,
  setAddressType,
  addressType,
  addressTypeList,
}) => {
  const [form] = Form.useForm();
  // Define initial values for the form
  useEffect(() => {
    form.resetFields();
    setAddressType([]);
    if (!addressType.length) {
      form.setFieldsValue({
        customer_address_type: undefined, 
        address_line_1: "",
        address_line_2: "",
        city: undefined,
        state: undefined,
        pincode: undefined,
      });
    }
  }, [customerId]);
  const initialValues = datas?.[0]
    ? {
        insured_customer_name: `${datas[0]?.first_name || ""} ${
          datas[0]?.middle_name || ""
        } ${datas[0]?.last_name || ""}`.trim(),
        gender: datas[0]?.gender || "",
        nationality: datas[0]?.nationality || "",
        mobile: datas[0]?.mobile_number || "",
        email: datas[0]?.email || "",
        date_of_birth: datas[0]?.date_of_birth
          ? moment(datas[0]?.date_of_birth, "YYYY-MM-DD")
          : null,
        applicant_type: datas[0]?.customer_type?.display_name || "",
        address_line_1: datas[0]?.address_line_1 || "",
        address_line_2: datas[0]?.address_line_2 || "",
        city: datas[0].city?.id || undefined,
        state: datas[0].state?.id || undefined,
        pincode: datas[0].pincode?.id || undefined,
        // customer_address_type: addressType || undefined,
      }
    : {};

  // Combined useEffect for handling customer data and address updates
  useEffect(() => {
    const updateFormData = async () => {
      try {
        // Handle customer data
        if (customerId && personalData?.length > 0) {
          const foundCustomer = personalData?.find(
            (person) => person.id === customerId
          );

          if (foundCustomer) {
            const [day, month, year] = foundCustomer?.date_of_birth.split("-") || [];
            const formattedDate = moment(
              `${year}-${month}-${day}`,
              "YYYY-MM-DD"
            );

            const customerFormData = {
              insured_customer_name: `${foundCustomer.first_name || ""} ${
                foundCustomer.middle_name || ""
              } ${foundCustomer.last_name || ""}`.trim(),
              gender: foundCustomer.gender || undefined,
              nationality: foundCustomer.nationality || "",
              mobile: foundCustomer.mobile_number || "",
              email: foundCustomer.email || "",
              date_of_birth: formattedDate,
              applicant_type: foundCustomer.customer_type?.display_name || "",
              customer_address_type: addressType || undefined,
            };

            // Preserve the current occupation value
            const currentOccupation = form.getFieldValue("ocupation");

            form.setFieldsValue({
              ...customerFormData,
              ocupation: currentOccupation, // Keep the current occupation
            });

            setMatchedCustomer(foundCustomer);

            // Update parent component's form values while preserving occupation
            setFormValues((prev) => {
              const prevValues = Array.isArray(prev) ? prev[0] : prev;
              return Array.isArray(prev)
                ? [
                    {
                      ...prevValues,
                      ...customerFormData,
                      ocupation: currentOccupation,
                    },
                  ]
                : {
                    ...prevValues,
                    ...customerFormData,
                    ocupation: currentOccupation,
                  };
            });
          }
        }

        // Handle address data
      
      } catch (error) {
        console.error("Failed to update form data:", error);
      }
    };

    if (customerId) {
      // Preserve occupation while resetting address fields
      const currentOccupation = form.getFieldValue("occupation");
      form.setFieldsValue({
        nationality: "",
        occupation: currentOccupation, // Keep the current occupation
      });
    }

    updateFormData();
  }, [
    customerId,
    personalData,
    addressType,
    form,
    setMatchedCustomer,
    setFormValues,
  ]);

  useEffect(() => {
    const updateAddressDetail = async() =>{
      if (customerId && addressType?.length) {
        const response = await getAddressDetailByAddresType(
          customerId,
          addressType
        );
        if (response?.data) {
          const { address_line_1, address_line_2, city, state, pincode } =
            response.data;
  
          // Preserve the current occupation value
          const currentOccupation = form.getFieldValue("ocupation");
  
          const addressFormData = {
            address_line_1: address_line_1 || "",
            address_line_2: address_line_2 || "",
            city: city?.id || undefined,
            state: state?.id || undefined,
            pincode: pincode?.id || undefined,
          };
  
          form.setFieldsValue({
            ...addressFormData,
            ocupation: currentOccupation, // Keep the current occupation
          });
  
          // Update parent component's form values while preserving occupation
          setFormValues((prev) => {
            const prevValues = Array.isArray(prev) ? prev[0] : prev;
            return Array.isArray(prev)
              ? [{ ...prevValues, ...addressFormData }]
              : { ...prevValues, ...addressFormData };
          });
        }
      }
    }
    updateAddressDetail()
  }, [addressType]);

  // Handle form value changes
  const onFormValuesChange = (changedValues, allValues) => {
    setFormValues((prevFormValues) => {
      if (Array.isArray(prevFormValues)) {
        return [{ ...prevFormValues[0], ...allValues }];
      }
      return { ...allValues };
    });
  };

  return (
    <>
      <div className="details_coverage_hdfc">Insured Customer</div>
      <div className="drawer-insurance-space">
        <Form
          onValuesChange={onFormValuesChange}
          layout="vertical"
          form={form}
          initialValues={initialValues}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="insured_customer_name"
                label="Insured Customer Name"
                required
              >
                <Input placeholder="Enter Insured Customer Name" disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="date_of_birth" label="Date Of Birth" required>
                <DatePicker
                  format="DD-MM-YYYY"
                  className="magma-insured-customer-date-of-birth"
                  placeholder="Select Date"
                  disabled
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="gender" label="Gender" required>
                <Select
                  placeholder="Select Gender"
                  className="magma-insured-customer-date-of-birth"
                  disabled
                  allowClear
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} className="occupation-nationality-contactnumber">
            <Col span={8}>
              <Form.Item
                name="ocupation"
                label="Occupation"
                required
                rules={[
                  {
                    required: true,
                    message: "Occupation is required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Occupation"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  allowClear
                  disabled={insurance_id}
                >
                  {occupationType?.map((locations) => (
                    <Option key={locations.id} value={locations.id}>
                      {locations.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="nationality" label="Nationality" required>
                <Input placeholder="Enter Nationality" disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="contact_number" label="Contact Number">
                <Input.Group compact>
                  <Form.Item noStyle>
                    <Input
                      className="insured-customer-contact-number-91"
                      defaultValue="+91"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item name="mobile" noStyle required>
                    <Input
                      className="insured-customer-contact-number"
                      placeholder="Enter Contact Number"
                      disabled
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} className="occupation-nationality-contactnumber">
            <Col span={8}>
              <Form.Item name="email" label="Email ID" required>
                <Input placeholder="Enter Email" disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="applicant_type" label="Applicant Type" required>
                <Input placeholder="Enter Applicant Type" disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="customer_address_type"
                label="Address Type"
                required
                rules={[
                  {
                    required: true,
                    message: "Address Type is required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Address Type"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  onChange={(value) => setAddressType([value])}
                  allowClear
                >
                  {addressTypeList?.map((addressType) => (
                    <Option key={addressType.id} value={addressType.id}>
                      {addressType.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} className="occupation-nationality-contactnumber">
            <Col span={12}>
              <Form.Item
                name="address_line_1"
                label="Address Line 1"
                required
                rules={[
                  {
                    required: true,
                    message: "Address Line 1 is required",
                  },
                ]}
              >
                <Input
                  placeholder="Please Enter Address line 1"
                  disabled={true}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="address_line_2"
                label="Address Line 2"
                rules={[
                  {
                    required: true,
                    message: "Address Line 2 is Required",
                  },
                ]}
                required
              >
                <Input
                  placeholder="Please Enter Address line 2"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "City is Required",
                  },
                ]}
                required
              >
                <Select
                  placeholder="Select City"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  allowClear
                  disabled={true}
                >
                  {city?.map((locations) => (
                    <Option key={locations.id} value={locations.id}>
                      {locations.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "State is Required",
                  },
                ]}
                required
              >
                <Select
                  placeholder="Select State"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                  }
                  allowClear
                  disabled={true}
                >
                  {states?.map((state) => (
                    <Option key={state.id} value={state.id}>
                      {state.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="pincode"
                label="Pincode"
                rules={[
                  {
                    required: true,
                    message: "Pincode is Required",
                  },
                ]}
                required
              >
                <Select
                  placeholder="Select Pincode"
                  showSearch
                  disabled={true}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {pincode?.map((pincodes) => (
                    <Option key={pincodes.id} value={pincodes.id}>
                      {pincodes.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default MagmaInsuredCustomerForm;
