import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import userReducer from './userSlice';
import salesFormReducer from './salesFormSlice'
// import notificationReducer from  './notification'
import notificationReducer from "./notificationSlice";
import notificationMiddleware from "./notificationMiddleware";

 
const userPersistConfig = {
  key: "user",
  storage,
};

const salesFormPersistConfig = {
  key: "salesForm",
  storage,
};

const notificationPersistConfig = {
  key: "notification",
  storage,
};

const persistedReducer = persistReducer(userPersistConfig, userReducer);
const persistedSalesFormReducer = persistReducer(salesFormPersistConfig, salesFormReducer);
const persistedNotificationReducer = persistReducer(notificationPersistConfig, notificationReducer);


export const store = configureStore({
  reducer: {
    user: persistedReducer,
    salesForm: persistedSalesFormReducer,
    notification:persistedNotificationReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(notificationMiddleware),
});

export const persistor = persistStore(store);
