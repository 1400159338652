import { Avatar, Button, Tooltip } from "antd";
import "../styles/chatbox.css";
import { RxCross1 } from "react-icons/rx";
import { PaperClipOutlined, SendOutlined } from "@ant-design/icons";
import useWebSocket from "../helperFunctions/useWebSocketHook";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  formatISOChatDate,
  getAllChatListByLoanId,
  getChatUsers,
} from "../helperFunctions/apiServices";
import { Mention, MentionsInput } from "react-mentions";
import baseConfig from "../../../config/baseConfig";
import FileRenderer from "./FileRenderer";
import { FileOutlined } from "@ant-design/icons";

const isImageFile = (file) => {
  if (!file) return false;

  // Check if it's a File object
  if (file instanceof File && file.type.startsWith("image/")) {
    return true;
  }

  // Check if it's a Base64 image string
  if (typeof file === "string" && file.startsWith("data:image/")) {
    return true;
  }

  return false;
};

export default function ChatBox({ loan_id, closeDrawer }) {
  const [inputMessage, setInputMessage] = useState("");
  const fileInputRef = useRef(null);
  const chatWindowRef = useRef(null);
  const [activeUsers, setActiveUsers] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userList, setUserList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loadingOlderMessages, setLoadingOlderMessages] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(true);

  const chatSocketUrl = `${
    baseConfig.socketurlchat
  }chat/${loan_id}/?Authorization=${localStorage.getItem("access") || ""}`;

  const {
    // messages: socketMessages,
    sendMessage,
    isConnected,
    lastMessage,
  } = useWebSocket(chatSocketUrl);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      try {
        const response = await getChatUsers(loan_id, {
          signal: controller.signal,
        });
        setUserList(response.data || []);
      } catch (error) {
        if (error.name !== "AbortError") console.error(error);
      }
    })();
    return () => controller.abort();
  }, [loan_id]);

  useEffect(() => {
    if (lastMessage?.message_type === "active_users") {
      setActiveUsers(lastMessage.users || []);
    }
  }, [lastMessage]);

  const loggedInUserId = useSelector((state) => state?.user?.user_data?.id);

  useEffect(() => {
    fetchMoreMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastMessage && lastMessage.message) {
      setMessages((prev) => {
        const isDuplicate = prev.some(
          (msg) => msg.message.id === lastMessage.message.id
        );
        return isDuplicate ? prev : [...prev, lastMessage];
      });
    }
  }, [lastMessage]);

  const fetchMoreMessages = async () => {
    if (!hasMore || loadingOlderMessages) return;

    setLoadingOlderMessages(true);
    const response = await getAllChatListByLoanId(loan_id, page);
    if (response.data.length > 0) {
      setMessages((prevMessages) => [...response.data, ...prevMessages]);
      setPage((prevPage) => prevPage + 1);
    } else {
      setHasMore(false);
    }
    setLoadingOlderMessages(false);
  };

  const handleScroll = () => {
    if (!chatWindowRef.current) return;
  
    const { scrollTop, scrollHeight, clientHeight } = chatWindowRef.current;
  
    // Fix floating-point errors in scroll detection
    const atBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 5;
  
    setIsAtBottom(atBottom);
  
    // Load older messages when scrolled to the top
    if (scrollTop === 0 && !loadingOlderMessages) {
      setLoadingOlderMessages(true);
      fetchMoreMessages();
    }
  };
  
  // Ensures scrolling happens **after** messages fully render
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      setTimeout(() => {
        chatWindowRef.current.scrollTo({
          top: chatWindowRef.current.scrollHeight,
          behavior: "instant", // Change to "instant" to ensure proper positioning
        });
      }, 0);
    }
  };
  
  // Fix: Wait for DOM updates before scrolling
  useEffect(() => {
    if (isAtBottom) {
      requestAnimationFrame(scrollToBottom);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);
  
  // **Critical Fix:** Ensure correct height updates when messages grow
  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (!chatWindow) return;
  
    const observer = new ResizeObserver(() => {
      if (isAtBottom) {
        scrollToBottom();
      }
    });
  
    observer.observe(chatWindow);
  
    return () => observer.disconnect();
  }, [isAtBottom]);
  


  // Send message with tagged users
  const handleSendMessage = () => {
    const hasText = inputMessage.trim().length > 0;
    const hasImage = !!selectedImage;

    if (!hasText && !hasImage) return; // Prevent empty messages

    const messageToSend = {
      loan_detail: loan_id,
      ...(hasText && { content: inputMessage }), // Include content only if present
      ...(hasImage && { media_file: selectedImage }), // Include media only if present
    };

    sendMessage(messageToSend);
    setSelectedImage(null);
    setInputMessage("");
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // Converts file to base64
      reader.onload = () => {
        setSelectedImage(reader.result); // Stores base64 in state
        console.log(reader.result);
      };
      reader.onerror = (error) => {
        console.error("Error converting file to base64:", error);
      };
    }
  };

  return (
    <div className="chatbox_main_container">
      <div className="chatbox_header">
        <Avatar.Group>
          {activeUsers.length > 0 ? (
            activeUsers.map((user) => (
              <Tooltip title={user.name} key={user.id} placement="top">
                <Avatar
                  style={{ backgroundColor: "#1677ff" }}
                  src={user.thumb_profile_img || undefined}
                >
                  {!user.thumb_profile_img && user.name[0]}
                </Avatar>
              </Tooltip>
            ))
          ) : (
            <Avatar style={{ backgroundColor: "#f56a00" }}>V</Avatar>
          )}
        </Avatar.Group>
        <span>{isConnected ? "Connected" : "Disconnected"}</span>
        <RxCross1
          style={{
            cursor: "pointer",
          }}
          onClick={closeDrawer}
        />
      </div>

      <div
        ref={chatWindowRef}
        onScroll={handleScroll}
        className="chatbox_message_window"
      >
        <div className="chatbox_messages">
          {messages.map((msg) => {
            if (msg?.message?.message_type === "chat_message") {
              return (
                <div
                  key={msg.message.id}
                  className={`chat_bubble ${
                    msg?.message?.user?.id === loggedInUserId
                      ? "sent"
                      : "received"
                  }`}
                >
                  {msg?.message?.user?.id !== loggedInUserId && (
                    <Tooltip title={msg?.message?.user.name} placement="top">
                      <Avatar
                        className="chat_avatar"
                        src={msg?.message?.user?.thumb_profile_img || undefined}
                      >
                        {!msg?.message?.user?.thumb_profile_img &&
                          msg?.message?.user.name[0]}
                      </Avatar>
                    </Tooltip>
                  )}
                  <div className="chat_content">
                    {msg?.message?.media?.media_file &&
                      msg?.message?.media?.file_type && (
                        <FileRenderer
                          fileUrl={msg?.message?.media?.media_file}
                          fileType={msg?.message?.media?.file_type}
                        />
                      )}

                    {msg?.message?.content && (
                      <p className="chat_text">
                        {msg.message.content
                          .split(/(@\[[^\]]+\]\(\d+\))/g)
                          .map((part, index) =>
                            part.match(/^@\[(.*?)\]\(\d+\)$/) ? (
                              <span
                                key={index}
                                className={
                                  msg?.message?.user?.id === loggedInUserId
                                    ? "tagged_user_sent"
                                    : "tagged_user_received"
                                }
                              >
                                {part.match(/^@\[(.*?)\]\(\d+\)$/)[1]}
                              </span>
                            ) : (
                              part
                            )
                          )}
                      </p>
                    )}
                  </div>
                  <span
                    className={`chat_time_${
                      msg?.message?.user?.id === loggedInUserId
                        ? "sent"
                        : "received"
                    }`}
                  >
                    {formatISOChatDate(msg?.message?.time_stamp)}
                  </span>
                </div>
              );
            }
            if (msg?.message?.message_type === "chat_notification") {
              return (
                <div
                  key={msg.message.id}
                  className={"chat_notification_bubble"}
                >
                  <div className="chat_notification_content">
                    <p className="chat_notification_text">
                      {msg?.message?.content
                        ?.split(/(@\[[^\]]+\]\(\d+\))/g)
                        .map((part, index) =>
                          part.match(/^@\[(.*?)\]\(\d+\)$/) ? (
                            <span
                              key={index}
                              className="tagged_user_notification"
                            >
                              {part?.match(/^@\[(.*?)\]\(\d+\)$/)[1]}
                            </span>
                          ) : (
                            part
                          )
                        )}
                    </p>
                  </div>
                  <span className={`notification_time`}>
                    {formatISOChatDate(msg?.message?.time_stamp)}
                  </span>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>

      <div className="chatbox_footer">
        <Button
          type="text"
          icon={<PaperClipOutlined />}
          className="attach_btn"
          onClick={() => fileInputRef.current.click()}
        >
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </Button>

        <div className="chat_input_wrapper">
          {selectedImage && (
            <div className="image_preview_container">
              {isImageFile(selectedImage) ? (
                <img
                  src={selectedImage}
                  alt="upload"
                  className="image_preview"
                />
              ) : (
                <FileOutlined style={{ fontSize: 50 }} />
              )}
              <RxCross1
                className="image_delete_icon"
                onClick={() => setSelectedImage(null)}
              />
            </div>
          )}

          <div className="chat_input_wrapper">
            <MentionsInput
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              className="chat_input mention_input"
              placeholder="Type a message..."
              onKeyDown={handleEnterKeyPress}
              style={{
                suggestions: {
                  list: {
                    bottom: "100%", // Moves the mention list above the input
                    top: "auto",
                    position: "absolute",
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    maxHeight: "150px",
                    overflowY: "auto",
                    width: "280px",
                  },
                  item: {
                    padding: "5px 10px",
                    cursor: "pointer",
                  },
                },
                control: {
                  backgroundColor: "white",
                  fontSize: 14,
                  minHeight: 40,
                  maxHeight: 120,
                  overflowY: "auto",
                  overflowX: "hidden", // Fix horizontal overflow
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                  paddingRight: 16, // Prevents text from overlapping scrollbar
                  resize: "none", // Prevents unwanted resizing
                },
                highlighter: {
                  padding: 9,
                  border: "1px solid transparent",
                  minHeight: 40,
                },
                input: {
                  margin: 0,
                  padding: 9,
                  minHeight: 40,
                  maxHeight: 120,
                  overflowY: "auto",
                  overflowX: "hidden", // Prevent horizontal scrolling
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                },
              }}
            >
              <Mention
                trigger="@"
                data={userList.map((user) => ({
                  id: user.id,
                  display: user.name,
                }))}
                className="mention"
                appendSpaceOnAdd
                displayTransform={(id, display) => `@${display}`}
              />
            </MentionsInput>
          </div>
        </div>

        <Button
          onClick={handleSendMessage}
          type="text"
          icon={<SendOutlined />}
          className="send_btn"
        />
      </div>
    </div>
  );
}
