
import React from "react";
import { Table } from "antd";

const BackgroundStatusTable = ({ backgroundCheckStatusData }) => {
  const isVerified =
  backgroundCheckStatusData &&
  backgroundCheckStatusData.data?.result &&
  backgroundCheckStatusData.data?.result.bankTxnStatus;

  if (!isVerified) {
    const unverifiedDataSource = [
      { key: "Account Number", value: "-" },
      { key: "IFSC Code", value: "-" },
      { key: "Account Holder Name", value: "-" },
      { key: "Bank Response", value: "-" },
      { key: "Transaction Status", value: "Failed" },
    ];

    const columns = [
      {
        title: "Field",
        dataIndex: "key",
        key: "key",
        width: "50%",
      },
      {
        title: "Value",
        dataIndex: "value",
        key: "value",
        width: "50%",
      },
    ];

    return (
      <div>
        <p className="text-warning mb-3">This bank account is not verified</p>
        <Table
          dataSource={unverifiedDataSource}
          columns={columns}
          pagination={false}
          bordered
        />
      </div>
    );
  }

  const { result } = backgroundCheckStatusData.data;

  const dataSource = [
    { key: "Account Number", value: result.accountNumber || "-" },
    { key: "IFSC Code", value: result.ifsc || "-" },
    { key: "Account Holder Name", value: result.accountName || "-" },
    { key: "Bank Response", value: result.bankResponse || "-" },
    {
      key: "Transaction Status",
      value: result.bankTxnStatus ? "Success" : "Failed",
    },
  ];

  const columns = [
    {
      title: "Field",
      dataIndex: "key",
      key: "key",
      width: "50%",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: "50%",
    },
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      bordered
    />
  );
};

export default BackgroundStatusTable;
