import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Typography,
  Progress,
  Avatar,
  Layout,
  message,
} from "antd";
import { getEmployeeBirthday } from "../service/getEmployeeBirthday";
import {
  getEmployeePunchInDetails,
  getEmployeeWorkAnniversery,
  employeePunchOutService,
} from "../service/getEmployeeWorkAnniversery";
import cakeImage from "../assets/Group.png";
import starFive from "../assets/StarIcon.png";
import "../style/DashBoardLiteEmployeeDetail.css";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const { Text } = Typography;

const DashBoardLiteEmployeeDetail = () => {
  const [birthdayCelebrations, setBirthdayCelebrations] = useState([]);
  const [anniversaryCelebrations, setAnniversaryCelebrations] = useState([]);
  const [punchInDetails, setPunchInDetails] = useState([]);
  const [workTime, setWorkTime] = useState("00:00");
  const [progressPercent, setProgressPercent] = useState(0);

  const user_id = useSelector((state) => state?.user?.user_data?.id);
  const latitude = useSelector((state) => state?.user?.user_location?.latitude);
  const longitude = useSelector(
    (state) => state?.user?.user_location?.longitude
  );

  const time_sheet_event_type = "MTSETNNH";
  const currentDate = new Date();
  const day = currentDate.getDate(); // Get the current day (1-31)
  const month = currentDate.getMonth() + 1; // Get the current month (0-11), so we add 1 to make it 1-12
  const year = currentDate.getFullYear();

  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // For numbers between 11 and 20, use 'th'
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const inTimestamp = punchInDetails?.in_timestamp;
  const parsedDate = dayjs(inTimestamp, "DD-MM-YYYY hh:mm:ss.SSSSSS A");

  // Format the full date as "Tuesday, Oct 15th, 2024"
  const formattedDate = parsedDate.format("dddd, MMM D, YYYY");
  const dayOfMonth = parsedDate.date();
  const dateWithSuffix =
    formattedDate.replace(
      `${dayOfMonth}`,
      `${dayOfMonth}${getDaySuffix(dayOfMonth)}`
    ) || null;

  // Format the time as "HH:mm"
  const formattedTime = parsedDate.format("hh:mm A"); // Add AM/PM for clarity

  useEffect(() => {
    const getBirthdayList = async () => {
      try {
        const response = await getEmployeeBirthday();
        setBirthdayCelebrations(response.data);
      } catch (error) {
        setBirthdayCelebrations([]); // Ensure the list is empty if there is an error
      }
    };

    const getAnniversaryList = async () => {
      try {
        const response = await getEmployeeWorkAnniversery();
        if (response.data.length) {
          setAnniversaryCelebrations(response.data);
        } else {
          setAnniversaryCelebrations([]);
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
        setAnniversaryCelebrations([]); // Ensure the list is empty if there is an error
      }
    };

    getBirthdayList();
    getAnniversaryList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPunchInDetails = async () => {
    try {
      const response = await getEmployeePunchInDetails(
        time_sheet_event_type,
        user_id,
        day,
        month,
        year
      );
      setPunchInDetails(response.data);
    } catch (error) {
      message.error(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (!user_id) {
      return;
    }

    getPunchInDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  const handlePunchOut = async () => {
    const punchOutData = {
      time_sheet_event_type: time_sheet_event_type,
      latitude: latitude,
      longitude: longitude,
    };

    try {
      await employeePunchOutService(punchOutData);
      if (Object.keys(punchInDetails).length === 0) {
        message.success("Successfully punched In!");
      } else {
        message.success("Successfully punched out!");
      }
      getPunchInDetails();
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (!punchInDetails?.in_timestamp) return;

    const inTimestamp = dayjs(
      punchInDetails.in_timestamp,
      "DD-MM-YYYY hh:mm:ss.SSSSSS A"
    );
    const workShiftDuration = 9 * 60; // 8 hours in minutes

    const updateWorkTime = () => {
      const now = dayjs();
      const diffInMinutes = now.diff(inTimestamp, "minute");

      const hours = Math.floor(diffInMinutes / 60);
      const minutes = diffInMinutes % 60;

      // Format work time as "Xh Ym"
      const formattedTime = `${hours}h ${minutes}m`;
      setWorkTime(formattedTime);

      // Calculate progress percentage
      const progress = Math.min((diffInMinutes / workShiftDuration) * 100, 100);
      setProgressPercent(progress);
    };

    updateWorkTime();
    const intervalId = setInterval(updateWorkTime, 1000 * 60); // Update every minute

    return () => clearInterval(intervalId);
  }, [punchInDetails]);

  return (
    <Layout className="layout_employee">
      <div className="layout_div">
        <Card
          bordered={false}
          style={{
            borderRadius: "5px",
            marginBottom: "19px",
          }}
        >
          <p className="title">Let's Get to Work</p>
          <p className="subheading">
            {dateWithSuffix === "Invalid Date" ? "" : dateWithSuffix}
          </p>
          <div className="card_div">
            {formattedTime === "Invalid Date" ? (
              <div></div>
            ) : (
              <Text>Punch-in: {formattedTime || ""}</Text>
            )}
            <Text>
              Work Time: <strong>{workTime || ""}</strong>
            </Text>
          </div>
          <Progress
            percent={progressPercent}
            showInfo={false}
            strokeColor="#73C9AE"
          />

          <Text type="secondary" className="card_text">
            Shift: 09:30 - 18:30
          </Text>
          <Button type="primary" block onClick={handlePunchOut}>
            {Object.keys(punchInDetails).length === 0
              ? "Punch In"
              : "Punch Out  "}
          </Button>
        </Card>

        <Card
          className="title"
          title="Birthday Celebrations"
          headStyle={{
            color: "rgb(39, 101, 143)",
            fontFamily: "poppins",
            fontSize: "20px",
            fontWeight: 500,
          }}
          style={{ marginBottom: "18px" }}
        >
          <div className="card_second_div">
            <style>
              {`
      /* Hide scrollbar for WebKit-based browsers */
      div::-webkit-scrollbar {
        display: none;
      }
    `}
            </style>

            {birthdayCelebrations?.length === 0 ? (
              <div
                style={{ textAlign: "center", padding: "20px", color: "#888" }}
              >
                No birthdays today
              </div>
            ) : (
              birthdayCelebrations?.map((item, index) => (
                <div key={index} className="birthday_celebration_div">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={item?.profile_img}
                      className="birthday_avatar"
                    />
                    <div className="birthday_celebration_div_second">
                      <Text>{item?.name}</Text>
                      <br />
                      <Text type="secondary">{item?.location?.name}</Text>
                    </div>
                  </div>
                  <img src={cakeImage} alt="cake" className="birthday_img" />
                </div>
              ))
            )}
          </div>
        </Card>

        {/* <Title level={4} className="title">
          Anniversary Celebrations
        </Title> */}
        <Card
          title="Anniversary Celebrations"
          headStyle={{
            color: "rgb(39, 101, 143)",
            fontFamily: "poppins",
            fontSize: "20px",
            fontWeight: 400,
          }}
        >
          <div
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              scrollbarWidth: "none", // Firefox
              msOverflowStyle: "none", // Internet Explorer 10+
            }}
          >
            <style>
              {`
      /* Hide scrollbar for WebKit-based browsers */
      div::-webkit-scrollbar {
        display: none;
      }
    `}
            </style>

            {anniversaryCelebrations.length === 0 ? (
              <div className="anniversary_div_first">
                No work anniversaries today
              </div>
            ) : (
              anniversaryCelebrations?.map((item, index) => {
                const randomColor = `#${Math.floor(
                  Math.random() * 16777215
                ).toString(16)}`;
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "70px",
                      marginBottom: "10px",
                      borderRadius: "12px",
                      border: `1px solid ${randomColor}`,
                      padding: "0 15px",
                      backgroundColor: `${randomColor}20`,
                    }}
                  >
                    <div className="anniversary_div_second">
                      <Avatar
                        src={item?.profile_img}
                        className="anniversary_avatar"
                      />
                      <div style={{ marginLeft: "10px" }}>
                        <Text>{item?.name}</Text>
                        <br />
                        <Text type="secondary">{item?.location?.name}</Text>
                      </div>
                    </div>
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={starFive}
                        alt="cake"
                        className="anniversary_img"
                        style={{ display: "block", height: "40px" }}
                      />
                      <div className="imageText">{item?.anniversary_year}</div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default DashBoardLiteEmployeeDetail;
