import React, { useState, useEffect } from "react";
import { Modal, Drawer, message } from "antd";
import {
  getInsuranceDetailsByIdService,
  deleteHDFCErgo,
} from "../services/loanInsuranceService";
import { DeleteOutlined } from "@ant-design/icons";
import HDFCInsuranceForm from "./HDFCInsuranceForm";
import Header from "../../../../layout/views/Header";
import pdf_image from "../assets/pdf.png";
import EditButton from "../../../../../utils/editButton/EditButton";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import { useSelector } from "react-redux";

const HDFCInsuranceview = ({
  insurance_id,
  loan_id,
  toggleRefreshInsurance,
  setAddressType,
  addressType
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [customer_id, setCustomer_id] = useState("");
  const [imageData, setImagedata] = useState(null);
  const [refreshDelete, setRefreshTableDelete] = useState(false);

  const { user_data } = useSelector((state) => state.user);


  const toggleRefreshDelete = () => {
    setRefreshTableDelete((prev) => !prev);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setSelectedUserId(null);
    setOpen(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getInsuranceDetailsByIdService(insurance_id);
        setData(data.data);
        setImagedata(data?.data?.document);
        setCustomer_id(
          data?.data?.insured_customer_detail?.customer_detail?.id
        );
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurance_id, refreshDelete]);

  const deleteUserById = async () => {
    try {
      const response = await deleteHDFCErgo(insurance_id);
      if (response.status === 200 && response.success === true) {
        message.success("HDFC Ergo deleted successfully");
        setOpenModal(false);
        toggleRefreshInsurance();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  // Merge function
  const mergeData = (insuredCustomers, nominees) => {
    return insuredCustomers?.map((customer) => {
      const matchingNominees = nominees.filter(
        (nominee) => nominee.insurance_customer_detail === customer.id
      );
      return {
        ...customer,
        nominees: matchingNominees[0],
      };
    });
  };
  // Merged result
  const mergedResult = mergeData(
    data?.insured_customer_detail,
    data?.nominee_detail
  );

  const IndianNumberFormat = (value) => {
    return new Intl.NumberFormat('en-IN').format(value);
  };

  return (
    <div>
      <div className="icici_view_header">
        <div className="insurance_view_heading"></div>
        <div className="insurance_button_container">
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["EDITINSR"]}
          >
            <div
              className="insurance-edit-button"
              onClick={() => {
                showDrawer();
              }}
            >
              <EditButton />
            </div>
          </ConditionalRender>
          <Drawer
            title={
              <Header
                title={insurance_id ? "Edit" : "Add"}
                onClose={onClose}
                name="HDFC Insurance"
              />
            }
            width={1000}
            onClose={onClose}
            open={open}
            styles={{
              body: { paddingBottom: 80, paddingLeft: 0, paddingRight: 0 },
            }}
            closable={false}
          >
            <HDFCInsuranceForm
              toggleRefreshInsurance={toggleRefreshInsurance}
              id={selectedUserId}
              open={open}
              images={imageData}
              refreshDelete={toggleRefreshDelete}
              closeForm={onClose}
              onCancel={onClose}
              setId={setSelectedUserId}
              insurance_id={insurance_id}
              loan_id={loan_id}
              customer_id={customer_id}
              setAddressType={setAddressType}
              addressType={addressType}
            />
          </Drawer>
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["DELTINSR"]}
          >
            <div
              className="insurance-delete-button"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <DeleteOutlined />
            </div>
          </ConditionalRender>
        </div>
      </div>

      <div className="icici_view_header">
        <div className="insurance_view_heading">Details Of Coverage</div>
      </div>

      <div className="loan_and_insurance_container">
        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Loan Amount</div>
            <div className="loan_inusurance_data">
                 {data?.insurance_coverage_data?.loan_amount ? `₹ ${IndianNumberFormat(data?.insurance_coverage_data?.loan_amount)}` : '-'}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">PAN</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.pan_no || "-"}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Tenure Of Loan</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.tenure || "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">
              Premium Amount (Including GST)
            </div>
            <div className="loan_inusurance_data">
{data?.insurance_coverage_data?.total_premium_incl_gst ? `₹ ${IndianNumberFormat(data?.insurance_coverage_data?.total_premium_incl_gst)}` : '-'}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Policy Term(In Months)</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.policy_term || "-"}
            </div>
          </div>
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Property Type</div>
            <div className="loan_inusurance_data">
              {data?.insurance_coverage_data?.proprty_type?.name || "-"}
            </div>
          </div>
        </div>

        <div className="laon_insurance_Basic_card">
          <div className="loan_insurance_basic_card_content">
            <div className="loan_inusurance_label">Sum Assured</div>
            <div className="loan_inusurance_data">
                 {data?.insurance_coverage_data?.sum_assured ? `₹ ${IndianNumberFormat(data?.insurance_coverage_data?.sum_assured)}` : '-'}
            </div>
          </div>
        </div>
      </div>

      {mergedResult?.map((insuredCustomer, index) => {
        return (
          <>
            <div className="icici_view_header" key={insuredCustomer?.id}>
              <div className="insurance_view_heading">
                Life To Be Assured {index + 1}
              </div>
            </div>
            <div className="loan_and_insurance_container">
              <div className="laon_insurance_Basic_card">
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Name</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.first_name || "-"}{" "}
                    {insuredCustomer?.middle_name || "-"}{" "}
                    {insuredCustomer?.last_name || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Nationality</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.nationality || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Address Line 2</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.address_line_2 || "-"}
                  </div>
                </div>
              </div>

              <div className="laon_insurance_Basic_card">
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Date Of Birth</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.date_of_birth || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Contact Number</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.mobile || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">City</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.city?.name || "-"}
                  </div>
                </div>
              </div>

              <div className="laon_insurance_Basic_card">
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Gender</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.gender || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Email</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.email || "-"}
                  </div>
                </div>

                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">State</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.state?.name || "-"}
                  </div>
                </div>
              </div>

              <div className="laon_insurance_Basic_card">
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Occupation</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.ocupation?.name || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Address Line 1</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.address_line_1 || "-"}
                  </div>
                </div>

                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Pincode</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.pincode?.name || "-"}
                  </div>
                </div>
              </div>
            </div>

            <p className="second_heading_details_container">
              Nominee Details
            </p>
            <div className="loan_and_insurance_container">
              <div className="laon_insurance_Basic_card">
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Name</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.nominees?.name || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">
                    Nominee's Contact Number
                  </div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.nominees?.mobile || "-"}
                  </div>
                </div>

                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">State</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.nominees?.state?.name || "-"}
                  </div>
                </div>
              </div>

              <div className="laon_insurance_Basic_card">
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">
                    Relation With The Life To Be Assured
                  </div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.nominees?.relation?.name || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Address Line 1</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.nominees?.address_line_1 || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Pincode</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.nominees?.pincode?.name || "-"}
                  </div>
                </div>
              </div>

              <div className="laon_insurance_Basic_card">
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">
                    Nominee's Date Of Birth
                  </div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.nominees?.date_of_birth || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">Address Line 2</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.nominees?.address_line_2 || "-"}
                  </div>
                </div>

            
              </div>

              <div className="laon_insurance_Basic_card">
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">
                    Nominee's Gender
                  </div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.nominees?.gender || "-"}
                  </div>
                </div>
                <div className="loan_insurance_basic_card_content">
                  <div className="loan_inusurance_label">City</div>
                  <div className="loan_inusurance_data">
                    {insuredCustomer?.nominees?.city?.name || "-"}
                  </div>
                </div>
              </div>
            </div>

            {data?.nominee_detail?.[index]?.appointee && (
        <>
          <div className="second_heading_details_container">
            Appointee Details
          </div>
          <div className="loan_and_insurance_container">
            <div className="laon_insurance_Basic_card">
              <div className="loan_insurance_basic_card_content">
                <div className="loan_inusurance_label">Appointee Name</div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail?.[index]?.appointee?.appointee_name || "-"}
                </div>
              </div>

              <div className="loan_insurance_basic_card_content">
                <div className="loan_inusurance_label">
                  Appointee's Contact Number
                </div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail?.[index]?.appointee?.mobile || "-"}
                </div>
              </div>
            </div>

            <div className="laon_insurance_Basic_card">
              <div className="loan_insurance_basic_card_content">
                <div className="loan_inusurance_label">
                  Appointee's Date Of Birth
                </div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail?.[index]?.appointee?.date_of_birth || "-"}
                </div>
              </div>
            </div>

            <div className="laon_insurance_Basic_card">
              <div className="basic_card-content">
                <div className="loan_inusurance_label">Appointee's Gender</div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail?.[index]?.appointee?.gender || "-"}
                </div>
              </div>
            </div>

            <div className="laon_insurance_Basic_card">
              <div className="loan_insurance_basic_card_content">
                <div className="loan_inusurance_label">
                  Relationship With Nominee
                </div>
                <div className="loan_inusurance_data">
                  {data?.nominee_detail?.[index]?.appointee?.relation?.name || "-"}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
          </>
        );
      })}

      <div
        className="hdfc-image-gallery"
      >
        {imageData?.map((picture, index) => (
          <div key={index} className="image-container">
            {picture.file_type === "application/pdf" ? (
              <a
                href={picture.document}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={pdf_image}
                  className="hdfc-pdf-image"
                  alt="PDF Preview"
                />
              </a>
            ) : (
              <RViewerJS
                options={{
                  url: (image) => image.getAttribute("data-original"),
                }}
              >
                <img
                  src={picture.thumb_document}
                  data-original={picture.document}
                  alt="Preview"
                  className="hdfc-pdf-image"
                />
              </RViewerJS>
            )}
          </div>
        ))}
      </div>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteUserById(); // Pass the current deleteValue
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this insurance?`}</p>
      </Modal>
    </div>
  );
};

export default HDFCInsuranceview;
