import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationSocket: null,
  unseenNotificationCount: 0, // ✅ New field to store unseen count from API
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationSocket: (state, action) => {
      state.notificationSocket = action.payload;
    },
    setUnseenNotificationCount: (state, action) => {
      state.unseenNotificationCount = action.payload; // ✅ Set unseen count from API
    },
    resetNotifications: (state) => {
      state.notifications = [];
    },
    incrementUnseenNotificationCount: (state) => {
      state.unseenNotificationCount += 1;
    },
    clearUnseenNotifications: (state) => {
      state.unseenNotificationCount = 0;
    },
  },
});

export const {
  setNotificationSocket,
  setUnseenNotificationCount,
  resetNotifications,
  incrementUnseenNotificationCount,
  clearUnseenNotifications
} = notificationSlice.actions;

export default notificationSlice.reducer;
