import { axiosRequest } from "../../../utils/api/axiosRequest";

export const getLoanAllNotification = (page, limit) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/communication/messages/notifications/?page=${page}&limit=${limit}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const clearAllNotifications = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        `/api/v1/communication/messages/notifications/`,
        {
          clear_all: "True",
        }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
