import { axiosRequest } from "../../../../utils/api/axiosRequest";
export const getAllSubSection = (stage_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/master-stage-wise-sub-section/list?stage=${stage_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllQueryStatus = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choice/query-status`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getAllUsers = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/user/get-user-list-by-loan-location?loan_id=${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllStages = (stage_code) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-stage-history/stage-re-intitied-dropdown/?current_stage=${stage_code}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createSendBackQuery = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/loan/stage-query/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateUserAndStatusById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/loan-stage-history/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getCustomerListByLoanIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/customer-detail/personal-detail-list/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAgentListByAgency = (agency_code, loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/master/agency/list`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
