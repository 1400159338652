import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, Space, Select, message, InputNumber } from "antd";
import {
  getMonthlyHouseholdListService,
  getMonthlyHouseholdFrequencyListService,
  createMonthlyHouseholdService,
  updateMonthlyHouseholdService,
} from "../services/creditAnalysisServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
const { Option } = Select;
// const { Title } = Typography;

const MonthlyHouseholdForm = ({ open, closeForm, refreshData, selectedHouseholdRow }) => {
  const [form] = Form.useForm();
  const [expenseNature, setExpenseNature] = useState([]);
  const [expenseNatureFrequency, setExpenseNatureFrequency] = useState([]);
  const [loading, setLoading] = useState(false);
  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const getAllExpenseNatures = async () => {
    try {
      const response = await getMonthlyHouseholdListService();
      if (selectedHouseholdRow) {
        const filteredRecords = response?.data.filter(record => record?.display_name === selectedHouseholdRow?.expense_nature?.display_name)
        setExpenseNature(filteredRecords);
        form.setFieldsValue({

          expense_nature: selectedHouseholdRow?.expense_nature?.id,
          frequency: selectedHouseholdRow?.frequency,
          amount: selectedHouseholdRow?.amount,

        })

      } else {

        setExpenseNature(response.data);
      }
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("Expenses fetched successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllExpenseNaturesFrequency = async () => {
    try {
      const response = await getMonthlyHouseholdFrequencyListService();
      setExpenseNatureFrequency(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("Frequency fetched successfully");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (open) {
      getAllExpenseNatures();
      getAllExpenseNaturesFrequency();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (selectedHouseholdRow) {
        values.loan_detail = parseInt(loan_id);
        const response = await updateMonthlyHouseholdService(selectedHouseholdRow.id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Monthly Household details successfully updated");
          refreshData();
          closeForm();

        }
      } else {
        values.loan_detail = parseInt(loan_id);
        const response = await createMonthlyHouseholdService(values);
        if ((response.status = 200 && response.success)) {
          message.success("Monthly Household details successfully created");
          form.resetFields()
          refreshData();
          closeForm();

        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false)
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        expenses: expenseNature?.map((nature) => ({
          expense_nature: nature.name,
          expense_frequency: "",
          amount: "",
        })),
      }}
    >
      {/* Table Header */}
      <Row gutter={16} style={{ fontWeight: "bold", marginBottom: "10px" }}>
        <Col span={8}>
          Expense Nature <span style={{ color: "red" }}>*</span>
        </Col>
        <Col span={8}>
          Frequency <span style={{ color: "red" }}>*</span>
        </Col>
        <Col span={8}>
          Amount (<span style={{ fontSize: "16px" }}>₹</span>) <span style={{ color: "red" }}>*</span>
        </Col>
      </Row>

      {
        selectedHouseholdRow ? (
          <>
            <Row gutter={16} style={{ marginBottom: "10px" }}>
              <Col span={8}>
                <Form.Item >
                  <Input value={selectedHouseholdRow?.expense_nature?.display_name} readOnly />
                </Form.Item>
                <Form.Item
                  name={'expense_nature'}
                  style={{ display: 'none' }}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name={'frequency'}
                  rules={[{ required: true, message: "Please select frequency" }]}
                >
                  <Select placeholder="Please Select Frequency" showSearch optionFilterProp="children">
                    {expenseNatureFrequency?.map((frequency) => (
                      <Option key={frequency} value={frequency}>
                        {frequency}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name={'amount'}
                  rules={[{ required: true, message: "Please enter the amount" }]}
                >
                  <InputNumber
                    placeholder="Please enter Amount"
                    style={{ width: "100%" }}
                    min={0}
                    maxLength={10}
                    controls={true}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <>
            {expenseNature?.map((nature, index) => (
              <Row gutter={16} key={nature.id || index} style={{ marginBottom: "10px" }}>
                <Col span={8}>
                  <Form.Item>
                    <Input value={nature.name} readOnly />
                  </Form.Item>
                  <Form.Item
                    name={['data', index, 'expense_nature']}
                    initialValue={nature.id}
                    style={{ display: 'none' }}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name={['data', index, 'frequency']}
                    rules={[{ required: true, message: "Please select frequency" }]}
                  >
                    <Select placeholder="Please Select Frequency" showSearch optionFilterProp="children">
                      {expenseNatureFrequency?.map((frequency) => (
                        <Option key={frequency} value={frequency}>
                          {frequency}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name={['data', index, 'amount']}
                    rules={[{ required: true, message: "Please enter the amount" }]}
                  >
                    <InputNumber
                      placeholder="Please enter Amount"
                      style={{ width: "100%" }}
                      min={0}
                      maxLength={10}
                      controls={true}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ))
            }
          </>
        )
      }




      {/* Submit/Cancel Buttons */}
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse", marginTop: "20px" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};


export default MonthlyHouseholdForm;
