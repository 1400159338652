import React, { useEffect, useState } from "react";
import "../styles/propertyMatching.css";
import "../styles/customerMatching.css";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { getPropertyDedupe } from "../services/dedupeService";
import { message, Modal } from "antd";
import Loading from "../../../utils/loading/Loading";
import correctImage from "../assets/correct.png";
import linkImage from "../assets/link.png";
import CustomNoData from "../../../utils/noDataIcon/CustomNoData";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { encrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import { getLoanIdForHyperlink } from "../services/dedupeService";
import { dedupePropertyLinkService } from "../services/dedupeService";

const PropertyMatching = ({ loanId }) => {
  const [showNoData, setShowNoData] = useState(false);
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (item) => {
    setSelectedRecord(item);
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    await handleLinkClick(selectedRecord);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPropertyDepupeData = async () => {
      try {
        setLoading(true);
        const response = await getPropertyDedupe(loanId);

        if (response?.response_code !== 200 || response?.status === "I") {
          setShowNoData(true);
          setLoading(false);
        } else {
          const data = [];
          response?.propertymatch.forEach((customer, index) => {
            data.push({
              key: index,
              customer_name: customer.matched_loan_account_no,
              customer_type: "",
              rule_name: "",
              match_percentage: "",
              is_lan_row: true,
              is_linked: customer?.is_linked,
              application_number: customer?.matched_application_no || "",
              uuid: v4(),
              input_customer_id: response?.input_customer_id,
              customer_id: customer?.matched_customer_id,
              loan_account_no: customer?.matched_loan_account_no,
              loan_stage: customer?.loan_stage,
            });
            data.push({
              key: index,
              customer_name: customer.matched_customer_full_name,
              customer_type: customer.matched_customer_type,
              rule_name: customer.matched_rule_name,
              match_percentage: customer.matched_percentage,
              is_lan_row: false,
              uuid: v4(),
            });
          });

          setPropertyData(data);
          setLoading(false);
        }
      } catch (error) {
        setShowNoData(true);
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchPropertyDepupeData();
  }, [loanId]);

  const handleAppNoClick = async (appNumber) => {
    try {
      const response = await getLoanIdForHyperlink(appNumber);
      if (response.data.id) {
        const path = `/loandetails/${encrypt(response?.data?.id.toString())}`;
        navigate(path);
      } else {
        navigate("/not-found");
      }
    } catch (error) {
      navigate("/not-found");
    }
  };

  const handleLinkClick = async (match) => {
    try {
      console.log(propertyData);
      const data = {
        linked_type: "Property",
        linked_source_code: match.input_customer_id,
        linked_destination_code: match.customer_id,
      };

      const response = await dedupePropertyLinkService(data);
      if (response && response.status === 200) {
        message.success("Customer linked successfully!");
        const newData = propertyData.map((record) => {
          // Preserve previous `is_linked` value unless a new match is found
          if(record.is_lan_row){

            const wasPreviouslyLinked = record?.is_linked ?? false;
            const isCurrentlyLinked = response?.data?.linked_lan_details.some((m) => 
              m.linked_destination_code === record.customer_id &&
              m.linked_source_code === record.input_customer_id
            );
            return {
              ...record,
              is_linked: wasPreviouslyLinked || isCurrentlyLinked, // Keep previous matches
            };
          }
          return record;
        });
        setIsModalOpen(false);
        setSelectedRecord(null);
        setPropertyData(newData);
      } else {
        setIsModalOpen(false);
        setSelectedRecord(null);
        message.error("Failed to link customer. Please try again.");
      }
    } catch (error) {
      setIsModalOpen(false);
      setSelectedRecord(null);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <div className="property-matching-container">
      {loading ? (
        <div style={{ border: "1px solid #ddd" }}>
          <Loading style={{ width: "100%" }} loading={loading} />
        </div>
      ) : showNoData ? (
        <div className="no_data_found_message">
          <CustomNoData />
        </div>
      ) : (
        <table className="property_custom_table">
          <thead>
            <tr>
              <th className="table-header customer_name_column">
                Customer Name
              </th>
              <th className="table-header customer_type_column">
                Customer Type
              </th>
              <th className="table-header rule_name_column">Rule Name</th>
              <th className="table-header match_percentage_column">
                Match Percentage
              </th>
            </tr>
          </thead>
          <tbody>
            {propertyData &&
              propertyData?.map((item, index) => (
                <tr
                  key={item.key}
                  className={`table-row ${
                    item.is_lan_row === true ? "first-row" : ""
                  } `}
                >
                  {item?.is_lan_row ? (
                    <td colSpan="4">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <h6
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleAppNoClick(item?.application_number)
                            }
                            className="dedupe_loan_number"
                          >
                            {item?.loan_account_no &&
                            item?.loan_account_no !== "None"
                              ? item?.loan_account_no
                              : ""}
                            &nbsp;
                            {item?.application_number &&
                            item.application_number !== "None"
                              ? `(${item?.application_number})`
                              : ""}
                            {item?.loan_stage ? ` (${item.loan_stage})` : ""}
                          </h6>
                        </div>
                        {item?.is_linked ? (
                          <button className="dedup_link_lan_button" disabled>
                            <img src={correctImage} alt="" />
                            <span>Linked</span>
                          </button>
                        ) : (
                          <button
                            className="dedup_link_lan_button"
                            onClick={() => showModal(item)}
                          >
                            <img src={linkImage} alt="" />
                            <span>Link</span>
                          </button>
                        )}
                      </div>
                    </td>
                  ) : (
                    <>
                      <td className="property-table-data">
                        {item.customer_name}
                      </td>
                      <td className="property-table-data">
                        {item.customer_type}
                      </td>
                      <td className="property-table-data">{item.rule_name}</td>
                      <td className="property-table-data">
                        {item.match_percentage}
                      </td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {selectedRecord && (
        <Modal
          title="Link Lan"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Are you sure you want to link lan?</p>
        </Modal>
      )}
    </div>
  );
};

export default PropertyMatching;
