import React from "react";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import { Dropdown, Menu } from "antd";
import { AiOutlineMore } from "react-icons/ai";
import dummy_img from "../assets/dummy.png";
import Editbutton from "../assets/Form.png";
import shear from '../assets/share 1.png';
import deletebutton from '../assets/DeleteOutlined.png'
import pdf_image from "../assets/pdf.png";
import csvimage from "../assets/csv.png";
import { FaFile } from "react-icons/fa";

const DocumentCard = ({ properties, category, handleMenuClick, handleImageError }) => {
    
    return (
        <div className="customer_document_main_container">
            <div className="customer_document_main_content">
                <div className="customer_document_image">
                    {(properties.file_type === "image/jpeg" ||
                        properties.file_type === "image/jpg" ||
                        properties.file_type === "image/png") && (
                            <RViewerJS options={{ url: (image) => image.getAttribute("data-original") }}>
                                <img
                                    className="customer_document_file_image"
                                    src={properties.thumb_document_path || dummy_img}
                                    data-original={properties.document_path || dummy_img}
                                    alt="Document"
                                    height={64}
                                    width={64}
                                    onError={handleImageError}
                                />
                            </RViewerJS>
                        )}
                    {(properties.file_type ===
                        "application/vnd.ms-excel" ||
                        properties.file_type ===
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                        properties.file_type ===
                        "application/octet-stream" ||
                        properties.file_type === "text/csv") && (
                            <img
                                className="customer_document_file_image"
                                src={csvimage}
                                alt="CSV"
                                height={64}
                                width={64}
                                onClick={() =>
                                    window.open(
                                        properties.document_path,
                                        "_blank"
                                    )
                                }
                            />
                        )}
                    {properties.file_type === "application/pdf" && (
                        <img
                            className="customer_document_file_image"
                            src={pdf_image}
                            alt="PDF"
                            height={65}
                            width={65}
                            onClick={() =>
                                window.open(
                                    properties.document_path,
                                    "_blank"
                                )}
                        />
                    )}
                    {properties.file_type === "text/plain" && (
                        <FaFile
                            size={64}
                            color="red"
                            onClick={() =>
                                window.open(
                                    properties.document_path,
                                    "_blank"
                                )
                            }
                            className="customer_document_file_image"

                        />
                    )}
                </div>

                <div className="customer_file_documnet_type_name">
                    <div>{category.document_type?.display_name}</div>
                    <div className="customer_document_file_size">
                        <div className="customer_document_file_number">{properties?.document_number}</div>
                        <div className="dot"></div>
                        {properties?.file_size ? (properties.file_size / (1024 * 1024)).toFixed(2) : 0} MB
                    </div>
                </div>
            </div>
            <div
                className={`customer_document_file_extention ${properties?.file_type === "image/jpeg" ||
                    properties?.file_type === "image/jpg" ||
                    properties?.file_type === "image/png"
                    ? "jpeg"
                    : properties?.file_type === "application/pdf"
                        ? "pdf"
                        : ""
                    }`}
            >
                {properties?.file_type === "image/jpeg"
                    ? "JPEG"
                    : properties?.file_type === "image/jpg"
                        ? "JPG"
                        : properties?.file_type === "image/png"
                            ? "PNG"
                            : properties?.file_type === "application/pdf"
                                ? "PDF"
                                : "-"}
                <Dropdown
                    overlay={
                        <Menu onClick={(e) => handleMenuClick({ ...e, properties })}>
                            <Menu.Item key="edit">
                                <div className="icon_content">
                                    <div>
                                        <img src={Editbutton} alt="Edit" className="action_icons_button" />
                                    </div>
                                    <div className="action_button_name">Edit</div>
                                </div>
                            </Menu.Item>
                            <Menu.Item key="share">
                                <div className="icon_content">
                                    <div>
                                        <img src={shear} alt="Share" className="action_icons_button" />
                                    </div>
                                    <div className="action_button_name">Share</div>
                                </div>
                            </Menu.Item>
                            <Menu.Item key="delete">
                                <div className="icon_content">
                                    <div>
                                        <img src={deletebutton} alt="Delete" className="action_icons_button" />
                                    </div>
                                    <div className="action_button_name">Delete</div>
                                </div>
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={["click"]}
                    placement="bottomLeft"
                >
                    <AiOutlineMore className={properties?.file_type === "image/jpeg" ? "jpeg-more-icon" : "more-icon"} />
                </Dropdown>
            </div>
        </div>
    );
};

export default DocumentCard;
