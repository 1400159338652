import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import { editLoanDetailsByLoanId, getDueDate, getTestProgramList } from '../services/loanInsuranceService';
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const { Option } = Select;
const validationSchema = Yup.object().shape({
    loan_amount_exclude_insurance: Yup.string()
        .required("Loan amount is required")
        .matches(/^[0-9.,]+$/, 'Loan amount can only contain numbers'),
    approved_roi: Yup.string()
        .required("Approved ROI is required")
        .matches(/^[0-9., ]+$/, "Approved ROI can only contain numbers"),
    approved_amount: Yup.string()
        .required("Loan amount is required")
        .matches(/^[0-9., ]+$/, "Loan amount can only contain numbers"),
    approved_tenure: Yup.string()
        .required("Approved tenure is required"),
    emi: Yup.string()
        .required("EMI is required"),
    total_insurance_premium: Yup.string()
        .required("Total insurance is required"),
    scheme_name: Yup.string()
        .required("Scheme is required"),
    product_end_use_name: Yup.string()
        .required("Product is required"),
    interest_type: Yup.string()
        .required("Interest type is required"),
    due_date: Yup.string()
        .required("Due date is required"),
    test_program: Yup.string()
        .required("Test program is required"),
    clss_value: Yup.string()
        .required("CLSS is required"),
    transaction_type: Yup.string()
        .required("Transaction type is required"),
    psl: Yup.string()
        .required("PSL is required"),

});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};
const LoanDetailsForm = ({ refreshData, data, id, open, closeForm }) => {
    const [form] = Form.useForm();
    const [allTestProgram, setAllTestProgram] = useState([]);
    const [allDueDate, setAllDueDate] = useState([]);

    const getLoanData = async () => {
        if (id) {
            try {
                const { loan_amount_include_insurance,
                    loan_amount_exclude_insurance,
                    approved_roi,
                    scheme_name,
                    approved_tenure,
                    emi,
                    interest_type,
                    due_date,
                    total_insurance_premium,
                    clss_value,
                    transaction_type,
                    product_end_use_name,
                    psl,
                    test_program,

                } =
                    data;
                form.setFieldsValue({
                    approved_amount: loan_amount_include_insurance,
                    loan_amount_exclude_insurance,
                    approved_roi,
                    scheme_name: scheme_name?.display_name,
                    approved_tenure,
                    emi,
                    interest_type,
                    due_date,
                    total_insurance_premium,
                    clss_value,
                    transaction_type,
                    product_end_use_name: product_end_use_name?.display_name,
                    psl,
                    test_program: test_program?.id,
                });
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        }
    };
    useEffect(() => {
        const getAllTestPrograms = async () => {
            try {
                const response = await getTestProgramList();
                setAllTestProgram(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        const getAllDueDates = async () => {
            try {
                const response = await getDueDate();
                setAllDueDate(response.data.choices);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };

        getAllTestPrograms();
        getAllDueDates();
    }, [open, form]);
    const handleSubmit = async (values) => {
        const payload = {
            approved_amount: values.approved_amount,
            approved_roi: values.approved_roi,
            approved_tenure: values.approved_tenure,
            emi_due_date: values.due_date,
            emi: values.emi,
            test_program: values.test_program,
            psl: values.psl,
        };

        try {
            const response = await editLoanDetailsByLoanId(id, payload);
            if ((response.status = 200 && response.success)) {
                message.success("Loan details successfully updated");
                refreshData();
                closeForm();
            }

        } catch (error) {
            message.error(error.response.data.message);
        }
    };

    useEffect(() => {
        if (open) {
            getLoanData();
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id]);

    // const handleValuesChange = (_, allValues) => {
    //     const { approved_amount, approved_tenure, approved_roi } = allValues;
    //     const emi = calculateEMI(approved_amount, approved_tenure, approved_roi);
    //     form.setFieldsValue({ emi });
    // };
    
    const handleValuesChange = (_, allValues) => {
        const { approved_amount, approved_tenure, approved_roi, total_insurance_premium } = allValues;

        // Update Loan Amount (Excluding Insurance)
        const loanAmountExcludingInsurance = approved_amount && total_insurance_premium
            ? (parseFloat(approved_amount) - parseFloat(total_insurance_premium)).toFixed(2)
            : "";
        form.setFieldsValue({ loan_amount_exclude_insurance: loanAmountExcludingInsurance });

        // Update EMI dynamically
        const emi = calculateEMI(approved_amount, approved_tenure, approved_roi);
        form.setFieldsValue({ emi });
    };
    const calculateEMI = (loanAmount, tenure, annualROI) => {
        if (!loanAmount || !tenure || !annualROI) return "";
        let r = annualROI / (12 * 100);
        let n = tenure; 
        let EMI = (loanAmount * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
        return EMI ? EMI.toFixed(2) : "";
    };

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            onValuesChange={handleValuesChange}
            layout="vertical"
        >
            <Row gutter={16}>


                <Col span={8}>
                    <Form.Item name="approved_amount" label="Loan Amount (Include Insurance)" rules={[yupSync]} required>
                        <Input placeholder="Please enter loan amount"

                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="loan_amount_exclude_insurance" label="Loan Amount (Exclude Insurance)" rules={[yupSync]} required>
                        <Input placeholder="Please enter loan amount" disabled />
                    </Form.Item>
                </Col>


                <Col span={8}>
                    <Form.Item name="approved_roi" label="Approved ROI" rules={[yupSync]} required>
                        <Input placeholder="Please enter approved ROI"
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="approved_tenure" label="Approved Tenure" rules={[yupSync]} required>
                        <Input placeholder="Please enter approved tenure"
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="emi" label="EMI" rules={[yupSync]} required>
                        <Input placeholder="Please enter EMI"
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="total_insurance_premium" label="Total Insurance" rules={[yupSync]} required>
                        <Input placeholder="Please enter total insurance" disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="scheme_name" label="Scheme" rules={[yupSync]} required>
                        <Input placeholder="Please select" disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="product_end_use_name" label="Product" rules={[yupSync]} required>
                        <Input placeholder="Please select" disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="interest_type" label="Interest Type" rules={[yupSync]} required>
                        <Input placeholder="Please select" disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="due_date" label="Due Date" rules={[yupSync]} required>
                        <Select
                            mode="single"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {allDueDate?.map((duedate) => {
                                return (
                                    <Select.Option key={duedate.value} value={duedate.value}>
                                        {duedate.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="test_program" label="Test Program" rules={[yupSync]} required>
                        <Select
                            mode="single"
                            placeholder="Please select"
                            required={true}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {allTestProgram?.map((testprogram) => {
                                return (
                                    <Select.Option key={testprogram.id} value={testprogram.id}>
                                        {testprogram.display_name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="clss_value" label="CLSS" rules={[yupSync]} required>
                        <Select placeholder="Please Select" disabled>
                            <Option value={true}>Yes</Option>
                            <Option value={false}>No</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="transaction_type" label="Transaction Type" rules={[yupSync]} required>
                        <Input placeholder="Please select" disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="psl" label="PSL" rules={[yupSync]} required>
                        <Select placeholder="Please Select">
                            <Option value={true}>Yes</Option>
                            <Option value={false}>No</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    )
}

export default LoanDetailsForm