import React from "react";
import { Avatar } from "antd";
import dayjs from "dayjs";
import "../styles/notificationCard.css";
import { encrypt } from "../../../utils/cryptoUtils/cryptoUtils";

const NotificationCard = ({ notification }) => {
  const { content, time_stamp, user } = notification.notification;

  return (
    <div className="card_container">
      {/* Left Avatar Div */}
      <div className="left_avatar_div">
        <Avatar
          size={"32px"}
          src={user.thumb_profile_img || undefined}
          style={{
            backgroundColor: !user.thumb_profile_img
              ? "#1890ff"
              : "transparent",
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          {!user.thumb_profile_img && (user?.name?.charAt(0).toUpperCase() || "V")}
        </Avatar>
      </div>
      {/* Right COntent Div */}
      <div className="right_content_div">
        <p className="notification_title">
          {content?.Title.split(/(@\[[^\]]+\]\(\d+\))/g).map((part, index) =>
            part.match(/^@\[(.*?)\]\(\d+\)$/) ? (
              <span key={index} className="is_user_notification_title">
                {part.match(/^@\[(.*?)\]\(\d+\)$/)[1]}
              </span>
            ) : (
              part
            )
          )}
        </p>
        <p className="notification_description">
          {content?.Description.split(/(#\[[^\]]+\]\(\d+\))/g).map(
            (part, index) =>
              part.match(/^#\[(.*?)\]\((\d+)\)$/) ? (
                <a
                  href={`/loandetails/${encrypt(
                    part.match(/^#\[(.*?)\]\((\d+)\)$/)[2].toString()
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="is_loan_detail_notification_description"
                >
                  {part.match(/^#\[(.*?)\]\((\d+)\)$/)[1]}
                </a>
              ) : (
                part
              )
          )}
        </p>
        <p style={{ fontSize: 10, color: "#999", marginTop: "8px" }}>
          {dayjs(time_stamp).format("MMMM D, YYYY h:mm A")}
        </p>
      </div>
    </div>
  );
};

export default NotificationCard;
