import React, { useEffect, useState } from "react";
import { Button, message, Space, Table } from "antd";
import { createBankingIncomeDetails, getBankingDetailsCreditByCustomerId, IncomeCalculationPost } from "../services/incomeDetailsServices";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import IncomeCalculation from "./IncomeCalculation";
import IndianNumberFormat from "../../../../../utils/indianNumberFormat/IndianNumberFormat";
const { Column, ColumnGroup } = Table;

const columns = [
    {
        title: "Month",
        dataIndex: "month",
        key: "month",
        sorter: "true",
        align: "left",
    },
    {
        title: "Inward",
        dataIndex: "inward_bounce_charge_txns",
        key: "inward_bounce_charge_txns",
        align: "left",
    },
    {
        title: "Outward",
        dataIndex: "outward_bounce_charge_txns",
        key: "outward_bounce_charge_txns",
        align: "left",
    },
];


const BankingView = ({ closeForm, open, refreshData, id, selectedScheme, selectedIncome, selectedIncomeConsidered, bank_id }) => {
    const [bankAccountData, setBankAccountData] = useState([]);
    const { encrypted_loan_id } = useParams();
    const [previewData, setPreviewData] = useState(null);

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    const rowClassName = (record) => {
        if (record.month === "Total") {
            return "total-row";
        }
        return "";
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getBankingDetailsCreditByCustomerId(id,loan_id);
                setBankAccountData(response.data.banks || []);
                // const transformedData = response.data.banks.flatMap((customer) => {
                //     const balanceMap = {};

                //     customer.daily_txn.forEach((balance) => {
                //         const month = new Date(balance.bank_balance_date).toLocaleString(
                //             "default",
                //             { month: "long", year: "numeric" }
                //         );

                //         // Initialize the month entry if it doesn't exist
                //         if (!balanceMap[month]) {
                //             balanceMap[month] = {
                //                 month,
                //                 balance_5: null,
                //                 balance_15: null,
                //                 balance_25: null,
                //             };
                //         }

                //         // Assign balance amount based on the day extracted from bank_balance_date
                //         const day = new Date(balance.bank_balance_date).getDate();
                //         if (day === 5) {
                //             balanceMap[month].balance_5 = balance.balance_amount;
                //         } else if (day === 15) {
                //             balanceMap[month].balance_15 = balance.balance_amount;
                //         } else if (day === 25) {
                //             balanceMap[month].balance_25 = balance.balance_amount;
                //         }
                //     });

                //     // Convert the balanceMap object back to an array
                //     return Object.values(balanceMap);
                // });
                // setBankAccountBankBalanceData(transformedData)
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleSubmit = async () => {
        try {
            const payload = {
                customer_detail: id,
                loan_detail: loan_id,
                scheme_detail: selectedScheme,
                // income_considered: true
                income_considered: (selectedIncome === null && selectedIncomeConsidered === null
                    ? true
                    : (selectedIncome !== null ? selectedIncome : selectedIncomeConsidered))
            };

            const response = await createBankingIncomeDetails(payload);
            if ((response.status = 200 && response.success)) {
                message.success("Banking scheme created successfully");
                // form.resetFields();
                closeForm();
                refreshData();
            }
        }
        catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };

    const handlePreview = async () => {

        const payload = {
            customer_detail: id,
            loan_detail: loan_id,
            scheme_detail: selectedScheme,
        };

        const response = await IncomeCalculationPost(payload);
        if (response.status === 200 && response.success) {
            console.log("lll", response.data)
            setPreviewData(response.data);
            // message.success("Preview data sent successfully");
        }

    };

    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                {bankAccountData.map((bank) => {
                    // Transform daily_txn data inside the map function
                    const balanceMap = {};

                    bank.daily_txn.forEach((balance) => {
                        const month = new Date(balance.bank_balance_date).toLocaleString(
                            "default",
                            { month: "long", year: "numeric" }
                        );

                        if (!balanceMap[month]) {
                            balanceMap[month] = {
                                month,
                                balance_5: null,
                                balance_15: null,
                                balance_25: null,
                            };
                        }

                        const day = new Date(balance.bank_balance_date).getDate();
                        if (day === 5) balanceMap[month].balance_5 = balance.balance_amount;
                        else if (day === 15) balanceMap[month].balance_15 = balance.balance_amount;
                        else if (day === 25) balanceMap[month].balance_25 = balance.balance_amount;
                    });

                    const transformedData = Object.values(balanceMap);

                    return (
                        <div key={bank.bank_id}>
                            <div className="banking_bank_name">{bank.bank_name}</div>

                            <div className="main_credit">
                                <p className="credit_heading">Credit Transactions</p>
                                <div className="table-container">
                                    <Table dataSource={bank.monthly_txn} pagination={false} rowClassName={rowClassName}>
                                        <Column title="Month" sorter dataIndex="month" key="month" align="left" />
                                        <ColumnGroup title="Credit" align="center">
                                            <Column title="Count" dataIndex="total_credit_txns" key="total_credit_txns" align="center" />
                                            <Column title="Amount" dataIndex="credit_amount" key="credit_amount" align="center" />
                                        </ColumnGroup>
                                        <ColumnGroup title="Debit" align="center">
                                            <Column title="Count" dataIndex="total_debit_txns" key="total_debit_txns" align="center" />
                                            <Column title="Amount" dataIndex="debit_amount" key="debit_amount" align="center" />
                                        </ColumnGroup>
                                        <Column title="Closing Balance" sorter dataIndex="closing_balance" key="closing_balance" align="center" />
                                    </Table>
                                </div>
                            </div>

                            <div className="main_debit">
                                <p>No. of Cheques Bounced</p>
                                <div className="debit_box" style={{ gap: "20px" }}>
                                    <div className="" style={{ height: "auto" }}>
                                        <div className="cheque_div">No. of Cheques Bounced</div>
                                        <div className="table-container">
                                            <Table columns={columns} dataSource={bank.bounce_txn} pagination={false} rowClassName={rowClassName} />
                                        </div>
                                    </div>

                                    <div className="table-responsive balance_div">
                                        <Table
                                            dataSource={transformedData}
                                            columns={[
                                                { title: "Month & Year", dataIndex: "month", key: "month", render: (text) => <div>{text}</div> },
                                                { title: "Day 5", dataIndex: "balance_5", key: "balance_5",render: (text) => (text ?  `₹ ${IndianNumberFormat(text)}` : '') },
                                                { title: "Day 15", dataIndex: "balance_15", key: "balance_15",render: (text) => (text ?  `₹ ${IndianNumberFormat(text)}` : '') },
                                                { title: "Day 25", dataIndex: "balance_25", key: "balance_25",render: (text) => (text ?  `₹ ${IndianNumberFormat(text)}` : '') },
                                            ]}
                                            rowKey="month"
                                            pagination={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div>
                <Space direction="horizontal" align="center" style={{ display: "flex", flexDirection: "row-reverse", marginBottom: "20px" }}>
                    <Button type="primary" onClick={handlePreview}>Preview</Button>
                    <Button onClick={closeForm}>Cancel</Button>
                </Space>

                {previewData && <IncomeCalculation previewData={previewData} schemeDisplayName={selectedScheme} refreshTableData={refreshData} />}

                <Space direction="horizontal" align="center" style={{ display: "flex", flexDirection: "row-reverse", marginBottom: "20px" }}>
                    <Button type="primary" onClick={handleSubmit}>Submit</Button>
                </Space>
            </div>
        </>
    )
}

export default BankingView