import React, { useEffect, useState } from "react";
import "./style/LoanSummaryStyle.css";
import RViewerJS from "viewerjs-react";
import pdf from "./asset/pdf.png";
import xls from "./asset/xls.png";
import { DoubleLeftOutlined } from "@ant-design/icons";
import Loading from "../loading/Loading";
import CustomNoData from "../noDataIcon/CustomNoData";

const LoanSummaryBlock = ({ data, attachments = [], typeCheck }) => {
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the time as needed (1000 ms = 1 second)

    return () => clearTimeout(timer);
  }, []);

  const modifiedData = {
    ...data,
    credit_assessment_data:
      data?.credit_assessment_detail || data?.credit_assessment_data,
  };

  const displayedAttachments = showAll ? attachments : attachments.slice(0, 2);

  // const applicant = modifiedData?.customer_detail?.find(
  //   (customer) =>
  //     customer.customer_type.name === "Applicant" ||
  //     customer.customer_type.name === "Company Applicant"
  // );

  const applicant = modifiedData?.customer_detail?.find((customer) => {
    return customer.customer_type.name === "Applicant" || customer.customer_type.name === "Company Applicant";
  });


  const coApplicants = modifiedData?.customer_detail?.filter(
    (customer) =>
      customer.customer_type.name === "Co-Applicant" ||
      customer.customer_type.name === "Company Co-Applicant"
  );

  const GuarantorDetail = modifiedData?.customer_detail?.find(
    (customer) =>
      customer.customer_type.name === "Guarantor" ||
      customer.customer_type.name === "Company Guarantor"
  );

  const getRcuDetail = (customer_type) => {
    return modifiedData?.rcu_detail?.customer_wise_rcu_address_and_doc?.filter(
      (item) => item.customer_type === customer_type
    );
  };
  const rcuDetails = getRcuDetail("Applicant");
  const rcuApplicant =
    rcuDetails && rcuDetails.length > 0 ? rcuDetails[0] : null;

  const getCoapplicantRcuDetail = (customer_type) => {
    return modifiedData?.rcu_detail?.customer_wise_rcu_address_and_doc?.filter(
      (item) => item.customer_type === customer_type
    );
  };

  //  

  const rcucopplicantDetails = getCoapplicantRcuDetail("Co-Applicant");

  const rcuGuarantorDetails = getCoapplicantRcuDetail("Guarantor");

  const rcuCoApplicant =
    rcucopplicantDetails && rcucopplicantDetails.length > 0
      ? rcucopplicantDetails
      : null;

      
      

  return (
    <>
      <div>
        <div className="fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : data ? (
            <>
              <div className="Loan_summary_first_container">
                <div className="loan_summary_heading_block" >
                  <div className="Loan_summary_content" >Loan Number</div>
                  <div className="loan_summary_second_container_heading_two">
                    {modifiedData?.loan_detail?.loan_account_number || "-"}
                  </div>
                </div>
                <div>
                  <div className="Loan_summary_content">Application Number</div>
                  <div className="loan_summary_second_container_heading_two">
                    {modifiedData?.loan_detail?.application_number || "-"}
                  </div>
                </div>
                <div>
                  <div className="Loan_summary_content">Sales Manager</div>
                  <div className="loan_summary_second_container_heading_two">
                    {modifiedData?.loan_detail?.sales_manager?.name || "-"}
                  </div>
                </div>
                <div>
                  <div className="Loan_summary_content">Branch</div>
                  <div className="loan_summary_second_container_heading_two">
                    {modifiedData?.loan_detail?.location?.display_name || "-"}
                  </div>
                </div>
              </div>

              <div className="loan_summray_container">
                <div className="loan_summary_table_one">
                  <div className="loan_summary_one_heading">
                    <div className="Details_text">Details</div>
                  </div>
                  <div className="loan_summary_table_data">
                    <div className="loan_summary_table_data_text">
                      <div className="loan_summary_table_data_heading">
                        Agency Name:
                      </div>
                      <div className="loan_summary_table_data_value">
                        {modifiedData?.credit_assessment_data?.agency?.name ||
                          "-"}
                      </div>
                    </div>
                  </div>
                  <div className="loan_summary_table_data">
                    <div className="loan_summary_table_data_text">
                      <div className="loan_summary_table_data_heading">
                        Agent Name:
                      </div>
                      <div className="loan_summary_table_data_value">
                        {modifiedData?.credit_assessment_data?.agent?.name ||
                          "-"}
                      </div>
                    </div>
                  </div>
               
                  <div className="loan_summary_table_data">
                    {applicant ? (
                      <>
                        <div className="loan_summary_table_data">
                          <div className="loan_summary_table_data_text">
                            <div className="loan_summary_table_data_heading">
                              Applicant / Customer:
                            </div>
                            <div className="loan_summary_table_data_value">
                              {applicant?.customer_name || applicant?.full_name || "-"}
                            </div>
                          </div>
                        </div>
                        <div className="loan_summary_table_data">
                          <div className="loan_summary_table_data_text">
                            <div className="loan_summary_table_data_heading">
                              Customer Mobile No. :
                            </div>
                            <div className="loan_summary_table_data_value">
                              {applicant.customer_mobile_number
                                ? `${applicant.customer_mobile_number}`
                                : `${applicant.mobile}`}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : // Fallback to RCU details if no applicant found

                    
                      rcuApplicant ? (
                        <>
                          <div className="loan_summary_table_data_text">
                            <div className="loan_summary_table_data_heading">
                              Applicant / Customer:
                            </div>
                            <div className="loan_summary_table_data_value">
                              {rcuApplicant.customer_name || "-"}
                            </div>
                          </div>

                          <div className="coapplicant_customer_loan_summary">
                            <div className="loan_summary_table_data_heading">
                              Customer Mobile No. :
                            </div>
                            <div className="loan_summary_table_data_value">
                              {rcuApplicant.mobile_number || "-"}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>-</div>
                      )}

                    {coApplicants ? (
                      <>
                        {coApplicants?.map((coApplicant, index) => (
                          <div
                            key={coApplicant.id}
                            className="loan_summary_table_data_two"
                          >
                            <div className="loan_summary_table_data_heading">{`Co-Applicant ${index + 1
                              }:`}</div>
                            <div className="loan_summary_table_data_value">
                              {coApplicant.customer_name || coApplicant.full_name || "-"}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : rcuCoApplicant ? (
                      <div  className="rcu_coapplicant_block">
                        <>
                        { rcucopplicantDetails.map((rcucopplicantDetail, index) => (
                          <div className="rcu_guarantor_loan_summary">
                            <div className="loan_summary_table_data_heading">
                              Co-Applicant {index + 1}:
                            </div> 
                            <div className="loan_summary_table_data_value">
                              {rcucopplicantDetail.customer_name || "-"}
                            </div>
                          </div>
                          ))}
                           
                        </>
                      </div>
                    ) : (
                      <div>-</div>
                    )}
                  </div>

                  
                  {GuarantorDetail ? (
                    <div className="loan_summary_table_data">
                      <div className="loan_summary_table_data_text">
                        <div className="loan_summary_table_data_heading">
                          Personal Guarantor:
                        </div>
                        <div className="loan_summary_table_data_value">
                          {GuarantorDetail.customer_name || GuarantorDetail.full_name || ""}
                        </div>
                      </div>
                    </div>
                  )  : rcuGuarantorDetails ? (
                    <>
                      {rcuGuarantorDetails.map((rcuGuarantorDetail, index) => (
                        <div key={index} className="rcu_guarantor_loan_summary">
                          <div className="loan_summary_table_data_heading">
                          Guarantor {index + 1}:
                          </div>
                         
                          <div className="loan_summary_table_data_value">
                            {rcuGuarantorDetail.customer_name || "-"}
                          </div>
                        </div>
                      ))} 
                      </>
                  ) : 
                  (
                  <div>-</div>
                  
                )
                }
                </div>

                <div className="Laon_summary_table_two">
                  <div className="loan_summary_one_heading">
                    <div className="Details_text">Initiated Data</div>
                  </div>
                  <div className="loan_summary_table_data">
                    <div className="loan_summary_table_data_text">
                      <div className="loan_summary_table_data_heading">
                        Vendor Email:
                      </div>
                      <div className="loan_summary_table_mail_data_value">
                        {modifiedData?.credit_assessment_data?.mail_to || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="loan_summary_table_data">
                    <div className="loan_summary_table_data_text">
                      <div className="loan_summary_table_data_heading">
                        Credit Manager Name:
                      </div>
                      <div className="loan_summary_table_data_value">
                        {modifiedData?.loan_detail?.credit_manager?.name || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="loan_summary_table_data">
                    <div className="loan_summary_table_data_text">
                      <div className="loan_summary_table_data_heading">
                        Credit Manager Mobile No:
                      </div>
                      <div className="loan_summary_table_data_value">
                        {modifiedData?.loan_detail?.credit_manager?.mobile ||
                          "-"}
                      </div>
                    </div>
                  </div>
                  <div className="loan_summary_table_data">
                    <div className="loan_summary_table_data_text">
                      <div className="loan_summary_table_data_heading">
                        Initiated On:
                      </div>
                      <div className="loan_summary_table_data_value">
                        {modifiedData?.credit_assessment_data?.initiated_at ||
                          "-"}
                      </div>
                    </div>
                  </div>
                  <div className="legal_table_data">
                    <div className="loan_summary_table_data_text">
                      <div className="loan_summary_table_data_heading">
                        Provided Documents:
                      </div>
                      <div className="legal_table_data_value">
                        {/* {`Dear Team, Kindly Initiate ${typeCheck}`} */}
                        {modifiedData?.credit_assessment_data?.remark ||
                          "-"}
                      </div>
                    </div>
                  </div>
                  <div className="loan_summary_img_table_data">
                    <div className="loan_summary_table_data_text">
                      <div className="loan_summary_img_data_heading">
                        Attachments:
                      </div>
                      <div className="loan_summary_img_data_value">
                        {attachments && attachments.length > 0 ? (
                          displayedAttachments.map((item, index) => {
                            if (item.file_type === "application/pdf") {
                              return (
                                <img
                                  src={pdf}
                                  height={50}
                                  width={50}
                                  alt="PDF Preview"
                                  key={index}
                                  className="attachment-img loan_summary_pdf_img"
                                  onClick={() =>
                                    window.open(item.attachment, "_blank")
                                  }
                                />
                              );
                            } else if (
                              item.file_type === "application/vnd.ms-excel" ||
                              item.file_type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                              item.file_type === "text/csv"
                            ) {
                              return (
                                <img
                                  src={xls}
                                  height={50}
                                  width={50}
                                  alt="Excel Placeholder"
                                  key={index}
                                  className="attachment-img loan_summary_pdf_img"
                                  onClick={() => {
                                    const excelUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(item.attachment)}`;
                                    window.open(excelUrl, "_blank");
                                  }}
                                />
                              );
                            }  else if (item.file_type === "video/webm" || item.file_type === "video/mp4") {
                              return (
                                <video
                                  key={index}
                                  height={50}
                                  width={50}
                                  controls
                                  className="attachment-img loan_summary_pdf_img"
                                >
                                  <source
                                    src={item.attachment}
                                    type="video/webm"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              );
                            } else if (item.attachment) {
                              return (
                                <RViewerJS key={index}>
                                  <img
                                    src={item.attachment}
                                    alt="img"
                                    height={50}
                                    width={50}
                                    className="img_loan_summary_block"
                                  />
                                </RViewerJS>
                              );
                            } else {
                              return (
                                <div
                                  key={index}
                                  className="loan_summary_no_image"
                                >
                                  No Image
                                </div>
                              );
                            }
                          })
                        ) : (
                          <div> - </div>
                        )}

                        {attachments.length > 2 && (
                          <span
                            className="loan_summary_attachment_img"
                            onClick={() => setShowAll(!showAll)}
                          >
                            {showAll ? (
                              <DoubleLeftOutlined className="doubleLeftOutlined_icon" />
                            ) : (
                              "..."
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="no_data_found_message">
              <CustomNoData />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoanSummaryBlock;
