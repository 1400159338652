import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  Form,
  Input,
  Row,
  Upload,
  message,
  Select,
} from "antd";

import pdf_image from "../assets/pdf.png";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import RViewerJS from "viewerjs-react";
import {
  deleteSalesPropertyDetailsDocumentById,
  getLandTypeService,
  getPropertyDetailsById,
  getSalesPropertyDetailsById,
  getSalesPropertyDetailsDocumentById,
  listAreaCategory,
  listCity,
  listConstructionStage,
  listCountry,
  listDistrict,
  listOwnership,
  listPincode,
  listPropertyType,
  listState,
  pincodeCodeService,
  postSalesProperty,
  updatePropertyById,
} from "../../loanApplication/loanTabDetails/propertyDetails/services/propertyDetailsService";
import { listDocumentType } from "../services/salesformservices";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  area_category: Yup.string()
    .required("Area category is required")
    .label("Area Category"),
  ownership: Yup.string()
    .required("Loan ownership is required")
    .label("Ownership"),
  land_type: Yup.string().required("Land type is required").label("Land Type"),
  number_of_floors: Yup.string()
    .required("Number of floors is required")
    .test(
      "is-number",
      " Number of floors must contain only digits",
      (value) => {
        return /^[0-9]+$/.test(value);
      }
    )
    // .test(
    // "min-max-range",
    // "Number of floors must be between 0 to 99",
    // (value) => {
    // const numericValue = parseInt(value, 10);
    // return numericValue >= 0 && numericValue <= 99;
    // }
    // )
    .matches(/^[0-9]+$/, "Number of floors must contain only digits")
    .label("Number Of Floors"),
  construction_stage: Yup.string()
    .required("Construction stage is required")
    .label("Construction Stage"),
  document_type: Yup.string()
    .required("Document Type is required")
    .label("Document Type"),
  document_number: Yup.string()
    .required("Document Number is required")
    .label("Document Number"),

  property_age: Yup.string()
    .required("Property age is required")
    // .test("min-max-range", "Property age must be between 0 to 99", (value) => {
    // const numericValue = parseInt(value, 10);
    // return numericValue >= 0 && numericValue <= 99;
    // })

    .test(
      "max-decimals",
      "Property age must have at most two decimal places",
      (value) => {
        if (value.includes(".")) {
          const [, decimals] = value.split(".");
          return decimals.length <= 2;
        }
        return true;
      }
    )
    .test("decimal-range", "Decimal part must be between 0 and 11", (value) => {
      if (value.includes(".")) {
        const [, decimals] = value.split(".");
        return parseInt(decimals, 10) >= 0 && parseInt(decimals, 10) <= 11;
      }
      return true;
    })
    .test("is-number", "Property age must contain only digits", (value) =>
      /^[0-9]+(\.[0-9]{0,2})?$/.test(value)
    )
    // .matches(/^[0-9]+(\.[0-9]{1,2})?$/, "Property age must contain only digits")
    .label("Property Age"),

  address_line_1: Yup.string()
    .required("Address line 1 is required")
    .min(2, "Address line 1 must be at least 2 characters long")
    .max(225, "Address line 1 must be at most 225 characters long")
    .label("Address Line 1"),
  address_line_2: Yup.string()
    .required("Address line 2 is required")
    .min(2, "Address line 2 must be at least 2 characters long")
    .max(225, "Address line 2 must be at most 225 characters long")
    .label("Address line 2"),
  landmark: Yup.string()
    .required("Landmark is required")
    .min(2, "Landmark must be at least 2 characters long")
    .max(225, "Landmark must be at most 225 characters long")
    .label("Landmark"),
  developer_name: Yup.string()
    .required("Developer name is required")
    .matches(
      /^[A-Za-z\s]+$/,
      "Developer name can only contain alphabetical values & whitespaces"
    ),
  project_name: Yup.string()
    .required("Project name is required")
    .matches(
      /^[A-Za-z0-9\s]+$/,
      "Project name must contain only alphanumeric characters and spaces"
    ),
  property_type: Yup.string().required("Property type is required"),
  // document_number: Yup.string()
  // .required("Document number is required"),
  // document_type: Yup.string()
  // .required("Document types is required"),
  city: Yup.string().required("City is required").label("City"),
  taluka: Yup.string().required("Taluka is required").label("Taluka"),
  district: Yup.string()
    .required("District is required")
    .label("District"),
  pincode: Yup.string().required("Pincode is required").label("Pincode"),
  state: Yup.string().required("State is required").label("State"),
  country: Yup.string().required("Country a is required").label("Country"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};
 

const SalesPropertyDetailForm = ({
  id,
  updateId,
  open,
  closeForm,
  refreshData,
  onNext,
  loan_detail,
}) => {
  const [form] = Form.useForm();
  const [landTypes, setLandTypes] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [constructionStage, setConstructionStage] = useState([]);
  const [ownership, setOwnership] = useState([]);
  const [areaCategory, setAreaCategory] = useState([]);
  const [city, setCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState();
  const [propertyType, setPropertyType] = useState();
  const [image, setImage] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [, setDeletePicture] = useState(null);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [, setOpenUploadModal] = useState(false);
  const [, setLoading] = useState(false);
  const [propertyid, setPropertyid] = useState();
  const [propertyDocs, setPropertydocs] = useState([]);
  const [openSalesModal, setOpenSalesModal] = useState(false);
  const [salesDocId, setSalesDocId] = useState();
  const [refreshDatas, setRefreshDatas] = useState(false);
  const [documentdisable, setDocumentdisable] = useState(true);
  // const [editsales, setEditsales] = useState()
  const latitude = useSelector((state) => state?.user?.user_location?.latitude);
  const longitude = useSelector(
    (state) => state?.user?.user_location?.longitude
  ); 

  const getSalesPropertyDetailsData = async () => {
    // Mark the function as async
    if (loan_detail) {
      try {
        const response = await getSalesPropertyDetailsById(loan_detail);
        // Check if response and response.data are valid
        if (response && response.data) {
          const {
            area_category,
            ownership,
            land_type,
            number_of_floors,
            construction_stage,
            property_age,
            address_line_1,
            address_line_2,
            landmark,
            city,
            taluka,
            district,
            pincode,
            state,
            country,
            property_type,
            project_name,
            developer_name,
          } = response.data;

          setPropertyid(response.data.id);

          // Set form fields with the retrieved data
          form.setFieldsValue({
            area_category,
            ownership,
            land_type,
            number_of_floors,
            construction_stage,
            property_age,
            address_line_1,
            address_line_2,
            landmark,
            project_name,
            developer_name,
            city: city?.id, // Use optional chaining to safely access the ID
            taluka: taluka,
            district: district?.id,
            pincode: pincode?.id,
            state: state?.id,
            country: country?.id,
            property_type: property_type?.id,
          });
        }
      } catch (error) {
        // Improved error handling with optional chaining and fallback message
        const errorMessage =
          error?.response?.data?.message ||
          "An error occurred while fetching property details";
        message.error(`Error: ${errorMessage}`);
      }
    }
  };

  useEffect(() => {
    if (loan_detail) {
      getSalesPropertyDetailsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_detail]);

  const getSalesPropertyDocumentsData = async () => {
    if (propertyid) {
      try {
        const response = await getSalesPropertyDetailsDocumentById(propertyid);
        setPropertydocs(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  }; // Add propertyid as a dependency to the callback

  useEffect(() => {
    getSalesPropertyDocumentsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyid, refreshDatas]); // Ensure to use the callback as a dependency

  const handleUploadChange = async (info) => {
    let updatedFileList = [...info.fileList];

    // Set the button to disabled initially
    setDocumentdisable(true);

    // Process the last uploaded file to convert it to base64
    if (info.fileList.length > 0) {
      const lastFile = info.fileList[info.fileList.length - 1];

      if (lastFile.originFileObj) {
        const base64 = await fileToBase64(lastFile.originFileObj);

        // Update the last file with additional fields
        updatedFileList = updatedFileList.map((file, index) => {
          if (index === updatedFileList.length - 1) {
            return {
              ...file,
              document_type: form.getFieldValue("document_type"),
              document_number: form.getFieldValue("document_number"),
              document_path: base64, // Store base64 URL here
            };
          }
          return file;
        });
      }
    }

    // Update the file list state
    setFileList(updatedFileList);

    // Reset form fields to allow new document inputs
    form.setFieldsValue({
      document_type: undefined,
      document_number: undefined,
      document_path: undefined,
    });

    // Re-enable the button for the next upload
    setDocumentdisable(true);
  };

  const confirmDelete = () => {
    setImage(image.filter((image) => image !== deleteUploadPicture));
    const updatedFileList = fileList.filter(
      (item) => item.uid !== deleteUploadPicture.uid
    );
    setFileList(updatedFileList);
    setOpenUploadModal(false); // Close the modal
    setDeleteUploadPicture(null); // Reset the selected picture
  };

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";

    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG, PNG, or PDF files!");
      return Upload.LIST_IGNORE; // Prevent invalid file from being added to the list
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    // Avoid duplicate file entries in the list
    setFileList((prevFileList) => {
      const alreadyExists = prevFileList.some(
        (existingFile) => existingFile.uid === file.uid
      );
      if (!alreadyExists) {
        return [...prevFileList, file];
      }
      return prevFileList;
    });

    // Update image list with new preview for image files, or use a placeholder for PDFs
    const newImage = {
      file_path: isJpgOrPng ? URL.createObjectURL(file) : pdf_image, // Use a placeholder image for PDFs
      uid: file.uid,
    };

    setImage((prevImages) => {
      const alreadyExists = prevImages.some(
        (existingImage) => existingImage.uid === file.uid
      );
      if (!alreadyExists) {
        return [...prevImages, newImage];
      }
      return prevImages;
    });

    return false; // Prevent automatic upload
  };

  const deletePropertyPictures = async () => {
    try {
      const response = await deleteSalesPropertyDetailsDocumentById(salesDocId); // Use salesDocId

      if (response?.status === 200 && response?.success === true) {
        message?.success("Property Picture deleted successfully");
        const filteredData = propertyDocs?.filter(
          (item) => item.id !== salesDocId
        );
        setPropertydocs(filteredData);
        //setOpenSalesModal(false); // Close the modal
        // refreshData(); // Refresh the data after deletion
        // setOpenModal(false);
        // setSalesDocId();
       // setOpenSalesModal(false);
       // setRefreshDatas(!refreshDatas);
        return;
      }
    } catch (error) {
      message?.error(error?.response?.data?.message);
    }
  };
  const getPropertyDetailsData = async () => {
    // Mark the function as async
    if (id) {
      try {
        const response = await getPropertyDetailsById(id); // Await the API call

        // Destructure the necessary fields from response.data safely
        const {
          area_category,
          ownership,
          land_type,
          number_of_floors,
          construction_stage,
          property_age,
          address_line_1,
          address_line_2,
          landmark,
          city,
          taluka,
          district,
          pincode,
          state,
          country,
          property_type,
          project_name,
          developer_name,
        } = response?.data || {}; // Fallback to empty object if response.data is undefined

        // Set form fields with the retrieved data
        form.setFieldsValue({
          area_category,
          ownership,
          land_type,
          number_of_floors,
          construction_stage,
          property_age,
          address_line_1,
          address_line_2,
          landmark,
          project_name,
          developer_name,
          city: city?.id, // Use optional chaining to safely access the ID
          taluka: taluka,
          district: district?.id,
          pincode: pincode?.id,
          state: state?.id,
          country: country?.id,
          property_type: property_type?.id,
        });
      } catch (error) {
        // Improved error handling with fallback message
        const errorMessage =
          error?.response?.data?.message ||
          "An error occurred while fetching property details";
        message.error(`Error: ${errorMessage}`);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getPropertyDetailsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (fileList.length === 0) {
        message.error("No document uploaded.");
        return;
      }
      values.number_of_floors = values.number_of_floors.toString();
      values.property_age = values.property_age.toString();

      if (loan_detail && typeof propertyid === "undefined") {
        // Case when loan_detail is present
        const filteredFileList = fileList?.map((item) => ({
          document_type: item.document_type,
          document_number: item.document_number,
          document_path: item.document_path,
        }));

        let payload = {
          ...values,
          loan_detail: Number(loan_detail),
          // is_active: false,
          latitude: latitude,
          longitude: longitude,
          property_document_detail: filteredFileList,
        };

        const response = await postSalesProperty(payload);
        if (response.status === 201 && response.success) {
          message.success("Property Details successfully Created");
          setPropertyid(response.data.id);
          setFileList([]);
          setImage([]);
          setDocumentdisable(true);
          // onNext() // Uncomment if you need to navigate to the next step
        }
      } else if (updateId || propertyid) {
        // Case when either updateId or editsales is present
        const propertyDocumentDetail = await Promise.all(
          fileList.map(async (file) => ({
            document_path: await fileToBase64(file.originFileObj),
            document_number: file.document_number,
            document_type: file.document_type,
          }))
        );

        const { document_type, document_path, document_number, ...restValues } =
          values;
        const payload = {
          ...restValues,
          property_document_detail: propertyDocumentDetail,
        };

        const response = await updatePropertyById(
          updateId || propertyid,
          payload
        );
        if (response.status === 200 && response.success) {
          message.success("Property Details successfully updated");
          // setFileList([]);
        setRefreshDatas(!refreshDatas);
        setDocumentdisable(true);
          setImage([]);
          setFileList([]);
          if (updateId) {
            refreshData(); // Refresh data if editsales is provided
            closeForm(); // Close form if editsales is provided
          }
        }
      }
    } catch (error) {
      // Handle error
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message) ||
          "An unexpected error occurred."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getPropertyDetailsData();
    } else {
      form.resetFields();
      setFileList([]);
      setImage([]);
      // setUploadDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  useEffect(() => {
    const getAllLandType = async () => {
      try {
        const response = await getLandTypeService();
        setLandTypes(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllConstructionStage = async () => {
      try {
        const response = await listConstructionStage();
        setConstructionStage(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllOwnership = async () => {
      try {
        const response = await listOwnership();
        setOwnership(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllAreaCategory = async () => {
      try {
        const response = await listAreaCategory();
        setAreaCategory(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCity = async () => {
      try {
        const response = await listCity();
        setCity(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    
    const getAllDistrict = async () => {
      try {
        const response = await listDistrict();
        setDistrict(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllPincode = async () => {
      try {
        const response = await listPincode();
        setPincode(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllState = async () => {
      try {
        const response = await listState();
        setStates(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllCountry = async () => {
      try {
        const response = await listCountry();
        setCountry(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllPropertyType = async () => {
      try {
        const response = await listPropertyType();
        setPropertyType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllDocumentType = async () => {
      try {
        const response = await listDocumentType();
        setDocumentType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllLandType();
    getAllConstructionStage();
    getAllOwnership();
    getAllAreaCategory();
    getAllCity();
    getAllDistrict();
    getAllPincode();
    getAllState();
    getAllCountry();
    getAllPropertyType();
    getAllDocumentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleNumberFields = (e, field) => {
    const newValue = e.target.value.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleInputFields = (e, fieldName) => {};

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handlePincodeChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.id === value);
    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, district, state, country } = response.data;
        form.setFieldsValue({
          city: city?.id || undefined,
          district: district?.id || undefined,
          state: state?.id || undefined,
          country: country?.id || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    const { document_number, document_type } = allValues || {};
    if (document_number?.trim() && document_type) {
      setDocumentdisable(false);
    } else {
      setDocumentdisable(true);
    }
  };
  

  return (
    <Form
      form={form}
      onValuesChange={handleValuesChange}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        number_of_floors: "",
        property_age: "",
        address_line_1: "",
        address_line_2: "",
        landmark: "",
        project_name: "",
        developer_name: "",
      }}
    >
      <Row gutter={16} className="row_form_property">
        <Col span={8}>
          <Form.Item
            name="area_category"
            label="Area Category"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {areaCategory.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="ownership"
            label="Ownership"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {ownership.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="number_of_floors"
            label="Number Of Floors"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Number Of Floors"
              onChange={(e) => handleNumberFields(e, "number_of_floors")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="row_form_property">
        <Col span={8}>
          <Form.Item
            name="construction_stage"
            label="Construction Stage"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {constructionStage.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="property_age"
            label="Property Age (In Years)"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Property Age"
              onChange={(e) => handleNumberFields(e, "property_age")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="land_type"
            label="Land Type"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {landTypes?.map((land_type) => (
                <Select.Option key={land_type} value={land_type}>
                  {land_type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Property Type"
            name="property_type"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {propertyType?.map((propertytype) => (
                <Select.Option key={propertytype?.id} value={propertytype?.id}>
                  {propertytype?.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="project_name"
            label="Name Of Project"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Project Name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="developer_name"
            label="Developer Name"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Developer Name" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="address_line_1"
            label="Address Line 1"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 1"
              onChange={(e) => handleInputFields(e, "address_line_1")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="address_line_2"
            label="Address Line 2"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 2"
              onChange={(e) => handleInputFields(e, "address_line_2")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="landmark"
            label="Landmark"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Landmark"
              onChange={(e) => handleInputFields(e, "landmark")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="pincode" label="Pincode" rules={[yupSync]} required>
            <Select
              showSearch
              placeholder="Please Select"
              onChange={handlePincodeChange}
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {pincode?.map((pincodes, index) => (
                <Option key={pincodes.id} value={pincodes.id}>
                  {pincodes.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="city" label="City" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              // onSearch={onSearch}
              filterOption={filterOption}
              disabled
            >
              {city?.map((locations, index) => (
                <Option key={locations.id} value={locations.id}>
                  {locations.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
        <Form.Item name="taluka" label="Taluka" rules={[yupSync]} required>
            <Input placeholder="Please enter taluka" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="district"
            label="District"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              // onSearch={onSearch}
              filterOption={filterOption}
              disabled
            >
              {district?.map((districts, index) => (
                <Option key={districts.id} value={districts.id}>
                  {districts.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="state" label="State" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              // onSearch={onSearch}
              filterOption={filterOption}
              disabled
            >
              {states?.map((states, index) => (
                <Option key={states.id} value={states.id}>
                  {states.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="country" label="Country" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              // onSearch={onSearch}
              filterOption={filterOption}
              disabled
            >
              {country?.map((countries, index) => (
                <Option key={countries.id} value={countries.id}>
                  {countries.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        {propertyDocs.length > 0 && (
          <div className="image-gallery">
            {propertyDocs?.map((picture, index) => (
              <div key={index} className="image-container">
                {picture.file_type === "application/pdf" ? (
                  <a
                    target="_blank"
                    href={picture.document_path}
                    rel="noreferrer"
                  >
                    <img
                      src={pdf_image}
                      width="100px"
                      height="100px"
                      alt="PDF Preview"
                      className="property_tab_img"
                    />
                  </a>
                ) : (
                  <RViewerJS key={index}>
                    <img
                      src={picture.document_path}
                      alt="Preview"
                      width="100px"
                      height="100px"
                    />
                  </RViewerJS>
                )}

                <div className="property_document">
                  <h4 className="property-img">
                    {picture.document_type.display_name}
                  </h4>
                  <div
                    className="delete-icon delect_property_tab"
                    onClick={() => {
                      setSalesDocId(picture.id);
                      setDeletePicture(picture);
                      setOpenModal(true);
                    }}
                  >
                    <DeleteOutlined />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Row>

      <div className="propert_button_onnext">
        <p className="documnet_heading_div">Documents</p>
      </div>

      <Row gutter={16} className="row_form_property_document">
        <Col span={8}>
          <Form.Item label="Document Type" name="document_type">
            <Select
              mode="single"
              placeholder="Please select"
              required
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {documentType?.map((documenttype) => (
                <Select.Option key={documenttype?.id} value={documenttype?.id}>
                  {documenttype?.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Document Number" name="document_number">
            <Input placeholder="Please Enter Document Number" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="document_path" label="Upload Documents">
            <Upload
              single
              fileList={fileList}
              onChange={handleUploadChange}
              beforeUpload={handleBeforeUpload}
              showUploadList={false}
              beUpload={handleBeforeUpload}
            >
              <Button
                type="primary"
                icon={<UploadOutlined />}
                className="slect_property_field"
                disabled={documentdisable}
              >
                Upload Document
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <div className="image-gallery">
        {image?.map((picture, index) => (
          <div key={index} className="image-container">
            <RViewerJS key={index}>
              <img
                src={picture.file_path}
                alt="img"
                height={100}
                width={100}
                className="property_image_img"
              />
            </RViewerJS>
            <div className="property-img">
              <div
                className="delete-icon delect_property_tab"
                onClick={() => {
                  setDeleteUploadPicture(picture);
                  setOpenSalesModal(true);
                }}
              >
                <DeleteOutlined />
              </div>
            </div>
          </div>
        ))}
      </div>


      <Modal
        title="Confirm Delete"
        open={openSalesModal}
        onOk={() => {
          confirmDelete();
          setOpenSalesModal(false);
          setDeleteUploadPicture(null);
        }}
        onCancel={() => {
          setOpenSalesModal(false);
          setDeleteUploadPicture(null);
        }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this upload image?`}</p>
      </Modal>

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          deletePropertyPictures();
          setOpenModal(false);
          // openSalesModal(false)
        }}
        onCancel={() => {
          setOpenModal(false);
          setSalesDocId(null);
        }}
        okType="danger"
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
      <div className="bottom-button">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default SalesPropertyDetailForm;
