import { Button, DatePicker, Form, Input, Modal, Select, Space, Table, message } from 'antd'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react'
// import ExistingLoanDetailsForm from '../../../customerTabDetails/existingLoansDetails/views/ExistingLoanDetailsForm';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { createEMIEquliserFormCam, deleteEMIEqualiserDetailsByIdService, getEMIEqualiserDataByCustomerId, IncomeCalculationPost, listBankService, listLoanPurposeService, listLoanStatusService, listSourceOfLiability } from '../services/incomeDetailsServices';
// import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
// import { useParams } from 'react-router-dom';
import dayjs from "dayjs";
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import { useParams } from 'react-router-dom';
import IncomeCalculation from './IncomeCalculation';
const { Option } = Select;

const EmiEuiliserForm = ({ closeForm, open, refreshData, id, selectedScheme, selectedIncome, selectedIncomeConsidered }) => {
    // const [customerList, setCustomerList] = useState([]);
    // const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [form] = Form.useForm();
    const { encrypted_loan_id } = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [allLoanPurpose, setAllLoanPurpose] = useState([]);
    const [allLoanStatus, setAllLoanStatus] = useState([]);
    const [allBanks, setAllBanks] = useState([]);
    const [allSourceOfLiabilities, setAllSourceOfLiabilities] = useState([]);
    const [considerObligation, setConsiderObligation] = useState('');
    const [previewData, setPreviewData] = useState(null);

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    const handleChange = (namePath, value) => {
        form.setFieldsValue({ [namePath]: value });
    };
    useEffect(() => {
        const getAllLoanPurpose = async () => {
            try {
                const response = await listLoanPurposeService();
                setAllLoanPurpose(response.data);
            }
            catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        const getAllLoanStatus = async () => {
            try {
                const response = await listLoanStatusService();
                setAllLoanStatus(response.data);
            }
            catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        const getAllBanks = async () => {
            try {
                const response = await listBankService();
                setAllBanks(response.data);
            }
            catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        const getAllSourceOfLiabilities = async () => {
            try {
                const response = await listSourceOfLiability();
                setAllSourceOfLiabilities(response.data);
            }
            catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        getAllLoanPurpose();
        getAllLoanStatus();
        getAllBanks();
        getAllSourceOfLiabilities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    // const customMaturityDateValidator = (_, value) => {
    //     const sanctionedDate = form.getFieldValue("sanctioned_date");
    //     if (dayjs(value).isSame(sanctionedDate, "day")) {
    //         return Promise.reject("Maturity date must be different from sanctioned date");
    //     }
    //     if (dayjs(value).isBefore(sanctionedDate, "day")) {
    //         return Promise.reject("Maturity date must be after the sanctioned date");
    //     }
    //     return Promise.resolve();
    // };

    // const customSanctionedDateValidator = (_, value) => {
    //     const maturityDate = form.getFieldValue("maturity_date");
    //     if (dayjs(value).isSame(maturityDate, "day")) {
    //         return Promise.reject("Sanctioned date must be different from maturity date");
    //     }
    //     if (dayjs(value).isAfter(maturityDate, "day")) {
    //         return Promise.reject("Sanctioned date must be before the maturity date");
    //     }
    //     return Promise.resolve();
    // };

    const handleNumberFields = (e, field) => {
        const newValue = e.target.value.replace(/[^\d.]/g, "");
        form.setFieldsValue({ [field]: newValue });
    };

    const handleConsiderObligationChange = (value) => {
        setConsiderObligation(value);
    };
    const getCashData = async () => {
        if (id) {
            try {
                const response = await getEMIEqualiserDataByCustomerId(id);
                let { existing_loans } = response?.data;

                form.setFieldsValue({
                    salaryDetails: existing_loans?.length > 0 ? existing_loans?.map(item => ({
                        id: item?.id,
                        loan_purpose: item?.loan_purpose?.id,
                        sanctioned_amount: item?.sanctioned_amount,
                        outstanding_amount: item?.outstanding_amount,
                        sanctioned_date: item?.sanctioned_date ? dayjs(item?.sanctioned_date, "DD-MM-YYYY") : null,
                        maturity_date: item?.maturity_date ? dayjs(item?.maturity_date, "DD-MM-YYYY") : null,
                        emi_amount: item?.emi_amount,
                        roi: item?.roi,
                        loan_status: item?.loan_status?.id,
                        consider_obligation: item?.consider_obligation,
                        eligibility_calculation_under_repayment_program: item?.eligibility_calculation_under_repayment_program,
                        source_of_liability: item?.source_of_liability,
                        bank: item?.bank?.id,
                    })) : [{
                        loan_purpose: null,
                        sanctioned_amount: null,
                        outstanding_amount: null,
                        sanctioned_date: null,
                        maturity_date: null,
                        emi_amount: null,
                        roi: null,
                        loan_status: null,
                        eligibility_calculation_under_repayment_program: null,
                        consider_obligation: null,
                        source_of_liability: null,
                        bank: null,
                    }]
                });
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        }

    };
    const handleDelete = async () => {
        try {
            // Call the service to delete the record from the backend
            const response = await deleteEMIEqualiserDetailsByIdService(loan_id, id, selectedScheme, selectedRecord.id);
            if (response.status === 200 && response.success === true) {
                message.success("Record deleted successfully");
                setOpenModal(false);
                getCashData();
                refreshData();
            }
        } catch (error) {
            setOpenModal(false);
            message.error(error.response.data.message);
        }
    };

    const handleSubmit = async (values) => {
        try {
            const payload = {
                customer_detail: id,
                loan_detail: loan_id,
                scheme_detail: selectedScheme,
                data: values.salaryDetails.map(item => ({
                    ...item,
                    sanctioned_date: item.sanctioned_date ? (item.sanctioned_date).format("YYYY-MM-DD") : null,
                    maturity_date: item.maturity_date ? (item.maturity_date).format("YYYY-MM-DD") : null
                })),
                // data: values.salaryDetails,
                income_considered: values.salaryDetails.every(item => item.id === undefined)
                    ? true
                    : (selectedIncome !== null ? selectedIncome : selectedIncomeConsidered)
                // income_considered: values.salaryDetails.find(item => item.id === undefined) ? true : selectedIncome
            };

            const response = await createEMIEquliserFormCam(payload);
            if ((response.status = 200 && response.success)) {
                message.success("EMI equaliser income details successfully created");
                form.resetFields();
                closeForm();
                refreshData();
            }
        }
        catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };
    const handlePreview = async () => {
        try {
            const values = await form.validateFields();
            const payload = {
                customer_detail: id,
                loan_detail: loan_id,
                scheme_detail: selectedScheme,
                data: values.salaryDetails,
            };

            const response = await IncomeCalculationPost(payload);
            if (response.status === 200 && response.success) {
                setPreviewData(response.data);
                // message.success("Preview data sent successfully");
            }
        } catch (error) {
            message.error("Please fill required field");
        }
    };
    useEffect(() => {
        if (open) {
            getCashData();

        } else {
            form.resetFields();
            // setPreviewData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id]);
    return (
        <div>
            <Form
                form={form}
                name="salary-details-form"
                layout="vertical"
                initialValues={{
                    salaryDetails: [{
                        sanctioned_amount: "",
                        outstanding_amount: "",
                        sanctioned_date: "",
                        maturity_date: "",
                        emi_amount: "",
                        roi: "",
                    }],
                }}
                onFinish={handleSubmit}
            >

                <Form.List name="salaryDetails">
                    {(fields, { add, remove }) => (
                        <>
                            <Table
                                columns={[
                                    {
                                        title: 'SR.NO',
                                        dataIndex: 'key',
                                        key: 'key',
                                        fixed: "left",
                                        render: (text, record, index) => <span>{index + 1}</span>,
                                    },
                                    {
                                        title: 'Loan Purpose',
                                        dataIndex: 'loan_purpose',
                                        key: 'loan_purpose',
                                        width: 150,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'loan_purpose']}
                                                rules={[{ required: true, message: 'Please select' }]}
                                            >
                                                <Select
                                                    placeholder="Select Select"
                                                    onChange={(value) => handleChange(['salaryDetails', index, 'loan_purpose'], value)}
                                                    allowClear
                                                    style={{ width: '200px' }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {allLoanPurpose?.map((loan_purpose) => {

                                                        return (
                                                            <Option
                                                                key={loan_purpose?.id}
                                                                value={loan_purpose?.id}>
                                                                {loan_purpose?.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Sanctioned Amount',
                                        dataIndex: 'sanctioned_amount',
                                        key: 'sanctioned_amount',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'sanctioned_amount']}
                                                rules={[{ required: true, message: 'Sanctioned amount is required' }]}
                                            >
                                                <Input
                                                    placeholder="Please enter sanctioned amoun"
                                                    onChange={(e) => {
                                                        handleChange(['salaryDetails', index, 'sanctioned_amount'], e.target.value);
                                                        handleNumberFields(e, "sanctioned_amount");
                                                    }}

                                                />

                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Outstanding Amount',
                                        dataIndex: 'outstanding_amount',
                                        key: 'outstanding_amount',
                                        width: 200,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'outstanding_amount']}
                                                rules={[
                                                    { required: true, message: "Outstanding amount is required" },

                                                ]}
                                            >
                                                <Input
                                                    placeholder="Please enter outstanding amount"
                                                    onChange={(e) => {
                                                        handleChange(['salaryDetails', index, 'outstanding_amount'], e.target.value);
                                                        handleNumberFields(e, "outstanding_amount");
                                                    }}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Sanctioned Date',
                                        dataIndex: 'sanctioned_date',
                                        key: 'sanctioned_date',
                                        width: 200,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'sanctioned_date']}
                                                rules={[
                                                    { required: true, message: "Sanctioned date is required" },
                                                    // { validator: customSanctionedDateValidator }
                                                ]}
                                            >
                                                <DatePicker
                                                    format="DD-MM-YYYY"
                                                    className="select_existing"
                                                    placeholder="Select Date"
                                                    onChange={(date, dateString) => handleChange(['salaryDetails', index, 'sanctioned_date'], dateString)}
                                                />

                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Maturity Date',
                                        dataIndex: 'maturity_date',
                                        key: 'maturity_date',
                                        width: 300,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'maturity_date']}
                                                rules={[
                                                    { required: true, message: "Maturity date is required" },
                                                    // { validator: customMaturityDateValidator }
                                                ]}
                                            >
                                                <DatePicker
                                                    style={{ width: "100%" }}
                                                    format="DD-MM-YYYY"
                                                    className="select_esutsting"
                                                    placeholder="Select Date"
                                                    onChange={(date, dateString) => handleChange(['salaryDetails', index, 'maturity_date'], dateString)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'EMI Amount',
                                        dataIndex: 'emi_amount',
                                        key: 'emi_amount',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'emi_amount']}
                                                rules={[
                                                    { required: true, message: "EMI amount is required" },
                                                    {
                                                        validator: (_, value) => {
                                                            const { sanctioned_amount, outstanding_amount } = form.getFieldsValue(["sanctioned_amount", "outstanding_amount"]);
                                                            if (!value) {
                                                                return Promise.resolve();
                                                            }
                                                            const emi = parseFloat(value);
                                                            if (emi === 0) {
                                                                return Promise.reject("EMI amount cannot be zero");
                                                            }
                                                            if (emi >= parseFloat(sanctioned_amount) || emi > parseFloat(outstanding_amount)) {
                                                                return Promise.reject("EMI amount must be less than or eqaul to both the sanctioned and outstanding amount");
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Please enter EMI amount"
                                                    onChange={(e) => {
                                                        handleChange(['salaryDetails', index, 'emi_amount'], e.target.value);
                                                        handleNumberFields(e, "emi_amount");
                                                    }}
                                                />

                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'ROI',
                                        dataIndex: 'roi',
                                        key: 'roi',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'roi']}
                                                rules={[{ required: true, message: 'ROI is required' }]}
                                            >
                                                <Input
                                                    style={{ width: '150px' }}
                                                    placeholder="Please enter ROI"
                                                    onChange={(e) => {
                                                        handleChange(['salaryDetails', index, 'roi'], e.target.value);
                                                        handleNumberFields(e, "roi");
                                                    }}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Eligibility Calculation Repayment Program ',
                                        dataIndex: 'eligibility_calculation_under_repayment_program',
                                        key: 'eligibility_calculation_under_repayment_program',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'eligibility_calculation_under_repayment_program']}
                                                rules={[{ required: true, message: 'Eligibility calculation is required' }]}
                                            >
                                                <Select
                                                    placeholder="Select Select"
                                                    onChange={(value) => handleChange(['salaryDetails', index, 'eligibility_calculation_under_repayment_program'], value)}
                                                    allowClear
                                                    style={{ width: '100%' }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value={true}>Yes</Option>
                                                    <Option value={false}>No</Option>
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Loan Status ',
                                        dataIndex: 'loan_status',
                                        key: 'loan_status',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'loan_status']}
                                                rules={[{ required: true, message: 'Enter agricultural income' }]}
                                            >
                                                <Select
                                                    placeholder="Select Select"
                                                    onChange={(value) => handleChange(['salaryDetails', index, 'loan_status'], value)}
                                                    allowClear
                                                    style={{ width: '200px' }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {allLoanStatus?.map((loan_status) => {

                                                        return (
                                                            <Select.Option
                                                                key={loan_status?.id}
                                                                value={loan_status?.id}>
                                                                {loan_status?.name}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Consider Obligation',
                                        dataIndex: 'consider_obligation',
                                        key: 'consider_obligation',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'consider_obligation']}
                                                rules={[{ required: true, message: 'Consider obligation is required' }]}
                                            >
                                                <Select
                                                    placeholder="Select Select"
                                                    // onChange={(value) => handleChange(['salaryDetails', index, 'consider_obligation'], value)}
                                                    value={considerObligation}
                                                    onChange={(value) => {
                                                        handleChange(['salaryDetails', index, 'consider_obligation'], value);
                                                        handleConsiderObligationChange(value);
                                                    }}
                                                    allowClear
                                                    style={{ width: '200px' }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Select.Option value={true}>Yes</Select.Option>
                                                    <Select.Option value={false}>No</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Source Of Liability',
                                        dataIndex: 'source_of_liability',
                                        key: 'source_of_liability',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'source_of_liability']}
                                                rules={[{ required: true, message: 'Enter agricultural income' }]}
                                            >
                                                <Select
                                                    placeholder="Select Select"
                                                    onChange={(value) => handleChange(['salaryDetails', index, 'source_of_liability'], value)}
                                                    allowClear
                                                    style={{ width: '200px' }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {allSourceOfLiabilities?.map((type) => (
                                                        <Select.Option key={type} value={type}>
                                                            {type}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Bank',
                                        dataIndex: 'bank',
                                        key: 'bank',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'bank']}
                                                rules={[{ required: true, message: 'Enter agricultural income' }]}
                                            >
                                                <Select
                                                    placeholder="Select Select"
                                                    onChange={(value) => handleChange(['salaryDetails', index, 'bank'], value)}
                                                    allowClear
                                                    style={{ width: '200px' }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {allBanks?.map((bank_name) => {

                                                        return (
                                                            <Select.Option
                                                                key={bank_name?.id}
                                                                value={bank_name?.id}>
                                                                {bank_name?.name}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Action',
                                        key: 'action',
                                        fixed: "right",
                                        render: (_, record, index) => {
                                            // Retrieve the actual value of the current salary detail using `form.getFieldValue`
                                            const salaryValues = form.getFieldValue('salaryDetails') || []; // Ensure there's a default value
                                            const currentDetail = salaryValues[index] || {}; // Get the current salary detail values


                                            return (
                                                <Form.Item>
                                                    <DeleteOutlined
                                                        style={{ color: 'red' }}
                                                        onClick={() => {
                                                            if (currentDetail?.id) {
                                                                setOpenModal(true);
                                                                setSelectedRecord(currentDetail)

                                                            } else {
                                                                remove(index);
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            );
                                        },

                                    },
                                ]}
                                dataSource={fields}
                                rowKey="key"
                                pagination={false}
                                scroll={{ x: true }}
                            />

                            <Button type="primary"
                                onClick={() => add()}
                                icon={<PlusCircleOutlined />}
                                style={{ marginTop: "10px" }} >
                                Month
                            </Button>
                        </>
                    )}
                </Form.List>

                <Space
                    direction="horizontal"
                    align="center"
                    style={{
                        display: "flex", flexDirection: "row-reverse",
                        marginBottom: "20px"
                    }}
                >
                    <Button type="primary" onClick={handlePreview}>
                        Preview
                    </Button>

                    <Button onClick={closeForm}>Cancel</Button>
                </Space>
                {previewData && (
                    <IncomeCalculation
                        previewData={previewData}
                        schemeDisplayName={selectedScheme}
                        refreshTableData={refreshData}
                    />
                )}
                <Space
                    direction="horizontal"
                    align="center"
                    style={{
                        display: "flex", flexDirection: "row-reverse",
                        marginBottom: "20px"
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Space>
            </Form>
            <Modal
                title="Confirm Delete"
                open={openModal}
                onOk={() => {
                    setOpenModal(false);
                    handleDelete();
                }}
                onCancel={() => setOpenModal(false)}
                okType="danger"
            >
                <p>{`Are you sure you want to delete?`}</p>
            </Modal>
        </div>




    )
}

export default EmiEuiliserForm