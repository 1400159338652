import { axiosRequest } from "../../../utils/api/axiosRequest";

export const getPropertyDedupe = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        `/api/v1/customer/dedupe/property-dedupe?loan_detail_id=${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getCustomerDedupe = (loan_id, customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        `/api/v1/customer/dedupe/customer-dedupe?loan_detail_id=${loan_id}&customer_id=${customer_id}`
      );
      // console.log(response);
      return resolve(response?.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const getLoanDetailsByLoanIdDedupe = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/loan/loan-detail/loan-id/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getLoanDetailsByIdDedupe = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-detail/${id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const dedupeCustomerLinkService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        "/api/v1/loan/linked-lan/link-lan-customer/",
        data
      ); 
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const dedupePropertyLinkService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        "/api/v1/loan/linked-lan/linked-lan-property/",
        data
      ); 
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getCustomersByLoanId = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/customer-detail/personal-detail-list/${loanId}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const createOverallStatusDedupeService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/dedupe/overall-status", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listOverallStatus = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/choices/dedupe-status`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getOverallStatusByLoanStageId = (loan_id, stage_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/dedupe/get-overall-status?loan_detail_id=${loan_id}&loan_stage_id=${stage_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getLoanIdForHyperlink = (appNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/dedupe/get-hyperlink-detail?application_number=${appNumber}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

