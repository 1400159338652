import {axiosRequest} from "../../../utils/api/axiosRequest"


export const getPropertyDescriptionById = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/property-legal-data/get-combine-legal-and-address/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const updatePropertyDescriptionById = (legal_id,values) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/property-legal-data/combine-update-legal-data-and-address/${legal_id}`,values);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const CustomerListService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-detail/personal-detail-list/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listUnitsService = () => {
  return new Promise(async (resolve, reject) => {
    try {            
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/unit-type-choice`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const listCity  = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/city/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  export const listTaluka = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/taluka/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  export const listDistrict  = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/district/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  export const listPincode  = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/pincode/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  export const listState  = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/state/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  export const listCountry  = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/country/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  

  export const getLegalSendBackData = (loan_stage) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/stage-query/get-stage-query-by-loan-stage/${loan_stage}?stage=LEGAL605`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getLegalQueryStatus = (loan_stage) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/choice/query-status`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const updateLegalSendBackData = (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/loan/stage-query/`,values);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }