import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import {
  createStageFlowService,
  getStageFlowByIdService,
  updateStageFlowByIdService,
} from "../services/stageFlowServices";
import { listStageService } from "../services/stageFlowServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  stage: Yup.string().required("Stage is required").min(1, "Stage is required"),
  next_stage: Yup.string().required("Next Stage is required").min(1, "Next Stage is required"),

});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const StageFlowForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [form] = Form.useForm();
  const [allStage, setAllStage] = useState([]);
  const [allNextStage, setAllNextStage] = useState([]);

  const getStageFlowData = async () => {
    if (id) {
      try {
        const response = await getStageFlowByIdService(id);
        const data = response?.data[0]; // Accessing the first element if it's an array
        
        const { stage, next_stage, is_active, is_verified } = data;
        form.setFieldsValue({
          stage: stage?.id,
          next_stage: next_stage?.id,
          is_active,
          is_verified,
        });
      } catch (error) {
        console.error('Error fetching stage flow data:', error); // Debug log
        message.error(error?.response?.data?.message || "Error fetching stage flow data");
      }
    }
  };


  const handleSubmit = async (values) => {
    try {
      if (id) {
        const response = await updateStageFlowByIdService(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Stage Flow successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createStageFlowService(values);
        if ((response.status = 201 && response.success)) {
          message.success("Stage Flow successfully created");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (open) {
      getStageFlowData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  useEffect(() => {
    const fetchAllStage = async () => {
      try {
        const response = await listStageService();
        setAllStage(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchAllStage();
  }, []);
  useEffect(() => {
    const fetchAllNextStage = async () => {
      try {
        const response = await listStageService();
        setAllNextStage(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchAllNextStage();
  }, []);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        display_name: "",
        code: "",
        is_active: true,
        is_verified: true,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="stage" label="Stage" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allStage?.map((stage) => {
                return (
                  <Select.Option key={stage.id} value={stage.id}>
                    {stage.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="next_stage" label="Next Stage" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allNextStage?.map((next_stage) => {
                return (
                  <Select.Option key={next_stage.id} value={next_stage.id}>
                    {next_stage.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default StageFlowForm;
