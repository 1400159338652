import React, { useState, useEffect, useCallback } from "react";
import { Checkbox, Button, Space, Row, Col, message } from "antd";
import { getFavoriteCustomerByIdService, createFavoriteCustomerTabsService } from "../services/callInitiateService";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";
import Loading from "../../../../../utils/loading/Loading";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SelectCustomerTabs = ({ onSave, favoriteTabs, defaultTabs, isOpen, selectedTabs, onClose }) => {
  const [selectedItems, setSelectedItems] = useState(selectedTabs);
  const [loading, setLoading] = useState(false);

  const DISABLED_TABS = [
    "Personal Details",
    "Address Details",
    "Document Details",
    "Employment Details",
    "Existing Loan Details",
  ];

  const fetchFavoriteTabs = useCallback(async () => {
    try {
      const response = await getFavoriteCustomerByIdService();
      const savedTabNames = response.data.map((tab) => tab.name);
      setSelectedItems([...new Set([...defaultTabs, ...savedTabNames])]);
    } catch (error) {
      message.error("Failed to fetch favorite tabs");
    }
  }, [defaultTabs]);

  useEffect(() => {
    if (isOpen) {
      fetchFavoriteTabs();
    }
  }, [isOpen, fetchFavoriteTabs]);

  useEffect(() => {
    setSelectedItems(selectedTabs);
  }, [selectedTabs]);

  const onChange = (checkedValues, columnTabs) => {
    setSelectedItems((prev) => {
      const filteredPrev = prev.filter((tab) => !columnTabs.includes(tab));
      return [...new Set([...filteredPrev, ...DISABLED_TABS, ...checkedValues])];
    });
  };

  const handleSave = async () => {
    setLoading(true);
    const payload = {
      customer_tab_codes: selectedItems
        .map((tabName) => {
          const tab = favoriteTabs.find((t) => t.display_name === tabName);
          return tab?.code || "";
        })
        .filter(Boolean),
    };

    try {
      await createFavoriteCustomerTabsService(payload);
      message.success("Tabs saved successfully");
      onSave(selectedItems);
    } catch (error) {
      message.error("Failed to save tabs");
    } finally {
      setLoading(false);
    }
  };

  const firstColumnTabs = favoriteTabs.slice(0, Math.ceil(favoriteTabs.length / 2)).map((tab) => tab.display_name);
  const secondColumnTabs = favoriteTabs.slice(Math.ceil(favoriteTabs.length / 2)).map((tab) => tab.display_name);

  return (
    <div >
      {loading ? (
        <Loading loading={loading} />
      ) : favoriteTabs?.length > 0 ? (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Checkbox.Group
              options={firstColumnTabs.map((tabName) => ({
                label: tabName,
                value: tabName,
                disabled: DISABLED_TABS.includes(tabName),
              }))}
              value={selectedItems}
              onChange={(checkedValues) => onChange(checkedValues, firstColumnTabs)}
              style={{ display: "flex", flexDirection: "column", gap: "15px", marginLeft: "10%" }}
            />
          </Col>

          <Col span={12}>
            <Checkbox.Group
              options={secondColumnTabs.map((tabName) => ({
                label: tabName,
                value: tabName,
                disabled: DISABLED_TABS.includes(tabName),
              }))}
              value={selectedItems}
              onChange={(checkedValues) => onChange(checkedValues, secondColumnTabs)}
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            />
          </Col>
        </Row>

      ) : (
        <div className="no_data_found_message">
          <CustomNoData />
        </div>
      )}

      {loading ? (
        <Skeleton count={6} height={30} style={{ marginTop: "20px" }} />
      ) : (

        <Space direction="horizontal" align="center" className="settings_form_button">
          <Button type="primary" onClick={handleSave} loading={loading}>
            Save
          </Button>
          <Button type="default" onClick={onClose}>Cancel</Button>
        </Space>
      )}
    </div>

  );
};

export default SelectCustomerTabs;