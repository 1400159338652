import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Select, Upload, message } from "antd";
import { listLegalIploadDocumentTypeService } from "../../services/documentDetailService";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import "../../styles/legalDocumentInitiateInputForm.css";
import * as Yup from "yup";
import pdfIcon from "../../assets/pdf.png";
import RViewerJS from "viewerjs-react";
import { DownOutlined, UpOutlined, FileOutlined } from "@ant-design/icons";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  upload_documents: Yup.mixed()
    .test(
      "is-valid",
      "Document Upload is required",
      (value) =>
        typeof value === "string" || (Array.isArray(value) && value.length > 0)
    )
    .label("Upload Document"),
    file_path: Yup.mixed().required("Document is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const LegalUploadInputForm = ({ form, setDocumentUpload, documnetupload }) => {
  const [collapsed1, setCollapsed1] = useState(true);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [legalDocumentType, setLegalDocumentType] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [imageId, setImageId] = useState();
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);

  const handleDocumentNameChange = (value) => {
    // Add the selected value to the selectedDocumentTypes array
    setSelectedDocumentTypes((prev) => {
      return [...prev, value]; // If value is not already in the array, add it
      // If the value is already in the array, don't add it again
    });

    // Disable the upload button if no document name is selected
    setUploadDisabled(!value);
  };

  const confirmDelete = () => {
    const filteredImages = image.filter((img) => img.id !== imageId);
    setImage(filteredImages);
    setDocumentUpload(filteredImages);
    const filteredFileList = fileList.filter((file) => file.uid !== imageId);
    setFileList(filteredFileList);
    setOpenUploadModal(false);
    form.setFieldsValue({ file_path: undefined }); // or null

  };

  const handleBeforeUpload = (file) => {
    const newImage = {
      id: file.uid,
      file_path: URL.createObjectURL(file),
      isPDF: file.type === "application/pdf",
      isImage:
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png",
    };

    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false;
  };

  const handleUploadChange = async ({ fileList: newFileList }) => {
    const freshImages = newFileList.map(async (file, index) => {
      let base64url = "";

      try {
        base64url = await convertFileToBase64(file.originFileObj);
      } catch (error) {
        message.error("Error converting file to base64.");
        return null;
      }
      const documentType = selectedDocumentTypes[index];
      return {
        legal_documents: {
          file_path: base64url,
          document_type: documentType,
        },
      };
    });

    const processedImages = await Promise.all(freshImages);
    const validImages = processedImages.filter((image) => image !== null);
    setDocumentUpload(validImages);
    setFileList(newFileList);
    setUploadDisabled(true);
    form.resetFields(["upload_documents"]);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const fetchLegalDocumentType = async () => {
    try {
      const response = await listLegalIploadDocumentTypeService();
      setLegalDocumentType(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    fetchLegalDocumentType();

    form.resetFields(["upload_documents"]);
    setFileList([]);
    setImage([]);
    setUploadDisabled(true);
  }, [form]);
  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };
  return (
    <div className="legal_initiate_input_main_container">
      <div className="legal_initiat_input_document_detail_heading">
        <div className="property_description_data">
          Legal Documents and Other Documents
        </div>
        <div className="scrutiny_collapse_button" onClick={toggleCollapse1}>
          {collapsed1 ? <DownOutlined /> : <UpOutlined />}
          <div className="scrutiny_collapse_button"></div>
        </div>
      </div>
      {collapsed1 && (
        <div className="Conclusion_content">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="upload_documents"
                label="Enter Document Name"
                rules={image.length ? null : [yupSync]}
                required
              >
                <Select
                  placeholder="Please select"
                  allowClear
                  showSearch
                  onChange={handleDocumentNameChange}
                  // disabled={uploadDisabled}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {legalDocumentType?.map((legaldocumenttype) => (
                    <Option
                      key={legaldocumenttype.value}
                      value={legaldocumenttype.value}
                    >
                      {legaldocumenttype.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item name="file_path" label="Select Documnet" required rules={[yupSync]}>
                <Upload
                  single
                  fileList={fileList}
                  onChange={handleUploadChange}
                  beforeUpload={handleBeforeUpload}
                  showUploadList={false}
                  disabled={uploadDisabled}
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    disabled={uploadDisabled}
                  >
                    Upload Document
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <div className="scrutiny_collapse_button">
            <RViewerJS>
              <div className="legal_initiate_input_upload_form">
                {image?.map((picture, index) => (
                  <div
                    key={picture.id}
                    className="legal_initiate_input_upload_form_image"
                  >
                    {picture.isPDF ? (
                      <img
                        src={pdfIcon}
                        alt="PDF Icon"
                        className="legal_initiate_input_pdf"
                      />
                    ) : picture.isImage ? (
                      <img
                        src={picture.file_path}
                        alt=""
                        className="legal_initiate_input_pdf_path"
                      />
                    ) : (
                      <FileOutlined className="technical-filesize" />
                    )}
                    <div
                      className="legal_initiate_input_image_view"
                      onClick={() => {
                        setImageId(picture.id);
                        setOpenUploadModal(true);
                      }}
                    >
                      <DeleteOutlined />
                    </div>
                  </div>
                ))}
              </div>
            </RViewerJS>
          </div>

          <Modal
            title={<h4>Are you sure you want to delete this image?</h4>}
            open={openModal}
            onCancel={() => {
              setOpenModal(false);
            }}
            okType="danger"
          ></Modal>
          <Modal
            title={<h4>Are you sure you want to delete this image?</h4>}
            open={openUploadModal}
            onOk={confirmDelete}
            onCancel={() => {
              setOpenUploadModal(false);
            }}
            okType="danger"
          ></Modal>
        </div>
      )}
    </div>
  );
};

export default LegalUploadInputForm;
