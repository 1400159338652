import React, { useEffect, useState } from "react";
import usersecond from "../assets/application.png";
import contact from "../assets/contact.png";
import "../styles/loanApplication.css";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import {
  getLAN,
  getLoanDetailsById,
  getLoanDetailsByLoanId,
} from "../services/loanapplicationService";
import { message, Button } from "antd";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import location from "../assets/location.png";
import Loading from "../../../../utils/loading/Loading";
// import CustomNoData from '../../../../utils/noDataIcon/CustomNoData';

const LoanDetailMainCard = ({ refresh }) => {
  const [loanDetailsByLoanId, setLoanDetailsByLoanId] = useState(null);
  const [loanDetails, setLoanDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { encrypted_loan_id } = useParams();
  const [displayButton, setDisplayButton] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const fetchLAN = async () => {
    try {
      const response = await getLAN(loan_id);
      if (response.status === 200) {
        message.success("Loan Account Number Generated Successfully");
        setDisplayButton(false);
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        const response = await getLoanDetailsById(loan_id);
        setLoanDetails(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const fetchLoanDetailsByLoanId = async () => {
      setLoading(true);
      try {
        const response = await getLoanDetailsByLoanId(loan_id);
        setLoanDetailsByLoanId(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLoanDetailsByLoanId();
    fetchLoanDetails();
  }, [loan_id, displayButton, refresh]);
  return (
    <>
      <div className="loan_application_main_container">
        <div className="loan-application-container">
          <div className="loan-details">
            <p>Loan Account Number</p>
            <h1>{loanDetails?.loan_account_number || "-"}</h1>
          </div>
        </div>

        <div>
          {displayButton === true ? (
            <Button
              type="primary"
              className="loangenerate_button"
              onClick={fetchLAN}
            >
              Generate Lan Number
            </Button>
          ) : (
            ""
          )}
        </div>
        <div>
          <p className="parent-stage-font-view">
            {loanDetails?.parent_stage || "-"}
          </p>
        </div>
      </div>

      <div>
        <div className="fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : (
            <>
              <div className="main_loan_basic_container">
                <div className="main_page_loan">
                  <div className="laon_main_page_first">
                    <div className="container-header">
                      <div>
                        <img
                          src={usersecond}
                          alt="user_image"
                          className="user_image_loan"
                        />
                      </div>

                      <div className="card_loan">
                        <div className="">
                          {/* {loanDetailsByLoanId?.loan_detail?.application_number || "-"} */}
                          {loanDetailsByLoanId?.loan_detail?.loan_type
                            ?.loan_type || "-"}
                        </div>
                        <div className="loan_label">Loan Type</div>
                      </div>
                    </div>
                  </div>

                  <div className="laon_main_page">
                    <div className="container-header container_heading_content">
                      <div className="">
                        <img
                          src={usersecond}
                          alt="user_image"
                          className="user_image_loan"
                        />
                      </div>

                      <div className="card_loan">
                        <div className="">
                          {loanDetailsByLoanId?.loan_detail
                            ?.application_number || "-"}
                        </div>
                        <div className="loan_label">Application Number</div>
                      </div>
                    </div>
                  </div>

                  <div className="laon_main_page">
                    <div className="container-header container_heading_content">
                      <div className="">
                        <img src={contact} className="user_image_loan" alt="" />
                      </div>

                      <div className="card_loan">
                        <div className="">
                          {" "}
                          {loanDetailsByLoanId &&
                          loanDetailsByLoanId?.customer_details
                            ? loanDetailsByLoanId?.customer_details.find(
                                (customer_details) =>
                                  customer_details.customer_type
                                    .customer_type_name === "Applicant" ||
                                  customer_details.customer_type
                                    .customer_type_name === "Company Applicant"
                              )?.full_name || "-"
                            : "-"}
                        </div>
                        <div className="loan_label">Applicant</div>
                      </div>
                    </div>
                  </div>

                  <div className="laon_main_page">
                    <div className="container-header container_heading_content">
                      <div>
                        <img
                          src={location}
                          alt="user_image"
                          className="user_image_loan"
                        />
                      </div>

                      <div className=" card_location">
                        <div className="">
                          {loanDetailsByLoanId?.loan_detail?.branch || "-"}
                        </div>
                        <div className="loan_label">Branch</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Basic_details_container">
                  <div className="Basic_card">
                    {/* <div className="basic_card-content">
                      <div className="loan_label" >Last Tranches</div>
                      <div className="loan_data">
                        {loanDetailsByLoanId?.loan_detail?.tranches || "-"}
                      </div>
                    </div> */}
                    <div className="basic_card-content">
                      <div className="loan_label">Sales Manager</div>
                      <div className="loan_data">
                        {loanDetailsByLoanId?.loan_detail?.sales_manager
                          ?.name || "-"}
                      </div>
                    </div>
                    <div className="basic_card-content">
                      <div className="loan_label">Scheme</div>
                      <div className="loan_data">
                        {loanDetailsByLoanId?.loan_detail?.scheme?.name || "-"}{" "}
                      </div>
                    </div>
                    <div className="basic_card-content">
                      <div className="loan_label">Interest Rate</div>
                      <div className="loan_data">
                        {loanDetailsByLoanId?.loan_detail?.intrest_rate || "-"}
                      </div>
                    </div>
                    <div className="basic_card-content">
                      <div className="loan_label">Disbursement Date</div>
                      <div className="loan_data">
                        {loanDetailsByLoanId?.loan_detail?.amount || "-"}
                      </div>
                    </div>
                  </div>

                  <div className="Basic_card">
                    {/* <div className="basic_card-content">
                      <div className="label">Application Number</div>
                      <div className="data">
                        {loanDetailsByLoanId?.loan_detail?.application_number || "-"}
                      </div>
                    </div> */}
                    <div className="basic_card-content">
                      <div className="loan_label">Credit Manager</div>
                      <div className="loan_data">
                        {loanDetails?.credit_manager?.name || "-"}
                      </div>
                    </div>

                    <div className="basic_card-content">
                      <div className="loan_label">Sanction Amount</div>
                      <div className="loan_data">
                        {loanDetailsByLoanId?.loan_detail?.product?.name || "-"}{" "}
                      </div>
                    </div>
                    <div className="basic_card-content">
                      <div className="loan_label">LTV</div>
                      <div className="loan_data">
                        {loanDetailsByLoanId?.loan_detail?.ltv || "-"}
                      </div>
                    </div>
                    <div className="basic_card-content">
                      <div className="loan_label">Installment Frequency</div>
                      <div className="loan_data">
                        {loanDetails?.emi_frequency || "-"}
                      </div>
                    </div>
                  </div>

                  <div className="Basic_card">
                    <div className="basic_card-content">
                      <div className="loan_label">Product</div>
                      <div className="loan_data">
                        {loanDetails?.product?.name || "-"}{" "}
                      </div>
                    </div>
                    <div className="basic_card-content">
                      <div className="loan_label">Sanction Date</div>
                      <div className="loan_data">
                        {loanDetailsByLoanId?.loan_detail?.sanction_date || "-"}
                      </div>
                    </div>
                    <div className="basic_card-content">
                      <div className="loan_label">Interest Type</div>
                      <div className="loan_data">
                        {loanDetails?.interest_type || "-"}{" "}
                      </div>
                    </div>
                    <div className="basic_card-content">
                      <div className="loan_label">Installment Amount</div>
                      <div className="loan_data">
                        {loanDetailsByLoanId?.loan_detail?.amount || "-"}
                      </div>
                    </div>
                  </div>

                  <div className="Basic_card">
                    <div className="basic_card-content">
                      <div className="loan_label">Loan Purpose</div>
                      <div className="loan_data">
                        {loanDetails?.loan_purpose?.name || "-"}
                      </div>
                    </div>
                    <div className="basic_card-content">
                      <div className="loan_label">Approved tenure</div>
                      <div className="loan_data">
                        {loanDetails?.approved_tenure || "-"}
                      </div>
                    </div>
                    <div className="basic_card-content">
                      <div className="loan_label"> Disbursement Amount</div>
                      <div className="loan_data">
                        {loanDetailsByLoanId?.loan_detail?.Purpose?.name || "-"}
                      </div>
                    </div>
                    <div className="basic_card-content">
                      <div className="loan_label">Due Date</div>
                      <div className="loan_data">
                        {loanDetails?.emi_due_date || "-"}
                      </div>
                    </div>
                    {/* <div className="basic_card-content">
                      <div className="loan_label">Interest Start Date</div>
                      <div className="loan_data">
                        {loanDetailsByLoanId?.loan_detail?.start_date || "-"}
                      </div>

                    </div> */}
                  </div>
                </div>
              </div>
            </>
          )}
          {/* // :
          //   (
          //     <div className="no_data_found_message">
          //       <CustomNoData />
          //     </div>
          //   ) */}
        </div>
      </div>
    </>
  );
};

export default LoanDetailMainCard;
