import React, { useState, useEffect, useCallback } from "react";
import { Button, Table, Drawer, message, Select, Spin } from "antd";
import "../styles/nachDetails.css";
import NachForm from "./NachForm";
import Header from "../../../layout/views/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { decrypt, encrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import {
  getNachByLoanIdService,
  // getLoanNumberDetail,
  getListOfLoan,
  getCustomersByLoanId,
  getBankAccountDetailsByIdService,
} from "../services/nachDetailService";
import debounce from "lodash.debounce";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import NachAddForm from "./NachAddForm";
import BankAccountDetailsForm from "../../customerTabDetails/bankAccountDetails/views/BankAccountDetailsForm";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../utils/authorization/AuthorizeComponent";

const NachDetails = ({ id }) => {
  const [showBankAccountData, setShowBankAccountData] = useState(false);
  const [bankAccountData, setBankAccountData] = useState(null);
  const [showAddNachForm, setShowAddNachForm] = useState(false);
  const [refreshTableData] = useState(false);
  const [buttonText] = useState("Change Account");
  const [nachData, setNachData] = useState();
  const [historyNachData, setHistoryNachData] = useState();
  const [loanId, setLoanId] = useState(null);
  const [isLoanChecked, setIsLoanChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [nachId, setNachId] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [loanList, setLoanList] = useState([]);
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [activeNachAccount, setActiveNachAccount] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const { encrypted_loan_id } = useParams();
  const navigate = useNavigate();

  const { user_data } = useSelector((state) => state.user);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  // History NACH Columns
  const columns1 = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
      render: (text) => text || "-",
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      key: "account_type",
      render: (text) => text || "-",
    },

    {
      title: "A/C Number",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
      key: "branch",
    },
    {
      title: "Holder Name",
      dataIndex: "holder_name",
      key: "holder_name",
    },
    {
      title: "IFSC Code",
      dataIndex: "ifsc_number",
      key: "ifsc_code",
    },
    {
      title: "MICR",
      dataIndex: "micr_number",
      key: "micr",
    },

    {
      title: "MI Amount",
      dataIndex: "emi",
      key: "mi_amount",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Vendor",
      key: "vendor",
      render: (record) => record?.vendor?.display_name,
    },

    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
    },
  ];

  // Active NACH Accounts columns
  const NachColumns = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank",
      render: (text) => text || "-",
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      key: "account_type",
      render: (text) => text || "-",
    },
    {
      title: "A/C No",
      dataIndex: "account_number",
      key: "account_number",
      render: (text) => text || "-",
    },
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      key: "branch",
      render: (text) => text || "-",
    },
    {
      title: "Holder Name",
      dataIndex: "holder_name",
      key: "account_holder_name",
      render: (text) => text || "-",
    },
    {
      title: "IFSC",
      dataIndex: "ifsc_number",
      key: "ifsc_code",
      render: (text) => text || "-",
    },
    {
      title: "MICR",
      dataIndex: "micr_number",
      key: "micr_code",
      render: (text) => text || "-",
    },
  ];

  const AllBankAccount = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank",
      render: (text) => text || "-",
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      key: "account_type",
      render: (text) => text || "-",
    },
    {
      title: "A/C No",
      dataIndex: "account_number",
      key: "account_number",
      render: (text) => text || "-",
    },
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      key: "branch",
      render: (text) => text || "-",
    },
    {
      title: "Holder Name",
      dataIndex: "holder_name",
      key: "account_holder_name",
      render: (text) => text || "-",
    },
    {
      title: "IFSC",
      dataIndex: "ifsc_number",
      key: "ifsc_code",
      render: (text) => text || "-",
    },
    {
      title: "MICR",
      dataIndex: "micr_number",
      key: "micr_code",
      render: (text) => text || "-",
    },
  ];

  

  useEffect(() => {
    if (loanId) {
      const fetchNachData = async () => {
        if (!loan_id) return;
        try {
          const response = await getNachByLoanIdService(loan_id);
          setActiveNachAccount(response?.data.active_nach_account || []);
          setNachData(response?.data.active_nach_account || []);
          setHistoryNachData(response?.data.in_active_nach_account || []);
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        }
      };
      fetchNachData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanId,loan_id, refreshTableData]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getBankAccountDetailsByIdService(loan_id);
        const results = response.data.results;

        // Transform the fetched data
        const transformedData = results.flatMap((customer) =>
          customer.customer_bank_details.map((account, index) => ({
            sr_no: account.id,
            bank_name: account.bank.display_name,
            account_type: account.account_type,
            account_number: account.account_number,
            branch_name: account.bank_branch.display_name,
            holder_name: account.account_holder_name,
            ifsc_number: account.ifsc_code,
            micr_number: account.micr_code,
          }))
        );

        setBankAccountData(transformedData);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        setLoading(false);
      }
    };

    if (loan_id) {
      fetchData();
    }
  }, [loan_id, refreshData]); // Add loan_id as a dependency

  const handleToggleData = () => {
    setShowBankAccountData((prev) => !prev);
  };

  // Application Number Serch api service

  const handleSearch = async (value) => {
    if (value) {
      setLoading(true);
      try {
        const response = await getListOfLoan(value);
        setLoanList(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        setLoading(false);
      }
    } else {
      setLoanList([]);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  // Application Number Select functionality

  const handleSelect = async (value) => {
    const selectedLoan = loanList.find(
      (loan) => loan.loan_account_number === value
    );

    if (selectedLoan) {
      setSelectedLoanId(selectedLoan?.id);
    }
    try {
      // const response = await getLoanNumberDetail(value);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  // Loan number successfully verify function
  const handleCheckInDatabase = () => {
    if (selectedLoanId) {
      setLoanId(selectedLoanId);
      const encryptedLoanId = encrypt(selectedLoanId.toString());

      navigate(`/nach/${encryptedLoanId}`);
      setIsLoanChecked(true);
      message.success("Loan number checked successfully");
    } else {
      setIsLoanChecked(false);
      message.error("Please select a loan number");
    }
  };

  const handleButtonClick = () => {
    showChequeFormDrawer();
  };

  // Add Account button style
  const buttonStyle = {
    background: buttonText === "Change Account" ? "lightgreen" : "orange",
    color: "white",
  };

  // Add Other Nach Account oncloseForm function
  const onCloseChequeForm = () => {
    setShowAddNachForm(false);
  };
  // Add Other Nach Account show drawer function
  const showChequeFormDrawer = () => {
    setShowAddNachForm(true);
  };

  // Add Row Click form onclose function
  const onClose = () => {
    setSelectedCustomer(null);
    setOpen(false);
  };
  // Add Row Click function
  const handleRowClick = (record) => {
    setNachId(record?.sr_no);
    setOpen(true);
  };

  // Add NACH Details Form  openNachDrawer function
  const openNachDrawer = () => {
    setDrawerVisible(true);
  };

  // Add NACH Details Form  closeNachDrawer function
  const closeNachDrawer = () => {
    setDrawerVisible(false);
  };

  const fetchCustomersByLoanId = async () => {
    if (!loanId) return;

    setLoading(true);
    try {
      const response = await getCustomersByLoanId(loanId);
      const customers = response?.data?.customer_list?.map((customer) => ({
        id: customer.id,
        name: `${customer.name}`,
      }));
      setCustomerList(customers || []);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  // Reset customer selection when the drawer closes
  useEffect(() => {
    if (showAddNachForm) {
      fetchCustomersByLoanId();
      setSelectedCustomer(null); // Reset selected customer when drawer opens
    } else {
      // Reset state when drawer closes
      setCustomerList([]);
      setSelectedCustomer(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddNachForm, loanId]);

  const handleCustomerchange = (value) => {
    setSelectedCustomer(value);
  };

  const rowClassName = (record) => {
    const isActive = activeNachAccount.some(
      (active) =>
        active.account_number === record.account_number &&
        active.holder_name === record.holder_name
    );
    
    return isActive ? "active-account-row" : ""; // Apply class if active
  };

  const location = useLocation();
  const isFromLoanDetails = location.pathname.includes("/loandetails/");

  if (isFromLoanDetails) {
    // If coming from loan details, extract loan ID from the URL
    const encryptedId = location.pathname.split('/').pop();
    try {
      loan_id = decrypt(encryptedId);
    } catch (error) {
      message.error("Invalid Loan ID");
    }
  } else {
    // Original logic for nach route
    try {
      loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
      message.error("Invalid Loan ID");
    }
  }
  
  // Set the loanId state when component mounts
  useEffect(() => {
    if (loan_id) {
      setLoanId(loan_id);
    }
  }, [loan_id]);

  return (
    <div>
      <div>
        <Drawer
          title={
            <Header
              title="Add"
              onClose={onCloseChequeForm}
              name="Add Other Nach Account"
            />
          }
          width={900}
          onClose={onCloseChequeForm}
          open={showAddNachForm}
          closable={false}
        >
          <Select
            value={selectedCustomer}
            onChange={handleCustomerchange}
            className="nach-select-customer"
            placeholder="Please Select Customer"
            labe
          >
            {customerList.length > 0 &&
              customerList.map((customer) => {
                return (
                  <Select.Option value={customer.id}>
                    {customer.name}
                  </Select.Option>
                );
              })}
          </Select>
          <div className="bank-account-container"></div>
          {selectedCustomer && (
            <BankAccountDetailsForm
              refreshData={toggleRefreshData}
              open={open}
              closeForm={onCloseChequeForm}
              customer_id={selectedCustomer}
            />
          )}
        </Drawer>
      </div>

      <div className="pdc_container">
        {!isFromLoanDetails && (
          <div className="nach_detail_heading">
            <div className="nach_heading">
              <h6>Application Number</h6>
            </div>
            <div className="application_serch">
              <Select
                showSearch
                allowClear
                placeholder="Enter Loan Number"
                onSearch={debouncedHandleSearch}
                onSelect={handleSelect}
                loading={loading}
                filterOption={false}
                notFoundContent={loading ? <Spin size="small" /> : null}
                disabled={id}
              >
                {loanList.map((loan) => (
                  <Select.Option key={loan.id} value={loan.loan_account_number}>
                    {loan?.loan_account_number}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div>
              <Button className="search_button" onClick={handleCheckInDatabase}>
                Check in data base
              </Button>
            </div>
            <div>
              <Button className="view_button">View Loan Details</Button>
            </div>
          </div>
        )}

        {(isFromLoanDetails || isLoanChecked) && (
          <>
            <div className="pdc_details_table">
              <div className="pdc_detail_heading">
                <div className="pdc_description_data">Active NACH Accounts</div>
                <div className="nach-change-account-container">
                  <div>
                    <ConditionalRender
                      userPermissions={user_data?.permissions}
                      requiredPermissions={["EDITNACH"]}
                    >
                      <Button
                        type="primary"
                        style={buttonStyle}
                        onClick={handleToggleData} // Switches between NACH and bank account data
                      >
                        Change Account
                      </Button>
                    </ConditionalRender>
                    <ConditionalRender
                      userPermissions={user_data?.permissions}
                      requiredPermissions={["POSTNACH"]}
                    >
                      <Button
                        type="primary"
                        className="nach-other-account-button"
                        onClick={handleButtonClick}
                      >
                        Add Other Account
                      </Button>
                    </ConditionalRender>
                  </div>

                  <div>
                    <Button
                      className="nach-form-button"
                      onClick={openNachDrawer} // Opens the drawer
                      // disabled={true}
                      type="primary"
                    >
                      NACH Form
                    </Button>
                  </div>

                  <Drawer
                    title={
                      <Header
                        title="Add"
                        onClose={closeNachDrawer}
                        name="Add NACH Details Form"
                      />
                    }
                    width={900}
                    closable={false}
                    placement="right"
                    onClose={closeNachDrawer}
                    open={isDrawerVisible}
                  >
                    <NachAddForm onClose={closeNachDrawer} />
                  </Drawer>
                </div>
              </div>

              <Drawer
                title={
                  <Header
                    title="Add"
                    onClose={onClose}
                    name="Nach person bank details"
                  />
                }
                width={900}
                onClose={onClose}
                open={open}
                closable={false}
              >
                <NachForm
                  closeForm={onClose}
                  nachId={nachId}
                  refreshData={toggleRefreshData}
                  open={open}
                />
              </Drawer>

              <div className="table_content">
                {/* Conditionally render based on showBankAccountData */}
                {showBankAccountData ? (
                  <Table
                    dataSource={bankAccountData} // Display bank account data
                    columns={AllBankAccount} // Add relevant columns for bank account data
                    onRow={(record) => ({
                      onClick: () => handleRowClick(record),
                    })}
                    rowClassName={rowClassName}
                    style={{ cursor: "pointer" }}
                    pagination={false}
                  />
                ) : (
                  <Table
                    dataSource={nachData} // Show NACH data
                    columns={NachColumns}
                    style={{ cursor: "pointer" }}
                    pagination={false}
                  />
                )}
              </div>
            </div>

            <div className="pdc_details_table">
              <div className="pdc_detail_heading">
                <div className="pdc_description_data">History NACH</div>
              </div>

              <div className="table_content">
                <Table
                  dataSource={historyNachData}
                  columns={columns1}
                  pagination={false}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NachDetails;
