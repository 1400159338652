import React, { useEffect, useState } from 'react';
import { Form, Button, Space, Row, Col, message, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { decrypt } from '../../../../../../utils/cryptoUtils/cryptoUtils';
import ErrorMessage from '../../../../../../utils/errorHandling/ErrorMessage';
import { listStandardReasons, listSubReasons, submitRejection } from '../services/rejectFormService';
import { useSelector } from "react-redux";

const RejectForm = ({ closeForm, open, setSubmitStageBar }) => {
  const [form] = Form.useForm();
  const { encrypted_loan_id } = useParams();
  const [standardReasons, setStandardReasons] = useState([]);
  const [subReasons, setSubReasons] = useState([]);
  const { user_data } = useSelector((state) => state.user);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchAllStandardReasons = async () => {
      try {
        const response = await listStandardReasons();
        setStandardReasons(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchAllStandardReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fetchAllSubReasons = async (standard_code) => {
    try {
      const response = await listSubReasons(standard_code);
      setSubReasons(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const handleStandardReasonChange = (value) => {
    form.setFieldsValue({ sub_reason: undefined });
    fetchAllSubReasons(value); // Fetch sub-reasons based on selected standard reason
  };

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      loan_detail: loan_id,
      stage: "REJECT91",
      stage_status: "COMPLETE",
      user: user_data?.id,

    };

    try {
      const response = await submitRejection(payload);
      if ((response.status = 201 && response.success)) {
        message.success("Loan rejected successfully");
        setSubmitStageBar();
        closeForm();
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item name="standard_reason" label="Standard Reasons"
            rules={[
              {
                required: true,
                message: 'Please select recommend to'
              }
            ]}>
            <Select
              mode="single"
              placeholder="Please select"
              allowClear
              className="initiation_form_select_width"
              showSearch
              onChange={handleStandardReasonChange}
              filterOption={(input, option) =>
                option.children
                  ? option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                  : false
              }

            >
              {standardReasons?.map((reason) => {
                return (
                  <Select.Option key={reason.id} value={reason.code}>
                    {reason.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item name="sub_reason" label="Sub Reasons"
            rules={[
              {
                required: true,
                message: 'Please select recommend to'
              }
            ]}>
            <Select
              mode="single"
              placeholder="Please select"
              allowClear
              className="initiation_form_select_width"
              showSearch
              filterOption={(input, option) =>
                option.children
                  ? option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                  : false
              }

            >
              {subReasons?.map((reason) => {
                return (
                  <Select.Option key={reason.id} value={reason.code}>
                    {reason.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Space
        direction="horizontal"
        align="center"
        className='initiate_button'
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>

        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default RejectForm;
