export const customTheme = {
  components: {
    Table: {
      headerBg: "#F1F1F1",
      padding: 10
    },
    Pagination: {
      borderRadius: 2,
    },
    Button: {
      borderRadius: 4,
      "colorPrimary": "rgb(39, 101, 143)",
      "colorText": "rgb(39, 101, 143)",
      "colorPrimaryHover": "rgb(39, 101, 143)"
    },
    "Tabs": {
      "itemSelectedColor": "rgb(39, 101, 143)",
      "itemHoverColor": "rgb(39, 101, 143)",
    },

  },
};
