import { message, Skeleton, Drawer, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "../styles/incomeDetails.css";
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { getCAMDetailsCustomerId } from '../services/incomeDetailsServices';
// import EditButton from '../../../../../utils/editButton/EditButton';
import Header from '../../../../layout/views/Header';
import LTVCalculationForm from './LTVCalculationForm';
// import { useSelector } from 'react-redux';
// import ConditionalRender from '../../../../../utils/authorization/AuthorizeComponent';
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import IndianNumberFormat from '../../../../../utils/indianNumberFormat/IndianNumberFormat';


const LTVCalculation = ({ refreshTableData, refreshLTVData }) => {
    const { encrypted_loan_id } = useParams();
    const [getLTVCalculation, setLTVCalculation] = useState([]);
    const [loading, setLoading] = useState(true); // State to track loading
    const [selectedRecord, setSelectedRecord] = useState();
    const [open, setOpen] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    // const { user_data } = useSelector((state) => state.user);
    const [collapseSections, setCollapseSections] = useState(true);
    let loan_id = null;

    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }
    // const showDrawer = () => {
    //     setOpen(true);
    // };
    const onClose = () => {
        setOpen(false);
        setSelectedRecord(null);
    };
    const toggleRefreshTableData = () => {
        setRefreshData((prev) => !prev);
    };


    useEffect(() => {
        const fetchLTVCalculation = async () => {
            try {
                const response = await getCAMDetailsCustomerId(loan_id);
                if (response?.data) {
                    setLTVCalculation(response.data);
                } else {
                    setLTVCalculation([]);  // Set as empty array if no customers exist
                }
            } catch (error) {
                message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
            }
            finally {
                setLoading(false); // Stop loading regardless of success or error
            }
        };

        fetchLTVCalculation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTableData, refreshData, loan_id, refreshLTVData]);

    // const renderEditButton = (showDrawer, id,) => (
    //     <div className="tab_form_sub_edit" onClick={() => {
    //         setSelectedRecord(id);
    //         showDrawer();
    //     }}>
    //         <EditButton />
    //     </div>
    // );


    const parameterLabels = [
        { key: "total_obligation", label: "Total Obligation" },
        { key: "total_monthly_income", label: "Total Monthly Income" },
        { key: "total_max_emi", label: "Total Max EMI" },
        { key: "total_emi_factor", label: "Total EMI Factor" },
        { key: "total_eligibility", label: "Total Eligibility" },
        { key: "ltv", label: "LTV" }
    ];

    const tableData = parameterLabels.map((param, index) => ({
        parameter: param.label,
        as_per_policy: getLTVCalculation?.actual_policy?.[param.key] || "-",
        as_per_actual: getLTVCalculation?.as_per_as_actual?.[param.key] || "-"
    }));

    const LTVColumns = [


        {
            title: 'Parameters',
            dataIndex: 'parameter',
            key: 'parameter',
            fixed: 'left',
        },
        {
            title: 'As Per Policy',
            dataIndex: 'as_per_policy',
            render: (text, record) => {
                if (record.parameter === "LTV") {
                    if (text === "-") {
                        return 0;
                    }
                    return text;
                }
                if (text === null || text === undefined) {
                    return "-"; // Replace only null or undefined, not 0
                }
               
                return `₹ ${IndianNumberFormat(text)}`;
            },
        },
        {
            title: 'As per Actual',
            dataIndex: 'as_per_actual',
            render: (text, record) => {
                if (record.parameter === "LTV") {
                    if (text === "-") {
                        return 0;
                    }
                    return text;
                }
                if (text === null || text === undefined) {
                    return "-";
                }

                return `₹ ${IndianNumberFormat(text)}`;
            },

        },

    ];

    return (
        <div className="income_calculations_container">
            <Drawer
                title={
                    <Header
                        title={selectedRecord ? "Edit" : "Add"}
                        onClose={onClose}
                        name="LTV Calculations"
                    />
                }
                width={720}
                onClose={onClose}
                open={open}
                closable={false}
            >
                <LTVCalculationForm
                    refreshData={toggleRefreshTableData}
                    id={selectedRecord && selectedRecord}
                    open={open}
                    closeForm={onClose}
                />
            </Drawer>
            <div className="ltv_calculation_heading_text">LTV Calculations
                <div style={{ display: 'flex', gap: "10px" }}>
                    {/* {getLTVCalculation && Object.keys(getLTVCalculation)?.length > 0 && (
                        <ConditionalRender
                            userPermissions={user_data?.permissions}
                            requiredPermissions={["EDITLTV1"]}
                        >
                            {renderEditButton(showDrawer, getLTVCalculation?.id)}
                        </ConditionalRender>
                    )} */}
                    {collapseSections ? (
                        <UpOutlined
                            onClick={() => setCollapseSections(!collapseSections)}
                        />
                    ) : (
                        <DownOutlined
                            onClick={() => setCollapseSections(!collapseSections)}
                        />
                    )}
                </div>
            </div>
            {loading ? (
                <Skeleton active paragraph={{ rows: 10 }} />
            ) : (
                <Table
                    className="insurance_table"
                    columns={LTVColumns}
                    dataSource={tableData}
                    pagination={false}
                    rowKey="key"

                />
            )}
        </div>
    );
};

export default LTVCalculation;
