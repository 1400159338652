import { Button, DatePicker, Form, Input, Modal, Select, Space, Table, message } from 'antd'
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import { deleteEMIEqualiserDetailsByIdService, createRentalFormCam, getRentalDataByCustomerId, listPropertyType, getAllMunicipalTaxPaidByService, getAllMaintencePaidByService, getAllGSTPaidByService, getAllRentalModeService } from '../services/incomeDetailsServices';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import moment from "moment";
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import dayjs from "dayjs";

const PureRentalForm = ({ closeForm, open, refreshData, id, selectedScheme, selectedIncome, selectedIncomeConsidered }) => {
    const [form] = Form.useForm();
    const { encrypted_loan_id } = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [allPropertyType, setAllPropertyType] = useState([]);
    const [maintenancePaidBy, setMaintenancePaidBy] = useState([]);
    const [municipalPaidBy, setMunicipalPaidBy] = useState([]);
    const [GSTPaidBy, setGSTPaidBy] = useState([]);
    const [rentalMode, setRentalMode] = useState([]);

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }
    const disabledDate = (current) => {
        // Disable dates after today
        return current && current > moment().endOf("day");
    };

    const handleNumberFields = (e, field) => {
        const newValue = e?.target?.value?.replace(/[^\d.]/g, "");
        form.setFieldsValue({ [field]: newValue });
    };
    const handleChange = (namePath, value) => {
        form.setFieldsValue({ [namePath]: value });
    };

    useEffect(() => {
        const getAllPropertyType = async () => {
            try {
                const response = await listPropertyType();
                setAllPropertyType(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        const getAllMunicipalTaxPaidBy = async () => {
            try {
                const response = await getAllMunicipalTaxPaidByService();
                setMunicipalPaidBy(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        const getAllMaintencePaidBy = async () => {
            try {
                const response = await getAllMaintencePaidByService();
                setMaintenancePaidBy(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        const getAllGSTPaidBy = async () => {
            try {
                const response = await getAllGSTPaidByService();
                setGSTPaidBy(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        const getAllRentalMode = async () => {
            try {
                const response = await getAllRentalModeService();
                setRentalMode(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        getAllRentalMode();
        getAllPropertyType();
        getAllMunicipalTaxPaidBy();
        getAllMaintencePaidBy();
        getAllGSTPaidBy();
    }, []);

    const getCashData = async () => {
        if (id) {
            try {
                const response = await getRentalDataByCustomerId(id);
                let { rental } = response?.data;

                form.setFieldsValue({
                    salaryDetails: rental?.length > 0 ? rental.map(item => ({
                        id: item?.id,
                        property_type: item?.property_type.id,
                        year_of_start: item?.year_of_start ? dayjs().year(item?.year_of_start).startOf("year") : null,
                        gross_income: item?.gross_income,
                        net_income: item?.net_income,
                        lessee_name: item?.lessee_name,
                        date_of_agreement: item?.date_of_agreement ? dayjs(item?.date_of_agreement, "DD-MM-YYYY") : null,
                        maintainance_paid_by: item?.maintainance_paid_by,
                        municipal_tax_paid_by: item?.municipal_tax_paid_by,
                        gst_paid_by: item?.gst_paid_by,
                        rental_mode: item?.rental_mode,
                    })) : [{
                        property_type: null,
                        year_of_start: null,
                        gross_income: null,
                        net_income: null,
                        lessee_name: null,
                        date_of_agreement: null,
                        maintainance_paid_by: null,
                        municipal_tax_paid_by: null,
                        gst_paid_by: null,
                        rental_mode: null,
                    }]
                });
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        }

    };

    useEffect(() => {
        if (open) {
            getCashData();

        } else {
            form.resetFields();
            // setPreviewData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id]);

    // const handlePreview = async () => {
    //     try {
    //         const values = await form.validateFields();
    //         const payload = {
    //             customer_detail: id,
    //             loan_detail: loan_id,
    //             scheme_detail: selectedScheme,
    //             data: values.salaryDetails,
    //         };

    //         const response = await IncomeCalculationPost(payload);
    //         if (response.status === 200 && response.success) {
    //             setPreviewData(response.data);
    //             // message.success("Preview data sent successfully");
    //         }
    //     } catch (error) {
    //         message.error("Please fill required field");
    //     }
    // };

    const handleDelete = async () => {
        try {
            // Call the service to delete the record from the backend
            const response = await deleteEMIEqualiserDetailsByIdService(loan_id, id, selectedScheme, selectedRecord.id);
            if (response.status === 200 && response.success === true) {
                message.success("Record deleted successfully");
                setOpenModal(false);
                // getCashData();
                refreshData();
            }
        } catch (error) {
            setOpenModal(false);
            message.error(error.response.data.message);
        }
    };
    const handleSubmit = async (values) => {
        try {
            const payload = {
                customer_detail: id,
                loan_detail: loan_id,
                scheme_detail: selectedScheme,
                data: values.salaryDetails.map(item => ({
                    ...item,
                    // net_income: item.net_income = parseFloat(values.net_income, 10),
                    year_of_start: item.year_of_start ? (item.year_of_start).format("YYYY") : null,
                    date_of_agreement: item.date_of_agreement ? (item.date_of_agreement).format("YYYY-MM-DD") : null
                })),
                // data: values.salaryDetails,
                income_considered: values.salaryDetails.every(item => item.id === undefined)
                    ? true
                    : (selectedIncome !== null ? selectedIncome : selectedIncomeConsidered)
                // income_considered: values.salaryDetails.find(item => item.id === undefined) ? true : selectedIncome
            };

            const response = await createRentalFormCam(payload);
            if ((response.status = 200 && response.success)) {
                message.success("Rental details successfully created");
                form.resetFields();
                closeForm();
                refreshData();
            }
        }
        catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };
    return (
        <div>
            <Form
                form={form}
                name="salary-details-form"
                layout="vertical"
                initialValues={{
                    salaryDetails: [{
                        // property_type: "",
                        year_of_start: "",
                        gross_income: "",
                        net_income: "",
                        lessee_name: "",
                        date_of_agreement: "",
                    }],
                }}
                onFinish={handleSubmit}
            >

                <Form.List name="salaryDetails">
                    {(fields, { add, remove }) => (
                        <>
                            <Table
                                columns={[
                                    {
                                        title: 'SR.NO',
                                        dataIndex: 'key',
                                        key: 'key',
                                        fixed: "left",
                                        render: (text, record, index) => <span>{index + 1}</span>,
                                    },
                                    {
                                        title: 'Property Type',
                                        dataIndex: 'property_type',
                                        key: 'property_type',
                                        width: 150,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'property_type']}
                                                rules={[{ required: true, message: 'Please select' }]}
                                            >
                                                <Select
                                                    placeholder="Select Select"
                                                    onChange={(value) => handleChange(['salaryDetails', index, 'property_type'], value)}
                                                    allowClear
                                                    style={{ width: '200px' }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {allPropertyType?.map((investment) => (
                                                        <Select.Option key={investment.id} value={investment.id}>
                                                            {investment.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Year Of Start (In Year)',
                                        dataIndex: 'year_of_start',
                                        key: 'year_of_start',
                                        width: 200,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'year_of_start']}
                                                rules={[
                                                    { required: true, message: "Year of start is required" },
                                                ]}
                                            >
                                                <DatePicker
                                                    picker="year"
                                                    disabledDate={disabledDate}
                                                    placeholder="Select Date"
                                                    // format="DD-MM-YYYY"
                                                    className="select_field_tab_width"

                                                />

                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Gross Rent',
                                        dataIndex: 'gross_income',
                                        key: 'gross_income',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'gross_income']}
                                                rules={[{ required: true, message: 'Gross rent is required' }]}
                                            >
                                                <Input
                                                    placeholder="Please enter sanctioned amoun"
                                                    onChange={(e) => {
                                                        handleChange(['salaryDetails', index, 'gross_income'], e.target.value);
                                                        handleNumberFields(e, "gross_income");
                                                    }}

                                                />

                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Net Rent',
                                        dataIndex: 'net_income',
                                        key: 'net_income',
                                        width: 200,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'net_income']}
                                                rules={[
                                                    { required: true, message: "Outstanding amount is required" },

                                                ]}
                                            >
                                                <Input
                                                    placeholder="Please enter outstanding amount"
                                                    onChange={(e) => {
                                                        handleChange(['salaryDetails', index, 'net_income'], e.target.value);
                                                        handleNumberFields(e, "net_income");
                                                    }}
                                                />
                                            </Form.Item>
                                        ),
                                    },


                                    {
                                        title: 'Lessee Name',
                                        dataIndex: 'lessee_name',
                                        key: 'lessee_name',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'lessee_name']}
                                                rules={[
                                                    { required: true, message: "EMI amount is required" },
                                                    {
                                                        validator: (_, value) => {
                                                            const { sanctioned_amount, outstanding_amount } = form.getFieldsValue(["sanctioned_amount", "outstanding_amount"]);
                                                            if (!value) {
                                                                return Promise.resolve();
                                                            }
                                                            const emi = parseFloat(value);
                                                            if (emi === 0) {
                                                                return Promise.reject("EMI amount cannot be zero");
                                                            }
                                                            if (emi >= parseFloat(sanctioned_amount) || emi > parseFloat(outstanding_amount)) {
                                                                return Promise.reject("EMI amount must be less than or eqaul to both the sanctioned and outstanding amount");
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Please enter EMI amount"
                                                    onChange={(e) => {
                                                        handleChange(['salaryDetails', index, 'lessee_name'], e.target.value);
                                                        handleNumberFields(e, "lessee_name");
                                                    }}
                                                />

                                            </Form.Item>
                                        ),
                                    },

                                    {
                                        title: 'Maturity Date',
                                        dataIndex: 'date_of_agreement',
                                        key: 'date_of_agreement',
                                        width: 300,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'date_of_agreement']}
                                                rules={[
                                                    { required: true, message: "Maturity date is required" },
                                                ]}
                                            >
                                                <DatePicker
                                                    style={{ width: "100%" }}
                                                    disabledDate={(current) => current && current > moment().endOf("day")}
                                                    format="DD-MM-YYYY"
                                                    className="select_esutsting"
                                                    placeholder="Select Date"
                                                    onChange={(date, dateString) => handleChange(['salaryDetails', index, 'date_of_agreement'], dateString)}
                                                />
                                            </Form.Item>
                                        ),
                                    },

                                    {
                                        title: 'Maintainance Paid By',
                                        dataIndex: 'maintainance_paid_by',
                                        key: 'maintainance_paid_by',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'maintainance_paid_by']}
                                                rules={[{ required: true, message: 'Enter agricultural income' }]}
                                            >
                                                <Select
                                                    placeholder="Select Select"
                                                    onChange={(value) => handleChange(['salaryDetails', index, 'maintainance_paid_by'], value)}
                                                    allowClear
                                                    style={{ width: '200px' }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {maintenancePaidBy?.map((type) => (
                                                        <Select.Option key={type} value={type}>
                                                            {type}
                                                        </Select.Option>
                                                    ))}                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Municipal Tax Paid By',
                                        dataIndex: 'municipal_tax_paid_by',
                                        key: 'municipal_tax_paid_by',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'municipal_tax_paid_by']}
                                                rules={[{ required: true, message: 'Enter agricultural income' }]}
                                            >
                                                <Select
                                                    placeholder="Select Select"
                                                    // onChange={(value) => handleChange(['salaryDetails', index, 'municipal_tax_paid_by'], value)}
                                                    // value={considerObligation}
                                                    onChange={(value) => {
                                                        handleChange(['salaryDetails', index, 'municipal_tax_paid_by'], value);
                                                        // handleConsiderObligationChange(value);
                                                    }}
                                                    allowClear
                                                    style={{ width: '200px' }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {municipalPaidBy?.map((type) => (
                                                        <Select.Option key={type} value={type}>
                                                            {type}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'GST Paid By',
                                        dataIndex: 'gst_paid_by',
                                        key: 'gst_paid_by',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'gst_paid_by']}
                                                rules={[{ required: true, message: 'Enter agricultural income' }]}
                                            >
                                                <Select
                                                    placeholder="Select Select"
                                                    onChange={(value) => handleChange(['salaryDetails', index, 'gst_paid_by'], value)}
                                                    allowClear
                                                    style={{ width: '200px' }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {GSTPaidBy?.map((type) => (
                                                        <Select.Option key={type} value={type}>
                                                            {type}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Rental Mode',
                                        dataIndex: 'rental_mode',
                                        key: 'rental_mode',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'rental_mode']}
                                                rules={[{ required: true, message: 'Enter agricultural income' }]}
                                            >
                                                <Select
                                                    placeholder="Select Select"
                                                    onChange={(value) => handleChange(['salaryDetails', index, 'rental_mode'], value)}
                                                    allowClear
                                                    style={{ width: '200px' }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {rentalMode?.map((mode) => (
                                                        <Select.Option key={mode} value={mode}>
                                                            {mode}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Action',
                                        key: 'action',
                                        fixed: "right",
                                        render: (_, record, index) => {
                                            // Retrieve the actual value of the current salary detail using `form.getFieldValue`
                                            const salaryValues = form.getFieldValue('salaryDetails') || []; // Ensure there's a default value
                                            const currentDetail = salaryValues[index] || {}; // Get the current salary detail values


                                            return (
                                                <Form.Item>
                                                    <DeleteOutlined
                                                        style={{ color: 'red' }}
                                                        onClick={() => {
                                                            if (currentDetail?.id) {
                                                                setOpenModal(true);
                                                                setSelectedRecord(currentDetail)

                                                            } else {
                                                                remove(index);
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            );
                                        },

                                    },
                                ]}
                                dataSource={fields}
                                rowKey="key"
                                pagination={false}
                                scroll={{ x: true }}
                            />

                            <Button type="primary"
                                onClick={() => add()}
                                icon={<PlusCircleOutlined />}
                                style={{ marginTop: "10px" }} >
                                Month
                            </Button>
                        </>
                    )}
                </Form.List>

                <Space
                    direction="horizontal"
                    align="center"
                    style={{
                        display: "flex", flexDirection: "row-reverse",
                        marginBottom: "20px"
                    }}
                >
                   <Button type="primary" htmlType="submit">
                        Submit
                    </Button>

                    <Button onClick={closeForm}>Cancel</Button>
                </Space>
            
            </Form>
            <Modal
                title="Confirm Delete"
                open={openModal}
                onOk={() => {
                    setOpenModal(false);
                    handleDelete();
                }}
                onCancel={() => setOpenModal(false)}
                okType="danger"
            >
                <p>{`Are you sure you want to delete?`}</p>
            </Modal>
        </div>
    )
}

export default PureRentalForm