import React, { useEffect, useState } from "react";
import {
  Select,
  TreeSelect,
  Button,
  Space,
  message,
  Col,
  Row,
  Typography,
} from "antd";
import {
  getAllPermissionsFormatted,
  getAllRolesFormatted,
  getAllUserAssignedRolesAndPermissions,
  updateUserPermissionsDetailsServicebyId,
  updateUserRolesDetailsServicebyId,
} from "../services/userService";
import Loading from "../../../utils/loading/Loading";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const RolesPermissionsForm = ({
  open,
  onCancel,
  closeDrawer,
  id,
  onNext,
  userData,
  currentRoute,
}) => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    onCancel();
  };

  // Function to get all permissions for given roles
  const getPermissionsForRoles = (roleIds) => {
    const permissionSet = new Set();

    roleIds.forEach((roleId) => {
      const role = roles.find((r) => r.id === roleId);
      if (role && role.permissions) {
        role.permissions.forEach((permission) => permissionSet.add(permission));
      }
    });
    return Array.from(permissionSet);
  };

  // Convert permission IDs to tree-friendly format
  const formatPermissionsForTree = (permissionIds) => {
    // if (permissions.length !== 0 && permissionIds.length !== 0) return [];
    const mixedFormatPermissions = [];
    const remainingPermissions = [...permissionIds];

    permissions.forEach((module) => {
      const modulePermissionIds = module.permissions.map((p) => p.id);

      if (
        modulePermissionIds.every((id) => remainingPermissions.includes(id))
      ) {
        if (modulePermissionIds.length > 0) {
          mixedFormatPermissions.push(module.module_name);
        }

        modulePermissionIds.forEach((id) => {
          const index = remainingPermissions.indexOf(id);
          if (index !== -1) {
            remainingPermissions.splice(index, 1);
          }
        });
      } else {
        // If not all permissions in module are selected, add individual permissions
        module.permissions.forEach((permission) => {
          if (remainingPermissions.includes(permission.id)) {
            mixedFormatPermissions.push(permission.id);
            const index = remainingPermissions.indexOf(permission.id);
            if (index !== -1) {
              remainingPermissions.splice(index, 1);
            }
          }
        });
      }
    });

    // Add any remaining permissions
    mixedFormatPermissions.push(...remainingPermissions);
    return mixedFormatPermissions;
  };

  const handleRoleChange = (newRoleIds) => {
    setSelectedRoles(newRoleIds);
    // If no roles selected, clear all permissions
    if (newRoleIds.length === 0) {
      setSelectedPermissions([]);
      return;
    }
    // Get all permissions from the selected roles
    const rolePermissions = getPermissionsForRoles(newRoleIds);
    // Update the permissions tree
    const formattedPermissions = formatPermissionsForTree(rolePermissions);
    setSelectedPermissions(formattedPermissions);
  };

  const handlePermissionChange = (updatedPermissions) => {
    console.log("selectedPermissions", selectedPermissions);
    // Step 1: Identify removed permissions
    const removedPermissions = selectedPermissions.filter(
      (p) => !updatedPermissions.includes(p)
    );

    console.log("updatedPermissions", removedPermissions, selectedRoles);

    // Step 2: Remove roles that have ANY of the removed permissions
    let updatedRoles = selectedRoles.filter((roleId) => {
      const role = roles.find((r) => r.id === roleId);
      console.log("role2111", role);

      if (role) {
        // If role has ANY of the removed permissions, it should be removed
        const hasRemovedPermission = role.permissions.some((p) =>
          removedPermissions.includes(p)
        );
        console.log("13111", hasRemovedPermission);
        return !hasRemovedPermission; // Remove role if it has any removed permission
      }
      return true;
    });
    console.log("roles", roles);
    console.log("updatedRoles", updatedRoles);

    // Step 3: Update state
    setSelectedRoles(updatedRoles);
    setSelectedPermissions(updatedPermissions);
  };

  const renderPermissionTreeNodes = (modules) => {
    return modules.map((module) => (
      <TreeNode
        key={module.module_name}
        title={module.module_name}
        value={module.module_name}
      >
        {module.permissions.map((permission) => (
          <TreeNode
            key={permission.id}
            title={permission.display_name}
            value={permission.id}
          />
        ))}
      </TreeNode>
    ));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (currentRoute === "/user_profile") {
          const rolesResponse = await getAllRolesFormatted();
          setRoles(rolesResponse.data);
          const userRoles = userData.roles.map((item) => item.id);
          setSelectedRoles(userRoles);

          // Get permissions from roles
          const rolePermissions = userRoles.reduce((acc, roleId) => {
            const role = rolesResponse.data.find((r) => r.id === roleId);
            return role ? [...acc, ...(role.permissions || [])] : acc;
          }, []);

          setSelectedPermissions(rolePermissions);
          return;
        }

        if (id !== null) {
          const [permissionsResponse, rolesResponse, userDataResponse] =
            await Promise.all([
              getAllPermissionsFormatted(),
              getAllRolesFormatted(),
              getAllUserAssignedRolesAndPermissions(id),
            ]);

          console.log("185", userDataResponse);

          setPermissions(permissionsResponse.data);
          setRoles(rolesResponse.data);

          if (userDataResponse.data) {
            const userRoles = userDataResponse.data.roles_list;
            setSelectedRoles(userRoles);

            // Get initial permissions from roles
            const rolePermissions = userRoles.reduce((acc, roleId) => {
              const role = rolesResponse.data.find((r) => r.id === roleId);
              return role ? [...acc, ...(role.permissions || [])] : acc;
            }, []);

            console.log("198", userRoles);

            // Check if permission_list is not empty
            const userPermissions = userDataResponse.data.permissions_list;
            const finalPermissions =
              userPermissions.length > 0 ? userPermissions : rolePermissions;

            // Format permissions for tree display
            const formattedPermissions =
              formatPermissionsForTree(finalPermissions);
            setSelectedPermissions(formattedPermissions);
          }
        }
      } catch (error) {
        if (currentRoute !== "/user_profile") {
          message.error("Failed to fetch data.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentRoute, userData]);

  const handleSubmit = async () => {
    if (currentRoute === "/user_profile") {
      onNext();
      return;
    }
    try {
      const rolePermissions = getPermissionsForRoles(selectedRoles);
      const selectedPermissionIds = selectedPermissions
        // .filter(permission => typeof permission === "number")
        .filter((permission) => !rolePermissions.includes(permission));

      await Promise.all([
        updateUserRolesDetailsServicebyId(id, selectedRoles),
        updateUserPermissionsDetailsServicebyId(id, selectedPermissionIds),
      ]);

      message.success("Roles and Permissions updated successfully!");
      onNext();
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  console.log("253", selectedPermissions);

  return (
    <div>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div>
          <Row gutter={16}>
            <Col span={12}>
              <div>
                <Typography>Roles</Typography>
                <Select
                  mode="multiple"
                  placeholder="Select Roles"
                  style={{ width: "100%" }}
                  onChange={handleRoleChange}
                  value={selectedRoles}
                  showSearch
                  filterOption={filterOption}
                  optionFilterProp="children"
                  allowClear
                  disabled={currentRoute === "/user_profile"}
                >
                  {roles.map((role) => (
                    <Option key={role.id} value={role.id}>
                      {role.display_name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col span={12}>
              <div>
                <Typography>Permissions</Typography>
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  value={selectedPermissions}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Select Permissions"
                  allowClear
                  treeDefaultExpandAll
                  treeCheckable
                  showCheckedStrategy="SHOW_PARENT"
                  onChange={handlePermissionChange}
                  disabled={currentRoute === "/user_profile"}
                >
                  {renderPermissionTreeNodes(permissions)}
                </TreeSelect>
              </div>
            </Col>
          </Row>
          {(currentRoute === "/users" || currentRoute === "/master") && (
            <Space
              direction="horizontal"
              align="center"
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "1rem",
              }}
            >
              <Button type="primary" onClick={handleSubmit}>
                Next
              </Button>
              <Button onClick={handleCancel}>Prev</Button>
              <Button onClick={closeDrawer}>Cancel</Button>
            </Space>
          )}
        </div>
      )}
    </div>
  );
};

export default RolesPermissionsForm;
