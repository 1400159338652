import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form, Select, Input, Button, Table, Space, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "../styles/incomeDetails.css";
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { createIncomeMultiplierFormCam, deleteIncomeMultipierAndEstimateByIdService, getIncomeMultiplierCustomerDataByCustomerId, IncomeCalculationPost, listFinancialYear } from '../services/incomeDetailsServices';
import IncomeCalculation from './IncomeCalculation';


const NormalIncomeAndIncomeEstimateForm = ({ closeForm, open, refreshData, id, selectedScheme, selectedIncome, selectedIncomeConsidered }) => {
    const [form] = Form.useForm();
    // const [customerList, setCustomerList] = useState([]);
    // const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [allMonthYear, setAllMonthYear] = useState([]);
    // const [getCustomerId, setCustomerId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [previewData, setPreviewData] = useState(null);

    const handleChange = (namePath, value) => {
        form.setFieldsValue({ [namePath]: value });
    };
    const { encrypted_loan_id } = useParams();

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        // const fetchCustomersByLoanId = async () => {
        //     try {
        //         const response = await getCustomersByLoanId(loan_id);
        //         const customers = response?.data?.customer_list?.map((customer) => {
        //             return {
        //                 id: customer.id,
        //                 name: `${customer.name} (${customer.customer_type})`,
        //             };
        //         });
        //         setCustomerList(customers);

        //         // setLoading(false);
        //     } catch (error) {
        //         // setLoading(false);
        //         message.error(
        //             ErrorMessage(error?.response?.status, error?.response?.data?.message)
        //         );
        //     }
        // };
        const getMonthYear = async () => {
            try {
                const response = await listFinancialYear();
                setAllMonthYear(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        // fetchCustomersByLoanId();
        getMonthYear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    // const handleCustomerchange = (value) => {
    //     setSelectedCustomer(value);
    // };


    const handleSubmit = async (values) => {
        try {

            // if (id) {
            //     const payload = {
            //         // customer_detail: getCustomerId,
            //         loan_detail: loan_id,
            //         data: values.salaryDetails.map((item) => ({
            //             id: item.id,
            //             financial_year: item.financial_year,
            //             profit_after_tax: item.profit_after_tax,
            //             depreciation: item.depreciation,
            //             interest_on_loans: item.interest_on_loans,
            //             interest_paid_to_family_members: item.interest_paid_to_family_members,
            //             rental_income_cash: item.rental_income_cash,
            //             rental_income_non_cash: item.rental_income_non_cash,
            //             dividend_or_interest: item.dividend_or_interest,
            //             agricultural_income: item.agricultural_income,
            //         })),
            //     };
            //     const response = await updateIncomeFormByCustomerId(payload);
            //     if ((response.status = 200 && response.success)) {
            //         message.success("Income details of non cash successfully updated");
            //         form.resetFields();
            //         refreshData();
            //         closeForm();
            //     }
            // } else {
            const payload = {
                loan_detail: loan_id,
                customer_detail: id,
                data: values.salaryDetails,
                scheme_detail: selectedScheme,
                income_considered: values.salaryDetails.every(item => item.id === undefined)
                    ? true
                    : (selectedIncome !== null ? selectedIncome : selectedIncomeConsidered)

            };

            const response = await createIncomeMultiplierFormCam(payload);
            if ((response.status = 200 && response.success)) {
                message.success("Income estimation details successfully created");
                form.resetFields();
                closeForm();
                refreshData();
            }
        }


        // }
        catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };

    const getIncomeData = async () => {
        if (id) {
            try {
                const response = await getIncomeMultiplierCustomerDataByCustomerId(id);
                let { customer_detail, data } = response?.data;
                // setCustomerId(customer_detail?.customer_id)
                // const customerName = getCustomerNameById(customer_detail);
                form.setFieldsValue({
                    customer: customer_detail?.customer_id,
                    salaryDetails: data?.length > 0 ? data?.map(item => ({
                        id: item?.id,
                        financial_year: item?.financial_year,
                        profit_after_tax: item?.profit_after_tax,
                        depreciation: item?.depreciation,
                        interest_on_loans: item?.interest_on_loans,
                        interest_paid_to_family_members: item?.interest_paid_to_family_members,
                        rental_income_cash: item?.rental_income_cash,
                        rental_income_non_cash: item?.rental_income_non_cash,
                        dividend_or_interest: item?.dividend_or_interest,
                        agricultural_income: item?.agricultural_income,
                    })) : [{
                        financial_year: null,
                        profit_after_tax: null,
                        depreciation: null,
                        interest_on_loans: null,
                        interest_paid_to_family_members: null,
                        rental_income_cash: null,
                        rental_income_non_cash: null,
                        dividend_or_interest: null,
                        agricultural_income: null,
                    }]

                });
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        }
    };

    const handleDelete = async () => {
        try {
            // Call the service to delete the record from the backend
            const deleteIncome = 'True';
            const response = await deleteIncomeMultipierAndEstimateByIdService(selectedRecord.id, selectedScheme, deleteIncome);
            if (response.status === 200 && response.success === true) {
                message.success("Record deleted successfully");
                setOpenModal(false);
                getIncomeData();
                refreshData();
            }
        } catch (error) {
            setOpenModal(false);
            message.error(error.response.data.message);
        }
    };
    useEffect(() => {
        if (open) {
            getIncomeData();

        } else {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id]);

    const handlePreview = async () => {
        try {
            const values = await form.validateFields();
            const payload = {
                customer_detail: id,
                loan_detail: loan_id,
                scheme_detail: selectedScheme,
                data: values.salaryDetails,
            };

            const response = await IncomeCalculationPost(payload);
            if (response.status === 200 && response.success) {
                setPreviewData(response.data);
                // message.success("Preview data sent successfully");
            }
        } catch (error) {
            message.error("Please fill required field");
        }
    };
    return (
        <>
            <Form
                form={form}
                name="salary-details-form"
                layout="vertical"
                initialValues={{
                    salaryDetails: [{
                        financial_year: null,
                        profit_after_tax: null,
                        depreciation: null,
                        interest_on_loans: null,
                        interest_paid_to_family_members: null,
                        rental_income_cash: null,
                        rental_income_non_cash: null,
                        dividend_or_interest: null,
                        agricultural_income: null
                    }],
                }}
                onFinish={handleSubmit}
            >
                {/* <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="customer" label="Customer" required
                         rules={[{ required: true, message: 'Please select a customer' }]}>
                            <Select
                                value={selectedCustomer}
                                onChange={handleCustomerchange}
                                style={{ width: "300px" }}
                                placeholder="Please Select Customer"
                                disabled={!!id}
                            >
                                {customerList.length > 0 &&
                                    customerList.map((customer) => {
                                        return (
                                            <Select.Option value={customer.id}>{customer.name}</Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row> */}
                <Form.List name="salaryDetails">
                    {(fields, { add, remove }) => (
                        <>
                            <Table
                                columns={[
                                    {
                                        title: 'SR.NO',
                                        dataIndex: 'key',
                                        key: 'key',
                                        fixed: "left",
                                        render: (text, record, index) => <span>{index + 1}</span>,
                                    },
                                    {
                                        title: 'F.Y',
                                        dataIndex: 'financial_year',
                                        key: 'financial_year',
                                        width: 150,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'financial_year']}
                                                rules={[{ required: true, message: 'Please select' }]}
                                            >
                                                <Select
                                                    placeholder="Search to Select"
                                                    onChange={(value) => handleChange(['salaryDetails', index, 'fy'], value)}
                                                    style={{ width: '120px' }}
                                                    allowClear
                                                    showSearch
                                                >
                                                    {allMonthYear?.map((branch) => (
                                                        <Select.Option key={branch} value={branch}>
                                                            {branch}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Profit After Tax  - APPLICANT',
                                        dataIndex: 'profit_after_tax',
                                        key: 'profit_after_tax',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'profit_after_tax']}
                                                rules={[{ required: true, message: 'Please Enter Turnover' }]}
                                            >
                                                <Input
                                                    placeholder="Enter Turnover"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'monthly_net_salary_non_cash'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Depreciation',
                                        dataIndex: 'depreciation',
                                        key: 'depreciation',
                                        width: 150,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'depreciation']}
                                                rules={[{ required: true, message: 'Enter OT/Variable' }]}
                                            >
                                                <Input
                                                    placeholder="Enter PBT"
                                                    style={{ width: '120px' }}
                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Interest Of Loan Emi',
                                        dataIndex: 'interest_on_loans',
                                        key: 'interest_on_loans',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'interest_on_loans']}
                                                rules={[{ required: true, message: 'Enter OT/Variable' }]}
                                            >
                                                <Input
                                                    placeholder="Enter Tax Paid"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: (
                                            <div>
                                                Interest paid to family members - <br />
                                                provided they are on loan structure
                                            </div>
                                        ),
                                        // title: 'Interest paid to family members - provided they are on loan structure ',
                                        dataIndex: 'interest_paid_to_family_members',
                                        key: 'interest_paid_to_family_members',

                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'interest_paid_to_family_members']}
                                                rules={[{ required: true, message: 'Enter OT/Variable' }]}
                                            >
                                                <Input
                                                    placeholder="Enter Depreciation"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Rental Income - Cash',
                                        dataIndex: 'rental_income_cash',
                                        key: 'rental_income_cash',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'rental_income_cash']}
                                                rules={[{ required: true, message: 'Enter OT/Variable' }]}
                                            >
                                                <Input
                                                    placeholder="Enter Interst Paid on Loans"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Rental Income - Non Cash',
                                        dataIndex: 'rental_income_non_cash',
                                        key: 'rental_income_non_cash',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'rental_income_non_cash']}
                                                rules={[{ required: true, message: 'Enter OT/Variable' }]}
                                            >
                                                <Input
                                                    placeholder="Enter Interst Paid on Loans"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Dividend / Interest',
                                        dataIndex: 'dividend_or_interest',
                                        key: 'dividend_or_interest',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'dividend_or_interest']}
                                                rules={[{ required: true, message: 'Enter OT/Variable' }]}
                                            >
                                                <Input
                                                    placeholder="Enter Interst Paid on Loans"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Agriculture Income',
                                        dataIndex: 'agricultural_income',
                                        key: 'agricultural_income',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'agricultural_income']}
                                                rules={[{ required: true, message: 'Enter OT/Variable' }]}
                                            >
                                                <Input
                                                    placeholder="Enter Interst Paid on Loans"

                                                    onChange={(e) => handleChange(['salaryDetails', index, 'over_time_pay'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Action',
                                        key: 'action',
                                        fixed: "right",
                                        render: (_, record, index) => {
                                            // Retrieve the actual value of the current salary detail using `form.getFieldValue`
                                            const salaryValues = form.getFieldValue('salaryDetails') || []; // Ensure there's a default value
                                            const currentDetail = salaryValues[index] || {}; // Get the current salary detail values


                                            return (
                                                <DeleteOutlined
                                                    style={{ color: 'red' }}
                                                    onClick={() => {
                                                        if (currentDetail?.id) {
                                                            setOpenModal(true);
                                                            setSelectedRecord(currentDetail)

                                                        } else {
                                                            remove(index);
                                                        }
                                                    }}
                                                />
                                            );
                                        },

                                    },
                                ]}
                                dataSource={fields}
                                rowKey="key"
                                pagination={false}
                                scroll={{ x: true }}
                            />

                            <Button type="primary" onClick={() => add()}
                                icon={<PlusCircleOutlined />}
                                style={{ marginTop: "10px" }} >
                                Year
                            </Button>
                        </>
                    )}
                </Form.List>

                <Space
                    direction="horizontal"
                    align="center"
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                    <Button type="primary" onClick={handlePreview}>
                        Preview
                    </Button>
                    <Button onClick={closeForm}>Cancel</Button>
                </Space>
                {previewData && (
                    <IncomeCalculation
                        previewData={previewData}
                        schemeDisplayName={selectedScheme}
                        refreshTableData={refreshData}
                    />
                )}
                <Space
                    direction="horizontal"
                    align="center"
                    style={{
                        display: "flex", flexDirection: "row-reverse",
                        marginTop: "20px"
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Space>
            </Form>

            <Modal
                title="Confirm Delete"
                open={openModal}
                onOk={() => {
                    setOpenModal(false);
                    handleDelete();
                }}
                onCancel={() => setOpenModal(false)}
                okType="danger"
            >
                <p>{`Are you sure you want to delete?`}</p>
            </Modal>
        </>

    );
};

export default NormalIncomeAndIncomeEstimateForm;
