import React, { useState, useEffect } from 'react';
import '../../documentDetails/styles/documentstyle.css';
  import { Badge, Table, message } from 'antd';
import '../../trail/styles/trail.css';
import EditButton from '../../../../../utils/editButton/EditButton';
import { getvendorstageByIdService } from '../services/basicServices';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: 'Assigned To',
    dataIndex: 'Assigned_to',
    render: (text, record) => {
      return record?.vendor_stage_history?.agency
        || '-';
    },
  },
  {
    title: 'Forwarded To',
    dataIndex: 'Forwarded_To',
    render: (text, record) => {
      return record?.vendor_stage_history?.forwarded_to
        || '-';
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (_, record) => {
      let badgeColor = '';
      switch (record?.vendor_stage_history?.status) {
        case 'Initiated':
          badgeColor = "#2E90FA";
          break;
        case 'Success':
          badgeColor = '#389E0D';
          break;
        case 'Re-Initiated':
          badgeColor = '#2E90FA';
          break;
        case 'Vendor-Completed':
          badgeColor = '#5925DC';
          break;
        case 'Forward':
          badgeColor = '#C4320A';
          break;
        default:
          badgeColor = '#000000';
      }
      return (
        <Badge
          text={record?.vendor_stage_history?.status}
          color={badgeColor}
          className='technical_trail_status'
          style={{ color: badgeColor }}
        />
      );
    }
  },
  {
    title: 'Initiated Date',
    dataIndex: 'initiated_at',
    render: (text, record) => {
      return record?.vendor_stage_history?.initiated_at
        || '-';
    },
  },
  {
    title: 'Completion Date',
    dataIndex: 'completed',
    render: (text, record) => {
      return record?.vendor_stage_history?.completed
        || '-';
    },
  },
];



const Trail = ({ stage_id, technicalStageData }) => {
  const [listOfApplications, setListOfApplications] = useState([]);
  const isSendBack = technicalStageData?.stage_status?.display_name === "Send Back";
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getvendorstageByIdService(stage_id);
        setListOfApplications(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    if (stage_id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='main_basic_container'>
        <div className='tarsil_container'>
          <div className='document_details valuation_property_blue_heading'>
            <div className='technical_details_blue'>Vendor Stage Details</div>
            <div>
              {isSendBack &&
                <div className='basic_edit_container basic_button'>
                  <EditButton />
                </div>
              }
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={listOfApplications}
            pagination={false}
            rowKey="id"
          />
        </div>
      </div>
    </>
  );
};

export default Trail;
