import { axiosRequest } from "../utils/api/axiosRequest";
import baseConfig from "../config/baseConfig";

const notificationMiddleware = (store) => {
  let socket = null;
  let reconnectAttempts = 0;
  const MAX_RECONNECT_ATTEMPTS = 5;
  let isInitialized = false; // ✅ Track if WebSocket is initialized

  const token = localStorage.getItem("access");

  const fetchUnseenNotificationCount = async () => {
    try {
      const response = await axiosRequest.get(
        "/api/v1/communication/messages/unseen-notifications-count/"
      );

      store.dispatch({
        type: "notifications/setUnseenNotificationCount",
        payload: response.data.data || 0, // Set count or 0 if undefined
      });
    } catch (error) {
      console.error("❌ Failed to fetch unseen notifications count:", error);
      store.dispatch({
        type: "notifications/setUnseenNotificationCount",
        payload: 0,
      });
    }
  };

  const connectWebSocket = () => {
    // ✅ Prevent duplicate connections
    if (socket && socket.readyState !== WebSocket.CLOSED) {
      console.log("🔄 WebSocket already connected");
      return;
    }

    socket = new WebSocket(
      `${baseConfig.socketurlDashboard}dashboard/?Authorization=${token}`
    );

    socket.onopen = () => {
      console.log("🔔 Notification WebSocket Connected");
      reconnectAttempts = 0;
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("🔔 New Notification:", data);

      // ✅ Dispatch to update unread count
      store.dispatch({
        type: "notifications/incrementUnseenNotificationCount",
      });
    };

    socket.onerror = (error) => {
      console.error("❌ Notification WebSocket Error:", error);
    };

    socket.onclose = () => {
      console.log("⚠️ Notification WebSocket Disconnected");
      if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
        const retryTime = Math.min(5000, (reconnectAttempts + 1) * 1000);
        setTimeout(() => {
          reconnectAttempts++;
          console.log(`♻️ Reconnecting... Attempt ${reconnectAttempts}`);
          connectWebSocket();
        }, retryTime);
      } else {
        console.log("🚫 Max reconnect attempts reached.");
      }
    };
  };

  return (next) => async (action) => {
    if (action.type === "notifications/initWebSocket" && !isInitialized) {
      isInitialized = true; // ✅ Ensure initialization only happens once
      await fetchUnseenNotificationCount(); // ✅ Fetch unseen count before WebSocket connection
      connectWebSocket();
    }
    return next(action);
  };
};

export default notificationMiddleware;
