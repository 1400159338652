import React, { useState, useEffect } from "react";
import "../styles/documentDetails.css";
import "../../style/customerStyle.css";
import { Drawer, message, Modal } from "antd";
import Header from "../../../../layout/views/Header";
import DocumentDetailsForm from "./DocumentDetailsForm";
import {
  getDocumentDetailsByIdService,
  deleteDocumentServiceById,
} from "../services/documentDetailsServices";
import { useParams } from "react-router-dom";
import "viewerjs-react/dist/index.css";
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import "../../../../commonCss/commonStyle.css";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import dummy_img from "../assets/dummy.png";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";
import { useSelector } from "react-redux";
import add from "../assets/Add.png";
import DocumentCard from "./DocumentCard";

const DocumentDetails = () => {
  const { user_data } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { encrypted_loan_id } = useParams();
  const [documentData, setDocumentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [payload, setPayload] = useState({});
  const [headings, setHeadings] = useState(null);

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDocumentDetailsByIdService(loan_id);
        setDocumentData(response?.data?.customers);
        setHeadings(response?.data?.unique_categories);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
  }, [loan_id, refreshData]);

  const onClose = () => {
    setOpen(false);
  };

  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return "tab_form_main_data_two_entries";
      case 3:
        return "tab_form_main_data_three_entries";
      default:
        return "";
    }
  };

  const handleImageError = (e) => {
    e.target.src = dummy_img;
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const handleDelete = async (values) => {
    let payload = {
      document_detail_id: values.document_detail_id,
      document_category: values.document_category,
      document_type: values.document_type,
    };

    try {
      const response = await deleteDocumentServiceById(payload);

      if (response?.status === 200 && response?.success) {
        message.success("Document deleted successfully");
        toggleRefreshData();
      } else {
        message.error("Failed to delete document");
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message) ||
          "An unexpected error occurred."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleMenuClick = ({ key, properties }) => {
    if (key === "edit") {
      setOpen(true);
      setSelectedDocumentId(properties.id);
      setSelectedCustomerId(properties.customer_detail.id);
    } else if (key === "share") {
      //Left empty to add code later
    } else if (key === "delete") {
      setPayload({
        document_detail_id: properties.id,
        document_category: properties.document_category[0].id,
        document_type: properties.document_type,
      });
      setOpenModal(true);
    }
  };

  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={<Header title="Document" onClose={onClose} />}
          width={970}
          onClose={onClose}
          open={open}
          closable={false}
          refreshData={toggleRefreshData}
        >
          <DocumentDetailsForm
            open={open}
            closeForm={onClose}
            refreshData={toggleRefreshData}
            loan_id={loan_id}
            customer_id={selectedCustomerId}
            document_id={selectedDocumentId}
          />
        </Drawer>
        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : documentData?.some(
              (person) => person?.document_details?.length > 0
            ) && headings ? (
            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">Key Parameter's</th>
                  {documentData &&
                    documentData?.map((person, index) => (
                      <th
                        key={person.id || index}
                        className="tab_form_top_heading"
                      >
                        <div className="add_button_alignment">
                          <div>
                            <span>{`${person?.customer_name}`} </span>
                            <span>
                              <span>{`(${person?.customer_type})`} </span>
                            </span>
                          </div>
                          <ConditionalRender
                            userPermissions={user_data?.permissions}
                            requiredPermissions={["POSTCUST"]}
                          >
                            <div
                              className="tab_form_sub_edit"
                              onClick={() => {
                                showDrawer();
                                setSelectedCustomerId(person.customer_id);
                                setSelectedDocumentId(null);
                              }}
                            >
                              <img src={add} alt="Add Button" />
                            </div>
                          </ConditionalRender>
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {headings.map((heading) => {
                  return (
                    <tr className="tab_form_gray_highlighted_row" key={heading}>
                      <td
                        className="tab_form_left_heading"
                        style={{ height: "100px" }}
                      >
                        {heading}
                      </td>
                      {documentData &&
                        documentData?.map((person, index) => (
                          <td
                            key={index}
                            className={`tab_form_main_data_table ${getTableColumnClass(
                              documentData?.length
                            )}`}
                          >
                            <div className="main_card_first_box">
                              {person?.document_details?.length > 0
                                ? person?.document_details?.map((properties) =>
                                    properties.document_category?.map(
                                      (category, catIndex) => {
                                        return (
                                          <>
                                            {heading === category.name && (
                                              <DocumentCard
                                                key={catIndex}
                                                properties={properties}
                                                category={category}
                                                handleMenuClick={
                                                  handleMenuClick
                                                }
                                                handleImageError={
                                                  handleImageError
                                                }
                                              />
                                            )}
                                          </>
                                        );
                                      }
                                    )
                                  )
                                : "-"}
                            </div>
                          </td>
                        ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="fade-in">
              <div>
                <table className="tab_form_main_table no_data_table_height">
                  <thead>
                    <tr>
                      <th className="tab_form_key_parameters">
                        Key Parameter's
                      </th>
                      {documentData?.map((person, index) => (
                        <th
                          key={index}
                          className="tab_form_top_heading no_data_heading_bottom_border"
                        >
                          <span className="no_data_heading_content">
                            <div>
                              <span>{`${person?.customer_name}`} </span>
                              <span>
                                <span>{`(${person?.customer_type})`} </span>
                              </span>
                            </div>
                            <ConditionalRender
                            userPermissions={user_data?.permissions}
                            requiredPermissions={["POSTCUST"]}
                          >
                            <div
                              className="tab_form_sub_edit"
                              onClick={() => {
                                showDrawer();
                                setSelectedCustomerId(person.customer_id);
                                setSelectedDocumentId(null);
                              }}
                            >
                              <img src={add} alt="Add Button" />
                            </div>
                          </ConditionalRender>
                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {documentData?.length ? (
                      <tr>
                        <td colSpan={documentData?.length + 1}></td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={documentData?.length + 1}></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="no_data_icon">
                <CustomNoData />
              </div>
            </div>
          )}
        </div>
        <Modal
          title="Confirm Delete"
          open={openModal}
          onOk={() => {
            handleDelete(payload);
            setOpenModal(false);
          }}
          onCancel={() => {
            setOpenModal(false);
          }}
          okType="danger"
        >
          <p>{`Are you sure you want to delete this file?`}</p>
        </Modal>
      </div>
    </>
  );
};

export default DocumentDetails;
