import { axiosRequest } from "../../../../utils/api/axiosRequest";
export const getAllProductService = (page, limit, searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      page = page ? page : 1;
      limit = limit ? limit : 10;

      let path = `/api/v1/master/product/?page=${page}&limit=${limit}`;
      if (searchQuery.length > 0) {
        
        path = `/api/v1/master/product/?search=${searchQuery}&page=${page}&limit=${limit}`;
      }
      const response = await axiosRequest.get(path);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getProductByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/product/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const createProductService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/master/product/", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const updateProductByIdService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/master/product/${id}`, data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }


  export const deleteProductByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.delete(`/api/v1/master/product/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const listLoanTypeService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/loantype/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  export const listUserService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get('/api/v1/user/get-user-list');
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };


  export const listAllEndUseService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/product-end-use/`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };