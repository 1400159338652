
import React, { useState, useEffect } from "react";
import { Drawer, Modal, Space, Table, message } from "antd";
import "../../loanandInsurance/styles/loanInsurance.css";
import "../../IncomeEvaluation/styles/incomeEvalution.css";
import {
  getBankDetailsById,
  getIncomeEvaluationById,
} from "../services/incomeEvaluationService";
import { useParams } from "react-router-dom";
import CreditTransactionDetails from "./CreditTransactionDetails";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import Header from "../../../../layout/views/Header";
import { IncomeMutiplierDataByLoanId, LTVCalculationsByLoanId, createCam } from "../services/incomeDetailsServices"
import {
  EditOutlined
} from "@ant-design/icons";
import { loanbycustomerId } from "../services/incomeDetailsServices";
// import EditButton from "../../../../../utils/editButton/EditButton";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import LTVCalculation from "./LTVCalculation";
// import IncomeCalculation from "./IncomeCalculation";
import ExistingLoanDetailsForm from "../../../customerTabDetails/existingLoansDetails/views/ExistingLoanDetailsForm";
import { formConfig } from "../../../../../config/schemeConfig";
import IndianNumberFormat from "../../../../../utils/indianNumberFormat/IndianNumberFormat";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import authorizeTableColumns from "../../../../../utils/authorization/authorizeTableColumns";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import IncomeDetails from "./IncomeDetails";

const IncomeEvalution = ({ loanDetails }) => {
  const { encrypted_loan_id } = useParams();
  const [incomeEvaluations, setIncomeEvaluations] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);
  const [, setTotal] = useState({});
  const [open, setOpen] = useState(false);
  const [, setNonCashIncomeData] = useState([]);
  const [, setIncomeMutiplierData] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState();
  const [schemeDisplayName, setSchemeDisplayName] = useState('');
  const [getLTVCalculationsrefresh, setLTVCalculationsRefresh] = useState(false);
  const [selectedExistingLoan, setSelectedExistingLoan] = useState(null);
  // const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openExistingLoan, setOpenExistingLoan] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [collapseExistingLoanSections, setCollapseExistingLoanSections] = useState(true);
  const { user_data } = useSelector((state) => state.user);
  const [collapseBankAccountsSections, setCollapseBankAccountsSections] = useState(true);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    // Check if loanDetails and scheme are defined, then extract display_name
    if (loanDetails && loanDetails?.scheme && loanDetails?.scheme?.code) {
      setSchemeDisplayName(loanDetails?.scheme?.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, loanDetails]);

  const showDrawerExistingLoan = () => {
    setOpenExistingLoan(true);
  };

  const onCloseExistingLoan = () => {
    setOpenExistingLoan(false);
  };
  // const showDrawer = () => {
  //   setOpen(true);
  // };
  const onClose = () => {
    setOpen(false);
    setSelectedPerson(null);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  const toggleLTVCalculationsData = () => {
    setLTVCalculationsRefresh((prev) => !prev);
  };


  useEffect(() => {
    // Fetch data when the component mounts
    const fetchCustomerData = async () => {
      try {
        if (loan_id) {
          const response2 = await loanbycustomerId(loan_id);
          if (response2.status === 200 && response2.success) {
            setNonCashIncomeData(response2?.data);
          }
          if (schemeDisplayName === 'INCOMEMU') {
            const incomeMutilplierData = await IncomeMutiplierDataByLoanId(loan_id);
            if (incomeMutilplierData.status === 200 && incomeMutilplierData.success) {
              setIncomeMutiplierData(incomeMutilplierData?.data);
            }
          }
        }
      } catch (error) {
        // Handle error in API call
        message.error("Error fetching customer data:", error);
      }
    };

    fetchCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, refreshTableData, schemeDisplayName]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getIncomeEvaluationById(loan_id);
        const loans = response?.data?.loans || [];
        const existingLoanDetails = loans
          ?.map((loan) => {
            const loanDetails = loan?.existing_loan_details?.map((detail) => ({
              ...detail,
              key: detail?.id,
              customer_name: loan?.customer_name,
              loan_purpose_name: detail?.loan_purpose?.name || "",
              loan_status_name: detail?.loan_status?.name || "",
              financial_institution_name:
                detail.financial_institutions?.name || "",

              consider_obligation: detail?.consider_obligation ? "Yes" : "No",
              eligibility_calculation_under_repayment_program: detail?.eligibility_calculation_under_repayment_program ? "Yes" : "No",
            }));
            return loanDetails;
          })
          .flat();
        const totalValues = response?.data?.total || {};
        setTotal(totalValues);
        setIncomeEvaluations(existingLoanDetails);
      } catch (error) {
        message.error("Error fetching existing loan data");
      }
    };

    fetchData();
  }, [loan_id, refreshTableData]);

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const response = await getBankDetailsById(loan_id);
        const bankData = response.data.results || [];
        // Function to flatten bank objects
        const flattenBanks = (customersData) => {
          const flattenedBanks = [];
          customersData.forEach((customer) => {
            customer.customer_bank_details.forEach((bank) => {
              flattenedBanks.push({ customer_id: customer.customer_id, customer_name: customer.customer_name, ...bank });
            });
          });
          return flattenedBanks;
        };

        const flattenedBanksArray = flattenBanks(bankData);
        const tableBankArray = flattenedBanksArray?.map((account, index) => {
          return {
            key: index,
            customer_id: account.customer_id,
            bank_detail_id: account.id,
            Parameters: `${account?.customer_name}`,
            Bank_Name: account?.bank?.display_name,
            Account_Number: account?.account_number,
            Bank_Branch: account?.bank_branch?.display_name,
            Account_Type: account?.account_type,
          };
        });

        setBankDetails(tableBankArray);
      } catch (error) {
        message.error("Error fetching Bank Details");
      }
    };
    fetchBankDetails();
  }, [loan_id]);



  const handleCreateCam = async () => {
    try {
      const payload = {
        loan_detail: loan_id,
      };
      await createCam(payload);
      await LTVCalculationsByLoanId(loan_id);
      toggleLTVCalculationsData();
      message.success("CAM created successfully");
      setOpenConfirmModal(false);
    } catch (error) {
      setOpenConfirmModal(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  // const deleteRental = async () => {
  //   try {
  //     const response = await deleteRentalDetailsByIdLoanId(selectedExistingLoan);

  //     if (response.status === 200 && response.success === true) {
  //       message.success("Rental deleted successfully");
  //       setOpenModal(false);
  //       toggleRefreshTableData();
  //       setSelectedExistingLoan(null);
  //     }
  //   } catch (error) {
  //     setOpenModal(false);
  //     message.error(error.response.data.message);
  //   }
  // };

  // const renderEditButton = (showDrawer, id,) => (
  //   <div className="tab_form_sub_edit" onClick={() => {
  //     setSelectedPerson(id);
  //     showDrawer();
  //   }}>
  //     <EditButton />
  //   </div>
  // );



  const columnsFour = [
    {
      title: "Customer Name/Parameter",
      dataIndex: "Parameters",
      width: 250,
    },
    {
      title: "Bank Name",
      dataIndex: "Bank_Name",
      width: 220,
      render: (text) => text ? text : '-',
    },
    {
      title: "Account Number",
      dataIndex: "Account_Number",
      width: 220,
      render: (text) => text ? text : '-',
    },
    {
      title: "Bank Branch",
      dataIndex: "Bank_Branch",
      width: 250,
      render: (text) => text ? text : '-',
    },
    {
      title: "Account Type",
      dataIndex: "Account_Type",
      render: (text) => text ? text : '-',
    },
  ];

  const col = [
    {
      title: "Customer Name/Parameter",
      dataIndex: "customer_name",
      width: 220,
      fixed: "left",
      className: "custom-header",
    },

    {
      title: "Loan Purpose",
      dataIndex: "loan_purpose_name",
      width: 150,
    },
    {
      title: "Sanctioned Amount",
      dataIndex: "sanctioned_amount",
      width: 120,
      render: (text) => {
        return text ? `₹ ${new Intl.NumberFormat('en-IN').format(text)}` : '-';
      },
    },

    {
      title: "Outstanding Amount",
      dataIndex: "outstanding_amount",
      width: 120,
      render: (text) => {
        return text ? `₹ ${new Intl.NumberFormat('en-IN').format(text)}` : '-';
      },
    },

    {
      title: "Sanctioned Date",
      dataIndex: "sanctioned_date",
      width: 120,
    },

    {
      title: "Maturity Date",
      dataIndex: "maturity_date",
      width: 120,
    },
    {
      title: "EMI",
      dataIndex: "emi_amount",
      render: (text) => (text ? `₹ ${IndianNumberFormat(text)}` : '-'),
      width: 120,
    },

    {
      title: "ROI",
      dataIndex: "roi",
      width: 120,
    },
    {
      title: "Loan Status",
      dataIndex: "loan_status_name",
      width: 120,
    },
    {
      title: "Obligation Considered",
      dataIndex: "consider_obligation",
      width: 120,
    },
    {
      title: "Source of Liability",
      dataIndex: "source_of_liability",
      width: 120,
    },
    {
      title: "Financial Institute",
      dataIndex: "financial_institution_name",
      width: 120,
    },
    {
      title: "Eligibility Calculation Repayment Program",
      dataIndex: "eligibility_calculation_under_repayment_program",
      width: 120,
    },
  ];

  const authorizedColumns = [
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["EDITCAM1"]}
            >
              <EditOutlined
                onClick={() => {
                  setSelectedExistingLoan(record.id);
                  setSelectedCustomerId(record.customer_id)
                  showDrawerExistingLoan();
                }}
              />
            </ConditionalRender>
          </Space>
        );
      },
    },
  ];

  const tableColumns = authorizeTableColumns(
    col,
    authorizedColumns,
    user_data?.permissions,
    ["EDITCAM1"]
  );


  return (
    <>
      <div className="tab_main_container">

        {/* <Drawer
          title={
            <Header
              title={"Add"}
              onClose={onClose}
              name={
                "Income details"
              }
            />
          }
          width={1000}
          onClose={onClose}
          open={open}
          closable={false}
        >
          {schemeDisplayName === 'NORMALI5' && (
            <IncomeNonCashForm
              closeForm={onClose}
              open={open}
              id={selectedPerson}
              refreshData={toggleRefreshTableData}
            />
          )}
          {schemeDisplayName === 'NORMALIN' && (
            <IncomeCashForm
              closeForm={onClose}
              open={open}
              id={selectedPerson}
              refreshData={toggleRefreshTableData}
            />
          )}
          {schemeDisplayName === 'INCOMEMU' && (
            <IncomeMultiplierForm
              closeForm={onClose}
              open={open}
              id={selectedPerson}
              refreshData={toggleRefreshTableData} />
          )}
          {schemeDisplayName === 'INCOMEES' && (
            <NormalIncomeAndIncomeEstimateForm
              closeForm={onClose}
              open={open}
              id={selectedPerson}
              refreshData={toggleRefreshTableData} />
          )} */}
        {/* {schemeDisplayName === 'LOWLTV36' && (
            <LowLtv
              closeForm={onClose}
              open={open} />
          )} */}

        {/* {schemeDisplayName === 'BANKING3' && (
            <Banking
              closeForm={onClose}
              open={open} />
          )} */}
        {/* {schemeDisplayName === 'Gross receipts 360' && (
            <GrossReciepts
              closeForm={onClose}
              open={open} />
          )} */}
        {/* {schemeDisplayName === 'EMIEQUAL' && (
            <EmiEuiliserForm
              closeForm={onClose}
              open={open}
              refreshData={toggleRefreshTableData} />
          )}
          {schemeDisplayName === 'RENTALIN' && (
            <PureRentalForm
              closeForm={onClose}
              open={open}
              refreshData={toggleRefreshTableData}
            />

          )}

        </Drawer> */}

        <Drawer
          title={
            <Header
              title={selectedPerson ? "Edit" : "Add"}
              onClose={onClose}
              name={"Income details"}
            />
          }
          width={1000}
          onClose={onClose}
          open={open}
          closable={false}
        >
          {schemeDisplayName && (
            (() => {
              const formConfigMatch = formConfig.find(form => form.schemeDisplayName === schemeDisplayName);
              if (formConfigMatch) {
                const FormComponent = formConfigMatch.component;

                // Conditionally set props based on the form type
                const commonProps = {
                  closeForm: onClose,
                  open: open,
                  refreshData: toggleRefreshTableData,
                };

                if (
                  schemeDisplayName === 'NORMALI5' ||
                  schemeDisplayName === 'NORMALIN' ||
                  schemeDisplayName === 'INCOMEMU' ||
                  schemeDisplayName === 'INCOMEES'
                ) {
                  return (
                    <FormComponent
                      {...commonProps}
                      id={selectedPerson} // Add the `id` prop for other forms
                    />
                  );
                }


                return (
                  <FormComponent
                    {...commonProps} // Spread common props for Emi and Rental forms
                  />
                );
              }
              return null;
            })()
          )}
        </Drawer>

        {/* <div className="cam-row">
          {schemeDisplayName !== 'BANKING3' && schemeDisplayName !== 'LOWLTV36' && (
            <>
              <ConditionalRender
                userPermissions={user_data?.permissions}
                requiredPermissions={["POSTCAM1"]}
              >
                <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  onClick={showDrawer}
                >
                  Add
                </Button>
              </ConditionalRender>
            </>
          )}
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["POSTCAM1"]}
          >
            <Button type="primary"
              icon={<PlusCircleOutlined />}
              danger
              onClick={() => {
                setOpenConfirmModal(true);
              }}
              className="cam_button"
            >
              CAM
            </Button>
          </ConditionalRender>

        </div> */}
        <div style={{ marginBottom: "10px" }}>
          <IncomeDetails toggleRefreshTableData={toggleLTVCalculationsData} />
        </div>
        <div>

          <LTVCalculation
            refreshTableData={refreshTableData}
            refreshLTVData={getLTVCalculationsrefresh}
          />
        </div>

        {/* <div>
          {nonCashIncomeData?.length > 0 && (
            <>
              {viewConfig?.map((config) => {
                if (nonCashIncomeData?.[0].scheme_name === config.scheme_name) {
                  const ViewComponent = config.component;
                  let viewProps = {};

                  // Manually pass props using if statements
                  if (
                    config.scheme_name === 'NORMALI5' ||
                    config.scheme_name === 'NORMALIN' ||
                    config.scheme_name === 'INCOMEES'
                  ) {
                    // Common props for most components
                    viewProps.nonCashIncomeData = nonCashIncomeData;
                    viewProps.renderEditButton = renderEditButton;
                    viewProps.showDrawer = showDrawer;
                    viewProps.schemeDisplayName = schemeDisplayName;
                  }

                  if (config.scheme_name === 'RENTALIN') {
                    // Props for PureRentalView
                    viewProps.refreshData = toggleRefreshTableData;
                  }

                  return <ViewComponent key={config.scheme_name} {...viewProps} />;
                }
                return null;
              })}
            </>
          )}


          {incomeMutiplierData.length > 0 && (
            <>
              {viewConfig?.map((config) => {
                if (incomeMutiplierData?.[0].scheme_name === config.scheme_name) {
                  const ViewComponent = config.component;
                  let viewProps = {};



                  if (config.scheme_name === 'INCOMEMU') {
                    // Common props for most components
                    viewProps.nonCashIncomeData = incomeMutiplierData;
                    viewProps.renderEditButton = renderEditButton;
                    viewProps.showDrawer = showDrawer;
                  }


                  return <ViewComponent key={config.scheme_name} {...viewProps} />;
                }
                return null;
              })}
            </>
          )} */}

        {/* {schemeDisplayName !== "RENTALIN" && (
            <IncomeCalculation
              schemeDisplayName={schemeDisplayName}
              refreshTableData={refreshTableData}
            />
          )} */}
        {/* </div> */}
        <div className="heading_details_container">

          <div className={`heading_text`}>
            <div> Existing Loan</div>
            {collapseExistingLoanSections ? (
              <UpOutlined
                onClick={() => setCollapseExistingLoanSections(!collapseExistingLoanSections)}
              />
            ) : (
              <DownOutlined
                onClick={() => setCollapseExistingLoanSections(!collapseExistingLoanSections)}
              />
            )}
          </div>

          {collapseExistingLoanSections && (
            <div className="existing-loan-table" >
              <Table
                className="insurance_table"
                // apiPath={`/api/v1/customer/existing_loan_detail/underwritting_existing_loan/${id}`}
                columns={tableColumns}
                dataSource={incomeEvaluations}
                pagination={false}
              />
            </div>
          )}
        </div>


        <div className="heading_details_container">


          <div className="heading_text">
            Bank Account Details
            {collapseBankAccountsSections ? (
              <UpOutlined
                onClick={() => setCollapseBankAccountsSections(!collapseBankAccountsSections)}
              />
            ) : (
              <DownOutlined
                onClick={() => setCollapseBankAccountsSections(!collapseBankAccountsSections)}
              />
            )}</div>
          {collapseBankAccountsSections && (
            <div className="bank_account_details_table">
              <Table
                columns={columnsFour}
                expandable={{
                  expandedRowRender: (record) => (
                    <div>
                      <CreditTransactionDetails customer_id={record.customer_id} bank_id={record.bank_detail_id}
                        refreshTableIncomeData={toggleRefreshTableData} />
                    </div>
                  ),
                  rowExpandable: (record) => record.name !== "Not Expandable",
                }}
                dataSource={bankDetails}
                pagination={false}
              />
            </div>
          )}
        </div>
      </div >
      {/* <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteRental();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal> */}
      <Drawer
        title={
          <Header
            title={"Edit"}
            onClose={onCloseExistingLoan}
            name={
              "Existing Loan Form"
            }
          />
        }
        width={1000}
        onClose={onCloseExistingLoan}
        open={openExistingLoan}
        closable={false}
      >

        <ExistingLoanDetailsForm
          closeForm={onCloseExistingLoan}
          open={openExistingLoan}
          id={selectedExistingLoan}
          customer_id={selectedCustomerId}
          refreshData={toggleRefreshTableData}
        />

      </Drawer>
      <Modal
        title="Confirm Modal"
        open={openConfirmModal}
        onOk={() => {
          setOpenConfirmModal(false);
          handleCreateCam();
        }}
        onCancel={() => setOpenConfirmModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to create cam?`}</p>
      </Modal>
    </>
  );
};

export default IncomeEvalution;
