import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer, message, Modal, Tag, Tooltip } from "antd";
import { useSelector } from "react-redux";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import dummy_img from "../assets/dummyImage.png";
import "../styles/personalDetails.css";
import "../../style/customerStyle.css";
import {
  getPersonalDetailsByIdService,
  deletePersonalDetailsByIdService,
  getLoanIdForHyperlink,
} from "../services/personalDetailsServices";
import Header from "../../../../layout/views/Header";
import PersonalDetailsForm from "./PersonalDetailsForm";
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import HistoryTable from "../../../history/views/HistoryTable";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import "../../../../commonCss/commonStyle.css";
import EditButton from "../../../../../utils/editButton/EditButton";
import { decrypt, encrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import CompanyApplicantForm from "./CompanyApplicantForm";
import DeleteButton from "../../../../../utils/deleteButton/DeleteButton";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FaExclamationCircle } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
import CallInitiate from "../../customerDetails/views/CallInitiate";

const PersonalDetails = ({ onShowPartnerTab, onRefresh }) => {
  const { encrypted_loan_id } = useParams();
  const [open, setOpen] = useState(false);
  const [companyApplicantDrawerOpen, setCompanyApplicantDrawerOpen] =
    useState(false);
  const [personalData, setPersonalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedPersonal, setSelectedPersonal] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customer, setCustomer] = useState();
  const { user_data } = useSelector((state) => state.user);
  const [customHeaders, setCustomHeaders] = useState([]);
  const [customerTypename, setCustomerTypename] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openCallForm, setOpenCallForm] = useState(false);

  const navigate = useNavigate();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setCompanyApplicantDrawerOpen(false);
    setCustomer(null);
  };

  const showDrawerCallInitiate = () => {
    setOpenCallForm(true);
  };
  const onCloseCallInitiate = () => {
    setOpenCallForm(false);
    setSelectedPersonal(null);
    setSelectedCustomer(null);
  };
  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  useEffect(() => {
    if (loan_id) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await getPersonalDetailsByIdService(loan_id);
          setPersonalData(response.data.customers);

          const customerTypes = response?.data?.customers?.map(
            (customer) => customer?.customer_type?.code
          );

          const customerTypeNames = response?.data?.customers?.map(
            (customer) => customer?.customer_type?.display_name
          );
          setCustomerTypename(customerTypeNames);

          const companyCustomerTypes = ["MCTCPAP3", "MCTUPNM6", "MCTNCPA2"];
          const isPresent = customerTypes.some((item) =>
            companyCustomerTypes.includes(item)
          );

          let newHeaders = [...additionalHeaders];

          if (isPresent) {
            newHeaders = [
              ...newHeaders.slice(0, 2),
              "Company Name",
              "Office Address Type",
              "Business Construction Type",
              "SPOC Location",
              "Corporate Identification Number (CIN)",
              "Taxpayer Identification Numbers (TIN)",
              "Incorporation Date",
              ...newHeaders.slice(2),
            ];
          }

          setCustomHeaders(newHeaders);

          if (onShowPartnerTab) {
            onShowPartnerTab(isPresent); // Pass the result to show/hide Partner tab
          }
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, loan_id]);

  const additionalHeaders = [
    "Key Parameter's",
    "Profile Photo",
    "Full Name",
    // "Middle Name",
    // "Last Name",
    "Date Of Birth",
    // "Age",
    "Gender",
    "Marital status",
    "Caste Category",
    "Place of Birth",
    "Nationality",
    "Religion",
    "University",
    "Father Name",
    "Spouse Name",
    "Email",
    "Relation With Applicant",
    "Education",
    "Family Type",
    "Is Disabled",
    "Ex Servicemen",
    "Phone Number",
    "Mobile Number",
    "Alternate Mobile Number",
    "Linked Lan",
  ];

  const fieldNameMap = {
    thumb_image: "Profile Photo",
    first_name: "First Name",
    middle_name: "Middle Name",
    last_name: "Last Name",
    date_of_birth: "Date Of Birth",
    age: "Age",
    gender: "Gender",
    marital_status: "Marital status",
    place_of_birth: "Place of Birth",
    nationality: "Nationality",
    religion: "Religion",
    university: "University",
    father_name: "Father Name",
    spouse_name: "Spouse Name",
    email: "Email",
    relation_with_applicant: "Relation With Applicant",
    education: "Education",
    family_type: "Family Type",
    is_ex_servicemen: "Is Disabled",
    is_disabled: "Ex Servicemen",
    phone_country_code: "Phone Country Code",
    phone_number: "Phone Number",
    mobile_country_code: "Mobile Country Code",
    mobile_number: "Mobile Number",
    alt_mobile_country_code: "Alternate Mobile Country Code",
    alt_mobile_number: "Alternate Mobile Number",
    caste_category: "Caste Category",
    salutation: "Salutation",
    bd_modified_at: "Business Modified Date",
    spoc_location: "Spoc Location",
    thumb_photo: "Profile Photo",
    name: "Company Name",
    office_address_type: "Office Address Type",
    business_construction_type: "Business Construction Type",
    incorporation_date: "Incorporation Date",
    cin: "Corporate Identification Number (CIN)",
    tin: "Taxpayer Identification Numbers (TIN)",
  };
  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return "tab_form_main_data_two_entries";
      case 3:
        return "tab_form_main_data_three_entries";
      // case 4:
      //   return 'tab_form_main_data_four_entries';
      default:
        return "";
    }
  };

  const deleteBank = async () => {
    setLoadingDelete(true);
    try {
      const response = await deletePersonalDetailsByIdService(selectedPersonal);
      if (response.status === 200 && response.success === true) {
        message.success("Customer deleted successfully");
        toggleRefreshData();
        setSelectedPersonal(null);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };

  const handleLanNavigate = async (lan) => {
    try {
      const response = await getLoanIdForHyperlink(lan);
      if (response.data.id) {
        const path = `/loandetails/${encrypt(response?.data?.id.toString())}`;
        window.open(path, "_blank");
      } else {
        navigate("/not-found");
      }
    } catch (error) {
      navigate("/not-found");
    }
  };

  return (
    <div className="tab_form_container">
      <Drawer
        title={
          <Header
            title={selectedPersonal ? "Add" : "Edit"}
            onClose={onClose}
            name={`${selectedCustomer?.first_name} ${selectedCustomer?.middle_name}  ${selectedCustomer?.last_name}'s Personal Details`}
          />
        }
        width={970}
        refreshData={toggleRefreshData}
        onClose={onClose}
        open={open}
        body-Style={{ paddingBottom: 80 }}
        closable={false}
        loan_id={loan_id}
      >
        <PersonalDetailsForm
          refreshData={toggleRefreshData}
          open={open}
          closeForm={onClose}
          id={selectedCustomer}
          customer={customer}
          setCustomer={setCustomer}
          loan_id={loan_id}
          onRefresh={onRefresh}
        />
      </Drawer>
      <Drawer
        title={
          <Header
            title={
              selectedCustomer
                ? "Edit Company Applicant"
                : "Add Company Applicant"
            }
            onClose={onClose}
            name={` ${selectedCustomer?.name} Company Details`}
          />
        }
        width={970}
        refreshData={toggleRefreshData}
        onClose={onClose}
        open={companyApplicantDrawerOpen}
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        loan_id={loan_id}
      >
        <CompanyApplicantForm
          refreshData={toggleRefreshData}
          open={companyApplicantDrawerOpen}
          closeForm={onClose}
          id={selectedCustomer}
          customer={customer}
          setCustomer={setCustomer}
          loan_id={loan_id}
        />
      </Drawer>
      <Drawer
        title={
          <Header
            title="Call"
            onClose={onCloseCallInitiate}
            name={`${selectedCustomer?.customer_name}`}
          />
        }
        width={970}
        onClose={onCloseCallInitiate}
        open={openCallForm}
        closable={false}
      >
        <CallInitiate
          refreshData={toggleRefreshData}
          reference_id={selectedPersonal}
          customer_data={selectedCustomer}
          open={openCallForm}
          closeForm={onCloseCallInitiate}
          tab="personal"
        />
      </Drawer>
      <div className="tab_form_main_container fade-in">
        {loading ? (
          <Loading loading={loading} />
        ) : personalData?.length > 0 ? (
          <table className="tab_form_main_table">
            <thead>
              <tr>
                <th className="tab_form_key_parameters_persoanl">
                  {customHeaders[0]}
                </th>
                {personalData.map((person, index) => (
                  <th key={index} className="tab_form_top_heading">
                    {customHeaders[0] === "Key Parameter's" && (
                      <span className="personal_tab_heading">
                        <div>
                          {customerTypename.includes("Company Applicant") ||
                          customerTypename.includes("Company Co-Applicant") ||
                          customerTypename.includes("Company Guarantor") ? (
                            <span>{person?.customer_detail?.name}</span>
                          ) : (
                            <span>
                              {`${person?.first_name || ""} ${
                                person?.middle_name || ""
                              } ${person?.last_name || ""}` ||
                                person?.customer_detail?.name ||
                                ""}
                            </span>
                          )}

                          <span>
                            <span className="name_for_persoanl">
                              {`(${person?.customer_type?.display_name})`}{" "}
                            </span>
                            {/* <img
                              src={group}
                              alt="group"
                              className="personal_img"
                            /> */}
                          </span>
                        </div>
                        <div className="personal_tab_icon">
                          {person?.customer_type?.display_name ===
                            "Applicant" ||
                          person?.customer_type?.display_name ===
                            "Company Applicant" ? (
                            ""
                          ) : (
                            <ConditionalRender
                              userPermissions={user_data?.permissions}
                              requiredPermissions={["DELTCUST"]}
                            >
                              <div
                                className="tab_form_sub_edit"
                                onClick={() => {
                                  setSelectedPersonal(person?.id);
                                  setOpenModal(true);
                                }}
                              >
                                <DeleteButton />
                              </div>
                            </ConditionalRender>
                          )}

                          {person?.created_at &&
                            person?.modified_at &&
                            isModifiedAfterCreated(
                              person?.created_at,
                              person?.modified_at
                            ) && (
                              <HistoryTable
                                id={person?.id}
                                bench_id={
                                  customerTypename.includes(
                                    "Company Applicant"
                                  ) ||
                                  customerTypename.includes(
                                    "Company Co-Applicant"
                                  ) ||
                                  customerTypename.includes("Company Guarantor")
                                    ? "6f2c4a7d8b9e5a3b"
                                    : "66bcfc08fcb1a944"
                                }
                                fieldNameMap={fieldNameMap}
                              />
                            )}
                          <ConditionalRender
                            userPermissions={user_data?.permissions}
                            requiredPermissions={["EDITCUST"]}
                          >
                            <div
                              className="tab_form_sub_edit"
                              onClick={() => {
                                setSelectedCustomer(person);
                                // Check if the applicant is a "Company Applicant"
                                if (
                                  [
                                    "Company Applicant",
                                    "Company Co-Applicant",
                                    "Company Guarantor",
                                  ].includes(
                                    person?.customer_type?.display_name
                                  )
                                ) {
                                  // Show the Company Applicant form
                                  setCompanyApplicantDrawerOpen(true);
                                } else {
                                  // Show the PersonalDetailsForm
                                  showDrawer();
                                }
                              }}
                            >
                              <EditButton />
                            </div>
                          </ConditionalRender>
                        </div>
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading
                ? Array.from({ length: customHeaders.length - 1 }).map(
                    (_, index) => (
                      <tr key={`loading-${index}`}>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    )
                  )
                : customHeaders?.slice(1).map((header, rowIndex) => (
                    <tr
                      key={`row-${rowIndex}`}
                      className={`Personal_main_data_table ${
                        header === "PD Entries"
                          ? " tab_form_gray_highlighted_row"
                          : header === "No Of Years In Current City"
                          ? " tab_form_highlighted_row_orange"
                          : ""
                      }`}
                    >
                      <td
                        className={`tab_form_left_heading${
                          header === "PD Entries" ||
                          header === "No Of Years In Current City"
                            ? ` ${
                                header === "PD Entries"
                                  ? "tab_form_gray_highlighted_row "
                                  : "tab_form_highlighted_row_orange"
                              }`
                            : ""
                        }`}
                      >
                        {header}
                      </td>
                      {personalData?.map((person, colIndex) => (
                        <td
                          key={`col-${colIndex}`}
                          className={`tab_form_main_data_table ${getTableColumnClass(
                            personalData.length
                          )}`}
                        >
                          {header === "Profile Photo" ? (
                            <RViewerJS
                              options={{
                                url: (image) =>
                                  image.getAttribute("data-original"),
                              }}
                            >
                              <img
                                // className="profile_img"
                                className={`profile_img ${
                                  person.thumb_image ? "has-thumb" : ""
                                }`}
                                src={person.thumb_image || dummy_img}
                                data-original={person.image || dummy_img}
                                alt="Profile"
                              />
                            </RViewerJS>
                          ) : (
                            person[header.replace(/ /g, "_")] || ""
                          )}

                          {header === "Company Name" && (
                            <span>{person?.name || "-"}</span>
                          )}
                          {header === "Office Address Type" && (
                            <span>{person?.office_address_type || "-"}</span>
                          )}
                          {header === "Business Construction Type" && (
                            <span>
                              {person?.business_construction_type || "-"}
                            </span>
                          )}
                          {header === "Incorporation Date" && (
                            <span>{person?.incorporation_date || "-"}</span>
                          )}
                          {header === "SPOC Location" && (
                            <span>{person?.spoc_location || "-"}</span>
                          )}
                          {header ===
                            "Corporate Identification Number (CIN)" && (
                            <span>{person?.cin || "-"}</span>
                          )}
                          {header ===
                            "Taxpayer Identification Numbers (TIN)" && (
                            <span>{person?.tin || "-"}</span>
                          )}

                        {header === "Full Name" && (
                          <span>
                            {person?.salutation} {person?.first_name || "-"} {person?.middle_name} {person?.last_name}

                            {person?.name_check && (
                              <>
                                {person.name_check.verified?.documents?.length > 0 && (
                                  <Tooltip
                                    title={
                                      <>
                                        {person.name_check.verified.documents.map((doc, index) => (
                                          <div key={index}>{doc}</div>
                                        ))}
                                      </>
                                    }
                                  >
                                    <RiVerifiedBadgeFill className="personal_tab_status_true_flage" />
                                  </Tooltip>
                                )}
                                {person.name_check.unverified?.documents?.length > 0 && (
                                  <Tooltip
                                    title={
                                      <>
                                        {person.name_check.unverified.documents.map((doc, index) => (
                                          <div key={index}>{doc}</div>
                                        ))}
                                      </>
                                    }
                                  >
                                    <FaExclamationCircle className="personal_tab_status_false_flage" />
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </span>
                        )}

                        {/* {header === "Middle Name" && (
                          <span>{person?.middle_name || "-"}</span>
                        )}

                        {header === "Last Name" && (
                          <span>
                            {person?.last_name || "-"}

                          </span>
                        )} */}

                        {header === "Date Of Birth" && (
                          <span>
                            {person?.date_of_birth || "-"} {` (${person?.age} Years)`}
                            {person?.date_of_birth_check && (
                              <>
                                {person.date_of_birth_check.verified?.documents?.length > 0 && (
                                  <Tooltip
                                    title={`${person.date_of_birth_check.verified.documents.join(", ")}`}
                                  >
                                    <RiVerifiedBadgeFill className="personal_tab_status_true_flage" />
                                  </Tooltip>
                                )}

                                  {person.date_of_birth_check.unverified
                                    ?.documents?.length > 0 && (
                                    <Tooltip
                                      title={`${person.date_of_birth_check.unverified.documents.join(
                                        ", "
                                      )}`}
                                    >
                                      <FaExclamationCircle className="personal_tab_status_false_flage" />
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </span>
                          )}


                        {/* {header === "Age" && (
                          <span>{person?.age || "-"}</span>
                        )} */}

                          {header === "Place of Birth" && (
                            <span>{person?.place_of_birth || "-"}</span>
                          )}

                          {header === "Gender" && (
                            <span>{person?.gender || "-"}</span>
                          )}

                          {header === "Marital status" && (
                            <span>{person?.marital_status || "-"}</span>
                          )}

                          {header === "Caste Category" && (
                            <span>
                              {person?.caste_category?.display_name || "-"}
                            </span>
                          )}

                          {header === "Nationality" && (
                            <span>{person?.nationality || "-"}</span>
                          )}

                          {header === "Religion" && (
                            <span>{person?.religion || "-"}</span>
                          )}

                          {header === "University" && (
                            <span>{person?.university || "-"}</span>
                          )}

                          {header === "Father Name" && (
                            <span>{person?.father_name || "-"}</span>
                          )}

                          {header === "Spouse Name" && (
                            <span>{person?.spouse_name || "-"}</span>
                          )}

                          {header === "Email" && (
                            <span>{person?.email || "-"}</span>
                          )}
                          {header === "Relation With Applicant" && (
                            <span>
                              {person?.relation_with_applicant?.display_name ||
                                "-"}
                            </span>
                          )}

                          {header === "Education" && (
                            <span>{person?.education || "-"}</span>
                          )}

                          {header === "Family Type" && (
                            <span>{person?.family_type || "-"}</span>
                          )}

                          {header === "Is Disabled" && (
                            <span>{person?.is_disabled ? "Yes" : "No"}</span>
                          )}

                          {header === "Ex Servicemen" && (
                            <span>
                              {person?.is_ex_servicemen ? "Yes" : "No"}
                            </span>
                          )}

                          {header === "Phone Number" && (
                            <span>
                              {person?.phone_number ? (
                                <>+91 {person.phone_number}</>
                              ) : (
                                "-"
                              )}
                            </span>
                          )}

                          {header === "Mobile Number" && (
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                {person?.mobile_country_code
                                  ? `+${person.mobile_country_code}`
                                  : ""}
                                &nbsp;&nbsp;
                                {person?.mobile_number || "-"}
                              </div>
                              <div>
                                <IoCallOutline
                                  className="call_icon"
                                  onClick={() => {
                                    showDrawerCallInitiate();
                                    setSelectedPersonal(person?.id);
                                    setSelectedCustomer(person);
                                  }}
                                />
                              </div>
                            </span>
                          )}

                          {header === "Alternate Mobile Number" && (
                            <span>
                              {person?.alt_mobile_number ? (
                                <>+91 {person.alt_mobile_number}</>
                              ) : (
                                "-"
                              )}
                            </span>
                          )}
                          {header === "Linked Lan" && (
                            <span>
                              {person?.linked_lan_list.length > 0 ? (
                                <>
                                  {person?.linked_lan_list.map((lan) => {
                                    return (
                                      <>
                                        {lan && (
                                          <Tag
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            color="processing"
                                            onClick={() =>
                                              handleLanNavigate(lan)
                                            }
                                          >
                                            {lan}
                                          </Tag>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                "-"
                              )}
                            </span>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
            </tbody>
          </table>
        ) : (
          <div className="no_data_found_message">
            <CustomNoData />
          </div>
        )}
      </div>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteBank();
        }}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ loading: loadingDelete }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>
    </div>
  );
};

export default PersonalDetails;
