import React, { useEffect } from "react";
import "../styles/documentDetails.css";
import {
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { PlusCircleOutlined } from "@ant-design/icons";
import '../styles/legalinputform.css'
import { Button, Drawer, Modal, Space, Table, message } from "antd";
import { useState } from "react";
import Header from "../../layout/views/Header";
import ScrutinyAndLegalOpinionForm from "./ScrutinyAndLegalOpinionForm";
import PreDisbursementStageForm from "./PreDisbursementStageForm";
import PostDisbursementStageForm from "./PostDisbursementStageForm";
import {
  deleteDocumentDetailServiceById,
  getDocumentDetailServiceByLoanId,
  listDocumentTypeService,
} from "../services/documentDetailService";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { getPropertyDescriptionById } from "../services/propertyDescriptionService";
import PropertyPicturesForm from "./PropertyPicturesForm";
import Loading from "../../../utils/loading/Loading";
import EditButton from "../../../utils/editButton/EditButton";
import pdfIcon from "../assets/pdf.png";
import RViewerJS from "viewerjs-react";

const DocumentDetails = ({ loanId, stageId, legalStageData }) => {
  const [open, setOpen] = useState(false);
  const [scrutiny, setScrutiny] = useState([]);
  const [preDisbursement, setPreDisbursement] = useState([]);
  const [postDisbursement, setPostDisbursement] = useState([]);
  const [propertyPictures, setPropertyPictures] = useState([]);
  const [openSecondDrawer, setOpenSecondDrawer] = useState(false);
  const [openThirdDrawer, setOpenThirdDrawer] = useState(false);
  const [openFourthDrawer, setOpenFourthDrawer] = useState(false);
  const [collapsed1, setCollapsed1] = useState(true);
  const [collapsed2, setCollapsed2] = useState(true);
  const [collapsed3, setCollapsed3] = useState(true);
  const [collapsed4, setCollapsed4] = useState(true);
  const [selectedScrutiny, setSelectedScrutiny] = useState(null);
  const [selectedPreDisbursement, setSelectedPreDisbursement] = useState(null);
  const [selectedPostDisbursement, setSelectedPostDisbursement] =
    useState(null);
  const [selectedPropertyPicture, setSelectedPropertyPicture] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [documentType, setDocumentType] = useState([]);
  const [loading, setLoading] = useState(true);
  const [legalId, setLegalId] = useState();
  const isSendBack = legalStageData?.stage_status?.display_name === "Send Back";

  const fetchDocumentType = async () => {
    try {
      const response = await listDocumentTypeService();

      setDocumentType(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };

  const toggleCollapse3 = () => {
    setCollapsed3(!collapsed3);
  };

  const toggleCollapse4 = () => {
    setCollapsed4(!collapsed4);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedScrutiny(null);
  };

  const showSecondDrawer = () => {
    setOpenSecondDrawer(true);
  };

  const onCloseSecondDrawer = () => {
    setOpenSecondDrawer(false);
    setSelectedPreDisbursement(null);
  };

  const showThirdDrawer = () => {
    setOpenThirdDrawer(true);
  };

  const onCloseThirdDrawer = () => {
    setOpenThirdDrawer(false);
    setSelectedPostDisbursement(null);
  };

  const showFourthDrawer = () => {
    setOpenFourthDrawer(true);
  };

  const onCloseFourthDrawer = () => {
    setOpenFourthDrawer(false);
    setSelectedPropertyPicture(null);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const renderEditButton = () => {
    return (
      <div className="tab_form_sub_edit">
        <EditButton />
      </div>
    );
  };
  const columns = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
      width: 100,
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      width: 600,
      render: (text) => <span className='legal_document_view_form_data'>{text}</span>,

    },
    {
      title: "Dated",
      dataIndex: "dated",
      key: "dated",
      width: 300,
      render: (text) => <span className='legal_document_view_form_data'>{text}</span>,
    },
    {
      title: "Type of Document",
      dataIndex: "document_type",
      key: "type_of_document",
      width: 300,
      render: (text) => <span className='legal_document_view_form_data'>{text}</span>,
    },
    {
      title: "Actions",
      fixed: "right",
      width: 300,
      render: (_, record) => {
        return isSendBack ? (
          <Space size="middle">
            <EditOutlined
              className="edit_icon"
              onClick={() => {
                setSelectedScrutiny(record);
                showDrawer();
              }}
            />
            <DeleteOutlined
              className="delete-icon"
              onClick={() => {
                setSelectedScrutiny(record);
                setOpenModal(true);
              }}
            />
          </Space>
        ) : "-";
      },
    },
  ];

  const columns2 = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
      width: 100,
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      width: 500,
      render: (text) => <span className='legal_document_view_form_data'>{text}</span>,
    },
    {
      title: "Dated",
      dataIndex: "dated",
      key: "dated",
      width: 300,
      render: (text) => <span className='legal_document_view_form_data'>{text}</span>,
    },
    {
      title: "Type of Document",
      dataIndex: "document_type",
      key: "type_of_document",
      width: 300,
      render: (text) => <span className='legal_document_view_form_data'>{text}</span>,
    },
    {
      title: "Actions",
      fixed: "right",
      width: 300,
      render: (_, record) => {
        return isSendBack ? (
          <Space size="middle">
            <EditOutlined
              className="edit_icon"
              onClick={() => {
                setSelectedPreDisbursement(record);
                showSecondDrawer();
              }}
            />
            <DeleteOutlined
              className="delete-icon"
              onClick={() => {
                setSelectedPreDisbursement(record);
                setOpenModal(true);
              }}
            />
          </Space>
        ) : "-";
      },
    },
  ];

  const columns3 = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
      width: 100,
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      width: 500,
      render: (text) => <span className='legal_document_view_form_data'>{text}</span>,
    },
    {
      title: "Dated",
      dataIndex: "dated",
      key: "dated",
      width: 300,
      render: (text) => <span className='legal_document_view_form_data'>{text}</span>,
    },
    {
      title: "Type of Document",
      dataIndex: "document_type",
      key: "type_of_document",
      width: 300,
      render: (text) => <span className='legal_document_view_form_data'>{text}</span>,
    },
    {
      title: "Actions",
      fixed: "right",
      width: 300,
      render: (_, record) => {
        return isSendBack ? (
          <Space size="middle">
            <EditOutlined
              className="edit_icon"
              onClick={() => {
                setSelectedPostDisbursement(record);
                showThirdDrawer();
              }}
            />
            <DeleteOutlined
              className="delete-icon "
              onClick={() => {
                setSelectedPostDisbursement(record);
                setOpenModal(true);
              }}
            />
          </Space>
        ) : "-";
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDocumentDetailServiceByLoanId(stageId);
        setScrutiny(response.data.scrutiny_documents);
        setPreDisbursement(response.data.pre_disbursement_documents);
        setPostDisbursement(response.data.post_disbursement_documents);
        setPropertyPictures(response.data.legal_upload_serialized_data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchDocumentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData]);

  useEffect(() => {
    const fetchLegalId = async () => {
      try {
        const response = await getPropertyDescriptionById(stageId);

        if (response?.data?.legal_detail_data?.id) {
          setLegalId(response.data.legal_detail_data.id);
        } else {
          console.error("Legal ID is undefined");
        }
      } catch (error) {

        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchLegalId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteScrunity = async () => {
    if (!selectedScrutiny) {
      setOpenModal(false);
      return;
    }

    try {
      const response = await deleteDocumentDetailServiceById(
        selectedScrutiny.id
      );

      if (response?.status === 200 && response?.success === true) {
        message?.success("Scrunity document deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedScrutiny(null);
      }
    } catch (error) {
      setOpenModal(false);
      message?.error(error?.response?.data?.message);
    }
  };

  const deletePreDisbursementDocument = async () => {
    if (!selectedPreDisbursement) {
      setOpenModal(false);
      return;
    }

    try {
      const response = await deleteDocumentDetailServiceById(
        selectedPreDisbursement.id
      );

      if (response?.status === 200 && response?.success === true) {
        message?.success("Pre-disbursement document deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedPreDisbursement(null); // Reset selectedPreDisbursement
      }
    } catch (error) {
      setOpenModal(false);
      message?.error(error?.response?.data?.message);
    }
  };

  const deletePostDisbursementDocument = async () => {
    if (!selectedPostDisbursement) {
      setOpenModal(false);
      return;
    }

    try {
      const response = await deleteDocumentDetailServiceById(
        selectedPostDisbursement.id
      );

      if (response?.status === 200 && response?.success === true) {
        message?.success("Post-disbursement document deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedPostDisbursement(null); // Reset selectedPostDisbursement
      }
    } catch (error) {
      setOpenModal(false);
      message?.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="">
      <Drawer
        title={
          <Header
            title={selectedScrutiny ? "Edit" : "Add"}
            onClose={onClose}
            name="List Of Documents Submitted Before Me/Us For Scrutiny And Legal Opinion"
          />
        }
        width={1050}
        onClose={onClose}
        open={open}
        closable={false}
      >
        {loanId && stageId && (
          <ScrutinyAndLegalOpinionForm
            open={open}
            legalId={legalId}
            loanId={loanId}
            stageId={stageId}
            closeForm={onClose}
            documentType={documentType}
            scrutiny={scrutiny}
            selectedScrutiny={selectedScrutiny}
            refreshData={toggleRefreshTableData}
          />
        )}
      </Drawer>

      <Drawer
        title={
          <Header
            title={selectedPreDisbursement ? "Edit" : "Add"}
            onClose={onCloseSecondDrawer}
            name="Essential Documents Required To Be Collected At Pre-Disbursement Stage"
          />
        }
        width={1050}
        onClose={onCloseSecondDrawer}
        open={openSecondDrawer}
        closable={false}
      >
        <PreDisbursementStageForm
          open={openSecondDrawer}
          legalId={legalId}
          closeForm={onCloseSecondDrawer}
          documentType={documentType}
          preDisbursement={preDisbursement}
          selectedPreDisbursement={selectedPreDisbursement}
          refreshData={toggleRefreshTableData}
        />
      </Drawer>

      <Drawer
        title={
          <Header
            title={selectedPostDisbursement ? "Edit" : "Add"}
            onClose={onCloseThirdDrawer}
            name="Documents Required To Be Collected At Post-Disbursement Stage To Create A Valid Mortgage"
          />
        }
        width={1050}
        onClose={onCloseThirdDrawer}
        open={openThirdDrawer}
        closable={false}
      >
        <PostDisbursementStageForm
          open={openThirdDrawer}
          legalId={legalId}
          closeForm={onCloseThirdDrawer}
          documentType={documentType}
          postDisbursement={postDisbursement}
          selectedPostDisbursement={selectedPostDisbursement}
          refreshData={toggleRefreshTableData}
        />
      </Drawer>
      <Drawer
        title={
          <Header
            title="Edit"
            onClose={onCloseFourthDrawer}
            name="Legal Documents & Other Documents"
          />
        }
        width={1050}
        onClose={onCloseFourthDrawer}
        open={openFourthDrawer}
        closable={false}
      >
        <PropertyPicturesForm
          open={openFourthDrawer}
          legal_detail={legalId}
          closeForm={onCloseFourthDrawer}
          documentType={documentType}
          propertyPictures={propertyPictures}
          selectedPropertyPicture={selectedPropertyPicture}
          setSelectedPropertyPicture={setSelectedPropertyPicture}
          refreshData={toggleRefreshTableData}
        />
      </Drawer>

      <div className="legal_views_document_container">
        <div className="legal_input_blue_heading_block">
          <div className="legal_document_view_heading_name">
            List Of Documents Submitted Before Me/Us For Scrutiny And Legal
            Opinion
          </div>
          <div className="legal_input_button_space">
            {isSendBack &&
              <Button
                icon={<PlusCircleOutlined />}
                className="legal_view_document_button"
                onClick={() => {
                  showDrawer();
                }}
              >

                Add Documents
              </Button>
            }
            <div
              className="legal_input_collapse_icon"
              onClick={toggleCollapse1}
            >
              {collapsed1 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        {collapsed1 && (
          <div className="legal_input_table">
            {loading ? (
              <Loading />
            ) : (
              <Table
                columns={columns}
                dataSource={scrutiny}
                pagination={false}

              />
            )}
          </div>
        )}
      </div>

      <div className="legal_views_document_container">
        <div className="legal_input_blue_heading_block">
          <div className="legal_document_view_heading_name">
            Essential Documents Required To Be Collected At Pre-Disbursement
            Stage
          </div>
          <div className="legal_input_button_space">
            {isSendBack &&
              <Button
                icon={<PlusCircleOutlined />}
                className="legal_view_document_button"
                onClick={() => {
                  showSecondDrawer();
                }}
              >
                Add Documents
              </Button>
            }
            <div
              className="legal_input_collapse_icon"
              onClick={toggleCollapse2}
            >
              {collapsed2 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        <div>
          {collapsed2 && (
            <div className="legal_input_table">
              {loading ? (
                <Loading />
              ) : (
                <Table
                  columns={columns2}
                  dataSource={preDisbursement}
                  pagination={false}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div className="legal_views_document_container">
        <div className="legal_input_blue_heading_block">
          <div className="legal_document_view_heading_name">
            Documents Required To Be Collected At Post-Disbursement Stage To
            Create A Valid Mortgage
          </div>
          <div className="legal_input_button_space">
            {isSendBack &&
              <Button
                icon={<PlusCircleOutlined />}
                className="legal_view_document_button"
                onClick={() => {
                  showThirdDrawer();
                }}
              >
                Add Documents
              </Button>
            }
            <div
              className="legal_input_collapse_icon"
              onClick={toggleCollapse3}
            >
              {collapsed3 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        <div>
          {collapsed3 && (
            <div className="legal_input_table">
              {loading ? ( // Show loading spinner if data is still being fetched
                <Loading />
              ) : (
                <Table
                  columns={columns3}
                  dataSource={postDisbursement}
                  pagination={false}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div className="legal_views_document_container">
        <div className="legal_input_blue_heading_block">
          <div className="legal_input_heading_name">Property Pictures</div>
          <div className="legal_input_button_space">

            {isSendBack &&
              <div
                className="legal_input_edit_button"
                onClick={showFourthDrawer}
              >
                {renderEditButton()}
              </div>}
            <div
              className="legal_input_collapse_icon"
              onClick={toggleCollapse4}
            >
              {collapsed4 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        {collapsed4 && (
          <div className="valuation_Proerty_images_container" >
            {loading ? (
              <Loading />
            ) : (
              <div className="document_images" >
                {propertyPictures?.map((picture, index) => (
                  <div
                    key={index}
                    className="input_document_property_image"
                  >
                    {picture.file_type === "application/pdf" ? (
                      <img
                        src={pdfIcon}
                        alt={`PDF ${index}`}
                        className="input_document_property_type"
                        onClick={() => {
                          if (picture.file_path) {
                            window.open(picture.file_path, "_blank");
                          } else {
                            console.warn("PDF file path is missing or invalid.");
                          }
                        }}
                      />
                    ) : (
                      <RViewerJS
                        options={{
                          url: (img) => img.getAttribute("data-original"),
                        }}
                      >
                        <img
                          src={picture.thumb_file_path}
                          data-original={picture.file_path}
                          alt="Preview"
                          className="input_document_property_path"
                        />
                      </RViewerJS>
                    )}
                    <div className="document_image_label_name">
                      {picture.name}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        title={<h4>Are you sure you want to delete this document?</h4>}
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteScrunity();
          deletePreDisbursementDocument();
          deletePostDisbursementDocument();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      ></Modal>
    </div>
  );
};

export default DocumentDetails;
