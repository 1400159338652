import { axiosRequest } from "../../../utils/api/axiosRequest";

export const createAdhocHighmarkService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        "/api/v1/bureau/adhoc-request/get-adhoc-report/",
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listPurposeType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/bureau/adhoc-request/get-adhoc-report/inquiry-purpose/list`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listGenderType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/choices/gender`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listIdentificationType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/bureau/adhoc-request/get-adhoc-report/identification/choices`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const pincodeCodeService = (pincode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/master/pincode/get-data-by-pincode/?name=${pincode}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getallpincode = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/pincode/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllCityService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/city/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllDistrictService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/district/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllStateService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/state/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllCountriesService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/country/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
