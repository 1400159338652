import React, { useEffect, useState } from "react";
import { Col, DatePicker, Form, Input, Row, Select, message } from "antd";
import {
  listPincode,
  listState,
  listUnitsService,
  CustomerListService,
} from "../../services/propertyDescriptionService";

import "../../styles/legalDocumentInitiateInputForm.css";
import AddPropertyOwnerModal from "../AddPropertyOwnerModal";
import moment from "moment";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { pincodeCodeService } from "../../services/pincodeCodeService";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
const { Option } = Select;

const LegalPropertyDescriptionForm = ({
  yupSync,
  form,
  loanId,
  legalInputData,
  onOwnerChange,
}) => {
  const [collapsed1, setCollapsed1] = useState(true);
  const [pincode, setPincode] = useState([]);
  const [state, setState] = useState([]);
  const [units, setUnits] = useState([]);
  const [propertyOwner, setPropertyOwner] = useState([]);
  const [collapsedpropertydescription, setCollapsedpropertydescription] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [, setShowOtherInput] = useState(false);

  const fetchUnits = async () => {
    try {
      const response = await listUnitsService();
      setUnits(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const fetchCustomerName = async (loanId) => {
    try {
      const response = await CustomerListService(loanId);
      setPropertyOwner(response.data.customer_list);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    fetchUnits();
    fetchCustomerName(loanId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

 
  useEffect(() => {
    const getAllPincode = async () => {
      try {
        const response = await listPincode();
        setPincode(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllState = async () => {
      try {
        const response = await listState();
        setState(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    getAllPincode();
    getAllState();
  }, [form]);

  useEffect(() => {
    if (legalInputData?.legal_initiate_data) {
      const { transaction_type, property_address } =
        legalInputData.legal_initiate_data;
      form.setFieldsValue({
        transaction_type,
        address: property_address, 
      });
    }
  }, [legalInputData, form]);

  const handleOwnerChange = (value) => {
    if (value === "other") {
      setShowOtherInput(true);
      setIsModalVisible(true);
    } else {
      setShowOtherInput(false);
      setIsModalVisible(false);
      onOwnerChange(value);
    }
  };

  
  const handleAddPropertyOwner = (newOwner) => {
    const newOwnerObj = {
      id: propertyOwner.length + 1,
      first_name: newOwner,
      middle_name: newOwner,
      last_name: newOwner,
      name: newOwner,
    };
    setPropertyOwner([...propertyOwner, newOwnerObj]);
    form.setFieldsValue({ present_owner: newOwnerObj.name });
    onOwnerChange(newOwnerObj.name);
  };

  const toggleCollapsepropertydescription = () => {
    setCollapsedpropertydescription(!collapsedpropertydescription);
  };

  const handletextInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s,./()0-9/-]/g, ""); // Allow numbers
    form.setFieldsValue({ [field]: newValue });
  };

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  const handlePincodeChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.name === value);

    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, district, state, country } = response.data;
        form.setFieldsValue({
          city: city?.name || undefined,
          district: district?.name || undefined,
          state: state?.name || undefined,
          country: country?.name || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <div className="legal_initiate_input_main_container">
      <div className="legal_input_block_gap_heading_gap">
        <div className="property_description_data">Property Description</div>
        <div className="scrutiny_collapse_button " onClick={toggleCollapse1}>
          {collapsed1 ? <DownOutlined /> : <UpOutlined />}
          <div
            className="scrutiny_collapse_button"
            onClick={toggleCollapsepropertydescription}
          ></div>
        </div>
      </div>
      {collapsed1 && (
        <>
          <div className="Conclusion_content">
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item
                  name="transaction_type"
                  label="Transaction Type"
                  rules={[yupSync]}
                  required
                >
                  <Input placeholder="Please Enter Transaction Type" disabled />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="present_owner"
                  label="Property Owner"
                  rules={[yupSync]}
                  required
                >
                  <Select
                    mode="single"
                    onChange={handleOwnerChange}
                    placeholder="Please select"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {propertyOwner?.map((property) => {
                      return (
                        <Select.Option
                          key={property.id}
                          value={`${property?.name}`}
                        >
                          {property?.customer_type
                            ? `${property?.name} (${property.customer_type})`
                            : property?.name}
                        </Select.Option>
                      );
                    })}
                    <Option key="other" value="other">
                      Other
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
      
              <Col span={8}>
                <Form.Item
                  name="address"
                  label="Property Address"
                  rules={[yupSync]}
                  required
                >
                  <Input placeholder="Enter Property Address" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item
                  name="area_of_property"
                  label="Area of Property"
                  rules={[yupSync]}
                  required
                >
                  <Input placeholder="Please Enter Area of Property" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="unit_type"
                  label="Units"
                  rules={[yupSync]}
                  required
                >
                  <Select
                    mode="single"
                    placeholder="Please select"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {units?.map((unit) => (
                      <Option key={unit.value} value={unit.value}>
                        {unit.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="property_description_heading_two">
            <div className="property_description_data">As per Documents</div>
          </div>

          <div className="Conclusion_content">
            <div className="legal_input_as_per_document">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="address1"
                    label="Address Line 1"
                    rules={[yupSync]}
                    required
                  >
                    <Input
                      placeholder="Please Enter Address Line 1"
                      onChange={(e) => handletextInput(e, "address1")}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="address2"
                    label="Address Line 2"
                    rules={[yupSync]}
                    required
                  >
                    <Input
                      placeholder="Please Enter Address Line 2"
                      onChange={(e) => handletextInput(e, "address2")}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item
                    name="landmark"
                    label="Landmark"
                    rules={[yupSync]}
                    required
                  >
                    <Input
                      placeholder="Please Enter Landmark"
                      onChange={(e) => handletextInput(e, "landmark")}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="pincode"
                    label="Pincode"
                    rules={[yupSync]}
                    required
                  >
                    <Select
                      mode="single"
                      placeholder="Please Select Pincode"
                      required={true}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={handlePincodeChange}
                    >
                      {pincode?.map((pincode) => {
                        return (
                          <Select.Option key={pincode.id} value={pincode.name}>
                            {pincode.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="city"
                    label="City"
                    rules={[yupSync]}
                    required
                  >
                    <Input
                      placeholder="Please Enter City"
                      onChange={(e) => handletextInput(e, "city")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item
                    name="taluka"
                    label="Taluka"
                    rules={[yupSync]}
                    required
                  >
                    <Input
                      placeholder="Please Enter Taluka"
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="district"
                    label="District"
                    rules={[yupSync]}
                    required
                  >
                    <Input
                      placeholder="Please Enter District"
                      onChange={(e) => handletextInput(e, "district")}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[yupSync]}
                    required
                  >
                    <Select
                      mode="single"
                      placeholder="Please Select State"
                      required={true}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {state?.map((state) => {
                        return (
                          <Select.Option key={state.id} value={state.id}>
                            {state.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item
                    name="country"
                    label="Country"
                    rules={[yupSync]}
                    required
                  >

                    <Input
                      placeholder="Please Enter Taluka"
                      onChange={(e) => handletextInput(e, "country")}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item
                    name="description_east"
                    label="On East Side"
                    rules={[yupSync]}
                    required
                  >
                    <Input
                      placeholder="Please Enter On East Side"
                      onChange={(e) => handletextInput(e, "description_east")}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="description_west"
                    label="On West Side"
                    rules={[yupSync]}
                    required
                  >
                    <Input
                      placeholder="Please Enter On West Side"
                      onChange={(e) => handletextInput(e, "description_west")}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="description_north"
                    label="On North Side"
                    rules={[yupSync]}
                    required
                  >
                    <Input
                      placeholder="Please Enter On North Side"
                      onChange={(e) => handletextInput(e, "description_north")}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item
                    name="description_south"
                    label="On South Side"
                    rules={[yupSync]}
                    required
                  >
                    <Input
                      placeholder="Please Enter On South Side"
                      onChange={(e) => handletextInput(e, "description_south")}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="date_of_report"
                    label="Date of Report"
                    required
                  >
                    <DatePicker
                    disabledDate={(current) => current && current > moment().endOf("day")}
                      className="legal_input_as_per_document_date"
                      format="DD-MM-YYYY"
                      placeholder="DD-MM-YYYY"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}

      <AddPropertyOwnerModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onAddPropertyOwner={handleAddPropertyOwner}
          existingOwners={propertyOwner}

      />
    </div>

    
  );
};

export default LegalPropertyDescriptionForm;
