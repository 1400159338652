import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import {
  updateAddressDetailsByIdService,
  viewAddressDetailsByIdService,
  getallpincode,
  getAllDistrictService,
  getAllStateService,
  getAllCountriesService,
  getAllCityService,
  createAddressService,
  pincodeCodeService,
} from "../services/addressDetailsServices";
import * as Yup from "yup";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import { addNewCustomerAddress } from "../../../../../redux/salesFormSlice";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  address_line_1: Yup.string()
    .required("Address line 1 is required")
    .min(2, "Address line 1 must be atleast 2 characters")
    .max(225, "Address line 1 must be at max 225 characters"),
  address_line_2: Yup.string()
    .required("Address line 2 is required")
    .min(2, "Address line 2 must be atleast 2 characters")
    .max(225, "Address line 2 must be at max 225 characters"),
  landmark: Yup.string()
    .required("Landmark is required")
    .min(2, "Landmark must be atleast 2 characters")
    .max(128, "Landmark must be at max 128 characters"),
  city: Yup.string().required("City is required"),
  taluka: Yup.string()
  .matches(/^[A-Za-z ]+$/, "Taluka can only contain letters")
  .required("Taluka is required"),
  pincode: Yup.string()
    .required("Pincode is required")
    .matches(/^[0-9]+$/, "Pincode should contain numbers only"),
  district: Yup.string().required("District is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const OfficeForm = ({
  refreshData,
  closeForm,
  id,
  open,
  customer_id,
  selectedAddressType,
  isSalesForm,
  activeCustomerKey,
  activeAddressKey,
}) => {
  const [form] = Form.useForm();
  const [pincode, setPincode] = useState();
  const [city, setCity] = useState();
  const [district, setDistrict] = useState();
  const [states, setStates] = useState();
  const [country, setCountry] = useState();
  const { encrypted_loan_id } = useParams();
  const [loading, setLoading] = useState(false);
  const latitude = useSelector((state) => state?.user?.user_location?.latitude);
  const longitude = useSelector(
    (state) => state?.user?.user_location?.longitude
  );
  const dispatch = useDispatch();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const getOfficeData = async (id) => {
    try {
      const response = await viewAddressDetailsByIdService(id);
      const {
        address_line_1,
        address_line_2,
        landmark,
        city,
        taluka,
        district,
        state,
        pincode,
        country,
      } = response?.data;
      form.setFieldsValue({
        address_line_1,
        address_line_2,
        landmark,
        city: city?.id,
        taluka: taluka,
        district: district?.id,
        state: state?.id,
        pincode: pincode?.id,
        country: country?.id,
      });
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (id) {
        const response = await updateAddressDetailsByIdService(id, values);
        if (response && response.data) {
          if (response.status === 200 && response.success) {
            message.success("Address details updated successfully");
            if (!isSalesForm) {
              refreshData();
              closeForm();
            }
          }
        } else {
          // Handle the case where response or response.data is undefined
          message.error("Failed to update address details");
        }
      } else {
        values.loan_detail = parseInt(loan_id);
        values.customer_detail = customer_id;
        values.address_type = selectedAddressType;
        values.latitude = latitude;
        values.longitude = longitude;
        const response = await createAddressService(values);
        if ((response.status = 200 && response.success)) {
          message.success("Address details successfully created");
          if (typeof isSalesForm === "undefined") {
            refreshData();
            closeForm();
          }
          if (
            typeof activeAddressKey !== "undefined" &&
            typeof activeCustomerKey !== "undefined"
          ) {
            dispatch(
              addNewCustomerAddress({
                activeCustomerKey: activeCustomerKey,
                activeAddressKey: activeAddressKey,
                id: response?.data?.id,
              })
            );
          }
        }
      }
    } catch (error) {
      if (typeof isSalesForm === "undefined") {
         closeForm();
      }
         message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePincodeChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.id === value);
    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, district, state, country } = response.data;
        form.setFieldsValue({
          city: city?.id || undefined,
          district: district?.id || undefined,
          state: state?.id || undefined,
          country: country?.id || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    const getAllPincodelist = async () => {
      try {
        const response = await getallpincode();
        setPincode(response.data);
      } catch (error) {
        closeForm();
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCitylist = async () => {
      try {
        const response = await getAllCityService();
        setCity(response.data);
      } catch (error) {
        closeForm();
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllDistrict = async () => {
      try {
        const response = await getAllDistrictService();
        setDistrict(response.data);
      } catch (error) {
        closeForm();
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllState = async () => {
      try {
        const response = await getAllStateService();
        setStates(response.data);
      } catch (error) {
        closeForm();
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCountries = async () => {
      try {
        const response = await getAllCountriesService();
        setCountry(response.data);
      } catch (error) {
        closeForm();
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllPincodelist();
    getAllCitylist();
    getAllDistrict();
    getAllState();
    getAllCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

 
  const handleInput = (e, field) => {
    // Allow letters, numbers, comma, forward slash, and empty spaces
    const newValue = e.target.value.replace(/[^A-Za-z0-9,/\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    if (id) {
      getOfficeData(id);

      // getAddressType();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        address_line_1: "",
        address_line_2: "",
        address_type: "",
        landmark: "",
      }}
    >
      <Row gutter={16} className="address_type_form">
        <Col span={8}>
          <Form.Item
            name="address_line_1"
            label="Address Line 1"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 1"
              onChange={(e) => handleInput(e, "address_line_1")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="address_line_2"
            label="Address Line 2"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 2"
              onChange={(e) => handleInput(e, "address_line_2")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="landmark"
            label="Landmark"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Landmark"
              onChange={(e) => handleInput(e, "landmark")}
            />
          </Form.Item>
        </Col>
      </Row>


      <Row gutter={16} className="address_type_form">
        <Col span={8}>
          <Form.Item name="pincode" label="Pincode" rules={[yupSync]} required>
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              placeholder="Please Select"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handlePincodeChange}
            >
              {pincode?.map((pincodes, index) => (
                <Option key={pincodes.id} value={pincodes.id}>
                  {pincodes.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="city" label="City" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {city?.map((locations, index) => (
                <Option key={locations.id} value={locations.id}>
                  {locations.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="taluka" label="Taluka" rules={[yupSync]} required>
            <Input placeholder="Please enter taluka" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="address_type_form">
        <Col span={8}>
          <Form.Item
            name="district"
            label="District"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {district?.map((districts, index) => (
                <Option key={districts.id} value={districts.id}>
                  {districts.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="state" label="State" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {states?.map((states, index) => (
                <Option key={states.id} value={states.id}>
                  {states.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="country" label="Country" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {country?.map((countries, index) => (
                <Option key={countries.id} value={countries.id}>
                  {countries.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        className="address_button"

      >
        {isSalesForm ? (
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        ) : (
          <>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
            <Button onClick={closeForm}>Cancel</Button>
          </>
        )}
      </Space>
    </Form>
  );
};

export default OfficeForm;
