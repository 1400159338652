
import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getDependentDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/dependent-details/dependent-details/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listAddressTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/address-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const createDependentService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/dependent-details/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getDependentByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/dependent-details/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const updateDependentDetailsByIdService = (loan_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/dependent-details/${loan_id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listRelationService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/relation/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listGenderService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/gender`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const pincodeCodeService = (pincode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/pincode/get-data-by-pincode/?name=${pincode}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getallpincode = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/pincode/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getAllCityService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/city/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllDistrictService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/district/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllStateService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/state/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllCountriesService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/country/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const listAddressType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/address-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const deleteDependentDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/dependent-details/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
