import React, { useEffect, useState } from "react";
import { Table, message, Drawer } from "antd";
import { listCustomerNameService, getCAMDetailsCustomerId, checkEmploymentDetailsByCustomerId } from "../services/incomeDetailsServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Header from "../../../../layout/views/Header";
import IncomeDetailsForm from "./IncomeDetailsForm";
import IndianNumberFormat from "../../../../../utils/indianNumberFormat/IndianNumberFormat";


const IncomeDetails = ({ toggleRefreshTableData }) => {
  const { encrypted_loan_id } = useParams();
  const [customerData, setCustomerData] = useState([]);
  const [customerDataByCustomer, setCustomerDataByCustomer] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedScheme, setSelectedScheme] = useState(null);
  const [selectedIncomeConsidered, setIncomeConsidered] = useState(null);
  const [mergedCustomerData, setMergedCustomerData] = useState([]);
  const [getIncomeDetailsRefresh, setIncomeDetailsRefresh] = useState(false);

  const toggleIncomeDetailsData = () => {
    setIncomeDetailsRefresh((prev) => !prev);
  };

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedPerson(null);
    setSelectedScheme(null);
  };

  useEffect(() => {
    const getAllCustomerNames = async () => {
      try {
        const response = await listCustomerNameService(loan_id);
        if (response?.data?.customer_list) {
          setCustomerData(
            response.data.customer_list.map((customer, index) => ({
              key: index,
              customerName: `${customer.first_name || ""} ${customer.middle_name || ""} ${customer.last_name || ""}`.trim() || "-",
              id: customer.id,
            }))
          );
        }
      } catch (error) {
        message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
      }
    };

    const getAllCustomerData = async () => {
      try {
        const response = await getCAMDetailsCustomerId(loan_id);
        if (response?.data?.customers) {
          setCustomerDataByCustomer(response.data.customers);
          toggleRefreshTableData();
        }
      } catch (error) {
        message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
        setCustomerDataByCustomer([]);
      }
    };


    getAllCustomerNames();
    getAllCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, getIncomeDetailsRefresh]);

  // useEffect(() => {
  //   const mergeCustomerData = async () => {
  //     setMergedCustomerData((prevCustomers) =>
  //       customerData.map((customer) => {
  //         // Ensure customerDataByCustomer is an array before calling .find()
  //         const matchedIncome = Array.isArray(customerDataByCustomer)
  //           ? customerDataByCustomer.find(incomeData => incomeData.customer_detail_id === customer.id)
  //           : undefined;
  //         // if (matchedIncome) {
  //         //   setSelectedScheme(matchedIncome?.scheme_detail?.code);
  //         // }
  //         return {

  //           ...customer,
  //           code: matchedIncome?.scheme_detail?.code,
  //           scheme: matchedIncome?.scheme_detail?.name || "-",
  //           netMonthlyIncome: matchedIncome?.appraised_monthly_income || "-",
  //           income: matchedIncome ? (matchedIncome.income_considered ? true : false) : "-",
  //           incomeConsidered: matchedIncome ? (matchedIncome.income_considered ? "Yes" : "No") : "-",
  //           monthlyObligation: matchedIncome?.appraised_obligation || "-",
  //           foir: matchedIncome?.foir || "-",
  //           maxEmi: matchedIncome?.max_emi || "-",
  //           emiFactor: matchedIncome?.emi_factor || "-",
  //           eligibility: matchedIncome?.eligibility || "-",
  //         };
  //       })
  //     );
  //   };
  //   mergeCustomerData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loan_id, customerData]);

  useEffect(() => {
    const mergeCustomerData = async () => {
      const formattedCustomers = [];

      customerData.forEach((customer) => {
        const matchedCustomer = Array.isArray(customerDataByCustomer)
          ? customerDataByCustomer.find(incomeData => incomeData.customer_detail_id === customer.id)
          : undefined;

        if (matchedCustomer && matchedCustomer.income_details.length > 0) {
          matchedCustomer.income_details.forEach((incomeDetail, index) => {
            formattedCustomers.push({
              key: `${customer.id}-${index}`,
              id: customer.id,
              matching: true,
              customerName: customer.customerName || "-",
              incomeDetailsCount: matchedCustomer.income_details.length, // Needed for rowSpan
              isFirstRow: index === 0, // Identify first row for rowSpan
              rowSpan: index === 0 ? matchedCustomer.income_details.length : 0, // Set rowspan for first row only
              code: incomeDetail?.scheme_detail?.code || "-",
              scheme: incomeDetail?.scheme_detail?.name || "-",
              netMonthlyIncome: incomeDetail?.appraised_monthly_income || "-",
              income: incomeDetail ? (incomeDetail.income_considered ? true : false) : "-",
              incomeConsidered: incomeDetail ? (incomeDetail.income_considered ? "Yes" : "No") : "-",
              monthlyObligation: incomeDetail?.appraised_obligation || "-",
              foir: incomeDetail?.foir || "-",
              maxEmi: incomeDetail?.max_emi || "-",
              emiFactor: incomeDetail?.emi_factor || "-",
              eligibility: incomeDetail?.eligibility || "-",
            });
          });
        } else {
          formattedCustomers.push({
            key: `${customer.id}-0`,
            matching: false,
            id: customer.id,
            customerName: customer.customerName || "-", // Keep original name
            incomeDetailsCount: 1, // For rowSpan
            isFirstRow: true, // Since it's a single row
            rowSpan: 1, // Set to 1 for customers without income details
            code: "-",
            scheme: "-",
            netMonthlyIncome: "-",
            income: "-",
            incomeConsidered: "-",
            monthlyObligation: "-",
            foir: "-",
            maxEmi: "-",
            emiFactor: "-",
            eligibility: "-",
          });
        }
      });

      setMergedCustomerData(formattedCustomers);
    };

    mergeCustomerData();
  }, [loan_id, customerData, customerDataByCustomer]);


  const handleAddClick = (record) => {
    setSelectedPerson(record?.id);
    // setSelectedScheme(record?.code);
    // setIncomeConsidered(record?.income)
    showDrawer();
  };
  const handleEditClick = (record) => {
    setSelectedPerson(record?.id);
    setSelectedScheme(record?.code);
    setIncomeConsidered(record?.income)
    showDrawer();
  };
  const handleCheckAndProceed = async (record) => {
    try {
      const response = await checkEmploymentDetailsByCustomerId(record.id);
      if ((response.status = 200 && response.success)) {
        handleAddClick(record);
      } else {
        message.error(response?.message);
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };



  const columns = [
    // {
    //   title: "Customer Name",
    //   dataIndex: "customerName",
    //   key: "customerName",
    //   fixed: "left",
    //   render: (text) => (text ? text : "-"),
    // },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      fixed: "left",
      render: (text, record) => ({
        children: (
          <div style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "space-between" }}>
            <div>{text}</div>
            <div>
              <PlusCircleOutlined
                style={{ color: "rgb(39, 101, 143)", cursor: "pointer" }}
                onClick={() => handleCheckAndProceed(record)}
              />
            </div>
          </div>
        ),
        props: { rowSpan: record.rowSpan }, // Ensure rowspan works correctly
      }),
    },
    {
      title: "Scheme",
      dataIndex: "scheme",
      key: "scheme",
      render: (text) => (text !== null && text !== undefined && text !== "" && text !== "-" ? text : '-'),
    },
    {
      title: "Appraised Net Monthly Income",
      dataIndex: "netMonthlyIncome",
      key: "netMonthlyIncome",
      render: (text) => (text !== null && text !== undefined && text !== "" && text !== "-" ? `₹ ${IndianNumberFormat(text)}` : '-'),
    },
    {
      title: "Income Considered",
      dataIndex: "incomeConsidered",
      key: "incomeConsidered",
      render: (text) => (text !== null && text !== undefined && text !== "" && text !== "-" ? text : '-'),
    },
    {
      title: "Monthly Obligation",
      dataIndex: "monthlyObligation",
      key: "monthlyObligation",
      render: (text) => (text !== null && text !== undefined && text !== "" && text !== "-" ? `₹ ${IndianNumberFormat(text)}` : '-'),
    },
    {
      title: "FOIR (As Per Policy)",
      dataIndex: "foir",
      key: "foir",
      render: (text) => (text !== null && text !== undefined && text !== "" && text !== "-" ? `${IndianNumberFormat(text)}` : '-'),
    },
    {
      title: "Max EMI",
      dataIndex: "maxEmi",
      key: "maxEmi",
      render: (text) => (text !== null && text !== undefined && text !== "" && text !== "-" ? `₹ ${IndianNumberFormat(text)}` : '-'),
    },
    {
      title: "EMI Factor",
      dataIndex: "emiFactor",
      key: "emiFactor",
      render: (text) => (text !== null && text !== undefined && text !== "" && text !== "-" ? `₹ ${IndianNumberFormat(text)}` : '-'),
    },
    {
      title: "Eligibility",
      dataIndex: "eligibility",
      key: "eligibility",
      render: (text) => (text !== null && text !== undefined && text !== "" && text !== "-" ? `₹ ${IndianNumberFormat(text)}` : '-'),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (_, record) => {
        if (record.matching === false) {
          return "-";
        }

        return (
          <EditOutlined
            style={{ color: "rgb(39, 101, 143)", cursor: "pointer" }}
            onClick={() => handleEditClick(record)}
          />
        );
      },
      // render: (_, record) => {
      //   // Check if the customer's `id` exists in `customer_detail_id` from customerDataByCustomer
      //   const matchedCustomer = customerDataByCustomer.find(
      //     (customer) => customer.customer_detail_id === record.id
      //   );

      //   return matchedCustomer ? (
      //     <EditOutlined
      //       style={{ color: "rgb(39, 101, 143)", cursor: "pointer" }}
      //       onClick={() => handleAddClick(record)}
      //     />
      //   ) : (
      //     <PlusCircleOutlined
      //       style={{ color: "rgb(39, 101, 143)", cursor: "pointer" }}
      //       onClick={() => handleCheckAndProceed(record)}
      //     />
      //   );
      // },

    },
  ];

  return (
    <div className="income_calculations_container">
      <Drawer
        title={
          <Header
            title={selectedPerson ? "Edit" : "Add"}
            onClose={onClose}
            name={"Income details"}
          />
        }
        width={1000}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <IncomeDetailsForm
          onClose={onClose}
          open={open}
          toggleRefreshTableData={toggleIncomeDetailsData}
          selectedPerson={selectedPerson}
          selectedSchemeIncome={selectedScheme}
          selectedIncomeConsidered={selectedIncomeConsidered}
        />
      </Drawer>
      <Table
        columns={columns}
        dataSource={mergedCustomerData}
        pagination={false}
        className="insurance_table"
        style={{ marginBottom: "10px" }} />
    </div>
  );
};

export default IncomeDetails;
