import { message } from "antd";
import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getLoanInsuranceByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-detail/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAdditionalLoanDetailIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/additional-loan-detail/get-all-data/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getInsuranceByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/loan/insurance-detail/icici-prudential/icici-insurance-detail-by-loan/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getChargesByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/charge-details/charge-detail-by-loan/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getMagmaListByIdService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/insurance-plan-magma/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllStateService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/state/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllCityService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/city/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getallpincode = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/pincode/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const postICICIPrudentail = async (data) => {
  try {
    const response = await axiosRequest.post(`api/v1/loan/insurance-detail/icici-prudential/`, data);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;  // rethrow the error to be handled by the calling function
  }
};

export const postHDFCErgo = async (data) => {
  try {
    const response = await axiosRequest.post(`/api/v1/loan/insurance-detail/hdfc-ergo/`, data);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;  // rethrow the error to be handled by the calling function
  }
};

export const getAllPropertyTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/property-type/`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getAllOccuptationTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/occupation/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllDocumentTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/document-type/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const postMagmaHDI = async (data,setLoading) => {
  try {
    const response = await axiosRequest.post(`/api/v1/loan/insurance-detail/magma-hdi/`, data);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    setLoading(false)
    throw error;  // rethrow the error to be handled by the calling function
  }
};

export const getAllRelationTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/relation/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllApplicantTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/customer-type/customer-type-list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getICICIQuestionTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/icici-questionnaire/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getPersonalDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-detail/personal-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getLoanDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getBranchAddressByIdService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/locations/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getNationalityByIdService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/nationality`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getRelationByIdService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/relation/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}



export const getInsuranceCompaniesByIdService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/insurance-company/`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getInsurancesByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/insurance-detail/insurance-list/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getInsuranceDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/insurance-detail/insurance-detail-by-insurance-id/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getMagmaPlanningList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/insurance-plan-magma/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const EditHDFCErgo = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/insurance-detail/hdfc-ergo/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
};

export const updateICICIPrudential = async (data) => {
  try {
    const response = await axiosRequest.patch(`/api/v1/loan/insurance-detail/icici-prudential/`, data);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;  // rethrow the error to be handled by the calling function
  }
};

export const postEditMagma = async (data,setLoading) => {
  try {
    const response = await axiosRequest.patch(`/api/v1/loan/insurance-detail/magma-hdi/`, data);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    setLoading(false)
    return message.error(error?.response?.data?.message)
  }
};

export const deleteHDFCErgo = async (id) => {
  try {
    const response = await axiosRequest.delete(`/api/v1/loan/insurance-detail/hdfc-ergo/${id}`,);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;  // rethrow the error to be handled by the calling function
  }
};

export const deleteICICIPrudential = async (id) => {
  try {
    const response = await axiosRequest.delete(`/api/v1/loan/insurance-detail/icici-prudential/${id}`,);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;  // rethrow the error to be handled by the calling function
  }
};

export const deleteMagmaHdi = async (id) => {
  try {
    const response = await axiosRequest.delete(`/api/v1/loan/insurance-detail/magma-hdi/${id}`,);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;  // rethrow the error to be handled by the calling function
  }
};


export const deleteHDFCDocumentDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/loan/insurance-document/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getInsuranceDetailsCamService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/insurance-detail/insurance_detail_cam_check/${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const createChargesUnderwritingService = async (data) => {
  try {
    const response = await axiosRequest.post(`api/v1/loan/charge-details/`, data);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;  // rethrow the error to be handled by the calling function
  }
};

export const updateChargesUnderwritingServiceById = async (id, data) => {
  try {
    const response = await axiosRequest.patch(`api/v1/loan/charge-details/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;  // rethrow the error to be handled by the calling function
  }
};

export const listChargeStatusService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/charge-status/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listChargeNameServices = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/charge/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getChargesDataByIdServices = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/loan/charge-details/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getInsuranceAddressTypeList = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/address-type/insurance-address-type-list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAddressDetailByAddresType = (customer_id, addressType) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/customer/customer-address-detail/address-detail-by-address-type/${customer_id}?address_type=${addressType}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const insurancePincodeCodeService = (pincode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/pincode/get-data-by-pincode/?name=${pincode}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getDocumentDetailByDocumentType = (customer_id, documentType) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/document-detail/document-detail-by-document-type/${customer_id}?document_type=${documentType}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const editLoanDetailsByLoanId = async (loan_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/additional-loan-detail/update-loan-detail-calculations/${loan_id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
};

export const getTestProgramList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/test-program/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getDueDate = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/get-emi-due-date`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}