import React from "react";
import {
  FileImageOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileWordOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  VideoCameraOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import ReactPlayer from "react-player";
import RViewerJS from "viewerjs-react";

const FileRenderer = ({ fileUrl, fileType }) => {
  // Function to determine which icon to use
  const getFileIcon = () => {
    if (fileType.startsWith("image/"))
      return (
        <FileImageOutlined
          className="file_icon"
          style={{ fontSize: 40, color: "#1890ff" }}
        />
      );
    if (fileType.startsWith("video/"))
      return (
        <VideoCameraOutlined
          className="file_icon"
          style={{ fontSize: 40, color: "#52c41a" }}
        />
      );
    if (fileType.startsWith("audio/"))
      return (
        <AudioOutlined
          className="file_icon"
          style={{ fontSize: 40, color: "#faad14" }}
        />
      );
    if (fileType === "application/pdf")
      return (
        <FilePdfOutlined
          className="file_icon"
          style={{ fontSize: 40, color: "red" }}
        />
      );
    if (fileType.includes("excel") || fileType.includes("spreadsheet"))
      return (
        <FileExcelOutlined
          className="file_icon"
          style={{ fontSize: 40, color: "green" }}
        />
      );
    if (fileType.includes("word"))
      return (
        <FileWordOutlined
          className="file_icon"
          style={{ fontSize: 40, color: "blue" }}
        />
      );
    if (fileType.includes("text") || fileType.includes("plain"))
      return (
        <FileTextOutlined
          className="file_icon"
          style={{ fontSize: 40, color: "#8c8c8c" }}
        />
      );
    return (
      <FileUnknownOutlined
        className="file_icon"
        style={{ fontSize: 40, color: "#595959" }}
      />
    ); // Default unknown file
  };

  return (
    <div>
      {fileType.startsWith("image/") ? (
        <RViewerJS>
          <img src={fileUrl} alt="Chat File" className="chat_image" />
        </RViewerJS>
      ) : fileType.startsWith("video/") || fileType.startsWith("audio/") ? (
        <ReactPlayer
          url={fileUrl}
          controls
          width="100%"
          height="auto"
          className="react-player"
        />
      ) : (
        <a target="_blank" rel="noreferrer" href={fileUrl} download>
          {getFileIcon()}
        </a>
      )}
    </div>
  );
};

export default FileRenderer;
