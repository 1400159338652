import { axiosRequest } from "../../../../../../utils/api/axiosRequest";

export const listUserService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/user/get-user-list-by-loan-location?loan_id=${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const initiateFinanciallApproval = (loan_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(`/api/v1/loan/initiate-stage/finacial-approval-initiate/${loan_id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}   