import {
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Space,
  Button,
  message,
  Input,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import "../styles/RCUInitiationForm.css";
import {
  getRCUProcessListById,
  getRCUProcessDetailsById,
  postRCUInitiate,
  getRCUoptions,
} from "../services/rcuinitiationform";
import ErrorMessage from "../../../../../../utils/errorHandling/ErrorMessage";
import pdf from "../images/Pdf.png";
import { PlusOutlined, FileOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import RViewerJS from "viewerjs-react";
import Loading from "../../../../../../utils/loading/Loading";
import TextArea from "antd/es/input/TextArea";
import xls from "../asset/xls.png";
import video from "../asset/clapperboard.png";
import { getAllVendorSelectionTypeService, getAllVendorTypeService, getDocumentByIdService } from "../../LegalInitiationForm/Services/legalInitiationService";
import dummy_img from "../asset/dummy.png";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  check_type: Yup.string()
    .required("Vendor Type is required")
    .min(1, "Vendor Type is required"),
  user: Yup.string()
    .required("Internal RCU Manager is required")
    .min(1, "Internal RCU Manager is required"),
  agency: Yup.string()
    .required(" Vendor is required")
    .min(1, "Vendor is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const RCUInitiationForm = ({
  open,
  loan_id,
  closeForm,
  agencyCode,
  setSubmitStageBar,
}) => {
  const [form] = Form.useForm();
  const [rcu, setRCU] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [, setImages] = useState([]);
  const [sampledata, setSampledata] = useState();
  const { user_data } = useSelector((state) => state.user);
  const [modifiedDatas, setModifiedDatas] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [value, setValue] = useState("");
  const [rcutype, setRcutype] = useState([]);
  const [choice, setChoice] = useState("");
  const [loading, setLoading] = useState(true);
  const [customerName, setCustomerName] = useState("");
  const [applicantType, setApplicantType] = useState("");
  const [selectedVendorType, setSelectedVendorType] = useState(null);
  const [vendorType, setVendorType] = useState([]);
  const [vendorExternalType, setVendorExternalType] = useState([]);
  const [vendorInternalType, setVendorInternalType] = useState([]);

  const [internalVendorEmailMap, setInternalVendorEmailMap] = useState({});
  const [externalVendorEmailMap, setExternalVendorEmailMap] = useState({});
  const [existingDocs, setExistingDocs] = useState([]);
  const [checkedDocs, setCheckedDocs] = useState([]);

  const handleVendorChange = (value) => {
    if (selectedVendorType === "Internal") {
      form.setFieldsValue({ mail_to: internalVendorEmailMap[value] || "" });
    } else if (selectedVendorType === "External") {
      form.setFieldsValue({ mail_to: externalVendorEmailMap[value] || "" });
    }
  };


  const handleVendorTypeChange = async (value) => {
    setSelectedVendorType(value);
    form.setFieldsValue({ user: undefined, agency: undefined, mail_to: null });
    try {
      if (value === "Internal") {
        const response = await getAllVendorSelectionTypeService("Internal", "RCU", loan_id);
        setVendorInternalType(response.data);

        const internalEmailMapping = {};
        response.data.forEach((vendor) => {
          internalEmailMapping[vendor.user_id] = vendor.work_email;
        });
        setInternalVendorEmailMap(internalEmailMapping);
      }
      else if (value === "External") {
        const response = await getAllVendorSelectionTypeService("External", "RCU", loan_id);
        setVendorExternalType(response.data);

        const externalEmailMapping = {};
        response.data.forEach((vendor) => {
          externalEmailMapping[vendor.id] = vendor.work_email;
        });
        setExternalVendorEmailMap(externalEmailMapping);
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (sampledata?.customer_document_address_detail) {
      const applicant = sampledata?.customer_document_address_detail?.find(
        (customer) =>
          customer.customer_type?.name === "Applicant" ||
          customer.customer_type?.name === "Company Applicant"
      );
      setCustomerName(applicant?.customer_name?.full_name || "");
      setApplicantType(applicant?.customer_type?.name);
    }
  }, [sampledata]);

  const generateMailSubject = () => {
    const locationName = sampledata?.loan_detail?.location?.display_name || "";
    const loanAccountNumber =
      sampledata?.loan_detail?.loan_account_number || "";

    // Use the customerName from state directly
    const applicant = sampledata?.customer_document_address_detail?.find(
      (customer) =>
        customer.customer_type?.name === "Applicant" ||
        customer.customer_type?.name === "Company Applicant"
    );

    return `RCU initiate - ${loanAccountNumber} - ${customerName} (${applicant?.customer_type?.name}) ${locationName}`;
  };

  const handleUpload = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const trimFileName = (name, maxLength = 10) => {
    if (name.length > maxLength) {
      return `${name.substring(0, maxLength)}...`;
    }
    return name;
  };

  const getRcutypecheck = async () => {
    try {
      const response = await getRCUoptions();
      setRcutype(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  // const internalVendorResponse = async () => {
  //   try {
  //     const response = await getAllVendorSelectionTypeService("Internal", "RCU", loan_id);
  //     setVendorInternalType(response.data);

  //     const internalEmailMapping = {};
  //     response.data.forEach((vendor) => {
  //       internalEmailMapping[vendor.user_id] = vendor.work_email;
  //     });
  //     setInternalVendorEmailMap(internalEmailMapping);
  //   } catch (error) {
  //     message.error(
  //       ErrorMessage(error?.response?.status, error?.response?.data?.message)
  //     );
  //   }
  // };

  // const externalVendorResponse = async () => {
  //   try {
  //     const response = await getAllVendorSelectionTypeService("External", "RCU", loan_id);
  //     setVendorExternalType(response.data);

  //     const externalEmailMapping = {};
  //     response.data.forEach((vendor) => {
  //       externalEmailMapping[vendor.id] = vendor.work_email;
  //     });
  //     setExternalVendorEmailMap(externalEmailMapping);
  //   } catch (error) {
  //     message.error(
  //       ErrorMessage(error?.response?.status, error?.response?.data?.message)
  //     );
  //   }
  // };

  const VendorTypeResponse = async () => {
    try {
      const response = await getAllVendorTypeService();
      setVendorType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllRCU = async () => {
    try {
      const response = await getRCUProcessListById();
      setRCU(response?.data?.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllRCUData = async () => {
    try {
      const response = await getRCUProcessDetailsById(loan_id);
      setSampledata(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (open) {
      // internalVendorResponse();
      // externalVendorResponse();
      VendorTypeResponse();
      getAllRCU();
      getRcutypecheck();
      getAllRCUData();
    } else {
      form.resetFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, loan_id]);

  const handleBeforeUpload = async (file) => {
    const base64 = await convertToBase64(file);

    const newImage = {
      file_path: URL.createObjectURL(file),
      uid: file.uid,
      name: file.name,
      base64_url: base64, // Add the base64 URL to the newImage object
    };

    setImages((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    setAttachments((prevAttachments) => [...prevAttachments, base64]);

    return false;
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!(file instanceof Blob)) {
        reject(new Error("File is not a valid Blob"));
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (sampledata) {
      const modifiedDatass = sampledata?.customer_document_address_detail?.map(
        (detail) => {
          const modifiedDocuments = {};

          for (const category in detail?.customer_document) {
            if (detail?.customer_document?.hasOwnProperty(category)) {
              modifiedDocuments[category] = detail?.customer_document[
                category
              ]?.map((doc) => ({
                ...doc,
                dropdown_value: "", // Initialize dropdown_value with an empty string or any default value
              }));
            }
          }

          return {
            ...detail,
            customer_document: modifiedDocuments,
          };
        }
      );

      setModifiedDatas(modifiedDatass);
      setLoading(false);
    }
  }, [sampledata]);

  const handleSubmit = async (values) => {
    setLoading(true);

    const mailBody = `
    <span style="padding: 10px 10px 0px 10px;width: 77%;">
      Dear Team,<br>
      Please initiate the RCU Search for ${sampledata?.customer_document_address_detail
        ?.filter((customer) => customer?.customer_type?.name === "Applicant")
        ?.map((customer) => `${customer?.customer_name?.full_name || ""}`)
        .join(", ")} (Applicant).<br><br>
      <div style=" margin-top: 10px;
      border: 1px solid #b8b3b373;
      border-radius: 8px;">
        <div style="  border-bottom: 1px solid #b8b3b373;display: flex;flex-direction: row;">
          <div style="  width: 35%;font-family: Roboto;
          font-size: 14px;font-weight: 400; 
          padding: 10px;text-align: left;
          color: #000000B2;margin-left: 8px;
          border-right: 1px solid #D9D9D9;">Customer Name</div>
          <div style="  padding: 10px;
          width: 65%;font-family: Roboto;
          font-size: 14px; font-weight: 500;
          line-height: 22px;
          text-align: left;
  color: #000000B2;
  margin-left: 8px;">${customerName} (${applicantType})</div>
        </div>
        <div style="  border-bottom: 1px solid #b8b3b373;
        display: flex;flex-direction: row;">
          <div style="  width: 35%;
  font-family: Roboto;font-size: 14px;
  font-weight: 400;padding: 10px;
  text-align: left;
  color: #000000B2;
  margin-left: 8px;
  border-right: 1px solid #D9D9D9;">LAN</div>
          <div style=" padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">${sampledata?.loan_detail?.loan_account_number || "N/A"
      }</div>
        </div>
        <div style="  border-bottom: 1px solid #b8b3b373;display: flex;flex-direction: row;">
          <div style="  width: 35%;font-family: Roboto;font-size: 14px;font-weight: 400;padding: 10px;text-align: left;color: #000000B2;margin-left: 8px;border-right: 1px solid #D9D9D9;">Product</div>
          <div style=" padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">${sampledata?.loan_detail?.loan_type?.display_name || "N/A"
      }</div>
        </div>
        <div style="border-bottom: 1px solid #b8b3b373;display: flex;flex-direction: row;">
          <div style="  width: 35%;font-family: Roboto;font-size: 14px;font-weight: 400;padding: 10px;text-align: left;color: #000000B2;margin-left: 8px;border-right: 1px solid #D9D9D9;">Transaction Type</div>
          <div style="  padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">${choice || "N/A"
      }</div>
        </div>
        <div style="border-bottom: 1px solid #b8b3b373;display: flex;flex-direction: row;">
          <div style="width: 35%;font-family: Roboto;font-size: 14px;font-weight: 400;padding: 10px;text-align: left;color: #000000B2;
  margin-left: 8px;border-right: 1px solid #D9D9D9;">Property Address</div>
          <div style=" padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">
            ${sampledata?.property_detail?.address_line_1 || ""}, 
            ${sampledata?.property_detail?.address_line_2 || ""}, 
            ${sampledata?.property_detail?.city?.name || ""}, 
            ${sampledata?.property_detail?.district?.name || ""}, 
            ${sampledata?.property_detail?.taluka || ""}, 
            ${sampledata?.property_detail?.state?.name || ""}, 
            ${sampledata?.property_detail?.pincode?.name || ""}
          </div>
        </div>
        <div style="  border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;">
          <div style="  width: 35%;font-family: Roboto;
  font-size: 14px;font-weight: 400;padding: 10px;text-align: left;
  color: #000000B2;margin-left: 8px;border-right: 1px solid #D9D9D9;">Branch</div>
          <div style=" padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">${sampledata?.loan_detail?.location?.display_name || "N/A"
      }</div>
        </div>
        <div style="  border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;">
          <div style="  width: 35%;font-family: Roboto;font-size: 14px;font-weight: 400;padding: 10px;text-align: left;color: #000000B2;
  margin-left: 8px; border-right: 1px solid #D9D9D9;">Contact Person</div>

          <div style=" padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">
(Vastu - Vishal Yadav, 8654366784)<br>
            Customer Details:<br>
         Mr. Jayesh Pawar (APPLICANT) - 9765488765</div>
     
          
        </div>
      </div>
    </span>
    `;

    const mailSubject = generateMailSubject();
    const documentDetailsArray = [];
    checkedDocs.forEach((docId) => {
      const doc = existingDocs.find((d) => d.id === docId);
      if (doc) {
        documentDetailsArray.push({
          document_id: doc.id,
          document_type: doc.document_type?.code,
          file_type: doc.type
        });
      }

    });

    const formattedValues = {
      loan_detail: sampledata?.loan_detail?.id,
      agency: values.agency,
      user: values.user,
      agent: values.agent_name,
      remark: values.remark,
      stage_initiate: "Rcu",
      mail_to: values.mail_to,
      mail_cc: [user_data.email],
      mail_body: mailBody,
      mail_subject: mailSubject,
      attachment: attachments,
      document_detail: documentDetailsArray,
      type_of_check: choice,
      check_type: values.check_type,
      sampling_data: sampledata?.customer_document_address_detail?.map(
        (rcue) => {
          // Conditionally handle Customer Profile documents only if choice !== "Document"
          const customerProfileDocs =
            choice !== "Documents"
              ? (rcue?.customer_document?.["Customer Profile"] || [])?.map(
                (doc) => ({
                  customer_id: rcue?.customer_name?.id,
                  rcu_process:
                    values[`document_${rcue?.customer_name?.id}_${doc?.id}`], // Default to "Screen" if no value is set
                })
              )
              : [];

          // Filter out Customer Profile documents from the documents field
          const filteredDocuments = transformDocuments(
            rcue?.customer_document
          )?.flatMap(({ keys, docs }) => {
            return docs
              ?.filter((doc) => {
                // Filter out documents associated with "Customer Profile"
                const isCustomerProfileDoc = keys.includes("Customer Profile");
                return !isCustomerProfileDoc;
              })
              ?.map((doc) => {
                const rcuProcessValue =
                  values[
                  `document_${rcue?.customer_name?.id}_${doc?.id}_${keys}_${doc?.document_type}`
                  ];
                if (rcuProcessValue) {
                  return {
                    document_id: doc?.id,
                    rcu_process: rcuProcessValue,
                  };
                }
                return undefined; // Filter out undefined entries
              })
              .filter((doc) => doc !== undefined);
          });

          // Return the formatted sampling data
          return {
            customer_id: rcue?.customer_name?.id,
            address: rcue?.customer_address?.map((addr) => ({
              address_id: addr?.id,
              rcu_process: values[`address_${addr?.id}`],
            })),
            documents: filteredDocuments,
            // Only include `customerProfileDocs` if choice !== "Document"
            customer_profile:
              customerProfileDocs.length > 0 ? customerProfileDocs : undefined,
          };
        }
      ),
    };

    try {
      const response = await postRCUInitiate(formattedValues);
      if (response && response.success) {
        message.success("RCU initiated successfully");
        setSubmitStageBar();
        closeForm();
        form.resetFields();
        setImages([]);
      } else {
        message.error("failed to initiate RCU");
        setImages([]);
        setValue("");
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.data?.message, error?.response?.status)
      );
      setImages([]);
      setValue("");
      closeForm();
      setLoading(false);
    }
  };

  const handleSelectChange = (value, dropdownId, customerId, keys, type) => {
    form.setFieldsValue({
      [`document_${customerId}_${dropdownId}_${keys}_${type}`]: value,
      // document_${rcue.customer_name.id}_${doc?.id}
    });

    form.setFieldsValue({
      ...form.getFieldsValue(),
    });
  };

  const transformDocuments = (documents) => {
    if (!documents || typeof documents !== "object") return [];
    return Object.entries(documents)?.map(([keys, docs]) => ({
      keys,
      docs,
    }));
  };


  const filteredDocuments = (customerDocument) => {
    return transformDocuments(customerDocument).filter(({ keys }) => {
      if (choice === "Profile") {
        return keys === "Customer Profile";
      } else if (choice === "Documents") {
        return keys !== "Customer Profile";
      } else {
        return true; // For "Both", display all documents
      }
    });
  };

  const closeForms = () => {
    closeForm();
    form.resetFields();
    setImages([]);
  };

  useEffect(() => {
    if (loan_id) {
      const fetchData = async () => {
        try {
          const response = await getDocumentByIdService(loan_id);
          const documents = response?.data?.documents || [];

          const formattedDocs = documents.map((doc, index) => ({
            uid: `${doc.id}-${index}`,
            id: doc.id,
            name: doc.document_path.split("/").pop(),
            status: "done",
            url: doc.document_path,
            thumb_document_path: doc.thumb_document_path,
            thumb_document: doc.thumb_document,
            type: doc.file_type,
            document_type: doc.document_type,
          }));

          setExistingDocs(formattedDocs);
          setCheckedDocs([]); // Check all initially
        } catch (error) {
          message.error("Error fetching documents");
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, open]);

  const handleCheckboxChangeDocuments = (id) => {
    setCheckedDocs((prevCheckedDocs) =>
      prevCheckedDocs.includes(id)
        ? prevCheckedDocs.filter((docId) => docId !== id)
        : [...prevCheckedDocs, id]
    );
  };



  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Form
          form={form}
          onFinish={handleSubmit}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="rcu_inition_container"
        >
          <div>
            <Checkbox />
            <span className="legal_property_heading">
              &nbsp; Attach Application Form
            </span>
          </div>

          <div className="rcu_inition_first_input_row">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="check_type"
                  label="Type"
                  rules={[yupSync]}
                  required
                >
                  <Select
                    placeholder="Please select"
                    allowClear
                    className="select_field"
                    onChange={handleVendorTypeChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {vendorType?.map((type) => (
                      <Select.Option
                        key={type}
                        value={type}
                      >
                        {type}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* Internal Manager - Visible only when Internal is selected */}
              {selectedVendorType === "Internal" && (
                <Col span={8}>
                  <Form.Item
                    name="user"
                    label="Internal RCU Manager"
                    rules={[yupSync]}
                    required
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      className="select_field"
                      onChange={handleVendorChange}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }

                    >
                      {vendorInternalType?.map((manager) => (
                        <Select.Option key={manager.user_id} value={manager.user_id}>
                          {manager.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {/* Vendor - Visible only when External is selected */}
              {selectedVendorType === "External" && (
                <Col span={8}>
                  <Form.Item
                    name="agency"
                    label="Vendor"
                    rules={[yupSync]}
                    required
                  >
                    <Select
                      placeholder="Please select"
                      allowClear
                      className="select_field"
                      onChange={handleVendorChange}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }

                    >
                      {vendorExternalType?.map((vendor) => (
                        <Select.Option key={vendor.id} value={vendor.id}>
                          {vendor.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={8}>
                <Form.Item
                  name="type_of_check"
                  label="Type Of Check:"
                  required
                  rules={[
                    { required: true, message: "Type Of Check is required" },
                  ]}
                >
                  <Select
                    placeholder="Please Select"
                    onChange={(value) => setChoice(value || null)} // Corrected onChange event
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {rcutype?.map((choice, index) => (
                      <Option key={index} value={choice}>
                        {choice}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>


          {choice && choice !== "Documents" && (
            <div>
              <div className="Address_text_rcu">
                Address Sampling <span className="Address_text_rcu_star">*</span>
              </div>
              {modifiedDatas?.map((rcue) =>
                rcue?.customer_address && rcue?.customer_address?.length > 0 ? (
                  <div
                    className="rcu_div_container"
                    key={rcue?.customer_name?.customer_id}
                  >
                    <div className="loan_fiinition">
                      <div className="loan_fiinition_heading">
                        {rcue?.customer_type?.name} Name:{" "}
                        {`${rcue?.customer_name?.full_name}`}
                      </div>
                      <div className="loan_fiinition_heading">
                        Mobile Number: {rcue?.customer_name?.mobile_number}
                      </div>
                    </div>

                <div>
                  <div className="rcu_table_heading">
                    <div className="rcu_source_column">Address Type</div>
                    <div className="rcu_source_column_two">
                      Select RCU Process For Address
                    </div>
                    <div className="rcu_source_column_third">
                      Address Details
                    </div>
                  </div>
                  <div>
                    {rcue?.customer_address?.map((addr) => (
                      <div className="table_data" key={addr.id}>
                        <div className="table_data_body">
                          {addr.address_type.name}
                        </div>
                        <div className="table_data_body">
                          <Form.Item
                            name={`address_${addr.id}`}
                            rules={[
                              {
                                required: true,
                                message: "Please Select",
                              },
                            ]}
                          >
                            <Select
                              className="select_rcu_inition"
                              placeholder="Please Select"
                              allowClear
                            >
                              {rcu?.map((rcus) => (
                                <Option value={rcus?.value} key={rcus?.value}>
                                  {rcus?.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="table_data_body_two">
                          <p>
                            {addr.address_line_1}, {addr.address_line_2},{" "}
                            {addr.city.name}, {addr.district.name},{" "}
                            {addr.taluka}, {addr.state.name} -{" "}
                            {addr.pincode.name}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null
          )}

            </div>
          )}


          {choice && choice !== "Profile" && (
            <div>
              <div className="Address_text_rcu">
                Documents Sampling <span className="Address_text_rcu_star">*</span>
              </div>

              {modifiedDatas &&
                modifiedDatas?.map(
                  (rcue) =>
                    rcue?.customer_document && (
                      <div
                        className="rcu_div_container"
                        key={rcue?.customer_name?.customer_id}
                      >
                        <div className="loan_fiinition">
                          <div className="loan_fiinition_heading">
                            {rcue?.customer_type?.name} Name:{" "}
                            {rcue?.customer_name?.full_name}{" "}
                          </div>
                          {/* <div className="loan_fiinition_heading"> */}
                          {/* Mobile Number: {rcue?.customer_name?.mobile_number} */}
                          <Form.Item name={`dropdown_${rcue.id}`}>
                            <Select
                              className="select_rcu_inition"
                              placeholder="Please Select"
                              allowClear
                              onChange={(value) => {
                                const updatedFields = {
                                  ...form.getFieldsValue(),
                                  [`dropdown_${rcue.id}`]: value,

                                  ...filteredDocuments(
                                    rcue.customer_document
                                  ).reduce((acc, { docs, keys }) => {
                                    docs.forEach((doc) => {
                                      acc[
                                        `selectDocument_${rcue.customer_name.id}_${doc?.id}`
                                      ] = value;
                                      acc[
                                        `document_${rcue.customer_name.id}_${doc?.id}_${keys}_${doc.document_type}`
                                      ] = value;
                                    });
                                    return acc;
                                  }, {}),
                                };

                                // Set the updated fields in the form
                                form.setFieldsValue(updatedFields);
                              }}
                            >
                              {rcu?.map((rcus) => (
                                <Option value={rcus?.value} key={rcus?.value}>
                                  {rcus?.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          {/* </div> */}
                        </div>

                        <div>
                          {/* <Row>
                    <Col span={6}> */}
                          <div className="rcu_initiotion_document_table">
                            <div className="table_data_body">
                              <div className="source-column-div">Group</div>
                            </div>
                            {/* </Col> */}
                            {/* <Col span={6}> */}
                            <div className="table_data_body">
                              <div className="source-column-div">RCU Process</div>
                            </div>
                            {/* </Col> */}
                            {/* <Col span={6}> */}
                            <div className="table_data_body">
                              <div className="source-column-div">Documents</div>
                            </div>
                            {/* </Col> */}
                            {/* <Col span={6}> */}
                            <div className="table_data_body">
                              <div className="source-column-div">RCU Process</div>
                            </div>
                          </div>
                          {/* </Col>
                  </Row> */}

                          {filteredDocuments(rcue?.customer_document)?.map(
                            ({ keys, docs }) => (
                              <div className="table_data" key={keys}>
                                {/* <Row> */}
                                {/* <Col span={6}> */}
                                <div className="table_data_body">
                                  <div className="keys-column rcu_contnet">
                                    {keys}
                                  </div>
                                </div>
                                {/* </Col> */}
                                {/* <Col span={6}> */}
                                <div className="table_data_body">
                                  <div className="source-column-div">
                                    {docs?.[0]?.group_name}
                                    <Col span={6}>
                                      <Form.Item
                                        // name={`dropdown_${docs?.[0]?.id}`}
                                        name={`selectDocument_${rcue?.customer_name?.id}_${docs?.[0]?.id}`}
                                      >
                                        <Select
                                          className="select_rcu_inition"
                                          placeholder="Please Select"
                                          allowClear
                                          onChange={(value) => {
                                            // Iterate over docs array and update each document
                                            docs.forEach((doc) => {
                                              handleSelectChange(
                                                value,
                                                doc.id,
                                                rcue.customer_name.id,
                                                keys,
                                                doc.document_type
                                              );
                                            });
                                          }}
                                        // onChange={(value) => handleSelectChange(value, docs[0]?.id, rcue.customer_name.id)}
                                        >
                                          {rcu?.map((rcus) => (
                                            <Option
                                              value={rcus?.value}
                                              key={rcus?.value}
                                            >
                                              {rcus?.label}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  </div>
                                </div>
                                {/* </Col> */}
                                {/* <Col span={6}> */}
                                <div className="table_data_body">
                                  <div className="document-image-container">
                                    {docs?.map((doc) => {
                                      if (!doc.path) {
                                        return (
                                          <div
                                            className="document_third"
                                            key={doc?.id}
                                          >
                                            <p>No document available</p>
                                          </div>
                                        );
                                      }

                                      // Extract the file name from the URL
                                      const parts = doc.path.split("/");
                                      const fileName = parts.pop().split("?")[0]; // Get the last part before the query params
                                      const fileExtension = fileName
                                        .split(".")
                                        .pop()
                                        .toLowerCase();

                                      return (
                                        <div
                                          className="document_third"
                                          key={doc.id}
                                        >
                                          <div className="img_document">
                                            {/* Conditionally render based on file extension */}
                                            {["png", "jpg", "jpeg"].includes(
                                              fileExtension
                                            ) ? (
                                              <>
                                                <RViewerJS>
                                                  <img
                                                    src={doc.path}
                                                    height={64}
                                                    width={64}
                                                    alt="Document"
                                                  />
                                                </RViewerJS>
                                                <p className="rcu-doc-title">
                                                  {doc?.document_type ||
                                                    "Customer Profile"}
                                                </p>
                                              </>
                                            ) : fileExtension === "pdf" ? (
                                              <>
                                                <img
                                                  src={pdf} // Assuming 'pdf_image' is an imported image placeholder for PDFs
                                                  alt="PDF"
                                                  height={64}
                                                  width={64}
                                                  className="rcu_image_pdf"
                                                  onClick={() =>
                                                    window.open(doc.path, "_blank")
                                                  }
                                                />
                                                <p className="rcu-doc-title">
                                                  {doc?.document_type ||
                                                    "Customer Profile"}
                                                </p>
                                              </>
                                            ) : (
                                              <>
                                                <FileOutlined className="file_uplaod_icon_rcu" />
                                                <p className="rcu-doc-title">
                                                  {doc?.document_type ||
                                                    "Customer Profile"}
                                                </p>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                {/* </Col> */}
                                {/* <Col span={6}> */}
                                <div
                                  className="table_data_body"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  {docs?.map((doc, index) => (
                                    <div className="source-column-div">
                                      <Col span={6}>
                                        <Form.Item
                                          // name={`document_${rcue.customer_name.id}_`}
                                          name={`document_${rcue?.customer_name?.id}_${doc?.id}_${keys}_${doc?.document_type}`}
                                          style={{ width: "175px" }}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please Select",
                                            },
                                          ]}
                                        >
                                          <Select
                                            placeholder="Please Select"
                                            allowClear
                                          >
                                            {rcu?.map((rcus) => (
                                              <Option
                                                value={rcus?.value}
                                                key={rcus?.value}
                                              >
                                                {rcus?.label}
                                              </Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                    </div>
                                  ))}
                                </div>
                                {/* </Col> */}
                                {/* </Row> */}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )
                )}
            </div>
          )}


          <div className="email_div_container">
            <div className="select_rcu_initin">
              <div className="content_block">
                <span className="email_heading">
                  <span className="all_required_sign"> *</span>To :
                </span>
                <span className="email_content">
                  <Form.Item name="mail_to">
                    <Input disabled />
                  </Form.Item>
                </span>
              </div>
              <div className="content_block">
                <span className="email_heading">
                  <span className="all_required_sign"> *</span>CC :
                </span>
                <span className="email_content">
                  <Form.Item name="mail_cc">
                    <Input
                      placeholder={user_data?.email}
                      value={user_data?.email}
                      disabled
                    />
                  </Form.Item>
                </span>
              </div>
              <div className="content_block">
                <span className="email_heading">Add CC :</span>
                <span className="email_content">
                  <Form.Item name="add_cc">
                    <Input />
                  </Form.Item>
                </span>
              </div>
              <div className="content_block">
                <span className="email_heading">
                  <span className="all_required_sign"> *</span>Subject :
                </span>
                <span className="email_content">
                  <Form.Item
                    name="mail_subject"
                  // rules={[{ required: true, message: "Subject is required" }]}
                  >
                    {generateMailSubject()}
                  </Form.Item>
                </span>
              </div>
              <div className="content_block">
                <span className="email_heading">Message :</span>
                <span className="email_content">
                  Dear Team,
                  <br />
                  Please initiate RCU Search for {customerName} ({applicantType}
                  )
                  <div className="initiation_form_table_one ">
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        Customer Name
                      </div>
                      <div className="initiation_form_table_data_value">
                        {customerName} ({applicantType})
                      </div>
                    </div>
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        LAN
                      </div>
                      <div className="initiation_form_table_data_value">
                        {sampledata?.loan_detail?.loan_account_number}
                      </div>
                    </div>
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        Product
                      </div>
                      <div className="initiation_form_table_data_value">
                        {sampledata?.loan_detail?.loan_type?.display_name}
                      </div>
                    </div>
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        Check Type
                      </div>
                      <div className="initiation_form_table_data_value">
                        {choice}
                      </div>
                    </div>
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        Property Address
                      </div>
                      <div className="initiation_form_table_data_value">
                        {`${sampledata?.property_detail?.address_line_1}, ${sampledata?.property_detail?.address_line_2}, ${sampledata?.property_detail?.city?.name}, ${sampledata?.property_detail?.district?.name}, ${sampledata?.property_detail?.taluka}, ${sampledata?.property_detail?.state?.name}, ${sampledata?.property_detail?.pincode?.name}`}
                      </div>
                    </div>
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        Branch
                      </div>
                      <div className="initiation_form_table_data_value">
                        {sampledata?.loan_detail?.location?.display_name}
                      </div>
                    </div>
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        Contact Person
                      </div>
                      <div className="initiation_form_table_data_value">
                        {customerName} ({applicantType})
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              <div className="content_block">
                <span className="email_heading">
                  <span className="all_required_sign"> *</span> Remarks:
                  <br />
                  (If attached, please mention the list of documents)
                </span>
                <span className="email_content">
                  <Form.Item
                    name="remark"
                    rules={[
                      {
                        required: true,
                        message: "Please enter remarks",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder="Please Enter Remark"
                      value={value}
                      onChange={(val) => {
                        setValue(val);
                        // form.setFieldsValue({ remark: val });
                      }}
                    />
                  </Form.Item>
                </span>
              </div>

              <div className="content_block">
                <span className="email_heading">Attachments :</span>
                <span className="email_content">
                  <div>
                    {existingDocs.length > 0 && (
                      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginBottom: "20px" }}>
                        {existingDocs.map((doc) => {
                          let icon = null;
                          if (doc.type === "application/pdf") {
                            icon = pdf;
                          } else if (doc.type === "video/webm") {
                            icon = video;
                          } else if (
                            doc.type === "application/vnd.ms-excel" ||
                            doc.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          ) {
                            icon = xls;
                          } else if (doc.type.startsWith("image/")) {
                            icon = doc.url || doc.thumbUrl;
                          }

                          return (
                            <div
                              key={doc.id}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                position: "relative",
                                padding: "10px",
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                                background: "#f9f9f9",
                                width: "120px",
                              }}
                            >

                              {doc.type.startsWith("image/") ? (
                                <RViewerJS options={{ url: (image) => image.getAttribute("data-original") }}>
                                  {/* <div> */}
                                  <img
                                    // className="customer_document_file_image"
                                    src={doc.thumb_document_path || doc.thumb_document || dummy_img}
                                    data-original={doc.url || dummy_img}
                                    alt="Document"
                                    height={56}
                                    width={62}
                                    style={{ cursor: "pointer", borderRadius: "5px" }}
                                  />
                                  {/* </div> */}
                                </RViewerJS>
                              ) : doc.type === "application/pdf" ? (
                                /* Handling PDF Files */
                                <img
                                  src={pdf}
                                  alt="PDF"
                                  height={64}
                                  width={64}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => window.open(doc.url, "_blank")}
                                />
                              ) : doc.type === "application/vnd.ms-excel" ||
                                doc.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                                <img
                                  src={xls}
                                  alt="CSV"
                                  height={64}
                                  width={64}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const excelUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(doc.url)}`;
                                    window.open(excelUrl, "_blank");
                                  }}
                                />
                              ) : doc.type === "video/webm" || doc.type.startsWith("video/") ? (
                                <img
                                  className="customer_document_file_image"
                                  src={video} // Use a video icon
                                  alt="Video"
                                  height={64}
                                  width={64}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => window.open(doc.url, "_blank")}
                                />
                              ) : icon ? (
                                <img
                                  src={icon}
                                  alt="File Icon"
                                  style={{ width: "60px", height: "60px", objectFit: "contain" }}
                                />
                              ) : (
                                <FileOutlined style={{ fontSize: "40px", color: "#888" }} />
                              )}

                              <div
                                style={{
                                  fontSize: "12px",
                                  marginTop: "5px",
                                  // whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  display: "flex",
                                  alignItems: "center",
                                  textAlign: "center",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {doc?.document_type?.display_name}
                              </div>
                              <Checkbox
                                checked={checkedDocs.includes(doc.id)}
                                onChange={() => handleCheckboxChangeDocuments(doc.id)}
                                style={{ top: "5px", left: "5px" }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <Form.Item>
                      <Upload
                        multiple
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        fileList={fileList}
                        onChange={handleUpload}
                        // beforeUpload={() => false}
                        beforeUpload={handleBeforeUpload}
                        itemRender={(originNode, file, fileList, actions) => {
                          // Determine the icon based on file type or use the file's URL if it's an image
                          let icon = null;

                          if (file.type === "application/pdf") {
                            icon = pdf; // Path to your PDF icon
                          } else if (file.type === "video/webm") {
                            icon = video; // Path to your Excel icon
                          } else if (
                            file.type === "application/vnd.ms-excel" ||
                            file.type ===
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          ) {
                            icon = xls; // Path to your Excel icon
                          } else if (file.type.startsWith("image/")) {
                            icon = file.url || file.thumbUrl; // Use the image preview if available
                          }

                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                position: "relative",
                                padding: "10px",
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                                background: "#f9f9f9",
                                width: "120px",
                                textAlign: "center",
                              }}
                            >
                              {/* File Preview or Icon */}
                              {file.type.startsWith("image/") ? (
                                <RViewerJS options={{ url: (image) => image.getAttribute("data-original") }}>
                                  <img
                                    src={file.thumbUrl}
                                    data-original={file.thumbUrl}
                                    alt="Document"
                                    height={56}
                                    width={62}
                                    style={{ cursor: "pointer", borderRadius: "5px" }}
                                  />
                                </RViewerJS>
                              ) : icon ? (
                                <img
                                  src={icon}
                                  alt={`${file.type} Icon`}
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    objectFit: "contain",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => file.url && window.open(file.url, "_blank")}
                                />
                              ) : (
                                originNode
                              )}
                              <div
                                style={{
                                  fontSize: "12px",
                                  marginTop: "5px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100px",
                                }}>
                                {trimFileName(file.name)}</div>
                              <div
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={actions.remove}
                              >
                                <DeleteOutlined
                                  style={{ fontSize: "15px", color: "#4880ff" }}
                                />
                              </div>
                            </div>
                          );
                        }}
                        showUploadList={{
                          showRemoveIcon: false, // Disable default Ant Design remove icon
                          showPreviewIcon: false,
                        }}
                        rules={[
                          { required: true, message: "Please upload a document" },
                        ]}
                      >
                        <button className="upload_button" type="button">
                          <PlusOutlined />
                          <div>Upload</div>
                        </button>
                      </Upload>
                    </Form.Item>
                  </div>
                </span>
              </div>
            </div>
          </div>

          <Space
            direction="horizontal"
            align="center"
            className="intiate_cancle_button"
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              Initiate
            </Button>
            <Button onClick={closeForms}>Cancel</Button>
          </Space>
        </Form>
      )}
    </>
  );
};

export default RCUInitiationForm;
