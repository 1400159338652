/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { message, Tabs } from "antd";
import { CalendarOutlined, CheckSquareOutlined } from "@ant-design/icons";
import DashboardLiteToDoList from "./DashboardLiteToDoList";
import styles from "../style/MeetingCard.module.css";
import { getEmployeeMeetings } from "../service/getEmployeeAssignedWork";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import Loading from "../../../utils/loading/Loading";
import CustomNoData from "../../../utils/noDataIcon/CustomNoData";
import { dateToString } from "../../../utils/dateConvertor/DateConvertor";

const MeetingTaskSwitcher = ({ selectedDate }) => {
  const [events, setEvents] = useState(null);
  const [eventLoading, setEventLoading] = useState(false);
  const google_access_token = localStorage.getItem("google_access_token");
  const google_refersh_token = localStorage.getItem("google_refresh_token");

  const convertDateFormat = (dateString) => {
    const [dd, mm, yyyy] = dateString.split("-");
    return `${yyyy}-${mm}-${dd}`;
  };
  const convertedDate = convertDateFormat(dateToString(selectedDate));

  useEffect(() => {
    (async () => {
      try {
        setEventLoading(true);
        if (google_access_token && google_refersh_token) {
          const response = await getEmployeeMeetings(
            google_access_token,
            google_refersh_token,
            convertedDate
          );
          const responseEvents = response?.data?.items.map((meeting) => {
            const formatTime = (timeString) => {
              const date = new Date(timeString);
              let hours = date.getHours();
              const minutes = date.getMinutes();
              const ampm = hours >= 12 ? "PM" : "AM";

              hours = hours % 12 || 12; // Convert 0 to 12 for midnight
              const formattedMinutes = minutes.toString().padStart(2, "0"); // Ensure two-digit minutes

              return `${hours}:${formattedMinutes} ${ampm}`;
            };
            return {
              title: meeting?.summary,
              startTime: formatTime(meeting?.start?.dateTime),
              endTime: formatTime(meeting?.end?.dateTime),
              type: "info",
              meetLink: meeting?.hangoutLink,
            };
          });
          setEvents(responseEvents);
          setEventLoading(false);
        } else {
          message.info("Log in with google for the best experience");
          setEventLoading(false);
        }
      } catch (error) {
        setEventLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    })();
  }, [convertedDate]);

  return (
    <div className="w-full">
      <Tabs defaultActiveKey="meetings">
        <Tabs.TabPane
          tab={
            <span>
              <CalendarOutlined /> Meetings
            </span>
          }
          key="meetings"
        >
          <div className={`${styles.meetingsContainer} space-y-3 mt-4`}>
            {eventLoading ? (
              <Loading />
            ) : !events || events.length === 0 ? (
              <CustomNoData />
            ) : (
              <>
                {events.map((meeting, index) => (
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    className={`${styles.meetingCard} ${
                      index % 2 === 0
                        ? styles.greenBackground
                        : styles.blueBackground
                    }`}
                    key={index}
                    onClick={() => {
                      window.open(
                        meeting?.meetLink,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    <div className={styles.meetingTitle}>
                      <span className={styles.statusDot}></span>
                      <span>{meeting.title}</span>
                    </div>
                    <div className={styles.meetingTime}>
                      <span className={styles.clockIcon}>🕒</span>
                      <span>
                        {meeting.startTime} - {meeting.endTime}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <span>
              <CheckSquareOutlined /> To-Do's
            </span>
          }
          key="todos"
        >
          <DashboardLiteToDoList selectedDate={selectedDate} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default MeetingTaskSwitcher;
