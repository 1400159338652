// src/router/Router.js
import React from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import Login from "../modules/login/views/Login";
import PageNotFound from "../modules/pages/notfound/views/PageNotFound";
import UnderMaintenance from "../modules/pages/undermaintenance/views/UnderMaintenance";
import PublicRoutes from "./protectedRoutes/PublicRoutes";
import AuthProtectedRoutes from "./protectedRoutes/AuthProtectedRoutes";
import MyLayout from "../modules/layout/views/MyLayout";
import User from "../modules/user/views/User";
import Master from "../modules/masters/master/Master";
import LoanApplication from "../modules/loanApplication/loanSummary/views/LoanApplication";
import ListOfApplication from "../modules/loanApplication/listOfApplication/views/ListOfApplication";
import Dedupe from "../modules/dedupe/views/Dedupe";
import { CibilReport } from "../modules/loanApplication/customerTabDetails/bureauDetails/views/CibilReport";
import { HighMarkReport } from "../modules/loanApplication/customerTabDetails/bureauDetails/views/HighMarkReport";
import IdCardDetail from "../modules/idCard/views/IdCardDetail";
import IdCard from "../modules/idCard/views/IdCard";
import AuthorizedRoute from "./protectedRoutes/AuthorizedRoute";
import Details from "../modules/technicalValuation/detailsTab/details/views/Details";
import IdCardRenderer from "../modules/idCard/views/IdCardRenderer";
import LegalValuation from "../modules/LegalValuation/views/LegalValuation";
import ForgetPassword from "../modules/login/views/ForgetPassword";
import ResetPassword from "../modules/login/views/ResetPassword";
import LegalInputForm from "../modules/LegalValuation/views/LegalInputForm/LegalInputForm";
import TechnicalValuationReport from "../modules/technicalValuation/detailsTab/technicalValuationReport/TechnicalValuationReport";
import RcuPost from "../modules/rcu/views/rcupost";
import Fi from "../modules/fi/view/Fi";
import Rcu from "../modules/rcu/views/rcu";
import Adhoc from "../modules/adhoc/views/Adhoc";
import Receipt from "../modules/receipt/views/Receipt";
import NachDetails from "../modules/loanApplication/nachDetails/views/NachDetails";
import PartDisbursedInitiate from "../modules/partDisbursed/partDisbursedInitiate/views/PartDisbursedInitiate";
import PartDisbursedTable from "../modules/partDisbursed/partDisbursedTable/views/PartDisbursedTable";
import FiInputForm from "../modules/fi/view/fiApplicantRVInputForm/FiInputForm";
import AllLoanNumber from "../modules/loanApplication/loanTabDetails/operations/OTCPDD/views/AllLoanNumber";
import ListSalesApplication from "../modules/listSalesApplication/views/listSalesApplication";
import NotChecked from "../modules/loanApplication/nachDetails/views/NotChecked";
import InProgress from "../modules/loanApplication/nachDetails/views/InProgress";
import { v4 } from "uuid";
import DashboardLite from "../modules/dashboardLite/views/DashboardLite";
import UserProfile from "../modules/user/views/UserProfile";
import Calendar from "../modules/pages/Calender/Views/Calendar";
import SalesForm from "../modules/salesform/views/SalesApplicationForm/SalesForm";
import SecurityInterestPendingList from "../modules/loanApplication/loanTabDetails/operations/Cersai/SecurityInterest/views/SecurityInterestPendingList";
import SecuritySatisfactionPendingList from "../modules/loanApplication/loanTabDetails/operations/Cersai/SecuritySatisfaction/views/SecuritySatisfactionPendingList";
import Approved from "../modules/loanApplication/nachDetails/views/Approved";
import Rejected from "../modules/loanApplication/nachDetails/views/Rejected";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <PublicRoutes>
              <Outlet />
            </PublicRoutes>
          }
        >
          <Route path="/login" element={<Login />} />
        </Route>
        <Route
          element={
            <MyLayout>
              <Outlet />
            </MyLayout>
          }
        >
          <Route
            element={
              <AuthProtectedRoutes>
                <Outlet />
              </AuthProtectedRoutes>
            }
          >
            <Route path="/" element={<DashboardLite />} />

            <Route
              element={
                <AuthorizedRoute permissions={["VIEWUSER"]}>
                  <Outlet />
                </AuthorizedRoute>
              }
            >
              <Route path="/users" element={<User />} />
            </Route>

            <Route
              element={
                <AuthorizedRoute permissions={["VWMASTER"]}>
                  <Outlet />
                </AuthorizedRoute>
              }
            >
              <Route path="/master" element={<Master />} />
            </Route>

            <Route
              element={
                <AuthorizedRoute permissions={["VWRECIPT"]}>
                  <Outlet />
                </AuthorizedRoute>
              }
            >
              <Route path="/receipt" element={<Receipt />} />
            </Route>

            <Route path="/id-card" element={<IdCard />} />


            <Route
              element={
                <AuthorizedRoute permissions={["LSLOANS1"]}>
                  <Outlet />
                </AuthorizedRoute>
              }
            >
              <Route path="/applications" element={<ListOfApplication />} />

            </Route>
            <Route
              path="/applications/:encrypted_stage_id"
              element={<ListOfApplication key={v4()} />}
            />
            <Route
              element={
                <AuthorizedRoute permissions={["VWLOANID"]}>
                  <Outlet />
                </AuthorizedRoute>
              }
            >
              <Route
                path="/loandetails/:encrypted_loan_id"
                element={<LoanApplication />}
              />
              <Route
                path="/legal/input_form/:encrypted_loan_id/:encrypted_stage_id"
                element={<LegalInputForm typeCheck="Legal" />}
              />
            </Route>
            <Route
              path="/legal/view/:encrypted_loan_id/:encrypted_stage_id"
              element={<LegalValuation typeCheck="Legal" />}
            />
            <Route
              path="/cibil_report/:encrypted_customer_id"
              element={<CibilReport />}
            />
            <Route path="user_profile" element={<UserProfile />} />
            <Route
              path="/highmark_report/:encrypted_customer_id"
              element={<HighMarkReport />}
            />
            <Route path="/dedupe/:encrypted_loan_id/:encrypted_stage_id" element={<Dedupe />} />
            <Route
              path="/technical/view/:encrypted_loan_id"
              element={<Details />}
            />
            <Route path="/nach/:encrypted_loan_id" element={<NachDetails />} />
            <Route path="/nach" element={<NachDetails />} />
            <Route path="/not_checked" element={<NotChecked />} />
            <Route path="/in_progress" element={<InProgress />} />
            <Route path="/approved" element={<Approved />} />
            <Route path="/rejected" element={<Rejected/>} />
            <Route
              path="/technical/input_form/:encrypted_loan_id"
              element={<TechnicalValuationReport />}
            />
            <Route
              path="/technical/view/:encrypted_loan_id/:encrypted_stage_id"
              element={<Details typeCheck="Technical" />}
            />
            <Route
              path="/technical/input_form/:encrypted_loan_id/:encrypted_stage_id"
              element={<TechnicalValuationReport typeCheck="Technical" />}
            />
            <Route path="/adhoc" element={<Adhoc />} />
            <Route path="/calendar" element={<Calendar />} />

            <Route
              path="/otc_pdd/:encrypted_loan_id"
              element={<AllLoanNumber />}
            />
            <Route path="/otc_pdd" element={<AllLoanNumber />} />
            <Route path="/part_disbursed" element={<PartDisbursedInitiate />} />
            <Route
              path="/parstDisbursmenttable/:encrypted_loan_id/:part_disbursement_id"
              element={<PartDisbursedTable />}
            />
                        <Route path="/securityinterestpendinglist" element={<SecurityInterestPendingList/>} />
            <Route path="/securitysatisfactionpendinglist" element={<SecuritySatisfactionPendingList />} />
            {/* <Route path="/loandetails/:encrypted_loan_id" element={<LoanApplication />} /> */}

            <Route
              path="/highmark_report/:customer_id"
              element={<HighMarkReport />}
            />
            <Route
              path="/fi/view/:encrypted_loan_id/:encrypted_stage_id"
              element={<Fi typeCheck="FI" />}
            />
            <Route
              path="/fi/input_form/:encrypted_loan_id/:encrypted_stage_id"
              element={<FiInputForm typeCheck="FI" />}
            />
            <Route
              path="/rcu/view/:encrypted_loan_id/:encrypted_stage_id"
              element={<Rcu />}
            />
            <Route path="/listsalesapplication" element={<ListSalesApplication />} />
            <Route
              path="/sales/:encrypted_loan_id"
              element={<SalesForm />}
            />
            <Route
              path="/rcu/input_form/:encrypted_loan_id/:encrypted_stage_id"
              element={<RcuPost />}
            />
            <Route
              path="/sales-form"
              element={<SalesForm />}
            />
          </Route>
        </Route>
        <Route
          element={
            <AuthProtectedRoutes>
              <Outlet />
            </AuthProtectedRoutes>
          }
        >
          <Route
            path="/id-card/id-card-detail/:organization/:encrypted_employee_id"
            element={<IdCardDetail />}
          />
        </Route>


        <Route path="/vastu/id-card" element={<IdCardRenderer />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/maintenance" element={<UnderMaintenance />} />

        <Route path="/not-found" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />


      </Routes>
    </BrowserRouter>
  );
};

export default Router;
