import React, { useEffect, useState } from "react";
import "../styles/employmentDetails.css";
// import verify from "../assets/Group.png";
import add from "../assets/Add.png";
import "../../style/customerStyle.css";
import { Drawer, Modal, message, Tooltip } from "antd";
import Header from "../../../../layout/views/Header";
import SalariedForm from "./SalariedForm";
import SelfEmployedForm from "./SelfEmployedForm";
import { deletePensionerDetailsByIdService, deleteRentalDetailsByIdService, deleteSalariedDetailsByIdService, deleteSelfSalariedDetailsByIdService, getEmploymentDetailsByIdService } from "../services/employmentDetailsServices";
import { useParams } from "react-router-dom";
import "../styles/employmentDetails.css";
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import IndianNumberFormat from "../../../../../utils/indianNumberFormat/IndianNumberFormat";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import HistoryTable from "../../../history/views/HistoryTable";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import "../../../../commonCss/commonStyle.css";
import EmploymentType from "./EmploymentType";
import PensionerForm from "./PensionerForm";
import RentalForm from "./RentalForm";
import EditButton from "../../../../../utils/editButton/EditButton";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import DeleteButton from "../../../../../utils/deleteButton/DeleteButton";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";
import OfficeForm from "../../addressDetails/views/OfficeForm";
import { FaExclamationCircle } from "react-icons/fa";

const EmployementDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [open, setOpen] = useState(false);
  const [openEmploymentType, setOpenEmploymentType] = useState(false);
  const [bankAccountData, setBankAccountData] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState();
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const { user_data } = useSelector((state) => state.user);
  const [openModal, setOpenModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteType, setDeleteType] = useState('');

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    // setSelectedBank(null);
  };

  const showDrawerEmploymentType = () => {
    setOpenEmploymentType(true);
  };

  const onCloseEmploymentType = () => {
    setOpenEmploymentType(false);
  };

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getEmploymentDetailsByIdService(loan_id);
        setBankAccountData(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
  }, [loan_id, refreshData]); // Moved loan_id to the dependency array

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust date formatting as needed
  };

  const renderEditButton = (id, person, type) => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
          setSelectedCustomer(id);
          setSelectedPerson(person);
          setType(type);
        }}
      >
        <EditButton />
      </div>
    );
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case "salaried":
        return "Salaried";
      case "self employed":
        return "Self Employed";
      case "rental":
        return "Rental";
      case "pensioner":
        return "Pensioner";
      default:
        return "";
    }
  };

  const Headings = [
    "Key Parameter's",
    "Salaried",
    "Organization Name",
    "Industry Type",
    "Year Of Start (In Year)",
    "Employer Number",
    "Total Experience (In Year)",
    "Managed by",
    "Number Of Employees",
    "Number Of Branches",
    "Gross Income",
    "Net Income",
    "Employment Nature",
    "Business Legal Structure",
    "Department",
    "Designation",
    "Experience In Current Organization (In Year)",
    "Date Of Joining",
    "Organization Nature",
    "Manual Scavenger",
    "Occupation",
    "Address"
  ];

  const employment_Headings = [
    "Self Employed",
    "Organization Name",
    "Industry Type",
    "Year Of Start (In Year)",
    "Total Experience (In Year)",
    "Managed by",
    "Number Of Employees",
    "Number Of Branches",
    "Gross Income",
    "Net Income",
    "Nature Of Employment",
    "Business Legal Structure",
    "Business Seasonality",
    "Organization Nature",
    "Line Of Work",
    "Source Of Fund",
    "Business Registration Proof",
    "Ownership Of Business Premises",
    "Years Of Business In Current Premises",
    "Years In Current Business",
    "Business Area",
    "Business Location",
    "Type Of Structure",
    "Family Bussiness",
    "Amount Salary Paid to Self",
    "Average Daily Sale",
    "Estimated Gross Margin (%)",
    "Net Margin Earned (%)",
    "Udyam Registration Number",
    "Manual Scavenger",
    "Occupation",
    "Address"
  ];

  const rental_Headings = [
    "Rental",
    "Property Type",
    "Year Of Start (In Year)",
    "Gross Rent",
    "Net Rent",
    "Lessee Name",
    "Date Of Agreement",

    "Municipal Tax Paid By",
    "Maintainance Paid By",
    "GST Paid By",
    "Rental Mode"


  ];

  const pensioner_Headings = [
    "Pensioner",
    "Organization Name",
    "Industry Type",
    "Business Legal Structure",
    "Year Of Start (In Year)",
    "Gross Income",
    "Net Income",

  ];
  const fieldNameMap = {
    property_type: "Property Type",
    organization_name: "Organization Name",
    year_of_start: "Year Of Start (In Year)",
    total_experience: "Total Experience (In Year)",
    managed_by: "Managed by",
    number_of_employees: "Number Of Employees",
    no_of_branches: "Number Of Branches",
    gross_income: "Gross Income",
    bd_modified_at: "Business modified date",

    bd_create_date_at: "Business Created Date",
    net_income: "Net Income",
    net_rent: "Net Rent",
    gross_rent: "Gross Rent",
    department: "Department",
    manual_scavenger:"Manual Scavenger",
    udyam_registration_number:"Udyam Registration Number",
    customer_full_address:"Address",
    employer_number: "Employer Number",
    designation: "Designation",
    date_of_joining: "Date Of Joining",
    experience_in_current_organization: "Experience In Current Organization (In Year)",
    business_legal_structure: "Business Legal Structure",
    organization_nature: "Organization Nature",
    nature_of_employment: "Employment Nature",
    industry_type: "Industry Type",
    business_seasonality: "Business Seasonality",
    line_of_work: "Line Of Work",
    source_of_fund: "Source Of Fund",
    lessee_name: "Lessee Name",
    date_of_agreement: "Date Of Agreement",
    rental_amount: "Rental Amount",
    municipal_tax_paid_by: "Municipal Tax Paid By",
    maintainance_paid_by: "Maintenance Paid By",
    gst_paid_by: "GST Paid By",
    total_business_experience: "Total Business Experience  (In Year)",
    business_registration_proof: "Business Registration Proof",
    ownership_of_business_premises: "Ownership Of Business Premises",
    years_of_business_in_current_premises: "Years Of Business In Current Premise",
    years_in_current_business: "Years In Current Business",
    business_area: "Business Area",
    type_of_structure: "Type Of Structure",
    type_of_business: "Family Business",
    amount_salary_paid_to_self: "Amount Salary Paid to Self",
    average_daily_sale: "Average Daily Sale",
    estimated_gross_margin: "Estimated Gross Margin (%)",
    net_margin_earned: "Net Margin Earned (%)",
    business_location_type: "Business Location Type",
  };

  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return "tab_form_main_data_two_entries";
      case 3:
        return "tab_form_main_data_three_entries";
      // case 4:
      //   return 'tab_form_main_data_four_entries';
      default:
        return "";
    }
  };

  const deleteSalaried = async () => {
    setLoadingDelete(true);
    try {
      const response = await deleteSalariedDetailsByIdService(deleteId);
      if (response.status === 200 && response.success === true) {
        message.success("Salaried details deleted successfully");
        toggleRefreshData();
        setDeleteId(null);
        setDeleteType(null);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };

  const deleteSelfEmployed = async () => {
    setLoadingDelete(true);
    try {
      const response = await deleteSelfSalariedDetailsByIdService(deleteId);
      if (response.status === 200 && response.success === true) {
        message.success("Self employed details deleted successfully");
        toggleRefreshData();
        setDeleteId(null);
        setDeleteType(null);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };

  const deleteRental = async () => {
    setLoadingDelete(true);
    try {
      const response = await deleteRentalDetailsByIdService(deleteId);
      if (response.status === 200 && response.success === true) {
        message.success("Rental details deleted successfully");
        toggleRefreshData();
        setDeleteId(null);
        setDeleteType(null);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };

  const deletePensioner = async () => {
    setLoadingDelete(true);
    try {
      const response = await deletePensionerDetailsByIdService(deleteId);
      if (response.status === 200 && response.success === true) {
        message.success("Pensioner details deleted successfully");
        toggleRefreshData();
        setDeleteId(null);
        setDeleteType(null);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };

  const handleDelete = (id, employmentType) => {
    setOpenModal(true);
    setDeleteId(id);
    setDeleteType(employmentType);
  };

  const confirmDelete = async () => {
    setOpenModal(false);
    switch (deleteType) {
      case "rental":
        await deleteRental(deleteId);
        break;
      case "pensioner":
        await deletePensioner(deleteId);
        break;
      case "selfEmployed":
        await deleteSelfEmployed(deleteId);
        break;
      case "salaried":
        await deleteSalaried(deleteId);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header
              title="Edit"
              onClose={onClose}
              name={`${selectedPerson}'s ${getTypeLabel(type)}  Details`}
            />
          }
          width={970}
          onClose={onClose}
          open={open}
          closable={false}
        >
          {type === "salaried" ? (
            <SalariedForm
              refreshData={toggleRefreshData}
              open={open}
              customer_id={selectedCustomerId}
              closeForm={onClose}
              id={selectedCustomer}
              isEdit={true}
            />
          ) : type === "self employed" ? (
            <SelfEmployedForm
              refreshData={toggleRefreshData}
              open={open}
              customer_id={selectedCustomerId}
              closeForm={onClose}
              id={selectedCustomer}
              isEdit={true}
            />
          ) : type === "rental" ? (
            <RentalForm
              refreshData={toggleRefreshData}
              open={open}
              customer_id={selectedCustomerId}
              closeForm={onClose}
              id={selectedCustomer}
            />
          ) : type === "pensioner" ? (
            <PensionerForm
              refreshData={toggleRefreshData}
              open={open}
              customer_id={selectedCustomerId}
              closeForm={onClose}
              id={selectedCustomer}
            />
          ) : type === "Address" ? (
            <OfficeForm
              refreshData={toggleRefreshData}
              open={open}
              customer_id={selectedCustomerId}
              closeForm={onClose}
              id={selectedCustomer}
            />
          ) : null}
        </Drawer>

        <Drawer
          title={
            <Header
              title="Add"
              onClose={onCloseEmploymentType}
              name={`${selectedPerson?.customer_name}'s  Employment Details`}
            />
          }
          width={970}
          onClose={onCloseEmploymentType}
          open={openEmploymentType}
          closable={false}
        >
          <EmploymentType
            refreshData={toggleRefreshData}
            open={openEmploymentType}
            customer_id={selectedCustomerId}
            closeForm={onCloseEmploymentType}
          // id={selectedCustomer}
          />
        </Drawer>

        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : bankAccountData?.overall_max_salaried_count > 0 ||
            bankAccountData?.overall_max_selfemployed_count > 0 ||
            bankAccountData?.overall_max_rental_count > 0 ||
            bankAccountData?.overall_max_pensioner_count > 0
            ? (
              <>
                <table className="tab_form_main_table">
                  <thead>
                    <tr>
                      <th className="tab_form_key_parameters">{Headings[0]}</th>
                      {bankAccountData?.results?.map((person, index) => (
                        <th key={index} className="tab_form_top_heading">
                          <div className="add_button_alignment">
                            <div>
                              <span>{`${person?.customer_name}`} </span>
                              <span>
                                <span>{`(${person?.customer_type})`} </span>
                                {/* <img
                                src={verify}
                                alt="group"
                               className="verify_img"
                              /> */}
                              </span>
                            </div>
                            <ConditionalRender
                              userPermissions={user_data?.permissions}
                              requiredPermissions={["POSTCUST"]}
                            >
                              <div
                                className="tab_form_sub_edit"
                                onClick={() => {
                                  showDrawerEmploymentType();
                                  setSelectedCustomerId(person.customer_id);
                                  setSelectedPerson(person);
                                }}
                              >
                                <img src={add} alt="Add Button" />
                              </div>
                            </ConditionalRender>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {bankAccountData &&
                      [...Array(bankAccountData.overall_max_salaried_count)].map(
                        (_, rowIndex) =>
                          Headings.slice(1).map((header, colIndex) => (
                            <tr
                              key={`row-${colIndex}`}
                              className={
                                header === "Salaried"
                                  ? "employment-row"
                                  : header === "PD Entries"
                                    ? "employment-pd-row"
                                    : ""
                              }
                            >
                              <td className="tab_form_left_heading">
                                {header === "Salaried"
                                  ? `Salaried ${rowIndex + 1}`
                                  : header}
                              </td>
                              {bankAccountData?.results?.map(
                                (person, colIndex) => (
                                  <td
                                    key={`col-${colIndex}`}
                                    className={`tab_form_main_data_table ${getTableColumnClass(
                                      bankAccountData.results.length
                                    )}`}
                                  >
                                    {person.customer_employment.salaried &&
                                      person.customer_employment?.salaried[
                                      rowIndex
                                      ] ? (
                                      <>
                                        {header === "Organization Name"
                                          ? person.customer_employment.salaried?.[
                                            rowIndex
                                          ]?.organization_name || "-"
                                          : header === "Industry Type"
                                            ? person.customer_employment.salaried?.[
                                              rowIndex
                                            ]?.industry_type?.name || "-"
                                            : header === "Year Of Start (In Year)"
                                              ? person.customer_employment.salaried?.[
                                                rowIndex
                                              ]?.year_of_start || "-"

                                              : header === "Employer Number"
                                                ? person.customer_employment.salaried?.[
                                                  rowIndex
                                                ]?.employer_number || "-"

                                                : header === "Total Experience (In Year)"
                                                  ? person.customer_employment.salaried?.[
                                                    rowIndex
                                                  ]?.total_experience || "-"
                                                  : header === "Managed by"
                                                    ? person.customer_employment.salaried?.[
                                                      rowIndex
                                                    ]?.managed_by || "-"
                                                    : header === "Number Of Employees"
                                                      ? person.customer_employment.salaried?.[
                                                        rowIndex
                                                      ]?.number_of_employees || "-"
                                                      : header === "Number Of Branches"
                                                        ? person.customer_employment.salaried?.[
                                                          rowIndex
                                                        ]?.no_of_branches || "-"
                                                        : header === "Gross Income"
                                                          ? `₹ ${IndianNumberFormat(
                                                            person.customer_employment.salaried?.[rowIndex]?.gross_income
                                                          ) || "-"}`
                                                          : header === "Net Income"
                                                            ? `₹ ${IndianNumberFormat(
                                                              person.customer_employment.salaried?.[rowIndex]?.net_income
                                                            ) || "-"}`

                                                            : header === "Employment Nature"
                                                              ? person.customer_employment.salaried?.[
                                                                rowIndex
                                                              ]?.nature_of_employment?.name || "-"
                                                              : header === "Business Legal Structure"
                                                                ? person.customer_employment.salaried?.[
                                                                  rowIndex
                                                                ]?.business_legal_structure || "-"
                                                                : header === "Department"
                                                                  ? person.customer_employment.salaried?.[
                                                                    rowIndex
                                                                  ]?.department || "-"
                                                                  : header === "Designation"
                                                                    ? person.customer_employment.salaried?.[
                                                                      rowIndex
                                                                    ]?.designation || "-"
                                                                    : header ===
                                                                      "Experience In Current Organization (In Year)"
                                                                      ? person.customer_employment.salaried?.[
                                                                        rowIndex
                                                                      ]
                                                                        ?.experience_in_current_organization ||
                                                                      "-"
                                                                      : header === "Date Of Joining"
                                                                        ? person.customer_employment.salaried?.[
                                                                          rowIndex
                                                                        ]?.date_of_joining
                                                                          ? person.customer_employment.salaried[
                                                                            rowIndex
                                                                          ].date_of_joining
                                                                          : "-"
                                                                        : header === "Organization Nature"
                                                                          ? person.customer_employment.salaried?.[
                                                                            rowIndex
                                                                          ]?.organization_nature?.name || " - "
                                                                          
                                                                          : header === "Manual Scavenger"
                                                                          ? person.customer_employment.salaried?.[rowIndex]?.manual_scavenger === true
                                                                            ? "Yes"
                                                                            : person.customer_employment.salaried?.[rowIndex]?.manual_scavenger === false
                                                                              ? "No"
                                                                              : "-"

                                                                              : header === "Occupation"
                                                                              ? person.customer_employment.salaried?.[
                                                                                rowIndex
                                                                              ]?.occupation
                                                                                ? (
                                                                                  <>
                                                                                    {
                                                                                      person.customer_employment.salaried[
                                                                                        rowIndex
                                                                                      ].occupation?.name
                                                                                    }
                                                                                    
                                                                                    {(person.customer_employment.salaried[rowIndex].occupation?.is_negative || 
                                                                                      person.customer_employment.salaried[rowIndex].occupation?.is_restricted) && (
                                                                                      <Tooltip
                                                                                        title={
                                                                                          person.customer_employment.salaried[rowIndex].occupation?.is_negative &&
                                                                                          person.customer_employment.salaried[rowIndex].occupation?.is_restricted
                                                                                            ? "This profile is negative and restricted"
                                                                                            : person.customer_employment.salaried[rowIndex].occupation?.is_negative
                                                                                            ? "This profile is negative"
                                                                                            : "This profile is restricted"
                                                                                        }
                                                                                      >
                                                                                        <FaExclamationCircle className="occupation_status_false_flage" />
                                                                                      </Tooltip>
                                                                                    )}
                                                                                  </>
                                                                                )
                                                                                : "-"
                                                                              : header === "Address" ? (
                                                                                <div className="employment_detail_address_section">
                                                                                <span>
                                                                                  {person?.customer_employment?.salaried?.[rowIndex]?.customer_address?.customer_full_address || "-"}
                                                                                </span>
                                                                                {person?.customer_employment?.salaried?.[rowIndex]?.customer_address?.customer_full_address && (
                                                                                  <span>
                                                                                    {renderEditButton(
                                                                                      person?.customer_employment?.salaried?.[rowIndex]?.customer_address_detail?.id,
                                                                                      person?.customer_name,
                                                                                      "Address"
                                                                                    )}
                                                                                  </span>
                                                                                )}
                                                                              </div>
                                                                              ) : " "
                                                                          }

                                        {header === "Salaried" && (
                                          <div

                                            className="employment_tab"
                                          >
                                            <ConditionalRender
                                              userPermissions={user_data?.permissions}
                                              requiredPermissions={["DELTCUST"]}
                                            >
                                              <div className="tab_form_sub_edit"
                                                onClick={() => {
                                                  handleDelete(
                                                    person?.customer_employment
                                                      .salaried[rowIndex]?.id,
                                                    "salaried"
                                                  )
                                                }}>
                                                <DeleteButton />
                                              </div>
                                            </ConditionalRender>
                                            {person?.customer_employment.salaried[
                                              rowIndex
                                            ]?.created_at &&
                                              person?.customer_employment
                                                .salaried[rowIndex]
                                                ?.modified_at &&
                                              isModifiedAfterCreated(
                                                person?.customer_employment
                                                  .salaried[rowIndex]?.created_at,
                                                person?.customer_employment
                                                  .salaried[rowIndex]?.modified_at
                                              ) && (
                                                <div className="">
                                                  <HistoryTable
                                                    id={
                                                      person?.customer_employment
                                                        .salaried[rowIndex]?.id
                                                    }
                                                    bench_id={"af0e5d20f4d2e757"}
                                                    fieldNameMap={fieldNameMap}
                                                  />
                                                </div>
                                              )}
                                            <ConditionalRender
                                              userPermissions={
                                                user_data?.permissions
                                              }
                                              requiredPermissions={["EDITCUST"]}
                                            >
                                              {renderEditButton(
                                                person?.customer_employment
                                                  .salaried[rowIndex]?.id,
                                                person?.customer_name,
                                                "salaried"
                                              )}
                                            </ConditionalRender>
                                          </div>
                                        )}
                                      </>
                                    ) : header === "Salaried" ? (
                                      ""
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                          ))
                      )}
                  </tbody>
                </table>

                <div className="">
                  <table className="tab_form_main_table">
                    <tbody>
                      {bankAccountData &&
                        [
                          ...Array(
                            bankAccountData.overall_max_selfemployed_count
                          ),
                        ].map((_, rowIndex) =>
                          employment_Headings.slice(0).map((header, colIndex) => (
                            <tr
                              key={`row-${colIndex}`}
                              className={
                                header === "Self Employed"
                                  ? "employment-row"
                                  : header === "PD Entries"
                                    ? "employment-pd-row"
                                    : ""
                              }
                            >
                              <td className="tab_form_left_heading">
                                {header === "Self Employed"
                                  ? `Self Employed ${rowIndex + 1}`
                                  : header}
                              </td>
                              {bankAccountData?.results?.map(
                                (person, colIndex) => (
                                  <td
                                    key={`col-${colIndex}`}
                                    className={`tab_form_main_data_table ${getTableColumnClass(
                                      bankAccountData.results.length
                                    )}`}
                                  >
                                    {person.customer_employment.self_employed &&
                                      person.customer_employment?.self_employed[
                                      rowIndex
                                      ] ? (
                                      <>
                                        {header === "Organization Name"
                                          ? person.customer_employment
                                            .self_employed?.[rowIndex]
                                            ?.organization_name || "-"
                                          : header === "Industry Type"
                                            ? person.customer_employment
                                              .self_employed?.[rowIndex]
                                              ?.industry_type?.name || "-"
                                            : header === "Year Of Start (In Year)"
                                              ? person.customer_employment
                                                .self_employed?.[rowIndex]
                                                ?.year_of_start || "-"
                                              : header === "Total Experience (In Year)"
                                                ? person.customer_employment
                                                  .self_employed?.[rowIndex]
                                                  ?.total_business_experience || "-"
                                                : header === "Managed by"
                                                  ? person.customer_employment
                                                    .self_employed?.[rowIndex]
                                                    ?.managed_by || "-"
                                                  : header === "Number Of Employees"
                                                    ? person.customer_employment
                                                      .self_employed?.[rowIndex]
                                                      ?.number_of_employees || "-"
                                                    : header === "Number Of Branches"
                                                      ? person.customer_employment
                                                        .self_employed?.[rowIndex]
                                                        ?.no_of_branches || "-"

                                                      : header === "Gross Income"
                                                        ? `₹ ${IndianNumberFormat(
                                                          person.customer_employment
                                                            .self_employed?.[rowIndex]
                                                            ?.gross_income
                                                        ) || "-"}`

                                                        : header === "Net Income"
                                                          ? `₹ ${IndianNumberFormat(
                                                            person.customer_employment
                                                              .self_employed?.[rowIndex]
                                                              ?.net_income
                                                          ) || "-"}`
                                                          : header === "Nature Of Employment"
                                                            ? person.customer_employment
                                                              .self_employed?.[rowIndex]
                                                              ?.nature_of_employment?.name || "-"
                                                            : header === "Business Legal Structure"
                                                              ? person.customer_employment
                                                                .self_employed?.[rowIndex]
                                                                ?.business_legal_structure || "-"
                                                              : header === "Business Seasonality"
                                                                ? person.customer_employment
                                                                  .self_employed?.[rowIndex]
                                                                  ?.business_seasonality || "-"
                                                                : header === "Line Of Work"
                                                                  ? person.customer_employment
                                                                    .self_employed?.[rowIndex]
                                                                    ?.line_of_work?.name || "-"
                                                                  : header === "Source Of Fund"
                                                                    ? person.customer_employment
                                                                      .self_employed?.[rowIndex]
                                                                      ?.source_of_fund || " - "
                                                                    : header === "Department"
                                                                      ? person.customer_employment
                                                                        .self_employed?.[rowIndex]
                                                                        ?.department || "-"
                                                                      : header === "Designation"
                                                                        ? person.customer_employment
                                                                          .self_employed?.[rowIndex]
                                                                          ?.designation || "-"
                                                                        : header ===
                                                                          "Experience In Current Organization (In Year)"
                                                                          ? person.customer_employment
                                                                            .self_employed?.[rowIndex]
                                                                            ?.experience_in_current_organization ||
                                                                          "-"
                                                                          : header === "Date Of Joining"
                                                                            ? person.customer_employment
                                                                              .self_employed?.[rowIndex]
                                                                              ?.date_of_joining
                                                                              ? formatDate(
                                                                                person.customer_employment
                                                                                  .self_employed[rowIndex]
                                                                                  .date_of_joining
                                                                              )
                                                                              : "-"
                                                                            : header === "Organization Nature"
                                                                              ? person.customer_employment
                                                                                .self_employed?.[rowIndex]
                                                                                ?.organization_nature?.name || " - "


                                                                              : header === "Business Registration Proof"
                                                                                ? person.customer_employment
                                                                                  .self_employed?.[rowIndex]
                                                                                  ?.business_registration_proof || "-"

                                                                                : header === "Ownership Of Business Premises" ? person.customer_employment
                                                                                  .self_employed?.[rowIndex]
                                                                                  ?.ownership_of_business_premises || "-"

                                                                                  : header === "Years Of Business In Current Premises"
                                                                                    ? person.customer_employment
                                                                                      .self_employed?.[rowIndex]
                                                                                      ?.years_of_business_in_current_premises || "-"


                                                                                    : header === "Years In Current Business"
                                                                                      ? person.customer_employment
                                                                                        .self_employed?.[rowIndex]
                                                                                        ?.years_in_current_business || "-"



                                                                                      : header === "Business Area"
                                                                                        ? person.customer_employment
                                                                                          .self_employed?.[rowIndex]
                                                                                          ?.business_area || "-"




                                                                                        : header === "Business Location"
                                                                                          ? person.customer_employment
                                                                                            .self_employed?.[rowIndex]
                                                                                            ?.business_location_type || "-"



                                                                                          : header === "Type Of Structure"
                                                                                            ? person.customer_employment
                                                                                              .self_employed?.[rowIndex]
                                                                                              ?.type_of_structure || "-"


                                                                                            : header === "Family Bussiness"
                                                                                              ? person.customer_employment
                                                                                                .self_employed?.[rowIndex]
                                                                                                ?.type_of_business || "-"


                                                                                              : header === "Amount Salary Paid to Self"
                                                                                                ? `₹ ${IndianNumberFormat(
                                                                                                  person.customer_employment
                                                                                                    .self_employed?.[rowIndex]
                                                                                                    ?.amount_salary_paid_to_self) || "-"}`

                                                                                                : header === "Average Daily Sale"
                                                                                                  ? `₹ ${IndianNumberFormat(person.customer_employment
                                                                                                    .self_employed?.[rowIndex]
                                                                                                    ?.average_daily_sale) || "-"}`

                                                                                                  : header === "Estimated Gross Margin (%)"
                                                                                                    ? person.customer_employment
                                                                                                      .self_employed?.[rowIndex]
                                                                                                      ?.estimated_gross_margin || "-"
                                                                                                    : header === "Net Margin Earned (%)"
                                                                                                      ? person.customer_employment
                                                                                                        .self_employed?.[rowIndex]
                                                                                                        ?.net_margin_earned || "-"
                                                                                                        : header === "Udyam Registration Number"
                                                                                                        ? person.customer_employment
                                                                                                          .self_employed?.[rowIndex]
                                                                                                          ?.udyam_registration_number || "-"
                                                                                                        : header === "Manual Scavenger"
                                                                                                        ? person.customer_employment.self_employed?.[rowIndex]?.manual_scavenger === true
                                                                                                          ? "Yes"
                                                                                                          : person.customer_employment.self_employed?.[rowIndex]?.manual_scavenger === false
                                                                                                            ? "No"
                                                                                                            : "-"
                                                                                                            : header === "Occupation"
                                                                                                            ? person.customer_employment.self_employed?.[
                                                                                                              rowIndex
                                                                                                            ]?.occupation
                                                                                                              ? (
                                                                                                                <>
                                                                                                                  {
                                                                                                                    person.customer_employment.self_employed[
                                                                                                                      rowIndex
                                                                                                                    ].occupation?.name
                                                                                                                  }
                                                                                                                  
                                                                                                                  {(person.customer_employment.self_employed[rowIndex].occupation?.is_negative || 
                                                                                                                    person.customer_employment.self_employed[rowIndex].occupation?.is_restricted) && (
                                                                                                                    <Tooltip
                                                                                                                      title={
                                                                                                                        person.customer_employment.self_employed[rowIndex].occupation?.is_negative &&
                                                                                                                        person.customer_employment.self_employed[rowIndex].occupation?.is_restricted
                                                                                                                          ? "This profile is negative and restricted"
                                                                                                                          : person.customer_employment.self_employed[rowIndex].occupation?.is_negative
                                                                                                                          ? "This profile is negative"
                                                                                                                          : "This profile is restricted"
                                                                                                                      }
                                                                                                                    >
                                                                                                                      <FaExclamationCircle className="occupation_status_false_flage" />
                                                                                                                    </Tooltip>
                                                                                                                  )}
                                                                                                                </>
                                                                                                              )
                                                                                                              : "-"
                                                                                                            : header === "Address" ? (
                                                                                                              <div className="employment_detail_address_section">
                                                                                                              <span>
                                                                                                                {person?.customer_employment?.self_employed?.[rowIndex]?.customer_address?.customer_full_address || "-"}
                                                                                                              </span>
                                                                                                              {person?.customer_employment?.self_employed?.[rowIndex]?.customer_address?.customer_full_address && (
                                                                                                                <span>
                                                                                                                  {renderEditButton(
                                                                                                                    person?.customer_employment?.self_employed?.[rowIndex]?.customer_address_detail?.id,
                                                                                                                    person?.customer_name,
                                                                                                                    "Address"
                                                                                                                  )}
                                                                                                                </span>
                                                                                                              )}
                                                                                                            </div>
                                                                                                            ) : " "
                                                                                                      
                                                                                                      }
                                        {header === "Self Employed" && (
                                          <div

                                            className="employment_tab"
                                          >
                                            <ConditionalRender
                                              userPermissions={user_data?.permissions}
                                              requiredPermissions={["DELTCUST"]}
                                            >
                                              <div
                                                onClick={() => {
                                                  handleDelete(
                                                    person?.customer_employment
                                                      .self_employed[rowIndex]?.id,
                                                    "selfEmployed"
                                                  )
                                                }}>
                                                <DeleteButton />
                                              </div>
                                            </ConditionalRender>
                                            {person?.customer_employment
                                              .self_employed[rowIndex]
                                              ?.created_at &&
                                              person?.customer_employment
                                                .self_employed[rowIndex]
                                                ?.modified_at &&
                                              isModifiedAfterCreated(
                                                person?.customer_employment
                                                  .self_employed[rowIndex]
                                                  ?.created_at,
                                                person?.customer_employment
                                                  .self_employed[rowIndex]
                                                  ?.modified_at
                                              ) && (
                                                <HistoryTable
                                                  id={
                                                    person?.customer_employment
                                                      .self_employed[rowIndex]?.id
                                                  }
                                                  bench_id={"895ad036b4ec3ed9"}
                                                  fieldNameMap={fieldNameMap}
                                                />
                                              )}
                                            <ConditionalRender
                                              userPermissions={
                                                user_data?.permissions
                                              }
                                              requiredPermissions={["EDITCUST"]}
                                            >
                                              {renderEditButton(
                                                person?.customer_employment
                                                  .self_employed[rowIndex]?.id,
                                                person?.customer_name,
                                                "self employed"
                                              )}
                                            </ConditionalRender>
                                          </div>
                                        )}
                                      </>
                                    ) : header === "Self Employed" ? (
                                      ""
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                          ))
                        )}
                    </tbody>
                  </table>
                </div>

                <div className="">
                  <table className="tab_form_main_table">
                    <tbody>
                      {bankAccountData &&
                        [...Array(bankAccountData.overall_max_rental_count)].map(
                          (_, rowIndex) =>
                            rental_Headings.map((header, colIndex) => (
                              <tr
                                key={`row-${colIndex}`}
                                className={
                                  header === "Rental"
                                    ? "employment-row"
                                    : header === "PD Entries"
                                      ? "employment-pd-row"
                                      : ""
                                }
                              >
                                <td className="tab_form_left_heading">
                                  {header === "Rental"
                                    ? `Rental ${rowIndex + 1}`
                                    : header}
                                </td>
                                {bankAccountData?.results?.map(
                                  (person, colIndex) => (
                                    <td
                                      key={`col-${colIndex}`}
                                      className={`tab_form_main_data_table ${getTableColumnClass(
                                        bankAccountData.results.length
                                      )}`}
                                    >
                                      {person.customer_employment.rental &&
                                        person.customer_employment?.rental[
                                        rowIndex
                                        ] ? (
                                        <>
                                          {header === "Property Type"
                                            ? person.customer_employment.rental?.[
                                              rowIndex
                                            ]?.property_type?.display_name ||
                                            "-"
                                            : header === "Year Of Start (In Year)"
                                              ? person.customer_employment.rental?.[
                                                rowIndex
                                              ]?.year_of_start || "-"
                                              : header === "Gross Rent"
                                                ? `₹ ${IndianNumberFormat(
                                                  person.customer_employment
                                                    .rental?.[rowIndex]
                                                    ?.gross_income
                                                ) || "-"}`
                                                : header === "Net Rent"
                                                  ? `₹ ${IndianNumberFormat(
                                                    person.customer_employment
                                                      .rental?.[rowIndex]?.net_income
                                                  ) || "-"}`
                                                  : header === "Lessee Name"
                                                    ? person.customer_employment.rental?.[
                                                      rowIndex
                                                    ]?.lessee_name || "-"
                                                    : header === "Date Of Agreement"
                                                      ? person.customer_employment.rental?.[
                                                        rowIndex
                                                      ]?.date_of_agreement || '-'


                                                      : header === "Municipal Tax Paid By"
                                                        ? person.customer_employment.rental?.[
                                                          rowIndex
                                                        ]?.municipal_tax_paid_by || "-"
                                                        : header === "Maintainance Paid By"
                                                          ? person.customer_employment.rental?.[
                                                            rowIndex
                                                          ]?.maintainance_paid_by || " - "
                                                          : header === "GST Paid By"
                                                            ? person.customer_employment.rental?.[
                                                              rowIndex
                                                            ]?.gst_paid_by || "-"
                                                            : header === "Rental Mode"
                                                              ? person.customer_employment.rental?.[
                                                                rowIndex
                                                              ]?.rental_mode || "-"







                                                              : ""}
                                          {header === "Rental" && (
                                            <div

                                              className="employment_tab"
                                            >
                                              <ConditionalRender
                                                userPermissions={user_data?.permissions}
                                                requiredPermissions={["DELTCUST"]}
                                              >
                                                <div
                                                  onClick={() => {
                                                    handleDelete(
                                                      person?.customer_employment
                                                        .rental[rowIndex]?.id,
                                                      "rental"
                                                    )
                                                  }}>
                                                  <DeleteButton />
                                                </div>
                                              </ConditionalRender>
                                              {person?.customer_employment.rental[
                                                rowIndex
                                              ]?.created_at &&
                                                person?.customer_employment
                                                  .rental[rowIndex]
                                                  ?.modified_at &&
                                                isModifiedAfterCreated(
                                                  person?.customer_employment
                                                    .rental[rowIndex]?.created_at,
                                                  person?.customer_employment
                                                    .rental[rowIndex]?.modified_at
                                                ) && (
                                                  <HistoryTable
                                                    id={
                                                      person?.customer_employment
                                                        .rental[rowIndex]?.id
                                                    }
                                                    bench_id={"70efe71e334c5c36"}
                                                    fieldNameMap={fieldNameMap}
                                                  />
                                                )}
                                              <ConditionalRender
                                                userPermissions={
                                                  user_data?.permissions
                                                }
                                                requiredPermissions={["EDITCUST"]}
                                              >
                                                {renderEditButton(
                                                  person?.customer_employment
                                                    .rental[rowIndex]?.id,
                                                  person?.customer_name,
                                                  "rental"
                                                )}
                                              </ConditionalRender>
                                            </div>
                                          )}
                                        </>
                                      ) : header === "Rental" ? (
                                        ""
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </td>
                                  )
                                )}
                              </tr>
                            ))
                        )}
                    </tbody>
                  </table>
                </div>

                <div className="">
                  <table className="tab_form_main_table">
                    <tbody>
                      {bankAccountData &&
                        [
                          ...Array(bankAccountData.overall_max_pensioner_count),
                        ].map((_, rowIndex) =>
                          pensioner_Headings.map((header, colIndex) => (
                            <tr
                              key={`row-${colIndex}`}
                              className={
                                header === "Pensioner"
                                  ? "employment-row"
                                  : header === "PD Entries"
                                    ? "employment-pd-row"
                                    : ""
                              }
                            >
                              <td className="tab_form_left_heading">
                                {header === "Pensioner"
                                  ? `Pensioner ${rowIndex + 1}`
                                  : header}
                              </td>
                              {bankAccountData?.results?.map(
                                (person, colIndex) => (
                                  <td
                                    key={`col-${colIndex}`}
                                    className={`tab_form_main_data_table ${getTableColumnClass(
                                      bankAccountData.results.length
                                    )}`}
                                  >
                                    {person.customer_employment.pensioner &&
                                      person.customer_employment?.pensioner[
                                      rowIndex
                                      ] ? (
                                      <>
                                        {header === "Organization Name"
                                          ? person.customer_employment
                                            .pensioner?.[rowIndex]
                                            ?.organization_name || "-"
                                          : header === "Industry Type"
                                            ? person.customer_employment
                                              .pensioner?.[rowIndex]
                                              ?.industry_type?.display_name || "-"
                                            : header === "Business Legal Structure"
                                              ? person.customer_employment
                                                .pensioner?.[rowIndex]
                                                ?.business_legal_structure || "-"
                                              : header === "Year Of Start (In Year)"
                                                ? person.customer_employment
                                                  .pensioner?.[rowIndex]
                                                  ?.year_of_start || "-"
                                                : header === "Gross Income"
                                                  ? `₹ ${IndianNumberFormat(
                                                    person.customer_employment
                                                      .pensioner?.[rowIndex]
                                                      ?.gross_income
                                                  ) || "-"}`
                                                  : header === "Net Income"
                                                    ? `₹ ${IndianNumberFormat(
                                                      person.customer_employment
                                                        .pensioner?.[rowIndex]?.net_income
                                                    ) || "-"}`
                                                    : ""}
                                        {header === "Pensioner" && (
                                          <div

                                            className="employment_tab"
                                          >
                                            <ConditionalRender
                                              userPermissions={user_data?.permissions}
                                              requiredPermissions={["DELTCUST"]}
                                            >
                                              <div
                                                onClick={() => {
                                                  handleDelete(
                                                    person?.customer_employment
                                                      .pensioner[rowIndex]?.id,
                                                    "pensioner"
                                                  )
                                                }}>
                                                <DeleteButton />
                                              </div>
                                            </ConditionalRender>
                                            {person?.customer_employment
                                              .pensioner[rowIndex]?.created_at &&
                                              person?.customer_employment
                                                .pensioner[rowIndex]
                                                ?.modified_at &&
                                              isModifiedAfterCreated(
                                                person?.customer_employment
                                                  .pensioner[rowIndex]
                                                  ?.created_at,
                                                person?.customer_employment
                                                  .pensioner[rowIndex]
                                                  ?.modified_at
                                              ) && (
                                                <HistoryTable
                                                  id={
                                                    person?.customer_employment
                                                      .pensioner[rowIndex]?.id
                                                  }
                                                  bench_id={"150dfa7745733128"}
                                                  fieldNameMap={fieldNameMap}
                                                />
                                              )}
                                            <ConditionalRender
                                              userPermissions={
                                                user_data?.permissions
                                              }
                                              requiredPermissions={["EDITCUST"]}
                                            >
                                              {renderEditButton(
                                                person?.customer_employment
                                                  .pensioner[rowIndex]?.id,
                                                person?.customer_name,
                                                "pensioner"
                                              )}
                                            </ConditionalRender>
                                          </div>
                                        )}
                                      </>
                                    ) : header === "Pensioner" ? (
                                      ""
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                          ))
                        )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className='fade-in'>
                <div>
                  <table className='tab_form_main_table no_data_table_height'>
                    <thead>
                      <tr>
                        <th className="tab_form_key_parameters">
                          {Headings[0]}
                        </th>
                        {bankAccountData?.results?.map((person, index) => (
                          <th
                            key={index}
                            className="tab_form_top_heading no_data_heading_bottom_border"
                          >
                            <span className='no_data_heading_content'>
                              <div>
                                <span>{`${person?.customer_name}`} </span>
                                <span>
                                  <span>{`(${person?.customer_type})`} </span>
                                  {/* <img
                                  src={verify}
                                  alt="group"
                                  className='verify_icon'
                                /> */}
                                </span>
                              </div>
                              <ConditionalRender
                                userPermissions={user_data?.permissions}
                                requiredPermissions={["POSTCUST"]}
                              >
                                <div
                                  className="tab_form_sub_edit"
                                  onClick={() => {
                                    showDrawerEmploymentType();
                                    setSelectedCustomerId(person.customer_id);
                                    setSelectedPerson(person);
                                  }}
                                >
                                  <img src={add} alt="Add Button" />
                                </div>
                              </ConditionalRender>
                            </span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {/* Placeholder to ensure table body aligns */}
                      {bankAccountData?.results?.length ? (
                        <tr>
                          <td colSpan={bankAccountData?.results?.length + 1}>
                            {/* Your data rows go here */}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td
                            colSpan={bankAccountData?.results?.length + 1}
                          ></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div
                  className='no_data_icon'
                >
                  <CustomNoData />
                </div>
              </div>
            )}
        </div>
      </div>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          // setOpenModal(false);
          confirmDelete();
        }}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ loading: loadingDelete }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>
    </>
  );
};

export default EmployementDetails;
