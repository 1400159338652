import React, { useEffect, useState } from 'react'
import { getCaseHealthSummaryDocVerificationService } from '../services/caseHealthService';
import { Table, message } from "antd";
import ErrorMessage from '../../../../utils/errorHandling/ErrorMessage';
import { getLoanDetailsById } from '../../loanSummary/services/loanapplicationService';
import verify from "../assets/Group.png";

const DocumentVerificationTable = ({ loan_id, open }) => {

    const [documentVerificationData, setDocumentVerificationData] =
    useState([]);
    const [scheme, setScheme] = useState();
    const [verificationcolumns, setVerificationColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]); // Active loans & FOIR in table store


    useEffect(() => {
        const fetchLoanDetails = async () => {
          try {
            const response = await getLoanDetailsById(loan_id);
            setScheme(response.data.scheme.id);
          } catch (error) {
            message.error(
              ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
          }
        };
      
        fetchLoanDetails();
      }, [loan_id]);

    const fetchDocumentVerificationData = async () => {
        try {
          const response = await getCaseHealthSummaryDocVerificationService(
            loan_id,
            scheme
          );
          setDocumentVerificationData(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          message.error(
            ErrorMessage(error?.response?.status, error?.response?.data?.message)
          );
        }
      };

      useEffect(() => {
        if (scheme) {
          fetchDocumentVerificationData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [loan_id, open, scheme]);


      useEffect(() => {

        if (!documentVerificationData || !documentVerificationData.customers) {
          return;
        }
    
        const categories = documentVerificationData.unique_document_categories;
        const customers = documentVerificationData.customers;
        const documentMap = {};
    
        categories?.forEach((category) => {
          documentMap[category] = [];
        });
    
        customers?.forEach((customer) => {
          customer.documents?.forEach((doc) => {
            if (documentMap[doc.document_category]) {
              doc.document_types?.forEach((type) => {
                const docName = Object.keys(type)[0];
                if (!documentMap[doc.document_category].includes(docName)) {
                  documentMap[doc.document_category].push(docName);
                }
              });
            }
          });
        });
    
        let tableRows = [];
        Object.entries(documentMap).forEach(([category, docs]) => {
          docs.forEach((doc, docIndex) => {
            let rowData = {
              key: `${category}-${doc}`,
              category: docIndex === 0 ? category : "", 
              document: doc,
              rowSpan: docIndex === 0 ? docs.length : 0, 
            };
    
            customers.forEach((customer) => {
              const docExists = customer.documents.some((d) =>
                d.document_types.some((dt) => dt[doc] === true)
              );
              rowData[customer.name] = docExists ? (
                <img src={verify} alt="Verified" className="verify-icon" />
              ) : (
                <span className="red-cross">❌</span>
              );
            });
    
            tableRows.push(rowData);
          });
        });
        setTableData(tableRows);
    
        const customerColumns = customers?.map((customer) => ({
          title: customer.name,
          dataIndex: customer.name,
          key: customer.name,
          align: "center",
        }));
    
        const newColumns = [
          {
            title: "Document List",
            dataIndex: "category",
            key: "category",
            align: "center",
            width: 200,
            onHeaderCell: () => ({
              colSpan: 2, // Header ko dono columns ke upar span karne ke liye
            }),
            render: (text, record) => ({
              children: <strong>{text}</strong>,
              props: { rowSpan: record.rowSpan },
            }),
          },
          {
            title: "", // Empty title
            dataIndex: "document",
            key: "document",
            width: 150,
            onHeaderCell: () => ({
              colSpan: 0, // Ye column ka header hide karne ke liye
            }),
          },
          
          ...customerColumns,
        ];
    
        setVerificationColumns(newColumns);
      }, [documentVerificationData]);


  return (
    <div>
       <div className="customer_table">
          <Table
            dataSource={tableData}
            columns={verificationcolumns}
            rowKey="key" 
            pagination={false}
            loading={loading}
          />
        </div>
    </div>
  )
}

export default DocumentVerificationTable