import { message, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import IndianNumberFormat from '../../../../../utils/indianNumberFormat/IndianNumberFormat';
import ConditionalRender from '../../../../../utils/authorization/AuthorizeComponent';
import { useSelector } from 'react-redux';
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { getIncomeCalculationsByLoanId } from '../services/incomeDetailsServices';
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';

const IncomeNonCashView = ({ nonCashIncomeData, renderEditButton, showDrawer, schemeDisplayName }) => {
    const [collapseSections, setCollapseSections] = useState(true);
    const { user_data } = useSelector((state) => state.user);
    const { encrypted_loan_id } = useParams();
    // const [incomeCalculationColumns, setIncomeCalculationColumns] = useState([]);
    const [incomeCalculationData, setIncomeCalculationData] = useState([]);
    // const [loading, setLoading] = useState(true);
    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
    }

    const Noncashcolumns = [
        {
            title: 'SR.NO',
            dataIndex: 'key',
            key: 'key',
            fixed: 'left',
            render: (text, record, index) => {
                // Hide SR.NO for custom rows
                if (record.key === "value_to_be_considered" || record.key === "eligible_income") {
                    return null;
                }
                return <span>{index + 1}</span>;
            },
        },
        {
            title: 'Month & Year',
            dataIndex: 'month_of_salary',
            render: (text) => (text !== null && text !== undefined && text !== "" ? text : '-'),
        },
        {
            title: 'Fixed Net Salary',
            dataIndex: 'monthly_net_salary_non_cash',
            render: (text) => (text !== null && text !== undefined && text !== "" ? `₹ ${IndianNumberFormat(text)}` : '-'),
        },
        {
            title: 'OT/Variable',
            dataIndex: 'over_time_pay',
            key: 'over_time_pay',
            render: (text) => (text !== null && text !== undefined && text !== "" ? IndianNumberFormat(text) : '-'),
        },
    ];

    useEffect(() => {
        const fetchIncomeData = async () => {

            try {
                const response = await getIncomeCalculationsByLoanId(loan_id);
                if (response.status === 200 && response.success) {
                    setIncomeCalculationData(response.data);
                    console.log("incomeCalculationData", incomeCalculationData)
                    // if (response?.data?.length > 0) {

                    //     const { columns, data: transformedData } = IncomeCalculationsColumns(schemeDisplayName, response?.data);
                    //     setIncomeCalculationColumns(columns);
                    //     setIncomeCalculationData(transformedData);
                    // } else {
                    //     setIncomeCalculationColumns([]);
                    //     setIncomeCalculationData([]);
                    // }
                } else {
                    // setIncomeCalculationColumns([]);
                    setIncomeCalculationData([]);
                }
            } catch (error) {
                message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
            } 
            // finally {
            //     setLoading(false);
            // }
        };

        fetchIncomeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schemeDisplayName, nonCashIncomeData]);

    const valueToConsider = {
        monthly_net_salary_non_cash: incomeCalculationData.find(item => item.value_to_consider_avg_monthly_net_salary_non_cash !== undefined)?.value_to_consider_avg_monthly_net_salary_non_cash || 0,
        over_time_pay: incomeCalculationData.find(item => item.value_to_consider_ot_pay !== undefined)?.value_to_consider_ot_pay || 0,
    };

    const eligibleIncome = {
        monthly_net_salary_non_cash: incomeCalculationData.find(item => item.eligible_income_avg_monthly_net_salary_non_cash !== undefined)?.eligible_income_avg_monthly_net_salary_non_cash || 0,
        over_time_pay: incomeCalculationData.find(item => item.eligible_income_ot_pay !== undefined)?.eligible_income_ot_pay || 0,
    };

    // Custom Rows (Dynamically populated)
    const customRows = [
        {
            key: "value_to_be_considered",
            month_of_salary: <strong>Value to be Considered</strong>,
            monthly_net_salary_non_cash: valueToConsider.monthly_net_salary_non_cash,
            over_time_pay: IndianNumberFormat(valueToConsider.over_time_pay),
        },
        {
            key: "eligible_income",
            month_of_salary: <strong>Eligible Income</strong>,
            monthly_net_salary_non_cash: eligibleIncome.monthly_net_salary_non_cash,
            over_time_pay: IndianNumberFormat(eligibleIncome.over_time_pay),
        },
    ];


    return (
        <>
            {nonCashIncomeData.length > 0 && (
                <div className="heading_details_container">
                    <div className={`heading_text_cam_income_details ${collapseSections ? "" : "no-border"}`}>
                        <div>Income details</div>
                        {collapseSections ? (
                            <UpOutlined
                                onClick={() => setCollapseSections(!collapseSections)}
                            />
                        ) : (
                            <DownOutlined
                                onClick={() => setCollapseSections(!collapseSections)}
                            />
                        )}
                    </div>
                    {collapseSections && (
                        <div>
                            {nonCashIncomeData.map((data) => (
                                <div key={data.id} style={{ marginBottom: "20px" }}>
                                    <div className="heading_text">{data.customer?.name}
                                        <ConditionalRender
                                            userPermissions={user_data?.permissions}
                                            requiredPermissions={["EDITCAM1"]}
                                        >
                                            {renderEditButton(showDrawer, data.customer?.id)}
                                        </ConditionalRender>
                                    </div>
                                    <div style={{ overflowX: "auto" }}>
                                        <Table
                                            className="insurance_table"
                                            columns={Noncashcolumns}
                                            // dataSource={data.income_detail}
                                            dataSource={[...data.income_detail, ...customRows]}
                                            pagination={false}
                                            rowKey="id"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}

        </>
    )
}

export default IncomeNonCashView