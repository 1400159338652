import { Col, Form, message, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { listSchemeService, getSchemeCheckMatchService, getLoanDetailsByLoanIdForScheme } from '../services/incomeDetailsServices';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { formConfig } from '../../../../../config/schemeConfig';
import { useParams } from 'react-router-dom';
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';

const { Option } = Select;

const IncomeDetailsForm = ({ onClose, open, toggleRefreshTableData, selectedPerson, selectedSchemeIncome, selectedIncomeConsidered }) => {
    const [form] = Form.useForm();
    const { encrypted_loan_id } = useParams();
    const [schemeList, setSchemeList] = useState([]);
    const [selectedScheme, setSelectedScheme] = useState(null);
    const [selectedIncome, setSelectedIncome] = useState(null);
    const [showFormComponent, setShowFormComponent] = useState(false);
    const [loanDetailsByLoanId, setLoanDetailsByLoanId] = useState(null);
    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    useEffect(() => {
        if (!loanDetailsByLoanId?.loan_type?.id) return;  // Prevent unnecessary API call
    
        const getAllSchemes = async () => {
            try {
                const response = await listSchemeService(loanDetailsByLoanId?.loan_type?.id);
                setSchemeList(response.data);
            } catch (error) {
                message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
            }
        };
        getAllSchemes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanDetailsByLoanId, open]);
    useEffect(() => {
        const getAllLoanData = async () => {
            try {
                const response = await getLoanDetailsByLoanIdForScheme(loan_id);
                setLoanDetailsByLoanId(response.data);

            } catch (error) {
                message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
            }
        };
        getAllLoanData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);


    const handleSchemeChange = async (value) => {
        setSelectedScheme(value);
        try {
            const response = await getSchemeCheckMatchService(loan_id, value);
            if ((response.status = 200 && response.success)) {
                setShowFormComponent(true);
            }
        } catch (error) {
            message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
        }
    };
    const handleIncomeChange = (value) => {
        setSelectedIncome(value);
    };

    useEffect(() => {
        if (open) {
            //   getBankData();
        } else {
            form.resetFields();
            setSelectedScheme(null);
            setShowFormComponent(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        if (selectedSchemeIncome) {
            setSelectedScheme(selectedSchemeIncome);
            setShowFormComponent(true);
            form.setFieldsValue({ scheme: selectedSchemeIncome });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSchemeIncome]);
    useEffect(() => {
        form.setFieldsValue({ income_considered: selectedIncomeConsidered });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, form]);
    return (
        <>
            <Form
                form={form}
                name="salary-details-form"
                layout="vertical"
            // onFinish={handleSubmit}
            >
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item name="scheme" label="Scheme" required
                            rules={[{ required: true, message: 'Please select a scheme' }]} >
                            <Select
                                placeholder="Please Select"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                onChange={handleSchemeChange}
                            >
                                {schemeList.map((scheme) => (
                                    <Option key={scheme.id} value={scheme.code}>
                                        {scheme.display_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {selectedSchemeIncome && (
                        <Col span={8}>
                            <Form.Item
                                name="income_considered"
                                label="Income Considered"
                                rules={[{ required: true, message: 'Please select income considered' }]}
                                required
                            >
                                <Select
                                    placeholder="Please Select"
                                    onChange={handleIncomeChange}
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Row>

            </Form>
            {/* {selectedScheme && ( */}
            {selectedScheme && showFormComponent && (
                (() => {
                    const formConfigMatch = formConfig.find(form => form.schemeDisplayName === selectedScheme);
                    if (formConfigMatch) {
                        const FormComponent = formConfigMatch.component;

                        // Conditionally set props based on the form type
                        const commonProps = {
                            closeForm: onClose,
                            open: open,
                            refreshData: toggleRefreshTableData,
                            selectedScheme: selectedScheme,
                            selectedIncome: selectedIncome,
                            id: selectedPerson,
                            selectedIncomeConsidered: selectedIncomeConsidered
                        };

                        if (
                            selectedScheme === 'NORMALI5' ||
                            selectedScheme === 'NORMALIN' ||
                            selectedScheme === 'INCOMEMU' ||
                            selectedScheme === 'INCOMEES'
                        ) {
                            return (
                                <FormComponent
                                    {...commonProps}

                                />
                            );
                        }


                        return (
                            <FormComponent
                                {...commonProps} // Spread common props for Emi and Rental forms
                            />
                        );
                    }
                    return null;
                })()
            )}
        </>
    )
}

export default IncomeDetailsForm