import { axiosRequest } from '../../../utils/api/axiosRequest';

export const getEmployeeAssignedWork = () => {
    return new Promise(async (resolve, reject) => {
        try {
          const response = await axiosRequest.get("/api/v1/dashboard_lite/get-work-list/");
          return resolve(response.data)
        } catch (error) {
          return reject(error)
        }
      })
}

export const getEmployeeMeetings = (access_token, refresh_token,selectedDate) => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/calender-event/?google_access_token=${access_token}&google_refresh_token=${refresh_token}&date=${selectedDate}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
}

