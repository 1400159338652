import { Table, message, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
// import { useParams } from 'react-router-dom';
// import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
// import { getIncomeCalculationsByLoanId } from '../services/incomeDetailsServices';
import IncomeCalculationsColumns from './IncomeCalculationsColumns';
import { UpOutlined, DownOutlined } from "@ant-design/icons";

const IncomeCalculation = ({ refreshTableData, schemeDisplayName, previewData }) => {
    
    const [incomeCalculationColumns, setIncomeCalculationColumns] = useState([]);
    const [incomeCalculationData, setIncomeCalculationData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [collapseSections, setCollapseSections] = useState(true);

    useEffect(() => {
        const fetchIncomeData = async () => {
            try {
                if (previewData) {
                    const { columns, data: transformedData } = IncomeCalculationsColumns(schemeDisplayName, previewData);
                    setIncomeCalculationColumns(columns);
                    setIncomeCalculationData(transformedData);
                    console.log(transformedData);   
                } else {
                    setIncomeCalculationColumns([]);
                    setIncomeCalculationData([]);
                }

            } catch (error) {
                message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
            } finally {
                setLoading(false);
            }
        };

        fetchIncomeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTableData, schemeDisplayName, previewData]);

    return (
        <div className="heading_details_container">
            <div className="heading_text">
                Income Calculations
                {collapseSections ? (
                    <UpOutlined
                        onClick={() => setCollapseSections(!collapseSections)}
                    />
                ) : (
                    <DownOutlined
                        onClick={() => setCollapseSections(!collapseSections)}
                    />
                )}</div>
            {collapseSections && (
                <div className='income_table'>
                    {loading ? (
                        <Skeleton active paragraph={{ rows: 5 }} />
                    ) : (

                        <Table
                            columns={incomeCalculationColumns}
                            dataSource={incomeCalculationData}
                            pagination={false}
                            scroll={{ x: true }}
                        />

                    )}
                </div>
            )}
        </div>
    );
};

export default IncomeCalculation;
