import React, { useState, useEffect } from "react";
import { Col, Row, Select, message } from "antd";
import ICICIInsuranceForm from "./ICICIInsuranceForm";
import MagmaHDIInsuranceForm from "./MagmaHDIInsuranceForm";
import HDFCInsuranceForm from "./HDFCInsuranceForm";
import {
  getPersonalDetailsByIdService,
  getInsuranceCompaniesByIdService,
} from "../services/loanInsuranceService";
import "../styles/insuranceForm.css";

const { Option } = Select;

const InsuranceForm = ({
  closeForm,
  open,
  id,
  toggleRefreshInsurance,
  setCount,
  count,
  setDisabledButton,
  disabledButton,
  loan_id,
  monthlyIncome,
}) => {
  const [selectedInsuranceType, setSelectedInsuranceType] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [errors, setErrors] = useState({});
  const [personalData, setPersonalData] = useState([]);
  const [companies, setCompanies] = useState([]);

  const handleInsuranceTypeChange = (value) => {
    setSelectedInsuranceType(value);
    setErrors((prevErrors) => ({ ...prevErrors, insurance_type: "" }));
    setSelectedCustomerId(undefined);
  };

  const handleCustomerChange = (value) => {
    setSelectedCustomerId(value);
    setErrors((prevErrors) => ({ ...prevErrors, insured_customer: "" }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPersonalDetailsByIdService(loan_id);
        setPersonalData(response.data.customers);
      } catch (error) {
        message.error(
          `${error?.response?.status}: ${error?.response?.data?.message}`
        );
      }
    };

    if (loan_id) {
      fetchData();
    }
  }, [loan_id]);

  useEffect(() => {
    const fetchInsuranceCompanies = async () => {
      try {
        const res = await getInsuranceCompaniesByIdService();
        setCompanies(res?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchInsuranceCompanies();
  }, [id]);

  // Reset form when the drawer is closed
  useEffect(() => {
    if (!open) {
      setSelectedInsuranceType("");
      setSelectedCustomerId(undefined); // Changed from commented out to properly reset
      setErrors({});
    }
  }, [open]);

  const renderInsuranceForm = () => {
    if (!selectedInsuranceType) {
      return null;
    }

    switch (selectedInsuranceType) {
      case "ICICIPRU":
        return (
          <ICICIInsuranceForm
            id={id}
            loan_id={loan_id}
            customerId={selectedCustomerId}
            closeForm={closeForm}
            insuranceType={selectedInsuranceType}
            setSelectedCustomer={setSelectedCustomerId}
            setSelectedInsuranceType={setSelectedInsuranceType}
            toggleRefreshInsurance={toggleRefreshInsurance}
            monthlyIncome={monthlyIncome}
          />
        );

      case "HDFCERGO":
        return (
            <HDFCInsuranceForm
              id={id}
              loan_id={loan_id}
              customerId={selectedCustomerId}
              closeForm={closeForm}
              insuranceType={selectedInsuranceType}
              setSelectedCustomer={setSelectedCustomerId}
              setSelectedInsuranceType={setSelectedInsuranceType}
              toggleRefreshInsurance={toggleRefreshInsurance}
              monthlyIncome={monthlyIncome}
            />
        );

      case "MAGMAHDI":
        return (
          <MagmaHDIInsuranceForm
            id={id}
            customerId={selectedCustomerId}
            setCount={setCount}
            loan_id={loan_id}
            count={count}
            closeForm={closeForm}
            insuranceType={selectedInsuranceType}
            setSelectedCustomer={setSelectedCustomerId}
            setSelectedInsuranceType={setSelectedInsuranceType}
            toggleRefreshInsurance={toggleRefreshInsurance}
            disabledButton={disabledButton}
            setDisabledButton={setDisabledButton}
            monthlyIncome={monthlyIncome}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="drawer-insurance-space">
        <Row gutter={16}>
          <Col span={12}>
            <div>
              <label className="insurancetype-size">
                <span className="astrick">*</span>
                Insurance Type
              </label>
              <br />
              <Select
                placeholder="Please Select"
                allowClear
                onChange={handleInsuranceTypeChange}
                value={selectedInsuranceType || undefined}
                className="insurance_field"
              >
                {companies.map((company) => (
                  <Option key={company.id} value={company.code}>
                    {company.name}
                  </Option>
                ))}
              </Select>
              {errors.insurance_type && (
                <div className="insurance_error">{errors.insurance_type}</div>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div>
              <label className="insurancetype-size">
                <span className="astrick">*</span>
                Select Insured Customer
              </label>
              <br />
              <Select
                placeholder="Please Select Customer"
                allowClear
                onChange={handleCustomerChange}
                value={selectedCustomerId}
                className="insurance_field"
              >
                {personalData.map((person) => (
                  <Option key={person.id} value={person.id}>
                    {person.first_name} {person.middle_name} {person.last_name}{" "}
                    ({person.customer_type.display_name})
                  </Option>
                ))}
              </Select>
              {errors.insured_customer && (
                <div className="insurance_error">{errors.insured_customer}</div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="render_insurance_form">{renderInsuranceForm()}</div>
    </>
  );
};

export default InsuranceForm;
