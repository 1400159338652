import React, { useEffect, useState } from "react";
import { Table, message, Modal, Divider, Form, Skeleton, Input, Select, Row, Col } from "antd";
import { Tooltip } from "antd";
import {
  getLoanStagesListService,
  stageToCompleteService,
} from "../services/loanapplicationService";
import { useParams, Link } from "react-router-dom";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { decrypt, encrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { getActionRoute } from "../../../../utils/stageFlow/StageFlow";
import SendBackForm from "./SendBackForm";
import QueryIcon from "../assets/query.svg";
import ForwardIcon from "../assets/forward.svg";
import CompleteIcon from "../assets/complete.svg";
import ForwardForm from "./ForwardForm";
import CancelIcon from "../assets/cancel.svg";
import { useSelector } from "react-redux";
import { getAllUsers, updateUserAndStatusById } from "../services/sendBackService";

const LoanApplicationDetails = ({ open, setSubmitStageBar, loan_details, onRefresh }) => {
  const { encrypted_loan_id } = useParams();
  const [stagesData, setStagesData] = useState(null);
  const [stageCode, setStageCode] = useState(null);
  const [getID, setID] = useState(null);
  const [users, setUsers] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isForwardModalVisible, setIsForwardModalVisible] = useState(false);
  const [selectedStageId, setSelectedStageId] = useState(null);
  const [selectedStageStatus, setSelectedStageStatus] = useState(null);

  const [refreshTableData, setRefreshTableData] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmStatusModalVisible, setConfirmStatusModalVisible] =
    useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [selectedStageCode, setSelectedStageCode] = useState(null);
  const [sendBackForm] = Form.useForm();
  const [forwaedForm] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const allowedStages = ["CAM", "Dedupe", "Technical", "Legal", "RCU", "FI"];

  const { user_data } = useSelector((state) => state.user);
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  // const showModal = (stage_code) => {
  //   setIsModalVisible(true);
  //   setStageCode(stage_code);
  // };

  const showModal = (record) => {
    setIsModalVisible(true);
    setStageCode(record); // You may still need the stage_code for some logic.

    sendBackForm.setFieldsValue({
      // stage: record.stage,
      assign_to: record?.action_by?.id,
    });
  };
  // const showForwardModal = (id) => {
  //   setIsForwardModalVisible(true);
  //   setID(id);
  // };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersresponse = await getAllUsers(loan_id);
        setUsers(usersresponse.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmModalVisible]);

  const showForwardModal = (record) => {
    setIsForwardModalVisible(true);
    setSelectedRecord(record);
    setID(record.id);

  };

  const handleCancel = () => {
    setIsModalVisible(false);
    sendBackForm.resetFields();
  };

  const handleCloseForwardModal = () => {
    setIsForwardModalVisible(false);
    forwaedForm.resetFields();
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  const showConfirmModal = (id, stage, stage_code, status) => {
    setSelectedStage(stage);
    setSelectedStageCode(stage_code);
    setSelectedStageId(id);
    setSelectedStageStatus(status);
    setConfirmModalVisible(true);
  };

  const showConfirmStatusModal = (id, stage) => {
    setSelectedStageId(id);
    setSelectedStage(stage);
    setConfirmStatusModalVisible(true);
  };

  const handleConfirmOk = async () => {
    setLoading(true); // Start loading

    try {

      let selectedUser = null;
      let user = null;
      if (selectedStage === "QC") {
        const values = await form.validateFields();
        selectedUser = values.user;
      }
      if (selectedStageStatus === "Vendor Completed" || selectedStageStatus === "Initiated" || selectedStageStatus === "Forward") {
        user = user_data?.id
      }

      await handleCompleteStage(
        selectedStageId,
        selectedStage,
        selectedStageCode,
        selectedUser,
        user
      );

      setConfirmModalVisible(false);
      form.resetFields();

    } catch (error) {
      // Handle error if any
      setLoading(false); // Stop loading
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };


  const handleConfirmStatusOk = async () => {
    try {
      const values = await form.validateFields();
      const reason = values.reason;
      handleCancelStage(selectedStageId, selectedStage, reason);
      setConfirmStatusModalVisible(false);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLoanStagesListService(loan_id);

        const transformedData = response.data.map(stage => {
          const childrenData = stage.history.map(history => ({
            ...history,
            parentStage: stage.stage, // Link the history to the parent stage
          }));

          return {
            ...stage,
            ...(childrenData.length > 0 ? { children: childrenData } : {}), // Only include `children` if there's data
          };
        });



        setStagesData(transformedData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    if (refreshTableData || open) {
      fetchData();
    }
  }, [loan_id, refreshTableData, open]);

  const handleCompleteStage = async (selectedStageId, stage, stage_code, selectedUser, user) => {
    try {
      const payload = {
        ...(selectedUser || user ? { user: stage === "QC" ? selectedUser : user } : {}),
        // user: stage === "QC" ? selectedUser : user,
        stage: stage_code,
        stage_status: "COMPLETE",
      };
      const response = await stageToCompleteService(selectedStageId, payload);
      if ((response.status = 200 && response.success)) {
        message.success(`${stage} Stage completed successfully`);
        setSubmitStageBar();
        toggleRefreshTableData();
        onRefresh();
      }
    } catch (error) {
      setLoading(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleCancelStage = async (id, stage, reason) => {
    try {
      if (id) {
        const payload = {
          stage_status: "CANCELLE",
          reason,
          user: user_data?.id,
        };
        const response = await updateUserAndStatusById(id, payload);
        if ((response.status = 200 && response.success)) {
          message.success(`${stage} Stage cancelled successfully`);
          form.resetFields();
          setSubmitStageBar();
          toggleRefreshTableData();
          onRefresh();
        }
      }
    } catch (error) {
      form.resetFields();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const getActionButton = (record) => {
    let { id, stage, stage_code, status, handled_by } = record;

    let isCreditManagerOrSuperAdmin = false;

    if (!user_data?.is_agent) {
      isCreditManagerOrSuperAdmin = false;
    }

    let hasCreditedPermission = user_data?.permissions?.some(
      (permission) => permission.code === "CREDITED"
    );

    if (
      user_data?.roles.some(
        (role) => role.code === "SUPADMIN"
      )
    ) {
      isCreditManagerOrSuperAdmin = true;
    }

    if (loan_details?.credit_manager?.id === user_data?.id) {
      isCreditManagerOrSuperAdmin = true;
    }
    // if (hasCreditedPermission === true) {
    //   if (hasCreditedPermission && stage === "QC" && status === "Initiated") {
    //     status = "Initiated";
    //   }

    // }
    // if (handled_by?.id !== user_data?.id) {
    //   if (isCreditManagerOrSuperAdmin === false) {
    //     status = "default";
    //   }
    // }
    if (hasCreditedPermission && stage === "QC" && status === "Initiated") {
      status = "Initiated";

    } else if (handled_by?.id !== user_data?.id && isCreditManagerOrSuperAdmin === false) {
      status = "default";
    }


    if (stage === "Dedupe") {
      return <div>-</div>;
    }

    const tooltipText = (action) => {
      switch (action) {
        case "Complete":
          return `Complete ${stage}`;
        case "Forward":
          return `Forward ${stage}`;
        case "Cancel":
          return `Cancel ${stage}`;
        case "Send Back":
          return `Send Back ${stage}`;
        default:
          return "";
      }
    };

    switch (status) {
      case "Completed":
        // if (stage !== "Lead") {
        if (isCreditManagerOrSuperAdmin) {
          return (
            <Tooltip title={tooltipText("Send Back")}>
              <img
                className="stage_icons"
                alt="Send Back Icon"
                src={QueryIcon}
                onClick={() => showModal(record)} // Pass the entire record
              />
            </Tooltip>
          );
        }
        // }
        return <div>-</div>;

      // case "Send Back":
      //   return (
      //     <>
      //       <Tooltip title={tooltipText("Complete")}>
      //         <img
      //           alt="Complete Icon"
      //           className="stage_icons"
      //           src={CompleteIcon}
      //           onClick={() => showConfirmModal(id, stage, stage_code)}
      //         />
      //       </Tooltip>
      //       <Tooltip title={tooltipText("Forward")}>
      //         <img
      //           alt="Forward Icon"
      //           className="stage_icons"
      //           src={ForwardIcon}
      //           onClick={() => showForwardModal(record)}
      //         />
      //       </Tooltip>
      //       <Tooltip title={tooltipText("Cancel")}>
      //         <img
      //           alt="Cancel Icon"
      //           className="stage_icons"
      //           src={CancelIcon}
      //           onClick={() => showConfirmStatusModal(id, stage)}
      //         />
      //       </Tooltip>
      //     </>
      //   );

      case "Initiated":

        return (
          <>
            <Tooltip title={tooltipText("Complete")}>
              <img
                alt="Complete Icon"
                className="stage_icons"
                src={CompleteIcon}
                onClick={() => showConfirmModal(id, stage, stage_code)}
              />
            </Tooltip>
            {stage !== "QC" && (
              <Tooltip title={tooltipText("Forward")}>
                <img
                  alt="Forward Icon"
                  className="stage_icons"
                  src={ForwardIcon}
                  onClick={() => showForwardModal(record)}
                />

              </Tooltip>
            )}
            {!["Income Evaluation", "QC"].includes(stage) && (
              <Tooltip title={tooltipText("Cancel")}>
                <img
                  alt="Cancel Icon"
                  className="stage_icons"
                  src={CancelIcon}
                  onClick={() => showConfirmStatusModal(id, stage)}
                />
              </Tooltip>
            )}
          </>
        );

      case "Forward":
        return (
          <>
            <Tooltip title={tooltipText("Complete")}>
              <img
                alt="Complete Icon"
                className="stage_icons"
                src={CompleteIcon}
                onClick={() => showConfirmModal(id, stage, stage_code)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Forward")}>
              <img
                alt="Forward Icon"
                className="stage_icons"
                src={ForwardIcon}
                onClick={() => showForwardModal(record)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Cancel")}>
              <img
                alt="Cancel Icon"
                className="stage_icons"
                src={CancelIcon}
                onClick={() => showConfirmStatusModal(id, stage)}
              />
            </Tooltip>
          </>
        );

      case "Vendor Completed":
        return (
          <>
            <Tooltip title={tooltipText("Complete")}>
              <img
                alt="Complete Icon"
                className="stage_icons"
                src={CompleteIcon}
                onClick={() => showConfirmModal(id, stage, stage_code, status)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Forward")}>
              <img
                alt="Forward Icon"
                className="stage_icons"
                src={ForwardIcon}
                onClick={() => showForwardModal(record)}
              />
            </Tooltip>
            {/* <Tooltip title={tooltipText("Cancel")}>
              <img
                alt="Cancel Icon"
                className="stage_icons"
                src={CancelIcon}
                onClick={() => showConfirmStatusModal(id, stage)}
              />
            </Tooltip> */}
            {stage !== "Income Evaluation" && (
              <Tooltip title={tooltipText("Cancel")}>
                <img
                  alt="Cancel Icon"
                  className="stage_icons"
                  src={CancelIcon}
                  onClick={() => showConfirmStatusModal(id, stage)}
                />
              </Tooltip>
            )}

          </>
        );
      case "Cancelled":
        return <div>-</div>;

      default:
        return <div>-</div>;
    }
  };

  const columns = [
    {
      //  title: "Sr. No",
      //  dataIndex: "srNo",
      //  key: "srNo",
      //  render: (text, record, index) => index + 1,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      width: 170,
      render: (text) => text || "-",
    },
    {
      title: "Action By",
      dataIndex: "action_by",
      key: "action_by",
      width: 170,
      render: (action_by) => {
        return action_by !== null ? action_by?.name : " - ";
      },
    },
    {
      title: "Assigned To",
      dataIndex: "handled_by",
      key: "handled_by",
      width: 200,
      render: (handled_by) => {
        if (!handled_by) return "-";

        if (handled_by.type === "External") {
          return `${handled_by.agency}${handled_by.name ? ` (${handled_by.name})` : ""}`;
        }

        return handled_by.name || "-";
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (text, record) => {
        const { stage, status, id, handled_by, parentStage } = record;
        const encrypted_stage_id = encrypt(id.toString());
        if (parentStage) {
          // Historical rows should always show plain text
          return <span>{status || "-"}</span>;
        }
        // if (allowedStages.includes(stage) && status === "Completed") {
        if (
          allowedStages.includes(stage) &&
          (status === "Completed" || status === "Vendor Completed")
        ) {
          // Always show the Link for "Completed" status
          return (
            <Link
              to={getActionRoute(
                stage,
                status,
                encrypted_loan_id,
                encrypted_stage_id
              )}
              className="stage_status_link"
            >
              {status}
            </Link>
          );
        }

        if (
          allowedStages.includes(stage) &&
          (status === "Initiated" || status === "Forward" || status === "Send Back") &&
          (handled_by?.id === user_data?.id || handled_by?.agent_list?.includes(user_data?.id))
          // (handled_by?.type === "Internal" ? handled_by?.id === user_data?.id : handled_by?.agent_list?.includes(user_data?.id))
          // handled_by?.id === user_data?.id
        ) {
          // Show the Link for "Initiated" status only if handled_by matches user_data
          return (
            <Link
              to={getActionRoute(
                stage,
                status,
                encrypted_loan_id,
                encrypted_stage_id
              )}
              className="stage_status_link"
            >
              {status}
            </Link>
          );
        }

        // Default rendering for statuses not meeting the conditions
        return <span>{status || "-"}</span>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 200,
      render: (text, record) => {
        if (record.status === "Completed") {
          return record.completed_date || "-";
        }
        return record.initiated_date || "-";
      },
    },
    // {
    //   title: "Completed Date",
    //   dataIndex: "completed_date",
    //   key: "completed_date",
    //   width: 200,
    //   render: (text) => text || "-",
    // },
    {
      title: "TAT",
      dataIndex: "tat",
      key: "tat",
      width: 100,
      render: (text) => text || "-",
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      // render: (text, record) =>
      //   record?.handled_by?.id === user_data?.id
      //     ? getActionButton(record)
      //     : "-",
      render: (text, record) => {

        if (record.parentStage) {
          // For history rows, return only a hyphen
          return "-";
        }
        return record ? getActionButton(record) : "-";



      }
    },
  ];

  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {stagesData?.length > 0 && (
            <Table
              columns={columns}
              dataSource={stagesData}
              pagination={false}
              rowKey={(record) => `stage-${record.id}`}
              rowClassName={(record) => (record.parentStage ? "history-row" : "")}
            // indentSize={20}
            />
          )}
        </>
      )}
      <Modal
        width={700}
        title="Sendback"
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="sendback_modal"
      >
        <Divider className="sendback_modal_divider" />

        <SendBackForm
          closeForm={handleCancel}
          stage_code={stageCode}
          form={sendBackForm}
          toggleTableRefreshData={toggleRefreshTableData}
          setSubmitStageBar={setSubmitStageBar}
          onRefresh={onRefresh}
        />
      </Modal>
      <Modal
        width={700}
        title="Forward"
        open={isForwardModalVisible}
        footer={null}
        onCancel={handleCloseForwardModal}
      >
        <Divider className="sendback_modal_divider" />

        <ForwardForm
          closeForm={handleCloseForwardModal}
          selectedRecord={selectedRecord}
          form={forwaedForm}
          id={getID}
          setSubmitStageBar={setSubmitStageBar}
          toggleTableRefreshData={toggleRefreshTableData}
          onRefresh={onRefresh}
        />
      </Modal>
      <Modal
        title="Confirm Completion"
        open={confirmModalVisible}
        onOk={handleConfirmOk}
        onCancel={() => {
          setConfirmModalVisible(false);
          form.resetFields();
        }}
        okType="danger"
        okButtonProps={{
          loading,
          className: 'fixed-width-button'
        }}

      >
        <p>{`Are you sure you want to complete the ${selectedStage} stage?`}</p>
        {selectedStage === "QC" && (
          <Form layout="vertical" form={form}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="user" label="Credit Manager"
                  rules={[{ required: true, message: "Please Select" }]}
                >
                  <Select
                    mode="single"
                    placeholder="Please select"
                    required={true}
                    allowClear
                    className="forward_form_select"
                    showSearch
                    filterOption={(input, option) =>
                      option?.children?.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {users?.map((user) => (
                      <Select.Option key={user?.id} value={user?.id}>
                        {user?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
      <Modal
        title="Confirm Cancel"
        open={confirmStatusModalVisible}
        onOk={() => form.submit()}
        onCancel={() => {
          setConfirmStatusModalVisible(false);
          form.resetFields();
        }}
        okType="danger"
      >
        <Form form={form} layout="vertical"
          onFinish={handleConfirmStatusOk}>
          <p>Are you sure you want to cancel the <b>{selectedStage}</b> stage?</p>
          <Form.Item
            style={{ margin: 0 }}
            name="reason"
            label="Remark"
            rules={[
              { required: true, message: "Please enter your reason" },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Enter your reason here"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default LoanApplicationDetails;
