import { axiosRequest } from "../../../../../utils/api/axiosRequest";
export const getPersonalDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/customer-detail/personal-detail/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getPersonaByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/customer-detail/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updatePersonalDetailsByIdService = (loan_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(
        `/api/v1/customer/customer-detail/${loan_id}`,
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updatePersonalDetailsPartnerByIdService = (loan_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        `/api/v1/customer/business-partner-detail/`,
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllCastService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/master/caste-category/list`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getCountryService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/country/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listGenderType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/choices/gender`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const listMaritalStatusType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/choices/marital-status`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const listReligion = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/choices/religion`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listNationality = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/choices/nationality`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listRelation = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/relation/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listEducation = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/choices/eductaion`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listFamilyType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/choices/family-type`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listBusinessConstructionTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/business-constitution-type`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listCustomerType  = (ids) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/customer-type/list?customer_id=${ids}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
 

export const listCustomersalesType  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/customer-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getApplicantListByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `api/v1/customer/customer-detail/customer-list/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getPersonalSalesDetails = (customerid) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const response = await axiosRequest.get(`/api/v1/customer/combine-sales-form/get-all/${customer_detail}`);
      const response = await axiosRequest.get(
        `api/v1/customer/customer-detail/${customerid}?get_inactive=True`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updatePersonalSalesDetails = (customerid, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(
        `api/v1/customer/customer-detail/${customerid}`,
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const createPersonalSalesDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        `api/v1/customer/customer-detail/`,
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listAddressService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/office-type`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const deletePersonalDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/customer-detail/delete-customer_details/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getLoanIdForHyperlink = (lan) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/customer/dedupe/get-hyperlink-detail?loan_account_number=${lan}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};