import { axiosRequest } from "../../../../src/utils/api/axiosRequest";

export const getChatUsers = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/user/get-user-list-by-loan-location?loan_id=${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getAllChatListByLoanId = (loan_id,pageNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/communication/messages/get-message-by-loan-id/${loan_id}?page=${pageNumber}&limit=25`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export function formatISOChatDate(isoString) {
  const date = new Date(isoString);

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const yyyy = date.getFullYear();
  const hh = String(date.getHours()).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");

  return `${dd}-${mm}-${yyyy}:${hh}:${min}`;
}