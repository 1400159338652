import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Space,
  Select,
  message,
  Upload,
  Input,
} from "antd";
import "../styles/documentDetailsForm.css";
import {
  listDocumentTypeService,
  listCategoryTypeService,
  uploadDocumentServiceById,
  editDocumentServiceById,
  getDocumentDataServiceById,
} from "../services/documentDetailsServices";
import * as Yup from "yup";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import pdf_image from "../assets/pdf.png";

const validationSchema = Yup.object().shape({
  document_type: Yup.string()
    .required("Document Type is required!")
    .label("Document Type"),
  document_number: Yup.string()
    .required("Document Number is required!")
    .label("Document Number"),
  document_category: Yup.string()
    .required("Document Category is required")
    .label("Document Category"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const DocumentDetailsForm = ({
  refreshData,
  id,
  open,
  closeForm,
  onNext,
  loan_id,
  customer_id,
  document_id,
}) => {
  const [form] = Form.useForm();
  const [documentType, setDocumentType] = useState([]);
  const [categoryType, setCategoryType] = useState([]);
  const [files, setFiles] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [documentcategory, setDocumentCategory] = useState(null);

  const getDocumentData = async () => {
    if (document_id) {
      try {
        const response = await getDocumentDataServiceById(document_id);
        const documentPath = response?.data?.document_path;

        setFileList(
          Array.isArray(documentPath) ? documentPath : [documentPath]
        );
        setFiles(documentPath);

        form?.setFieldsValue({
          document_number: response?.data?.document_number,
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  useEffect(() => {
    if (open && document_id) {
      getDocumentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, document_id]);

  const handleUpload = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const latestFile = newFileList[0].originFileObj;
      const fileType = latestFile.type;

      if (fileType.startsWith("image/") || fileType === "application/pdf") {
        setFileList([newFileList[0]]);
        setFiles(latestFile);
      } else {
        message.error("Cannot upload files except .pdf and image");
        setFileList([]);
        setFiles(null);
      }
    }
  };

  useEffect(() => {
    const getAllDocumentType = async () => {
      try {
        const response = await listDocumentTypeService(documentcategory);
        setDocumentType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    if (documentcategory) {
      getAllDocumentType();
    }
  }, [documentcategory]);

  const getAllCategoryType = async () => {
    try {
      const response = await listCategoryTypeService();
      setCategoryType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (open) {
      // getAllDocumentType();
      getAllCategoryType();
    } else {
      form.resetFields();
      setFiles(null);
      setFileList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSubmit = async (values) => {
    if (fileList.length === 0) {
      message.error("Please Upload Files");
    } else {
      try {
        const formData = new FormData();

        fileList.forEach((file) => {
          formData.append("document_path", file.originFileObj);
        });

        formData.append("loan_detail", loan_id);
        formData.append("customer_detail", customer_id);

        for (const key in values) {
          formData.append(key, values[key]);
        }

        if (document_id) {
          let response = await editDocumentServiceById(document_id, formData);
          if (response.status === 200 && response.success) {
            message.success("Property Document successfully updated");
            refreshData();
            closeForm();
            setFiles(null);
            setFileList([]);
          }
        } else {
          let response = await uploadDocumentServiceById(formData);
          if (response.status === 201 && response.success) {
            message.success("Property Document successfully added");
            refreshData();
            closeForm();
            setFiles(null);
            setFileList([]);
          }
        }
      } catch (error) {
        message.error(
          ErrorMessage(
            error?.response?.status,
            error?.response?.data?.message
          ) || "An unexpected error occurred."
        );
      }
    }
  };

  const handleDeleteFile = () => {
    setFiles(null);
    setFileList([]);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        document_category: null,
        document_type: null,
      }}
      onFinish={handleSubmit}
    >
      <Row gutter={16}>
        <Col span={document_id ? 12 : 8}>
          <Form.Item
            label="Document Number"
            name="document_number"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Document Number" />
          </Form.Item>
        </Col>

        {!document_id && (
          <Col span={8}>
            <Form.Item
              label="Document Category Type"
              name="document_category"
              rules={[yupSync]}
              required
            >
              <Select
                // mode="single"
                placeholder="Please select"
                required
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) => {
                  setDocumentCategory(value); // Update document category state
                  form.setFieldsValue({ document_type: null }); // Reset document_type to null
                }}
              >
                {categoryType?.map((documenttype) => (
                  <Select.Option
                    key={documenttype?.id}
                    value={documenttype?.id}
                  >
                    {documenttype?.display_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {!document_id && (
          <Col span={8}>
            <Form.Item
              label="Document Type"
              name="document_type"
              rules={[
                { required: true, message: "Please select a document type" },
                yupSync,
              ]} // Simplified validation rules
            >
              <Select
                placeholder="Please select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                disabled={!documentcategory} // Assuming documentcategory is a boolean
              >
                {documentType?.map((documenttype, index) => (
                  <Select.Option key={index} value={documenttype?.id}>
                   
                    {documenttype?.display_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col span={document_id ? 12 : 8} className="file-upload-container">
          <Form.Item>
            <Upload
              single
              // name="avatar"
              // listType="picture-card"
              // className="avatar-uploader"
              fileList={fileList}
              onChange={handleUpload}
              beforeUpload={() => false}
              showUploadList={false}
              rules={[{ required: true, message: "Please upload document" }]}
              disabled={files?.length > 0 || files !== null}
            >
              <Button
                className="avatar-uploader-button"
                type="primary"
                icon={<UploadOutlined />}
                disabled={files?.length > 0 || files !== null}
              >
                Upload File
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      {files && (
        <div className="customer-uploaded-files-container">
          {files instanceof File && files.type.startsWith("image/") ? (
            <img
              src={URL.createObjectURL(files)}
              height={100}
              width={100}
              alt="Document"
              style={{ objectFit: "cover" }}
            />
          ) : files instanceof File && files.type === "application/pdf" ? (
            <a
              href={URL.createObjectURL(files)}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={pdf_image}
                width="100px"
                height="100px"
                alt="PDF Preview"
              />
            </a>
          ) : typeof files === "string" && files.startsWith("http") ? (
            files.toLowerCase().includes(".pdf") ? (
              <a href={files} target="_blank" rel="noreferrer noopener">
                <img
                  src={pdf_image}
                  width="100px"
                  height="100px"
                  alt="PDF Preview"
                />
              </a>
            ) : (
              <img
                src={files}
                height={100}
                width={100}
                alt="Uploaded File Preview"
                style={{ objectFit: "cover" }}
              />
            )
          ) : null}

          <DeleteOutlined
            className="docs-uploaded-container"
            onClick={handleDeleteFile}
          />
        </div>
      )}

      {onNext ? (
        <Space
          direction="horizontal"
          align="center"
          className="customer_tabs_form_buttons"
        >
          <Button type="primary" onClick={onNext}>
            Save And Next
          </Button>
        </Space>
      ) : (
        <Space
          direction="horizontal"
          align="center"
          className="customer_tabs_form_buttons"
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      )}
    </Form>
  );
};

export default DocumentDetailsForm;
