import React, { useEffect, useState } from "react";
import { message } from "antd";
import { Progress } from "antd";
import { Table, Button, Modal } from "antd";
import "../styles/caseHealthSummary.css";
import {
  getBankAccountDetailsByIdService,
  getCustomerDetailsByIdService,
  getkarzaDocumentDetailsByIdService,
  getCaseHealthSummaryDetailsByIdService,
} from "../services/caseHealthService";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import GoogleMapLocation from "../../../../utils/googleMapLocation/GoogleMapLocation";
import { Chart } from "react-google-charts";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import CustomNoData from "../../../../utils/noDataIcon/CustomNoData";
import Loading from "../../../../utils/loading/Loading";
import IndianNumberFormat from "../../../../utils/indianNumberFormat/IndianNumberFormat";
import moment from "moment";
import verify from "../assets/Group.png";
import unverified from "../assets/unverified.png";
import DocumentVerificationTable from "./DocumentVerificationTable";

const CaseHealthSummary = ({ loan_id, open }) => {
  const [bankAccountData, setBankAccountData] = useState(null); 
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]); // Active loans & FOIR in table store
  const [loanDetails, setLoanDetails] = useState([]);
  const [branchLatitude, setBranchLatitude] = useState(); //  Branch Latitude store
  const [branchLongitude, setBranchLongitude] = useState(); //  Branch Longitude store
  const [documentDetails, setDocumentDetails] = useState(); //  karza customer document store
  const [expandedAccountId, setExpandedAccountId] = useState(null);
  const [customerDetailsData, setCustomerDetailsData] = useState([]);
  const [propertyValue, setPropertyValue] = useState({});
  const [applicantBackground, setApplicantBackground] = useState("");
  const [CibilData, setCibilData] = useState([]);
  const [bankDetailsData, setBankDetailsData] = useState([]);
  const [ExistingLoanData, setExistingLoanData] = useState([]);
  const [deviationDetailsData, setDeviationDetailsData] = useState([]);
  const [SanctionData, setSanctionData] = useState([]);
  const [refrenceData, setRefrenceData] = useState([]);
  const [distanceData, setDistanceData] = useState([]);
  const [income, setIncome] = useState(0);
  const [salariedData, setSalariedData] = useState([]);
  const [selfEmployedData, setSelfEmployedData] = useState([]);
  const [proposalData, setProposalData] = useState([]);
  const [rentalData, setRentalData] = useState([]);
  const [pensionerData, setPensionerData] = useState([]);
  const [eligibilityData, setEligibilityData] = useState([]);
  const [strengthData, setStrengthData] = useState([]);
  const [weaknessData, setWeaknessData] = useState([]);
  const [HighmarkData, setHighmarkData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDoc, setCurrentDoc] = useState(null);
  const [customers, setCustomers] = useState([]);

  const handleshowimage = (record) => {
    // Using documentDetails state to find the original customer data
    const customer = documentDetails.find((doc) => doc.key === record.key);

    // Get the original document data from API response stored in state
    const customer_data = customers.find(
      (c) => c.customer_name === customer.customerName
    );
    const document = customer_data?.documents.find(
      (doc) => doc.id === record.key
    );

    if (document?.path) {
      setCurrentDoc(document.path);
      setIsModalOpen(true);
    }
  };


  // Case Health Summary Details Service
  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const { data } = await getCustomerDetailsByIdService(loan_id);
        setCustomerData(data);
        setLoanDetails(data.existing_loan_data);
        const financialTableData = [
          {
            key: "1",
            column1: "Appraised Net Monthly Income",
            column2: `₹ ${data.appraised_monthly_income.toLocaleString(
              "en-IN",
              { minimumFractionDigits: 2 }
            )}`,
          },
          {
            key: "2",
            column1: "FOIR (As Per Policy)",
            column2: `${data.foir}%`,
          },
          {
            key: "3",
            column1: "Max EMI",
            column2: `₹ ${data.max_emi.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
            })}`,
          },
          {
            key: "4",
            column1: "EMI Factor",
            column2: `₹ ${data.emi_factor.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
            })}`,
          },
          {
            key: "5",
            column1: "Eligibility",
            column2: `₹ ${data.eligibility.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
            })}`,
          },
          {
            key: "6",
            column1: "EMI",
            column2: `₹ ${data.emi.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
            })}`,
          },
          {
            key: "7",
            column1: "Actual LTV",
            column2: `${data.actual_ltv}%`,
          },
          {
            key: "8",
            column1: "Actual FOIR",
            column2: data.actual_foir || "N/A",
          },
        ];
        setTableData(financialTableData);
        setBranchLatitude(data.branch_lat);
        setBranchLongitude(data.branch_long);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, open]);

  // Bank Account Details Service
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBankAccountDetailsByIdService(loan_id);
        setBankAccountData(response.data.results);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
  }, [loan_id, open]);

  // Karza Customer Document Details Service
  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const response = await getkarzaDocumentDetailsByIdService(loan_id);
        setCustomers(response.data.customers);
        const { customers } = response.data;
        let documentCounter = 0;
        const transformedData = customers.flatMap((customer) =>
          customer.documents.map((document) => {
            documentCounter++; // Increment counter for each document
            let parsedDate = "-";
            if (document.karza_status?.[0]?.created_at) {
              const dateParts = document.karza_status[0].created_at.split(" ");
              const [day, month, year] = dateParts[0].split("-");
              const time = dateParts[1] + " " + dateParts[2];
              parsedDate = new Date(
                `${month}-${day}-${year} ${time}`
              ).toLocaleString();
            }
            return {
              key: document.id,
              no: documentCounter, // Use the running counter instead of documentIndex + 1
              customerType: customer.customer_type,
              customerName: customer.customer_name,
              facts: document.karza_status?.[0]?.fact || "-",
              status: document.status || "-",
              result: document.karza_status?.[0]?.result || "-",
              verifiedOn: parsedDate,
            };
          })
        );

        setDocumentDetails(transformedData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchDocumentData();
  }, [loan_id, open]);

  //Case Health Summary Data
  useEffect(() => {
    const fetchCaseHealthSummaryData = async () => {
      try {
        setLoading(true);
        const response = await getCaseHealthSummaryDetailsByIdService(loan_id);
        const { data } = response;
        // customer details
        const formattedData = data.customer_details.map((item, index) => ({
          key: item.id,
          sr_no: index + 1,
          customer_type: item.customer_type || "-",
          customer_class: item.customer_class || "-",
          customer_name: item.customer_name || "-",
          relationship_with_applicant: item.relationship_with_applicant || "-",
          current_age: item.current_age || "-",
          maturity_age: item.maturty_age || "-",
          burea_score: item.Burea_score || "-",
        }));
        setCustomerDetailsData(formattedData);
        // property Values
        setPropertyValue(data.property_value);
        // Applicant Background
        setApplicantBackground(data.applicant_background);
        // Cibil Details
        if (data.burea_details && data.burea_details.length > 0) {
          const cibilFormattedData = data.burea_details.map((item, index) => ({
            key: item.customer_id,
            sr_no: index + 1,
            customer_name: item.customer_name || "-",
            score: item.cibil.score || "-",
            vintage: item.cibil.vintage,
            no_of_trade_lines: item.cibil.no_of_trade_lines,
            high_credit_value: item.cibil.high_credit_value,
            total_current_balance_on_live_loan_acc:
              item.cibil.total_current_balance_on_live_loan_acc,
            no_of_active_loans: item.cibil.no_of_active_loans || "-",
            highest_DPD_repost: item.cibil.highest_DPD_repost || "-",
            no_of_inquiries_secured: item.cibil.no_of_inquiries_secured || "-",
            loans_taken_12_24_month: item.cibil.loans_taken_12_24_month,
          }));
          setCibilData(cibilFormattedData);
        }

        if (data.burea_details && data.burea_details.length > 0) {
          const highmarkFormattedData = data.burea_details.map(
            (item, index) => ({
              key: item.customer_id,
              sr_no: index + 1,
              customer_name: item.customer_name || "-",
              score: item.highmark.score || "-",
              vintage: item.highmark.vintage,
              no_of_trade_lines: item.highmark.no_of_trade_lines,
              high_credit_value: item.highmark.high_credit_value,
              total_current_balance_on_live_loan_acc:
                item.highmark.total_current_balance_on_live_loan_acc,
              no_of_active_loans: item.highmark.no_of_active_loans || "-",
              highest_DPD_repost: item.highmark.highest_DPD_repost || "-",
              no_of_inquiries_secured: item.highmark.no_of_inquiries_secured,
              loans_taken_12_24_month: item.highmark.loans_taken_12_24_month,
            })
          );
          setHighmarkData(highmarkFormattedData);
        }

        // Bank Account Details
        const formattedBankDetails = data.banking_details.map(
          (item, index) => ({
            key: index + 1,
            sr_no: index + 1,
            a_c_holder_name: item.account_holder_name?.trim() || "-",
            bank_name: item.bank_name?.trim() || "-",
            a_c_type: item.account_type?.trim() || "-",
            a_c_no: item.account_number?.trim() || "-",
            twelve_month_abb: item.twelve_month_abb?.trim() || "-",
            six_month_abb: item.six_month_abb?.trim() || "-",
            three_month_abb: item.three_month_abb?.trim() || "-",
          })
        );
        setBankDetailsData(formattedBankDetails);

        // Existing Loan Data
        const formattedExistingLoanData = data.existing_loan_details.map(
          (item, index) => ({
            key: index + 1,
            sr_no: index + 1,
            customer_name: item.customer_name || "-",
            bank_or_FI_name: item.bank_or_FI_name || "-",
            product: item.product || "-",
            loan_sancation_amount: item.loan_sancation_amount || "-",
            pos: item.pos || "-",
            open_date: item.open_date || "-",
            repayment_account_name_account_number:
              item.repayment_account_name_account_number || "-",
            emi: item.emi || "-",
            emi_taken_to_obligation: item.emi_taken_to_obligation
              ? "Yes"
              : "No",
            date_of_emi_clearnce_of_last_12_months:
              item.date_of_emi_clearnce_of_last_12_months || "-",
          })
        );
        setExistingLoanData(formattedExistingLoanData);
        // Deviation Data
        const formattedDeviationData = data.deviation_details.map(
          (item, index) => ({
            key: item.loan_id,
            sr_no: index + 1,
            deviation_from_policy: item.deviation_from_policy || "-",
            mitigates: item.mitigates || "-",
          })
        );
        setDeviationDetailsData(formattedDeviationData);
        //Sanction Details
        const sanctionDetails = data.loan_sancation_details.map(
          (item, index) => ({
            key: item.loan_detail,
            sr_no: index + 1,
            sanction_condition: item.sanction_condition || "-",
            sanction_condition_description:
              item.sanction_condition_description || "-",
            status: item.status || "-",
            sanction_condition_type: item.sanction_condition_type || "-",
          })
        );
        setSanctionData(sanctionDetails);
        //Refrence Details
        const referenceDetails = data.reference_data.map((item, index) => ({
          key: index + 1,
          sr_no: index + 1,
          name: item.name || "-",
          mobile_number: item.mobile_number || "-",
        }));
        setRefrenceData(referenceDetails);
        //income
        setIncome(data.business_details.income || 0);
        //Salaried Data
        if (data.business_details.salaried) {
          const employementData = data.business_details.salaried.map(
            (item, index) => ({
              key: index + 1,
              sr_no: index + 1,
              organization_name: item.organization_name || "-",
              year_of_start: item.year_of_start || "-",
              salary_period: item.salary_period || "-",
              net_income: item.net_income || "-",
              nature_of_employment: item.nature_of_employment || "-",
            })
          );
          setSalariedData(employementData);
        }

        // Self Employed data
        if (data.business_details.self_employed) {
          const selfEmployedData = data.business_details.self_employed.map(
            (item, index) => ({
              key: index + 1,
              sr_no: index + 1,
              organization_name: item.organization_name || "-",
              year_of_start: item.year_of_start || "-",
              net_income: item.net_income || "-",
              total_business_experience: item.total_business_experience || "-",
              business_area: item.business_area || "-",
              average_daily_sale: item.average_daily_sale || "-",
              industry_type: item.industry_type || "-",
              number_of_employees: item.number_of_employees || "-",
            })
          );
          setSelfEmployedData(selfEmployedData);
        }

        // Rental data
        if (data.business_details.rental_data) {
          const formattedRentalData = data.business_details.rental_data.map(
            (item, index) => ({
              key: index + 1,
              sr_no: index + 1,
              property_type: item.property_type || "-",
              year_of_start: item.year_of_start || "-",
              net_income: item.net_income || "-",
              date_of_agreement: item.date_of_agreement || "-",
            })
          );
          setRentalData(formattedRentalData);
        }

        // Pensioner data
        if (data.business_details.pensioner_data) {
          const formattedPensionerData =
            data.business_details.pensioner_data.map((item, index) => ({
              key: index + 1,
              sr_no: index + 1,
              organization_name: item.organization_name || "-",
              year_of_start: item.year_of_start || "-",
              net_income: item.net_income || "-",
              total_business_experience: item.total_business_experience || "-",
              business_area: item.business_area || "-",
              average_daily_sale: item.average_daily_sale || "-",
              industry_type: item.industry_type || "-",
              number_of_employees: item.number_of_employees || "-",
            }));
          setPensionerData(formattedPensionerData);
        }

        //proposal Details
        const { proposal_details } = data;
        const formattedProposalData = [];
        proposal_details.existing_loan.forEach((loan, index) => {
          formattedProposalData.push({
            key: `existing_${index + 1}`,
            sr_no: index + 1,
            loan_details: `Existing Loan ${index + 1}`,
            product: loan.product || "-",
            loan_amount: loan.loan_amount || "-",
            loan_pos: loan.loan_pos || "-",
            tenor: loan.tenor || "-",
            roi: loan.roi || "-",
            emi: loan.emi || "-",
            loan_status: loan.loan_status || "-",
            property_value: loan.property_value || "-",
            final_ltv: loan.final_ltv || "-",
            whether_cre_cre: loan.whether_cre_cre || "-",
          });
        });

        //existing Loan Details
        const existingLoansCount = proposal_details.existing_loan.length;
        formattedProposalData.push({
          key: "proposed_loan",
          sr_no: existingLoansCount + 1,
          loan_details: "Proposed Loan",
          product: proposal_details.proposed_loan.product || "-",
          loan_amount: proposal_details.proposed_loan.loan_ammount || "-",
          loan_pos: proposal_details.proposed_loan.loan_pos || "-",
          tenor: proposal_details.proposed_loan.tenor || "-",
          roi: proposal_details.proposed_loan.roi || "-",
          emi: proposal_details.proposed_loan.emi || "-",
          loan_status: proposal_details.proposed_loan.loan_status || "-",
          property_value: proposal_details.proposed_loan.property_value || "-",
          final_ltv: proposal_details.proposed_loan.final_ltv || "-",
          whether_cre_cre:
            proposal_details.proposed_loan.whether_cre_cre || "-",
        });
        // Add combine exposure
        formattedProposalData.push({
          key: "combine_exposure",
          sr_no: existingLoansCount + 2,
          loan_details: "Combine Exposure",
          product: proposal_details.combine_exposure.product || "-",
          loan_amount: proposal_details.combine_exposure.loan_ammount || "-",
          loan_pos: proposal_details.combine_exposure.loan_pos || "-",
          tenor: proposal_details.combine_exposure.tenor || "-",
          roi: proposal_details.combine_exposure.roi || "-",
          emi: proposal_details.combine_exposure.emi || "-",
          loan_status: proposal_details.combine_exposure.loan_status || "-",
          property_value:
            proposal_details.combine_exposure.property_value || "-",
          final_ltv: proposal_details.combine_exposure.final_ltv || "-",
          whether_cre_cre:
            proposal_details.combine_exposure.whether_cre_cre || "-",
        });
        setProposalData(formattedProposalData);

        //Eligibility Details
        const { eligibility_details } = response.data;
        const eligibilityTableData = [
          {
            key: "1",
            column1: "Loan Amount Required",
            column2: eligibility_details.loan_amount_required
              ? `₹ ${IndianNumberFormat(
                  eligibility_details.loan_amount_required.toLocaleString(
                    "en-IN",
                    { minimumFractionDigits: 2 }
                  )
                )}`
              : "-",
          },
          {
            key: "2",
            column1: "Appraised Obligations",
            column2: eligibility_details.apprised_obligations
              ? `₹ ${eligibility_details.apprised_obligations.toLocaleString(
                  "en-IN",
                  { minimumFractionDigits: 2 }
                )}`
              : "-",
          },
          {
            key: "3",
            column1: "Tenor Applied",
            column2: eligibility_details.tenor_applied || "-",
          },
          {
            key: "4",
            column1: "Rate of Interest",
            column2: eligibility_details.rate_of_intrest
              ? `${eligibility_details.rate_of_intrest}%`
              : "-",
          },
          {
            key: "5",
            column1: "Value of the Property",
            column2: eligibility_details.value_of_the_property
              ? `₹ ${eligibility_details.value_of_the_property.toLocaleString(
                  "en-IN",
                  { minimumFractionDigits: 2 }
                )}`
              : "-",
          },
          {
            key: "6",
            column1: "Total Incomes",
            column2: eligibility_details.total_incomes
              ? `₹ ${eligibility_details.total_incomes.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                })}`
              : "-",
          },
          {
            key: "7",
            column1: "Appraised Monthly Incomes",
            column2: eligibility_details.apprised_monthly_incomes
              ? `₹ ${eligibility_details.apprised_monthly_incomes.toLocaleString(
                  "en-IN",
                  { minimumFractionDigits: 2 }
                )}`
              : "-",
          },
          {
            key: "8",
            column1: "FOIR",
            column2: eligibility_details.fori || "-",
          },
          {
            key: "9",
            column1: "Max EMI",
            column2: eligibility_details.max_emi
              ? `₹ ${eligibility_details.max_emi.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                })}`
              : "-",
          },
          {
            key: "10",
            column1: "EMI Factor",
            column2: eligibility_details.emi_factor
              ? `₹ ${eligibility_details.emi_factor.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                })}`
              : "-",
          },
          {
            key: "11",
            column1: "Eligibility",
            column2: eligibility_details.eligibility
              ? `₹ ${eligibility_details.eligibility.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                })}`
              : "-",
          },
          {
            key: "12",
            column1: "EMI",
            column2: eligibility_details.emi
              ? `₹ ${eligibility_details.emi.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                })}`
              : "-",
          },
          {
            key: "13",
            column1: "Actual FOIR",
            column2: eligibility_details.actual_foir || "-",
          },
          {
            key: "14",
            column1: "Actual LTV",
            column2: eligibility_details.actual_ltv
              ? `${eligibility_details.actual_ltv}%`
              : "-",
          },
        ];
        setEligibilityData(eligibilityTableData);

        // Geography Details
        const { distance_detail } = response.data;
        const distanceTableData = [
          {
            key: "1",
            column1: "From Branch to Collateral",
            column2: `${distance_detail.from_branch_to_collateral ?? "-"}`,
          },
          {
            key: "2",
            column1: "From Branch to Business Premises",
            column2: `${
              distance_detail.from_branch_to_business_premises ?? "-"
            } `,
          },
          {
            key: "3",
            column1: "From Branch to Current Premises",
            column2: `${
              distance_detail.from_branch_to_current_premises ?? "-"
            } `,
          },
          {
            key: "4",
            column1: "From Current Residence to Collateral",
            column2: `${
              distance_detail.from_current_residence_to_collateral ?? "-"
            }`,
          },
          {
            key: "5",
            column1: "From Current Business Premises to Collateral",
            column2: `${
              distance_detail.from_current_business_premises_to_collateral ??
              "-"
            }`,
          },
        ];
        setDistanceData(distanceTableData);

        //Strength and Weakness
        const formattedStrengthData = data.strength_weakness_details
          .filter((item) => item.key_type === "Strength")
          .map((item, index) => ({
            key: index + 1,
            sr_no: index + 1,
            description: item.description || "-",
          }));

        const formattedWeaknessData = data.strength_weakness_details
          .filter((item) => item.key_type === "Weakness")
          .map((item, index) => ({
            key: index + 1,
            sr_no: index + 1,
            description: item.description || "-",
          }));

        setStrengthData(formattedStrengthData);
        setWeaknessData(formattedWeaknessData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchCaseHealthSummaryData();
    // eslint-disable-next-line
  }, [loan_id, open]);

  // Karza customer documnet table columns
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 80,
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      title: "Facts",
      dataIndex: "facts",
      key: "facts",
      width: 200,
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      width: 120,
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
      width: 120,
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Verified on",
      dataIndex: "verifiedOn",
      key: "verifiedOn",
      width: 150,
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "View",
      key: "view",
      render: (_, record) => (
        <Button type="link" onClick={() => handleshowimage(record)}>
          View
        </Button>
      ),
      width: 100,
    },
  ];

  // Active loans & FOIR in table columns
  const columns1 = [
    {
      dataIndex: "column1",
      key: "column1",
      width: 300,
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      dataIndex: "column2",
      key: "column2",
      width: 280,
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  // Customer Details Colums
  const CustomerColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      title: "Customer Type",
      dataIndex: "customer_type",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Customer Class",
      dataIndex: "customer_class",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Relationship With Applicant",
      dataIndex: "relationship_with_applicant",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Current Age",
      dataIndex: "current_age",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Maturity Age",
      dataIndex: "maturity_age",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Bureau Score",
      dataIndex: "burea_score",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  //  Proposal Details
  const proposalColumns = [
    // {
    //     title: 'Sr No',
    //     dataIndex: 'sr_no',
    //     key: 'sr_no',
    //     render: (text) => <span className='tables_text'>{text}</span>,
    // },
    {
      title: <span className="custom-category-header">Loan Details</span>,
      dataIndex: "loan_details",
      key: "loan_details",
      fixed: "left",
      render: (text) => <span className="custom-category-header ">{text}</span>,
    },

    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Loan Amount",
      dataIndex: "loan_amount",
      key: "loan_amount",
      render: (text) => (
        <span className="tables_text">₹ {IndianNumberFormat(text)}</span>
      ),
    },
    {
      title: "POS",
      dataIndex: "loan_pos",
      key: "loan_pos",
      render: (text) => (
        <span className="tables_text">₹ {IndianNumberFormat(text)}</span>
      ),
    },
    {
      title: "Tenor",
      dataIndex: "tenor",
      key: "tenor",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "ROI",
      dataIndex: "roi",
      key: "roi",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "EMI",
      dataIndex: "emi",
      key: "emi",
      render: (text) => (
        <span className="tables_text"> ₹ {IndianNumberFormat(text)}</span>
      ),
    },
    {
      title: "Loan Status",
      dataIndex: "loan_status",
      key: "loan_status",
      render: (text) => <span className="tables_text">{text}</span>,
    },

    {
      title: "Property Value",
      dataIndex: "property_value",
      key: "property_value",
      render: (text) => (
        <span className="tables_text">₹ {IndianNumberFormat(text)}</span>
      ),
    },
    {
      title: "Final LTV",
      dataIndex: "final_ltv",
      key: "final_ltv",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Whether CRE/CRE",
      dataIndex: "whether_cre_cre",
      key: "whether_cre_cre",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  // Salaried Colums
  const salariedColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      title: "Organization",
      dataIndex: "organization_name",
      key: "organization_name",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Year of Start",
      dataIndex: "year_of_start",
      key: "year_of_start",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Salaried Period",
      dataIndex: "salary_period",
      key: "salary_period",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Net Income",
      dataIndex: "net_income",
      key: "net_income",
      render: (text) => (
        <span className="tables_text">₹ {IndianNumberFormat(text)}</span>
      ),
    },
    {
      title: "Nature of Employment",
      dataIndex: "nature_of_employment",
      key: "nature_of_employment",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  // selfEmployedColumns
  const selfEmployedColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      title: "Organization",
      dataIndex: "organization_name",
      key: "organization_name",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Year of Start",
      dataIndex: "year_of_start",
      key: "year_of_start",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Net Income",
      dataIndex: "net_income",
      key: "net_income",
      render: (text) => (
        <span className="tables_text"> ₹ {IndianNumberFormat(text)}</span>
      ),
    },
    {
      title: "Total Business Experience",
      dataIndex: "total_business_experience",
      key: "total_business_experience",
      render: (text) => (
        <span className="tables_text"> ₹ {IndianNumberFormat(text)}</span>
      ),
    },
    {
      title: "Business Area",
      dataIndex: "business_area",
      key: "business_area",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Average Daily Sale",
      dataIndex: "average_daily_sale",
      key: "average_daily_sale",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Industry Type",
      dataIndex: "industry_type",
      key: "industry_type",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Number Of Employees",
      dataIndex: "number_of_employees",
      key: "number_of_employees",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  //RentalColumns
  const RentalColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      title: "Property Type",
      dataIndex: "property_type",
      key: "property_type",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Year of Start",
      dataIndex: "year_of_start",
      key: "year_of_start",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Net Income",
      dataIndex: "net_income",
      key: "net_income",
      render: (text) => (
        <span className="tables_text">₹ {IndianNumberFormat(text)}</span>
      ),
    },

    {
      title: "Date Of Agreement",
      dataIndex: "date_of_agreement",
      key: "date_of_agreement",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  //PensionerColumns
  const PensionerColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      title: "Organization",
      dataIndex: "organization_name",
      key: "organization_name",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Year of Start",
      dataIndex: "year_of_start",
      key: "year_of_start",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Net Income",
      dataIndex: "net_income",
      key: "net_income",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Total Business Experience",
      dataIndex: "total_business_experience",
      key: "total_business_experience",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Business Area",
      dataIndex: "business_area",
      key: "business_area",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Average Daily Sale",
      dataIndex: "average_daily_sale",
      key: "average_daily_sale",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Industry Type",
      dataIndex: "industry_type",
      key: "industry_type",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Number Of Employees",
      dataIndex: "number_of_employees",
      key: "number_of_employees",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  // Bureau Details
  const CibilColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Score",
      dataIndex: "score",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Vintage",
      dataIndex: "vintage",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "No Of Trade Lines",
      dataIndex: "no_of_trade_lines",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "High Credit Value",
      dataIndex: "high_credit_value",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Total Current Balance",
      dataIndex: "total_current_balance_on_live_loan_acc",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "No Of Active Loans",
      dataIndex: "no_of_active_loans",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Highest DPD Repost",
      dataIndex: "highest_DPD_repost",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "No Of Inquiries Secured",
      dataIndex: "no_of_inquiries_secured",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Loans Taken Month",
      dataIndex: "loans_taken_12_24_month",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  const HighmarkColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Score",
      dataIndex: "score",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Vintage",
      dataIndex: "vintage",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "No Of Trade Lines",
      dataIndex: "no_of_trade_lines",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "High Credit Value",
      dataIndex: "high_credit_value",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Total Current Balance",
      dataIndex: "total_current_balance_on_live_loan_acc",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "No Of Active Loans",
      dataIndex: "no_of_active_loans",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Highest DPD Repost",
      dataIndex: "highest_DPD_repost",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "No Of Inquiries Secured",
      dataIndex: "no_of_inquiries_secured",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Loans Taken Month",
      dataIndex: "loans_taken_12_24_month",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  // Bank Account Details
  const BankDetailsColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      title: "Account Holder Name",
      dataIndex: "a_c_holder_name",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Account Type",
      dataIndex: "a_c_type",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Account Number",
      dataIndex: "a_c_no",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "12 Month ABB",
      dataIndex: "twelve_month_abb",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "6 Month ABB",
      dataIndex: "six_month_abb",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "3 Month ABB",
      dataIndex: "three_month_abb",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  // Existing Loan Details
  const ExistingLoanColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Bank Name",
      dataIndex: "bank_or_FI_name",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Product",
      dataIndex: "product",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Loan Sanction Amount",
      dataIndex: "loan_sancation_amount",
      render: (text) => (
        <span className="tables_text">₹ {IndianNumberFormat(text)}</span>
      ),
    },
    {
      title: "POS",
      dataIndex: "pos",
      render: (text) => (
        <span className="tables_text">₹ {IndianNumberFormat(text)}</span>
      ),
    },
    {
      title: "Open Date",
      dataIndex: "open_date",
      // render: (text) => <span className='tables_text'>{text}</span>,

      render: (text) => (
        <span className="tables_text">{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Repayment Account Name",
      dataIndex: "repayment_account_name_account_number",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "EMI",
      dataIndex: "emi",
      render: (text) => (
        <span className="tables_text">₹ {IndianNumberFormat(text)}</span>
      ),
    },
    {
      title: "EMI Taken To Obligation",
      dataIndex: "emi_taken_to_obligation",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Date Of EMI Clearance",
      dataIndex: "date_of_emi_clearnce_of_last_12_months",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  //EligibilityDetailsColumns
  const EligibilityDetailsColumns = [
    {
      title: "Eligibility Criteria",
      dataIndex: "column1",
      key: "column1",
      width: 250,
    },
    {
      title: "Value",
      dataIndex: "column2",
      key: "column2",
      className: "custom-column",
    },
  ];

  // Deviation Details
  const DeviationColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      title: "Deviation From Policy",
      dataIndex: "deviation_from_policy",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Mitigates",
      dataIndex: "mitigates",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  // Loan Sanction Details
  const SanctionColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      render: (text) => (
        <span className="tables_text sanction_data">{text}</span>
      ),
      fixed: "left",
      width: 100,
    },
    {
      title: "Sanction Condition",
      dataIndex: "sanction_condition",
      render: (text) => (
        <span className="tables_text sanction_data">{text}</span>
      ),
      width: 300,
    },
    {
      title: "Sanction Condition Description",
      dataIndex: "sanction_condition_description",
      render: (text) => (
        <span className="tables_text sanction_data">{text}</span>
      ),
      width: 300,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span className="tables_text sanction_data">{text}</span>
      ),
      width: 300,
    },
    {
      title: "Sanction Condition Type",
      dataIndex: "sanction_condition_type",
      render: (text) => (
        <span className="tables_text sanction_data">{text}</span>
      ),
      width: 300,
    },
  ];

  // Refrence Details
  const RefrenceColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => <span className="tables_text">{text}</span>,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  // GeoghraphyColumns
  const GeoghraphyColumns = [
    {
      title: "Distance Criteria",
      dataIndex: "column1",
      key: "column1",
      width: 280,
    },
    {
      title: "Distance (km)",
      dataIndex: "column2",
      key: "column2",
      className: "custom-column",
    },
  ];

  // StrengthColumns
  const StrengthColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
      width: 50,
    },
    {
      title: "Strength",
      dataIndex: "description",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  //WeaknessColumns
  const WeaknessColumns = [
    {
      title: "Sr No",
      dataIndex: "sr_no",
      render: (text) => <span className="tables_text">{text}</span>,
      fixed: "left",
      width: 50,
    },
    {
      title: "Weakness",
      dataIndex: "description",
      render: (text) => <span className="tables_text">{text}</span>,
    },
  ];

  //Bank details in table Arrow button and Added button Fuctionality
  const handleArrowClick = (accountId) => {
    if (expandedAccountId === accountId) {
      setExpandedAccountId(null);
    } else {
      setExpandedAccountId(accountId);
    }
  };

  // CanvasJSChart pieChart code
  const data = [
    ["Customer Type", "Percentage"],
    ["Applicant", 5],
    ["Co-Applicant", 31],
    ["Co-Applicant-2", 40],
    ["Co-Applicant-3", 17],
    ["Personal Guarantor", 7],
  ];

  const options = {
    title: "Customer Satisfaction",
    pieHole: 0.4,
    is3D: false,
    pieSliceText: "percentage",
    legend: { position: "right" },
  };

  return (
    <div className="case_health_summary_main_container">
      {/* <div className='case_Health_Summary_decision_summary'>
                <div className='decision_summary'>
                    <div className='summary'>
                        <div><h1 className='summary_heading'>Decision Summary</h1></div>
                        <div>
                            <h3 className='credit_heading'>Overall Creditworthiness</h3>
                            <div className='colourbar'>
                                <div className='red_color'></div>
                                <div className='orange_color'></div>
                                <div className='yellow_color'></div>
                                <div className='green_color'><p className='text_confident'>Confident</p></div>
                                <div className='light_green_color'></div>
                            </div>
                        </div>
                    </div>


                    <div className='summary'>
                        <p className='approve_decision'>Final Approver’s Decision</p>
                        <h3 className='button_refer'>Refer</h3>
                        <div className='logo'>
                            <div className='image_avtar'><img src={avtar} alt="" /></div>
                            <div><h3 className='decision_mekar'>Decision Maker:</h3><h3 className='name'> Adarsh Shah</h3>
                            </div>
                        </div>
                        <div>
                            <p className='date'>Date: 10 May 2024   12:39 PM</p>
                        </div>
                    </div>

                    <div className='summary'>
                        <p className='approve_decision'>Final Approver’s Decision</p>
                        <h3 className='button_refer'>Refer</h3>
                        <div className='logo'>
                            <div className='image_avtar'><img src={avtar} alt="" /></div>
                            <div><h3 className='decision_mekar'>Decision Maker:</h3><h3 className='name'> Adarsh Shah</h3>
                            </div>
                        </div>
                        <div>
                            <p className='date'>Date: 10 May 2024   12:39 PM</p>
                        </div>
                    </div>
                    <div className='approved_image'><img src={approved} alt="" /></div>
                </div>
            </div> */}

      <div className="customer_container">
        <div className="customer_info_div">
          <div className="tables_container_headings">
            <h1 className="tables_container_headings_content"> Customers</h1>
          </div>
          <div>
            <div className="customer_details">
              <div className="customer_box"></div>
              <div className="details_info">
                <h3 className="customer_details_content">
                  No. of Applicants Added
                </h3>
              </div>
              <div className="number">
                {customerData?.total_number_of_customer || 0}
              </div>
            </div>
          </div>

          <div className="customer_details">
            <div className="customer_box"></div>
            <div className="details_info">
              <h3 className="customer_details_content">
                No. of Women Applicants
              </h3>
            </div>
            <div className="applicants-number">
              {customerData?.number_of_women_applicants || 0}
            </div>
          </div>

          <div className="customer_details">
            <div className="customer_box"></div>
            <div className="details_info">
              <h3 className="customer_details_content">
                Average Bureau Scores of All Customers
              </h3>
            </div>
            <div className="bureau-number">
              {customerData?.avg_cibil_score || 0}
            </div>
          </div>

          <div className="customer_details">
            <div className="customer_box"></div>
            <div className="details_info">
              <h3 className="customer_details_content">
                No. of References Added
              </h3>
            </div>
            <div className="number">
              {customerData?.number_of_references_added || 0}
            </div>
          </div>
        </div>

        <div className="customer_info_div">
          {customerData ? (
            <>
              <div className="location_container">
                {branchLatitude !== null && branchLongitude !== null && (
                  <GoogleMapLocation
                    className="location"
                    latitude={customerData?.customer_lat}
                    longitude={customerData?.customer_long}
                    branchLatitude={branchLatitude}
                    branchLongitude={branchLongitude}
                  />
                )}
              </div>

              <div className="location_details">
                <div>
                  <p className="fsv_sale_value">
                    Loan Amount
                  </p>
                  <h3 className="amount">
                    ₹{" "}
                    {customerData.requested_amount?.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    }) || "0.00"}
                  </h3>
                </div>
                <div>
                  <p className="loan_sale_value">Property Valuation</p>
                  <h3 className="loan_amount">
                    ₹{" "}
                    {customerData?.technical_valuation_of_properrty?.toLocaleString(
                      "en-IN",
                      { minimumFractionDigits: 2 }
                    ) || "0.00"}
                  </h3>
                </div>
              </div>

              <div className="percentage">
                <p className="ltv_sale_value">LTV Calculated</p>
                <h1 className="num">
                  {customerData?.ltv ? `${customerData?.ltv}%` : "N/A"}
                </h1>
              </div>
            </>
          ) : (
            <>
              <div className="map_div ">
                <GoogleMapLocation
                  className="location"
                  latitude={19.9975}
                  longitude={73.7898}
                  branchLatitude={branchLatitude}
                  branchLongitude={branchLongitude}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="account_main_container">
        <div className="pichart">
          <h1 className="piechart_ammount">₹ 5,192,00.00</h1>
          <p className="piechart_details">Total Income of All Customers</p>
          <hr className="account_section_divider" />

          <div className="chart">
            <Chart
              chartType="PieChart"
              data={data}
              options={options}
              width={"100%"}
              height={"400px"}
            />
          </div>
        </div>

        <div className="bank_account_details">
          <div className="tables_container_headings">
            <h1 className="tables_container_headings_content">Bank Accounts</h1>
          </div>
          <div className="bank_main_container">
            {loading ? (
              <Loading loading={loading} />
            ) : bankAccountData && bankAccountData.length > 0 ? (
              bankAccountData.map((customer) =>
                customer.customer_bank_details &&
                customer.customer_bank_details.length > 0 ? (
                  <div key={customer.customer_id} className="customer_section">
                    {customer.customer_bank_details.map((bankDetail) => (
                      <div key={bankDetail.id} className="bank_detail_section">
                        <div className="bank_details_details">
                          <div className="bank_customer_details">
                            <div className="bank_detail_summary">
                              <h3 className="customer_name">
                                {bankDetail.account_holder_name || "-"}
                              </h3>
                              <h3 className="name_saving">
                                {bankDetail.account_number
                                  ? `Savings account ${bankDetail.account_number}`
                                  : "-"}
                              </h3>
                            </div>
                          </div>
                          <div className="account_div_one">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={
                                  bankDetail.is_verified ? verify : unverified
                                }
                                alt="verification status"
                                className="verify_img_case_health"
                              />
                            </div>
                            <div
                              className="arrow"
                              onClick={() => handleArrowClick(bankDetail.id)}
                            >
                              {expandedAccountId === bankDetail.id ? (
                                <AiOutlineUp />
                              ) : (
                                <AiOutlineDown />
                              )}
                            </div>
                          </div>
                        </div>

                        {expandedAccountId === bankDetail.id && (
                          <div className="bank_details_details_content">
                            <div className="bank_person_details">
                              <div className="bank_information">
                                <div className="account_card">
                                  <div className="bank_label">Bank</div>
                                  <div className="data">
                                    {bankDetail.bank?.display_name || "-"}
                                  </div>
                                </div>
                                <div className="account_card">
                                  <div className="bank_label">Bank Branch</div>
                                  <div className="data">
                                    {bankDetail.bank_branch?.display_name ||
                                      "-"}
                                  </div>
                                </div>
                                <div className="account_card">
                                  <div className="bank_label">Account Type</div>
                                  <div className="data">
                                    {bankDetail.account_type || "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="bank_information">
                                <div className="account_card">
                                  <div className="bank_label">IFSC Code</div>
                                  <div className="data">
                                    {bankDetail.ifsc_code || "-"}
                                  </div>
                                </div>
                                <div className="account_card">
                                  <div className="micr-label">MICR Code</div>
                                  <div className="data">
                                    {bankDetail.micr_code || "-"}
                                  </div>
                                </div>
                                <div className="account_card">
                                  <div className="bank_label"></div>
                                  <div className="data"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : null
              )
            ) : (
              <div className="no_data_found_message">
                <CustomNoData />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="table_chart">
        <div className="tables_container_headings">
          <h1 className="tables_container_headings_content">
            Asset / Document / Fraud Verifications
          </h1>
        </div>
        <DocumentVerificationTable loan_id={loan_id} open={open}/>
        <div className="customer_table">
          <Table
            dataSource={documentDetails}
            columns={columns}
            loading={loading}
            rowKey="sr_no"
            pagination={false}
          />

          <Modal
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            title="Document Viewer"
            footer={null}
            width={550}
          >
            {currentDoc &&
              (currentDoc.toLowerCase().endsWith(".pdf") ? (
                <iframe
                  src={currentDoc}
                  style={{ width: "100%", height: "80vh" }}
                  title="PDF Viewer"
                />
              ) : (
                <img
                  src={currentDoc}
                  alt="Document"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "80vh",
                    display: "block",
                    objectFit: "contain",
                  }}
                />
              ))}
          </Modal>
        </div>
      </div>

      {/* Customer Details */}
      <div className="case_health_main_tables_container">
        <div className="tables_container_headings">
          <h1 className="tables_container_headings_content">
            Customer Details
          </h1>
        </div>
        <div className="customer_table">
          <Table
            columns={CustomerColumns}
            dataSource={customerDetailsData}
            rowKey="sr_no"
            pagination={false}
          />
        </div>
      </div>

      {/* proposal_details */}
      <div className="case_health_main_tables_container">
        <div className="tables_container_headings">
          <h1 className="tables_container_headings_content">
            Proposal Details
          </h1>
        </div>
        <div className="customer_table">
          <Table
            dataSource={proposalData}
            columns={proposalColumns}
            rowKey="sr_no"
            pagination={false}
            className="proposal_details_table"
          />
        </div>
      </div>

      {/* Property Values */}
      <div className="case_health_summary_tables_block_container">
        <div className="property_background_container">
          <div className="tables_container_headings">
            <h1 className="tables_container_headings_content">
              Property Value
            </h1>
          </div>
          <div className="property_background_contenet">
            {loading ? (
              <Loading loading={loading} />
            ) : propertyValue && propertyValue.area ? (
              <>
                <div className="property_area_block">
                  <div> Area :- </div>
                  <div className="property_area_text ">
                    {propertyValue.area || "-"}
                  </div>
                </div>
              </>
            ) : (
              <div className="no_data_found_message">
                <CustomNoData />
              </div>
            )}
          </div>
        </div>

        {/* //Applicant Background */}
        <div className="property_background_container ">
          <div className="tables_container_headings">
            <h1 className="tables_container_headings_content">
              Applicant Background
            </h1>
          </div>
          <div className="property_background_contenet">
            {loading ? (
              <Loading loading={loading} />
            ) : applicantBackground && applicantBackground.length > 0 ? ( // Check if loanDetails has data
              <>
                {applicantBackground
                  ? applicantBackground.split("\n").map((line, index) => (
                      <div
                        className="tables_text application_background_text"
                        key={index}
                      >
                        {line}
                      </div>
                    ))
                  : "-"}
              </>
            ) : (
              <div className="no_data_found_message">
                <CustomNoData />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* //Deviation Details */}
      <div className="case_health_summary_tables_block_container">
        <div className="applicant_bacnkground_container">
          <div className="tables_container_headings">
            <h1 className="tables_container_headings_content">
              Deviation Details
            </h1>
          </div>
          <div className="customer_table">
            <Table
              columns={DeviationColumns}
              dataSource={deviationDetailsData}
              rowKey="sr_no"
              pagination={false}
            />
          </div>
        </div>

        {/* //Refrence Details */}
        <div className="applicant_bacnkground_container">
          <div className="tables_container_headings">
            <h1 className="tables_container_headings_content">
              Reference Details
            </h1>
          </div>
          <div className="customer_table">
            <Table
              columns={RefrenceColumns}
              dataSource={refrenceData}
              rowKey="sr_no"
              pagination={false}
            />
          </div>
        </div>
      </div>

      {/* //Eligibility Details */}
      <div className="case_health_summary_tables_block_container">
        <div className="eligibility_main_container">
          <div className="tables_container_headings">
            <h1 className="tables_container_headings_content">
              Eligibility Details
            </h1>
          </div>
          <div className="eligibility_calculation">
            <div className="calculation_sheet">Calculation Sheet</div>
            <div className="calculation_tables_sheet_table">
              <Table
                dataSource={eligibilityData}
                columns={EligibilityDetailsColumns}
                pagination={false}
                showHeader={false}
                loading={loading}
                rowKey="sr_no"
                className="eligibility_table"
              />
            </div>
          </div>
        </div>

        {/* //Geoghraphy Details */}
        <div className="eligibility_main_container">
          <div className="tables_container_headings">
            <h1 className="tables_container_headings_content">
              Geography Details
            </h1>
          </div>
          <div className="eligibility_calculation">
            <div className="calculation_tables_sheet_table">
              <Table
                dataSource={distanceData}
                columns={GeoghraphyColumns}
                pagination={false}
                showHeader={false}
                loading={loading}
                rowKey="sr_no"
                className="eligibility_table"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Employee Details */}
      <div className="case_health_main_tables_container">
        <div className="tables_container_headings">
          <h1 className="tables_container_headings_content">
            Business Details
          </h1>
        </div>
        {loading ? (
          <Loading loading={loading} />
        ) : salariedData.length > 0 ||
          selfEmployedData.length > 0 ||
          rentalData.length > 0 ||
          pensionerData.length > 0 ? (
          <>
            <div className="property_value_contenet">
              <div className="case_health_summary_lables table_sub_heading">
                Income :-
              </div>
              <div className="case_health_summary_lables table_sub_heading_text">
                ₹ {IndianNumberFormat(income)}
              </div>
            </div>

            <div>
              {salariedData.length > 0 && (
                <>
                  <div className="busness_details_div cibil_container">
                    <div className="tables_container_headings">
                      <h1 className="tables_container_headings_content ">
                        Salaried
                      </h1>
                    </div>
                    <div className="customer_table">
                      <Table
                        columns={salariedColumns}
                        dataSource={salariedData}
                        rowKey="sr_no"
                        pagination={false}
                      />
                    </div>
                  </div>
                </>
              )}

              {selfEmployedData.length > 0 && (
                <>
                  <div className="busness_details_div cibil_container">
                    <div className="tables_container_headings">
                      <h1 className="tables_container_headings_content">
                        Self Employed
                      </h1>
                    </div>
                    <div className="customer_table">
                      <Table
                        columns={selfEmployedColumns}
                        dataSource={selfEmployedData}
                        rowKey="sr_no"
                        pagination={false}
                      />
                    </div>
                  </div>
                </>
              )}

              {rentalData.length > 0 && (
                <>
                  <div className="busness_details_div cibil_container">
                    <div className="tables_container_headings">
                      <h1 className="tables_container_headings_content">
                        Rental
                      </h1>
                    </div>
                    <div className="customer_table">
                      <Table
                        columns={RentalColumns}
                        dataSource={rentalData}
                        rowKey="sr_no"
                        pagination={false}
                      />
                    </div>
                  </div>
                </>
              )}

              {pensionerData.length > 0 && (
                <>
                  <div className="busness_details_div cibil_container">
                    <div className="tables_container_headings">
                      <h1 className="tables_container_headings_content">
                        Pensioner
                      </h1>
                    </div>

                    <div className="customer_table">
                      <Table
                        columns={PensionerColumns}
                        dataSource={pensionerData}
                        rowKey="sr_no"
                        pagination={false}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="no_data_found_message">
            <CustomNoData />
          </div>
        )}
      </div>
      <div className="case_health_main_tables_container">
        <div className="tables_container_headings">
          <h1 className="tables_container_headings_content">Bureau Details</h1>
        </div>

        {/* Conditionally render Cibil table */}
        {loading ? (
          <Loading loading={loading} />
        ) : CibilData.length > 0 ||
          HighmarkData.length > 0 ||
          rentalData.length > 0 ? (
          <>
            {CibilData.length > 0 && (
              <div
                className="busness_details_div cibil_container"
                style={{ margin: "1rem" }}
              >
                <div className="tables_container_headings">
                  <h1 className="tables_container_headings_content">Cibil</h1>
                </div>
                <div className="customer_table">
                  <Table
                    columns={CibilColumns}
                    dataSource={CibilData}
                    rowKey="sr_no"
                    pagination={false}
                  />
                </div>
              </div>
            )}

            {/* Conditionally render Highmark table */}
            {HighmarkData.length > 0 && (
              <div
                className="busness_details_div cibil_container"
                style={{ margin: "1rem" }}
              >
                <div className="tables_container_headings">
                  <h1 className="tables_container_headings_content">
                    Highmark
                  </h1>
                </div>
                <div className="customer_table">
                  <Table
                    columns={HighmarkColumns}
                    dataSource={HighmarkData}
                    rowKey="sr_no"
                    pagination={false}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="no_data_found_message">
            <CustomNoData />
          </div>
        )}
      </div>

      {/* Bank Details */}
      <div className="case_health_main_tables_container">
        <div className="tables_container_headings">
          <h1 className="tables_container_headings_content">Bank Details</h1>
        </div>
        <div className="customer_table">
          <Table
            columns={BankDetailsColumns}
            dataSource={bankDetailsData}
            rowKey="sr_no"
            pagination={false}
          />
        </div>
      </div>

      {/* Existing Loan Details */}
      <div className="case_health_main_tables_container">
        <div className="tables_container_headings">
          <h1 className="tables_container_headings_content">
            Existing Loan Details
          </h1>
        </div>
        <div className="customer_table">
          <Table
            columns={ExistingLoanColumns}
            dataSource={ExistingLoanData}
            rowKey="sr_no"
            pagination={false}
          />
        </div>
      </div>

      {/* eligibility_details */}
      <div className="case_health_main_tables_container">
        <div className="tables_container_headings">
          <h1 className="tables_container_headings_content">
            Loan Sanction Details
          </h1>
        </div>
        <div className="customer_table">
          <Table
            columns={SanctionColumns}
            dataSource={SanctionData}
            rowKey="sr_no"
            pagination={false}
          />
        </div>
      </div>

      <div className="case_health_main_tables_container">
        <div className="tables_container_headings">
          <h1 className="tables_container_headings_content">
            Strength And Weakness Details
          </h1>
        </div>
        <div className="strength_weekness_container">
          <Table
            columns={StrengthColumns}
            dataSource={strengthData}
            rowKey="sr_no"
            pagination={false}
          />
          <Table
            columns={WeaknessColumns}
            dataSource={weaknessData}
            rowKey="sr_no"
            pagination={false}
          />
        </div>
      </div>

      <div className="active_loan">
        <div className="tables_container_headings">
          <h1 className="tables_container_headings_content">
            Active Loans & FOIR
          </h1>
        </div>
        <div className="main_active_loan">
          <div className="active_loan_first">
            {loading ? (
              <Loading loading={loading} />
            ) : loanDetails && loanDetails.length > 0 ? (
              <>
                <div className="active_loan_first">
                  {loanDetails.map((loan, index) => (
                    <div className="auto_loan" key={index}>
                      <div className="auto tables_text">
                        {loan?.loan_purpose}
                      </div>
                      <div className="progress">
                        <Progress
                          percent={100 - (loan?.remaining_percentage || 0)}
                          status="active"
                          className="progressbar_content"
                        />
                      </div>
                      <div className="sanction tables_text">
                        Sanctioned Amt: ₹{" "}
                        {loan?.total_sanctioned_amount?.toLocaleString(
                          "en-IN",
                          { minimumFractionDigits: 2 }
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="no_data_found_message">
                <CustomNoData />
              </div>
            )}
          </div>
          <div className="active_loan_two">
            <Table
              dataSource={tableData}
              columns={columns1}
              pagination={false}
              showHeader={false}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseHealthSummary;
