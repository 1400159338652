import React, { useEffect} from "react";
import { Button, Col, Form, Input, Row, Space, message } from "antd";
import {
  getDecisionQuestionById,
  updateDecisionQuestionService,
  createDecisionQuestionService,
} from "../services/creditAnalysisServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";


const DecisionQuestionsForm = ({ selectedQuestion, refreshData, closeForm }) => {
  const [form] = Form.useForm();

  


  const getDecisionQuestions = async () => {
    if (selectedQuestion) {
      try {
        const response = await getDecisionQuestionById(selectedQuestion);
        const { question, answer } = response?.data || {};
        form.setFieldsValue({ question, answer });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };
  
  

  const handleSubmit = async (values) => {
    try {
      if (selectedQuestion) {
        const response = await updateDecisionQuestionService(selectedQuestion, values);
        if (response.status === 200 && response.success) {
          message.success("Decision Question successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createDecisionQuestionService(values);

        if (response.status === 201 && response.success) {
          message.success("Decision question successfully created");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  
  
  useEffect(() => {
    getDecisionQuestions();
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestion]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        question: "",
        answer: "",
      }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="question"
            label="Question"
            rules={[{ required: true, message: "Please enter the question" }]}
          >
            <Input.TextArea placeholder="Please enter Question" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="answer"
            label="Answer"
            rules={[{ required: true, message: "Please enter the answer" }]}
          >
            <Input.TextArea placeholder="Please enter Answer" />
          </Form.Item>
        </Col>
      </Row>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default DecisionQuestionsForm;
