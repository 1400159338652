import { axiosRequest } from "../../../../utils/api/axiosRequest";

export const getAllMediaTypeservice = (page, limit, searchQuery) => {
    return new Promise(async (resolve, reject) => {
      try {
        page = page ? page : 1;
        limit = limit ? limit : 10;
        let path = `/api/v1/master/media-type/?page=${page}&limit=${limit}`;
        if (searchQuery.length > 0) {
          path = `/api/v1/master/media-type/?search=${searchQuery}&page=${page}&limit=${limit}`;
        }
        const response = await axiosRequest.get(path);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };



export const createMediaTypeService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
        const response = await axiosRequest.post("/api/v1/master/media-type/", data);
        return resolve(response.data);
        
    } catch (error) {
      return reject(error)
    }
  })
}

export const getMediaTypeByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/media-type/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateMediaTypeByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/master/media-type/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteMediaTypeByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/master/media-type/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const listUserService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get('/api/v1/user/get-user-list');
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

