/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Tooltip,
  message,
  Select,
  Button,
  Drawer,
  Skeleton,
  Menu,
  Dropdown,
  FloatButton,
} from "antd";
import { QuestionCircleOutlined, MessageOutlined } from "@ant-design/icons";
import "../styles/loanApplication.css";
import CustomerDetails from "../../customerTabDetails/customerDetails/views/CustomerDetails";
import {
  getLoanDetailsById,
  getAllStages,
  getMasterStages,
  getStageHistory,
} from "../services/loanapplicationService";
import LoanDetails from "../../loanTabDetails/loanDetails/views/LoanDetails";
import { useParams } from "react-router-dom";
import LoanApplicationDetails from "./LoanApplicationDetails";
import Header from "../../../layout/views/Header";
import "viewerjs-react/dist/index.css";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import LegalInitiationForm from "../initiationForms/LegalInitiationForm/views/LegalInitiationForm";
import FIInitiationForm from "../initiationForms/FIInitiationForm/views/FIInitiationForm";
import RCUInitiationForm from "../initiationForms/RCUInitiationForm/views/RCUInitiationForm";
import PDInitiationForm from "../initiationForms/PDInitiationForm/views/PDInitiationForm";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../utils/authorization/AuthorizeComponent";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import TechnicalInitiationForm from "../initiationForms/TechnicalInitiatonForm/views/TechnicalInitiationForm";
import LoanDetailMainCard from "./LoanDetailMainCard";
import PrincipleInitiationForm from "../initiationForms/PricipleInititationForm/views/PrincipleInitiationForm";
import FinalApproval from "../initiationForms/FinalApprovalInitiationForm/views/FinalApproval";
import CaseHealthSummary from "../../caseHealthSummary/views/CaseHealthSummary";
import { AiOutlineVerticalAlignTop } from "react-icons/ai";
// import "react-toastify/dist/ReactToastify.css";
import RejectForm from "../initiationForms/RejectInitiationForm/views/RejectForm";
import ChatBox from "../../../chatbot/views/ChatBox";
import FinancialApprovalForm from "../initiationForms/FinancialApprovalForm/views/FinancialApprovalForm";

const { Option } = Select;

const LoanApplication = () => {
  const { encrypted_loan_id } = useParams();
  const { user_data } = useSelector((state) => state.user);
  const [loanDetails, setLoanDetails] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isFloatingWindowVisible, setIsFloatingWindowVisible] = useState(false);
  const [allStages, setAllStages] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [title, setTitle] = useState("");
  const [isDocumentsDrawerVisible, setDocumentsDrawerVisible] = useState(false);

  const [masterStages, setMasterStages] = useState(null);
  const [refreshLoanDetailMainCard, setRefreshLoanDetailMainCard] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [agencyCode, setAgencyCode] = useState("");
  const [menuVisible, setMenuVisible] = useState(false);

  const handleRefresh = () => {
    // This changes the state, which will cause the component to re-render
    setRefreshLoanDetailMainCard((prev) => !prev);
  };

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDocumentsDrawer = () => {
    setDocumentsDrawerVisible(true);
  };

  const closeDocumentsDrawer = () => {
    setDocumentsDrawerVisible(false);
  };

  const showChatDrawer = () => {
    setIsFloatingWindowVisible(prev => !prev);
  };

  const handleSelectStage = (value) => {
    const getCode = allStages?.find((item) => item.display_name === value);
    if (getCode.agency_code) {
      setAgencyCode(getCode?.agency_code.code);
    }

    setSelectedStage(value);
  };

  const showFormDrawer = () => {
    if (!selectedStage) {
      message.error("Please select a stage before proceeding.");
      return;
    }
    setOpen(true);
  };

  const formOnClose = () => {
    setOpen(false);
    setSelectedStage(null);
  };

  const fetchStages = async () => {
    setLoading(true);
    try {
      const allStagesListResponse = await getAllStages(loan_id);
      const currentStageResponse = await getStageHistory(loan_id);
      const masterStagesResponse = await getMasterStages();

      let response = currentStageResponse.data;
      let stages = masterStagesResponse.data;
      const insertCode = {
        Initiated: "section_initiated",
        Forward: "section_initiated",
        Completed: "section_completed",
        "Vendor Completed": "section_initiated",
        "Re - Initiated": "section_reinitiated",
        Query: "section_query",
        Hold: "section_hold",
      };

      const priority = [
        "Initiated",
        "Re - Initiated",
        "Query",
        "Hold",
        "Completed",
        "Cancelled",
      ];

      // First, create a dictionary to track the highest priority status for each stage
      let stagePriority = {};

      // Go through the response array to determine the highest priority status for each stage
      response.forEach((item) => {
        const stageCode = item.stage_code;
        const status = item.status;
        if (stagePriority[stageCode]) {
          // Compare the current status priority with the existing one
          const currentPriorityIndex = priority.indexOf(
            stagePriority[stageCode]
          );
          const newPriorityIndex = priority.indexOf(status);
          if (newPriorityIndex < currentPriorityIndex) {
            stagePriority[stageCode] = status;
          }
        } else {
          stagePriority[stageCode] = status;
        }
      });

      // Now, modify the stages array by adding the class_name key based on the highest priority status
      stages.forEach((name) => {
        const stageCode = name.code;
        if (stagePriority[stageCode]) {
          const status = stagePriority[stageCode];
          if (insertCode[status]) {
            name.class_name = insertCode[status];
          }
        }
      });
      setMasterStages(stages);
      setAllStages(allStagesListResponse.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        const response = await getLoanDetailsById(loan_id);
        setLoanDetails(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchLoanDetails();
    fetchStages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, refreshLoanDetailMainCard]);

  const showDrawer = async () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
    // fetchStages();
  };

  const handleChildSubmit = () => {
    fetchStages();
  };

  const handleMenuClick = (e) => {
    const sectionId = e.key;
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      sectionElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setMenuVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="loan_details">Top</Menu.Item>
      <Menu.Item key="customer-details-section">Customer Details</Menu.Item>
      <Menu.Item key="loan-details-section">Loan Details</Menu.Item>
    </Menu>
  );

  const CustomTooltip = () => {
    return (
      <div>
        <div className="loan_status_tooltip">
          Loan Application Progress - Colour Code Reference
        </div>
        <div className="tooltip-content">
          <div className="loan_tooltip_color_status_block">
            <div className="color-code loan_tooltip_complete_status"></div>
            <div>Completed</div>
          </div>
          <div className="loan_tooltip_color_status_block">
            <div className="color-code loan_tooltip_initiated_status"></div>
            <div>Initiated</div>
          </div>

          <div className="loan_tooltip_color_status_block">
            <div className="color-code loan_tooltip_pending_status"></div>
            <div>Pending</div>
          </div>
          <div className="loan_tooltip_color_status_block">
            <div className="color-code loan_tooltip_query_status"></div>
            <div>Query</div>
          </div>
          <div className="loan_tooltip_color_status_block">
            <div className="color-code loan_tooltip_void_status"></div>
            <div>Void</div>
          </div>
        </div>
      </div>
    );
  };
  React.useEffect(() => {
    const loanNumber = loanDetails?.loan_account_number
      ? ` (${loanDetails.loan_account_number})`
      : "";
    switch (selectedStage) {
      case "Legal":
        setTitle(`Legal ${loanNumber}`);
        break;
      case "In Principal Approval":
        setTitle(`In Principal Approval ${loanNumber}`);
        break;
      case "Final Approval":
        setTitle(`Final Approval ${loanNumber}`);
        break;
        case "Financial Approval":
          setTitle(`Financial Approval ${loanNumber}`);
          break;
      case "FI":
        setTitle(`FI ${loanNumber}`);
        break;
      case "RCU":
        setTitle(`RCU ${loanNumber}`);
        break;
      case "PD":
        setTitle(`PD ${loanNumber}`);
        break;
      case "Technical":
        setTitle(`Technical ${loanNumber}`);
        break;
      case "Rejected":
        setTitle(`Rejected ${loanNumber}`);
        break;
      default:
        setTitle("");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStage]);


  return (
    <div id="loan_details">
      <Drawer
        title={
          <Header title={"Initiate"} onClose={formOnClose} name={`${title}`} />
        }
        width={950}
        onClose={formOnClose}
        open={open}
        closable={false}
      >
        {selectedStage === "Legal" && (
          <LegalInitiationForm
            closeForm={formOnClose}
            open={open}
            agencyCode={agencyCode}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "FI" && (
          <FIInitiationForm
            closeForm={formOnClose}
            open={open}
            agencyCode={agencyCode}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "RCU" && (
          <RCUInitiationForm
            loan_id={loan_id}
            open={open}
            closeForm={formOnClose}
            agencyCode={agencyCode}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "PD" && (
          <PDInitiationForm
            closeForm={formOnClose}
            open={open}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "Technical" && (
          <TechnicalInitiationForm
            closeForm={formOnClose}
            open={open}
            agencyCode={agencyCode}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "In Principal Approval" && (
          <PrincipleInitiationForm
            closeForm={formOnClose}
            open={open}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "Final Approval" && (
          <FinalApproval
            closeForm={formOnClose}
            open={open}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
         {selectedStage === "Financial Approval" && (
          <FinancialApprovalForm
            closeForm={formOnClose} 
            open={open}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "Reject" && (
          <RejectForm
            closeForm={formOnClose}
            open={open}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
      </Drawer>

      <LoanDetailMainCard refresh={refreshLoanDetailMainCard} />
      <div className="scroll-button">
        <Tooltip title={<div>Chats</div>} placement="top">
          <FloatButton
            icon={<MessageOutlined />}
            onClick={showChatDrawer}
            className="float_chat_button"
          />
        </Tooltip>

        <Tooltip title={<div>Case Health Summary</div>} placement="top">
          <FloatButton onClick={showDocumentsDrawer} className="float_button" />
        </Tooltip>

        {/* <a href="#" className="scroll-button"> */}
        {/* <Button
            shape="circle"
            icon={<AiOutlineVerticalAlignTop />}
            onClick={handleScrollToSection}
            className="float_Button_two"
          /> */}
        <Dropdown
          overlay={menu}
          visible={menuVisible}
          onVisibleChange={(visible) => setMenuVisible(visible)}
          trigger={["click"]}
        >
          <Button
            shape="circle"
            icon={<AiOutlineVerticalAlignTop />}
            onClick={(e) => {
              e.stopPropagation();
              setMenuVisible(!menuVisible);
            }}
            className="float_Button_two"
          />
        </Dropdown>
        {/* </a> */}

        <Drawer
          title={
            <Header
              title=<div className="home_application_summary_heading">
                Application Health
              </div>
              onClose={closeDocumentsDrawer}
              visible={isDocumentsDrawerVisible}
            />
          }
          width={1100}
          onClose={closeDocumentsDrawer}
          open={isDocumentsDrawerVisible}
          body-Style={{ paddingBottom: 80 }}
          closable={false}
          loan_id={loan_id}
        >
          <CaseHealthSummary
            loan_id={loan_id}
            open={isDocumentsDrawerVisible}
          />
        </Drawer>

        {/* {isFloatingWindowVisible && (
          <FloatingWindow
            loan_id={loan_id}
            // sendJsonMessage={sendJsonMessage}
            // lastJsonMessage={lastJsonMessage}
            // readyState={readyState}
            // conversationId={conversationId}
            showChatDrawer={showChatDrawer}
            isFloatingWindowVisible={isFloatingWindowVisible}
          />
        )} */}

        <Drawer
          id="chat_drawer"
          width={650}
          onClose={showChatDrawer}
          open={isFloatingWindowVisible}
          closable={false}
          styles={{ body: { padding: 0 } }}
        >
          <ChatBox loan_id={loan_id}  closeDrawer={showChatDrawer}/>
        </Drawer>
      </div>

      <Drawer
        title={
          <Header
            title="View"
            onClose={onCloseDrawer}
            name="Application Stages"
          />
        }
        width={1050}
        onClose={onCloseDrawer}
        open={drawerVisible}
        closable={false}
      >
        <LoanApplicationDetails
          open={drawerVisible}
          setSubmitStageBar={handleChildSubmit}
          loan_details={loanDetails}
          onRefresh={handleRefresh}
        />
      </Drawer>

      <div className="loan_application_progress">
        <div className="loan_application_progress_content">
          <div className="left_content">
            <p className="tooltip-text">Loan Application Progress</p>
            <Tooltip
              title={<CustomTooltip />}
              overlayClassName="custom-tooltip"
            >
              <QuestionCircleOutlined className="qustion_circle_outline" />
            </Tooltip>
            <Button type="link" className="stage_button" onClick={showDrawer}>
              <u> View Detailed Progress </u>
            </Button>
          </div>
          <div className="right_content">
            <Select
              placeholder="Select Stage "
              className="select_stage"
              onChange={handleSelectStage}
              value={selectedStage}
            >
              {allStages
                ?.sort((a, b) => a.display_name.localeCompare(b.display_name))
                .map((stage, index) => {
                  return (
                    <Option key={index} value={stage.display_name}>
                      {stage.display_name}
                    </Option>
                  );
                })}
            </Select>
            <Button
              onClick={showFormDrawer}
              type="primary"
              className="go_button_div"
            >
              Go
            </Button>
          </div>
        </div>

        <div className="container">
          <Skeleton active loading={loading}>
            {masterStages?.map((stage, index) => {
              const stageClass = stage?.class_name || "section_default";
              return (
                <div
                  key={index}
                  className={`section ${stageClass}`}
                  onClick={showDrawer}
                >
                  {stage.name}
                </div>
              );
            })}
          </Skeleton>
        </div>
      </div>

      <ConditionalRender
        userPermissions={user_data?.permissions}
        requiredPermissions={["VWCUSTMR"]}
      >
        <div id="customer-details-section">
          <CustomerDetails
            loanDetails={loanDetails}
            onRefresh={handleRefresh}
          />
        </div>
      </ConditionalRender>

      <ConditionalRender
        userPermissions={user_data?.permissions}
        requiredPermissions={["VWLOANID"]}
      >
        <div id="loan-details-section">
          <LoanDetails loanDetails={loanDetails} onRefresh={handleRefresh} />
        </div>
        {/* <PartDisbursedTable/> */}
      </ConditionalRender>
    </div>
  );
};

export default LoanApplication;
