import React, { useEffect, useState } from "react";
import { Tabs, Divider, Space, message } from "antd";
import PropertyDescription from "./PropertyDescription";
import DocumentDetails from "./DocumentDetails";
import MiscellaneousDetails from "./MiscellaneousDetails";
import "../styles/legalValuation.css";
import { getLegalStageData, getLegalValuationFormData } from "../services/legalValuationService";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import LoanSummaryBlock from "../../../utils/loanSummaryBlock/LoanSummaryBlock";
import LegalSendBack from "./LegalSendBack";
const { TabPane } = Tabs;

const LegalValuation = ({ typeCheck }) => {
  const [legalInputData, setLegalInputData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [activeTab, setActiveTab] = useState("0");
  const { encrypted_loan_id, encrypted_stage_id } = useParams();
  const [legalStageData, setLegalStageData] = useState([]);
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const tabs = [
    {
      label: "Property Description",
      component: (key) => (
        key === "0" && <PropertyDescription loanId={loan_id} stageId={stage_id} legalStageData={legalStageData}/>
      ),
    },
    {
      label: "Document Details",
      component: (key) => (
        key === "1" && <DocumentDetails loanId={loan_id} stageId={stage_id} legalStageData={legalStageData}/>
      ),
    },
    {
      label: "Miscellaneous Details",
      component: (key) => (
        key === "2" && <MiscellaneousDetails loanId={loan_id} stageId={stage_id} legalInputData={legalInputData} legalStageData={legalStageData}/>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLegalValuationFormData(stage_id);
        setLegalInputData(response.data);
        setAttachments(response.data.initiate_stage_attachment_data);
        const StageResponse = await getLegalStageData(stage_id);
        setLegalStageData(StageResponse.data);

      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="legal_input_valuation_report">
        <div>
          <h3 className="legal_heading">Legal Valuation Report</h3>
        </div>
      </div>
      <LoanSummaryBlock data={legalInputData} attachments={attachments} typeCheck={typeCheck} />
      {legalStageData?.stage_status?.display_name === "Send Back" && <LegalSendBack />}

      <div className="legal_tabs_container">
        <Space
          direction="horizontal"
          align="center"
          className="legal_input_space"
        >
          <h2 className="legal_input_valuation_report_heading">Details</h2>
        </Space>

        <Divider className="legal_input_valuation_report_divider" />
        <Tabs
          type="card"
          className="legal_input_valuation_report_tab"
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
        >
          {tabs.map((tab, index) => (
            <TabPane tab={tab.label} key={index.toString()}>
              {tab.component(activeTab)}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default LegalValuation;
