import React, { useState, useEffect } from "react";
import { Tabs, Drawer, message } from "antd";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import "../../customerDetails/styles/customerDetails.css";
import "../../../styles/loanTabsApplication.css";
import { customerDetailsTabs } from "../../../../../config/schemeConfig";
import PartnerDetails from "../../partnerDetails/views/PartnerDetails";
import { CiSettings } from "react-icons/ci";
import SelectCustomerTabs from "./SelectCustomerTabs";
import Header from "../../../../layout/views/Header";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { listFavoriteCustomerTabsService, getFavoriteCustomerByIdService } from "../services/callInitiateService";

const { TabPane } = Tabs;

const CustomerDetails = ({ loanDetails, onRefresh }) => {
  const [mode] = useState("top");
  const [showCustomerDetails, setShowCustomerDetails] = useState(true);
  const [activeKey, setActiveKey] = useState("0");
  const [isPartnerTabVisible, setIsPartnerTabVisible] = useState(false);
  const [allFavoriteTabs, setAllFavoriteTabs] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTabs, setSelectedTabs] = useState([
    "Personal Details",
    "Address Details",
    "Document Details",
    "Employment Details",
    "Existing Loan Details",
  ]);

  useEffect(() => {
    fetchFavoriteTabs();
  }, []);

  const fetchFavoriteTabs = async () => {
    try {
      const response = await getFavoriteCustomerByIdService();
      const savedTabNames = response.data.map((tab) => tab.name);
      setSelectedTabs([...new Set([...savedTabNames])]); // Remove duplicates
    } catch (error) {
      message.error("Failed to fetch favorite tabs");
    }
  };

  const getAllFavoriteTabs = async () => {
    try {
      const response = await listFavoriteCustomerTabsService();
      setAllFavoriteTabs(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const toggleCustomerDetails = () => {
    setShowCustomerDetails((prev) => !prev);
  };

  const handleShowPartnerTab = (isPresent) => {
    setIsPartnerTabVisible(isPresent);
  };

  const handleSaveTabs = async (updatedTabs) => {
    setSelectedTabs(updatedTabs);
    await fetchFavoriteTabs();
    setOpen(false);
  };

  const tabs = [];

  customerDetailsTabs[loanDetails?.loan_type?.display_name]?.forEach((item) => {
    if (selectedTabs.includes(item.label)) {
      let componentWithProps;
      if (item.label === "Address Details") {
        componentWithProps = <item.component loanDetails={loanDetails} />;
      } else if (item.label === "Personal Details") {
        componentWithProps = <item.component onShowPartnerTab={handleShowPartnerTab} onRefresh={onRefresh} />;
      } else {
        componentWithProps = <item.component />;
      }
      tabs.push({ label: item.label, component: componentWithProps });
    }
  });

  if (isPartnerTabVisible && !tabs.some((tab) => tab.label === "Partners")) {
    tabs.push({ label: "Partners", component: <PartnerDetails /> });
  }

  const showDrawer = () => {
    setOpen(true);
    fetchFavoriteTabs();
    getAllFavoriteTabs();
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <div className="customer_main_container">
      <div className={`customer_main_heading ${!showCustomerDetails ? "customer_main_heading_border" : ""}`}>
        <div>
          <h2 className="heading_loan_details">Customer Details</h2>
        </div>
        <div className="customer_icon">
          <CiSettings className="setting_button" onClick={showDrawer} />
          <Drawer
            title={<Header title="Customer Details Tabs" onClose={closeDrawer} />}
            width={800}
            onClose={closeDrawer}
            open={open}
            bodyStyle={{ paddingBottom: 80 }}
            closable={false}
          >
            <SelectCustomerTabs
              favoriteTabs={allFavoriteTabs}
              defaultTabs={selectedTabs}
              onSave={handleSaveTabs}
              selectedTabs={selectedTabs}
              onClose={closeDrawer} 
            />
          </Drawer>
          {showCustomerDetails ? (
            <AiOutlineUp className="customer_collapse_icon" onClick={toggleCustomerDetails} />
          ) : (
            <AiOutlineDown className="customer_collapse_icon" onClick={toggleCustomerDetails} />
          )}
        </div>
      </div>
      <div className={`${showCustomerDetails ? "customer_block_content_show" : "customer_block_content_hide"}`}>
        <Tabs
          tabPosition={mode}
          type="card"
          className="customer_block_content"
          onChange={handleTabChange}
          activeKey={activeKey}
          destroyInactiveTabPane
        >
          {tabs.map((tab, index) => (
            <TabPane tab={tab.label} key={index}>
              {tab.component}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default CustomerDetails;
