import React, { useEffect, useState } from "react";
import "../../style/customerStyle.css";
// import verify from "../assets/Group.png";
import { message } from "antd";
import {
  getCrimeCheckDetailsByLoanId,
  getFraudDetailsByLoanId,
} from "../services/fraudCrimeCheckServices";
import { useParams } from "react-router-dom";
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { EyeOutlined } from "@ant-design/icons";
import "../../../../commonCss/commonStyle.css";
import "../styles/fraudCrimeCheckDetails.css";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";
import { Drawer } from "antd";
import KarzaDetailsForm from "./karzaDetailsForm";
import Header from "../../../../layout/views/Header";
import {
  getBackgroundCheckByKarza,
  getKarzaDetailsService,
} from "../services/karzaDetailsServices";
import Skeleton from "react-loading-skeleton";
import add from "../../addressDetails/assets/Add.png";
import BackgroundStatusTable from "./BackgroundStatusTable";

const BackgroundCheckDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [crimeCheckData, setCrimeCheckData] = useState([]);
  const [fraudDetails, setFraudDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  // Karza related states
  const [openStatus, setOpenStatus] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedPersonId, setSelectedPersonId] = useState(null);
  const [backgroundCheckStatusData, setBackgroundCheckStatusData] =
    useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showStatusDrawer = (customerId, type) => {
    setSelectedCustomerId(customerId);
    setSelectedType(type);
    setOpenStatus(true);
  };

  const onCloseStatus = () => {
    setOpenStatus(false);
  };

  useEffect(() => {
    const fetchStatusData = async () => {
      if (selectedCustomerId && selectedType && openStatus) {
        try {
          const data = await getBackgroundCheckByKarza(
            selectedType,
            selectedCustomerId
          );
          setBackgroundCheckStatusData(data);
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        } finally {
          setLoading(false);
        }
      }
    };
    fetchStatusData();
  }, [selectedCustomerId, selectedType, openStatus]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        const [crimeCheckResponse, fraudDetailsResponse, karzaResponse] =
          await Promise.all([
            getCrimeCheckDetailsByLoanId(loan_id),
            getFraudDetailsByLoanId(loan_id),
            getKarzaDetailsService(loan_id),
          ]);
        // setSelectedCustomerId()
        setCrimeCheckData(crimeCheckResponse?.data || []);
        setFraudDetails(fraudDetailsResponse?.data || []);
        setCustomerData(karzaResponse?.data || []);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [loan_id, refreshData]);

  const tableHeadings = [
    "Crime Data",
    "No of Cases",
    "Report Date",
    "Status",
    "View Full Report",
  ];

  const HunterHeading = [
    "Fraud Data",
    "Score",
    "Date Of Initiation",
    "Decision",
  ];

  // Karza specific functions
  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return "tab_form_main_data_two_entries";
      case 3:
        return "tab_form_main_data_three_entries";
      case 4:
        return "tab_form_main_data_four_entries";
      default:
        return "";
    }
  };

  function toTitleCase(str) {
    if (!str) return "-";
    return str.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase());
  }

  const uniqueObjectNames = new Set();

  if (Array.isArray(customerData)) {
    customerData.forEach((customer) => {
      if (
        Array.isArray(customer.karza_response) &&
        customer.karza_response.length > 0
      ) {
        customer.karza_response.forEach((response) => {
          Object.keys(response).forEach((key) => {
            uniqueObjectNames.add(toTitleCase(key));
          });
        });
      }
    });
  }

  const uniqueObjectNamesList = [...uniqueObjectNames];
  const customHeaders = ["Key Parameter's", "Karza", ...uniqueObjectNamesList];

  const showDrawer = (personId) => {
    setSelectedPersonId(personId);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header title="Edit" onClose={onClose} name="Verification Types" />
          }
          width={970}
          onClose={onClose}
          open={open}
          closable={false}
          loan_id={loan_id}
        >
          <KarzaDetailsForm
            refreshData={toggleRefreshData}
            open={open}
            closeForm={onClose}
            loan_id={loan_id}
            selectedPersonId={selectedPersonId}
          />
        </Drawer>

        <Drawer
          title={
            <Header
              onClose={onCloseStatus}
              name={ "Karza"}
            />
          }
          width={970}
          onClose={onCloseStatus}
          open={openStatus}
          closable={false}
        >
          <BackgroundStatusTable
            backgroundCheckStatusData={backgroundCheckStatusData}
            customerId={selectedCustomerId}
            type={selectedType}
            open={openStatus}
            closeForm={onCloseStatus}
          />
        </Drawer>
        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : crimeCheckData.length > 0 || fraudDetails.length > 0 ? (
            <>
              <table className="tab_form_main_table">
                <thead>
                  <tr>
                    <th className="tab_form_key_parameters">Key Parameters</th>
                    {fraudDetails.map((customer) => (
                      <th
                        key={customer.customer_id}
                        className="tab_form_top_heading"
                      >
                        <div className="add_button_alignment">
                          <div>
                            <span>{customer.customer_name}</span>
                            <span>
                              <span>{` (${customer.customer_type})`} </span>
                              {/* <img src={verify} alt="group" className="verify_img_fraud_check" /> */}
                            </span>
                          </div>
                          <div className="tab_form_sub_edit">
                            <img
                              src={add}
                              alt="Add Button"
                              onClick={() => showDrawer(customer.customer_id)}
                            />
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* Render Fraud Details */}

                  {HunterHeading.map((heading, rowIndex) => (
                    <tr
                      key={`row-${rowIndex}`}
                      className={
                        heading === "Fraud Data" || heading === "Fraud Data"
                          ? "address-row"
                          : heading === "PD Entries"
                          ? "address-pd-row "
                          : heading === "Staying for Years"
                          ? "tab_form_highlighted_row_orange"
                          : ""
                      }
                    >
                      <td
                        key={`col-${rowIndex}`}
                        className="tab_form_left_heading "
                      >
                        {heading}
                      </td>

                      {fraudDetails.map((detail, colIndex) => (
                        <td key={colIndex} className="tab_form_main_data_table">
                          {heading === "Score"
                            ? detail.score || "-"
                            : heading === "Date Of Initiation"
                            ? detail.date_of_initiation || "-"
                            : heading === "Decision"
                            ? detail.descision
                            : ""}
                        </td>
                      ))}
                    </tr>
                  ))}

                  {tableHeadings.map((heading, rowIndex) => (
                    <tr
                      key={`row-${rowIndex}`}
                      className={
                        heading === "Crime Data" || heading === "Crime Data"
                          ? "address-row"
                          : heading === "PD Entries"
                          ? "address-pd-row "
                          : heading === "Staying for Years"
                          ? "tab_form_highlighted_row_orange"
                          : ""
                      }
                    >
                      <td
                        key={`col-${rowIndex}`}
                        className="tab_form_left_heading "
                      >
                        {heading}
                      </td>
                      {crimeCheckData.map((customer) => {
                        const details =
                          customer.customer_crime_check_details || {};
                        return (
                          <td
                            key={customer.customer_id}
                            className="tab_form_main_data_table"
                          >
                            {heading === "No of Cases"
                              ? details.number_of_cases || "-"
                              : heading === "Report Date"
                              ? details.report_date || "-"
                              : heading === "Status"
                              ? details.status || "-"
                              : heading === "View Full Report" &&
                                (details?.report ? (
                                  <a
                                    href={details?.report}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <EyeOutlined className="fraud_check_icon" />
                                  </a>
                                ) : (
                                  "-"
                                ))}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
              <table className="tab_form_main_table">
                {/* <thead>
                  <tr>
                    <th className="address-row">Karza</th>
                    {customerData?.map((person, index) => (
                      <th key={index} className="tab_form_left_heading">
                        <div className="add_button_alignment">
                          <div
                            // key={`row-${headerIndex}`}
                            className="address-row"
                          >
                           
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead> */}

                <tbody>
                  {loading
                    ? Array.from({ length: customHeaders?.length - 1 }).map(
                        (_, index) => (
                          <tr key={`loading-${index}`}>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                        )
                      )
                    : customHeaders?.slice(1).map((header, rowIndex) => (
                        <tr
                          key={`row-${rowIndex}`}
                          className={`Personal_main_data_table ${
                            header === "Karza"
                              ? " address-row"
                              : header === "Karza"
                              ? " address-row"
                              : ""
                          }`}
                        >
                          <td
                            className={`tab_form_left_heading${
                              header === "PD Entries" ||
                              header === "No Of Years In Current City"
                                ? ` ${
                                    header === "PD Entries"
                                      ? "tab_form_gray_highlighted_row "
                                      : "tab_form_highlighted_row_orange"
                                  }`
                                : ""
                            }`}
                          >
                            {header}
                          </td>

                          {customerData?.map((person, colIndex) => (
                            <td
                              key={`col-${colIndex}`}
                              className={`tab_form_main_data_table ${getTableColumnClass(
                                customerData?.length
                              )}`}
                            >
                              {[
                                "Pan Card",
                                "Bank Account",
                                "Driving License",
                                "Mobile Verification",
                                "Epf Uan Lookup",
                                "Epf Verification",
                                "Electricity Bill Authentication",
                                "Employment Verification Advanced",
                                "Fssai License Authentication",
                                "Form 16",
                                "Gst Authentication",
                                "Gst By Pan",
                                "Ifsc",
                                "Itr Verification",
                                "Lpg Id Authentication",
                                "Png Authentication",
                                "Passport Verification",
                                "Ration Card Authentication",
                                "Voter Id Authentication",
                                "Verification Type",
                                "Shop And Establishment",
                                "Tan Authentication",
                                "Voter Id",
                              ].includes(header) && (
                                <span
                                onClick={() => {
                                  const karzaItem = person.karza_response?.find(
                                    (item) => item[header.toUpperCase()]
                                  );
                        
                                  const type = karzaItem
                                    ? karzaItem[header.toUpperCase()]?.type
                                    : header.toUpperCase();
                        
                                  showStatusDrawer(person.customer_id, type);
                                }}
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    // textDecoration: "underline",
                                  }}
                                >
                                  {toTitleCase(
                                    person.karza_response?.find(
                                      (item) => item[header.toUpperCase()]
                                    )?.[header.toUpperCase()]?.remark || "-"
                                  )}
                                </span>
                              )}
                              {header === "Aadhaar File" && (
                            <span>
                              {" "}
                              {toTitleCase(
                                person.karza_response.find(
                                  (item) => item["AADHAAR FILE"]
                                )?.["AADHAAR FILE"]?.remark || "-"
                              )}
                            </span>
                          )}
                            </td>
                          ))}
                        </tr>
                      ))}
                </tbody>
              </table>
            </>
          ) : (
            <div className="fade-in">
              <div>
                <table className="tab_form_main_table no_data_table_height">
                  <thead>
                    <tr>
                      <th className="tab_form_key_parameters">
                        Key Parameters
                      </th>
                      {fraudDetails?.map((person, index) => (
                        <th
                          key={index}
                          className="tab_form_top_heading no_data_heading_bottom_border"
                        >
                          <span className="no_data_heading_content">
                            <div>
                              <span>{`${person?.customer_name}`} </span>
                              <span>
                                <span>{`(${person?.customer_type})`} </span>
                                {/* <img
                                  src={verify}
                                  alt="group"
                                  className='verify_icon'
                                /> */}
                              </span>
                            </div>
                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Placeholder to ensure table body aligns */}
                    {fraudDetails?.length && crimeCheckData?.length ? (
                      <tr>
                        <td colSpan={fraudDetails?.length + 1}>
                          {/* Your data rows go here */}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={fraudDetails?.length + 1}></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="no_data_icon">
                <CustomNoData />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BackgroundCheckDetails;
