import { axiosRequest } from "../../../../../../utils/api/axiosRequest";

export const submitRejection = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.post(`/api/v1/loan/loan-stage-history/`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}

export const listStandardReasons = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/master/standard-reason/list`);
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};
export const listSubReasons = (standard_code) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/master/sub-reason/list?standard_reason=${standard_code}`);
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};
