const routeArray = [
    { stage: 'Legal', status: 'Initiated', route: (loan_id, stage_id) => `/legal/input_form/${loan_id}/${stage_id}` },
    { stage: 'Legal', status: 'Send Back', route: (loan_id, stage_id) => `/legal/view/${loan_id}/${stage_id}` },
    { stage: 'Technical', status: 'Initiated', route: (loan_id, stage_id) => `/technical/input_form/${loan_id}/${stage_id}` },
    { stage: 'Technical', status: 'Send Back', route: (loan_id, stage_id) => `/technical/view/${loan_id}/${stage_id}` },
    { stage: 'Legal', status: 'Forward', route: (loan_id, stage_id) => `/legal/input_form/${loan_id}/${stage_id}` },
    { stage: 'Technical', status: 'Forward', route: (loan_id, stage_id) => `/technical/input_form/${loan_id}/${stage_id}` },
    { stage: 'Legal', status: 'Completed', route: (loan_id, stage_id) => `/legal/view/${loan_id}/${stage_id}` },
    { stage: 'Technical', status: 'Completed', route: (loan_id, stage_id) => `/technical/view/${loan_id}/${stage_id}` },
    { stage: 'Legal', status: 'Vendor Completed', route: (loan_id, stage_id) => `/legal/view/${loan_id}/${stage_id}` },
    { stage: 'Technical', status: 'Vendor Completed', route: (loan_id, stage_id) => `/technical/view/${loan_id}/${stage_id}` },
    { stage: 'RCU', status: 'Initiated', route: (loan_id, stage_id) => `/rcu/input_form/${loan_id}/${stage_id}` },
    { stage: 'RCU', status: 'Send Back', route: (loan_id, stage_id) => `/rcu/view/${loan_id}/${stage_id}` },
    { stage: 'RCU', status: 'Forward', route: (loan_id, stage_id) => `/rcu/input_form/${loan_id}/${stage_id}` },
    { stage: 'RCU', status: 'Completed', route: (loan_id, stage_id) => `/rcu/view/${loan_id}/${stage_id}` },
    { stage: 'RCU', status: 'Vendor Completed', route: (loan_id, stage_id) => `/rcu/view/${loan_id}/${stage_id}` },
    { stage: 'FI', status: 'Initiated', route: (loan_id, stage_id) => `/fi/input_form/${loan_id}/${stage_id}` },
    { stage: 'FI', status: 'Send Back', route: (loan_id, stage_id) => `/fi/view/${loan_id}/${stage_id}` },
    { stage: 'FI', status: 'Forward', route: (loan_id, stage_id) => `/fi/input_form/${loan_id}/${stage_id}` },
    { stage: 'FI', status: 'Completed', route: (loan_id, stage_id) => `/fi/view/${loan_id}/${stage_id}` },
    { stage: 'FI', status: 'Vendor Completed', route: (loan_id, stage_id) => `/fi/view/${loan_id}/${stage_id}` },
    { stage: 'Dedupe', status: 'Completed', route: (loan_id, stage_id) => `/dedupe/${loan_id}/${stage_id}` },
    { stage: 'Dedupe', status: 'Initiated', route: (loan_id, stage_id) => `/dedupe/${loan_id}/${stage_id}` },
];

export const getActionRoute = (stage, status, encrypted_loan_id, encrypted_stage_id) => {
    const routeObject = routeArray.find(
        (r) => (r.stage === stage && r.status === status)
    );
    return routeObject ? routeObject.route(encrypted_loan_id, encrypted_stage_id) : `/maintenance`;
};
