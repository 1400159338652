import {axiosRequest} from "../../../utils/api/axiosRequest"


export const getLegalValuationFormData = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/initiate-stage/get-credit-assessment-data-for-legal-input-form/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getLegalStageData = (stage_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/loan-stage-history/${stage_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }