const IncomeCalculationsColumns = (schemeDisplayName, data) => {
    const columns = [
        {
            title: 'Particulars',
            dataIndex: 'particulars',
            key: 'particulars',
            width: 200,
        },
        {
            title: 'Value to be Considered',
            dataIndex: 'valueConsidered',
            key: 'valueConsidered',
            render: (text) => `₹ ${text?.toLocaleString()}`, // Format with locale string
            width: 150,
        },
        {
            title: 'Eligible Income',
            dataIndex: 'eligibleIncome',
            key: 'eligibleIncome',
            render: (text) => `₹ ${text?.toLocaleString()}`, // Format with locale string
            width: 150,
        },
    ];

    // Data transformation based on schemeDisplayName
    let transformedData;

    switch (schemeDisplayName) {
        case 'NORMALI5':
            transformedData = [
                {
                    key: '1',
                    particulars: 'Monthly Net Salary - Non Cash',
                    valueConsidered: data[0].value_to_consider_avg_monthly_net_salary_non_cash, // Extracting from the first object
                    eligibleIncome: data[1].eligible_income_avg_monthly_net_salary_non_cash, // Extracting from the second object
                },
                {
                    key: '2',
                    particulars: 'Overtime Pay - Non Cash',
                    valueConsidered: data[2].value_to_consider_ot_pay, // Extracting from the third object
                    eligibleIncome: data[3].eligible_income_ot_pay, // Extracting from the fourth object
                },
            ];
            break;
        case 'NORMALIN':
            transformedData = [
                {
                    key: '1',
                    particulars: 'Monthly Net Salary in Cash',
                    valueConsidered: data[0].value_to_consider_avg_monthly_net_salary_cash,
                    eligibleIncome: data[0].eligible_avg_monthly_net_salary_cash,
                },
                {
                    key: '2',
                    particulars: 'Rental Income - Cash',
                    valueConsidered: data[0].value_to_consider_avg_rental_cash,
                    eligibleIncome: data[0].eligible_avg_rental_cash,
                },
                {
                    key: '3',
                    particulars: 'Rental Income - Non Cash',
                    valueConsidered: data[0].value_to_consider_avg_rental_non_cash,
                    eligibleIncome: data[0].eligible_avg_rental_non_cash,
                },
                {
                    key: '4',
                    particulars: 'Dividend / Interest',
                    valueConsidered: data[0].value_to_consider_divident,
                    eligibleIncome: data[0].eligible_divident,
                },
                {
                    key: '5',
                    particulars: 'Agricultural Income',
                    valueConsidered: data[0].value_to_consider_agriculture_income,
                    eligibleIncome: data[0].eligible_agriculture_income,
                },
            ];

            break;

        case 'INCOMEMU':
            transformedData = [
                // {
                //     key: '1',
                //     particulars: 'Turnover',
                //     valueConsidered: data[0].value_to_consider_turnover,
                //     eligibleIncome: data[0].eligible_turnover,
                // },
                {
                    key: '1',
                    particulars: 'Tax Paid',
                    valueConsidered: data[0].value_to_consider_tax_paid,
                    eligibleIncome: data[0].eligible_tax_paid,
                },
                {
                    key: '1',
                    particulars: 'Profit Before Tax',
                    valueConsidered: data[0].value_to_consider_profit_before_tax,
                    eligibleIncome: data[0].eligible_profit_before_tax,
                },
                {
                    key: '2',
                    particulars: 'Depreciation',
                    valueConsidered: data[0].value_to_consider_depreciation,
                    eligibleIncome: data[0].eligible_depreciation,
                },
                {
                    key: '3',
                    particulars: 'Interest On Loans',
                    valueConsidered: data[0].value_to_consider_interest_on_loans,
                    eligibleIncome: data[0].eligible_interest_on_loans,
                },
                {
                    key: '4',
                    particulars: 'Rental Income - Cash',
                    valueConsidered: data[0].value_to_consider_rental_income_cash,
                    eligibleIncome: data[0].eligible_rental_income_cash,
                },
                {
                    key: '5',
                    particulars: 'Rental Income - Non Cash',
                    valueConsidered: data[0].value_to_consider_rental_income_non_cash,
                    eligibleIncome: data[0].eligible_rental_income_non_cash,
                },
                {
                    key: '6',
                    particulars: 'Dividend / Interest',
                    valueConsidered: data[0].value_to_consider_divident_or_interest,
                    eligibleIncome: data[0].eligible_divident_or_interest,
                },
                {
                    key: '7',
                    particulars: 'Agricultural Income',
                    valueConsidered: data[0].value_to_consider_agriculture_income,
                    eligibleIncome: data[0].eligible_agriculture_income,
                },
            ];
            break;

        case 'INCOMEES':
            transformedData = [
                {
                    key: '1',
                    particulars: 'Turnover',
                    valueConsidered: data[0].value_to_consider_turnover,
                    eligibleIncome: data[0].eligible_turnover,
                },
                {
                    key: '2',
                    particulars: 'Tax Paid',
                    valueConsidered: data[0].value_to_consider_tax_paid,
                    eligibleIncome: data[0].eligible_tax_paid,
                },
                {
                    key: '3',
                    particulars: 'Profit Before Tax (PBT)',
                    valueConsidered: data[0].value_to_consider_pbt,
                    eligibleIncome: data[0].eligible_pbt,
                },
                {
                    key: '4',
                    particulars: 'Depreciation',
                    valueConsidered: data[0].value_to_consider_depreciation,
                    eligibleIncome: data[0].eligible_depreciation,
                },
                {
                    key: '5',
                    particulars: 'Interest on Loans',
                    valueConsidered: data[0].value_to_consider_interest_on_loans,
                    eligibleIncome: data[0].eligible_interest_on_loans,
                },
                {
                    key: '6',
                    particulars: 'Rental Income - Cash',
                    valueConsidered: data[0].value_to_consider_rental_cash,
                    eligibleIncome: data[0].eligible_rental_cash,
                },
                {
                    key: '7',
                    particulars: 'Rental Income - Non Cash',
                    valueConsidered: data[0].value_to_consider_rental_non_cash,
                    eligibleIncome: data[0].eligible_rental_non_cash,
                },
                {
                    key: '8',
                    particulars: 'Dividend / Interest',
                    valueConsidered: data[0].value_to_consider_divident,
                    eligibleIncome: data[0].eligible_divident,
                },
                {
                    key: '9',
                    particulars: 'Agricultural Income',
                    valueConsidered: data[0].value_to_consider_agriculture_income,
                    eligibleIncome: data[0].eligible_agriculture_income,
                },
            ];
            break;

        case 'BANKING3':
            transformedData = [
                {
                    key: '1',
                    particulars: '12 Month Average Bank Balance',
                    valueConsidered: data[0].value_to_consider_abb,
                    eligibleIncome: data[0].eligible_monthaly_abb,
                },
            ];
            break;

        case 'LOWLTV36':
            transformedData = [
                {
                    key: '1',
                    particulars: '12 Month Average Bank Balance',
                    valueConsidered: data[0].value_to_consider_12_month_avg_bb,
                    eligibleIncome: data[0].eligible_consider_12_month_avg_bb,
                },
            ];
            break;

        case 'EMIEQUAL':
            transformedData = [
                {
                    key: '1',
                    particulars: 'Monthly Consolidated EMI',
                    valueConsidered: data[0].value_to_consider_monthaly_consolidated_emi,
                    eligibleIncome: data[0].eligible_monthaly_consolidated_emi,
                },
            ];
            break;

        default:
            transformedData = []; // No data for default case
            break;

    }

    return { columns, data: transformedData };
};

export default IncomeCalculationsColumns;
