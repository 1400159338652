import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Upload,
  message,
} from "antd";
import "../styles/adhoc.css";

import { DeleteOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import {
  createAdhocHighmarkService,
  getAllCityService,
  getAllCountriesService,
  getAllDistrictService,
  getAllStateService,
  getallpincode,
  listGenderType,
  listIdentificationType,
  listPurposeType,
  pincodeCodeService,
} from "../services/adhocservices";
import pdf_image from "../assets/pdf.png";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import ScoreTable from "./ScoreTable";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  loan_application_number: Yup.string().required(
    "Loan Application Number is required"
  ),
  // .matches(/^[0-9]+$/, "Loan Application Number must contain only numbers"),
  enquiry_amount: Yup.string()
    .required("Enquiry Amount is required")
    .matches(/^[0-9]+$/, "Enquiry Amount must contain only numbers"),
  purpose: Yup.string().required("Purpose is required"),
  first_name: Yup.string()
    .required("First name is required")
    .matches(/^[A-Za-z ]+$/, "First name can only contain letters")
    // .min(2, "First name must be at least 2 characters")
    // .max(128, "First name must be at max 128 characters")
    .label("First name"),
  middle_name: Yup.string()
    .required("Middle name is required")
    .matches(/^[A-Za-z ]+$/, "Middle name can only contain letters")
    // .min(2, "Middle name must be at least 2 characters")
    // .max(128, "Middle name must be at max 128 characters")
    .label("Middle name"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(/^[A-Za-z ]+$/, "Last name can only contain letters")
    // .min(2, "Last name must be at least 2 characters")
    // .max(128, "Last name must be at max 128 characters")
    .label("Last Name"),
  date_of_birth: Yup.date().required("Date of Birth is required"),
  gender: Yup.string().required("Gender is required").label("Gender"),
  address_line_1: Yup.string().required("Address Line 1 is required"),
  address_line_2: Yup.string().required("Address Line 2 is required"),
  pincode: Yup.string().required("Pincode is required"),
  city: Yup.string().required("City is required"),
  taluka: Yup.string()
  .matches(/^[A-Za-z ]+$/, "Taluka can only contain letters")
  .required("Taluka is required"),
  district: Yup.string().required("District is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  mobile_number: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "Mobile Number must be 10 digits"),
  phone_number: Yup.string().matches(/^\d{8,15}$/, {
    message: "Phone number must be between 8 to 15 digits",
    excludeEmptyString: true,
  }),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const Adhoc = ({ refreshData }) => {
  const [pincode, setPincode] = useState();
  const [city, setCity] = useState();
  const [district, setDistrict] = useState();
  const [states, setStates] = useState();
  const [country, setCountry] = useState();
  const [genderType, setGenderType] = useState([]);
  const [purposeType, setPurposeType] = useState([]);
  const [identificationType, setIdentificationType] = useState([]);
  const [highMarkData, setHighMarkData] = useState();
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [isUploadButtonDisabled, setUploadButtonDisabled] = useState(true);
  const [form] = Form.useForm();

  
  const handleFieldChange = () => {
    const values = form.getFieldsValue();
    const { identification, details } = values;
    if (identification && details) {
      setUploadButtonDisabled(false);
    } else {
      setUploadButtonDisabled(true);
    }
  };

  const handlePincodeChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.id === value);
    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, district, state, country } = response.data;
        form.setFieldsValue({
          city: city?.id || undefined,
          district: district?.id || undefined,
          state: state?.id || undefined,
          country: country?.id || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  const handleInputt = (e, name) => {
    let value = e.target.value.replace(/\D/g, "");
    value = value.slice(0, 15);
    form.setFieldsValue({ [name]: value });
  };

  const handleUploadChange = (info) => {
    let updatedFileList = [...info.fileList];

    // Assuming 'updatedFileList' is your array and 'form' is your form reference
    updatedFileList = updatedFileList.map((file, index) => {
      if (index === updatedFileList.length - 1) {
        return {
          ...file,
          identification: form.getFieldValue("identification"),
          details: form.getFieldValue("details"),
          attachment: file.originFileObj
            ? file.originFileObj.name
            : file.attachment,
        };
      }
      return file;
    });
    setFileList(updatedFileList);
    form.setFieldsValue({
      identification: undefined,
      details: undefined,
      attachment: undefined,
    });
  };

  const handleBeforeUpload = (file) => {
    // const identification = form.getFieldValue("identification");
    // const details = form.getFieldValue("details");

    // if (!identification) {
    //   form.setFields([
    //     {
    //       name: "identification",
    //       errors: ["Please select a document proof"],
    //     },
    //   ]);
    //   return Upload.LIST_IGNORE;
    // }

    // if (!details) {
    //   form.setFields([
    //     {
    //       name: "details",
    //       errors: ["Please enter document number"],
    //     },
    //   ]);
    //   return Upload.LIST_IGNORE;
    // }
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const newImage = {
      file_path: isJpgOrPng ? URL.createObjectURL(file) : pdf_image,
      uid: file.uid,
    };

    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    setUploadButtonDisabled(true);
    return false; // Prevent automatic upload
  };

  const confirmDelete = () => {
    setImage(image.filter((image) => image !== deleteUploadPicture));
    const updatedFileList = fileList.filter(
      (item) => item.uid !== deleteUploadPicture.uid
    );
    setFileList(updatedFileList);
    setOpenUploadModal(false); // Close the modal
    setDeleteUploadPicture(null); // Reset the selected picture
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (values, bureauType) => {
    // values.date_of_birth = values.date_of_birth.format("YYYY-MM-DD");
    if (values.date_of_birth) {
      values.date_of_birth = values.date_of_birth.format("YYYY-MM-DD");
    }

    values.enquiry_amount = parseFloat(values.enquiry_amount);
    const document = await Promise.all(
      fileList.map(async (file) => ({
        attachment: await fileToBase64(file.originFileObj),
        details: file.details,
        identification: file.identification,
      }))
    );
    const requestData = {
      ...values,
      purpose: values.purpose,
      bureau_type: bureauType,
      document: document,
    };

    try {
      const response = await createAdhocHighmarkService(requestData);
      if (response.status === 200 && response?.success) {
        if (bureauType === "02") {
          setHighMarkData({
            data: response.data,
            highMarkScore: "02",
          });
          message.success("Highmark Data successfully created");
          form.resetFields();
          setImage([]);
          setFileList([]);
        } else if (bureauType === "01") {
          setHighMarkData({
            data: response.data,
            highMarkScore: "01",
          });
          message.success("Cibil Data successfully created");
          form.resetFields();
          setImage([]);
          setFileList([]);
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    const getAllGenderType = async () => {
      try {
        const response = await listGenderType();
        setGenderType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllGenderType();
  }, []);

  useEffect(() => {
    const getAllPurposeType = async () => {
      try {
        const response = await listPurposeType();
        setPurposeType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllPurposeType();
  }, []);

  useEffect(() => {
    const getAllIdentification = async () => {
      try {
        const response = await listIdentificationType();
        setIdentificationType(response.data.choices);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllIdentification();
  }, []);

  useEffect(() => {
    const getAllPincodelist = async () => {
      try {
        const response = await getallpincode();
        setPincode(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            refreshData();
          }
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCitylist = async () => {
      try {
        const response = await getAllCityService();
        setCity(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            refreshData();
          }
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllDistrict = async () => {
      try {
        const response = await getAllDistrictService();
        setDistrict(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            refreshData();
          }
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllState = async () => {
      try {
        const response = await getAllStateService();
        setStates(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            refreshData();
          }
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCountries = async () => {
      try {
        const response = await getAllCountriesService();
        setCountry(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            refreshData();
          }
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllPincodelist();
    getAllCitylist();
    getAllDistrict();
    getAllState();
    getAllCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleDynamicSubmit = (bureauType) => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values, bureauType);
      })
      .catch((error) => {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      });
  };

  const handleInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const NumberFields = (e, field) => {
    // Extract only digits
    const value = e.target.value.replace(/\D/g, "");

    // Update the field value
    form.setFieldsValue({ [field]: value });
  };

  const acceptNoCharacters = (e, field) => {
    // Extract only alphanumeric characters
    const value = e.target.value.replace(/[^A-Za-z0-9]/g, "");

    // Update the field value
    form.setFieldsValue({ [field]: value });
  };

  return (
    <div className="adhoc_container">
      <div className="adhoc_heading">
        <h2 className="adhoc_data">Cibil Details</h2>
      </div>

      <div className="adhoc_content">
        <div className="adhoc_sub_heading">
          <div className="adhoc_sub_data">Enquiry Details</div>
          <div  className="adhoc_enquiry_details_box">
            <div className="adhoc_data_button" onClick={() => {}}>
              Beureau Reports
            </div>
          </div>
        </div>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            transaction_type: "",
          }}
         className="adhoc_cibil_details_form"
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="loan_application_number"
                label="Loan Application Number"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Enter Loan Application Number"
                  onChange={(e) =>
                    acceptNoCharacters(e, "loan_application_number")
                  }
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="enquiry_amount"
                label="Enquiry Amount"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Enter Enquiry Amount"
                  onChange={(e) => NumberFields(e, "enquiry_amount")}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="purpose"
                label="Purpose"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please select"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {purposeType?.map((purpose) => (
                    <Option key={purpose?.id} value={purpose?.id}>
                      {purpose?.display_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="adhoc_sub_heading">
            <div className="adhoc_sub_data">Consumer Details</div>
          </div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Enter First Name"
                  onChange={(e) => handleInput(e, "first_name")}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="middle_name"
                label="Middle Name"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Enter Middle Name"
                  onChange={(e) => handleInput(e, "middle_name")}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Enter Last Name"
                  onChange={(e) => handleInput(e, "last_name")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="date_of_birth"
                label="Date Of Birth"
                rules={[yupSync]}
                required
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  className="adhoc_consumer_details_date"
                  placeholder="Select Date"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="gender"
                label="Gender"
                rules={[yupSync]}
                required
              >
                <Select placeholder="Please Select" showSearch allowClear>
                  {genderType?.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="address_line_1"
                label="Address Line 1"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Please Enter Address Line 1"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="address_line_2"
                label="Address Line 2"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Please Enter Address Line 2"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="pincode"
                label="Pincode"
                rules={[yupSync]}
                required
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Please Select"
                  filterOption={filterOption}
                  onChange={handlePincodeChange}
                  allowClear
                >
                  {pincode?.map((pincodes, index) => (
                    <Option key={pincodes?.id} value={pincodes?.id}>
                      {pincodes?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="city" label="City" rules={[yupSync]} required>
                <Select
                  placeholder="Please Select"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  disabled
                >
                  {city?.map((locations, index) => (
                    <Option key={locations?.id} value={locations?.id}>
                      {locations?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="taluka" label="Taluka" rules={[yupSync]} required>
                <Input placeholder="Please enter taluka" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="district"
                label="District"
                rules={[yupSync]}
                required
              >
                <Select
                  placeholder="Please Select"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  disabled
                >
                  {district?.map((districts, index) => (
                    <Option key={districts?.id} value={districts?.id}>
                      {districts?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="state" label="State" rules={[yupSync]} required>
                <Select
                  placeholder="Please Select"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  disabled
                >
                  {states?.map((states, index) => (
                    <Option key={states?.id} value={states?.id}>
                      {states?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="country"
                label="Country"
                rules={[yupSync]}
                required
              >
                <Select
                  placeholder="Please Select"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  disabled
                >
                  {country?.map((countries, index) => (
                    <Option key={countries?.id} value={countries?.id}>
                      {countries?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Mobile Number"
                name="mobile_number"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Please Enter Mobile Number"
                  onChange={(e) => handleNumberFields(e, "mobile_number")}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="phone_number"
                label="Phone Number"
                rules={[yupSync]}
              >
                <Input
                  placeholder="Please Enter Phone Number"
                  onChange={(e) => handleInputt(e, "phone_number")}
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="adhoc_sub_heading">
            <div className="adhoc_sub_data">Document Details</div>
          </div>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Document Proof" name="identification">
                <Select
                  mode="single"
                  placeholder="Please select"
                  required={true}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleFieldChange}
                >
                  {identificationType?.map((identification) => (
                    <Option
                      key={identification?.value}
                      value={identification?.value}
                    >
                      {identification?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Document Number" name="details">
                <Input
                  placeholder="Please Enter Details"
                  onChange={(e) => {
                    acceptNoCharacters(e, "details");
                    handleFieldChange();
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="attachment" label="Attachment">
                <Upload
                  single
                  fileList={fileList}
                  onChange={handleUploadChange}
                  beforeUpload={handleBeforeUpload}
                  showUploadList={false}
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    disabled={isUploadButtonDisabled}
                  >
                    Upload Document
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <div className="adhoc_document_details_divider">
            <div  className="adhoc_document_details_divider_line">
              {image?.map((picture, index) => (
                <div
                  key={index}
                className="adhoc_document_details_imgae"
                >
                  <img
                    src={picture?.file_path}
                    alt=""
                    className="adhoc_document_details_image_path"
                  />
                  <div
                    className="adhoc_document_details_image_delete_content"
                    onClick={() => {
                      setDeleteUploadPicture(picture);
                      setOpenUploadModal(true);
                    }}
                  >
                    <DeleteOutlined />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <Modal
            title="Confirm Delete"
            open={openUploadModal}
            onOk={confirmDelete}
            onCancel={() => {
              setOpenUploadModal(false);
              setDeleteUploadPicture(null);
            }}
            okType="danger"
          >
            <p>{`Are you sure you want to delete this upload image?`}</p>
          </Modal>
          <Space
            direction="horizontal"
            align="center"
            className="adhoc_cibil_details_space_button"
          >
            <Button type="primary" onClick={() => handleDynamicSubmit("02")}>
              Get Highmark
            </Button>
            <Button type="primary" onClick={() => handleDynamicSubmit("01")}>
              Get Cibil
            </Button>
          </Space>
        </Form>
        {highMarkData ? <ScoreTable highMarkData={highMarkData} /> : ""}
      </div>
    </div>
  );
};

export default Adhoc;
