import { Button, Col, Form, Input, message, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { createOverallStatusDedupeService, listOverallStatus } from '../services/dedupeService';
import ErrorMessage from '../../../utils/errorHandling/ErrorMessage';
import * as Yup from "yup";
import { useParams } from 'react-router-dom';
import { decrypt } from '../../../utils/cryptoUtils/cryptoUtils';

const validationSchema = Yup.object().shape({
    remark: Yup.string()
        .required("Remark is required"),
    status: Yup.string().required("Status is required"),

});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};


const OverallStatusForm = ({ closeForm, open, refreshData }) => {
    const [form] = Form.useForm();
    const [overallStatus, setOverallStatus] = useState([]);
    const { encrypted_loan_id, encrypted_stage_id } = useParams();

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");

    }
    let stage_id = null;
    try {
        stage_id = decrypt(encrypted_stage_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    useEffect(() => {
        const getAllBank = async () => {
            try {
                const response = await listOverallStatus();
                setOverallStatus(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };

        getAllBank();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, open]);


    const handleSubmit = async (values) => {
        try {
            const payload = {
                ...values,
                loan_detail_id: loan_id,
                loan_stage_id: stage_id,
            };
            const response = await createOverallStatusDedupeService(payload);
            if ((response.status = 201 && response.success)) {
                message.success("Dedupe completed successfully.");
                refreshData();
                closeForm();
            }

        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };

    useEffect(() => {

        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeForm]);
    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
                remark: "",
            }}
        >

            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item name="status" label="Status" rules={[yupSync]} required>
                        <Select placeholder="Please Select" showSearch allowClear>
                            {overallStatus.map((type) => (
                                <Select.Option key={type} value={type}>
                                    {type}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="remark"
                        label="Remark"
                        rules={[yupSync]}
                        required
                    >
                        <Input
                            placeholder="Please enter remark"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    )
}

export default OverallStatusForm