import React, { useState, useEffect } from "react";
import "../styles/videoAudioGallaryDetails.css";
import {
  getAudioAnalysisData,
  getDocumentDetailsByIdService,
} from "../services/videoAudioGalleryService";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { Button, message, Drawer } from "antd";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import Header from "../../../../layout/views/Header";

const VideoAudioGallary = () => {
  const { encrypted_loan_id } = useParams();
  const [propertyData, setPropertyData] = useState();
  const [analyseLoading, setAnalyseLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [audioAnalysisData, setAudioAnalysisData] = useState(null);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDocumentDetailsByIdService(loan_id);
        setPropertyData(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
  }, [loan_id]);

  const handleAnalyzeClick = async (media_id) => {
    try {
      setAnalyseLoading((prev) => ({ ...prev, [media_id]: true }));
      const response = await getAudioAnalysisData(media_id);
      setAudioAnalysisData(response?.data?.response); // Store API response
      setAnalyseLoading((prev) => ({ ...prev, [media_id]: false }));
      setDrawerVisible(true); // Open Drawer
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setAnalyseLoading((prev) => ({ ...prev, [media_id]: false }));
    }
  };

  return (
    <>
      <div className="fade-in">
        <div className="audio_deatails_container">
          <div className="audio_card">
            <div className="audio_card-content_first">
              <div className="videoaudio_label_two">Key Parameter's</div>
            </div>
          </div>
          <div className="audio_card_second">
            <div className="audio_card-content_first">
              <div className="videoaudio_label_third">
                Loan Applications Gallery
              </div>
            </div>
          </div>
        </div>

        <div className="video_gallery_section">
          <div className="gallery_heading">
            <p className="text_video">Video Gallery</p>
          </div>

          <div className="video_gallery_grid">
            {propertyData?.video_list?.map((item, index) => (
              <div key={index} className="video_gallery_item">
                <ReactPlayer
                  url={item?.media_path}
                  className="react-player"
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="audio_gallery_section">
          <div className="gallery_heading">
            <p className="text_video">Audio Gallery</p>
          </div>

          <div className="video_gallery_grid">
            {propertyData?.audio_list?.map((item) => (
              <div key={item.id} className="audio_gallery_item">
                <audio className="autio_div" controls>
                  <source src={item?.media_path} type="audio/mpeg" />
                </audio>
                <Button
                  style={{ marginLeft: "16px" }}
                  loading={analyseLoading[item.id]}
                  onClick={() => handleAnalyzeClick(item?.id)}
                >
                  Analyse Audio
                </Button>
              </div>
            ))}
          </div>
        </div>

        {/* Drawer Component */}
        <Drawer
          title={
            <Header
              title={"Audio Analysis"}
              onClose={() => setDrawerVisible(false)}
            />
          }
          placement="right"
          width={500}
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          closable={false}
        >
          <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {audioAnalysisData
              ? JSON.stringify(audioAnalysisData, null, 2)
              : "No Data"}
          </pre>
        </Drawer>
      </div>
    </>
  );
};

export default VideoAudioGallary;
