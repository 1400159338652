import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: [],
  loanId:null,
  documentcheckcustomers: {},
  activeCustomer: 0,
  activeKey: {activeKey:0},
  cibil: [], 
  highmark: [],
  loanType: null, 
};

const salesFormSlice = createSlice({
  name: "salesForm",
  initialState,
  reducers: {
    setLoanId: (state, action) => {
      state.loanId = action.payload;
    },
    setCustomers: (state, action) => { 
      state.customers = action.payload;
    },

    addCibilData: (state, action) => {
      const { index, value } = action.payload;
    
       
      if (Array.isArray(state.cibil)) {
         
        if (state.cibil[index]) {
         
          state.cibil[index] = { ...state.cibil[index], ...value };
        } else {
        
          state.cibil[index] = { ...value };
        }
      } else {
            state.cibil = [{ ...value }];
      }
    },
    
    addLoanTypeData: (state, action) => { 
      const  value  = action.payload; 
      state.loanType = value.value;
    },
    
    
    
    

    deleteCustomer: (state, action) => { 
      const indexToDelete = action.payload.indexToDelete; 
      if (indexToDelete >= 0 && indexToDelete < state.customers.length) { 
        state.customers.splice(indexToDelete, 1);
      }
    },

    setCustomerClass: (state, action) => {
      const {index ,value} = action.payload
      state.customers[index].customer_class = value;
    },

    setCompanyId: (state, action) => {
      const {index ,value} = action.payload
      state.customers[index].comapnyId = value;
    },

    setDocumentCheck: (state, action) => {
      state.documentcheckcustomers = action.payload;
    },

    setActiveLoanTabKey: (state, action) => {
      const { activeKey } = action.payload;
      state.activeKey = activeKey;   
    }, 

    resetCustomers: (state, action) => {
      state.customers = [];
      state.activeCustomer = 0;
      state.activeKey = 0;
      state.documentcheckcustomers = {};
      state.loanId = null; 
      state.cibil = [];
      state.highmark = [];
      state.loanType = ""
    },

    setReferenceApicalled: (state, action) => {
      const { index } = action.payload;
    
      
      if (state.customers[index] && state.customers[index].referenceDetails) {
        state.customers[index].referenceDetails.referenceapicalled = 1;
      }
    },

    setDependentApicalled: (state, action) => {
      const { index } = action.payload;
    
      
      if (state.customers[index] && state.customers[index].referenceDetails) {
        state.customers[index].dependentDetails.dependentapicalled = 1;
      }
    },

    setbase64: (state, action) => {
      const { index, base64Url } = action.payload; // Extract base64Url from the payload
    
      if (state.customers[index] && state.customers[index].documentData) {
        state.customers[index].documentData.base64 = base64Url; // Assign base64Url to documentData.base64
      }
    },
    

    setExistingApicalled: (state, action) => {
      const { index } = action.payload;
    
      
      if (state.customers[index] && state.customers[index].existingloanDetails) {
        state.customers[index].existingloanDetails.existingloanapicalled = 1;
      }
    },

    setBankApicalled: (state, action) => {
      const { index } = action.payload;
    
      
      if (state.customers[index] && state.customers[index].bankDetails) {
        state.customers[index].bankDetails.bankaccountapicalled = 1;
      }
    },

    setAddressApicalled: (state, action) => {
      const { index } = action.payload;
    
      
      if (state.customers[index] && state.customers[index].addressDetails) {
        state.customers[index].addressDetails.addressapicalled = 1;
      }
    },
    
    setInvestmentApicalled: (state, action) => {
      const { index } = action.payload; 
      
      if (state.customers[index] && state.customers[index].investmentDetails) {
        state.customers[index].investmentDetails.investmentloanapicalled = 1;
      }
    },
    
    setEmployeementApicalled: (state, action) => {
      const { index } = action.payload; 
      
      if (state.customers[index] && state.customers[index].employmentDetails) {
        state.customers[index].employmentDetails.employeeapicalled = 1;
      }
    },

    setPartnerApicalled: (state, action) => {
      const { index } = action.payload; 
      
      if (state.customers[index] && state.customers[index].partnerDetails) {
        state.customers[index].partnerDetails.partnerapicalled = 1;
      }
    },
    

    changeActiveCustomer: (state, action) => {
      state.activeCustomer = action.payload;
    },
    setCustomerdocumentData: (state, action) => {
      const { index, value } = action.payload; 
      const updatedCustomers = updateDocumentDataAtIndex(
        state.customers,
        index,
        value
      );
      state.customers = updatedCustomers;
    },
    setCustomerApplicantType: (state, action) => {
      const { index, value } = action.payload; 
      const updatedCustomers = updateApplicantTypeAtIndex(
        state.customers,
        index,
        value
      );
      state.customers = updatedCustomers;
    },
    changeStepperActiveKey: (state, action) => {
      const { index, value } = action.payload;
      const updatedCustomers = updateActiveKeyAtIndex(
        state.customers,
        index,
        value
      );
      state.customers = updatedCustomers;
    },
    changeCustomerId: (state, action) => {
      const { index, value } = action.payload;
      const updatedCustomers = updateCustomerIdAtIndex(
        state.customers,
        index,
        value
      );
      state.customers = updatedCustomers;
    },

    setInitialCustomerAddreess: (state, action) => {
      const { activeCustomer, addresses } = action.payload;

      const updatedCustomers = setAddresses(
        state.customers,
        activeCustomer,
        addresses
      );
      state.customers = updatedCustomers;
    },

    setInitialCustomerEmployment: (state, action) => {
      const { activeCustomer, employments } = action.payload;
      const updatedCustomers = setEmployment(
        state.customers,
        activeCustomer,
        employments
      );
      state.customers = updatedCustomers;
    },

    setInitialCustomerDependent: (state, action) => {
      const { activeCustomer, dependents } = action.payload;

      const updatedCustomers = setDependent(
        state.customers,
        activeCustomer,
        dependents
      );
      state.customers = updatedCustomers;
    },

    setInitialCustomerPartner: (state, action) => {
      const { activeCustomer, partners } = action.payload;

      const updatedCustomers = setPartner(
        state.customers,
        activeCustomer,
        partners
      );
      state.customers = updatedCustomers;
    },

    changeActiveAddressKey: (state, action) => {
      const { activeCustomer, key } = action.payload;

      const updatedCustomers = setActiveAddressKey(
        state.customers,
        activeCustomer,
        key
      );
      state.customers = updatedCustomers;
    },

    changeActiveEmploymentKey: (state, action) => {
      const { activeCustomer, key } = action.payload;

      const updatedCustomers = setActiveEmploymentKey(
        state.customers,
        activeCustomer,
        key
      );
      state.customers = updatedCustomers;
    },

    changeActiveDependentKey: (state, action) => {
      const { activeCustomer, key } = action.payload;

      const updatedCustomers = setActiveDependentKey(
        state.customers,
        activeCustomer,
        key
      );
      state.customers = updatedCustomers;
    },

    changeActivePartnerKey: (state, action) => {
      const { activeCustomer, key } = action.payload;

      const updatedCustomers = setActivePartnerKey(
        state.customers,
        activeCustomer,
        key
      );
      state.customers = updatedCustomers;
    },

    changeActiveEmployementKey: (state, action) => {
      const { activeCustomer, key } = action.payload;

      const updatedCustomers = setActiveEmployementKey(
        state.customers,
        activeCustomer,
        key
      );
      state.customers = updatedCustomers;
    },
    addNewCustomerAddress: (state, action) => {
      const { activeCustomerKey, activeAddressKey, id } = action.payload;
      const updatedCustomers = updateCustomerAddressId(
        state.customers,
        activeCustomerKey,
        activeAddressKey,
        id
      );
      state.customers = updatedCustomers;
    },

    removeAddress: (state, action) => { 
      const { activeCustomerKey, activeAddressKey } = action.payload;  
 
      if (state.customers[activeCustomerKey]) {
        const customer = state.customers[activeCustomerKey];
 

        if (customer.addressDetails && Array.isArray(customer.addressDetails.addresses)) {
          const addresses = customer.addressDetails.addresses;

        
          if (activeAddressKey >= 0 && activeAddressKey < addresses.length) { 
            addresses.splice(activeAddressKey, 1); 
            customer.addressDetails.addresses = addresses;
          } else {
            console.log("Invalid reference index");
          }
        } else {
          console.log("No exisying loan details available for this customer");
        }
      } else {
        console.log("Invalid customer index");
      }
    },

    setInitialCustomerReferences: (state, action) => {
      const { activeCustomer, references } = action.payload;

      const updatedCustomers = setReferences(
        state.customers,
        activeCustomer,
        references
      );
      state.customers = updatedCustomers;
    },

    changeActiveReferenceKey: (state, action) => {
      const { activeCustomer, key } = action.payload; 

      const updatedCustomers = setActiveReferenceKey(
        state.customers,
        activeCustomer,
        key
      );
      state.customers = updatedCustomers;
    }, 

    addNewCustomerReference: (state, action) => {
      const { activeCustomerKey, activeReferenceKey, id } = action.payload;
      const updatedCustomers = updateCustomerReferencesId(
        state.customers,
        activeCustomerKey,
        activeReferenceKey,
        id
      );
      state.customers = updatedCustomers;
    },

    removeReference: (state, action) => {
      const { activeCustomerKey, activeReferenceKey } = action.payload; 
      if (state.customers[activeCustomerKey]) {
        const customer = state.customers[activeCustomerKey];
 
        if (customer.referenceDetails && Array.isArray(customer.referenceDetails.references)) {
          const references = customer.referenceDetails.references;

        
          if (activeReferenceKey >= 0 && activeReferenceKey < references.length) { 
            references.splice(activeReferenceKey, 1); 
            customer.referenceDetails.references = references;
          } else {
            console.log("Invalid reference index");
          }
        } else {
          console.log("No reference details available for this customer");
        }
      } else {
        console.log("Invalid customer index");
      }
    },

    addNewCustomerDependent: (state, action) => {
      const { activeCustomerKey, activeDependentKey, id } = action.payload;
      const updatedCustomers = updateCustomerDependentId(
        state.customers,
        activeCustomerKey,
        activeDependentKey,
        id
      );
      state.customers = updatedCustomers;
    },

    removeDependent: (state, action) => { 
      const { activeCustomerKey, activeDependentKey } = action.payload; 
      if (state.customers[activeCustomerKey]) {
        const customer = state.customers[activeCustomerKey];
 

        if (customer.dependentDetails && Array.isArray(customer.dependentDetails.dependents)) {
          const dependents = customer.dependentDetails.dependents;

        
          if (activeDependentKey >= 0 && activeDependentKey < dependents.length) { 
            dependents.splice(activeDependentKey, 1); 
            customer.dependentDetails.dependents = dependents;
          } else {
            console.log("Invalid reference index");
          }
        } else {
          console.log("No reference details available for this customer");
        }
      } else {
        console.log("Invalid customer index");
      }
    },

    addNewCustomerPartner: (state, action) => {
      const { activeCustomerKey, activePartnerKey, id } = action.payload;
      const updatedCustomers = updateCustomerPartnerId(
        state.customers,
        activeCustomerKey,
        activePartnerKey,
        id
      );
      state.customers = updatedCustomers;
    },

    removePartner: (state, action) => { 
      const { activeCustomerKey, activePartnerKey } = action.payload;  
 
      if (state.customers[activeCustomerKey]) {
        const customer = state.customers[activeCustomerKey];
 

        if (customer.partnerDetails && Array.isArray(customer.partnerDetails.partners)) {
          const partners = customer.partnerDetails.partners;

        
          if (activePartnerKey >= 0 && activePartnerKey < partners.length) { 
            partners.splice(activePartnerKey, 1); 
            customer.partnerDetails.partners = partners;
          } else {
            console.log("Invalid reference index");
          }
        } else {
          console.log("No partner details available for this customer");
        }
      } else {
        console.log("Invalid customer index");
      }
    },

    changeActiveBankAccountKey: (state, action) => {
      const { activeCustomer, key } = action.payload; 

      const updatedCustomers = setActiveBankAccountKey(
        state.customers,
        activeCustomer,
        key
      );
      state.customers = updatedCustomers;
    }, 

    addNewCustomerBankAccount: (state, action) => { 
      const { activeCustomerKey, activeBankAccountKey, id } = action.payload;
      const updatedCustomers = updateCustomerBankAccountsId(
        state.customers,
        activeCustomerKey,
        activeBankAccountKey,
        id
      );
      state.customers = updatedCustomers;
    },

    removeBankAccount: (state, action) => { 
      const { activeCustomerKey, bankDetailskey } = action.payload;   
 
      if (state.customers[activeCustomerKey]) {
        const customer = state.customers[activeCustomerKey];
 

        if (customer.bankDetails && Array.isArray(customer.bankDetails.bankaccounts)) {
          const bankaccounts = customer.bankDetails.bankaccounts;

        
          if (bankDetailskey >= 0 && bankDetailskey < bankaccounts.length) { 
            bankaccounts.splice(bankDetailskey, 1); 
            customer.bankDetails.bankaccounts = bankaccounts;
          } else {
            console.log("Invalid reference index");
          }
        } else {
          console.log("No exisying loan details available for this customer");
        }
      } else {
        console.log("Invalid customer index");
      }
    },


    addNewCustomerEmployment: (state, action) => {
      const { activeCustomerKey, activeEmploymentKey, id ,type } = action.payload;
      const updatedCustomers = updateCustomerEmploymentId(
        state.customers,
        activeCustomerKey,
        activeEmploymentKey,
        id,
        type
      );
      state.customers = updatedCustomers;
    },

    removeEmployeement: (state, action) => { 
      const { activeCustomerKey, activeEmploymentKey } = action.payload;   
 
      if (state.customers[activeCustomerKey]) {
        const customer = state.customers[activeCustomerKey];
 

        if (customer.employmentDetails && Array.isArray(customer.employmentDetails.employments)) {
          const employments = customer.employmentDetails.employments;

        
          if (activeEmploymentKey >= 0 && activeEmploymentKey < employments.length) { 
            employments.splice(activeEmploymentKey, 1); 
            customer.employmentDetails.employments = employments;
          } else {
            console.log("Invalid reference index");
          }
        } else {
          console.log("No employee details available for this customer");
        }
      } else {
        console.log("Invalid customer index");
      }
    },


    setInitialCustomerBankAccount: (state, action) => {
      const { activeCustomer, bankaccounts } = action.payload;
      const updatedCustomers = setBankAccounts(
        state.customers,
        activeCustomer,
        bankaccounts
      );
      state.customers = updatedCustomers;
    },

    changeActiveInvestmentKey: (state, action) => {
      const { activeCustomer, key } = action.payload; 

      const updatedCustomers = setActiveInvestmentKey(
        state.customers,
        activeCustomer,
        key
      );
      state.customers = updatedCustomers;
    }, 

    setInitialCustomerInvestments: (state, action) => {
      const { activeCustomer, investments } = action.payload; 
    
      const updatedCustomers = setInvestments(
        state.customers,
        activeCustomer,
        investments
      );
      state.customers = updatedCustomers;
    },

    addNewCustomerInvestment: (state, action) => {
      const { activeCustomerKey, activeInvestmentKey, id } = action.payload; 
      const updatedCustomers = updateCustomerInvestmentsId(
        state.customers,
        activeCustomerKey,
        activeInvestmentKey,
        id
      );
      state.customers = updatedCustomers;
    },

    removeInvestment: (state, action) => { 
      const { activeCustomerKey, activeInvestmentKey } = action.payload;  
 
      if (state.customers[activeCustomerKey]) {
        const customer = state.customers[activeCustomerKey];
 

        if (customer.investmentDetails && Array.isArray(customer.investmentDetails.investments)) {
          const investments = customer.investmentDetails.investments;

        
          if (activeInvestmentKey >= 0 && activeInvestmentKey < investments.length) { 
            investments.splice(activeInvestmentKey, 1); 
            customer.investmentDetails.investments = investments;
          } else {
            console.log("Invalid reference index");
          }
        } else {
          console.log("No exisying loan details available for this customer");
        }
      } else {
        console.log("Invalid customer index");
      }
    },

    addNewCustomerExistingLoan: (state, action) => {
      const { activeCustomerKey, activeExistingLoanKey, id } = action.payload;
      const updatedCustomers = updateCustomerExistingLoanId(
        state.customers,
        activeCustomerKey,
        activeExistingLoanKey,
        id
      );
      state.customers = updatedCustomers;
    },

    removeExisting: (state, action) => { 
      const { activeCustomerKey, activeExistingLoanKey } = action.payload;  
 
      if (state.customers[activeCustomerKey]) {
        const customer = state.customers[activeCustomerKey];
 

        if (customer.existingloanDetails && Array.isArray(customer.existingloanDetails.existingloan)) {
          const existingloan = customer.existingloanDetails.existingloan;

        
          if (activeExistingLoanKey >= 0 && activeExistingLoanKey < existingloan.length) { 
            existingloan.splice(activeExistingLoanKey, 1); 
            customer.existingloanDetails.existingloan = existingloan;
          } else {
            console.log("Invalid reference index");
          }
        } else {
          console.log("No existing loan details available for this customer");
        }
      } else {
        console.log("Invalid customer index");
      }
    },

    setInitialCustomerExistingLoan: (state, action) => {


      const { activeCustomer, existingloan } = action.payload;
      const updatedCustomers = setExistingLoans(
        state.customers,
        activeCustomer,
        existingloan, 
      );
      state.customers = updatedCustomers;
    },

    changeActiveExistingLoanKey: (state, action) => {
      const { activeCustomer, key } = action.payload;  

      const updatedCustomers = setActiveExistingLoanKey(
        state.customers,
        activeCustomer,
        key
      );
      state.customers = updatedCustomers;
    }, 
  },
});

function updateActiveKeyAtIndex(data, index, value) {
  if (index >= 0 && index < data.length) {
    const updatedData = [...data];
    updatedData[index] = {
      ...updatedData[index],
      activeKey: value,
    };
    return updatedData;
  } else {
    throw new Error("Index out of bounds");
  }
}

function updateCustomerIdAtIndex(data, index, value) {
  if (index >= 0 && index < data.length) {
    const updatedData = [...data];
    updatedData[index] = {
      ...updatedData[index],
      id: value,
    };
    delete updatedData[index].documentData;
    return updatedData;
  } else {
    throw new Error("Index out of bounds");
  }
}

function updateDocumentDataAtIndex(data, index, value) {
  if (index >= 0 && index < data.length) {
    const updatedData = [...data];
    updatedData[index] = {
      ...updatedData[index],
      documentData: value,
    };
    return updatedData;
  } else {
    throw new Error("Index out of bounds");
  }
}
function updateApplicantTypeAtIndex(data, index, value) {
  if (index >= 0 && index < data.length) {
    const updatedData = [...data];
    updatedData[index] = {
      ...updatedData[index],
      applicant_type: value,
    };
    return updatedData;
  } else {
    throw new Error("Index out of bounds");
  }
}

function setActiveDependentKey(customers, index, key) {
  if (customers[index]) {
    customers[index].dependentDetails.activeDependentKey = key;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

function setActivePartnerKey(customers, index, key) {
  if (customers[index]) {
    customers[index].partnerDetails.activePartnerKey = key;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

function setActiveEmployementKey(customers, index, key) {
  if (customers[index]) {
    customers[index].employmentPensioner.activeEmplomentPensionerKey = key;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

// Function to set activeAddressKey at a specific index
function setActiveAddressKey(customers, index, key) {
  if (customers[index]) {
    customers[index].addressDetails.activeAddressKey = key;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

function setActiveReferenceKey(customers, index, key) {
  if (customers[index]) {
    customers[index].referenceDetails.activeReferenceKey = key;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

function setActiveEmploymentKey(customers, index, key) {
  if (customers[index]) {
    customers[index].employmentDetails.activeEmploymentKey = key;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

function setActiveBankAccountKey(customers, index, key) {


  if (customers[index]) {
    customers[index].bankDetails.activeBankAccountKey = key;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

function setActiveInvestmentKey(customers, index, key) {
  if (customers[index]) {
    customers[index].investmentDetails.activeInvestmentKey = key;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

function setActiveExistingLoanKey(customers, index, key) { 

  if (customers[index]) {
    customers[index].existingloanDetails.activeExistingLoanKey = key;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

// Function to set addresses at a specific index
function setAddresses(customers, index, newAddressses) {
  if (customers[index]) {
    customers[index].addressDetails.addresses = newAddressses;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

function setReferences(customers, index, newReferences) {
  if (customers[index]) {
    if (!customers[index].referenceDetails) {
      customers[index].referenceDetails = { references: [] }; 
    }
    customers[index].referenceDetails.references = newReferences;
  } else {
    console.error(`Customer at index ${index} not found`);
  }
  return customers;
}

function setEmployment(customers, index, newEmployments) {
  if (customers[index]) {
    customers[index].employmentDetails.employments = newEmployments;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

function setBankAccounts(customers, index, newBankAccounts) {
  if (customers[index]) {
    if (!customers[index].bankDetails) {
      customers[index].bankDetails = { bankaccounts: [] }; 
    }
    customers[index].bankDetails.bankaccounts = newBankAccounts;
  } else {
    console.error(`Customer at index ${index} not found`);
  }
  return customers;
}

function setInvestments(customers, index, newInvestments) {  

  if (customers[index]) {
    if (!customers[index].investmentDetails) {
      customers[index].investmentDetails = { investments: [] }; 
    }
    customers[index].investmentDetails.investments = newInvestments;
  } else {
    console.error(`Customer at index ${index} not found`);
  }
  return customers;
}

function setExistingLoans(customers, index, newExistingLoans) {

  if (customers[index]) {
    if (!customers[index].existingloanDetails) {
      customers[index].existingloanDetails = { existingloan: [] }; 
    }
    customers[index].existingloanDetails.existingloan = newExistingLoans;
  } else {
    console.error(`Customer at index ${index} not found`); 
  }
  return customers;
}
    
function setDependent(customers, index, newDependent) {
  if (customers[index]) {
    customers[index].dependentDetails.dependents = newDependent;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

function setPartner(customers, index, newPartner) {
  if (customers[index]) {
    customers[index].partnerDetails.partners = newPartner;
  } else {
    console.error("Invalid index: ", index);
  }
  return customers;
}

function updateCustomerAddressId(customers, index, addressIndex, id) {
  // Check if the customer exists at the given index
  if (customers[index]) {
    if (
      customers[index].addressDetails &&
      Array.isArray(customers[index].addressDetails.addresses) &&
      addressIndex >= 0 &&
      addressIndex < customers[index].addressDetails.addresses.length
    ) {
      customers[index].addressDetails.addresses[addressIndex] = id;
    } else {
      console.log("Invalid addressIndex or addresses array");
    }
  } else {
    console.log("Invalid customer index");
  }
  return customers;
}

function updateCustomerReferencesId(customers, index, referenceIndex, id) {
  if (customers[index]) {
    if (
      customers[index].referenceDetails &&
      Array.isArray(customers[index].referenceDetails.references) &&
      referenceIndex >= 0 &&
      referenceIndex < customers[index].referenceDetails.references.length
    ) {
      customers[index].referenceDetails.references[referenceIndex] = id;
    } else {
      console.log("Invalid referenceIndex or references array");
    }
  } else {
    console.log("Invalid customer index");
  }
  return customers;
}

function updateCustomerBankAccountsId(customers, index, bankaccountIndex, id) {
  if (customers[index]) {
    if (
      customers[index].bankDetails &&
      Array.isArray(customers[index].bankDetails.bankaccounts) &&
      bankaccountIndex >= 0 &&
      bankaccountIndex < customers[index].bankDetails.bankaccounts.length
    ) {
      customers[index].bankDetails.bankaccounts[bankaccountIndex] = id;
    } else {
      console.log("Invalid bankaccountIndex or references array");
    }
  } else {
    console.log("Invalid customer index");
  }
  return customers;
}

function updateCustomerInvestmentsId(customers, index, investmentIndex, id) {

  if (customers[index]) {
    if (
      customers[index].investmentDetails &&
      Array.isArray(customers[index].investmentDetails.investments) &&
      investmentIndex >= 0 &&
      investmentIndex < customers[index].investmentDetails.investments.length
    ) {
      customers[index].investmentDetails.investments[investmentIndex] = id;
    } else {
      console.log("Invalid bankaccountIndex or references array");
    }
  } else {
    console.log("Invalid customer index");
  }
  return customers;
}

function updateCustomerExistingLoanId(customers, index, existingloanIndex, id) { 

  if (customers[index]) {
    if (
      customers[index].existingloanDetails &&
      Array.isArray(customers[index].existingloanDetails.existingloan) &&
      existingloanIndex >= 0 &&
      existingloanIndex < customers[index].existingloanDetails.existingloan.length
    ) {
      customers[index].existingloanDetails.existingloan[existingloanIndex] = id;
    } else {
      console.log("Invalid bankaccountIndex or references array");
    }
  } else {
    console.log("Invalid customer index");
  }
  return customers;
}

function updateCustomerDependentId(customers, index, dependentIndex, id) {
  // Check if the customer exists at the given index
  if (customers[index]) {
    // Check if the dependents array exists and the addressIndex is within bounds
    if (
      customers[index].dependentDetails &&
      Array.isArray(customers[index].dependentDetails.dependents) &&
      dependentIndex >= 0 &&
      dependentIndex < customers[index].dependentDetails.dependents.length
    ) {
      // Update the address at the given addressIndex with the new id
      customers[index].dependentDetails.dependents[dependentIndex] = id;
    } else {
      console.log("Invalid dependentsIndex or addresses array");
    }
  } else {
    console.log("Invalid customer index");
  }

  // Return the modified customers array
  return customers;
}

function updateCustomerPartnerId(customers, index, partnerIndex, id) {
  // Check if the customer exists at the given index
  if (customers[index]) {
    // Check if the dependents array exists and the addressIndex is within bounds
    if (
      customers[index].partnerDetails &&
      Array.isArray(customers[index].partnerDetails.partners) &&
      partnerIndex >= 0 &&
      partnerIndex < customers[index].partnerDetails.partners.length
    ) {
      // Update the address at the given addressIndex with the new id
      customers[index].partnerDetails.partners[partnerIndex] = id;
    } else {
      console.log("Invalid dependentsIndex or addresses array");
    }
  } else {
    console.log("Invalid customer index");
  }

  // Return the modified customers array
  return customers;
}


function updateCustomerEmploymentId(customers, index, employmentIndex, id,type) {
  // Check if the customer exists at the given index
  if (customers[index]) {
    // Check if the dependents array exists and the addressIndex is within bounds
    if (
      customers[index].employmentDetails &&
      Array.isArray(customers[index].employmentDetails.employments) &&
      employmentIndex >= 0 &&
      employmentIndex < customers[index].employmentDetails.employments.length
    ) {
      // Update the address at the given addressIndex with the new id
      customers[index].employmentDetails.employments[employmentIndex] ={
        id: id,
        type: type,
      }
    } else {
      console.log("Invalid employmentIndex or addresses array");
    }
  } else {
    console.log("Invalid customer index");
  }

  // Return the modified customers array
  return customers;
}

export const {
  setLoanId,
  setCustomers,
  resetCustomers,
  resetActiveKey,
  setDocumentCheck,
  changeActiveCustomer,
  changeStepperActiveKey,
  setCustomerdocumentData,
  changeCustomerId,
  setInitialCustomerAddreess,
  changeActiveAddressKey,
  addNewCustomerAddress,
  setInitialCustomerReferences,
  changeActiveReferenceKey,
  addNewCustomerReference,
  setInitialCustomerBankAccount,
  addNewCustomerBankAccount,
  changeActiveBankAccountKey,
  setInitialCustomerInvestments,
  addNewCustomerInvestment,
  changeActiveInvestmentKey,
  setInitialCustomerExistingLoan,
  changeActiveExistingLoanKey,
  addNewCustomerExistingLoan,
  setInitialCustomerDependent,
  changeActiveDependentKey,
  addNewCustomerDependent,
  changeActiveEmploymentKey,
  setInitialCustomerEmployment,
  addNewCustomerEmployment,
  setCustomerClass,
  setInitialCustomerPartner,
  changeActivePartnerKey,
  addNewCustomerPartner,
  setCompanyId,
  setActiveLoanTabKey, 
  setReferenceApicalled,
  setDependentApicalled,
  setExistingApicalled,
  setInvestmentApicalled,
  setBankApicalled,
  setAddressApicalled,
  setEmployeementApicalled,
  setPartnerApicalled,
  removeReference,
  removeDependent,
  removeExisting,
  removeInvestment,
  removeBankAccount,
  removeEmployeement,
  removeAddress,
  removePartner,
  deleteCustomer,
  setbase64,
  addCibilData,
  addHighmarkData,
  addLoanTypeData,
  setCustomerApplicantType
} = salesFormSlice.actions;

export default salesFormSlice.reducer;
