import React, { useState, useEffect } from "react";
import "../technicalValuationReport/styles/technicalreport.css";
import "../../../loanApplication/customerTabDetails/style/customerStyle.css";
import {
  Button,
  Col,
  Form,
  Input,
  Select,
  Row,
  DatePicker,
  Checkbox,
  Table,
  Upload,
  message,
  Modal,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { UploadOutlined, UpOutlined, DeleteOutlined, FileOutlined } from "@ant-design/icons";
import validationSchema from "./validationSchema";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import pdf_image from "../technicalValuationReport/asset/pdf.png";
import {
  TechnicalValuationReportService,
  getAllStatusLandHoldingChoiceService,
  getTypePropertySiteChoiceService,
  getZoingChoicesService,
  getMCGPLimitChoiceService,
  getapproachRoadTypeChoiceService,
  getGOVPVTRoadChoiceService,
  getLocalityTypeChoiceService,
  getMarkebilityChoiceService,
  getSesmicZoneChoiceService,
  getRiskDemolitionChoiceService,
  getNoOfKitchenService,
  getPortionWitnessChoiceService,
  getHouseDeliveryAgencyChoiceService,
  getIdentifiedThroughChoiceService,
  getMaintainenceLevelChoiceService,
  getTypeOfStructureService,
  developedByService,
  byLawService,
  listLegalDocumentTypeService,
  listTechnicalDocumentTypeService,
  BasicDetailsService,
  BeforeYearsService,
  getTypePropertyDocumentChoiceService,
  getCreditAssessmentData,
  getallpincode,
  getAllStateService,
} from "./services/TechnicalValuationReportservices";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import LoanSummaryBlock from "../../../../utils/loanSummaryBlock/LoanSummaryBlock";
import RViewerJS from "viewerjs-react";
import dayjs from "dayjs";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { stageToCompleteService } from "../../../loanApplication/loanSummary/services/loanapplicationService";
import { pincodeCodeService } from "./services/pincodeCodeService";
import CommonDeviation from "../../../../utils/deviation/views/CommonDeviation";
import { useSelector } from "react-redux";

const { Option } = Select;

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const TechnicalValuationReport = ({ typeCheck }) => {
  const navigate = useNavigate();
  const { encrypted_loan_id, encrypted_stage_id } = useParams();
  const [form] = Form.useForm();
  const [houseDelivery, setHouseDelivery] = useState([]);
  const [demolition, setDemolition] = useState([]);
  const [kitchen, setKitchen] = useState([]);
  const [markebility, setMarkebility] = useState([]);

  const [seismiczones, setSeismiczones] = useState([]);
  const [maintenance, setMaintenance] = useState([]);
  const [gov, setGov] = useState([]);
  const [approachroadtype, setApproachroadtype] = useState([]);
  const [zones, setZones] = useState([]);
  const [types, setTypes] = useState([]);
  const [ploatAsPerPlan, setPloatAsPerPlan] = useState(0);
  const [ploatAsPerSite, setPloatAsPerSite] = useState(0);
  const [eploatAsPerPlan, setEPloatAsPerPlan] = useState(0);
  const [eploatAsPerSite, setEPloatAsPerSite] = useState(0);
  const [pploatAsPerPlan, setPPloatAsPerPlan] = useState(0);
  const [pploatAsPerSite, setPPloatAsPerSite] = useState(0);
  const [aploatAsPerSite, setAPloatAsPerSite] = useState(0);
  const [landholding, setLandholding] = useState([]);
  const [rate, setRate] = useState(0);
  const [remark, setRemark] = useState("");
  const [identified, setIdentified] = useState([]);
  const [limitChoice, setLimitChoice] = useState([]);
  const [portion, setPortion] = useState([]);
  const [structures, setStructures] = useState([]);
  const [localityType, setLocalityType] = useState([]);
  const [, setPRecommended] = useState(0);
  const [erecommended, setERecommended] = useState(0);
  const [pcrecommended, setPCRecommended] = useState(0);
  const [arecommended, setARecommended] = useState(0);
  const [developedBy, setDevelopedBy] = useState([]);
  const [laws, setLaws] = useState([]);
  const [sameAsDocument, setSameAsDocument] = useState(false);
  const [isBasicDetailVisible, setIsBasicDetailVisible] = useState(true);
  const [isToggleValuationContent, setIsToggleValuationContent] =
    useState(true);
  const [isGovtValue, setIsGovtValue] = useState(true);
  const [isValuerCertification, setIsValuerCertification] = useState(true);
  const [isPropertyAppraised, setIsPropertyAppraised] = useState(true);
  const [isPlanApproval, setIsPlanApproval] = useState(true);
  const [isDocumentsProvided, setIsDocumentsProvided] = useState(true);
  const [isPropertyDocuments, setIsPropertyDocuments] = useState(true);
  const [isPropertySpecificRemark, setIsPropertySpecificRemark] =
    useState(true);
  const [isBoundariesDetails, setIsBoundariesDetails] = useState(true);
  const [legalDocumentType, setLegalDocumentType] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [images, setImages] = useState([]);
  const [documentNames, setDocumentNames] = useState([]);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [pcrate, setPcrate] = useState(0);
  const [erate, setErate] = useState(0);
  const [technicaldocument, setTechnicaldocument] = useState([]);
  const [beforeyears, setBeforeyears] = useState([]);
  const [sitedocument, setSitedocument] = useState([]);
  const [mismatch, setMismatch] = useState("");
  const [propertynearnala, setPropertynearnala] = useState("");
  const [propertynearhtl, setPropertynearhtl] = useState("");
  const [demolitiondone, setDemolitiondone] = useState("");
  const [propertyaffectedroad, setPropertyaffectedroad] = useState("");
  const [creditAssessmentStageData, setCreditAssessmentStageData] = useState(null);
  const [collectionStatus, setCollectionStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [pincode, setPincode] = useState([]);
  const [state, setState] = useState([]);
  const [OccupancyStatus, setOccupancyStatus] = useState("");
  const [manualDeviationData, setManualDeviationData] = useState([]);
  const [operationType, setOperationType] = useState("Technical");
  const [legalData, setLegalData] = useState(null);
  const { user_data } = useSelector((state) => state.user);
  // const { TextArea } = Input;

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchCreditAssessmentStageData = async () => {
      try {
        const data = await getCreditAssessmentData(stage_id);
        setCreditAssessmentStageData(data.data);
        setOperationType("Technical");
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchCreditAssessmentStageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let ploatDeviation = 0;

  let ploatDeviation = 0;

  if (isNaN(ploatAsPerSite) || isNaN(ploatAsPerPlan)) {
  } else if (Number(ploatAsPerSite) >= Number(ploatAsPerPlan)) {
    // Avoid division by zero
    if (Number(ploatAsPerPlan) === 0) {
      ploatDeviation = 0;
    } else {
      ploatDeviation = (
        ((Number(ploatAsPerSite) - Number(ploatAsPerPlan)) /
          Number(ploatAsPerPlan)) *
        100
      ).toFixed(2);
    }
  } else {
    ploatDeviation = 0;
  }

  ploatDeviation = parseFloat(ploatDeviation);

  let eploatDeviation = 0;
  if (Number(eploatAsPerPlan) >= Number(eploatAsPerSite)) {
    eploatDeviation = 0;
  } else {
    if (Number(eploatAsPerPlan) === 0) {
      eploatDeviation = 0;
    } else {
      eploatDeviation = (
        ((eploatAsPerSite - eploatAsPerPlan) / eploatAsPerPlan) *
        100
      ).toFixed(2);
    }
  }

  if (eploatDeviation !== 0) {
    eploatDeviation = parseFloat(eploatDeviation);
  }

  let pploatDeviation = 0;

  if (isNaN(pploatAsPerSite) || isNaN(pploatAsPerPlan)) {
  } else {
    if (Number(pploatAsPerSite) < Number(pploatAsPerPlan)) {
      pploatDeviation = 0;
    } else {
      if (Number(pploatAsPerPlan) === 0) {
        pploatDeviation = 0;
      } else {
        pploatDeviation = (
          ((Number(pploatAsPerSite) - Number(pploatAsPerPlan)) /
            Number(pploatAsPerPlan)) *
          100
        ).toFixed(2);
      }
    }

    if (pploatDeviation !== 0) {
      pploatDeviation = parseFloat(pploatDeviation);
    }
  }

  const avaluation = (aploatAsPerSite * arecommended) / 100;

  let valuation = (Math.min(ploatAsPerPlan, ploatAsPerSite) * rate).toFixed(2);
  valuation = parseFloat(valuation);

  let pvaluation = (
    ((Math.min(pploatAsPerPlan, pploatAsPerSite) * pcrecommended) / 100) *
    pcrate
  ).toFixed(2);
  pvaluation = parseFloat(pvaluation);

  let evaluation = (
    ((Math.min(eploatAsPerPlan, eploatAsPerSite) * erecommended) / 100) *
    erate
  ).toFixed(2);
  evaluation = parseFloat(evaluation);

  let tpc4 = Number(aploatAsPerSite);

  let tpc =
    Math.min(pploatAsPerPlan, pploatAsPerSite) * pcrate +
    Math.min(eploatAsPerPlan, eploatAsPerSite) * erate +
    valuation +
    tpc4;

  let fsv = tpc * 0.75;

  const fmv = valuation + evaluation + pvaluation + avaluation;

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handletextInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s/()-]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const formattedDeviations = manualDeviationData.map((item) => ({
    loan_detail: parseInt(loan_id),
    deviation: item.deviation,
    actual_description: item.actual_description,
    mitigates: item.mitigates,
  }));

  const handleSubmit = async (values, record) => {
    setLegalData(values)
    setConfirmModalVisible(true)
  };


  const handleConfirmOk = async () => {
    setLoading(true); // Start loading

    try {
      const formattedDateofvisit = legalData.date_of_visit.format("YYYY-MM-DD");
      const formattedDateofvaluation =
        legalData.date_of_valuation.format("YYYY-MM-DD");
      const formattedDateofinspection =
        legalData.date_of_inspection.format("YYYY-MM-DD");

      const declarationValue = legalData.declaration ? 1 : 0;
      // const technicalDetailValue = technical_detail;
      const uploadDocuments = await Promise.all(
        fileList.map(async (file, index) => ({
          [documentNames[index]]: {
            file_path: await fileToBase64(file.originFileObj),
            document_type: documentNames[index],
          },
        }))
      );

      const payload = {
        ...legalData,
        loan_detail: loan_id,
        date_of_visit: formattedDateofvisit,
        date_of_valuation: formattedDateofvaluation,
        date_of_inspection: formattedDateofinspection,
        loan_stage_id: stage_id,
        credit_assessment_detail:
          creditAssessmentStageData?.credit_assessment_data?.id,
        address: legalData.address,
        declaration: declarationValue,
        actual_site_address: `${legalData.document_address1},${legalData.document_address2},${legalData.document_city},${legalData.document_district}`,
        document_site_address: `${legalData.site_address1},${legalData.site_address2},${legalData.site_city},${legalData.site_district}`,
        total_market_value: tpc,
        // remark: remark,
        forced_sale_value: fsv,
        completed_value: fmv,
        ploat_deviation: ploatDeviation,
        eca_deviation: eploatDeviation,
        pca_deviation: pploatDeviation,
        amenities_valuation: avaluation,
        ploat_valuation: valuation,
        eca_valuation: evaluation,
        pca_valuation: pvaluation,
        //technical_detail: technicalDetailValue,
        upload_documents: uploadDocuments,
        technical_deviation: formattedDeviations,
        documents: technicaldocument.map((doc) => ({
          [doc.name.toLowerCase().replace(/[\s*/]/g, "_")]: {
            document_type: doc.id,
            document_number: legalData[`document_number_${doc.id}`],
            collection_status: legalData[`collection_status_${doc.id}`],
          },
        })),
      };

      const response = await TechnicalValuationReportService(payload);
      if ((response.status = 200 && response.success)) {
        // setConfirmModalVisible(true);

        await handleCompleteStage(stage_id);
        setConfirmModalVisible(false);
        form.resetFields();
        navigate(`/loandetails/${encrypted_loan_id}`);
        // message.success("Technical Valuation Details Added Successfully");

        //refreshData();
        // closeForm();
      }


    } catch (error) {
      // Handle error if any
      setLoading(false); // Stop loading
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };


  const getHousingDeliverylist = async () => {
    try {
      const response = await getHouseDeliveryAgencyChoiceService();
      setHouseDelivery(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getDemolitionChoiceslist = async () => {
    try {
      const response = await getRiskDemolitionChoiceService();
      setDemolition(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllState = async () => {
    try {
      const response = await getAllStateService();
      setState(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const getPincodeList = async () => {
    try {
      const response = await getallpincode();
      setPincode(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const getKitchenlist = async () => {
    try {
      const response = await getNoOfKitchenService();
      setKitchen(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getMarketlibitylist = async () => {
    try {
      const response = await getMarkebilityChoiceService();
      setMarkebility(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getSeismicZoneslist = async () => {
    try {
      const response = await getSesmicZoneChoiceService();
      setSeismiczones(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getMaintenenceLevellist = async () => {
    try {
      const response = await getMaintainenceLevelChoiceService();
      setMaintenance(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getGOVPVTRoadChoiceServices = async () => {
    try {
      const response = await getGOVPVTRoadChoiceService();
      setGov(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getapproachRoadTypeChoiceServiceList = async () => {
    try {
      const response = await getapproachRoadTypeChoiceService();
      setApproachroadtype(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getZoningChoicesList = async () => {
    try {
      const response = await getZoingChoicesService();
      setZones(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getTypePropertyChoicesList = async () => {
    try {
      const response = await getTypePropertySiteChoiceService();
      setTypes(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getLandHoldingList = async () => {
    try {
      const response = await getAllStatusLandHoldingChoiceService();
      setLandholding(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getIdentifiedThroughList = async () => {
    try {
      const response = await getIdentifiedThroughChoiceService();
      setIdentified(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getMCGPLimitChoiceList = async () => {
    try {
      const response = await getMCGPLimitChoiceService();
      setLimitChoice(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getPortionWitnessChoiceList = async () => {
    try {
      const response = await getPortionWitnessChoiceService();
      setPortion(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getTypeOfStructureList = async () => {
    try {
      const response = await getTypeOfStructureService();
      setStructures(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getLocalityTypeChoiceList = async () => {
    try {
      const response = await getLocalityTypeChoiceService();
      setLocalityType(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getDevelopedByChoiceList = async () => {
    try {
      const response = await developedByService();
      setDevelopedBy(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getyLawServiceList = async () => {
    try {
      const response = await byLawService();
      setLaws(response?.data?.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getTechnicalDocumentServiceList = async () => {
    try {
      const response = await listTechnicalDocumentTypeService();
      setTechnicaldocument(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getPersonalData = async () => {
    try {
      // Check the value of loan_id
      const response = await BasicDetailsService(stage_id);
      form.setFieldsValue({
        address: response.data.technical_initiate_data?.property_address,
        loan_account_number: response.data.loan_detail.loan_account_number,
        product: response.data.loan_detail.loan_type.name,
      });
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getBeforeYearsData = async () => {
    try {
      const response = await BeforeYearsService();
      setBeforeyears(response?.data?.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getTypePropertyDocument = async () => {
    try {
      const response = await getTypePropertyDocumentChoiceService();
      setSitedocument(response?.data?.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    getHousingDeliverylist();
    getDemolitionChoiceslist();
    getKitchenlist();
    getMarketlibitylist();
    getSeismicZoneslist();
    getMaintenenceLevellist();
    getGOVPVTRoadChoiceServices();
    getapproachRoadTypeChoiceService();
    getZoningChoicesList();
    getTypePropertyChoicesList();
    getLandHoldingList();
    getIdentifiedThroughList();
    getMCGPLimitChoiceList();
    getPortionWitnessChoiceList();
    getTypeOfStructureList();
    getapproachRoadTypeChoiceServiceList();
    getLocalityTypeChoiceList();
    getDevelopedByChoiceList();
    getyLawServiceList();
    fetchLegalDocumentType();
    getTechnicalDocumentServiceList();
    getPersonalData();
    getBeforeYearsData();
    getTypePropertyDocument();
    getPincodeList();
    getAllState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (date, dateString) => { };

  const toggleBasicDetailVisibility = () => {
    setIsBasicDetailVisible(!isBasicDetailVisible);
  };

  const toggleValuationContent = () => {
    setIsToggleValuationContent(!isToggleValuationContent);
  };

  const toggleGovtValue = () => {
    setIsGovtValue(!isGovtValue);
  };

  const toggleValuerCertification = () => {
    setIsValuerCertification(!isValuerCertification);
  };

  const togglePropertyAppraised = () => {
    setIsPropertyAppraised(!isPropertyAppraised);
  };

  const toggleIsPlanApproval = () => {
    setIsPlanApproval(!isPlanApproval);
  };

  const toggleIsDocumentProvided = () => {
    setIsDocumentsProvided(!isDocumentsProvided);
  };

  const toggleIsPropertyDocuments = () => {
    setIsPropertyDocuments(!isPropertyDocuments);
  };

  const toggleIsPropertySpecificRemark = () => {
    setIsPropertySpecificRemark(!isPropertySpecificRemark);
  };

  const toggleIsBoundariesDetails = () => {
    setIsBoundariesDetails(!isBoundariesDetails);
  };

  const fetchLegalDocumentType = async () => {
    try {
      const response = await listLegalDocumentTypeService();
      setLegalDocumentType(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleCollectionStatusChange = (value, key) => {
    setCollectionStatus((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handlePincodeDocumentChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.name === value);

    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, district, state, taluka, country } = response.data;
        form.setFieldsValue({
          document_city: city?.name || undefined,
          document_district: district?.name || undefined,
          document_state: state?.name || undefined,
          document_taluka: taluka?.name || undefined,
          document_country: country?.name || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handlePincodeSiteChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.name === value);

    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, district, state, taluka, country } = response.data;
        form.setFieldsValue({
          site_city: city?.name || undefined,
          site_district: district?.name || undefined,
          site_state: state?.name || undefined,
          site_taluka: taluka?.name || undefined,
          site_country: country?.name || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };


  const handleCompleteStage = async (stage_id, stage_code) => {
    try {
      const payload = {
        // loan_stage: stage_id,
        // loan_detail: loan_id,
        stage: "TECHINAL",
        stage_status: "VENDORCO",
        user: user_data?.id
      };
      const response = await stageToCompleteService(stage_id, payload);
      if ((response.status = 200 && response.success)) {
        message.success(`Technical stage completed successfully`);
      }
    } catch (error) {
      setLoading(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const columns1 = [
    {
      title: "Document Description",
      dataIndex: "document_description",
      key: "document_description",
    },
    {
      title: `Collection Status *`,
      dataIndex: "collection_status",
      key: "collection_status",
      width: 300,
      render: (_, record) => (
        <Form.Item
          name={`collection_status_${record.key}`}
          rules={[{ required: true, message: "Collection Status is required" }]}
        >
          <Select
            placeholder="Please Select"
            // className="technical_valuation_report_div_width_full"
            allowClear
            onChange={(value) =>
              handleCollectionStatusChange(value, record.key)
            }
          >
            {record.collection_status.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Document Approval Number *",
      dataIndex: "document_approval_number",
      key: "document_approval_number",
      width: 300,
      render: (_, record) => {
        // Clear the input value if collectionStatus is "No"
        if (collectionStatus[record.key] === "No") {
          form.setFieldsValue({
            [`document_number_${record.key}`]: "",
          });
        }

        return (
          <Form.Item
            name={`document_number_${record.key}`}
            rules={
              collectionStatus[record.key] === "No"
                ? [] // No rules if Collection Status is "No"
                : [
                  {
                    required: true,
                    message: "Document Approval Number is required",
                  },
                ]
            }
          >
            <Input
              placeholder="Please Enter Document Number"
              disabled={collectionStatus[record.key] === "No"} // Disable input if Collection Status is "No"
            />
          </Form.Item>
        );
      },
    },
  ];

  const data1 = technicaldocument.map((doc) => ({
    key: doc.id,
    document_description: doc.name,
    collection_status: ["Yes", "No"],
  }));
  const dataone = [
    {
      key: "1",
      area_type: "Plot Area/UDS",
      as_per_plan_deed_in_sqft: (
        <Form.Item name="ploat_as_per_plan" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              setPloatAsPerPlan(e.target.value);
              handleNumberFields(e, "ploat_as_per_plan");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      as_per_site_in_sqft: (
        <Form.Item name="ploat_as_per_site" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              setPloatAsPerSite(e.target.value);
              handleNumberFields(e, "ploat_as_per_site");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      deviation_in_percentage: (
        <Form.Item name="ploat_deviation">
          <Input
            disabled
            placeholder={isNaN(ploatDeviation) ? "" : ploatDeviation}
            value={ploatDeviation}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      rate_per_sqft: (
        <Form.Item name="ploat_rate" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              setRate(e.target.value);
              handleNumberFields(e, "ploat_rate");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      percentage_completed: (
        <Form.Item name="ploat_percentage_completed" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) =>
              handleNumberFields(e, "ploat_percentage_completed")
            }
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      percentage_recommended: (
        <Form.Item name="ploat_percentage_recommended" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              setPRecommended(e.target.value);
              handleNumberFields(e, "ploat_percentage_recommended");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      valuation_INR: (
        <Form.Item name="ploat_valuation">
          <Input disabled placeholder={valuation} value={valuation} className="valuation-input-field" />
        </Form.Item>
      ),
    },

    {
      key: "2",
      area_type: "Existing construction area",
      as_per_plan_deed_in_sqft: (
        <Form.Item name="eca_as_per_plan" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              setEPloatAsPerPlan(e.target.value);
              handleNumberFields(e, "eca_as_per_plan");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      as_per_site_in_sqft: (
        <Form.Item name="eca_as_per_site" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              setEPloatAsPerSite(e.target.value);
              handleNumberFields(e, "eca_as_per_site");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      deviation_in_percentage: (
        <Form.Item name="eca_deviation">
          <Input
            disabled
            placeholder={isNaN(eploatDeviation) ? "" : eploatDeviation}
            value={eploatDeviation}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      rate_per_sqft: (
        <Form.Item name="eca_rate" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              setErate(e.target.value);
              handleNumberFields(e, "eca_rate");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      percentage_completed: (
        <Form.Item name="eca_percentage_completed" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              handleNumberFields(e, "eca_percentage_completed");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      percentage_recommended: (
        <Form.Item name="eca_percentage_recommended" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              setERecommended(e.target.value);
              handleNumberFields(e, "eca_percentage_recommended");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      valuation_INR: (
        <Form.Item name="eca_valuation">
          <Input disabled value={evaluation} placeholder={evaluation} className="valuation-input-field" />
        </Form.Item>
      ),
    },
    {
      key: "3",
      area_type:
        "Proposed Construction area (Under-construction properties updated here)",
      as_per_plan_deed_in_sqft: (
        <Form.Item
          name="pca_as_per_plan"
          rules={[yupSync]}
          onChange={(e) => {
            setPPloatAsPerPlan(e.target.value);
            handleNumberFields(e, "pca_as_per_plan");
          }}

        >
          <Input placeholder="" className="valuation-input-field" />
        </Form.Item>
      ),
      as_per_site_in_sqft: (
        <Form.Item
          name="pca_as_per_site"
          rules={[yupSync]}
          onChange={(e) => {
            setPPloatAsPerSite(e.target.value);
            handleNumberFields(e, "pca_as_per_site");
          }}
        >
          <Input placeholder="" className="valuation-input-field" />
        </Form.Item>
      ),
      deviation_in_percentage: (
        <Form.Item name="pca_deviation">
          <Input
            disabled
            placeholder={isNaN(pploatDeviation) ? "" : pploatDeviation}
            value={pploatDeviation}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      rate_per_sqft: (
        <Form.Item name="pca_rate" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              setPcrate(e.target.value);
              handleNumberFields(e, "pca_rate");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      percentage_completed: (
        <Form.Item name="pca_percentage_completed" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              handleNumberFields(e, "pca_percentage_completed");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      percentage_recommended: (
        <Form.Item name="pca_percentage_recommended" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              setPCRecommended(e.target.value);
              handleNumberFields(e, "pca_percentage_recommended");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      valuation_INR: (
        <Form.Item name="pca_valuation">
          <Input disabled value={pvaluation} placeholder={pvaluation} className="valuation-input-field" />
        </Form.Item>
      ),
    },
    {
      key: "4",
      area_type: "Amenities",
      as_per_plan_deed_in_sqft: (
        <Form.Item name="amenities_as_per_plan" rules={[yupSync]}>
          <Input
            className="technical_valuation_report_amentites valuation-input-field"
            onChange={(e) => {
              setAPloatAsPerSite(e.target.value);
              handleNumberFields(e, "amenities_as_per_plan");
            }}
            placeholder=""

          />
        </Form.Item>
      ),
      percentage_completed: (
        <Form.Item name="amenities_percentage_completed" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              handleNumberFields(e, "amenities_percentage_completed");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),
      percentage_recommended: (
        <Form.Item name="amenities_percentage_recommended" rules={[yupSync]}>
          <Input
            placeholder=""
            onChange={(e) => {
              setARecommended(e.target.value);
              handleNumberFields(e, "amenities_percentage_recommended");
            }}
            className="valuation-input-field"
          />
        </Form.Item>
      ),

      valuation_INR: (
        <Form.Item name="amenities_valuation">
          <Input disabled placeholder={avaluation} value={avaluation} className="valuation-input-field" />
        </Form.Item>
      ),
    },
    {
      key: "5",
      area_type: "Fair Market Value (INR)",
      valuation_INR: <Input disabled placeholder={fmv} className="valuation-input-field" />,
    },
    {
      key: "6",
      area_type: "Total Property value on 100% completion (INR)",
      valuation_INR: (
        <Form.Item name="total_market_value">
          <Input disabled value={tpc} placeholder={tpc} className="valuation-input-field" />
        </Form.Item>
      ),
    },
    {
      key: "7",
      area_type: "Forced sale Value(INR) at 75%",
      valuation_INR: <Input disabled placeholder={fsv} className="valuation-input-field" />,
    },
  ];

  const columnsone = [
    {
      title: <Tooltip title="Area Type">Area Type</Tooltip>,
      dataIndex: "area_type",
      width: 480,
      ellipsis: true,
      className: "word-wrap",
    },
    {
      title: (
        <Tooltip title="As per Plan/deed (in sqft)">
          As per Plan/deed (in sqft)
        </Tooltip>
      ),
      dataIndex: "as_per_plan_deed_in_sqft",
    },
    {
      title: (
        <Tooltip title="As per Site (In sqft)">As per Site (In sqft)</Tooltip>
      ),
      dataIndex: "as_per_site_in_sqft",
    },
    {
      title: <Tooltip title="Deviation in %">Deviation in %</Tooltip>,
      dataIndex: "deviation_in_percentage",
    },
    {
      title: <Tooltip title="Rate / Sqft">Rate / Sqft</Tooltip>,
      dataIndex: "rate_per_sqft",
    },
    {
      title: <Tooltip title="Completed %">Completed %</Tooltip>,
      dataIndex: "percentage_completed",
      key: "percentage_completed",
    },
    {
      title: <Tooltip title="Recommended %">Recommended %</Tooltip>,
      dataIndex: "percentage_recommended",
      key: "percentage_recommended",
    },
    {
      title: <Tooltip title="Valuation INR">Valuation INR</Tooltip>,
      dataIndex: "valuation_INR",
      key: "valuation_INR",
    },
  ];

  const handleDocumentNameChange = (value, option) => {

    const updatedDocumentNames = fileList.map((file, index) =>
      index === option.index ? value : documentNames[index]
    );


    setDocumentNames(updatedDocumentNames);
    setUploadDisabled(false);
  };

  const handleUploadChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    const newImages = newFileList.map((file) => ({
      file_path: URL.createObjectURL(file.originFileObj || file),
      uid: file.uid,
      name: file.name,
      file_type: file.type,
    }));
    setImages(newImages);
    form.setFieldsValue({
      upload_documents: null,
    });
    setUploadDisabled(true);
  };

  const handleBeforeUpload = (file) => {
    const newImage = {
      file_path: URL.createObjectURL(file),
      uid: file.uid,
      name: file.name,
      file_type: file.type,
    };
    setImages((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    setDocumentNames((prevNames) => [
      ...prevNames,
      form.getFieldValue("upload_documents"),
    ]);
    return false; // Prevent automatic upload
  };

  const handleRemove = (file) => {
    URL.revokeObjectURL(file.originFileObj ? file.originFileObj : file);

    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);

    const updatedImages = updatedFileList.map((file) => ({
      file_path: URL.createObjectURL(file.originFileObj || file),
      uid: file.uid,
      name: file.name,
    }));
    setImages(updatedImages);

    const updatedDocumentNames = documentNames.filter(
      (_, index) => fileList[index].uid !== file.uid
    );
    setDocumentNames(updatedDocumentNames);

    if (updatedFileList.length === 0) {
      setUploadDisabled(false);
    }
  };

  const confirmDelete = () => {
    URL.revokeObjectURL(deleteUploadPicture.file_path);

    setImages(images.filter((image) => image.uid !== deleteUploadPicture.uid));
    setFileList(
      fileList.filter((file) => file.uid !== deleteUploadPicture.uid)
    );
    setDocumentNames(
      documentNames.filter(
        (_, index) => images[index].uid !== deleteUploadPicture.uid
      )
    );
    setOpenUploadModal(false);
    setDeleteUploadPicture(null);
  };

  useEffect(() => {
    if (sameAsDocument) {
      const values = form.getFieldsValue();
      form.setFieldsValue({
        site_address1: values.document_address1,
        site_address2: values.document_address2,
        site_survey_number: values.document_survey_number,
        site_plot_number: values.document_plot_number,
        site_state: values.document_state,
        site_house_number: values.document_house_number,
        site_city: values.document_city,
        site_pincode: values.document_pincode,
        site_taluka: values.document_taluka,
        site_district: values.document_district,
        site_country: values.document_country,
      });
    }
  }, [sameAsDocument, form]);

  // const sanitizedRemark = remark?.replace(/<p>/g, "")?.replace(/<\/p>/g, "");
  // useEffect(() => {
  //   if (sameAsDocument) {
  //     form.resetFields(); // Reset all form fields
  //   }
  // }, [sameAsDocument, form]);

  useEffect(() => {
    if (!sameAsDocument) {
      // Clear only the fields within the specific div when checkbox is unchecked
      form.setFieldsValue({
        site_address1: '',
        site_address2: '',
        site_survey_number: '',
        site_plot_number: '',
        site_state: undefined,
        site_house_number: '',
        site_city: undefined,
        site_pincode: undefined,
        site_taluka: undefined,
        site_district: undefined,
        site_country: undefined,
      });
    }
  }, [sameAsDocument, form]);

  const handleNumberFields = (e, field) => {
    // Get the value from the input
    let newValue = e.target.value;

    // Remove all characters except digits and decimal points
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Ensure that only one decimal point is allowed
    const decimalCount = (newValue.match(/\./g) || []).length;
    if (decimalCount > 1) {
      newValue = newValue.replace(/\.(?=.*\.)/g, ""); // Remove all but the last decimal point
    }

    // Ensure the value does not exceed 10 characters (including decimal point)
    if (newValue.length > 10) {
      // Split value by decimal point
      const [integerPart, decimalPart] = newValue.split(".");

      // Truncate integer and decimal parts if necessary
      const truncatedIntegerPart = integerPart.slice(0, 10);
      const truncatedDecimalPart = decimalPart
        ? decimalPart.slice(0, 10 - truncatedIntegerPart.length)
        : "";

      newValue = truncatedDecimalPart
        ? `${truncatedIntegerPart}.${truncatedDecimalPart}`
        : truncatedIntegerPart;
    }

    // Set the formatted value in the form
    form.setFieldsValue({ [field]: newValue });
  };

  const handleCharacterFields = (e, field) => {
    // Get the value from the input
    let newValue = e.target.value;

    // Remove all characters except digits, alphabetic characters, and decimal points
    newValue = newValue.replace(/[^0-9a-zA-Z. ]/g, "");

    // Ensure that only one decimal point is allowed
    const decimalCount = (newValue.match(/\./g) || []).length;
    if (decimalCount > 1) {
      newValue = newValue.replace(/\.(?=.*\.)/g, ""); // Remove all but the last decimal point
    }

    // Set the formatted value in the form
    form.setFieldsValue({ [field]: newValue });
  };
  const handleCharacterSpaceFields = (e, field) => {
    // Get the value from the input
    let newValue = e.target.value;

    // Remove all characters except digits, alphabetic characters, and decimal points
    newValue = newValue.replace(/[^0-9a-zA-Z. ]/g, "");

    // Ensure that only one decimal point is allowed
    const decimalCount = (newValue.match(/\./g) || []).length;
    if (decimalCount > 1) {
      newValue = newValue.replace(/\.(?=.*\.)/g, ""); // Remove all but the last decimal point
    }

    // Set the formatted value in the form
    form.setFieldsValue({ [field]: newValue });
  };

  const handleAlphaFields = (e, field) => {
    // Get the value from the input
    let newValue = e.target.value;

    // Remove all characters except alphabetic characters and blank spaces
    newValue = newValue.replace(/[^a-zA-Z\s]/g, "");

    // Set the formatted value in the form
    form.setFieldsValue({ [field]: newValue });
  };

  const handleContactNumberFields = (e, field) => {
    // Get the value from the input
    let newValue = e.target.value;

    // Remove all characters except digits
    newValue = newValue.replace(/[^0-9]/g, "");

    // Ensure that the value does not exceed 10 digits
    if (newValue.length > 10) {
      newValue = newValue.slice(0, 10);
    }

    // Set the formatted value in the form
    form.setFieldsValue({ [field]: newValue });
  };

  return (
    <div>
      <Modal
        title="Confirm Completion"
        open={confirmModalVisible}
        onOk={handleConfirmOk}
        onCancel={() => setConfirmModalVisible(false)}
        okType="danger"
        okButtonProps={{ loading }}
      >
        <p>{`Are you sure you want to complete the stage?`}</p>
      </Modal>
      <div>
        <div className="main_technical_report">
          <div>
            <p className="loan_summary_technical_valuation_report">
              Technical Valuation Report
            </p>
          </div>
        </div>
        <LoanSummaryBlock
          data={creditAssessmentStageData}
          attachments={
            creditAssessmentStageData?.initiate_stage_attachment_data
          }
          typeCheck={typeCheck}
        />
        <Form
          layout="vertical"
          className="technical_valuation_report_form"
          form={form}
          onValuesChange={(changedValues) => {
            if (changedValues.boundaries_matching === true) {
              form.setFieldsValue({ boundary_mismatch_remark: undefined }); // Clear the "Mismatch Remarks" field
            }
          }}
          initialValues={{
            property_address: "",
            loan_account_no: "",
            product: "",
            owner_name: "",
            land_value: "",
          }}
          onFinish={handleSubmit}
        >
          <div className="technical_input_form_main_container_block">
            <div className="container_technical">
              <div className="valuation_details">
                <div className="valuation_details_blue">Property Details</div>
                <div>
                  <UpOutlined
                    className={`up-outlined ${isBasicDetailVisible ? "" : "rotate"
                      }`}
                    onClick={toggleBasicDetailVisibility}
                  />
                </div>
              </div>
            </div>
            {isBasicDetailVisible && (
              <div className="technical_property_container">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item name="address" label="Property Address">
                      <TextArea
                        rows={4}
                        placeholder="Please Enter Property Address"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="loan_account_number"
                      label="Loan Account No"
                    >
                      <Input
                        placeholder="Please Enter Loan Account No"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item name="product" label="Product">
                      <Input placeholder="Please Enter Product" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="current_owner_name"
                      label="Name of Current Owner / Seller"
                      rules={[yupSync]}
                      required
                      onChange={(e) =>
                        handleAlphaFields(e, "current_owner_name")
                      }
                    >
                      <Input placeholder="Please Enter Name of Current Owner" />
                    </Form.Item>
                  </Col>
                  {/* </Row>
                <Row gutter={16}> */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="date_of_inspection"
                      label="Date of Inspection"
                      rules={[yupSync]}
                      required
                    >
                      <DatePicker
                        onChange={onChange}
                        placeholder="Select Date"
                        className="technical_valuation_report_date_width_full"
                        disabledDate={(current) =>
                          current && current > dayjs().endOf("day")
                        }
                        format="DD-MM-YYYY" // Set the date format
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="mobile"
                      label="Contact Number Of Customer"
                      rules={[yupSync]}
                      required
                      onChange={(e) => handleContactNumberFields(e, "mobile")}
                    >
                      <Input placeholder="Please Enter Contact Number Of Customer" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          <div className="technical_input_form_main_container_block">
            <div className="container_technical">
              <div className="valuation_details_blue_heading">
                <div className="tachnical_table">
                  <div className="valuation_details_blue">
                    Valuation Of Property ( Fair Market Valuation /Distress
                    Valuation)
                  </div>
                  <div></div>
                  <div className="technical_button_container">
                    <div className="red_text">Convert Yards to Sq. Feet</div>
                    <UpOutlined
                      className={`up-outlined ${isBasicDetailVisible ? "" : "rotate"
                        }`}
                      onClick={toggleValuationContent}
                    />
                  </div>
                </div>
              </div>
            </div>
            {isToggleValuationContent && (
              <Table
                columns={columnsone}
                dataSource={dataone}
                pagination={false}
                size="middle"
                scroll={{ x: 'max-content' }}
                responsive={true}
                style={{ width: '100%' }}
              />

            )}
          </div>
          <div className="technical_input_form_main_container_block">
            <div className="container_technical">
              <div className="valuation_details_blue_heading">
                <div className="tachnical_table">
                  <div className="valuation_details_blue">
                    As Per Govt. Value Of Property
                  </div>
                  <UpOutlined
                    className={`up-outlined ${isGovtValue ? "default" : "rotate"
                      }`}
                    onClick={toggleGovtValue}
                  />
                </div>
              </div>
            </div>
            {isGovtValue && (
              <div className="technical_property_container">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      label={
                        <span>
                          Government guideline/circle rate for land (₹ Per
                          Sq.ft)
                        </span>
                      }
                      required
                    >
                      <Input.Group compact className="government-property-tab government-property-tab">
                        <Form.Item noStyle>
                          <Input
                            className="technical_valuation_report_rate_land"
                            disabled
                            defaultValue="₹"
                          />
                        </Form.Item>
                        <Form.Item
                          name="land_value"
                          noStyle
                          rules={[yupSync]}
                          style={{ border: "1px solid red" }}
                        >
                          <Input
                            className="technical_valuation_report_Value_of_property_field"
                            placeholder="Please Enter Government guideline/circle rate for land"
                            onChange={(e) =>
                              handleNumberFields(e, "land_value")
                            }
                          />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      label={
                        <span>
                          Government guideline/circle rate for building (₹ Per
                          Sq.ft)
                        </span>
                      }
                      required
                    >
                      <Input.Group compact >
                        <Form.Item noStyle>
                          <Input
                            className="technical_valuation_report_rate_land"
                            disabled
                            defaultValue="₹"
                          />
                        </Form.Item>
                        <Form.Item
                          name="government_rate_for_bldg"
                          noStyle
                          rules={[yupSync]}
                        >
                          <Input
                            className="technical_valuation_report_Value_of_property_field"
                            placeholder="Please Enter Government guideline/circle rate for building"
                            onChange={(e) =>
                              handleNumberFields(e, "government_rate_for_bldg")
                            }
                          />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      label={<span>Land value as per govt rate</span>}
                      required
                    >
                      <Input.Group compact className="government-property-tab government-property-tab-mid government-property-tab
                   
                      ">
                        <Form.Item noStyle>
                          <Input
                            className="technical_valuation_report_rate_land "
                            disabled
                            defaultValue="₹"
                          />
                        </Form.Item>
                        <Form.Item

                          name="land_value_government"
                          rules={[yupSync]}
                          noStyle
                        >
                          <Input
                            className="technical_valuation_report_Value_of_property_field technical_valuation_report_Value_of_property_amount "
                            placeholder="Please Enter Land value as per govt rate"
                            onChange={(e) =>
                              handleNumberFields(e, "land_value_government")
                            }
                          />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      label={
                        <span>
                          Flat/apartment value as per government rate (₹)
                        </span>
                      }
                      className="technical_report_field_box "
                      required
                    >
                      <Input.Group compact >
                        <Form.Item noStyle>
                          <Input
                            className="technical_valuation_report_rate_land "
                            disabled
                            defaultValue="₹"
                          />
                        </Form.Item>
                        <Form.Item
                          name="apt_value_gov"
                          noStyle
                          rules={[yupSync]}
                        >
                          <Input
                            className="technical_valuation_report_Value_of_property_field"
                            placeholder="Please Enter Flat/apartment value as per government rate (₹)"
                            onChange={(e) =>
                              handleNumberFields(e, "apt_value_gov")
                            }
                          />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      label={
                        <span>
                          Total valuation of property as per government rate
                        </span>
                      }
                      required
                    >
                      <Input.Group compact>
                        <Form.Item noStyle>
                          <Input
                            className="technical_valuation_report_rate_land"
                            disabled
                            defaultValue="₹"
                          />
                        </Form.Item>
                        <Form.Item
                          name="total_valuation_goverment"
                          rules={[yupSync]}
                          noStyle
                        >
                          <Input
                            className="technical_valuation_report_Value_of_property_field"
                            placeholder="Please Enter Total valuation as per government rate (₹)"
                            onChange={(e) =>
                              handleNumberFields(e, "total_valuation_goverment")
                            }
                          />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="valuation_remark"
                      label="Remarks                                   "
                      rules={[yupSync]}

                      required
                    >
                      <Input
                        className="technical_valuation_report_Value_of_property_amount  technical_valuation_report_field_set  "
                        placeholder="Please Enter Remarks"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          <div className="technical_input_form_main_container_block">
            <div className="container_technical">
              <div className="valuation_details_blue_heading">
                <div className="tachnical_table">
                  <div className="valuation_details_blue">
                    Valuer Certification
                  </div>
                  <UpOutlined
                    className={`up-outlined ${isValuerCertification ? "default" : "rotate"
                      }`}
                    onClick={toggleValuerCertification}
                  />
                </div>
              </div>
            </div>
            {isValuerCertification && (
              <div className="technical_property_container">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="date_of_visit"
                      label="Date of Visit"
                      rules={[yupSync]}
                      required
                    >
                      <DatePicker
                        placeholder="Select Date Of Visit"
                        // onChange={onChange}
                        disabledDate={(current) =>
                          current && current > dayjs().endOf("day")
                        }
                        className="technical_valuation_report_date_width_full"
                        format="DD-MM-YYYY"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="date_of_valuation"
                      label="Date of report submission"
                      rules={[yupSync]}
                      required
                    >
                      <DatePicker
                        placeholder="Select Date Of Valuation"
                        // onChange={onChange}
                        disabledDate={(current) =>
                          current && current > dayjs().endOf("day")
                        }
                        className="technical_valuation_report_date_width_full"
                        format="DD-MM-YYYY"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="engineer_name"
                      label="Name of Engineer Visited the property"
                      rules={[yupSync]}
                      required
                    >
                      <Input
                        placeholder="Please Enter Name of Engineer Visited the property"
                        onChange={(e) => handleAlphaFields(e, "engineer_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="place"
                      label="Place"
                      rules={[yupSync]}
                      required
                    >
                      <Input
                        placeholder="Please Enter Place"
                        onChange={(e) => handleAlphaFields(e, "place")}
                        className="valuer-certification-medium valuer-certification-extrasmall"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="declaration"
                      label="Declaration"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                "You must accept the declaration"
                              ),
                        },
                      ]}
                      required
                    >
                      <Checkbox>
                        I Hereby Declare that I Have visited this property. I
                        Have no Direct or indirect interest in the Property
                        Valued. The Information in this Report is True and
                        Correct to the best of my Knowledge and Belief.
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </div>

          <div className="technical_input_form_main_container_block">
            <div className="container_technical">
              <div className="valuation_details_blue_heading">
                <div className="tachnical_table">
                  <div className="valuation_details_blue">
                    Address Of Property Being Appraised
                  </div>
                  <UpOutlined
                    className={`up-outlined ${isPropertyAppraised ? "default" : "rotate"
                      }`}
                    onClick={togglePropertyAppraised}
                  />
                </div>
              </div>
            </div>
            {isPropertyAppraised && (
              <div>
                <div className="container_technical">
                  <div className="valuation_details technical_report_address_heading_gray">
                    <div className="valuation_details_blue address_text  technical_report_address_heading_gray_text">
                      <div className="address_top_heading">
                        <div className="address_heading">
                          Address as per document:{" "}
                        </div>
                      </div>
                      <div className="technical_report_address_heading_red_text">
                        * Any textbox should contain only A-Z 0-9 / , - . # ( )
                      </div>
                    </div>
                  </div>

                  <div className="technical_property_container">
                    <div className="highlighted_red">
                      Plot No. and House No./Flat No. is used for cersai.
                    </div>

                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item
                          name="document_address1"
                          label="Address Line 1"
                          rules={[yupSync]}
                          required
                        >
                          <Input placeholder="Please Enter Address Line 1" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item
                          name="document_address2"
                          label="Address Line 2"
                          rules={[yupSync]}
                          required
                        >
                          <Input placeholder="Please Enter Address Line 1" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item
                          name="document_survey_number"
                          label="Survey No."
                          rules={[yupSync]}
                          required
                        >
                          <Input placeholder="Please Enter Survey No." />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item
                          name="document_plot_number"
                          label="Plot No."
                          rules={[yupSync]}
                          required
                        >
                          <Input placeholder="Please Enter Plot No." />
                        </Form.Item>
                      </Col>
                      {/* </Row>

                    <Row gutter={16}> */}
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item
                          name="document_house_number"
                          label="House No./ Flat No"
                          rules={[yupSync]}
                          required
                        >
                          <Input placeholder="Please Enter House No./ Flat No." />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item
                          name="document_pincode"
                          label="Pincode"
                          rules={[yupSync]}
                          required
                        >
                          <Select
                            mode="single"
                            placeholder="Please Select Pincode"
                            required={true}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handlePincodeDocumentChange}
                          >
                            {pincode?.map((pincode) => {
                              return (
                                <Select.Option
                                  key={pincode.id}
                                  value={pincode.name}
                                >
                                  {pincode.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item
                          name="document_city"
                          label="City"
                          required
                          rules={[yupSync]}
                        >
                          <Input
                            placeholder="Please Enter City"
                            onChange={(e) =>
                              handletextInput(e, "document_city")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item
                          name="document_taluka"
                          label="Taluka"
                          rules={[yupSync]}
                          required
                        >
                          <Input
                            placeholder="Please Enter Taluka"
                            onChange={(e) =>
                              handletextInput(e, "document_taluka")
                            }
                          />
                        </Form.Item>
                      </Col>
                      {/* </Row>
                    <Row gutter={16}> */}
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item
                          name="document_district"
                          label="District"
                          rules={[yupSync]}
                          required
                        >
                          <Input
                            placeholder="Please Enter District"
                            onChange={(e) =>
                              handletextInput(e, "document_district")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item
                          name="document_state"
                          label="State"
                          rules={[yupSync]}
                          required
                        >
                          <Select
                            mode="single"
                            placeholder="Please Select State"
                            required={true}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {state?.map((state) => {
                              return (
                                <Select.Option key={state.id} value={state.id}>
                                  {state.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item
                          name="document_country"
                          label="Country"
                          required
                          rules={[yupSync]}
                        >
                          <Input
                            placeholder="Please Enter Country"
                            onChange={(e) =>
                              handletextInput(e, "document_country")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="valuation_details_blue_heading technical_report_address_heading_gray  technical_report_address_distance technical_report_address_heading_gray_primary">
                  <div className="valuation_details_blue address_text technical_report_address_heading_gray_text">
                    <div>
                      Address As per Actual at site:
                      <Checkbox
                        className="technical_report_address_checkbox"
                        checked={sameAsDocument}
                        onChange={(e) => setSameAsDocument(e.target.checked)}
                      >
                        Same as per document
                      </Checkbox>
                    </div>

                    <div className="technical_report_address_heading_red_text">
                      * Any textbox should contain only A-Z 0-9 / , - . # ( )
                    </div>
                  </div>
                </div>
                <div className="technical_property_container">
                  <div className="highlighted_red">
                    Plot No. and House No./Flat No. is used for cersai.
                  </div>

                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="site_address1"
                        label="Address Line 1"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Address Line 1"
                          disabled={sameAsDocument}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="site_address2"
                        label="Address Line 2"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Address Line 2"
                          disabled={sameAsDocument}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="site_survey_number"
                        label="Survey No."
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Survey No."
                          disabled={sameAsDocument}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="site_plot_number"
                        label="Plot No. "
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Plot No."
                          disabled={sameAsDocument}
                        />
                      </Form.Item>
                    </Col>
                    {/* </Row>

                  <Row gutter={16}> */}
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="site_house_number"
                        label="House No./ Flat No"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter House No./ Flat No."
                          disabled={sameAsDocument}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="site_pincode"
                        label="Pincode"
                        rules={[yupSync]}
                        required
                      >
                        <Select
                          mode="single"
                          disabled={sameAsDocument}
                          placeholder="Please Select Pincode"
                          required={true}
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={handlePincodeSiteChange}
                        >
                          {pincode?.map((pincode) => {
                            return (
                              <Select.Option
                                key={pincode.id}
                                value={pincode.name}
                              >
                                {pincode.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="site_city"
                        label="City"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter City"
                          onChange={(e) => handletextInput(e, "site_city")}
                          disabled={sameAsDocument}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="site_taluka"
                        label="Taluka"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Taluka"
                          onChange={(e) => handletextInput(e, "site_taluka")}
                          disabled={sameAsDocument}
                        />
                      </Form.Item>
                    </Col>
                    {/* </Row>
                  <Row gutter={16}> */}
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="site_district"
                        label="District"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter District"
                          onChange={(e) => handletextInput(e, "site_district")}
                          disabled={sameAsDocument}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="site_state"
                        label="State"
                        rules={[yupSync]}
                        required
                      >
                        <Select
                          mode="single"
                          placeholder="Please Select State"
                          required={true}
                          allowClear
                          showSearch
                          disabled={sameAsDocument}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {state?.map((state) => {
                            return (
                              <Select.Option key={state.id} value={state.id}>
                                {state.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="site_country"
                        label="Country"
                        required
                        rules={[yupSync]}
                      >
                        <Input
                          placeholder="Please Enter Country"
                          onChange={(e) =>
                            handletextInput(e, "site_country")
                          }
                          disabled={sameAsDocument}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="valuation_details technical_report_address_heading_gray technical_report_address_distance">
                  <div className="valuation_details_blue address_text">
                    Remarks :
                  </div>
                </div>
                <div className="technical_property_container">
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="address_matching"
                        label="Address Matching?"
                        rules={[yupSync]}
                        required
                      >
                        <Select
                          placeholder="Please Select"
                          rules={[yupSync]}
                          className=""
                          allowClear
                        >
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="latitude"
                        label="Latitude"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Latitude"
                          onChange={(e) => handleNumberFields(e, "latitude")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="longitude"
                        label="Longitude"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Longitude"
                          onChange={(e) => handleNumberFields(e, "longitude")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
          <div className="technical_input_form_main_container_block">
            <div className="container_technical">
              <div className="technical_boundriers_details_blue_heading">
                <div className="valuation_details_blue">Boundaries Detail</div>
                <div>
                  <UpOutlined
                    className={`up-outlined ${isPropertyDocuments ? "default" : "rotate"
                      }`}
                    onClick={toggleIsBoundariesDetails}
                  />
                </div>
              </div>
            </div>
            {isBoundariesDetails && (
              <div>
                <div className="container_technical">
                  <div className="valuation_details  technical_report_address_heading_gray">
                    <div className="valuation_details_blue address_text">
                      As per Document
                    </div>
                  </div>
                </div>
                <div className="technical_property_container">
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="document_north"
                        label="North"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Address to the North"
                          onChange={(e) =>
                            handleCharacterFields(e, "document_north")
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="document_south"
                        label="South"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Address to the South"
                          onChange={(e) =>
                            handleCharacterFields(e, "document_south")
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="document_east"
                        label="East"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Address to the East"
                          onChange={(e) =>
                            handleCharacterFields(e, "document_east")
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="document_west"
                        label="West"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Address to the West"
                          onChange={(e) =>
                            handleCharacterFields(e, "document_west")
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="valuation_details technical_report_address_heading_gray">
                  <div className="valuation_details_blue address_text">
                    As per Site/Actual
                  </div>
                </div>
                <div className="technical_property_container">
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="actual_north"
                        label="North"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Address to the North"
                          onChange={(e) =>
                            handleCharacterFields(e, "actual_north")
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="actual_south"
                        label="South"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Address to the South"
                          onChange={(e) =>
                            handleCharacterFields(e, "actual_south")
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="actual_east"
                        label="East"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Address to the East"
                          onChange={(e) =>
                            handleCharacterFields(e, "actual_east")
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="actual_west"
                        label="West"
                        rules={[yupSync]}
                        required
                      >
                        <Input
                          placeholder="Please Enter Address to the West"
                          onChange={(e) =>
                            handleCharacterFields(e, "actual_west")
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="valuation_details technical_report_address_heading_gray">
                  <div className="valuation_details_blue address_text">
                    Remarks
                  </div>
                </div>

                <div className="technical_property_container">
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                      <Form.Item
                        name="boundaries_matching"
                        label="Boundries Matching?"
                        required
                        rules={[yupSync]}
                      >
                        <Select
                          placeholder="Please Select"
                          className=""
                          allowClear
                          onChange={(value) => setMismatch(value)}
                        >
                          <Option value={true}>Yes</Option>
                          <Option value={false}>No</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {mismatch === false ? (
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item
                          name="boundary_mismatch_remark"
                          label="Mismatch Remarks"
                          required
                          rules={[yupSync]}
                        >
                          <Input placeholder="Please Enter Mismatch Remarks" />
                        </Form.Item>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>
              </div>
            )}
          </div>
          <div className="technical_input_form_main_container_block">
            <div className="container_technical">
              <div className="technical_boundriers_details_blue_heading technical_boundriers_details_blue_heading_primary">
                <div className="valuation_details_blue">
                  Location & Property Specific Details (Based On Site Visit)
                </div>
                <div>
                  <UpOutlined
                    className={`up-outlined ${isPropertyDocuments ? "default" : "rotate"
                      }`}
                    onClick={toggleIsPropertyDocuments}
                  />
                </div>
              </div>
            </div>
            {isPropertyDocuments && (
              <div className="technical_property_container">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="status_land_holding"
                      label="Status Land Holding"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {landholding?.map((landholdings, index) => (
                          <Option
                            key={landholdings.label}
                            value={landholdings.label}
                          >
                            {landholdings.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="demarcation"
                      label="Plot Demacration Available"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="developed_by"
                      label="Layout developed by"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {developedBy?.map((type, index) => (
                          <Option key={type.id} value={type.id}>
                            {type.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="type_of_property_document"
                      label="Type of property as per document"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {sitedocument?.map((type, index) => (
                          <Option key={type.id} value={type.value}>
                            {type.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="type_of_property_site"
                      label="Type of property at site"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {types?.map((type, index) => (
                          <Option key={type.label} value={type.value}>
                            {type.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="zoning"
                      label="Location/Zoning as per master plan"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {zones?.map((zone, index) => (
                          <Option key={zone.label} value={zone.label}>
                            {zone.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="development_vicinity"
                      label="Development of the vicinity in % "
                      required
                      rules={[yupSync]}
                      onChange={(e) =>
                        handleNumberFields(e, "development_vicinity")
                      }
                    >
                      <Input placeholder="Please Enter Development Vicinity" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="approch_road_type"
                      label="Approach road type"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {approachroadtype?.map((approachroadtype, index) => (
                          <Option
                            key={approachroadtype.label}
                            value={approachroadtype.value}
                          >
                            {approachroadtype.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* </Row>

                <Row gutter={16}> */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="approch_road_width"
                      label="Approach road width(in feet)"
                      required
                      rules={[yupSync]}
                    >
                      <Input
                        placeholder="Please Enter Approach Road Width"
                        onChange={(e) =>
                          handleNumberFields(e, "approch_road_width")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="govt_private_road"
                      label="Govt road or Private passage"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {gov?.map((govs, index) => (
                          <Option key={govs.label} value={govs.label}>
                            {govs.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="mc_gp_limit"
                      label="Within Corporation"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please enter layout developed by"
                        className=""
                        allowClear
                      >
                        {limitChoice?.map((limit, index) => (
                          <Option key={limit.label} value={limit.value}>
                            {limit.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="identified_through"
                      label="Identified through
"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {identified?.map((identify, index) => (
                          <Option key={identify.label} value={identify.value}>
                            {identify.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* </Row>
                <Row gutter={16}> */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="met_person_name"
                      label="Person met at site"
                      required
                      rules={[yupSync]}
                    >
                      <Input
                        placeholder="Please Enter Person Met At Site"
                        onChange={(e) =>
                          handleAlphaFields(e, "met_person_name")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="type_of_structure"
                      label="Type Of Roof"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {structures?.map((structure, index) => (
                          <Option key={structure.label} value={structure.value}>
                            {structure.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="occupation_status"
                      label="Occupancy status"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                        onChange={(value) => {
                          setOccupancyStatus(value);
                          if (value === "No") {
                            // Reset the `occupied_since` field when OccupancyStatus is "no"
                            form.resetFields(["occupied_since"]);
                          }
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="occupied_since"
                      label="Occupied since (in years)"
                      required={OccupancyStatus === "Yes"}
                      rules={OccupancyStatus === "Yes" ? [yupSync] : []}
                    >
                      <Input
                        placeholder="Please Enter Occupied since (in years)"
                        disabled={OccupancyStatus === "No"}
                        onChange={(e) =>
                          handleNumberFields(e, "occupied_since")
                        }
                      />
                    </Form.Item>
                  </Col>
                  {/* </Row>

                <Row gutter={16}> */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="residual_life_of_property"
                      label="Residual life of property (in years)"
                      required
                      rules={[yupSync]}
                    >
                      <Input
                        placeholder="Please Enter Residual Life"
                        onChange={(e) =>
                          handleNumberFields(e, "residual_life_of_property")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="age_of_property"
                      label="Age of the property (in years)"
                      required
                      rules={[yupSync]}
                    >
                      <Input
                        placeholder="Please Enter Age of the property"
                        onChange={(e) =>
                          handleNumberFields(e, "age_of_property")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="basic_amenities"
                      label="Availability of basic amenities like"
                      rules={[yupSync]}
                      required
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        <Option value="0">Yes</Option>
                        <Option value="1">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="toilet"
                      label="Whether toilet built in house"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* </Row>

                <Row gutter={16}> */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="maintenance_level"
                      label="Maintenance level of building"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        allowClear
                      >
                        {maintenance?.map((maintenances, index) => (
                          <Option
                            key={maintenances.label}
                            value={maintenances.value}
                          >
                            {maintenances.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="structure_government"
                      label="Structure conforming to the guidelines as mentioned in NBC by Government of India:"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="situated_near_canal"
                      label="Property situated near nala/open"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=" technical_valuation_report_div_width_full "
                        allowClear
                        onChange={(value) => {
                          setPropertynearnala(value);
                          if (value === false) {
                            // Reset the `occupied_since` field when OccupancyStatus is "no"
                            form.resetFields(["distance_from_canal"]);
                          }
                        }}
                      >
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="distance_from_canal"
                      label="Distance from nala/open "
                      required={propertynearnala}
                      rules={propertynearnala ? [yupSync] : []}
                    >
                      <Input
                        placeholder="Please Enter Distance  "
                        className="technical_valuation_report_div_width_full "
                        onChange={(e) =>
                          handleNumberFields(e, "distance_from_canal")
                        }
                        disabled={propertynearnala === false}

                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="situated_near_ht_lines"
                      label="Property situated near high tension "
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                        onChange={(value) => {
                          setPropertynearhtl(value);
                          if (value === false) {
                            form.resetFields(["distance_from_ht_line"]);
                          }
                        }}
                      >
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="distance_from_ht_line"
                      label="Distance from high tension line if HT"
                      required={propertynearhtl}
                      rules={propertynearhtl ? [yupSync] : []}
                    >
                      <Input
                        placeholder="Please Enter  Distance  "
                        className=""
                        onChange={(e) =>
                          handleNumberFields(e, "distance_from_ht_line")
                        }
                        disabled={propertynearhtl === false}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="seismic_zone"
                      label="Seismic zone classification "
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {seismiczones?.map((seismiczone, index) => (
                          <Option
                            key={seismiczone.value}
                            value={seismiczone.value}
                          >
                            {seismiczone.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="locality_type"
                      label="Locality Type"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {localityType?.map((types, index) => (
                          <Option key={types.value} value={types.value}>
                            {types.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* </Row>

                <Row gutter={16}> */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="marketability"
                      label="Marketability"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className="technical_location_and_property technical-input-text-field technical_location_and_property_medium marketability-medium"
                        allowClear
                      >
                        {markebility?.map((markebilities, index) => (
                          <Option
                            key={markebilities.label}
                            value={markebilities.label}
                          >
                            {markebilities.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="any_hazard"
                      label="Any hazard in The event of earthquakes/ land slide /cyclone  "
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="market_feedback"
                      label="Market feedback (broker name and Broker number)"
                      required
                      rules={[yupSync]}
                    >
                      <Input
                        placeholder="Please Enter Market Feedback"
                        className="technical-input-text-field technical_location_and_property"
                        onChange={(e) =>
                          handleCharacterFields(e, "market_feedback")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="commercial_portion_witnessed"
                      label="% of commercial portion witnessed at the property"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className="technical_valuation_report_div_width_full technical-input-text-field technical_location_and_property"
                        allowClear
                      >
                        {portion?.map((portions, index) => (
                          <Option key={portions.label} value={portions.value}>
                            {portions.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="no_of_kitchen"
                      label="Number of Kitchens available in area wise"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {kitchen?.map((kitchens, index) => (
                          <Option key={kitchens.label} value={kitchens.value}>
                            {kitchens.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="risk_of_demolition"
                      label="Risk of demolition"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {demolition?.map((demolitions, index) => (
                          <Option key={index} value={demolitions.label}>
                            {demolitions.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="recent_demolition"
                      label="Any recent demolition done in this vicinity"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select Any Recent Demolition"
                        className=""
                        allowClear
                        onChange={(value) => {
                          setDemolitiondone(value);
                          if (value === false) {
                            form.resetFields(["before_years"]);
                          }
                        }}
                      >
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="before_years"
                      label="Before how many years of recent demolition"
                      required={demolitiondone}
                      rules={demolitiondone ? [yupSync] : []}
                    >
                      <Select
                        placeholder="Please Select Recent Demolition Years"
                        className=""
                        allowClear
                        disabled={demolitiondone === false}
                      >
                        {beforeyears?.map((year, index) => (
                          <Option key={index} value={year.value}>
                            {year.label}
                          </Option>
                        ))}
                      </Select>
                      {/* <Input placeholder="Please Enter Years Of Recent Demolition" /> */}
                    </Form.Item>
                  </Col>
                  {/* </Row>

                <Row gutter={16}> */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="property_affected"
                      label="Will the property be affected for road widening"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                        onChange={(value) => {
                          setPropertyaffectedroad(value);
                          if (value === false) {
                            form.resetFields(["road_widening"]);
                          }
                        }}
                      >
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="road_widening"
                      label="Road widening chances foreseen"
                      required
                      rules={propertyaffectedroad ? [yupSync] : []}
                    >
                      <Select
                        placeholder="Please Select"
                        className="technical_location_and_property"
                        allowClear
                        disabled={propertyaffectedroad === false}

                      >
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="property_negative_area"
                      label="Is the property in negative area"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className="technical_location_and_property"
                        allowClear
                      >
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="lift_available"
                      label="Is Lift Available?"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className="technical_location_and_property"
                        allowClear
                      >
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* </Row>

                <Row gutter={16}> */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="distance_from_branch"
                      label="Distance from branch(in KM)"
                      required
                      rules={[yupSync]}
                    >
                      <Input
                        placeholder="Please Enter Distance From Branch"
                        onChange={(e) =>
                          handleNumberFields(e, "distance_from_branch")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="house_delivery_agency"
                      label="House delivery agency"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {houseDelivery?.map((houseDelivery, index) => (
                          <Option
                            key={houseDelivery.value}
                            value={houseDelivery.value}
                          >
                            {houseDelivery.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </div>

          <div className="technical_input_form_main_container_block">
            <div className="container_technical">
              <div className="technical_boundriers_details_blue_heading">
                <div className="valuation_details_blue">Plan Approval</div>
                <UpOutlined
                  className={`up-outlined ${isPlanApproval ? "default" : "rotate"
                    }`}
                  onClick={toggleIsPlanApproval}
                />
              </div>
            </div>
            {isPlanApproval && (
              <div className="technical_property_container">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="as_per_approved"
                      label="Availability of Approved Plan"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        <Option value="1">Yes</Option>
                        <Option value="2">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="dcr_norms"
                      label="Is the plan complying DCR norms"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="floors_permitted"
                      label="Number of floors permitted"
                      rules={[yupSync]}
                      required
                    >
                      <Input
                        className="technical-input-text-field-plan-approval-primary technical-input-text-field-plan-approval-medium"
                        placeholder="Please Enter Number Of Floors Permitted"
                        onChange={(e) =>
                          handleNumberFields(e, "floors_permitted")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="floors_constructed"
                      label="Number of floors constructed"
                      rules={[yupSync]}
                      required
                    >
                      <Input
                        placeholder="Please Enter Number Of Floors Constructed"
                        onChange={(e) =>
                          handleNumberFields(e, "floors_constructed")
                        }
                        className="technical-input-text-field-plan-approval-primary technical-input-text-field-plan-approval-medium-primary"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="fsi_achieved"
                      label="FSI Achieved"
                      rules={[yupSync]}
                      required
                    >
                      <Input
                        placeholder="Please Enter FSI Achieved"
                        onChange={(e) => handleNumberFields(e, "fsi_achieved")}
                        className="technical-input-text-field-plan-approval technical-input-text-field-plan-approval-medium-primary"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="construction_permission_no"
                      label="Construction Permission Number"
                      rules={[yupSync]}
                      required
                    >
                      <Input
                        placeholder="Please Enter Construction Permission Number"
                        onChange={(e) =>
                          handleCharacterFields(e, "construction_permission_no")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="local_byelaws"
                      label="Designation Of Authority"
                      rules={[yupSync]}
                      required
                    >
                      <Select
                        placeholder="Please Select"
                        className=""
                        allowClear
                      >
                        {laws?.map((landholdings, index) => (
                          <Option
                            key={landholdings.label}
                            value={landholdings.value}
                          >
                            {landholdings.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="setback_deviation"
                      label="Setback Deviation(%)"
                      rules={[yupSync]}
                      required
                    >
                      <Input
                        placeholder="Please Enter Setback Deviation(%)"
                        onChange={(e) =>
                          handleNumberFields(e, "setback_deviation")
                        }
                        className="technical-input-text-field-plan-approval"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="vertical_deviation"
                      label="Vertical Deviation(%)"
                      rules={[yupSync]}
                      required
                    >
                      <Input
                        placeholder="Please Enter Vertical Deviation(%)"
                        onChange={(e) =>
                          handleNumberFields(e, "vertical_deviation")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="extension_in_future"
                      label="Any Extension In Future"
                      rules={[yupSync]}
                      required
                    >
                      <Input
                        placeholder="Please Enter Any Extension In Future"
                        onChange={(e) =>
                          handleCharacterSpaceFields(e, "extension_in_future")
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </div>

          <div className="technical_input_form_main_container_block">
            <div className="container_technical">
              <div className="technical_boundriers_details_blue_heading">
                <div className="valuation_details_blue">
                  Documents Provided by VHFCL
                </div>
                <div>
                  <UpOutlined
                    className={`up-outlined ${isDocumentsProvided ? "default" : "rotate"
                      }`}
                    onClick={toggleIsDocumentProvided}
                  />
                </div>
              </div>
            </div>
            {isDocumentsProvided && (
              <Table
                className="insurance_table technical_report_boundries_box"
                columns={columns1}
                dataSource={data1}
                pagination={false}
                size="middle"
              />
            )}
          </div>

          <div className="legal_initiate_input_main_container">
            <CommonDeviation
              manualDeviationData={manualDeviationData}
              setManualDeviationData={setManualDeviationData}
              operationType={operationType}
            />
          </div>

          <div className="technical_input_form_main_container_block">
            <div className="container_technical">
              <div className="technical_boundriers_details_blue_heading technical_boundriers_details_blue_heading_primary">
                <div className="valuation_details_blue">
                  Property Specific Remarks & Observation
                </div>
                <div>
                  <UpOutlined
                    className={`up-outlined ${isPropertyDocuments ? "rotate-0" : "rotate-180"
                      }`}
                    onClick={toggleIsPropertySpecificRemark}
                  />
                </div>
              </div>
            </div>
            {isPropertySpecificRemark && (
              <div className="technical_property_container">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <Form.Item
                      name="overall_status"
                      label="Overall Status"
                      required
                      rules={[yupSync]}
                    >
                      <Select
                        placeholder="Please Select"
                        className="technical_valuation_report_div_width_full"
                        rules={[yupSync]}
                        allowClear
                      >
                        <Option value="Positive">Positive</Option>
                        <Option value="Negative">Negative</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <div>
                  <Form.Item
                    name="remark"
                    label=" Remarks /Observation ( please enter NA if none)"
                    required
                    rules={[yupSync]}
                  >
                    <TextArea placeholder="Please Enter Remark" rows={4} value={remark} onChange={setRemark} />
                  </Form.Item>
                </div>
              </div>
            )}
          </div>

          <div className="technical_input_form_main_container_block">
            <div className="container_technical">
              <div className="technical_boundriers_details_blue_heading">
                <div className="valuation_details_blue">
                  Property Pictures and Other Documents
                </div>
                <div>
                  <UpOutlined
                    className={`up-outlined ${isPropertyDocuments ? "rotate-0" : "rotate-180"
                      }`}
                    onClick={() => setIsPropertyDocuments(!isPropertyDocuments)}
                  />
                </div>
              </div>
            </div>
            {isPropertyDocuments && (
              <div className="technical_property_container">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="upload_documents"
                      label="Enter Document Name"
                      required
                      rules={
                        images.length > 0
                          ? []
                          : [
                            {
                              required: true,
                              message: "Documents is required",
                            },
                          ]
                      }
                    >
                      <Select
                        placeholder="Please select"
                        allowClear
                        className="technical_report_property_documnet_field"
                        showSearch
                        onChange={handleDocumentNameChange}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {legalDocumentType?.map((legaldocumenttype) => (
                          <Select.Option
                            key={legaldocumenttype.value}
                            value={legaldocumenttype.value}
                          // index={index}
                          >
                            {legaldocumenttype.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item name="file_path" label=" ">
                      <Upload
                        multiple
                        fileList={fileList}
                        onChange={handleUploadChange}
                        onRemove={handleRemove}
                        beforeUpload={handleBeforeUpload}
                        showUploadList={false}
                        disabled={uploadDisabled}
                      >
                        <Button
                          classNames="technical_report_propert_document_upload_field"
                          type="primary"
                          icon={<UploadOutlined />}
                          disabled={uploadDisabled}
                        >
                          Upload Document
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <div className="technical_report_property_documnet_divider">
                  <div className="technical_report_property_documnet_image">
                    {images?.map((picture, index) => (
                      <div
                        key={index}
                        className="technical_report_property_documnet_image_box"
                      >
                        {picture.file_type === "application/pdf" ? (
                          <>
                            <a
                              href={picture.file_path}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              <img
                                src={pdf_image}
                                width="100px"
                                height="100px"
                                alt="PDF Preview"
                              />
                            </a>
                            <div className="technical_report_document_image_lable">
                              {documentNames[index]}
                            </div>{" "}
                          </>
                        ) : (picture.file_type === "image/jpeg" || picture.file_type === "image/jpg" || picture.file_type === "image/png") ? (
                          <RViewerJS
                            options={{
                              url: (img) => img.getAttribute("data-original"),
                            }}
                          >
                            <img
                              src={picture.file_path}
                              alt=""
                              className="technical_report_document_image_path"
                            />
                            <div className="technical_report_document_image_lable">
                              {documentNames[index]}
                            </div>{" "}
                          </RViewerJS>
                        ) : (
                          <FileOutlined className="technical-filesize" />
                        )}
                        <div
                          className="technical_report_document_image_delete_icon"
                          onClick={() => {
                            setDeleteUploadPicture(picture);
                            setOpenUploadModal(true);
                          }}
                        >
                          <DeleteOutlined />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <Modal
                  title="Confirm Delete"
                  open={openUploadModal}
                  onOk={confirmDelete}
                  onCancel={() => {
                    setOpenUploadModal(false);
                    setDeleteUploadPicture(null);
                  }}
                  okType="danger"
                >
                  <p>{`Are you sure you want to delete this uploaded image?`}</p>
                </Modal>
              </div>
            )}
          </div>

          <Col span={24} className="technical_report_submit_space_button">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="technical_submit_button "
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Form>
        {/* </div> */}
      </div>
    </div>
  );
};
export default TechnicalValuationReport;
