import React, { useEffect, useState } from "react";
import "../../documentDetails/styles/documentstyle.css";
import { Drawer, message } from "antd";
import { useParams } from "react-router-dom";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import Header from "../../../../layout/views/Header";

import pdf_image from "../assets/pdf.png";
import ProvidedDocument from "./ProvidedDocument";
import {
  getDocumentDetailsByIdService,
  getRemarkDataByIdService,
} from "../services/documentDetailsServices";
import "../../commonCSS/commonTechnicalValuation.css";
import PropertyPictureForm from "./PropertyPictureForm";
import ObservationPropertyForm from "./ObservationPropertyForm";
import Loading from "../../../../../utils/loading/Loading";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import EditButton from "../../../../../utils/editButton/EditButton";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const DocumentDetails = ({ stage_id, technicalStageData }) => {


  const { encrypted_loan_id } = useParams();
  const [openDocumentProvidedForm, setOpenDocumentProvidedForm] =
    useState(false);
  const [openObservationProperty, setOpenObservationPropertyForm] =
    useState(false);
  const [openPropertyPictureForm, setOpenPropertyPicturesForm] =
    useState(false);
  const [documentDetail, setDocumentDetail] = useState();
  const [propertyData, setPropertyData] = useState();
  const [images, setImages] = useState([]);
  const [SelectedPropertyPicture, setSelectedPropertyPicture] = useState([]);

  const [showDocumentDetails, setShowDocumentDetails] = useState(true);
  const [showObservationDetails, setShowObservationDetails] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [showPropertyPicturesDetails, setshowPropertyPicturesDetails] =
    useState(true);

  const [technicalId, SetTechnicalId] = useState(true);
  const [loading, setLoading] = useState(true);
  const isSendBack = technicalStageData?.stage_status?.display_name === "Send Back";
  
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDocumentProvidedForm = () => {
    setOpenDocumentProvidedForm(true);
  };

  const showObservationPropertyForm = () => {
    setOpenObservationPropertyForm(true);
  };

  const showPropertyPicturesForm = () => {
    setOpenPropertyPicturesForm(true);
    setSelectedPropertyPicture(null);
  };

  const onClose = () => {
    setOpenDocumentProvidedForm(false);
    setOpenObservationPropertyForm(false);
    setOpenPropertyPicturesForm(false);
  };

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDocumentDetailsByIdService(stage_id);
        SetTechnicalId(data.data.allotment_letter.technical_detail);
        setDocumentDetail(data.data || {});
        setImages(data.data.technical_upload_serialized_data || []);
        setLoading(false);
      } catch (error) {

        setLoading(false);
      }
    };

    const fetchPropertyData = async () => {
      try {
        setLoading(true);
        const data = await getRemarkDataByIdService(stage_id);
        setPropertyData(data.data);
        setLoading(false);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
        setLoading(false);
      }
    };

    fetchData();
    fetchPropertyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, refreshData, stage_id]);

  return (
    <>
      <div className="main_basic_container">
        <div className="technical_views_form_main_container">
          <div className="valuation_details valuation_property_blue_heading ">
            <div className="technical_details_blue">
              Documents Provided by VHFCL
            </div>
            <div>
              <div className="technical_view_button_space">
                {isSendBack &&
                  <div
                    onClick={showDocumentProvidedForm}
                    className="technical_edit_button"
                  >
                    <EditButton />
                  </div>
                }

                {showDocumentDetails ? (
                  <DownOutlined
                    onClick={() => setShowDocumentDetails(!showDocumentDetails)}
                    className="collapse_icon"
                  />
                ) : (
                  <UpOutlined
                    onClick={() => setShowDocumentDetails(!showDocumentDetails)}
                    className="collapse_icon"
                  />
                )}
              </div>

              <Drawer
                title={
                  <Header
                    title="Edit"
                    onClose={onClose}
                    name="Documents Provided by VHFCL"
                  />
                }
                width={970}
                onClose={onClose}
                visible={openDocumentProvidedForm}
                className="custom-body-style"
                closable={false}
                refreshData={toggleRefreshData}
              >
                <ProvidedDocument
                  open={openDocumentProvidedForm}
                  closeForm={onClose}
                  refreshData={toggleRefreshData}
                  stage_id={stage_id}
                />
              </Drawer>
            </div>
          </div>

          {showDocumentDetails && (
            <>
              {loading ? (
                <Loading loading={loading} />
              ) : (
                <div className="data_table fade-in">
                  {documentDetail ? (
                    <>
                      <div className="document_details_heading">
                        <div className="document_details_data_td_heading" >
                          Document Description
                        </div>
                        <div className="document_details_data_td_heading_first">
                          Collection Status
                        </div>
                        <div className="document_details_data_td_heading_first">
                          Document/approval Number
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            Sale Deed/Release deed
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.sale_deed?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>
                        <div className="document_details_data_text_td">
                          {documentDetail?.sale_deed?.collection_status
                            ? documentDetail?.sale_deed?.document_number
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            Sale agreement{" "}
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.sale_agreement?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>
                        <div className="document_details_data_text_td">
                          {documentDetail?.sale_agreement?.collection_status
                            ? documentDetail?.sale_agreement?.document_number ||
                            "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            Statutory plan and number
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.statutory_plan_number
                            ?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>
                        <div className="document_details_data_text_td">
                          {documentDetail?.statutory_plan_number
                            ?.collection_status
                            ? documentDetail?.statutory_plan_number
                              ?.document_number
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            Construction agreement/Gift Deed
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.construction_agreement
                            ?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>
                        <div className="document_details_data_text_td">
                          {documentDetail?.construction_agreement
                            ?.collection_status
                            ? documentDetail?.construction_agreement
                              ?.document_number || "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            RERA approval Number if applicable
                          </div>
                        </div>

                        <div className=" document_details_data_text_td">
                          {documentDetail?.rera_approval?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>
                        <div className="document_details_data_text_td">
                          {documentDetail?.rera_approval?.collection_status
                            ? documentDetail?.rera_approval?.document_number
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            Allotment letter
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.allotment_letter?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.allotment_letter?.collection_status
                            ? documentDetail?.allotment_letter
                              ?.document_number || "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            7/12 extract/Patta/Land record
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.land_record?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.land_record?.collection_status
                            ? documentDetail?.land_record?.document_number ||
                            "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            Conversion Certificate
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.conversion_certificate
                            ?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.conversion_certificate
                            ?.collection_status
                            ? documentDetail?.conversion_certificate
                              ?.document_number || "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            Deviation approval mail from Vastu authority for
                            lack of documents
                          </div>
                        </div>

                        <div className=" document_details_data_text_td">
                          {documentDetail?.deviation_approval?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.deviation_approval?.collection_status
                            ? documentDetail?.deviation_approval
                              ?.document_number || "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            Property Tax receipts
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.property_tax_receipt
                            ?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.property_tax_receipt
                            ?.collection_status
                            ? documentDetail?.property_tax_receipt
                              ?.document_number || "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            Estimate Provided and its value
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.estimate_provided?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.estimate_provided
                            ?.collection_status
                            ? documentDetail?.estimate_provided?.document_number
                            : "NA"}
                        </div>
                      </div>


                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            Other documents
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.other_document?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.other_document?.collection_status
                            ? documentDetail?.other_document?.document_number ||
                            "-"
                            : "NA"}
                        </div>
                      </div>

                      <div className="valuation_details_data">
                        <div className="technical_documentation_descreption">
                          <div className="technical_documentation_descreption_data">
                            General Power of attorney
                          </div>
                        </div>
                        <div className=" document_details_data_text_td">
                          {documentDetail?.general_power_of_attorney
                            ?.collection_status
                            ? "Yes"
                            : "No"}
                        </div>

                        <div className="document_details_data_text_td">
                          {documentDetail?.general_power_of_attorney
                            ?.collection_status
                            ? documentDetail?.general_power_of_attorney
                              ?.document_number || "-"
                            : "NA"}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="no_data_found_message">
                      <CustomNoData />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>

        <div className="technical_views_form_main_container">
          <div className="valuation_details valuation_property_blue_heading">
            <div className="technical_details_blue">
              Property Specific Remarks & Observation
            </div>
            <div>
              <div className="technical_view_button_space">
                {isSendBack &&
                  <div
                    onClick={showObservationPropertyForm}
                    className="technical_edit_button"
                  >
                    <EditButton />
                  </div>
                }
                {showObservationDetails ? (
                  <DownOutlined
                    onClick={() =>
                      setShowObservationDetails(!showObservationDetails)
                    }
                    className="collapse_icon"
                  />
                ) : (
                  <UpOutlined
                    onClick={() =>
                      setShowObservationDetails(!showObservationDetails)
                    }
                    className="collapse_icon"
                  />
                )}
              </div>

              <Drawer
                title={
                  <Header
                    title="Edit"
                    onClose={onClose}
                    name="Property Specific Remarks & Observation"
                  />
                }
                width={970}
                onClose={onClose}
                visible={openObservationProperty}
                className="custom-body-style"
                closable={false}
                refreshData={toggleRefreshData}
              >
                <ObservationPropertyForm
                  open={openObservationProperty}
                  closeForm={onClose}
                  refreshData={toggleRefreshData}
                  stage_id={stage_id}
                />
              </Drawer>
            </div>
          </div>

          {showObservationDetails && (
            <div className="data_table fade-in">
              {propertyData ? (
                <>
                  <div className="document_details_second_heading">
                    <div className="document_details_second_data">
                      Overall Status<span className="remark_star_mark">*</span>
                    </div>
                    <div
                      className={`document_details_second_data ${propertyData?.technical_detail?.[0]?.overall_status === "Positive"
                        ? "overall_status_positive"
                        : "overall_status_nagative"
                        }`}
                    >
                      {propertyData?.technical_detail?.[0]?.overall_status}
                    </div>

                    <div className="document_details_second_data">
                      Remarks /Observation ( please enter NA if none)
                      <div>{propertyData?.technical_detail?.[0]?.remark}</div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="no_data_found_message">
                  <CustomNoData />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="technical_views_form_main_container">
          <div className="valuation_details valuation_property_blue_heading">
            <div className="technical_details_blue">Property Pictures</div>
            <div>
              <div className="technical_view_button_space">
                {isSendBack &&
                  <div
                    onClick={showPropertyPicturesForm}
                    className="technical_edit_button"
                  >
                    <EditButton />
                  </div>
                }
                {showPropertyPicturesDetails ? (
                  <DownOutlined
                    onClick={() =>
                      setshowPropertyPicturesDetails(
                        !showPropertyPicturesDetails
                      )
                    }
                    className="collapse_icon"
                  />
                ) : (
                  <UpOutlined
                    onClick={() =>
                      setshowPropertyPicturesDetails(
                        !showPropertyPicturesDetails
                      )
                    }
                    className="collapse_icon"
                  />
                )}
              </div>

              <Drawer
                title={
                  <Header
                    title="Edit"
                    onClose={onClose}
                    name="Property Pictures"
                  />
                }
                width={970}
                onClose={onClose}
                open={openPropertyPictureForm}
                className="custom-body-style"
                closable={false}
                refreshData={toggleRefreshData}
              >
                <PropertyPictureForm
                  open={openPropertyPictureForm}
                  setSelectedPropertyPicture={setSelectedPropertyPicture}
                  SelectedPropertyPicture={SelectedPropertyPicture}
                  images={images}
                  closeForm={onClose}
                  refreshData={toggleRefreshData}
                  technical_detail={technicalId}
                />
              </Drawer>
            </div>
          </div>
          {showPropertyPicturesDetails && (
            <>
              {loading ? (
                <Loading loading={loading} />
              ) : (
                <div className="valuation_Proerty_images_container fade-in">
                  {images.length > 0 ? (
                    <div className="document_images">
                      {images.map((image, index) => (
                        <div
                          key={index}
                          className="document_details_document_image"
                        >
                          {image.file_type === "application/pdf" ? (
                            <a
                              href={image.file_path}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={pdf_image}
                                className="technical_document_image"
                                alt="PDF Preview"
                              />
                            </a>
                          ) : (
                            <RViewerJS
                              options={{
                                url: (img) => img.getAttribute("data-original"),
                              }}
                            >
                              <img
                                src={image.thumb_file_path}
                                data-original={image.file_path}
                                alt="Preview"
                                className="technical_document_image"
                              />
                            </RViewerJS>
                          )}
                          <div className="technical_document_image">
                            {image.document_type}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="no_data_found_message">
                      <CustomNoData />
                    </div>
                  )}
                </div>
              )}
            </>

          )}
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default DocumentDetails;
