import React, { useEffect, useState } from "react";
import {
  Table,
  Typography,
  Space,
  Input,
  Select,
  Drawer,
  Button,
  message,
  Upload,
} from "antd";
import {
  EditOutlined,
  DownloadOutlined,
  UploadOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import Header from "../../../../../../layout/views/Header";
import CersaiEditForm from "./CersaiEditForm";
import {
  getDataForPendingList,
  getDownloadFile,
  getFileListData,
  getSecurityInterestPendingList,
  updateFileListDataByIdService,
} from "../services/SecurityInterestPendingList";
import debounce from "lodash.debounce";
import "../styles/SecurityInterestPendingList.css";
import { decrypt } from "../../../../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";

const { Option } = Select;

const SecurityInterestPendingList = () => {
  const [open, setOpen] = useState(false);
  const [securityInterestPendingList, setSecurityInterestPendingList] =
    useState([]);
  const [fileListData, setFileListData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [, setLoanDetailId] = useState(null);
  const [, setSearchQuery] = useState("");
  const [, setFileListSearchQuery] = useState("");
  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };
   const [loanIdByApplication, setLoanIdByApplication] = useState([]);

  const { Title } = Typography;


  const { encrypted_loan_id } = useParams();
  let loanidbyparam = null;
  try {
    loanidbyparam = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = (record) => {
    const loandeatil = record.loan_detail;
    setLoanDetailId(loandeatil);
    toggleRefreshData();
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    toggleRefreshData();
  };

  const handleDownload = async () => {
    if (selectedRows.length === 0) {
      message.warning("Please select at least one application to download.");
      return;
    }

    try {
      const applicationNumbers = selectedRows.map(
        (row) => row.loan_application_number
      );

      const response = await getDataForPendingList(applicationNumbers);

      if (response?.success && response.data.file_path) {
        // Check if the file URL is accessible
        const fileUrl = response.data.file_path;

        // Attempt to download the file
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = fileUrl.split("/").pop(); // Extract the file name from the URL
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        message.success("File downloaded successfully.");
        await fetchFileListData();
        setSelectedRows([]);
        setSelectedRowKeys([]);
      } else {
        message.error("File not found or cannot be downloaded.");
      }
    } catch (error) {
      message.error("An error occurred while downloading the file.");
      console.error("Download error:", error);
    }
  };

  const fetchPendingList = async (query = "") => {
    try {
      const response = await getSecurityInterestPendingList(query);
      
     const loanIdValues = response?.data?.map(item => item.loan_detail) || [];
     
     setLoanIdByApplication(loanIdValues);
      setSecurityInterestPendingList(response.data || []);
    } catch (error) {
      message.error("Failed to fetch the pending list.");
    }
  };

  const fetchFileListData = async (query = "") => {
    try {
      const response = await getFileListData(query);
      setFileListData(response.data || []);
    } catch (error) {
      message.error("Failed to fetch File list Data.");
    }
  };

  useEffect(() => {
    fetchPendingList();
    fetchFileListData();
  }, [refreshData]);

  const columns = [
    {
      title: "Sr.No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Loan No.",
      dataIndex: "loan_application_number",
      key: "loan_application_number",
      render: (text) => text?.replace(/[()']/g, "") || "-", // Remove unwanted characters
    },
    {
      title: "Customer",
      dataIndex: "applicant_name",
      key: "applicant_name",
    },
    {
      title: "Disbursement Date",
      dataIndex: "disbursement_date",
      key: "disbursement_date",
    },
    {
      title: "Branch",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Status",
      dataIndex: "disbursement_status",
      key: "disbursement_status",
      render: (text) => text || "N/A",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              showDrawer(record);
            }}
          />
        </Space>
      ),
    },
  ];

  const rowSelection = {
    type: "checkbox",
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      // If a row is being selected
      if (selected) {
        // Find the key for the current record
        const recordKey = record.loan_application_number || record.id;

        // Add the current row's key to the existing selected keys
        const updatedSelectedRowKeys = [...selectedRowKeys, recordKey];
        setSelectedRowKeys(updatedSelectedRowKeys);

        // Add the current row to the existing selected rows
        const updatedSelectedRows = [...selectedRows, record];
        setSelectedRows(updatedSelectedRows);
      } else {
        // If deselecting, remove the specific row key and row
        const updatedSelectedRowKeys = selectedRowKeys.filter(
          (key) => key !== (record.loan_application_number || record.id)
        );
        setSelectedRowKeys(updatedSelectedRowKeys);

        const updatedSelectedRows = selectedRows.filter(
          (r) => r.loan_application_number !== record.loan_application_number
        );
        setSelectedRows(updatedSelectedRows);
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        const allRowKeys = securityInterestPendingList.map(
          (record) => record.loan_application_number || record.id
        );
        setSelectedRowKeys(allRowKeys);
        setSelectedRows(securityInterestPendingList);
      } else {
        setSelectedRowKeys([]);
        setSelectedRows([]);
      }
    },
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const handleSearch = debounce((value) => {
    setSearchQuery(value);
    fetchPendingList(value);
  }, 300);

  const handleFileListSearch = debounce((value) => {
    setFileListSearchQuery(value);
    fetchFileListData(value);
  }, 300);

  const handleFileUpload = async (file, recordId) => {
    if (!file || !recordId) {
      message.error("File or Record ID is missing");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("responce_file_path", file);
      const response = await updateFileListDataByIdService(recordId, formData);

      if (response.success) {
        message.success("File uploaded successfully");
        await fetchFileListData();
      } else {
        message.error("File upload failed");
      }
    } catch (error) {
      console.error("File upload error:", error);
      message.error("An error occurred while uploading the file");
    }
  };

  const handleReferenceNumberChange = async (record, newValue) => {
    try {
      const formData = new FormData();
      formData.append("id", record.id);
      formData.append("file_reference_number", newValue);

      
      // Call the API
      const response = await updateFileListDataByIdService(record.id, formData);

      if (response.success) {
        message.success("Reference number updated successfully");
        await fetchFileListData();
      } else {
        message.error("Failed to update reference number");
      }
    } catch (error) {
      console.error("Reference number update error:", error);
      message.error("An error occurred while updating the reference number");
    }
  };

  const handleFileDownload = async (record) => {
    try {
      if (!record.file_path) {
        message.warning("No file available for download.");
        return;
      }

      const response = await getDownloadFile([record.id]);

      if (response?.success && response.data.file_path) {
        const fileUrl = response.data.file_path;
        const link = document.createElement("a");
        link.href = fileUrl;

        const fileName = fileUrl.split("/").pop();
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        message.success("File downloaded successfully.");
      } else {
        message.error("File not found or cannot be downloaded.");
      }
    } catch (error) {
      message.error("An error occurred while downloading the file.");
      console.error("Download error:", error);
    }
  };

  const filelist = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Generation Date",
      dataIndex: "file_genrated_date",
      key: "file_genrated_date",
      render: (text) => {
        const [date] = text?.split(" ") || []; // Extract the date portion
        return date || "-";
      },
    },
    {
      title: "Generated File",
      dataIndex: "file_path",
      key: "file_path",
      render: (text) => {
        // Extract the filename from the full URL
        const fileName =
          text?.substring(text.lastIndexOf("/") + 1, text.indexOf("?")) || "-";
        return fileName;
      },
    },
    {
      title: "Reference No.",
      dataIndex: "file_reference_number",
      key: "file_reference_number",
      render: (text, record, index) => (
        <Input.Group compact>
          <Input
            className="refrence-button-field"
            defaultValue={text}
            onChange={(e) => (record.updatedValue = e.target.value)}
            placeholder="Enter Reference No."
          />
          <Button
            className="no-vertical-align"
            onClick={() =>
              handleReferenceNumberChange(record, record.updatedValue || text)
            }
          >
            <ArrowRightOutlined />
          </Button>
        </Input.Group>
      ),
    },

    {
      title: "Response File",
      dataIndex: "responce_file_path",
      key: "responce_file_path",
      render: (text, record) => {
        // Check if a file is already uploaded (text is not null or empty)
        if (text) {
          const fileName = text
            .split("/")
            .pop()
            .split("_")
            .slice(0, 5)
            .join("_");
          return (
            <div>
              <a href={text} target="_blank" rel="noopener noreferrer">
                {fileName}
              </a>
            </div>
          );
        }

        // If no file is uploaded, show upload button
        return (
          <div>
            <Upload
              showUploadList={false}
              beforeUpload={(file) => {
                // Call the upload handler with the file and record ID
                handleFileUpload(file, record.id);
                return false; // Prevent default upload behavior
              }}
            >
              <Button icon={<UploadOutlined />}>Choose File</Button>
            </Upload>
          </div>
        );
      },
    },

    {
      title: "Emp Name",
      dataIndex: "employee",
      key: "employee",
      render: (employee) => employee?.name || "-",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          <DownloadOutlined
            onClick={() => handleFileDownload(record)}
            className="file-list-download-icon"
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Drawer
        title={
          <Header title={"Edit"} onClose={onClose} name="Cersai Details" />
        }
        width={900}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <CersaiEditForm
          open={open}
          closeForm={onClose}
          loanId={loanIdByApplication || loanidbyparam}
          // loanIdByApplication={loanIdByApplication}
          refreshData={toggleRefreshData}
        />
      </Drawer>

      <div className="pending-list-header">
        <div className="pending-list-download-button">
          <Title level={4} className="cersai-heading-pending-and-file">
            Pending List
          </Title>
          <Button
            icon={<DownloadOutlined />}
            onClick={handleDownload}
            type="primary"
          >
            Download
          </Button>
        </div>

        <div className="pending-list-filter-search-button">
          <span>Filter:</span>
          <Select defaultValue="All" className="filter-button">
            <Option value="all">All</Option>
            <Option value="pending">Without Errors</Option>
            <Option value="approved">With Errors(Only CERSAI)</Option>
            <Option value="rejected">Rejected</Option>
          </Select>

          <span>Search:</span>
          <Input
            placeholder="Enter search text"
            className="pending-list-search-button"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>

      <Table
        rowKey={(record) => record.loan_application_number || record.id}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        rowClassName={(record) => (record.has_error === 1 ? "error-row" : "")}
        dataSource={securityInterestPendingList}
        columns={columns}
        pagination={true}
        scroll={{ x: "max-content" }}
      />

      <Space
        direction="horizontal"
        align="center"
        className="file-list-heading"
      >
        <Title level={4} className="cersai-heading-pending-and-file">
          File List
        </Title>

        <div className="file-list-search-button">
          <span>Search:</span>
          <Input
            placeholder="Search File List"
            onChange={(e) => handleFileListSearch(e.target.value)}
          />
        </div>
      </Space>

      <Table
        dataSource={fileListData}
        columns={filelist}
        pagination={true}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default SecurityInterestPendingList;
