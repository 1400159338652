import React, { useEffect, useState } from "react";
import "../styles/decisionDetails.css";
import { Drawer, Table, message } from "antd";
import { UpOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { getMiscellaneousDetailServiceById } from "../../../../LegalValuation/services/miscellaneousDetailsService";
import CommonManualDeviationForm from "../../../../../utils/deviation/views/CommonManualDeviationForm";
import Header from "../../../../layout/views/Header";
import { updateMaualDeviationByIdService } from "../services/basicServices";
import { getLegalDeviationList, getRcuDeviationList, getTechnicalDeviationList } from "../../../../../utils/deviation/services/getDeviation";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { getRCUIdbyloanId } from "../../../../rcu/services/rcuservices";
import { getRemarkDataByIdService } from "../../documentDetails/services/documentDetailsServices";

const CommonDecisionDetails = ({ stageId, refreshTableData, type, sendBackData }) => {
  const [collapsed2, setCollapsed2] = useState(true);
  const [manualDeviationData, setManualDeviationData] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedDeviation, setSelectedDeviation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deviationList, setDeviationList] = useState([]);

  const showDrawer = (deviationData) => {
    setSelectedDeviation(deviationData);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedDeviation(null);
  };


  useEffect(() => {
    const fetchDeviationList = async () => {
      try {
        let result = null;

        // Call the appropriate API based on the operation type
        if (type === "rcu") {
          result = await getRcuDeviationList();
        } else if (type === "legal") {
          result = await getLegalDeviationList();
        } else if (type === "technical") {
          result = await getTechnicalDeviationList();
        }

        if (result) {
          setDeviationList(result?.data || []);
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    // Fetch data only if type is provided
    if (type) {
      fetchDeviationList();
    }
  }, [type, refreshTableData]);


  const fetchManualDeviationData = async () => {
    try {
      let response;

      // Dynamically select the API based on `type`
      if (type === "rcu") {
        response = await getRCUIdbyloanId(stageId);
      } else if (type === "legal") {
        response = await getMiscellaneousDetailServiceById(stageId);
      } else if (type === "technical") {
        response = await getRemarkDataByIdService(stageId);
      } else {
        throw new Error("Invalid type provided");
      }


      setManualDeviationData(response?.data);
    } catch (error) {

      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    fetchManualDeviationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageId, type]);




  const formatDeviationData = () => {
    if (!manualDeviationData) return [];

    switch (type) {
      case "technical":
        return manualDeviationData?.technical_detail?.flatMap(item =>
          item?.loan_deviations?.map((deviation, index) => ({
            key: `${item.id}-${index}`,
            no: index + 1,
            deviation: deviation.deviation,
            actual_deviations: deviation.actual_description,
            mitigates: deviation.mitigates,
            originalId: deviation.id,
            remove: ''
          })) || []
        ) || [];

      case "rcu":
        return manualDeviationData?.rcu_data?.loan_deviations?.map((deviation, index) => ({
          key: deviation.id,
          no: index + 1,
          deviation: deviation.deviation,
          actual_deviations: deviation.actual_description,
          mitigates: deviation.mitigates,
          originalId: deviation.id
        })) || [];

      case "legal":
        return manualDeviationData?.flatMap(item =>
          item?.loan_deviations?.map((deviation, index) => ({
            key: `${item.id}-${index}`,
            no: index + 1,
            deviation: deviation.deviation,
            actual_deviations: deviation.actual_description,
            mitigates: deviation.mitigates,
            originalId: deviation.id
          })) || []
        ) || [];

      default:
        return [];
    }
  };

  const findOriginalDeviation = (record) => {
    if (!manualDeviationData || !record.originalId) return null;

    switch (type) {
      case "technical":
        return manualDeviationData?.technical_detail
          ?.flatMap(item => item.loan_deviations)
          .find(dev => dev.id === record.originalId);

      case "rcu":
        return manualDeviationData?.rcu_data?.loan_deviations
          ?.find(dev => dev.id === record.originalId);

      case "legal":
        return manualDeviationData
          ?.flatMap(item => item.loan_deviations)
          .find(dev => dev.id === record.originalId);

      default:
        return null;
    }
  };

  const handleEditManualDeviation = async (updatedValues) => {

    setLoading(true);
    try {
      const payload = {
        deviation: updatedValues.deviation,
        actual_description: updatedValues.actual_description,
        mitigates: updatedValues.mitigates
      };


      await updateMaualDeviationByIdService(selectedDeviation.id, payload);
      await fetchManualDeviationData();
      message.success("Manual Deviation Updated Successfully");
      onClose();
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const columns2 = [
    { title: "SrNo.", dataIndex: "no" },
    { title: "Description", dataIndex: "deviation" },
    { title: "Deviations", dataIndex: "actual_deviations" },
    { title: "Mitigates", dataIndex: "mitigates" },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        const originalDeviation = findOriginalDeviation(record);
        if (!originalDeviation) return null;


        return sendBackData ? (
          <EditOutlined
            onClick={() => {
              showDrawer({
                id: originalDeviation.id,
                deviation: originalDeviation.deviation,
                actual_description: originalDeviation.actual_description,
                mitigates: originalDeviation.mitigates,
              });
            }}
          />
        ) : "-";
      },
    },
  ];

  const data2 = formatDeviationData();

  return (
    <>
      <Drawer
        title={
          <Header
            title={"Edit"}
            onClose={onClose}
            name="Manual Deviation"
          />
        }
        width={720}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <CommonManualDeviationForm
          selectedManual={true}
          initialValues={selectedDeviation}
          handleEditManualDeviation={handleEditManualDeviation}
          onClose={onClose}
          loading={loading}
          deviationList={deviationList}
        />
      </Drawer>
      <div className="main_basic_container">
        <div className="technical_views_decision_form_main_container">
          <div>
            <div className="heading_text">Deviation Details
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setCollapsed2(!collapsed2)}
              >
                {collapsed2 ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
          </div>



          {collapsed2 && (
            <>
              <div className="technical_decision_deviation_heading_block">
                <div>
                  <div className="text auto_deviation_text">Manual Deviation</div>
                </div>

              </div>
              <Table
                columns={columns2}
                dataSource={data2}
                pagination={false}
              />
            </>
          )}
        </div>
      </div>

    </>
  );
};

export default CommonDecisionDetails;