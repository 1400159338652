import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, Space, message, Select } from "antd";
import * as Yup from "yup";
import { getPropertyTechnicalDataByIdService } from "../../valuationDetails/services/valuationDetailsServices";
import { updateTechnicalPropertyDetailsByIdService } from "../services/propertyDetailsServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const validationSchema = Yup.object().shape({
    document_north: Yup.string().required("North is required"),
    document_south: Yup.string().required("South is required"),
    document_east: Yup.string().required("East is required"),
    document_west: Yup.string().required("West is required"),
    actual_north: Yup.string().required("Actual North As Per Site is required"),
    actual_south: Yup.string().required("Actual South As Per Site is required"),
    actual_east: Yup.string().required("Actual East As Per Site is required"),
    actual_west: Yup.string().required("Actual West As Per Site is required"),
    boundaries_matching: Yup.string().required("Boundaries Matching is required"),
    boundary_mismatch_remark: Yup.string().required("Mismatch Remark is required")
});

const { Option } = Select;

const BoundariesDetailsFrom = ({ refreshData, id, open, closeForm, stage_id }) => {
    const [boundariesMatching, setBoundariesMatching] = useState(true);
    // const [show,setShow] = useState(false);
    const [form] = Form.useForm();
    const [technicalid, setTechnicalid] = useState();


    const handleBoundariesMatchingChange = (value) => {
        setBoundariesMatching(value);
    if (value) {
            form.setFieldsValue({ boundary_mismatch_remark: '' });
        }
        form.setFieldsValue({ boundaries_matching: value });
    };


    const handleSubmit = async (values) => {
        const payload = {
            ...values,
            boundaries_matching: values.boundaries_matching ? "Yes" : "No",
            boundary_mismatch_remark: values.boundaries_matching  ? '' : values.boundary_mismatch_remark
        };
        try {
            const response = await updateTechnicalPropertyDetailsByIdService(technicalid, payload);

            if (response && response?.data) {
                if (response?.status === 200 && response?.success) {
                    message.success("Boundary Details updated successfully");
                    refreshData();
                    closeForm();
                }
            } else {
                message.error("Failed to update Boundary Details");
            }
        } catch (error) {
            closeForm();
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
              );
        }
    };

    const getPersonalData = async () => {
        try {
            const response = await getPropertyTechnicalDataByIdService(stage_id);
            setTechnicalid(response.data.technical_detail[0].id);
            let {
                document_north,
                document_south,
                document_east,
                document_west,
                actual_north,
                actual_south,
                actual_east,
                actual_west,
                boundaries_matching,
                boundary_mismatch_remark
            } = response?.data?.technical_detail[0];
            form?.setFieldsValue({
                document_north,
                document_south,
                document_east,
                document_west,
                actual_north,
                actual_south,
                actual_east,
                actual_west,
                boundaries_matching,
                boundary_mismatch_remark
            });

            setBoundariesMatching(boundaries_matching);
        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
              );  
        }

    };


    const yupSync = {
        async validator({ field }, value) {
            await validationSchema.validateSyncAt(field, { [field]: value });
        },
    };

    useEffect(() => {
        if (open) {
            getPersonalData();
        } else {
            form?.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, form, id, stage_id]);

    const handleCharacterFields = (e, field) => {
        // Get the value from the input
        let newValue = e.target.value;

        // Remove all characters except digits, alphabetic characters, and decimal points
        newValue = newValue.replace(/[^0-9a-zA-Z. ]/g, "");

        // Ensure that only one decimal point is allowed
        const decimalCount = (newValue.match(/\./g) || []).length;
        if (decimalCount > 1) {
            newValue = newValue.replace(/\.(?=.*\.)/g, ""); // Remove all but the last decimal point
        }

        // Set the formatted value in the form
        form.setFieldsValue({ [field]: newValue });
    };


    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                document_north: "",
                document_south: "",
                document_east: "",
                document_west: "",
                actual_north: "",
                actual_south: "",
                actual_east: "",
                actual_west: "",
                boundaries_matching: true,
                boundary_mismatch_remark: "",
            }}
            onFinish={handleSubmit}
        >
            <div className="addres_technical_docket_text">
                <div className="address_tech_actual_site">As per Document </div>
            </div><br />
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                        name="document_north"
                        label="North"
                        rules={[{ required: true, message: "North is required" }]}
                    >
                        <Input placeholder="Please Enter North" onChange={(e) => handleCharacterFields(e, "document_north")} />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name="document_south"
                        label="South"
                        rules={[{ required: true, message: "South is required" }]}
                    >
                        <Input placeholder="Please Enter South" onChange={(e) => handleCharacterFields(e, "document_south")} />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="document_east" label="East" rules={[yupSync]} required>
                        <Input placeholder="Please Enter East" onChange={(e) => handleCharacterFields(e, "document_east")} />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="document_west" label="West" rules={[yupSync]} required>
                        <Input placeholder="Please Enter West" onChange={(e) => handleCharacterFields(e, "document_west")} />
                    </Form.Item>
                </Col>
            </Row>

            <div className="addres_technical_docket_text">
                <div className="address_tech_actual_site">As per Site </div>
            </div>

            <Row gutter={16} className="adddres_technical_form_row">
                <Col span={8}>
                    <Form.Item name="actual_north" label="North" rules={[yupSync]} required>
                        <Input placeholder="Please Enter North" onChange={(e) => handleCharacterFields(e, "actual_north")} />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="actual_south" label="South" rules={[yupSync]} required>
                        <Input placeholder="Please Enter South" onChange={(e) => handleCharacterFields(e, "actual_south")} />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="actual_east" label="East" rules={[yupSync]} required>
                        <Input placeholder="Please Enter East" onChange={(e) => handleCharacterFields(e, "actual_east")} />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="actual_west" label="West" rules={[yupSync]} required>
                        <Input placeholder="Please Enter West" onChange={(e) => handleCharacterFields(e, "actual_west")} />
                    </Form.Item>
                </Col>
            </Row>

            <div className="addres_technical_docket_text">
                <div className="address_tech_actual_site">Remarks</div>
            </div>

            <Row gutter={16} className="adddres_technical_form_row">
                <Col span={8}>
                    <Form.Item
                        name="boundaries_matching"
                        label="Boundaries Matching"
                        rules={[{ required: true, message: 'Please select Boundaries Matching' }]}
                    >
                        <Select
                            placeholder="Please Select Boundaries Matching"
                            onChange={handleBoundariesMatchingChange} allowClear
                        >
                            <Option value={true}>Yes</Option>
                            <Option value={false}>No</Option>
                        </Select>
                    </Form.Item>
                </Col>

                {!boundariesMatching && (
                    <Col span={8}>
                        <Form.Item
                            name="boundary_mismatch_remark"
                            label="Mismatch Remarks"
                            rules={[{ required: true, message: 'Please enter Mismatch Remarks' }]}

                        >
                            <Input placeholder="Please Enter Mismatch Remarks" />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Space
                direction="horizontal"
                align="center"
               className="address_property_save_cancle"
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default BoundariesDetailsFrom;
