function GetDropdown(base, option, keyToCheck='id', is_choices=false) {
  
  if (is_choices === false) {
    const exists = base.some((item) => item[keyToCheck] === option[keyToCheck]);
    return exists ? base : [...base, option];
  }

  if (is_choices === true) {
    let baseArray = base.split(",").map((item) => item.trim()); 
    if (!baseArray.includes(option)) {
      baseArray.push(option);
    }
    return baseArray.join(", ");
  }
  return base;
}


export default GetDropdown;