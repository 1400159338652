import React, { useEffect, useState } from "react";
import "../styles/loanInsurance.css";
import { Button, message, Drawer, Table, Popover, Card } from "antd";
import "../../../../../utils/styles/heading.css";
import { PlusCircleOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";

import {
  getChargesByIdService,
  getInsurancesByIdService,
  getInsuranceDetailsCamService,
  getAdditionalLoanDetailIdService,
} from "../services/loanInsuranceService";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import InsuranceForm from "./InsuranceForm";
import Header from "../../../../layout/views/Header";
import HDFCInsuranceview from "./HDFCInsuranceview";
import MagmaHDIInsuranceview from "./MagmaHDIInsuranceview";
import ICICIInsuranceview from "./ICICIInsuranceview";
import Loading from "../../../../../utils/loading/Loading";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import ChargesUnderwritingForm from "./ChargesUnderwritingForm";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";
import { LTVCalculationsByLoanId } from "../../IncomeEvaluation/services/incomeDetailsServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import IndianNumberFormat from "../../../../../utils/indianNumberFormat/IndianNumberFormat";
import EditButton from "../../../../../utils/editButton/EditButton";
import LoanDetailsForm from "./LoanDetailsForm";

const LoanInsurance = () => {
  const [loanData, setLoanData] = useState(null);
  const [chargesData, setChargesData] = useState(null);
  const { encrypted_loan_id } = useParams();
  const [activeButton, setActiveButton] = useState(null); // State to track active insurance ID
  const [selectedInsurance, setSelectedInsurance] = useState(null); // State to track selected insurance
  const [collapseSections, setCollapseSections] = useState(true);
  const [loanDetailsCollapseSections, setLoanDetailsCollapseSections] =
    useState(true);
  const [chargesCollapseSections, setChargesCollapseSections] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [refeshInsurance, setRefreshInsurance] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [openCharges, setOpenCharges] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [selectedCharges, setSelectedCharges] = useState(null);
  const [getLTVCalculation, setLTVCalculation] = useState([]);
  const [openIncomeDetails, setOpenIncomeDetails] = useState(false);
  const [refreshDataIncomeDetails, setRefreshDataIncomeDetails] = useState(false);
  const { user_data } = useSelector((state) => state.user);
  const monthlyIncome = getLTVCalculation?.appraised_monthly_income;
  const showChargesDrawer = () => {
    setOpenCharges(true);
  };
  const toggleRefreshTableDataIncomeDetails = () => {
    setRefreshDataIncomeDetails((prev) => !prev);
  };
  const onCloseChargesDrawer = () => {
    setOpenCharges(false);
    setSelectedCharges(null);
  };

  const showChargesEditDrawer = () => {
    setOpenCharges(true);
  };

  const toggleRefreshInsurance = () => {
    setRefreshInsurance((prev) => !prev);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const columns = [
    {
      title: "Charge Name",
      dataIndex: "charge",
    },

    {
      title: "Charge Percentage",
      dataIndex: "charge_percentage",
      render: (text) => {
        return text !== undefined && text !== null ? `${text}%` : "-";
      },
    },

    {
      title: "Charge Amount",
      dataIndex: "charge_amount",
      render: (text) => {
        return text ? `₹${new Intl.NumberFormat("en-IN").format(text)}` : "-";
      },
    },

    {
      title: "Charge Gst Percentage",
      dataIndex: "charge_gst_percentage",
      render: (text) => {
        return text !== undefined && text !== null ? `${text}%` : "-";
      },
    },
    {
      title: "Charge GST Amount",
      dataIndex: "charge_gst_amount",
      render: (text) => {
        return text ? `₹${new Intl.NumberFormat("en-IN").format(text)}` : "-";
      },
    },

    {
      title: "Total Amount",
      dataIndex: "total_amount",
      render: (text) =>
        text && !isNaN(text)
          ? `₹${new Intl.NumberFormat("en-IN").format(text)}`
          : "-",
    },

    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <div>
            <EditOutlined
              onClick={() => {
                setSelectedCharges(record);
                showChargesEditDrawer();
              }}
            />
          </div>
        );
      },
    },
  ];

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getAdditionalLoanDetailIdService(loan_id);
        setLoanData(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [loan_id, refreshDataIncomeDetails]);

  useEffect(() => {
    const fetchChargesData = async () => {
      try {
        setLoading(true);
        const res = await getChargesByIdService(loan_id);
        setChargesData(res.data.charge_details);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching charges data:", error);
        setLoading(false);
      }
    };

    const fetchLTVCalculation = async () => {
      try {
        const response = await LTVCalculationsByLoanId(loan_id);
        setLTVCalculation(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        setLoading(false); // Stop loading regardless of success or error
      }
    };

    fetchLTVCalculation();
    fetchChargesData();
  }, [refreshTableData, loan_id]);

  useEffect(() => {
    const fetchInsuranceData = async () => {
      try {
        const res = await getInsurancesByIdService(loan_id);
        setInsurances(res.data);
        if (res.data.length > 0) {
          setSelectedInsurance(res.data[0]);
          setActiveButton(res.data[0].id);
        }
      } catch (error) {
        console.error("Error fetching charges data:", error);
        setLoading(false);
      }
    };
    fetchInsuranceData();
  }, [loan_id, refeshInsurance]);

  const data = chargesData
    ? chargesData.map((item, index) => ({
      key: index.toString(),
      id: item.id,
      no: (index + 1).toString(),
      charge: item.charge || "-",
      charge_percentage: item.charge_percentage || "-",
      charge_amount: item.charge_amount || "-",
      charge_gst_percentage: item.charge_gst_percentage || "-",
      charge_gst_amount: item.charge_gst_amount || "-",
      total_amount: item.total_amount || "-",
    }))
    : [];

  const showDrawerIncomeDetails = () => {
    setOpenIncomeDetails(true);
  };
  const onCloseIncomeDetails = () => {
    setOpenIncomeDetails(false);
  };
  const showDrawer = async () => {
    try {
      setLoading(true); // Show loading indicator while fetching data
      const response = await getInsuranceDetailsCamService(loan_id); // Call the API
      if ((response.status = 200 && response.success)) {
        setOpen(true);
      }
    } catch (error) {
      message.error("Please complete Cam, then fill insurance detail.");
    } finally {
      setLoading(false);
    }
  };

  // const showDrawer = () => setOpen(true);
  const onClose = () => {
    setCount(0);
    setOpen(false);
    toggleRefreshInsurance();
    setDisabledButton(true);
  };

  const handleButtonClick = (insurance) => {
    setActiveButton(insurance.id);
    setSelectedInsurance(insurance);
    // toggleRefreshInsurance()
  };

  const renderInsuranceView = () => {
    if (!selectedInsurance) return null;
    switch (selectedInsurance.insurance_company_detail__code) {
      case "HDFCERGO":
        return (
          <HDFCInsuranceview
            insurance_id={selectedInsurance.id}
            loan_id={loan_id}
            toggleRefreshInsurance={toggleRefreshInsurance}
          />
        );
      case "ICICIPRU":
        return (
          <ICICIInsuranceview
            insurance_id={selectedInsurance.id}
            loan_id={loan_id}
            toggleRefreshInsurance={toggleRefreshInsurance}
          />
        );
      case "MAGMAHDI":
        return (
          <MagmaHDIInsuranceview
            insurance_id={selectedInsurance.id}
            loan_id={loan_id}
            toggleRefreshInsurance={toggleRefreshInsurance}
            setDisabledButton={setDisabledButton}
            disabledButton={disabledButton}
          />
        );
      default:
        return null;
    }
  };
  const renderEditButton = (id) => (
    <div className="tab_form_sub_edit" onClick={() => {
      setSelectedRecordId(id);
      showDrawerIncomeDetails();
    }}>
      <EditButton />
    </div>
  );


  return (
    <>
      <Drawer
        title={
          <Header
            title={selectedCharges ? "Edit" : "Add"}
            onClose={onCloseChargesDrawer}
            name={`Charges`}
          />
        }
        width={750}
        onClose={onCloseChargesDrawer}
        open={openCharges}
        closable={false}
      >
        <ChargesUnderwritingForm
          refreshData={toggleRefreshTableData}
          id={selectedCharges && selectedCharges?.id}
          loan_id={loan_id}
          open={openCharges}
          closeForm={onCloseChargesDrawer}
        />
      </Drawer>
      <Drawer
        title={<Header title={"Add"} onClose={onClose} name="Insurance" />}
        bodyStyle={{ padding: "0px" }}
        width={1000}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <InsuranceForm
          loan_id={loan_id}
          open={open}
          closeForm={onClose}
          toggleRefreshInsurance={toggleRefreshInsurance}
          count={count}
          setCount={setCount}
          setDisabledButton={setDisabledButton}
          disabledButton={disabledButton}
          monthlyIncome={monthlyIncome}
        />
      </Drawer>
      <Drawer
        title={
          <Header
            title={"Edit"}
            onClose={onCloseIncomeDetails}
            name="Loan Details"
          />
        }
        width={900}
        onClose={onCloseIncomeDetails}
        open={openIncomeDetails}
        closable={false}
      >
        <LoanDetailsForm
          refreshData={toggleRefreshTableDataIncomeDetails}
          data={loanData}
          id={selectedRecordId}
          open={openIncomeDetails}
          closeForm={onCloseIncomeDetails}
        />
      </Drawer>
      <div className="fade-in">
        <div className="heading_details_container">
          <div
            className={`heading_text ${loanDetailsCollapseSections ? "" : "no-border"
              }`}
          >
            <div>Loan Details</div>


            <div style={{ display: "flex", gap: "10px" }}>
              {/* <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["EDITLTV1"]}
            > */}
              {renderEditButton(loanData?.additional_loan_detail_id)}
              {/* </ConditionalRender> */}

              {loanDetailsCollapseSections ? (
                <UpOutlined
                  onClick={() =>
                    setLoanDetailsCollapseSections(!loanDetailsCollapseSections)
                  }
                />
              ) : (
                <DownOutlined
                  onClick={() =>
                    setLoanDetailsCollapseSections(!loanDetailsCollapseSections)
                  }
                />
              )}
            </div>
          </div>

          {loanDetailsCollapseSections && (
            <>
              {loanData ? (
                <>
                  <div className="loan_insurance_main_container">
                    <div className="loan_details_main_contenet">
                      <div className="loan_details_contenet">
                        <div className="loan_details_label">Loan Amount (Include Insurance)</div>
                        <div className="loan_details_data">
                          {loanData?.loan_amount_include_insurance
                            ? `₹ ${IndianNumberFormat(
                              loanData?.loan_amount_include_insurance
                            )}`
                            : "-"}
                        </div>
                      </div>
                      <div className="loan_details_contenet">
                        <div className="loan_details_label">Loan Amount (Exclude Insurance)</div>
                        <div className="loan_details_data">
                          {loanData?.loan_amount_exclude_insurance
                            ? `₹ ${IndianNumberFormat(
                              loanData?.loan_amount_exclude_insurance
                            )}`
                            : "-"}
                        </div>
                      </div>
                      <div className="loan_details_contenet">
                        <div className="loan_details_label">Approved ROI</div>
                        <div className="loan_details_data">
                          {loanData?.approved_roi || "-"}
                        </div>
                      </div>
                      <div className="loan_details_contenet">
                        <div className="loan_details_label">Approved Tenure</div>
                        <div className="loan_details_data">
                          {/* {loanData?.approved_tenure || "-"} */}
                          {loanData?.approved_tenure
                            ? `${IndianNumberFormat(
                              loanData?.approved_tenure
                            )}`
                            : "-"}
                        </div>
                      </div>
                      <div className="loan_details_contenet">
                        <div className="loan_details_label">EMI</div>
                        <div className="loan_details_data">
                          {/* {loanData?.emi || "-"} */}
                          {loanData?.emi
                            ? `₹ ${IndianNumberFormat(
                              loanData?.emi
                            )}`
                            : "-"}
                        </div>
                      </div>
                      <div className="loan_details_contenet">
                        <div className="loan_details_label">Total Insurance</div>
                        <div className="loan_details_data">
                          {/* {loanData?.total_insurance_premium || "-"} */}
                          {loanData?.total_insurance_premium
                            ? `₹ ${IndianNumberFormat(
                              loanData?.total_insurance_premium
                            )}`
                            : "-"}
                          {loanData?.insurances?.length > 0 ?
                            <Popover
                              content={
                                <Card
                                  style={{ width: "250px" }}
                                  bodyStyle={{
                                    padding: "5px 10px", // Minimal padding for better alignment
                                  }}

                                >
                                  {loanData?.insurances?.length > 0 ? (
                                    loanData.insurances.map((insurance, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          margin: "10px",
                                        }}
                                      >
                                        <span>🔹 {insurance.company_name}</span>
                                        <span>₹ {IndianNumberFormat(insurance.total_premium)}</span>
                                      </div>
                                    ))
                                  ) : (
                                    <p>No insurance data available</p>
                                  )}
                                </Card>
                              }
                              title="Insurance Details"
                              trigger="hover"
                            >
                              <InfoCircleOutlined
                                style={{
                                  marginLeft: "10px",
                                  color: "rgb(190 132 11)",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                }}
                              />
                            </Popover>
                            : ""}

                          {/* <InfoCircleOutlined style={{ marginLeft: "10px", color: "rgb(190 132 11)",fontSize:"19px",cursor:"pointer"}} /> */}
                        </div>
                      </div>
                      <div className="loan_details_contenet">
                        <div className="loan_details_label">Scheme </div>
                        <div className="loan_details_data">
                          {loanData?.scheme_name?.display_name || "-"}
                        </div>
                      </div>

                      <div className="loan_details_contenet">
                        <div className="loan_details_label">Product</div>
                        <div className="loan_details_data">
                          {loanData?.product_end_use_name?.display_name || "-"}
                        </div>
                      </div>

                      <div className="loan_details_contenet">
                        <div className="loan_details_label">Interest Type</div>
                        <div className="loan_details_data">
                          {loanData?.interest_type || "-"}
                        </div>
                      </div>



                      <div className="loan_details_contenet">
                        <div className="loan_details_label">Due Date</div>
                        <div className="loan_details_data">
                          {loanData?.due_date || "-"}
                        </div>
                      </div>

                      <div className="loan_details_contenet">
                        <div className="loan_details_label">Test Program</div>
                        <div className="loan_details_data">
                          {loanData?.test_program?.display_name || "-"}
                        </div>
                      </div>
                      <div className="loan_details_contenet">
                        <div className="loan_details_label">CLSS</div>
                        <div className="loan_details_data">
                          {loanData?.clss_value ? "Yes" : "No"}
                        </div>
                      </div>
                      <div className="loan_details_contenet">
                        <div className="loan_details_label">Transaction Type</div>
                        <div className="loan_details_data">
                          {loanData?.transaction_type || "-"}
                        </div>
                      </div>
                      <div className="loan_details_contenet">
                        <div className="loan_details_label">PSL</div>
                        <div className="loan_details_data">
                          {loanData?.psl ? "Yes" : "No"}
                        </div>
                      </div>


                    </div>

                  </div>
                </>
              ) : (
                <div className="no_data_found_message">
                  <CustomNoData />
                </div>
              )}
            </>
          )}
        </div>



        <div className="heading_details_container">
          <div
            className={`charges_heading ${
              chargesCollapseSections ? "" : "no-border"
            }`}
          >
            <div className="charges">Charges</div>
            <div className="charges-underwriting-buttons">
              <div
                onClick={() => {
                  showChargesDrawer();
                  // setSelectedCharges(id);
                  // setSelectedPerson(person);
                }}
              >
                <Button
                  icon={<PlusCircleOutlined />}
                  className="add-charges-button"
                  // onClick={() => showDrawer("External")}
                >
                  Add Charges
                </Button>
              </div>
              {chargesCollapseSections ? (
                <UpOutlined
                  onClick={() =>
                    setChargesCollapseSections(!chargesCollapseSections)
                  }
                />
              ) : (
                <DownOutlined
                  onClick={() =>
                    setChargesCollapseSections(!chargesCollapseSections)
                  }
                />
              )}
            </div>
          </div>
          {chargesCollapseSections && (
            <>
              {chargesData ? (
                <div className="charges_table_container">
                  <Table
                    className="insurance_table"
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                  />
                </div>
              ) : (
                <Loading loading={loading} />
              )}
            </>
          )}
        </div>

        <div className="heading_details_container">
          <div
            className={`charges_heading ${
              chargesCollapseSections ? "" : "no-border"
            }`}
          >
            <div className="charges">Charges</div>
            <div className="charges-underwriting-buttons">
              <div
                onClick={() => {
                  showChargesDrawer();
                  // setSelectedCharges(id);
                  // setSelectedPerson(person);
                }}
              >
                <Button
                  icon={<PlusCircleOutlined />}
                  className="add-charges-button"
                  // onClick={() => showDrawer("External")}
                >
                  Add Charges
                </Button>
              </div>
              {chargesCollapseSections ? (
                <UpOutlined
                  onClick={() =>
                    setChargesCollapseSections(!chargesCollapseSections)
                  }
                />
              ) : (
                <DownOutlined
                  onClick={() =>
                    setChargesCollapseSections(!chargesCollapseSections)
                  }
                />
              )}
            </div>
          </div>
          {chargesCollapseSections && (
            <>
              {chargesData ? (
                <div className="charges_table_container">
                  <Table
                    className="insurance_table"
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                  />
                </div>
              ) : (
                <Loading loading={loading} />
              )}
            </>
          )}
        </div>

        <div className="heading_details_container">
          <div
            className={`heading_text_insurance_details ${
              collapseSections ? "" : "no-border"
            }`}
          >
            <div className="insurance-details-heading">Insurance Details</div>
            <div>
              <ConditionalRender
                userPermissions={user_data?.permissions}
                requiredPermissions={["POSTINSR"]}
              >
                <Button
                  icon={<PlusCircleOutlined />}
                  className="add-insurance-button"
                  onClick={() => showDrawer("External")}
                >
                  Add Insurance
                </Button>
              </ConditionalRender>
              {collapseSections ? (
                <UpOutlined
                  onClick={() => setCollapseSections(!collapseSections)}
                />
              ) : (
                <DownOutlined
                  onClick={() => setCollapseSections(!collapseSections)}
                />
              )}
            </div>
          </div>
          {collapseSections && (
            <>
              <div className="magma-hdfc-icici">
                {insurances && insurances.length > 0 ? (
                  insurances.map((insurance) => (
                    <Button
                      key={insurance.id}
                      className={`magma-hdfc-icici-tabs ${activeButton === insurance.id ? "active" : ""
                        }`}
                      onClick={() => handleButtonClick(insurance)}
                    >
                      {insurance.insurance_company_detail__name}
                    </Button>
                  ))
                ) : (
                  <div className="no_data_found_message">
                    <CustomNoData />
                  </div>
                )}
              </div>
              {insurances && insurances.length > 0 && renderInsuranceView()}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LoanInsurance;
